import React, { useEffect, useState } from 'react';
import styles from './Header.module.sass';
import cn from 'classnames';
import { useLocation } from 'react-router';
import { showLoginModal } from 'utils/showLoginModal';
import useAuth from 'hooks/useAuth';
import ModalPin from 'components/ModalPin';
import Views from 'screens/DetailsLayout/Views';

const Header = ({ article, pinned = false, views = 0 }) => {
  // UTILS-------------------------------------------------------------------UTILS

  const authCtx = useAuth();

  // PINS---------------------------------------------------------------------PINS

  const [pinState, setPinState] = useState(pinned || false);
  const [StarModalVisible, setStarModalVisible] = useState();
  const location = useLocation();
  // <----- WALIDACJA ODPINANIA ----->
  const pinsPath = location.pathname.slice(0, 5);
  const [redirectedUser, setRedirectedUser] = useState();
  const [unpinPost, setunpinPost] = useState();

  const unpinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (pinsPath === '/pins') {
      setunpinPost(true);
    } else {
      setRedirectedUser(true);
    }
  };
  const pinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    setStarModalVisible(true);
  };

  useEffect(() => {
    setPinState(pinned);
    if (pinState !== null) setPinState(pinned);
  }, [pinned]);

  // PINS---------------------------------------------------------------------PINS

  return (
    <>
      {(StarModalVisible || redirectedUser || unpinPost) && (
        <ModalPin
          visible={StarModalVisible}
          onClose={() => setStarModalVisible(false)}
          article={article}
          setPinState={setPinState}
          redirectedUser={redirectedUser}
          setRedirectedUser={setRedirectedUser}
          unpinPost={unpinPost}
          setunpinPost={setunpinPost}
          pinUrl={'/jobs/user-job/'}
          idName={'job_id'}
        />
      )}
      <div className={styles.container}>
        <Views viewsCount={article?.views} />
        <button
          className={cn(styles.pin_button)}
          onClick={pinState ? unpinHandler : pinHandler}
        >
          <img
            src={
              pinState
                ? '/images/Icons/heart/filled-color.svg'
                : '/images/Icons/heart/stroke.svg'
            }
            alt='favourite'
          />
        </button>
      </div>
    </>
  );
};

export default Header;
