import React, { useState } from 'react';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './Actions.module.sass';
import Icon from '../Icon';
import Loader from 'components/Loader';

const Actions = ({
  className,
  classActionsHead,
  classActionsBody,
  classActionsOption,
  deleteActionConfirmCancel,
  deleteConfirm,
  items,
  visible,
  setVisible,
  small,
  up,
  jzwit_button,
}) => {
  const [innerVisible, setInnerVisible] = useState(false);

  const toggle = () => {
    setVisible ? setVisible(!visible) : setInnerVisible(!innerVisible);
  };


  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setVisible ? setVisible(false) : setInnerVisible(false);
        deleteActionConfirmCancel && deleteActionConfirmCancel();
      }
      }
    >
      <div
        className={cn(
          styles.actions,
          className,
          {
            [styles.small]: small,
          },
          {
            [styles.up]: up,
          },
          {
            [styles.active]: visible ? visible : innerVisible,
          }
        )}
      >
        {jzwit_button ? (
          <button
            className={cn(classActionsHead)}
            onClick={() => toggle()}
          >
            {jzwit_button}
          </button>
        ) : (
          <button
            className={cn(styles.head, classActionsHead)}
            onClick={() => toggle()}
          >
            <Icon name='more-horizontal' size='24' />
          </button>
        )}
        <div className={cn(styles.body, classActionsBody)}>
          {items.map(
            (x, index) =>
              x?.title && (
                <button
                  className={cn(
                    styles.option,
                    classActionsOption,
                    x.color && styles.delete
                  )}
                  onClick={() => {
                    x.action && x.action();
                    deleteConfirm && toggle();
                  }
                  }
                  key={index}
                >
                  {x.icon && !x.loading && <Icon name={x.icon} size='24' />}
                  {x.loading && (
                    <div className={styles.loader}>
                      <Loader />
                    </div>
                  )}
                  {x.title}
                  {x.badge && <div className={styles.badge}>{x.badge}</div>}
                </button>
              )
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Actions;
