export class StatusNames {
  static PUBLISHED = 'Opublikowany';
  static SCHEDULED = 'Zaplanowany';
  static DRAFT = 'Szkic';
}

export class TypeNames {
  static POST = 'Post';
  static COURSE = 'Kurs';
  static JOB = 'Oferta pracy';
}