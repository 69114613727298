import React, { useState } from 'react';
import styles from './SortingAndFiltering.module.sass';
import Filters from '../../../components/Filters';
import Dropdown from '../../../components/Dropdown';
import FilterSettings from './FilterSettings';
import { courseSortingOptions } from 'data/dataArrays';
import CategoriesFilter from 'components/CategoriesFilter';

const SortingAndFiltering = props => {

  const CategorySelector = (x) =>
  {
    props.categorySelector(x)
    props.resetPagination(1)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.col}>
          <div className={styles.dropdown}>
            <Dropdown
              options={courseSortingOptions.map(item => item.name)}
              value={props.sortBy}
              setValue={props.setSortBy}
            />
          </div>
        </div>
        <div className={styles.col}>
          <CategoriesFilter
            className={styles.categories_button}
            categories={props.categories}
            categorySelector={CategorySelector}
          />
          <Filters className={styles.filters} title='Filtrowanie kursów'>
            <FilterSettings {...props} />
          </Filters>
        </div>
      </div>
    </>
  );
};
export default SortingAndFiltering;
