import React from 'react';
import styles from './Title.module.sass';
import cn from 'classnames';

const Title = ({ title, date }) => {
  let formatedDate;
  try {
    formatedDate = new Date(date).toLocaleDateString();
  } catch (e) {
    formatedDate = '';
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title || 'Brak tytułu'}</div>
      <div className={styles.date}>{formatedDate}</div>
    </div>
  );
};

export default Title;
