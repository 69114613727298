import React from 'react';
import styles from './Controls.module.sass';
import cn from 'classnames';
import Icon from 'components/Icon';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { supportEndpoints } from 'api/endpoints';
import { showError } from 'utils/showError';

const Controls = ({ id, refresh, is_solved }) => {
  // MARK AS RESOLVED-------------------------------------------------------------------MARK AS RESOLVED

  const handleResolve = async () => {
    console.log('resolve');
    try {
      const response = await devscntrAuth.put(
        `${supportEndpoints.reportBug.update}${id}/`,
        {
          is_solved: true,
        }
      );
      refresh && refresh();
      console.log(response);
    } catch (error) {
      showError(
        'Nie udało się zaktualizować zgłoszenia. Spróbuj ponownie później.'
      );
    }
  };

  // MARK AS RESOLVED-------------------------------------------------------------------MARK AS RESOLVED
  // DELETE REPORT-------------------------------------------------------------------------DELETE REPORT

  const handleDelete = async () => {
    try {
      const response = await devscntrAuth.delete(
        `${supportEndpoints.reportBug.update}${id}/`
      );
      console.log(response);
      setTimeout(() => refresh && refresh(), 1000);
    } catch (error) {
      showError('Nie udało się usunąć zgłoszenia. Spróbuj ponownie później.');
    }
  };

  // DELETE REPORT-------------------------------------------------------------------------DELETE REPORT
  // BLOCK USER-------------------------------------------------------------------------------BLOCK USER

  const handleBlockUser = async () => {
    console.log('block user');
    // try {
    //   const response = await devscntrAuth.delete(`${supportEndpoints.reportBug.update}${id}/`);
    //   console.log(response);
    //   setTimeout(() => refresh && refresh(), 1000);
    // } catch (error) {
    //   showError("Nie udało się usunąć zgłoszenia. Spróbuj ponownie później.");
    // }
  };

  // BLOCK USER-------------------------------------------------------------------------------BLOCK USER

  return (
    <div className={styles.controls}>
      <button className={styles.button} onClick={handleResolve}>
        <Icon name='check' size={20} />
      </button>
      <button className={styles.button} onClick={handleDelete}>
        <Icon name='trash' size={20} />
      </button>
      {/* <button className={styles.button} onClick={handleBlockUser}>
        <Icon name="lock" size={20} />
      </button> */}
    </div>
  );
};
export default Controls;
