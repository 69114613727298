import { useState, useEffect } from "react";
import axios from "axios";
import useAuth from "./useAuth";

const useAxios = () => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState();

  const authCtx = useAuth();

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const fetchData = async (configObj) => {
    const { method, url, requestConfig = {}, customHeaders } = configObj;


    const client = axios.create({
      baseURL: BASE_URL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
         ...(authCtx.isLoggedIn && {
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      }),
        ...customHeaders,
      },
    });

    try {
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await client[method.toLowerCase()](url, {
        ...requestConfig,
        signal: ctrl.signal,
      });
      setResponse(res.data);
      // console.log(res);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log(controller);

    // useEffect cleanup function
    return () => controller && controller.abort();
  }, [controller]);

  return [response, error, loading, fetchData];
};

export default useAxios;
