import React, { useState } from 'react';
import styles from './ReportBug.module.sass';
import cn from 'classnames';
import Card from 'components/Card';
import TextInput from 'components/TextInput';
import { validateInput } from 'utils/validation';
import { supportEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { showError } from 'utils/showError';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';

const MAX_DESCRIPTION_CHARS = 2000;

const ReportBug = () => {
  const authCtx = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isPosted, setIsPosted] = useState(false);

  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');

  const validation = () => {
    const titleValidation = validateInput(title, 100, 3, 'Tytuł', 'text');
    const emailValidation = validateInput(email, 100, 3, 'Email', 'email');
    const descriptionValidation = validateInput(
      description,
      MAX_DESCRIPTION_CHARS,
      20,
      'Opis błędu',
      'text'
    );

    return titleValidation && emailValidation && descriptionValidation;
  };

  const sendReport = async () => {
    setIsLoading(true);
    try {
      const response = await devscntrNoauth.post(
        supportEndpoints.reportBug.send,
        {
          title: title,
          email: email,
          description: description,
        }
      );
      setIsPosted(true);
      resetFields();
      authCtx.getLevel();
    } catch (error) {
      showError('Nie udało się wysłać zgłoszenia. Spróbuj ponownie później.');
    }
    setIsLoading(false);
  };

  const resetFields = () => {
    setTitle('');
    setEmail('');
    setDescription('');
  };

  const resetForm = () => {
    resetFields();
    setIsPosted(false);
  };

  const handleSubmit = async () => {
    if (validation()) {
      sendReport();
    }
  };

  return !isPosted ? (
    <div className={styles.card}>
      <div className={cn('title-yellow', styles.title)}>
        Zauważyłeś/aś błąd? Daj nam znać
      </div>
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.row}>
            <div className={styles.field}>
              <label>Tytuł</label>
              <TextInput
                name='title'
                className={styles.field}
                type='text'
                required
                onChange={e => setTitle(e.target.value)}
                value={title}
                placeholder='Podaj tytuł'
              />
            </div>
            <div className={styles.field}>
              <label>Twój email</label>
              <TextInput
                name='email'
                className={styles.field}
                type='email'
                required
                onChange={e => setEmail(e.target.value)}
                value={email}
                placeholder='Podaj swój email'
              />
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.head}>
              <label>
                Opis błędu <span className={styles.info}>(min. 20 znaków)</span>
              </label>
              <div
                className={cn(
                  styles.counter,
                  description.length > MAX_DESCRIPTION_CHARS && styles.error
                )}
              >
                {description.length}/{MAX_DESCRIPTION_CHARS}
              </div>
            </div>
            <textarea
              value={description}
              onChange={e => setDescription(e.target.value)}
              className={styles.textarea}
              placeholder='Postaraj się opisać błąd i okoliczności, w których wystąpił...'
              required
            />
          </div>
          <Button
            classNames={styles.button}
            isLoading={isLoading}
            onClick={handleSubmit}
            text='Wyślij zgłoszenie'
          />
        </div>
      </div>
    </div>
  ) : (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title='Dziękujemy za zgłoszenie błędu'
      classTitle={cn('title-yellow', styles.title)}
    >
      <div className={styles.container}>
        <div className={styles.message}>
          Zgłoszenia pomagają nam zlokalizować i naprawić błędy. Dzięki Tobie
          możemy poprawić naszą aplikację. Jeżeli twoje zgłoszenie okaże się
          pomocne, nagrodzimy Cię punktami doświadczenia.
        </div>
        <div className={styles.buttons}>
          <Button
            classNames={styles.button}
            text='Mam coś jeszcze'
            onClick={resetForm}
          />
          <Link to='/' className='button-stroke'>
            Strona główna
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default ReportBug;
