import React from 'react';
import styles from './Details.module.sass';
import cn from 'classnames';
import {
  currencyList,
  jobContractType,
  jobFormat,
  jobRecruitment,
  jobSeniority,
} from 'data/dataArrays';
import Item from './Item';

const currencyCode = currencyList[0].code;

const Details = ({ article }) => {
  const show =
    article?.salary.length > 0 ||
    article?.format ||
    article?.seniority ||
    article?.recruitment_format ||
    false;

  return (
    show && (
      <div className={styles.container}>
        {/* <div className={cn('title-green', styles.title)}>Szczegóły oferty</div>
      <div className={styles.content}>
        <ul>
          {article?.salary?.map((item, index) => (
            <li key={index}>
              <b>{jobContractType[item?.type - 1]}: </b>
              {item.type !== '1'
                ? `${item?.salary_from} ${currencyCode} - ${item?.salary_to} ${currencyCode}`
                : `${item?.hour_wage} ${currencyCode}/h`}
            </li>
          ))}
          <li>
            <b>Forma pracy:</b> {jobFormat[article?.format - 1]}
          </li>
          <li>
            <b>Doświadczenie:</b> {jobSeniority[article?.seniority - 1]}
          </li>
          <li>
            <b>Forma rekrutacji:</b>{' '}
            {jobRecruitment[article?.recruitment_format - 1]}
          </li>
        </ul>
      </div> */}
        <div className={styles.list}>
          {article?.salary?.length > 0 && (
            <Item icon='payment'>
              <b>Wynagrodzenie</b>
              {article?.salary?.map((item, index) => (
                <li className={styles.list_item} key={index}>
                  <b>{jobContractType[item?.type - 1]}: </b>
                  {item.type !== '1'
                    ? `${parseFloat(
                        item?.salary_from
                      ).toLocaleString()} ${currencyCode} - ${parseFloat(
                        item?.salary_to
                      ).toLocaleString()} ${currencyCode}`
                    : `${parseFloat(
                        item?.hour_wage
                      ).toLocaleString()} ${currencyCode}/h`}
                </li>
              ))}
            </Item>
          )}

          {article?.format && (
            <Item icon='home'>
              <b>Miejsce pracy</b>
              {jobFormat[article?.format - 1]}
            </Item>
          )}

          {article?.seniority && (
            <Item icon='clock'>
              <b>Doświadczenie</b>
              {jobSeniority[article?.seniority - 1]}
            </Item>
          )}

          {article?.recruitment_format && (
            <Item icon='smile'>
              <b>Forma rekrutacji</b>
              {jobRecruitment[article?.recruitment_format - 1]}
            </Item>
          )}
        </div>
      </div>
    )
  );
};

export default Details;
