import React, { useEffect, useState } from 'react';
import TextInput from '../../../components/TextInput';
import styles from './MiniData.module.sass';
import gt from '../NewTechnology.module.sass';
import useAuth from 'hooks/useAuth';
import { useContext } from 'react';
import FormContext from 'context/FormContext';
import EditorTiny from 'components/EditorTiny';
import Checkbox from 'components/Checkbox';
import ImageInputJZWIT from '../ImageInputJZWIT';

const MiniData = ({ editBasicData, checkboxValue, onCheckboxChange, isEditor }) => {
  const authCtx = useAuth();
  const formCtx = useContext(FormContext);
  const [image, setImage] = useState(editBasicData?.image || '');
  const [icon, setIcon] = useState(editBasicData?.icon || '');
  const [name, setName] = useState(editBasicData?.name || '');
  const [subtitle, setSubtitle] = useState(editBasicData?.subtitle || '');


  let basicData = {
    name: '',
    subtitle: '',
    content: '',
    image: '',
    icon: '',
  };
  const [formData, updateFormData] = useState(basicData);
  const [editorState, setEditorState] = useState('');
  const handleChangeOfData = () => {
    updateFormData({
      ...formData,
      name: name,
      subtitle: subtitle,
      image: image,
      icon: icon,
      content: editorState,
    });
  };

  const handleEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  useEffect(() => {
    if (editBasicData?.text) {
      setEditorState(editBasicData?.text || '');
    }
    if (editBasicData?.name) {
      setName(editBasicData?.name || '');
    }
  }, [editBasicData]);

  useEffect(() => {
    const timeOut = setTimeout(formCtx.setBasicData(formData), 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [formData]);

  useEffect(() => {
    setImage(editBasicData?.image || '');
    setIcon(editBasicData?.icon || '');
  }, [editBasicData]);

  useEffect(() => {
    const timeOut = setTimeout(handleChangeOfData, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [image, icon, editorState, name, subtitle]);

  return (
    <div className={gt.container}>
      <div className={gt.sc_title}>
        <h4>Wymagane Informacje</h4>
      </div>
      <div className={gt.ce_content}>
        <TextInput
          className={styles.field}
          label='Nazwa Technologi'
          name='name'
          type='text'
          tooltip='1-100 znaków. Bez tagów HTML oraz emotek'
          onTitleChange={e => setName(e.target.value)}
          value={name}
          required
        />
        <div className={styles.checkbox}>
          <Checkbox
            value={checkboxValue}
            onChange={(e) => { onCheckboxChange(e.target.checked); }} />
          Czy jest technologią?
        </div>
        <EditorTiny
          state={editorState}
          setState={handleEditorStateChange}
          label='Opis'
        />
        <ImageInputJZWIT
          className={styles.field}
          label='Zdjęcie w głównej sekcji'
          setFile={setImage}
          name='image'
          img={image}
          fileName={`${authCtx.userID}`}
          cropperHeight={250}
          isEditor = {isEditor}
        />
        <ImageInputJZWIT
          className={styles.field}
          label='Ikona Technologi'
          setFile={setIcon}
          name='icon'
          img={icon}
          fileName={`ikona_u${authCtx.userID}`}
          cropperHeight={250}
          isEditor = {isEditor}
        />
      </div>
    </div>
  );
};

export default MiniData;
