import React from 'react';
import styles from './PhoneNumberInput.module.sass';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import cn from 'classnames';
import Tooltip from '../Tooltip';

const PhoneNumberInput = ({
  className,
  classLabel,
  place,
  label,
  tooltip,
  name,
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <div className={className}>
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{' '}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon='info'
              place={place ? place : 'right'}
            />
          )}
        </div>
      )}
      <div className={styles.field}>
        <PhoneInput
          name={name}
          className={styles.input}
          placeholder='Your phone'
          defaultCountry='PL'
          international
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
export default PhoneNumberInput;
