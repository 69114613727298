import React from 'react';
import styles from './SearchBarLoader.module.sass';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SearchBarLoaderJZWIT = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <SkeletonTheme key={i}>
        <div className={styles.container} key={i}>
          <div className={styles.details}>
            <div className={styles.head}>
              <div style={{ width: `30%` }}>
                <Skeleton height={44} borderRadius={5} />
              </div>
            </div>
            <div className={styles.head}>
              <div style={{ width: `60%` }}>
                <Skeleton height={44} borderRadius={5} />
              </div>
            </div>
            <div className={styles.head}>
              <div style={{ width: `45%` }}>
                <Skeleton height={44} borderRadius={5} />
              </div>
            </div>
            <div className={styles.head}>
              <div style={{ width: `80%` }}>
                <Skeleton height={44} borderRadius={5} />
              </div>
            </div>
            <div className={styles.head}>
              <div style={{ width: `55%` }}>
                <Skeleton height={44} borderRadius={5} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default SearchBarLoaderJZWIT;
