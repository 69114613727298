import React from "react";
import cn from "classnames";
import styles from "./ModalConfirm.module.sass";
import Modal from "../Modal";

const ModalConfirm = ({ title, visible, setVisible, onConfirm }) => {
  const handleClose = () => {
    setVisible && setVisible(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setVisible && setVisible(false);
  };

  return (
    <Modal visible={visible} onClose={handleClose}>
      <div className={styles.container}>
        <h2>{title || "Potwierdź"}</h2>
        <div className={styles.btns}>
          <button className={cn("button")} onClick={handleConfirm}>
            Tak
          </button>
          <button
            className={cn("button-stroke", styles.cancel)}
            onClick={handleClose}
          >
            Nie
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default ModalConfirm;
