import styles from './FilterSettings.module.sass';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';

import cn from 'classnames';

export default function FilterSettings({
  typeName,
  statusName,
  setTypeName,
  setStatusName,
  typeOptions,
  statusOptions,
  clearFilters,

  pageLimitValue,
  setPageLimit,
  pageLimitOptions,
}) {
  return (
    <div className={styles.settings}>
      <div className={styles.item}>
        <div className={styles.label}>Ilość publikacji na stronę</div>
        <div className={styles.box}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            classDropdownLabel={styles.label}
            value={pageLimitValue}
            setValue={setPageLimit}
            options={pageLimitOptions}
          />
          <Icon name="filter" size="24" fill="#6F767E" />
        </div>
      </div>

      <div className={styles.item}>
        <div className={styles.label}>Typ publikacji</div>
        <div className={styles.box}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            classDropdownLabel={styles.label}
            value={typeName}
            setValue={setTypeName}
            options={typeOptions}
          />
          <Icon name="filter" size="24" fill="#6F767E" />
        </div>
      </div>

      <div className={styles.item}>
        <div className={styles.label}>Status publikacji</div>
        <div className={styles.box}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            classDropdownLabel={styles.label}
            value={statusName}
            setValue={setStatusName}
            options={statusOptions}
          />
          <Icon name="filter" size="24" fill="#6F767E" />
        </div>
      </div>

      <div className={styles.btns}>
        <button
          className={cn("button", styles.button)}
          onClick={clearFilters}
        >
          Resetuj filtry
        </button>
        {/* <button
            className={cn("button", styles.button)}
            onClick={props.onFiltersSubmit}
          >
            Zastosuj filtry
          </button> */}
      </div>
    </div>
  )
}
