import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './RecommendedPosts.module.sass';
import { Link } from 'react-router-dom';
import Card from '../Card';
import axios from 'axios';
import Item from './Item';
import SkeletonLoader from './Loader';
import useAuth from '../../hooks/useAuth';
import devscntrAuth from '../../api/Instance/devscntrAuth';
import devscntrNoauth from '../../api/Instance/devscntrNoauth';

const RecommendedPosts = ({ className, limit }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [recommendedPosts, setRecommendedPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [preparedPosts, setPreparedPosts] = useState(true);

  const getRecommendedPosts = async cancelToken => {
    try {
      const response = await axiosInstance.request({
        method: 'get',
        url: '/misc/recommended/',
        cancelToken: cancelToken.token,
      });
      setRecommendedPosts(response.data);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('recommended posts canceled');
      } else {
        console.log(error);
      }
    }
  };

  const preparePosts = () => {
    setPreparedPosts(() =>
      recommendedPosts.map(
        (post, index) => limit > index && <Item post={post} key={index} />
      )
    );
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getRecommendedPosts(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [isLoading]);

  useEffect(() => {
    preparePosts();
  }, [recommendedPosts]);

  return (
    <Card
      className={cn(styles.card, className)}
      title='Polecane wpisy'
      classTitle='title-blue'
    >
      <div className={styles.popular}>
        {isLoading ? (
          <SkeletonLoader cards={3} />
        ) : (
          <div className={styles.list}>
            {preparedPosts?.length > 0 ? (
              preparedPosts
            ) : (
              <span className={styles.info}>Brak polecanych wpisów</span>
            )}
          </div>
        )}
        <Link className={cn('button-stroke', styles.button)} to='/posts'>
          Wszystkie wpisy
        </Link>
      </div>
    </Card>
  );
};

export default RecommendedPosts;
