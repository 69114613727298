import React from 'react';
import styles from './Level.module.sass';
import cn from 'classnames';

const Level = ({ classNames, data }) => {
  return (
    <div className={cn(styles.container, classNames && classNames)}>
      <div className={styles.level}>
        <div className={styles.currentlvl}>
          Level: {data?.level?.substring(6) || 0}
        </div>
        <span className={styles.divider}>x</span>
        <div className={styles.xpPoints}>
          {data.currentXp || 0}/
          {data.currentXp > data.xpToNextLevel ? 'max' : data.xpToNextLevel}
        </div>
      </div>
      <div className={styles.rank}>Ranga: {data.currentRank || 'Brak'}</div>
      <div className={styles.badge}>
        Order: <img src={data.badge} alt='' />
      </div>
    </div>
  );
};

export default Level;
