import React from 'react';
import { Helmet } from 'react-helmet-async';

const AppHelmet = props => {
  const { title, forceTitle, description, image, type, url, children } = props;

  return (
    <Helmet prioritizeSeoTags>
      <title>
        {!title || title?.trim()?.length === 0
          ? 'itCNTR'
          : forceTitle
          ? `${title}`
          : `${title} - itCNTR`}
      </title>
      {title && <meta property='og:title' content={title} />}

      {description && (
        <>
          <meta property='og:description' content={description} />
          <meta name='description' content={description} />
        </>
      )}

      {image && <meta property='og:image' content={image} />}
      {type && <meta property='og:type' content={type} />}
      {url && <meta property='og:url' content={url} />}

      {children}
    </Helmet>
  );
};
export default AppHelmet;
