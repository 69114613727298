import { useEffect, useState } from 'react';

import { getItems } from 'lib/b5-test-resources';
import Test from './BigFiveMainTest';

import style from './BigFiveTest.module.sass';
import AppHelmet from 'components/AppHelmet';
import Card from 'components/Card';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { b5TestEndpoints } from 'api/endpoints';
import { useHistory, useLocation } from 'react-router-dom';
import { showLoginModal } from 'utils/showLoginModal';
import useAuth from 'hooks/useAuth';
import BigFiveTestInstruction from './BigFiveMainTest/components/BigFiveTestInstruction';

const BigFiveTest = () => {
  const authCtx = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [instructionVisible, setInstructionVisible] = useState(true);

  const [state, setState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const x = await getItems('pl');
      setState(x);
    };

    getData();
    window.scrollTo(0, 0);
  }, []);

  const submitTest = async result => {
    try {
      setIsLoading(true);
      const { data } = await devscntrAuth.post(b5TestEndpoints.save, result, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('b5data');
      localStorage.removeItem('b5InProgress');
      history.push(`/big-five/result/${data.Test_id}`, {
        from: location.pathname,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!authCtx.isLoggedIn) {
    showLoginModal();
    return null;
  }

  return (
    <>
      <AppHelmet title={'Test - Big Five'} />

      <Card
        className={style.container}
        title={'Test - Big Five'}
        classTitle={'title-blue'}
      >
        <Test
          lang={'pl'}
          inventory={state}
          onSubmit={submitTest}
          isLoading={isLoading}
        />
      </Card>

      <BigFiveTestInstruction
        visible={instructionVisible}
        onClose={() => setInstructionVisible(false)}
      />
    </>
  );
};

export default BigFiveTest;
