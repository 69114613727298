import React, { useState } from 'react';
import styles from './AddLevel.module.sass';
import cn from 'classnames';
import TextInput from 'components/TextInput';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { adminEndpoints } from 'api/endpoints';
import { validateInput } from 'utils/validation';
import { showError } from 'utils/showError';

const AddLevel = ({ onSuccess }) => {
  // UTILS-------------------------------------------------------------------------UTILS

  const [name, setName] = useState('');
  const [points, setPoints] = useState('');

  // UTILS-------------------------------------------------------------------------UTILS
  // VALIDATION---------------------------------------------------------------VALIDATION

  const validation = () => {
    if (!validateInput(name, 20, 1, 'nazwa', 'numbers')) return false;
    if (!validateInput(points, 20, 1, 'punkty', 'numbers')) return false;

    return true;
  };

  // VALIDATION---------------------------------------------------------------VALIDATION
  // ADD LEVEL-----------------------------------------------------------------ADD LEVEL

  const addLevel = async () => {
    if (!validation()) return;
    try {
      const response = await devscntrAuth.post(adminEndpoints.settings.levels, {
        name: `Level ${name}`,
        from_xp: points,
      });
      console.log(response);
      onSuccess();
    } catch (error) {
      showError('Nie udało się dodać poziomu');
    }
  };

  // ADD LEVEL-----------------------------------------------------------------ADD LEVEL

  return (
    <div className={styles.container}>
      <div className='title-blue'>Dodaj poziom</div>
      <div className={styles.content}>
        <div className={styles.field}>
          <TextInput
            label='Nazwa'
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            label='Punkty'
            value={points}
            onChange={e => setPoints(e.target.value)}
          />
        </div>
        <button className='button' onClick={addLevel}>
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default AddLevel;
