import React, { useEffect, useState } from "react";
import PhotoCropper from "../PhotoCropper";
import { validateImage } from "../../utils/validation";
import useAuth from "../../hooks/useAuth";

const PhotoUploader = ({
  onSubmit,
  sourceImage,
  width,
  height,
  filename,
  setErrorMsg,
}) => {
  const [files, setFiles] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const authCtx = useAuth();

  // Convert promise
  const getFile = async () => {
    return await fetch(imageURL);
  };

  // Convert file to base64 image
  function createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      setImageBase64(e.target.result);
      //console.log(`result: ${e.target.result}`);
    };
    reader.readAsDataURL(file);
  }

  // Converts base64 output image to file promise
  const urlToFile = async (url, filename, mimeType) => {
    const result = await fetch(url);
    const buffer = await result.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  };

  const onCropSubmit = async (output) => {
    console.log(`index: ${output}`);

    // Convert base64 image to file
    const file = urlToFile(output, `${filename}.jpeg`, "image/jpeg");
    const img = await file;

    // Validate image
    if (validateImage(img, 5)) {
      console.log(img);
      onSubmit(img, output);
    }
  };

  // Create new image based on source image and cropped area
  // REQUIRES: source image URL, new image width, height
  const resizeImage = (imagePath, newWidth, newHeight) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const originalImage = new Image();
    originalImage.src = imagePath;

    const renderCroppedImage = async () => {
      const originalWidth = originalImage.naturalWidth;
      const originalHeight = originalImage.naturalHeight;
      const aspectRatio = 1; //originalWidth / originalHeight;

      if (!newHeight) {
        newHeight = Math.floor(newWidth / aspectRatio);
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(
        originalImage,
        croppedArea.x,
        croppedArea.y,
        croppedArea.width,
        croppedArea.height,
        0,
        0,
        newWidth,
        newHeight
      );

      const output = canvas.toDataURL("image/jpeg", 0.9);
      console.log(`w funkcji:${output}`);
      onCropSubmit(output);
    };

    // On image load listener
    originalImage.addEventListener("load", renderCroppedImage);
  };

  const setFile = (file) => {
    setFiles(file);
    createImage(file);
  };

  if (files) {
    getFile();
  }

  useEffect(() => {
    if (validateImage(sourceImage, 5)) {
      setFile(sourceImage);
      if (files) {
        console.log(`img: ${sourceImage}`);
        setImageURL(URL.createObjectURL(files));
      }
    }
    console.log(files)
  }, [files]);

  const onCropComplete = (data) => {
    setCroppedArea(data);
  };

  const handleSubmit = () => {
    resizeImage(imageURL, width, height);
  };

  return (
    <div>
      <PhotoCropper
        onCropComplete={onCropComplete}
        onSubmit={handleSubmit}
        image={imageURL}
        width={width}
        height={height}
      />
    </div>
  );
};

export default PhotoUploader;
