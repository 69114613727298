import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './ProfilePage.module.sass';
import Profile from './Profile';
import Card from '../../components/Card';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import Courses from './Courses';
import Company from './Company';
import { setShortenAmount } from 'utils';

import useAuth from '../../hooks/useAuth';
import devscntrAuth from '../../api/Instance/devscntrAuth';
import devscntrNoauth from '../../api/Instance/devscntrNoauth';
import Jobs from './Jobs';
import { accountsEndpoints, levelsEndpoints } from '../../api/endpoints';
import LevelProgress from 'components/User/LevelProgress';
import { extractSocialLinks } from 'utils';
import Posts from './Posts';
import useBreakpoints from 'hooks/useBreakpoints';
import MyFollows from './Follows/MyFollows';
import MyFollowers from './Follows/MyFollowers';
import Pins from './Pins';
import ProfileLoader from './ProfileLoader';
import QuizzesList from './QuizzesList';
import Loader from 'components/MiniPost/Loader';
import AboutMe from './AboutMe';
import AboutMeLoader from './AboutMeLoader';

const defaultNav = [
  'O mnie',
  'Wpisy',
  'Kursy',
  'Przypinki',
  'Obserwacje',
  'Obserwujący',
  'Kwestionariusze',
];

const ProfilePage = () => {
  const [navigation, setNavigation] = useState(defaultNav);
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const params = useParams();
  const history = useHistory();

  const [rerender, setRerender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(defaultNav[0]);

  const [aboutMeData, setAboutMeData] = useState([]);

  const [stylesRef, setStylesRef] = useState(null);
  const breakpoints = [
    ['desktop', Infinity],
    ['tablet', 1024],
    ['mobile', 768],
  ];
  useBreakpoints(stylesRef, breakpoints);

  const [data, setData] = useState({});
  const [levelData, setLevelData] = useState(null);
  const [authorData, setAuthorData] = useState({
    id: params.id,
    name: '',
    image: null,
    bgImage: null,
    bio: '',
    level: 0,
    xpPoints: 0,
    follows: [],
    is_rss: false,
  });
  const [isBlocked, setIsBlocked] = useState(false);

  const [currentFollows, setCurrentFollows] = useState([]);
  const [myFollowers, setMyFollowers] = useState([]);
  const [isFollowed, setIsFollowed] = useState();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (authCtx.isLoggedIn) fetchFollows(cancelToken);
    fetchAuthor(cancelToken);
    getUserLevel(cancelToken);
    setNavigation(defaultNav);
    setActiveTab(defaultNav[0]);
    setActiveIndex(0);
    reRenderPosts();

    return () => {
      cancelToken.cancel();
    };
  }, [params.id]);

  // if it is a RSS profile, it will forward to main page
  useEffect(() => {
    if (authorData.is_rss) history.push('/');
  }, [authorData]);

  const reRenderPosts = () => {
    setActiveIndex(10);
    setTimeout(function () {
      setActiveIndex(0);
    }, 1000);
  };

  const fetchFollows = cancelToken => {
    devscntrAuth
      .get('/accounts/me/follow-user/', { cancelToken: cancelToken.token })
      .then(function (response) {
        let followings = response.data.map(item => item.id);
        setCurrentFollows(followings);
        if (followings) {
          setIsFollowed(followings.includes(parseInt(params.id)) || false);
        }
        setRerender(prev => !prev);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log('follows canceled');
        } else {
          console.log(error);
        }
      });
  };

  const fetchAuthor = cancelToken => {
    setIsLoading(true);

    axiosInstance
      .get(accountsEndpoints.userData.userInfo, {
        headers: {
          'USER-ID': params.id,
        },
        cancelToken: cancelToken.token,
      })
      .then(function (response) {
        setData(response.data);

        const hasCompany = response.data?.user?.company;

        const data = response.data;
        console.log(data)
        setAuthorData(prevState => ({
          ...prevState,
          id: data.user.id,
          name: data.user.first_name + ' ' + data.user.last_name,
          username: data.user.display_name, //username,
          image: data.user.image,
          bio: data.user.bio,
          bgImage: data.user.background_image,
          level: data.xp.current_level,
          xpPoints: data.xp.current_xp,
          xpToNextLvl: data.xp.next_level_xp_required,
          pins: data.pinned_posts,
          follows: data.following,
          isAuthor: data.user.is_author,
          is_rss: data.user?.is_rss,
          socials: extractSocialLinks(data.user.social_links),
        }));
        setAboutMeData(prevState => (
          {
            ...prevState,
            description: data.user.bio,
            phone: data.user.phone,
            email: data.user.email,
            employments: data.user.employments,
            education: data.user.education,
            certificates: data.user.certificates,
            skills: data.user.skills,
            interests: data.user.interests,
            foreign_languages: data.user.foreign_languages,
          }))
        setMyFollowers(response.data.followers || []);
        setRerender(prev => !prev);
        setIsLoading(false);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log('user data canceled');
        } else {
          console.log(error);
          history.push('/');
        }
      })
      .finally(() => {

      });
  };

  useEffect(() => {
    setIsBlocked(authCtx.blockedUsers.includes(parseInt(params.id)));
  }, [authCtx.blockedUsers]);

  const getUserLevel = async () => {
    try {
      const response = await devscntrNoauth.request({
        method: 'get',
        url: levelsEndpoints.userLevel,
        headers: { 'USER-ID': params.id },
      });

      const data = response.data;
      const calcNextLevelXp =
        data.next_level_xp_required - data.current_level_xp;
      const calcCurrentLevelXp = data.current_xp - data.current_level_xp;

      setLevelData({
        level: data.current_level.substr(6) || 'Level 0',
        currentXp: calcCurrentLevelXp || 0,
        currentRank: data.current_order || 'Brak',
        badge: data.current_order_image || null,
        xpToNextLevel: calcNextLevelXp || 1,
        thisLevelXp: 0,
        allPointsData: {
          nextLvlPoints: data.next_level_xp_required,
          currentLvlPoints: data.current_level_xp,
          currentPoints: data.current_xp,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const triggerFollowUser = (userID, isFollowed) => {
    if (isFollowed)
      setCurrentFollows(prevState => prevState.filter(x => x !== userID));
    else setCurrentFollows(prevState => [...currentFollows, userID]);

    const headers = {
      METHOD: isFollowed ? 'unfollow' : 'follow',
    };
    devscntrAuth
      .post(
        '/accounts/me/follow-user/',
        { id: userID },
        { headers: { ...headers } }
      )
      .then(function (response) {
        setIsFollowed(prev => !prev);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const descriptionInfoButton = () => {
    setActiveTab(defaultNav[0])
    scrollToSection('aboutMeDiv')
  }

  const scrollToSection = (sectionName) => {
    var scrollDiv = document.getElementById(sectionName).offsetTop;
    window.scrollTo({
      top: scrollDiv + 125,
      behavior: 'smooth',
    });
  }

  return (
    <>
      <div className={styles.shop} ref={x => setStylesRef(x)}>
        {!isLoading ? (
          <div className={styles.background}>
            <img
              src={authorData.bgImage || '/images/content/bg-shop.jpg'}
              alt='Background'
            />
          </div>
        ) : (
          <div className={styles.background_loader}></div>
        )}
        <Card className={styles.card}>
          {!authorData.is_rss ? (
            <>
              {!isLoading ? (
                <div className={styles.head}>
                  <Profile
                    name={
                      authorData?.name == ' '
                        ? authorData.name
                        : authorData.username || 'Brak nazwy'
                    }
                    desc={authorData.bio}
                    image={authorData.image}
                    level={authorData.level}
                    userId={authorData.id}
                    triggerFollow={triggerFollowUser}
                    currentUserId={authCtx.userID}
                    isFollowed={isFollowed}
                    isAuthor={authorData.isAuthor}
                    blocked={isBlocked}
                    followers={data.followers}
                    levelData={levelData}
                    descriptionButton={descriptionInfoButton}
                  />
                </div>
              ) : (
                <ProfileLoader />
              )}

              <div className={styles.container}>
                <div className={styles.control}>
                  <div className={styles.nav}>
                    {navigation.map((title, index) => (
                      <button
                        className={cn(styles.link, {
                          [styles.active]: title === activeTab,
                        })}
                        onClick={() => {
                          setActiveIndex(index);
                          setActiveTab(title);
                        }}
                        key={index}
                      >
                        {index === 3 && authorData?.follows?.length > 0 && (
                          <div className={styles.count_indicator}>
                            {setShortenAmount(authorData.follows?.length)}
                          </div>
                        )}
                        {index === 4 && myFollowers?.length > 0 && (
                          <div className={styles.count_indicator}>
                            {setShortenAmount(myFollowers?.length)}
                          </div>
                        )}
                        {title}
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.separator}></div>
                {!isLoading ? (
                  <div className={styles.wrap} id={'aboutMeDiv'}>
                    {activeTab === 'O mnie' && (
                      <AboutMe data={aboutMeData} />
                    )}
                    {activeTab === 'Wpisy' && (
                      <Posts setRerender={setRerender} params={params} />
                    )}
                    {activeTab === 'Kursy' && <Courses author={params?.id} />}
                    {activeTab === 'Przypinki' && <Pins userId={params.id} />}
                    {activeTab === 'Obserwacje' && (
                      <div className={styles.followers}>
                        <MyFollows data={authorData?.follows || []} />
                      </div>
                    )}

                    {activeTab === 'Obserwujący' && (
                      <div className={styles.followers}>
                        <MyFollowers data={myFollowers || []} />
                      </div>
                    )}

                    {activeTab === 'Kwestionariusze' && (
                      <div className={styles.quizzes}>
                        <QuizzesList data={data?.test_results || []} />
                      </div>
                    )}

                    {activeTab === 'Firma' && (
                      <>
                        <div className={styles.company}>
                          {data?.user?.company && (
                            <Company id={params.id} data={data?.user?.company} />
                          )}
                        </div>
                      </>
                    )}

                    {activeTab === 'Oferty pracy' && (
                      <div className={styles.company}>
                        {data?.user?.company && <Jobs userId={params.id} />}
                      </div>
                    )}
                  </div>
                ) : (
                  <AboutMeLoader />
                )}
              </div>
            </>
          ) : (
            <div className={styles.notExistsInfo}>Użytkownik nie istnieje</div>
          )}
        </Card>
      </div>
    </>
  );
};

export default ProfilePage;
