export default {
  plus: [
    {
      text: 'Sehr unzutreffend',
      score: 1,
      color: 1
    },
    {
      text: 'Unzutreffend',
      score: 2,
      color: 2
    },
    {
      text: 'Weder zutreffend noch unzutreffend',
      score: 3,
      color: 3
    },
    {
      text: 'Zutreffend',
      score: 4,
      color: 4
    },
    {
      text: 'Sehr zutreffend',
      score: 5,
      color: 5
    }
  ],
  minus: [
    {
      text: 'Sehr unzutreffend',
      score: 5,
      color: 1
    },
    {
      text: 'Unzutreffend',
      score: 4,
      color: 2
    },
    {
      text: 'Weder zutreffend noch unzutreffend',
      score: 3,
      color: 3
    },
    {
      text: 'Zutreffend',
      score: 2,
      color: 4
    },
    {
      text: 'Sehr zutreffend',
      score: 1,
      color: 5
    }
  ]
};
