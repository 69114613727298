// a copy from miniitemslayout, but modified

import React from 'react';
import styles from './Tags.module.sass';
import cn from 'classnames';
import uuid from 'react-uuid';

const Tags = ({ className, tags }) => {
  return (
    <div className={cn(styles.tags, className && className)}>
      {tags?.map(item => (
        <div className={styles.row} key={uuid()}>
          <div className={styles.count}>{item?.tags_count || '0'}</div>
          <div
            className={styles.tag}
            style={{
              backgroundColor: item?.colour
                ? item?.colour === 'undefined'
                  ? '#ffffff'
                  : item.colour
                : '#ffffff',
            }}
          >
            {item?.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tags;
