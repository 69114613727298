import React from 'react';
import styles from './QuizListItem.module.sass';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useAuth from 'hooks/useAuth';

const status = {
  private: 'Prywatny',
  public: 'Publiczny',
};

const QuizListItem = ({ data }) => {
  const { created_at, id, type, public: share = false } = data;
  const authCtx = useAuth();

  const isAuthUser = +authCtx.userID === +data?.user?.id;
  const canSeeResult = isAuthUser || (share && authCtx?.isLoggedIn);

  return (
    <li className={styles.item}>
      <div className={styles.date}>
        {new Date(created_at).toLocaleDateString()}
      </div>
      <div className={styles.type}>{type}</div>

      {share && <div className={'status-green'}>{status.public}</div>}
      {!share && <div className={'status-red'}>{status.private}</div>}

      <div className={styles.buttons}>
        {canSeeResult && (
          <Link
            className={classNames('button', styles.resultButton)}
            to={`/big-five/result/${id}`}
          >
            Zobacz wynik
          </Link>
        )}
      </div>
    </li>
  );
};

export default QuizListItem;
