import React, { useState, useEffect } from 'react';
import styles from './Courses.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import SortingAndFiltering from './SortingAndFiltering';
import axios from 'axios';
import RecommendedPosts from '../../components/RecommendedPosts';
import LatestComments from '../../components/LatestComments';

import useAuth from '../../hooks/useAuth';
import devscntrAuth from '../../api/Instance/devscntrAuth';
import devscntrNoauth from '../../api/Instance/devscntrNoauth';
import {
  courseDifficulty,
  courseFormat,
  courseLanguage,
  courseSortingOptions,
  pageSize,
} from 'data/dataArrays';
import MiniCourse from '../../components/MiniCourse';
import Loader from 'components/MiniCourse/Loader';
import { useHistory, useLocation } from 'react-router';
import { coursesEndpoints } from 'api/endpoints';
import AverageSalary from 'components/AverageSalary';
import Pagination from 'components/Pagination';

const ratingOptions = [
  'od 1 gwiazdki',
  'od 2 gwiazdek',
  'od 3 gwiazdek',
  'od 4 gwiazdek',
  'od 5 gwiazdek',
];
const difficultyFilterOptions = ['wszystkie', ...courseDifficulty];
const formatOptions = ['wszystkie', ...courseFormat];
const languageOptions = ['wszystkie', ...courseLanguage];

const postsPerPage = pageSize;
const Courses = () => {
  // UTILS--------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  const history = useHistory();
  const searchUrl = useLocation().search;

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);

  const [category, setCategory] = useState([]);

  // ARTICLES
  const [articles, setArticles] = useState([]);
  const [tags, setTags] = useState([]);

  // UTILS--------------------------------------------------------------------------UTILS
  // PAGINATION----------------------------------------------------------------PAGINATION

  const [currentPage, setCurrentPage] = useState(1);
  const [totalArticles, setTotalArticles] = useState(1);
  const [refresher, setRefresher] = useState(false);

  // SCROLL TOP ON PAGE CHANGE
  useEffect(() => {
    if (isMounted) {
      window.scrollTo(0, 0);
    }
  }, [currentPage]);

  // PAGINATION----------------------------------------------------------------PAGINATION
  // FILTERS STATES--------------------------------------------------------FILTERS STATES

  const [params, setParams] = useState('');
  const [sortBy, setSortBy] = useState(courseSortingOptions[0].name);
  const [filters, setFilters] = useState('');
  const [rating, setRating] = useState(ratingOptions[0]);
  const [languageFilter, setLanguageFilter] = useState(languageOptions[0]);
  const [difficulty, setDifficulty] = useState(difficultyFilterOptions[0]);
  const [format, setFormat] = useState(formatOptions[0]);
  const [priceFilters, setPriceFilters] = useState({
    showFree: false,
    showPaid: false,
    minPrice: 0,
    maxPrice: 100,
    priceRange: [23, 87],
  });

  // FILTERS----------------------------------------------------------------------FILTERS
  // GET COURSES--------------------------------------------------------------GET COURSES

  const getCourses = async cancelToken => {
    setIsLoading(true);
    const page = `&page=${currentPage}&page_size=${postsPerPage}`;
    try {
      const response = await axiosInstance.request({
        method: 'get',
        url: `${coursesEndpoints.show}?${params}${page}`,
        cancelToken: cancelToken.token,
      });

      const resData = response.data.results || [];
      let data = resData.filter(item => item.title) || [];
      if (authCtx.isAdmin) {
        data = resData;
      }

      const loadedArticles = data.map(x => (
        <MiniCourse key={x.id} article={x} />
      ));

      setTotalArticles(response.data?.count || 1);
      setArticles(loadedArticles);

      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status == 400) {
        console.log(error.response);
        const cancelToken = axios.CancelToken.source();
        getCourses(cancelToken);
      }
    }
    setContentLoaded(true);
  };

  useEffect(() => {
    if (isMounted) {
      setTotalArticles(1);
      setArticles([]);
      setRefresher(prev => !prev);
    }
  }, [params]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (isMounted) {
      setArticles([]);
      getCourses(cancelToken);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [searchUrl, refresher, isMounted]);

  // GET COURSES--------------------------------------------------------------GET COURSES
  // FILTERS----------------------------------------------------------------------FILTERS
  const getUrlParams = () => {
    let index;

    const loadedCategory = new URLSearchParams(searchUrl).get('categories');
    if (loadedCategory !== null && loadedCategory !== undefined) {
      const formatedCategory = loadedCategory.split('&');
      setCategory(formatedCategory);
    }

    const sortby = new URLSearchParams(searchUrl).get('sortby');
    index = courseSortingOptions.map(item => item.value).indexOf(sortby);

    if (index > 0) setSortBy(courseSortingOptions[index].name);
    else setSortBy(courseSortingOptions[0].name);

    const page = new URLSearchParams(searchUrl).get('page');
    if (page !== null) setCurrentPage(parseInt(page));

    const difficulty = new URLSearchParams(searchUrl).get('difficulty');
    if (difficulty !== null && difficultyFilterOptions[difficulty])
      setDifficulty(difficultyFilterOptions[difficulty]);
    else setDifficulty(difficultyFilterOptions[0]);

    const format = new URLSearchParams(searchUrl).get('type');
    if (format !== null && formatOptions[format])
      setFormat(formatOptions[format]);
    else setFormat(formatOptions[0]);

    const language = new URLSearchParams(searchUrl).get('language');
    if (language !== null) setLanguageFilter(language.toUpperCase());
    else setLanguageFilter(languageOptions[0]);

    const price = new URLSearchParams(searchUrl).get('price');
    if (price !== null)
      setPriceFilters(prev => ({ ...prev, showFree: price === 'free' }));
    else setPriceFilters(prev => ({ ...prev, showFree: false }));

    const tags = new URLSearchParams(searchUrl).get('tags') || [];
    if (tags?.length > 0) setTags(tags.split('&'));
    else setTags([]);

    setIsMounted(true);
  };

  useEffect(() => {
    getUrlParams();
    setArticles([]);
    if (!searchUrl) {
      setCategory([]);
    }
  }, [searchUrl]);

  useEffect(() => {
    if (isMounted) {
      changeUrlParams();
    }
  }, [
    sortBy,
    languageFilter,
    difficulty,
    format,
    priceFilters,
    tags,
    isMounted,
    currentPage,
    category,
  ]);

  const changeUrlParams = () => {
    const sortMethodIndex = courseSortingOptions
      .map(item => item.name)
      .indexOf(sortBy);

    let params = '';
    if (sortMethodIndex > 0)
      params += `sortby=${courseSortingOptions[sortMethodIndex].value}`;

    // PAGE
    if (currentPage > 1) {
      params += `${sortMethodIndex > 0 ? '&' : ''}page=${currentPage}`;
    }

    //CATEGORY
    if (category?.length > 0) {
      let temp = [];
      category.forEach(element => {
        temp.push(encodeURIComponent(element));
      });
      params += `&categories=${temp.join('%26')}`;
    }

    // DIFFICULTY
    if (difficultyFilterOptions[0] !== difficulty) {
      params += `&difficulty=${difficultyFilterOptions.indexOf(difficulty)}`;
    }
    // LANGUAGE
    if (languageOptions[0] !== languageFilter) {
      params += `&language=${languageFilter.toLowerCase()}`;
    }
    // FORMAT
    if (formatOptions[0] !== format) {
      params += `&type=${formatOptions.indexOf(format)}`;
    }
    // PRICE
    if (priceFilters.showFree) {
      params += `&price=free`;
    }
    // TAGS
    if (tags?.length > 0) {
      params += `&tags=${tags.join('%26')}`;
    }

    setParams(params);
  };

  useEffect(() => {
    if (isMounted) {
      history.push({
        search: params,
      });
    }
  }, [params]);

  const handleFiltersClear = () => {
    setFilters('');
    setPriceFilters(prev => ({
      ...prev,
      showFree: false,
    }));
    setRating(ratingOptions[0]);
    setLanguageFilter(languageOptions[0]);
    setDifficulty(difficultyFilterOptions[0]);
    setFormat(formatOptions[0]);
    setCurrentPage(1);
  };

  // FILTERS----------------------------------------------------------------------FILTERS
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <SortingAndFiltering
            categories={category}
            categorySelector={setCategory}
            resetPagination={setCurrentPage} //CATEGORY
            sortOptions={courseSortingOptions} // SORT
            sortBy={sortBy}
            setSortBy={filter => {
              setCurrentPage(1);
              setSortBy(filter);
            }}
            ratingOptions={ratingOptions} // RATING
            rating={rating}
            setRating={filter => {
              setCurrentPage(1);
              setRating(filter);
            }}
            languageOptions={languageOptions} // LANGUAGE
            languageFilter={languageFilter}
            setLanguageFilter={filter => {
              setCurrentPage(1);
              setLanguageFilter(filter);
            }}
            difficultyOptions={difficultyFilterOptions} // DIFFICULTY
            difficulty={difficulty}
            setDifficulty={filter => {
              setCurrentPage(1);
              setDifficulty(filter);
            }}
            formatOptions={formatOptions} // FORMAT
            format={format}
            setFormat={filter => {
              setCurrentPage(1);
              setFormat(filter);
            }}
            priceFilters={priceFilters} // PRICE
            setPriceFilters={filter => {
              setCurrentPage(1);
              setPriceFilters(filter);
            }}
            onFiltersSubmit={changeUrlParams} // ACTIONS
            onFiltersClear={handleFiltersClear}
          />
          {articles.length > 0 ? (
            <>
              {articles}
              {isLoading && <Loader cards={postsPerPage} />}
            </>
          ) : (
            !isLoading && <div className={styles.info}>Brak kursów</div>
          )}
          {isLoading && <Loader cards={postsPerPage * currentPage} />}
          <Pagination
            currentPage={currentPage}
            pageSize={postsPerPage}
            totalCount={totalArticles}
            onPageChange={page => setCurrentPage(page)}
          />
        </div>
        <div className={styles.col}>
          <AverageSalary className={styles.card} limit={3} />
          <RecommendedPosts className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Courses;
