import React, { useState } from 'react';
import styles from './AboutMeEducation.module.sass';
import cn from 'classnames';
import AboutMeDescriptionDisplay from '../AboutMeEmployments/AboutMeDescriptionDisplay';

const AboutMeEducation = ({ education }) => {
  const [openSections, setOpenSections] = useState([]);

  const months = [
    'styczeń',
    'luty',
    'marzec',
    'kwiecień',
    'maj',
    'czerwiec',
    'lipiec',
    'sierpień',
    'wrzesień',
    'październik',
    'listopad',
    'grudzień',
  ];

  const getMonth = month => {
    for (let i = 0; i < months.length; i++) {
      if (i === month) {
        return months[i];
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={cn('title-yellow', styles.header)}>Wykształcenie</div>
      {education?.length > 0 ? (
        <div className={styles.education_section}>
          {education?.map((item, index) => (
            <div className={styles.education_container} key={index}>
              <div className={styles.education_list}>
                <div className={styles.image}>
                  <img src={'/images/Education.png'} alt='Education' />
                </div>
                <div className={styles.information}>
                  <div className={styles.school}>{item?.school}</div>
                  <div className={styles.field}>
                    {item?.field}, {item?.major}, {item?.degree} stopień
                  </div>
                  <div className={styles.date}>
                    {new Date(item?.start_date).getUTCDate()}{' '}
                    {getMonth(new Date(item?.start_date).getUTCMonth())}{' '}
                    {new Date(item?.start_date).getUTCFullYear()} -{' '}
                    {new Date(item?.end_date).getUTCDate()}{' '}
                    {getMonth(new Date(item?.end_date).getUTCMonth())}{' '}
                    {new Date(item?.end_date).getUTCFullYear()}
                  </div>
                </div>
                <AboutMeDescriptionDisplay
                  openSections={openSections}
                  setOpenSections={setOpenSections}
                  index={index}
                />
              </div>
              {openSections.includes(index, 0) && (
                <div className={styles.description}>{item?.description}</div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.no_information}>
          Brak informacji o wykształceniu.
        </div>
      )}
    </div>
  );
};

export default AboutMeEducation;
