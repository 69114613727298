import { AuthContextProvider } from 'context/AuthContext';
import { GroupContextProvider } from 'context/group-context';
import { JZWITContextProvider } from 'context/jzwitContext';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { store } from 'store/rootReducer';

const ContextProviders = ({ children }) => {
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        >
          <GroupContextProvider>
            <JZWITContextProvider>{children}</JZWITContextProvider>
          </GroupContextProvider>
        </GoogleReCaptchaProvider>
      </AuthContextProvider>
    </Provider>
  );
};

export default ContextProviders;
