import React, { useState, useEffect } from 'react';
import styles from './EarningsSection.module.sass';

import { earningsEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import useAuth from 'hooks/useAuth';
import { useHistory, useLocation } from 'react-router';

import AverageEarnings from './AverageEarnings';
import EarningsLoader from './EarningsLoader';
import CategoryTrends from './CategoryTrends';
import ChooseCategoryBreadcrumbs from 'components/ChooseCategoryBreadcrumbs';
import Card from 'components/Card';
import cn from 'classnames';

const EarningsSection = () => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  const history = useHistory();
  const searchUrl = useLocation().search;
  const pathName = useLocation().pathname
  const isLogged = authCtx.isLoggedIn;

  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [lastSelectedCategory, setLastSelectedCategory] = useState([]);
  const [earningsData, setEarningsData] = useState([]);
  const [notLoggedIn, setNotLoggedIn] = useState(false);

  const getEarnings = async param => {
    try {
      const temp = await axiosInstance.get(
        `${earningsEndpoints.earnings}?detail=${encodeURIComponent(param)}`,
        {
          headers: {
            method: 'details',
          },
        }
      );
      setSelectedCategories(selectedCategories => [
        ...selectedCategories,
        { name: temp?.data.name, color: temp?.data.color },
      ]);
      setEarningsData(earningsData => [
        ...earningsData,
        {
          name: temp?.data.name,
          dates: temp?.data.dates,
          median: temp?.data.median,
          range: temp?.data.range,
          number_of_offers: temp?.data.number_of_offers,
          color: temp?.data.color,
        },
      ]);
    } catch (error) {
      console.log(error);
      setNotLoggedIn(true);
      setSelectedCategories(selectedCategories => [...selectedCategories, { name: param }])
    }
    setLoading(false);
    setLastSelectedCategory([]);
  };

  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck.some(item => value.name === item.name);
  };

  const selectCategory = item => {
    setLoading(true);
    setLastSelectedCategory(lastSelectedCategory => [
      ...lastSelectedCategory,
      item.name,
    ]);
    if (!checkIfArrayContains(selectedCategories, item)) {
      getEarnings(item.name);
    }
    if (checkIfArrayContains(selectedCategories, item)) {
      setTimeout(function () {
        setLoading(false);
        setLastSelectedCategory(current =>
          current.filter(name => !name.includes(item.name))
        );
      }, 500);
      setSelectedCategories(current =>
        current.filter(object => !object.name.includes(item.name))
      );
      setEarningsData(current =>
        current.filter(object => !object.name.includes(item.name))
      );
    }
  };

  const getUrlParams = async () => {
    const getAllCategories = new URLSearchParams(searchUrl).get('categories') || '';
    if (getAllCategories) {
      const categories = getAllCategories.split('&');
      await Promise.all(
        categories.map(async category => {
          getEarnings(category);
        })
      );
    }
    setCategoriesLoaded(true);
  };

  useEffect(() => {
    if (!categoriesLoaded) {
      getUrlParams();
    }
    setNotLoggedIn(false)
  }, []);

  const updateParams = (array) => {
    let params = '';
    if (array?.length > 0) {
      for (let i = 0; i < array.length; i++) {
        if (i === 0) {
          params +=
            '&categories=' + encodeURIComponent(array[i].name);
        }
        if (i > 0) {
          params += '%26' + encodeURIComponent(array[i].name);
        }
      }
    }
    history.push({
      search: params,
    });
  }

  useEffect(() => {
    if (categoriesLoaded) {
      updateParams(selectedCategories)
    }
  }, [selectedCategories]);

  return (
    <>
      <ChooseCategoryBreadcrumbs
        title={'Sprawdź zarobki w branży IT'}
        hasSearchBar
        itemClicked={selectCategory}
        lastSelectedCategory={lastSelectedCategory}
        loading={loading}
        selectedCategories={selectedCategories}
      />
      {loading ? (
        <EarningsLoader />
      ) : isLogged ? (
        <>
          {earningsData.length > 0 && <AverageEarnings data={earningsData} />}
          {earningsData.length > 0 && <CategoryTrends data={earningsData} />}
        </>
      ) : (
        <>
          {notLoggedIn && (
            <Card className={styles.notLoggedIn}>
              <p>Musisz być zalogowany, aby móc zobaczyć wyniki!</p>
              <button
                className={cn('button', styles.homebtn)}
                onClick={() => {
                  history.push('/sign-in', {
                    from: pathName + searchUrl,
                  });
                }}
              >
                Zaloguj się
              </button>
            </Card>
          )}
        </>
      )}
    </>
  );
};
export default EarningsSection;
