import React from "react";
import style from "./Skeleton.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TechnologyDetailLoader = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      // zrobic light mode
      <SkeletonTheme key={i}>
        <div className={style.skeleton} key={i}>
          {/* <div className={style.img}>
            <Skeleton width={170} height={150} borderRadius={20}/>
          </div>
          <div className={style.content}>
            <Skeleton count={5} />
          </div> */}

          {/* <div className={style.tags}>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </div> */}

          <div className={style.section}>
            <div className={style.content}>
              <Skeleton width={200} />
              <Skeleton width={150} className={style.text} />
              <Skeleton count={6} />
            </div>

            <div className={style.img}>
              <Skeleton height={250} borderRadius={20} />
            </div>
          </div>

          <div className={style.gt}>
            <div className={style.graph}>
              <Skeleton height={250} borderRadius={20} />
            </div>
            <div className={style.trends}>
              <Skeleton width={150} height={20} className={style.text} />
              <Skeleton count={6} />
            </div>
          </div>

          <div className={style.section}>
            <div className={style.content}>
              <Skeleton width={200} />
              <Skeleton width={150} className={style.text} />
              <Skeleton count={6} />
            </div>

            <div className={style.img}>
              <Skeleton height={250} borderRadius={20} />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default TechnologyDetailLoader;
