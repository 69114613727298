import React from 'react';
import styles from './TagsList.module.sass';
import cn from 'classnames';
import uuid from 'react-uuid';
import Tag from 'components/Tags/Tag';
import { useHistory } from 'react-router';

const TagsList = ({ className, tags, baseLink }) => {
  const history = useHistory();
  return (
    <div className={cn(styles.container, className && className)}>
      <div className={cn('title-blue', styles.title)}>Tagi</div>
      <ul className={styles.tags}>
        {tags?.map(item => (
          <Tag
            key={uuid()}
            name={item?.name || 'Brak nazwy'}
            //color={item?.colour || '#ffffff'}
            // onClick={() => {
            //   baseLink && history.push(`${baseLink}?tags=${item?.name}`);
            // }}
          />
        ))}
      </ul>
    </div>
  );
};

export default TagsList;
