import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import RelatedLoader from 'components/PostLoaderSkeleton/RelatedLoader';
import useAxios from 'hooks/useAxios';
import styles from './Related.module.sass';
import MiniCourse from 'components/MiniCourse';
import MiniJobOffer from 'components/MiniJobOffer';
import { jobsEndpoints, coursesEndpoints, postsEndpoints } from 'api/endpoints';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { FreeMode } from 'swiper';
// import 'swiper/scss';
import MiniPost from 'components/MiniPost';
import MiniJob from 'components/MiniJob';

const Related = ({ name, root, type }) => {
  const [url, setUrl] = useState(null);
  const [nameToViewMore, setNameToViewMore] = useState(null);
  const [routeToMap, setRouteToMap] = useState(null);
  const tag = name?.toLowerCase();

  const handleUrl = type => {
    switch (type) {
      case 'Oferty Pracy':
        setUrl(`${jobsEndpoints.show}`);
        setNameToViewMore('/jobs');
        break;
      case 'Wpisy':
        setUrl(`${postsEndpoints.show}`);
        setNameToViewMore('/posts');
        break;
      case 'Kursy':
        setUrl(`${coursesEndpoints.show}`);
        setNameToViewMore('/courses');
        break;
      default:
        console.error('nie podales typu komponentu');
    }
  };
  useEffect(() => {
    handleUrl(type);
  }, [type]);

  const [related, errorRelated, loadingRelated, GetAllRelated] = useAxios();
  const getRelated = () => {
    GetAllRelated({
      method: 'get',
      url: root
        ? `${url}?sortby=-created_at&page_size=5`
        : `${url}?tags=${tag}&sortby=-created_at&page_size=5`,
    });
  };
  useEffect(() => {
    url !== null && getRelated();
  }, [url]);

  const handleNameToMap = type => {
    switch (type) {
      case 'Oferty Pracy':
        setRouteToMap(related?.jobs);
        break;
      case 'Wpisy':
        setRouteToMap(related?.data);
        break;
      case 'Kursy':
        setRouteToMap(related?.results);
        break;
      default:
        console.error('nie podales typu komponentu');
    }
  };

  useEffect(() => {
    related.count > 0 && handleNameToMap(type);
  }, [loadingRelated]);

  // console.log(type, root);

  return (
    <div className={styles.related}>
      <header className={styles.sc_title}>
        <h4>{`Powiązane ${type}`}</h4>
        {type !== 'Oferty Pracy' && (
          <Link
            to={
              related.count === 0
                ? nameToViewMore
                : `${nameToViewMore}?sortby=newest&tags=${tag}`
            }
            className={styles.btn_more}
          >
            WIĘCEJ
          </Link>
        )}
      </header>

      {loadingRelated ? (
        <div className={styles.loader}>
          <RelatedLoader cards={4} />
        </div>
      ) : related.count !== 0 ? (
        <ul className={styles.relatedList}>
          {routeToMap?.map(slide => (
            <li key={slide.id}>
              {type === 'Oferty Pracy' && (
                <div className={styles.related_job}>
                  <MiniJob className={styles.item} recommended article={slide} />
                </div>
              )}
              {type === 'Kursy' && (
                <MiniCourse article={slide} relatedStyle={true} />
              )}
              {type === 'Wpisy' && <MiniPost article={slide} related={true} />}
            </li>
          ))}
        </ul>
      ) : (
        // <Swiper
        //   spaceBetween={15}
        //   slidesPerView='auto'
        //   freeMode
        //   centeredSlides
        //   centeredSlidesBounds
        //   modules={[FreeMode]}
        //   className={styles.swiper}
        // >
        //   {routeToMap?.map((slide, index) => (
        //     <SwiperSlide
        //       key={index}
        //       style={{ width: '25%', height: '400px' }}
        //       className={styles.slide}
        //     >
        //       {type === 'Oferty Pracy' &&  <MiniJobOffer key={slide.id} article={slide} relatedStyle={true} />}
        //       {type === 'Kursy' && <MiniCourse key={slide.id} article={slide} relatedStyle={true} />}
        //       {type === 'Wpisy' && <MiniPost key={index} article={slide} related={true} />}

        //     </SwiperSlide>
        //   ))}
        // </Swiper>
        <h4 className={styles.isEmpty}>BRAK WYNIKÓW</h4>
      )}
    </div>
  );
};

export default Related;
