export const prepareChartData = data => ({
  total: [
    {
      name: 'Ilość',
      value: data.total?.amount,
    },
    {
      name: 'Komentarze',
      value: data.total?.comments,
    },
    {
      name: 'Like',
      value: data.total?.likes,
    },
    {
      name: 'Dislike',
      value: data.total?.dislikes,
    },
    {
      name: 'Reakcje',
      value: data.total?.reactions,
    },
  ],
  posts: [
    {
      name: 'Ilość',
      value: data.posts?.amount,
    },
    {
      name: 'Komentarze',
      value: data.posts?.comments,
    },
    {
      name: 'Like',
      value: data.posts?.likes,
    },
    {
      name: 'Dislike',
      value: data.posts?.dislikes,
    },
    {
      name: 'Reakcje',
      value: data.posts?.reactions,
    },
  ],
  courses: [
    {
      name: 'Ilość',
      value: data.courses?.amount,
    },
    {
      name: 'Komentarze',
      value: data.courses?.comments,
    },
    {
      name: 'Like',
      value: data.courses?.likes,
    },
    {
      name: 'Dislike',
      value: data.courses?.dislikes,
    },
    {
      name: 'Reakcje',
      value: data.courses?.reactions,
    },
  ],
});
