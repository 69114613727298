import React, { useEffect, useState } from 'react';
import styles from './JZWIT_Comments.module.sass';
import cn from 'classnames';
import Comment from 'components/Comment';
import { showError } from 'utils/showError';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import axios from 'axios';
import { showLoginModal } from 'utils/showLoginModal';
import { validateInput } from 'utils/validation';
import { maxCommentLength } from 'data/dataArrays';
import { jzwitv2Endpoints } from 'api/endpoints';
import Button from 'components/Button';

const MAX_COMMENT_LENGTH = maxCommentLength;

const addComment = jzwitv2Endpoints.addComment

const showComments = jzwitv2Endpoints.showComments

const JZWIT_Comments = ({ className, id, type }) => {

    const authCtx = useAuth();
    const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

    const [comments, setComments] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getUrl = showComments ?? '';
    const sendUrl = addComment ?? '';

    const [value, setValue] = useState('');

    const getComments = async cancelToken => {
        let headers = { 'technology-id': id };
        try {
            const response = await axiosInstance.request({
                method: 'get',
                url: getUrl,
                cancelToken: cancelToken.token,
                headers: headers,
            });
            setComments(response?.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        getComments(cancelToken);

        return () => {
            cancelToken.cancel();
        };
    }, [id, refresh]);

    const validation = () => {
        if (!authCtx.isLoggedIn) {
            showLoginModal();
            return false;
        }

        if (!validateInput(value, MAX_COMMENT_LENGTH, 2, 'Komentarz', 'text'))
            return false;

        return true;
    };

    const onValueChange = value => {
        const allowedCharacters =
            /^[a-zA-Z0-9ĄąĆćĘęŁłŃńÓóŚśŹźŻż \p{L}\n"':!@#$%\^&*\(\)+,.?\-]+$/;

        if (value.length > 0) {
            if (!allowedCharacters.test(value[value.length - 1])) {
                return;
            }
        }
        setValue(value);
    };

    const sendComment = async comment => {
        setIsLoading(true);
        if (!sendUrl.length > 0) return;

        let data = { object: id, text: value };
        try {
            const response = await devscntrAuth.post(sendUrl, data);
            setRefresh(prev => !prev);
            setValue('');
            authCtx.getLevel();
        } catch (error) {
            showError('Nie udało się wysłać komentarza. Spróbuj ponownie później.');
        }
        setIsLoading(false);
    };

    const handleSubmit = () => {
        if (validation()) sendComment();
    };

    return (
        <div className={cn(styles.container, className && className)}>
            <div className={styles.head}>
                <div className={styles.comments_count}>
                    {comments?.length > 0 ? comments?.length : '0'}
                </div>
                Komentarze
            </div>
            <div className={styles.field}>
                <textarea
                    value={value}
                    onChange={e => onValueChange(e.target.value)}
                    className={cn(
                        styles.textarea,
                        value.length > MAX_COMMENT_LENGTH && styles.error
                    )}
                    placeholder='Podziel się swoją opinią...'
                />
                <div
                    className={cn(
                        styles.counter,
                        value.length > MAX_COMMENT_LENGTH && styles.error
                    )}
                >
                    {value.length}/{MAX_COMMENT_LENGTH}
                </div>
            </div>
            <Button
                classNames={styles.button}
                isLoading={isLoading}
                text='Wyślij'
                onClick={handleSubmit}
            />
            <div className={styles.list}>
                {comments?.map((item, index) => (
                    <Comment
                        key={`comment_${item.id}_${index}`}
                        className={styles.item}
                        item={item}
                        onComment={() => setRefresh(prev => !prev)}
                        type={type}
                    />
                ))}
            </div>
        </div>
    );
};

export default JZWIT_Comments;