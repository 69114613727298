import React, { useState, useEffect } from "react";
import devscntrAuth from "../../../../../api/Instance/devscntrAuth";
import { adminEndpoints } from "../../../../../api/endpoints";
import axios from "axios";
import BanModal from "../../../../../components/BanModal";
import UnbanModal from "../../../../../components/UnbanModal";
import styles from "./BanList.module.sass"
import cn from "classnames";

const BanList = ({banList, user}) => {

    const [ banModal, setBanModal ] = useState(false);
    const [ unbanModal, setUnbanModal ] = useState(false);

    const [ banElements, setBanElements ] = useState([])

    useEffect(()=>{
        if(banList?.data?.length > 0){
            const banListElements = banList.data.map((item, index) => {
                const bannedAt = new Date(item.banned_at)
                const expirationDate = new Date(item.expiration_date)

                return (
                    <li className={styles.item} key={index}>
                        <span className={styles.date}>
                            {bannedAt.toLocaleDateString()} - {expirationDate.toLocaleDateString()}
                        </span>
                        <span className={styles.reason}>{item.reason}</span>
                    </li>
                )
            })
            setBanElements(banListElements);
        }
    }, [ banList ])


    return (
        <div className={styles.container}>
            <p><b>Status:</b> {user?.is_banned ? <span className={styles.banned}>Zablokowany</span> :
                <span className={styles.active}>Aktywny</span>}</p>
            <button className={cn('button', (user.is_banned ? styles.btnunban : styles.btnban))} onClick={() => {
                if (!user.is_banned)
                    setBanModal(true)
                else
                    setUnbanModal(true)
            }}>{user?.is_banned ? "Odblokuj" : "Zablokuj"}</button>

            <p><b>Historia blokad:</b></p>
            <ul className={styles.list}>
                {banList?.data?.length > 0 && banElements}
            </ul>

            <BanModal userID={user?.id} visible={banModal} setVisible={setBanModal}/>
            <UnbanModal userId={user?.id} visible={unbanModal} setVisible={setUnbanModal}/>
        </div>
    )
}

export default BanList;