import React from 'react';
import styles from './SocialInputs.module.sass';
import cn from 'classnames';
import TextInput from 'components/TextInput';

const SocialInputs = ({ className, data, setData }) => {
  // Handle inputs changes
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className={cn(styles.container, { [className]: className })}>
      <TextInput
        className={styles.field}
        label='Facebook'
        name='facebook_url'
        placeholder='Podaj nazwę konta facebook albo url do niego'
        type='text'
        icon='facebook-fill'
        value={data.facebook_url}
        onChange={handleChange}
      />
      <TextInput
        className={styles.field}
        label='Twitter'
        name='twitter_url'
        type='text'
        placeholder='Podaj nazwę konta twitter albo url do niego'
        icon='twitter-fill'
        value={data.twitter_url}
        onChange={handleChange}
      />
      <TextInput
        className={styles.field}
        label='Github'
        name='github_url'
        type='text'
        icon='github'
        placeholder='Podaj nazwę konta github albo url do niego'
        value={data.github_url}
        onChange={handleChange}
      />
      <TextInput
        className={styles.field}
        label='LinkedIn'
        name='linkedin_url'
        type='text'
        icon='linkedin'
        value={data.linkedin_url}
        placeholder='Podaj nazwę konta linkedin albo url do niego'
        onChange={handleChange}
      />
      <TextInput
        className={styles.field}
        label='YouTube'
        name='youtube_url'
        type='text'
        icon='youtube'
        placeholder='Podaj nazwę konta youtube albo url do niego'
        value={data.youtube_url}
        onChange={handleChange}
      />
    </div>
  );
};

export default SocialInputs;
