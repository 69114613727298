import React, { useEffect, useState } from "react";
import styles from "./Item.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";
import Controls from "./Controls";
import { BsType } from "react-icons/bs";

const articleLinks = {
  post: "/post",
  course: "/course",
  job: "/jobs",
  user: "/profile",
};

const Item = ({ article, refresh }) => {
  const [data, setData] = useState(null);

  let type;
  if (article.post !== undefined) type = "post";
  if (article.course !== undefined) type = "course";
  if (article.job !== undefined) type = "job";
  if (article.comment !== undefined) type = "comment";
  if (article.reporteduser !== undefined) type = "user";

  useEffect(() => {
    if (article && type) {
      let specifiedData;

      switch (type) {
        case "post":
          specifiedData = {
            author_id: article.post.author || null,
            author_display_name: article.post.display_name || "Brak nazwy",
            article_id: article.post.id || null,
            article_title: article.post.title || "Brak tytułu",
            method: "posts",
          };
          break;

        case "course":
          specifiedData = {
            author_id: article.course.author || null,
            author_display_name: article.course.display_name || "Brak nazwy",
            article_id: article.course.id || null,
            article_title: article.course.title || "Brak tytułu",
            method: "courses",
          };
          break;

        case "job":
          specifiedData = {
            author_id: article.job.author_id || null,
            author_display_name: article.job.display_name || "Brak nazwy",
            article_id: article.job.id || null,
            article_title: article.job.title || "Brak tytułu",
            method: "jobs",
          };
          break;

        case "comment":
          specifiedData = {
            author_display_name: article.comment.user_name || "Brak nazwy",
            article_id: article.comment.to_post ? article.comment.post_id : article.comment.course_id,
            article_path: article.comment.to_post ? articleLinks.post : articleLinks.course,
            article_title: article.comment.text || "Brak treści komentarza",
            method: article.comment.to_post ? "postscomms" : "coursescomms",
          };
          break;

        case "user":
          specifiedData = {
            author_id: article.reporteduser.id || null,
            author_display_name: article.reporteduser.display_name || "Brak nazwy",
            article_id: article.reporteduser.id,
            article_title: `${article.reporteduser.display_name}, ${article.reporteduser.username}` || "Brak nazwy",
            method: "users",
          };
          break;

        default:
          specifiedData = false;
          break;
      }
      if (specifiedData) {
        setData({
          created_at: article.created_at || null,
          id: article.id || null,
          author_id: article.author || null,
          title: article.title || "Brak powodu",
          description: article.description || "Brak opisu",
          article_path: articleLinks[type] || null,
          type: type || null,
          ...specifiedData,
        });
      }
    }
  }, [article]);

  return (
    data && (
      <div className={styles.item}>
        <div className={styles.container}>
          <div className={styles.head}>
            <Link to={`/admin/users/${data.author_id}`} className={styles.author}>
              Autor treści: {data.author_display_name}
            </Link>
            <div className={styles.time}>{data.created_at.slice(0, 10)}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.title}>
              Powód: {data.title}
              {article.is_solved ? (
                <div className={cn("status-green", styles.status)}>rozwiązane</div>
              ) : (
                <div className={cn("status-yellow", styles.status)}>oczekujące</div>
              )}
            </div>
            <Link to={`${data.article_path}/${data.article_id}`} className={styles.article_title}>
              <div className={cn("status-blue", styles.status)}>{data.type}</div> {data.article_title}
            </Link>
            {data.description && (
              <div className={styles.description}>
                <b>Opis zgłoszenia:</b>
                <br />
                {data.description}
              </div>
            )}
          </div>
        </div>
        <Controls id={data.id} type={data.type} method={data.method} refresh={refresh} />
      </div>
    )
  );
};
export default Item;
