import React, { useEffect, useState } from 'react';
import styles from './BigFiveShareSettings.module.sass';
import Card from 'components/Card';
import Switch from 'components/Switch';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { b5TestEndpoints } from 'api/endpoints';

const BigFiveShareSettings = ({ testId, data }) => {
  const authCtx = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [share, setShare] = useState(data?.public || false);

  const updateShareSettings = async isSharing => {
    const payload = {
      id: testId,
      public: isSharing,
    };

    setIsLoading(true);
    try {
      await devscntrAuth.post(b5TestEndpoints.shareSettings, payload);
      setShare(isSharing);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setShare(data?.public);
  }, [data?.public]);

  if (!testId || !data?.user) return null;
  if (+data?.user?.id !== +authCtx.userID) return null;

  return (
    <Card title={'Udostępnianie'} classTitle={'title-purple'}>
      <div className={styles.switchWrapper}>
        <Switch
          value={share}
          onChange={e => updateShareSettings(e?.target?.checked || false)}
          isLoading={isLoading}
        />
        <label className={styles.label}>
          Zezwalaj na widoczność tego wyniku dla innych osób
        </label>
        <span className={styles.info}>
          {share
            ? 'Twój wynik jest teraz widoczny dla innych użytkowników'
            : 'Twój wynik jest prywatny'}
        </span>
      </div>
    </Card>
  );
};

export default BigFiveShareSettings;
