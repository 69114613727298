import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './AddButton.module.sass';
import Icon from '../../Icon';
import Modal from '../../Modal';
import { getEnvName } from 'utils';
import useAuth from 'hooks/useAuth';

const AddButton = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const authCtx = useAuth();

  return (
    <div
      className={cn(styles.notification, className, {
        [styles.active]: visible,
      })}
    >
      <button
        className={cn('button', styles.button, styles.active)}
        onClick={() => setVisible(!visible)}
      >
        <Icon name='add' size='24' />
      </button>
      <Modal
        outerClassName={styles.modal}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      >
        <div className={styles.body}>
          <h2>Dodaj</h2>

          <ul className={styles.addList}>
            <Link
              className={styles.element}
              to='/new-course'
              onClick={() => setVisible(false)}
            >
              <Icon name='store' size={24} className={styles.icon} />
              Kurs
            </Link>
            
            <Link
              className={styles.element}
              to='/new-post'
              onClick={() => setVisible(false)}
            >
              <Icon name='home' size={24} className={styles.icon} />
              Wpis
            </Link>


            {getEnvName() !== 'prod' && authCtx.isAdmin && (
              <Link
                className={styles.element}
                to='/new-job'
                onClick={() => setVisible(false)}
              >
                <Icon name='job' size={24} className={styles.icon} />
                Oferta pracy
              </Link>
            )}

            {authCtx.isAdmin && (
              <Link
                className={styles.element}
                to='/new-technology'
                onClick={() => setVisible(false)}
              >
                <Icon name='desktop' size={24} className={styles.icon} />
                Nowa technologia
              </Link>
            )}
          </ul>
        </div>
      </Modal>

      {/* <Link
            className={cn("button", styles.button)}
            to="/new-post"
            onClick={() => setVisible(false)}
          >
            Wpis
          </Link>
          <Link
            className={cn("button", styles.button)}
            to="/new-course"
            onClick={() => setVisible(false)}
          >
            Kurs
          </Link>
          <Link
            className={cn("button", styles.button)}
            to="/new-job"
            onClick={() => setVisible(false)}
          >
            Oferta pracy
          </Link>
          <Link
            className={cn("button", styles.button)}
            to="/new-job"
            onClick={() => setVisible(false)}
          >
            Nowa technologia
          </Link> */}
    </div>
  );
};

export default AddButton;
