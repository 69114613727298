import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import gt from '../../NewTechnology.module.sass';
import styles from './Section.module.sass';
import useCollapse from 'react-collapsed';
import Editor from '../../../../components/Editor';
import File from '../../../../components/File';
import TextInput from '../../../../components/TextInput';
import ImageInput from 'screens/NewLayouts/ImageInput';
import useAuth from 'hooks/useAuth';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import Icon from 'components/Icon';
import ModalLayoutImage from './ModalLayoutImage';
import ModalRemove from './ModalRemove';
import EditorTiny from 'components/EditorTiny';

const Section = ({
  isTrend,
  form,
  formFields,
  setFormFields,
  index,
  onDelete,
  itsEdit,
}) => {
  const authCtx = useAuth();
  const [isExpanded, setExpanded] = useState(true);
  const [visibleRemove, setVisibleRemove] = useState(false);
  const [visibleLayout, setVisibleLayout] = useState(false);
  const [image, setImage] = useState(form.image);
  const [editorState, setEditorState] = useState(form?.text);

  const [data, setData] = useState(null);
  const [title, setTitle] = useState(form.title);
  const [subtitle, setSubtitle] = useState(form.subtitle);
  const [side, setSide] = useState(form.side);

  useEffect(() => {
    setTitle(form.title);
    setSubtitle(form.subtitle)
    setEditorState(form?.text);
  }, [itsEdit]);

  const updateTitle = (value, i) => {
    const temp = formFields.map((obj, index) => {
      if (index === i) {
        return { ...obj, title: value}
      }
      return obj
    })
    setFormFields(temp)
  }

  const updateSubTitle = (value, i) => {
    const temp = formFields.map((obj, index) => {
      if (index === i) {
        return { ...obj, subtitle: value }
      }
      return obj
    })
    setFormFields(temp)
  }

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const submitRemove = () => {
    setVisibleRemove(prev => !prev);
  };

  const handleLayout = () => {
    setVisibleLayout(prev => !prev);
  };

  const handleEditorState = editorState => {
    setEditorState(editorState);
    const temp = formFields.map((obj, i) => {
      if (i === index) {
        return { ...obj, text: editorState }
      }
      return obj
    })
    setFormFields(temp)
  };

  useEffect(() => {
    setEditorState(form?.text)
  }, [form?.text]);
  
  return (
    <div className={gt.container}>
      <div className={gt.sc_title}>
        {isTrend ? (
          <h4>Trend {index + 1}</h4>
        ):(
          <h4>{form.title}</h4>
        )}
        
        <div className={styles.group_btn}>
            <button onClick={submitRemove} className={styles.btn_remove}>
              <Icon name='trash' size='20' />
            </button>
          <button
            className={gt.btn_ce}
            {...getToggleProps({
              onClick: () => setExpanded(prevExpanded => !prevExpanded),
            })}
          >
            {<Icon name='arrows-up-down' size='20' />}
          </button>
          <ModalLayoutImage
            visible={visibleLayout}
            onClose={() => setVisibleLayout(false)}
            setSide={setSide}
            side={side}
          ></ModalLayoutImage>
          <ModalRemove
            visible={visibleRemove}
            onClose={() => setVisibleRemove(false)}
            onDelete={onDelete}
            setVisibleRemove={setVisibleRemove}
          ></ModalRemove>
        </div>
      </div>

      <div {...getCollapseProps()}>
        <div className={gt.ce_content}>
        {!isTrend?(
          <>
            <TextInput
              className={styles.field}
              label='Tytuł sekcji'
              name='title'
              type='text'
              onTitleChange={e => updateTitle(e.target.value,index)}
              value={form.title}
            />
            <TextInput
              className={styles.field}
              label='Pod Tytuł sekcji'
              name='subtitle'
              type='text'
              onTitleChange={e => updateSubTitle(e.target.value,index)}
              value={form.subtitle}
            />
            <EditorTiny
              state={editorState}
              setState={handleEditorState}
              label='Opis'
            />
            </>
          ):(
            <>
              <TextInput
                className={styles.field}
                label='Tytuł trendu'
                name='title'
                type='text'
                onTitleChange={e => updateTitle(e.target.value,index)}
                value={form.title}
              />
              <TextInput
                className={styles.field}
                label='Pod Tytuł trendu'
                name='subtitle'
                type='text'
                onTitleChange={e => updateSubTitle(e.target.value,index)}
                value={form.subtitle}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Section;
