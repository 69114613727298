import React from 'react';
import styles from './BigFiveUserInfo.module.sass';
import cn from 'classnames';
import Card from 'components/Card';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

const BigFiveUserInfo = ({ user }) => {
  if (!user) return null;
  return (
    <Card className={styles.container}>
      <div className={styles.user}>
        <img
          className={styles.avatar}
          src={user?.image || '/images/default-avatar.png'}
          alt={user?.display_name}
        />
        {user?.display_name || 'Brak nazwy'}
      </div>

      <Link to={`/profile/${user?.id}`}>
        <Button classNames={cn('button-stroke', styles.button)}>
          Zobacz profil
        </Button>
      </Link>
    </Card>
  );
};

export default BigFiveUserInfo;
