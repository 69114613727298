import React from 'react';
import styles from './AboutMeLanguages.module.sass';
import cn from 'classnames';

const AboutMeLanguages = ({ languages }) => {
  const levels = {
    1: 'Początkujący',
    2: 'Podstawowy',
    3: 'Średnio zaawansowany',
    4: 'Ponad średnio zaawansowany',
    5: 'Zaawansowany',
    6: 'Biegły',
  };

  return (
    <div className={styles.container}>
      <div className={cn('title-darkBlue', styles.header)}>Języki obce</div>
      {languages?.length > 0 ? (
        <div className={styles.languages_list}>
          {languages?.map((item, index) => (
            <div className={styles.language_container} key={index}>
              <div className={styles.image}>
                <img
                  src={
                    languages?.image ? languages?.image : '/images/Language.png'
                  }
                  alt='Language'
                />
              </div>
              <div className={styles.details}>
                <div className={styles.language_name}>{item?.language}</div>
                <div className={styles.language_level}>
                  {item?.level ? levels[item?.level] : 'Brak poziomu'}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.no_information}>Brak informacji o językach.</div>
      )}
    </div>
  );
};

export default AboutMeLanguages;
