import React, { useEffect, useState } from 'react';
import styles from './FollowUserButton.module.sass';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { showError } from 'utils/showError';
import { showLoginModal } from 'utils/showLoginModal';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { accountsEndpoints } from 'api/endpoints';
import Loader from 'components/Loader';

const FollowUserButton = ({ classNames, userId, refresh }) => {
  // UTILS---------------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // UTILS---------------------------------------------------------------------------------UTILS
  // GET CURRENT FOLLOWS-----------------------------------------------------GET CURRENT FOLLOWS

  const getMyFollows = async () => {
    setIsLoading(true);
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: accountsEndpoints.userData.followUser,
      });
      const followedId = response.data.map(item => parseInt(item.id));
      setIsFollowed(followedId.includes(parseInt(userId)));
    } catch (error) {
      showError('Nie udało się pobrać listy obserwowanych użytkowników.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    authCtx.isLoggedIn && getMyFollows();
  }, []);

  // GET CURRENT FOLLOWS-----------------------------------------------------GET CURRENT FOLLOWS
  // FOLLOW USER---------------------------------------------------------------------FOLLOW USER

  const sendFollowState = async () => {
    try {
      const response = await devscntrAuth.post(
        accountsEndpoints.userData.followUser,
        {
          id: userId,
        },
        {
          headers: {
            method: !isFollowed ? 'unfollow' : 'follow',
          },
        }
      );
      refresh && refresh();
      authCtx.updateFollows(userId);
      authCtx.getLevel();
    } catch (error) {
      showError(
        'Nie udało się zmienić obserwacji użytkownika. Spróbuj ponownie później.'
      );
    }
  };

  useEffect(() => {
    if (isMounted && !isLoading) {
      sendFollowState();
    } else {
      setIsMounted(true);
    }
  }, [isFollowed]);

  const toggleFollow = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    setIsFollowed(prev => !prev);
  };

  // FOLLOW USER---------------------------------------------------------------------FOLLOW USER

  return authCtx.userID != userId ? (
    <button
      className={cn(
        `button${isFollowed && '-stroke'}`,
        styles.button,
        isLoading && styles.disabled,
        classNames && classNames
      )}
      onClick={toggleFollow}
      disabled={isLoading}
    >
      {isLoading && <Loader className={styles.loader} />}
      {isFollowed ? 'Obserwujesz' : 'Obserwuj'}
    </button>
  ) : (
    <Link to={`/profile/${authCtx.userID}`} className={cn('button-stroke')}>
      Twój profil
    </Link>
  );
};
export default FollowUserButton;
