import React, { useEffect, useState, useContext } from 'react';
import styles from './DynamicSection.module.sass';
import Section from './Section/Section';
import _ from 'lodash';
import FormContext from 'context/FormContext';
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';

const DynamicSection = ({ editDynamicSectionData }) => {
  const formCtx = useContext(FormContext);
  const [formFields, setFormFields] = useState([]);
  const [sectionsArray, setSectionsArray] = useState([]);
  const [removedSections, setRemovedSections] = useState([])

  //DND
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(formFields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let temp = []
    items.forEach((element,index) => {
      temp.push({...element,position: index + 1})
    });
    setFormFields(temp);
  }

  // ON SUBMIT
  const submit = e => {
    e.preventDefault();
  };

  useEffect(() => {
    setSectionsArray(editDynamicSectionData);
  }, [editDynamicSectionData]);

  // ADD FORM ON RENDER
  useEffect(() => {
    // if (editDynamicSectionData === undefined) {
    addFields();
    // }
  }, []);

  // ADD ANOTHER FORM
  const addFields = () => {
    let object = {
      id: '',
      title: '',
      subtitle: '',
      text: '',
      side: false,
      position: formFields?.length + 1,
    };
    setFormFields([...formFields, object]);
  };

  const handleSectionDelete = id => {
    setFormFields(item => {
      setRemovedSections([...removedSections, item.filter((_, i) => i === id)])
      return item.filter((_, i) => i !== id)
    })
  };

  useEffect(() => {
    formCtx.setDeletedSections(removedSections);
  }, [removedSections]);

  // HANDLE FORM CONTEXT CHANGE
  useEffect(() => {
    formCtx.setDynamicSectionsData(formFields);
  }, [formFields]);

  useEffect(() => {
    editDynamicSectionData && setFormFields(editDynamicSectionData);
  }, [editDynamicSectionData]);

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="formFields">
          {(provided) => (
            <form {...provided.droppableProps} className={styles.form} onSubmit={submit} ref={provided.innerRef}>
              {formFields?.map((section, index) => {
                return (
                  <Draggable key={`draggable-${index}`} draggableId={`draggable-${index}`} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Section
                          key={`draggable-${index}`}
                          form={section}
                          index={index}
                          formFields={formFields}
                          setFormFields={setFormFields}
                          onDelete={() => handleSectionDelete(index)}
                          itsEdit={sectionsArray}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </form>
          )}
        </Droppable>
      </DragDropContext >

      <button onClick={() => addFields()} className={styles.btn_add}>
        Dodaj sekcje
      </button>
    </>
  );
};

export default DynamicSection;
