import { FilterOptionName, FilterOptionValue, SortOptionName, SortOptionValue, SortOptionNameCodes, SortOptionValueCodes, FilterOptionJZWIT } from "./names";
import { getEnvName } from "utils";

export const sortOptions = [
  {
    name: SortOptionName.LATEST,
    value: SortOptionValue.LATEST,
  },
  {
    name: SortOptionName.ELDEST,
    value: SortOptionValue.ELDEST,
  },
  {
    name: SortOptionName.STATUS,
    value: SortOptionValue.STATUS,
  },
  {
    name: SortOptionName.VIEWS_DESC,
    value: SortOptionValue.VIEWS_DESC,
  },
  {
    name: SortOptionName.VIEWS,
    value: SortOptionValue.VIEWS,
  },
  {
    name: SortOptionName.LEADS_DESC,
    value: SortOptionValue.LEADS_DESC,
  },
  {
    name: SortOptionName.LEADS,
    value: SortOptionValue.LEADS,
  },
  {
    name: SortOptionName.LIKES_DESC,
    value: SortOptionValue.LIKES_DESC,
  },
  {
    name: SortOptionName.LIKES,
    value: SortOptionValue.LIKES,
  },
  {
    name: SortOptionName.RATING_DESC,
    value: SortOptionValue.RATING_DESC,
  },
  {
    name: SortOptionName.RATING,
    value: SortOptionValue.RATING,
  },
];

export const sortOptionsCodes = [
  {
    name: SortOptionNameCodes.LATEST,
    value: SortOptionValueCodes.LATEST,
  },
  {
    name: SortOptionNameCodes.ELDEST,
    value: SortOptionValueCodes.ELDEST,
  },
  {
    name: SortOptionNameCodes.NAME,
    value: SortOptionValueCodes.NAME,
  },
  {
    name: SortOptionNameCodes.NAME_DESC,
    value: SortOptionValueCodes.NAME_DESC,
  },
  {
    name: SortOptionNameCodes.ABSOLUTE,
    value: SortOptionValueCodes.ABSOLUTE,
  },
  {
    name: SortOptionNameCodes.ABSOLUTE_DESC,
    value: SortOptionValueCodes.ABSOLUTE_DESC,
  },
  {
    name: SortOptionNameCodes.PERCENTAGE,
    value: SortOptionValueCodes.PERCENTAGE,
  },
  {
    name: SortOptionNameCodes.PERCENTAGE_DESC,
    value: SortOptionValueCodes.PERCENTAGE_DESC,
  },
];

export const filterOptionsType = [
  {
    name: FilterOptionName.ALL,
    value: FilterOptionValue.ALL,
  },
  {
    name: FilterOptionName.POSTS,
    value: FilterOptionValue.POSTS,
  },
  {
    name: FilterOptionName.COURSES,
    value: FilterOptionValue.COURSES,
  },

  {
    name:  getEnvName() !== 'prod'&& FilterOptionName.JOBS,
    value: getEnvName() !== 'prod'&& FilterOptionValue.JOBS,
  },

];

export const filterOptionsStatus = [
  {
    name: FilterOptionName.ALL,
    value: FilterOptionValue.ALL,
  },
  {
    name: FilterOptionName.PUBLISHED,
    value: FilterOptionValue.PUBLISHED,
  },
  {
    name: FilterOptionName.SCHEDULED,
    value: FilterOptionValue.SCHEDULED,
  },
  {
    name: FilterOptionName.DRAFTS,
    value: FilterOptionValue.DRAFTS,
  },
];

export const jzwitSortingOptions = 
[
  {
    name: FilterOptionJZWIT.ALL,
    value: FilterOptionJZWIT.ALL,
  },
  {
    name: FilterOptionJZWIT.JUNIOR,
    value: FilterOptionJZWIT.JUNIOR,
  },
  {
    name: FilterOptionJZWIT.MID,
    value: FilterOptionJZWIT.MID,
  },
  {
    name: FilterOptionJZWIT.SENIOR,
    value: FilterOptionJZWIT.SENIOR,
  },
  {
    name: FilterOptionJZWIT.SAVE,
    value: FilterOptionJZWIT.SAVE,
  },
]

export const pageLimitOptions = [10, 25, 50];