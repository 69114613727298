import React from 'react';
import styles from './AboutMeInterests.module.sass';
import cn from 'classnames';

const AboutMeInterests = ({ interests }) => {
  return (
    <div className={styles.container}>
      <div className={cn('title-purple', styles.header)}>Zainteresowania</div>
      {interests?.length > 0 ? (
        <div className={styles.interests_list}>
          {interests?.map((item, index) => (
            <div className={styles.interest_container} key={index}>
              <div className={styles.interest_name}>{item?.name}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.no_information}>
          Brak informacji o zainteresowaniach.
        </div>
      )}
    </div>
  );
};

export default AboutMeInterests;
