import React, { useEffect, useState } from 'react';
import styles from './Reactions.module.sass';
import cn from 'classnames';
import Icon from 'components/Icon';
import axios from 'axios';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import { showLoginModal } from 'utils/showLoginModal';
import { coursesEndpoints, postsEndpoints } from 'api/endpoints';
import { emotionChangeDelay } from 'data/dataArrays';
import { Link } from 'react-router-dom';

// REACTION ENDPOINTS ASSIGNED TO TYPE
const reactionEndpoints = {
  post: postsEndpoints.reaction,
  course: coursesEndpoints.reaction,
};

const Reactions = ({
  className,
  type,
  id,
  comments,
  rating,
  rated,
  currentReaction,
  likes,
  dislikes,
  ratingLink,
  commentsLink,
  refresh,
}) => {
  // AUTH CONTEXT
  const authCtx = useAuth();

  const reactionUrl = reactionEndpoints[type] ?? '';
  const [isMounted, setIsMounted] = useState(false);
  const [allowClick, setAllowClick] = useState(true);

  const [reaction, setReaction] = useState(currentReaction || 0);
  const [emotions, setEmotions] = useState({
    likes: likes || 0,
    dislikes: dislikes || 0,
  });

  // CHANGE REACTION
  const handleReaction = newReaction => {
    // CHECK IF USER IS LOGGED IN
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    // CHECK IF REACTION IS NOT THE SAME
    if (reaction == newReaction) return;

    // ANTI-SPAM
    if (!allowClick) return;
    setAllowClick(false);
    setTimeout(() => setAllowClick(true), emotionChangeDelay);

    // SET NEW REACTION, SET NUMBERS
    setReaction(newReaction);
    if (currentReaction == 1) {
      setEmotions({
        likes: newReaction === 1 ? likes : likes - 1,
        dislikes: newReaction === 2 ? dislikes + 1 : dislikes,
      });
    } else if (currentReaction == 2) {
      setEmotions({
        likes: newReaction === 1 ? likes + 1 : likes,
        dislikes: newReaction === 2 ? dislikes : dislikes - 1,
      });
    } else {
      setEmotions({
        likes: newReaction === 1 ? likes + 1 : likes,
        dislikes: newReaction === 2 ? dislikes + 1 : dislikes,
      });
    }
  };

  // API CALL
  const sendReaction = async () => {
    // SET JSON ADAPTED TO TYPE
    let data = {};
    if (type === 'post') data = { post: id, emotion: reaction };
    if (type === 'course') data = { course_id: id, emotion: reaction };

    // SEND
    try {
      const response = await devscntrAuth.post(reactionUrl, data);
      authCtx.getLevel();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // CALL API ON REACTION CHANGE
  useEffect(() => {
    if (isMounted) {
      sendReaction();
    } else {
      setIsMounted(true);
    }
  }, [reaction]);

  return (
    <div className={cn(styles.container, className)}>
      {rating !== undefined &&
        (ratingLink ? (
          <Link to={ratingLink} className={styles.option}>
            <img
              src={`/images/Icons/star/${rated ? 'filled.svg' : 'light.svg'}`}
              alt='opinions'
            />
            <span>{rating?.toFixed(1) || 0}</span>
          </Link>
        ) : (
          <div className={styles.option}>
            <img
              src={`/images/Icons/star/${rated ? 'filled.svg' : 'light.svg'}`}
              alt='opinions'
            />
            <span>{rating?.toFixed(1) || 0}</span>
          </div>
        ))}

      {comments !== undefined &&
        (commentsLink ? (
          <Link to={commentsLink} className={styles.option}>
            <Icon name='message' size={20} />
            <span>{comments || '0'}</span>
          </Link>
        ) : (
          <div className={styles.option}>
            <Icon name='message' size={20} />
            <span>{comments || '0'}</span>
          </div>
        ))}

      {likes !== undefined && (
        <button className={styles.option} onClick={() => handleReaction(1)}>
          <Icon
            name={reaction == 1 ? 'thumbs-up-fill' : 'thumbs-up'}
            size={20}
            viewBox='0 0 24 24'
          />
          <span>{emotions?.likes || 0}</span>
        </button>
      )}

      {dislikes !== undefined && (
        <button className={styles.option} onClick={() => handleReaction(2)}>
          <Icon
            name={reaction == 2 ? 'thumbs-down-fill' : 'thumbs-down'}
            size={20}
            viewBox='0 0 24 24'
          />
          <span>{emotions?.dislikes || 0}</span>
        </button>
      )}
    </div>
  );
};

export default Reactions;
