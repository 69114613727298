import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

import Icon from "../../../../../components/Icon";
import { Link } from "react-router-dom";
import Control from "../Control";

import devscntrAuth from "../../../../../api/Instance/devscntrAuth";
import useAuth from "../../../../../hooks/useAuth";

const Item = ({ className, item, type, isAllRead }) => {
  const [visible, setVisible] = useState(false);
  const [currentValue, setCurrentValue] = useState("");
  const [replied, setReplied] = useState(false);
  const [isRead, setIsRead] = useState(false)

  const authCtx = useAuth();

  const date = new Date(item.created_at);

  const setAsRead = () => {
    if (!isRead && !isAllRead) {
      devscntrAuth
          .delete(`/accounts/me/notification/`, {
            headers: {
              TYPE: type === "commentPost" ? "comment_post" : "comment_course",
              ID: item.id,
            },
          })
          .then(() => {
            setIsRead(true);
          });
    }
  };

  const sendReply = () => {
    devscntrAuth
      .post("/posts/article/comment/", {
        text: currentValue,
        commentID: item.id,
      })
      .then(() => {
        setCurrentValue("");
        setReplied(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={cn(styles.item, { [styles.new]: !isRead && !isAllRead }, className)}>
      <div
          className={cn(styles.isread, { [styles.new]: !isRead && !isAllRead })}
          onClick={setAsRead}
      >
        {
          !isRead && !isAllRead
              ? "Nowe"
              : "Przeczytano"
        }
      </div>
      <div className={styles.avatar}>
        <img
          src={
            item.user_image
              ? item.user_image
              : "/images/default-avatar.png"
          }
          alt="Avatar"
        />
        <div className={styles.icon}>
          <Icon name="message" />
        </div>
      </div>
      <div className={styles.details}>
        <div className={styles.line}>
          <div className={styles.subtitle}>
            {item.user_displayname}
            <p> skomentował
                <Link
                  className={styles.link}
                  to={
                    item.post_id
                      ? `/post/${item.post_id}`
                      : `/course/${item.course_id}`
                  }
                >
                  {item.post_id ? "Twój post" : "Twój kurs"}
                </Link>
            </p>
          </div>
          <div className={styles.time}>{date.toLocaleString()}</div>
        </div>
        <div className={styles.content}>"{item.text}"</div>
        <Control
          className={styles.control}
          value={visible}
          setValue={setVisible}
          valueAnswer={currentValue}
          setValueAnswer={setCurrentValue}
          sendReplyHandler={sendReply}
          isReplied={replied}
          userImg={
            authCtx.userImg != undefined
              ? authCtx.userImg
              : "/images/default-avatar.png"
          }
          comment={item}
          authCtx={authCtx}
        />
      </div>
    </div>
  );
};

export default Item;
