import { useEffect, useState, useRef, useCallback } from 'react';
import styles from './ContactForm.module.sass';
import cn from 'classnames';
import SimpleReactValidator from 'simple-react-validator';
import TextInput from 'components/TextInput';
import TextArea from 'components/TextArea';
import Checkbox from 'components/Checkbox';
import { showError } from 'utils/showError';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { showSuccess } from 'utils/showSuccess';
import { companyData } from 'data/companyData';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { validateInput } from 'utils/validation';

export default function ContactForm() {
  /* ------------------------------- form values ------------------------------ */
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [messageValue, setMessageValue] = useState('');
  const [accepted, setAccepted] = useState(false);

  /* ------------------------------- validation ------------------------------- */
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptchaRef = useRef();
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: 'To pole jest wymagane',
        // boolean: 'Musisz zaakceptować zgodę',
        boolean: 'Musisz :attribute',
        string: 'Musi zawierać tylko litery',
        email: 'Niepoprawny adres email',
        min: 'Wymagana minimalna ilość znaków: :min',
        max: 'Dozwolona maksymalna ilość znaków: :max',
        default: 'Niepoprawne dane',
      },
      element: message => <div className={styles.error}>{message}</div>,
      validators: {
        string: {
          rule: (val, _, validator) => {
            return validator.helpers.testRegex(val, /^[a-zA-Z\s]*$/);
          },
        },
      },
    })
  );

  /* ---------------------------------- utils --------------------------------- */
  const controller = new AbortController();
  const [isLoading, setIsLoading] = useState(false);
  const [isPosted, setIsPosted] = useState(false);
  const isMounted = useRef(true);
  const [rerender, setRerender] = useState(false);
  const maxInputLength = 100;
  const maxTextareaLength = 1024;

  /* -------------------------------------------------------------------------- */
  /*                                mount effect                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                  recaptcha                                 */
  /* -------------------------------------------------------------------------- */
  const handleReCaptchaVerify = async () => {
    setIsLoading(true);

    if (!executeRecaptcha) {
      showError('Wystąpił błąd z reCaptcha. Spróbuj odświeżyć stronę.');
      setIsLoading(false);
      return;
    }
    const token = await executeRecaptcha('verify');
    sendForm(token);
    //recaptchaRef.current = token;
  };

  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  /* -------------------------------------------------------------------------- */
  /*                                 validation                                 */
  /* -------------------------------------------------------------------------- */
  const validation = () => {
    const nameValidation = validateInput(
      nameValue,
      maxInputLength,
      3,
      'Imię i nazwisko',
      'text'
    );
    const emailValidation = validateInput(
      emailValue,
      maxInputLength,
      3,
      'Twój email',
      'email'
    );
    const messageValidation = validateInput(
      messageValue,
      maxTextareaLength,
      20,
      'Treść wiadomości',
      'text'
    );

    const policyAccepted = accepted;
    if (!policyAccepted) showError('Musisz zaakceptować zgodę');

    return (
      nameValidation && emailValidation && messageValidation && policyAccepted
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  handlers                                  */
  /* -------------------------------------------------------------------------- */
  const toggleAcceptedHandler = () => {
    setAccepted(!accepted);
  };

  const rerenderHandler = () => {
    setRerender(!rerender);
  };

  const submitHandler = e => {
    e.preventDefault();

    //validator.current.showMessages();
    //rerenderHandler();

    if (!validation()) return;
    handleReCaptchaVerify();

    // if (!recaptchaRef.current) {
    //   handleReCaptchaVerify();
    //   setTimeout(() => {
    //     showError('Wystąpił błąd z reCaptcha. Proszę spróbować później.');
    //     return;
    //   }, 2000);
    // }
    //if (!validator.current.allValid()) return;

    //validator.current.hideMessages();
    //rerenderHandler();

    //sendForm(recaptchaRef.current);
  };

  const clearFormHandler = () => {
    setNameValue('');
    setEmailValue('');
    setMessageValue('');
    setAccepted(false);
  };

  const resetFormHandler = () => {
    clearFormHandler();
    setIsPosted(false);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   requests                                 */
  /* -------------------------------------------------------------------------- */
  const sendForm = async recaptcha => {
    setIsLoading(true);
    try {
      await devscntrNoauth.post('/misc/contact-form/', {
        signal: controller.signal,
        name: nameValue,
        email: emailValue,
        message: messageValue,
        recaptcha: recaptcha,
      });
      //isMounted.current && clearFormHandler();
      //isMounted.current && showSuccess('Wiadomość została wysłana');
      setIsPosted(true);
    } catch (err) {
      console.log(err);
      const errKey = err.response.data[0].key;
      console.log(err.response.data);
      console.log(errKey);
      if (errKey.includes('recaptcha')) {
        showError(
          'Wystąpił błąd z reCaptcha. Odśwież stronę i spróbuj ponownie.'
        );
        return;
      } else {
        isMounted.current &&
          showError(
            'Nie udało się wysłać wiadomości. Proszę spróbować później.'
          );
      }
    } finally {
      setIsLoading(false);
      // isMounted.current && recaptchaRef.current?.reset();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                     DOM                                    */
  /* -------------------------------------------------------------------------- */
  return !isPosted ? (
    <>
      <h3 className={cn('title-blue', styles.title)}>Skontaktuj się z nami</h3>
      <form className={styles.form} onSubmit={submitHandler}>
        <div className={styles.field}>
          <TextInput
            label='Imię i nazwisko'
            name='name'
            type='text'
            value={nameValue}
            onChange={e => setNameValue(e.target.value)}
          />
          {validator.current.message(
            'name',
            nameValue,
            `required|min:5|max:${maxInputLength}|string`
          )}
        </div>
        <div className={styles.field}>
          <TextInput
            label='Twój email'
            name='email'
            type='email'
            value={emailValue}
            onChange={e => setEmailValue(e.target.value)}
          />
          {validator.current.message(
            'email',
            emailValue,
            `required|email|max:${maxInputLength}`
          )}
        </div>
        <div className={styles.field}>
          <TextArea
            label='Treść wiadomości'
            name='message'
            type='text'
            value={messageValue}
            onChange={e => setMessageValue(e.target.value)}
            maxLength={maxTextareaLength}
          />
          {validator.current.message(
            'message',
            messageValue,
            `required|min:10|max:${maxTextareaLength}`
          )}
        </div>
        <div className={styles.googlePolicy}>
          This site is protected by reCAPTCHA and the Google
          <a href='https://policies.google.com/privacy'> Privacy Policy</a> and
          <a href='https://policies.google.com/terms'> Terms of Service</a>{' '}
          apply.
        </div>
        <div className={styles.bottom}>
          <div className={styles.left}>
            <Checkbox
              className={styles.checkbox}
              value={accepted}
              onChange={() => toggleAcceptedHandler()}
            />
            <div className={styles.policy}>
              Wyrażam zgodę na przetważanie moich danych osobowych zgodnie z{' '}
              <Link
                to='/privacy'
                target='_blank'
                rel='noopener noreferrer'
                className={cn(styles.field, styles.privacy_link)}
              >
                Polityką Prywatności
              </Link>
              {validator.current.message(
                'zaakceptować zgodę',
                accepted ? '' : 'true',
                'boolean'
              )}
            </div>
          </div>
          <div className={styles.right}>
            {/* ------------------------------- RECAPTCHA v2 ------------------------------ */}
            {/* <div className={styles.recaptcha}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={() => rerenderHandler()}
              ref={recaptchaRef}
              theme={darkMode.value ? 'dark' : 'light'}
            />
            {validator.current.message(
              'przejść weryfikację',
              recaptchaRef.current?.getValue() ? '' : 'true',
              'boolean'
            )}
            </div> */}
            {/* <button className={cn('button', styles.button)}>Wyślij</button> */}
            <Button classNames={styles.button} isLoading={isLoading}>
              Wyślij
            </Button>
          </div>
        </div>
        {/* <div className={styles.contactInfo}>
          {companyData.fullName}
          <br />
          ul. {companyData.address.street} {companyData.address.number},{' '}
          {companyData.address.zip} {companyData.address.city}
          <br />
          <a href={`mailto: ${companyData.contactMail}`}>
            {companyData.contactMail}
          </a>
          <br />
        </div> */}
      </form>
    </>
  ) : (
    <>
      <h3 className={cn('title-blue', styles.title)}>
        Wiadomość została wysłana
      </h3>
      <div className={styles.container}>
        <div className={styles.message}>
          Dziękujemy za wysłanie wiadomości. Postaramy się odpowiedzieć jak
          najszybciej.
        </div>
        <div className={styles.buttons}>
          <Button
            classNames={styles.button}
            text='Mam coś jeszcze'
            onClick={resetFormHandler}
          />
          <Link to='/' className='button-stroke'>
            Strona główna
          </Link>
        </div>
      </div>
    </>
  );
}
