import React, { useState, useEffect } from 'react';
import styles from './ChooseCategoryBreadcrumbsItems.module.sass';
import cn from 'classnames';
import PlusMinusButton from './PlusMinusButton';
import useDarkMode from 'use-dark-mode';

const ChooseCategoryBreadcrumbsItems = ({
  data,
  onItemClick,
  onItemAdd,
  selectedCategories,
  lastSelectedCategory,
  loading,
  searchBarActive,
}) => {
  const isDarkMode = useDarkMode();

  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck?.some(item => value.name === item.name);
  };

  return (
    <>
      <div className={styles.categories}>
        {data.map(
          (item, index) =>
            item.name !== 'Uncategorized' && (
              <div
                key={index}
                className={styles.item}
                style={
                  !checkIfArrayContains(selectedCategories, item)
                    ? {
                        backgroundColor: `${item.color}3F`,
                        borderColor: `${item.color}`,
                      }
                    : {
                        backgroundColor: `${item.color}`,
                        borderColor: `${item.color}`,
                      }
                }
              >
                <button
                  className={cn(
                    styles.item_button,
                    !(item.children.length > 0) &&
                      !searchBarActive &&
                      styles.item_shake,
                    searchBarActive && styles.item_cursor
                  )}
                  onClick={() => {
                    !searchBarActive && onItemClick(item);
                  }}
                  style={
                    !checkIfArrayContains(selectedCategories, item)
                      ? {
                          color: isDarkMode.value
                            ? `${item.color}`
                            : `#000000FF`,
                        }
                      : {
                          color: `black`,
                        }
                  }
                >
                  {item.name}
                </button>
                <PlusMinusButton
                  onClick={onItemAdd}
                  item={item}
                  color={item.color}
                  isOpen={
                    checkIfArrayContains(selectedCategories, item)
                      ? true
                      : false
                  }
                  lastSelectedCategory={lastSelectedCategory}
                  loading={loading}
                />
              </div>
            )
        )}
      </div>
    </>
  );
};
export default ChooseCategoryBreadcrumbsItems;
