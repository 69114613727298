import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: false,
  type: "",
  title: "",
  description: "",
  sendReaction: () => {},
};

export const reportModalSlice = createSlice({
  name: "reportModal",
  initialState: initialState,
  reducers: {
    showModal: (state, action) => {
      const { sendReaction } = action.payload;
      state.visible = true;
      state.sendReaction = sendReaction;
    },
    hideModal: (state) => {
      state.visible = false;
    },
    saveReportData: (state, action) => {
      const { type, title, description } = action.payload;
      state.type = type;
      state.title = title;
      state.description = description;
    },
    clearData: (state) => {
      state.type = "";
      state.title = "";
      state.description = "";
      state.sendReaction = () => {};
    },
  },
});
