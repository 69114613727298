import React, { useState } from 'react';
import styles from './AddXpPoints.module.sass';
import cn from 'classnames';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { adminEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';

const AddXpPoints = ({ classNames, id }) => {
  const [points, setPoints] = useState('');
  const [loading, setLoading] = useState(false);

  const addPoints = async () => {
    if (id === null) return;
    setLoading(true);
    try {
      const response = await devscntrAuth.put(
        `${adminEndpoints.users.levels.addPoints}`,
        {
          xpPoints: points,
        }
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className={cn(styles.container, classNames && classNames)}>
      <div className='title-green'>Dodaj punkty XP</div>
      <div className={styles.field}>
        <TextInput
          label='Punkty'
          value={points}
          onChange={e => setPoints(e.target.value)}
        />
      </div>
      <Button text='Dodaj' onClick={addPoints} isLoading={loading} />
    </div>
  );
};

export default AddXpPoints;
