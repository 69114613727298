import React, { useRef, useState } from 'react';
import styles from './FileInput.module.sass';
import cn from 'classnames';
import Icon from 'components/Icon';
import { showError } from 'utils/showError';

const FileInput = ({ classNames, file, setFile }) => {
  const inputRef = useRef(null);
  const [error, setError] = useState('');

  const validation = (file, maxFileSize = 5) => {
    // Allowed file extensions
    const allowedExtensions = /(\.pdf)$/i;

    // Check file's size
    if (file?.size / 1024 / 1024 > maxFileSize) {
      setError(`Maksymalny rozmiar pliku to ${maxFileSize} MB`);
      return false;
    }
    // Check file's extension
    if (!allowedExtensions.exec(file?.name)) {
      setError(`Możesz przesłać tylko pliki w formacie .pdf`);
      return false;
    }

    // If file is OK
    return true;
  };

  const handleClick = e => {
    e.preventDefault();
    inputRef.current.click();
  };

  const handleFileChange = e => {
    e.preventDefault();
    const file = e.target.files[0];
    inputRef.current.value = null;
    if (!validation(file, 5)) return;
    setError('');
    setFile && setFile(file);
  };

  return (
    <div className={cn(styles.container, classNames && classNames)}>
      <input
        className={styles.input}
        type='file'
        accept='.pdf'
        ref={inputRef}
        onChange={handleFileChange}
      />
      <div className={styles.content}>
        {error && <span className={styles.error}>{error}</span>}
        <button className={styles.icon} onClick={e => handleClick(e)}>
          <Icon name='upload' size={40} />
        </button>
        <div className={styles.text}>Przeciągnij i upuść plik tutaj</div>
      </div>
    </div>
  );
};

export default FileInput;
