import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import cn from "classnames";
import styles from "./Subscriptions.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import PopularProducts from "../../components/PopularProducts";
import Comments from "./Comments";
import RefundRequests from "../../components/RefundRequests";
import Article from "./Article";
import Card from "../../components/Card";
import Category from "./Category";
import axios from "axios";
import SubsCarusel from "./SubsCarusel";
import PostLoaderSkeleton from "../../components/PostLoaderSkeleton";
import { useLocation } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import LatestComments from "../../components/LatestComments";
import LatestCourses from "../../components/LatestCourses";
import devscntrAuth from "api/Instance/devscntrAuth";
import devscntrNoauth from "api/Instance/devscntrNoauth";
import Loader from "components/MiniPost/Loader";
import MiniPost from "../../components/MiniPost";
import {pageSize} from "../../data/dataArrays";

const postsPerPage = pageSize;

const Subscriptions = () => {
  let location = useLocation();
  // console.log(location);
  const authCtx = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState(authCtx.isLoggedIn);

  const [categories, setCategories] = React.useState([]);
  const [tags, setTags] = React.useState([]);

  const [articles, setArticles] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [ isLastPage, setIsLastPage ] = useState(false);

  const observer = useRef();
  const lastPostRef = useCallback(
      (post) => {
        if (isLoading) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(posts => {
          if (posts[0].isIntersecting && !isLastPage) {
            console.log('We are near the last post!')
            setCurrentPage(prev => ++prev)
          }
        });
        if (post) observer.current.observe(post)
        console.log(post)

      },
      [isLastPage, isLoading]
  );

  const [search, setSearch] = useState();

  useEffect(() => {
    fetchCategories();
    if (currentPage != 0) fetchPosts();
    if (location.search !== search) {
      setSearch(location.search);
    }
    // fetchCategories()
  }, [location, search, currentPage, tags]);

  useEffect(() => {
    if (location.search !== search) {
      setSearch(location.search);
    }
    // fetchCategories()
  }, [location, search, currentPage, tags]);

  const fetchPosts = () => {
    setIsLoading(true);
    const params =
      "?page=" +
      currentPage +
      (tags.length > 0 ? "&tags=" + tags.join("&") : "") +
      (search ? "&" + search : "") +
      "&page_size=" +
      postsPerPage;
    // console.log("params: ", params);
    devscntrAuth
      .request({
        method: "GET",
        url: `/accounts/me/follow-user-posts/${params}`,
      })
      .then(function (response) {
        console.log(response)

        if (response.data.next == null) setIsLastPage(true);
        else setIsLastPage(false);

        let loadedArticles = response.data.results.map((item, index) => {
          if(response.data.results.length - 1 === index + 1){
            return <MiniPost key={item.id} article={item} ref={lastPostRef} />;
          }
          return <MiniPost key={item.id} article={item} />

        });
        if (articles.length === 0) setArticles(loadedArticles);
        else setArticles((prevState) => [...prevState, ...loadedArticles]);


        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const compareNumbers = (a, b) => {
    return b.tags_count - a.tags_count;
  };

  const fetchCategories = () => {
    devscntrNoauth
      .request({
        method: "GET",
        url: "/misc/tags/",
      })
      .then(function (response) {
        const cats = response.data.sort(compareNumbers);
        setCategories(cats);
        // console.log(response.data.results);
      })
      .catch(function (error) {
        console.error(error);
      });
    // setArticles(dataResult);
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //   });
  };
  //   const baseUrl = "http://devscntr-staging.herokuapp.com/api/v1/";
  //   const [pins, setPins] = useState([]);

  //   const fetchCategories = () => {
  //     axios
  //       .request({
  //         method: "GET",
  //         url: "https://devscntr-staging.herokuapp.com/api/v1/posts/article/show-post/",
  //         params: { limit: 4, offset: 0 },
  //         headers: {
  //           accept: "application/json",
  //           Authorization: "Bearer " + localStorage.getItem("bearerToken"),
  //         },
  //       })
  //       .then(function (response) {
  //         setArticles(response.data.results);
  //         console.log(response.data.results);
  //       })
  //       .catch(function (error) {
  //         console.error(error);
  //       });
  //   };

  const handleChangeCategory = (ev) => {
    let newCategory = ev.target.value;
    setArticles([]);
    setCurrentPage(1);

    if (tags.length == 0) {
      setTags(newCategory);
    } else {
      setTags((prevTags) => {
        if (!prevTags.includes(newCategory)) return [...prevTags, newCategory];
        else return [...prevTags.filter((item) => !(item === newCategory))];
      });
    }
  };
  // console.log(currentPage)
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <header className={styles.subhead}>
            <h4>Subskrybcje</h4>
          </header>
          <Category
            categories={categories}
            onChangeCategory={handleChangeCategory}
          />
          <SubsCarusel />
          {/* DISPLAY POSTS*/}
          {articles.length > 0
              ? articles
              : !isLoading && articles.length === 0 &&
                  <p className={styles.alert} >"Brak postów do wyświetlenia"</p>
          }
          {isLoading && <Loader cards={2} />}

          {/* DISPLAY VALUE NULL */}
        </div>
        <div className={styles.col}>
          {/* <PopularProducts className={styles.card} views="4" />
          <Comments className={styles.card} />
          <RefundRequests title="Refund requests" classTitle="title-red" /> */}
          <LatestCourses className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Subscriptions;

// import React, { useEffect, useState } from "react";
// import styles from "./Subscriptions.module.sass";
// import SubsCarusel from "./SubsCarusel";
// import PopularProducts from "../../components/PopularProducts";
// import Comments from "./Comments";
// import RefundRequests from "../../components/RefundRequests";
// import Article from "./Article";
// import PostLoaderSkeleton from "../../components/PostLoaderSkeleton";

// import api from "../../api/devscntr";
// import useAxiosFunction from "../../hooks/useAxiosFunction";
// import axios from "axios";

// const Subscriptions = () => {

//   const [response, error, loading, axiosFetch] = useAxiosFunction();

//   const getData = () => {
//     axiosFetch({
//       axiosInstance: api,
//       method: "get",
//       url: "/accounts/me/follow-user-posts/",
//     });
//   };
//   const articlesFixed = response.map((item)=>{
//     return {
//       ...item,
//       description: JSON.parse(item.description)
//     }
//   })

//   useEffect(() => {
//     getData();
//     // eslint-disable-next-line
//   }, []);

//   return (
//     <>
//       <SubsCarusel/>
//       <div className={styles.row}>
//         <div className={styles.col}>
//           {/* DISPLAY LOADDER */}
//           {loading && <PostLoaderSkeleton cards={6} />}

//           {/* DISPLAY ERROR */}
//           {!loading && error && <p className="errMsg">{error}</p>}

//           {/* DISPLAY POSTS*/}
//           {!loading && !error && response.length > 0 && (<>
//           <Article className={styles.card} articles={articlesFixed} />
//           </>

//           )}

//           {/* DISPLAY VALUE NULL */}
//           {!loading && !error && response.length === 0 && <p className={styles.subnone}>Brak Postów</p>}
//         </div>

//         <div className={styles.col}>
//           <PopularProducts className={styles.card} views="4" />
//           <Comments className={styles.card} />
//           <RefundRequests title="Refund requests" classTitle="title-red" />
//         </div>
//       </div>
//     </>
//   );
// };

// export default Subscriptions;
