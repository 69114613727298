import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './ReplyComment.module.sass';
import Icon from 'components/Icon';
import Control from '../Control';
import { Link } from 'react-router-dom';
import Actions from 'components/Actions';

import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { showLoginModal } from 'utils/showLoginModal';
import More from '../More';
import { validateInput } from 'utils/validation';
import devscntrNoauth from '../../../api/Instance/devscntrNoauth';
import ProfileAvatar from '../../ProfileAvatar';

const ReplyComment = ({
  className,
  item,
  onComment,
  authorName,
  type,
  replyUrl,
}) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [currentValue, setCurrentValue] = useState('');
  const [currentValueAnswer, setCurrentValueAnswer] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [userLevel, setUserLevel] = useState('Level 0');

  useEffect(() => {
    fetchUserLevel();
  }, []);

  const sendReply = () => {
    if (replyUrl === '') return;
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    if (validateInput(currentValue?.trim(), 255, 2, 'odpowiedź')) replyApi();
  };

  const fetchUserLevel = () => {
    axiosInstance
      .request({
        method: 'get',
        url: '/levels/xpUser',
        headers: {
          'User-id': item.author,
        },
      })
      .then(response => {
        setUserLevel(response.data.current_level);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const replyApi = async () => {
    let replyComment = null
    if (type === 'post' || type === 'course') {
      replyComment = {
        text: currentValue.trim(),
        commentID: item.id,
      }
    } else if (type === 'jzwit') {
      replyComment = {
        text: currentValue.trim(),
        comment: item.id,
      }
    }
    try {
      const response = await devscntrAuth.post(replyUrl,replyComment);
      console.log(response);
      onComment();
      setCurrentValue('');
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={cn(styles.item, className)}>
        <Link to={`/profile/${item?.author}`}>
          <ProfileAvatar image={item.user_image} level={userLevel} small />
        </Link>
        <div className={styles.details}>
          <div className={styles.line}>
            <Link to={`/profile/${item?.author}`}>
              <div className={styles.author}>
                {item?.user_name || 'Brak nazwy'}
              </div>
            </Link>

            <div className={styles.rating_and_time}>
              <div className={styles.time}>
                {item?.created_at?.slice(0, 10)}
              </div>
              {item?.course_rating && (
                <div className={styles.rating}>
                  {item?.course_rating}
                  <Icon name='star-fill' size='24' />
                </div>
              )}
              <div className={styles.actions}>
                <More
                  id={item.id}
                  author={item.author}
                  type={type}
                  refresh={onComment}
                />
              </div>
            </div>
          </div>
          <div className={cn(styles.comment, showMore && styles.comment_full)}>
            <span className={styles.reply_to}>
              @{authorName || 'Brak nazwy'}
            </span>{' '}
            {item.text}
          </div>
          {item?.text?.length > 100 && (
            <div
              className={styles.show_more}
              onClick={() => setShowMore(prev => !prev)}
            >
              {showMore ? 'Pokaż mniej' : 'Pokaż więcej'}
            </div>
          )}
          <Control
            valueAnswer={currentValue}
            setValueAnswer={setCurrentValue}
            handleReply={sendReply}
            comment={item}
            type={type}
          />
        </div>
      </div>
      {item.replies.length > 0 &&
        item.replies.map((x, index) => (
          <ReplyComment
            className={className}
            key={index}
            item={x}
            onComment={onComment}
            authorName={item.user_name}
            type={type}
            replyUrl={replyUrl}
            answer
          />
        ))}
    </>
  );
};

export default ReplyComment;
