import React from 'react';
import styles from './Loader.module.sass';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Card from 'components/Card';

const Loader = ({ cards }) => {
  // return Array(cards)
  //   .fill(0)
  //   .map((_, i) => (
  //     <SkeletonTheme key={`minipost_loader_${i}`}>
  //       <div className={styles.container} key={i}>
  //         <Skeleton width={150} height={37} borderRadius={5} />
  //         <Skeleton width={100} height={37} borderRadius={5} />
  //         <Skeleton width={140} height={37} borderRadius={5} />
  //         <Skeleton width={110} height={37} borderRadius={5} />
  //         <Skeleton width={90} height={37} borderRadius={5} />
  //         <Skeleton width={110} height={37} borderRadius={5} />
  //         <Skeleton width={150} height={37} borderRadius={5} />
  //       </div>
  //     </SkeletonTheme>
  //   ));
  return (
    <SkeletonTheme>
      <Card
        title='Tagi'
        classTitle='title-green'
        className={styles.card}
        oneLine={true}
        head={
          <div className={styles.container}>
            <Skeleton width={40} height={37} borderRadius={5} />
            <Skeleton width={40} height={37} borderRadius={5} />
            <Skeleton width={40} height={37} borderRadius={5} />
            <Skeleton width={150} height={37} borderRadius={5} />
            <Skeleton width={100} height={37} borderRadius={5} />
            <Skeleton width={140} height={37} borderRadius={5} />
            <Skeleton width={110} height={37} borderRadius={5} />
            <Skeleton width={90} height={37} borderRadius={5} />
            <Skeleton width={110} height={37} borderRadius={5} />
            <Skeleton width={150} height={37} borderRadius={5} />
          </div>
        }
      ></Card>
    </SkeletonTheme>
  );
};

export default Loader;
