import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Card from 'components/Card';
import styles from './TagFilter.module.sass';
import ModalTags from './ModalTags';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import { accountsEndpoints, tagsEndpoints } from 'api/endpoints';
import Loader from './Loader';
import { showError } from 'utils/showError';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import PreferedTagsSettings from 'components/PreferedTagsSettings';
import Tag from 'components/Tags/Tag';
import HorizontalScroll from 'components/HorizontalScroll';

const LIMIT = 1000;

const TagFilter = ({ selectedTags, setSelectedTags }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [myTags, setMyTags] = useState([]);
  const [myTagsMounted, setMyTagsMounted] = useState(false);
  const [refreshMyTags, setRefreshMyTags] = useState(false);

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [lastDeleted, setLastDeleted] = useState(null);

  const compareNumbers = (a, b) => {
    return b.tags_count - a.tags_count;
  };

  const checkArrayEquality = (array1, array2) => {
    if (array1.length === array2.length) {
      array1.every(element => {
        if (!array2.includes(element)) {
          return false;
        }
      });
    }
    return true;
  };

  const getTags = async cancelToken => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.request({
        method: 'get',
        url: `${tagsEndpoints.show}?limit=${LIMIT}`,
        cancelToken: cancelToken.token,
      });
      setTags(response?.data);
      setAllTags(response?.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('tags canceled');
      } else {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  const getAllTags = async cancelToken => {
    try {
      const response = await axiosInstance.request({
        method: 'get',
        url: `${tagsEndpoints.showAll}?limit=1000`,
        cancelToken: cancelToken.token,
      });
      setAllTags(response?.data);
      if (selectedTags.length > 0) {
        const selected = response?.data.filter(item =>
          selectedTags.includes(item.name)
        );
        setSelectedItems(selected);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('all tags canceled');
      } else {
        console.log(error);
      }
    }
  };

  const getMyTags = async cancelToken => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: `${accountsEndpoints.userData.tagPreferences}`,
        cancelToken: cancelToken.token,
      });
      setMyTags(response.data);
      setIsMounted(true);
      setMyTagsMounted(true);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('my tags canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (allTags.length > LIMIT) {
      const selected = allTags.filter(item => selectedTags.includes(item.name));
      console.log(selected);
      setSelectedItems(selected);
    }
  }, [selectedTags]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getTags(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    authCtx.isLoggedIn && getMyTags(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [refreshMyTags]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (tags.length > 0) {
      //getAllTags(cancelToken);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [tags]);

  useEffect(() => {
    if (allTags.length > 0 && selectedTags.length > 0) {
      const selected = allTags.filter(item => selectedTags.includes(item.name));
      setSelectedItems(selected);
    }
  }, [selectedTags, allTags]);

  const showAllTags = () => {
    setModalVisible(true);
  };

  const resetTempTags = () => {
    setSelectedTags([]);
    setSelectedItems([]);
  };

  const applyMyTags = () => {
    if (myTagsMounted) {
      setSelectedTags(myTags.map(item => item.name));
      setSelectedItems(myTags);
    } else {
      setTimeout(() => {
        applyMyTags();
      }, 1000);
    }
  };

  const toggleTag = id => {
    const index = allTags.map(item => item.id).indexOf(id);

    if (selectedItems.map(item => item.id)?.includes(allTags[index].id)) {
      setSelectedTags(prev =>
        prev.filter(item => item !== allTags[index].name)
      );
      setSelectedItems(prev =>
        prev.filter(item => item.name !== allTags[index].name)
      );
      setLastDeleted(id);
    } else {
      setSelectedTags(prev => [...prev, allTags[index].name]);
      setSelectedItems(prev => [...prev, allTags[index]]);
    }
  };

  return isLoading ? (
    <Loader cards={1} />
  ) : (
    <Card
      title='Tagi'
      classTitle='title-green'
      className={styles.card}
      oneLine={true}
      head={
        <HorizontalScroll className={styles.tags} showScrollbar controls>
          {authCtx.isLoggedIn && (
            <button
              className={styles.settings_btn}
              onClick={() => setSettingsVisible(true)}
            >
              <Icon name='setting' size={20} />
            </button>
          )}
          <button className={styles.show_all_btn} onClick={showAllTags}>
            <Icon name='search' size={20} />
            {/* Pokaż wszystkie */}
          </button>
          {authCtx.isLoggedIn &&
            (selectedItems.length > 0 ? (
              <button className={styles.reset_tags_btn} onClick={resetTempTags}>
                <Icon name='close' size={20} />
              </button>
            ) : (
              <button
                className={cn(
                  styles.my_tags_btn,
                  myTags.length < 1 && styles.disabled
                )}
                onClick={applyMyTags}
                disabled={myTags.length < 1 ? true : false}
              >
                <Icon name='multiselect' size={20} />
              </button>
            ))}

          {selectedItems?.map((item, index) => (
            <Tag
              key={`tag_${index}`}
              name={item?.name || 'Brak nazwy'}
              color={item?.colour || '#ffffff'}
              selected={
                selectedItems.map(item => item.id)?.includes(item.id) || false
              }
              onClick={() => toggleTag(item.id)}
            />
          ))}
          {tags?.map(
            (item, index) =>
              index < 30 && (
                <Tag
                  key={`tag_${index}`}
                  name={item?.name || 'Brak nazwy'}
                  color={item?.colour || '#ffffff'}
                  selected={
                    selectedItems.map(item => item.id)?.includes(item.id) ||
                    false
                  }
                  visible={
                    !selectedItems.map(item => item.id)?.includes(item.id) ||
                    false
                  }
                  onClick={() => toggleTag(item.id)}
                />
              )
          )}
        </HorizontalScroll>
      }
    >
      <ModalTags
        visible={modalVisible}
        setVisible={() => setModalVisible(false)}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        selectedItems={selectedItems}
        tags={allTags}
        toggleTag={toggleTag}
      ></ModalTags>
      <Modal
        outerClassName={styles.settingsModal}
        visible={settingsVisible}
        onClose={() => setSettingsVisible(false)}
      >
        <PreferedTagsSettings
          allTags={allTags}
          myTags={myTags}
          onChange={() => setRefreshMyTags(prev => !prev)}
        />
      </Modal>
    </Card>
  );
};
export default TagFilter;
