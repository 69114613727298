import React, { useEffect, useState } from 'react';
import styles from './FollowButton.module.sass';
import cn from 'classnames';
import Button from 'components/Button';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import { showError } from 'utils/showError';
import { accountsEndpoints } from 'api/endpoints';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';

const FollowButton = ({ className, id, children }) => {
  const authCtx = useAuth();
  const myId = authCtx.userID;
  const [loading, setLoading] = useState(false);
  const [followed, setFollowed] = useState(
    authCtx.follows.includes(id) || false
  );

  // CONTROLLER--------------------------------------------------------------------------CONTROLLER

  const controller = new AbortController();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  // CONTROLLER--------------------------------------------------------------------------CONTROLLER
  // FOLLOW USER API----------------------------------------------------------------FOLLOW USER API

  const sendFollow = async isFollowed => {
    setLoading(true);
    try {
      const response = await devscntrAuth.request({
        method: 'post',
        url: `${accountsEndpoints.userData.followUser}`,
        signal: controller.signal,
        data: {
          id: id,
        },
        headers: {
          METHOD: isFollowed ? 'unfollow' : 'follow',
        },
      });

      setFollowed(!isFollowed);
      authCtx.updateFollows(id);
      authCtx.getLevel();
    } catch (error) {
      showError(
        'Nie udało się zmienić obserwacji użytkownika. Spróbuj ponownie później.'
      );
    } finally {
      setLoading(false);
    }
  };

  // FOLLOW USER API----------------------------------------------------------------FOLLOW USER API

  const handleClick = () => {
    sendFollow(followed);
  };

  return id != myId ? (
    <Button
      classNames={cn({ ['button-stroke']: followed }, styles.button, {
        [className]: className,
      })}
      onClick={handleClick}
      isLoading={loading}
    >
      {children}
      {!children && (
        <>
          <div
            className={cn(styles.inner_mobile, { [styles.loading]: loading })}
          >
            {!loading && <Icon name={followed ? 'close' : 'check'} size={20} />}
          </div>
          <div className={styles.inner_desktop}>
            {followed ? 'Obserwujesz' : 'Obserwuj'}
          </div>
        </>
      )}
    </Button>
  ) : (
    <Link to={`/profile/${myId}`} className={cn('button-stroke')}>
      Twój profil
    </Link>
  );
};

export default FollowButton;
