import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Certificates.module.sass';
import TextInput from 'components/TextInput';
import TextArea from 'components/TextArea';
import DatePicker from 'screens/Publications/components/DatePicker';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Icon from 'components/Icon';

const Certificates = ({
  certificates,
  setCertificates,
  setCertificatesRemoveList,
}) => {
  function getCurrentWidth() {
    return {
      width: window.innerWidth,
    };
  }

  const [screenWidth, setScreenWidth] = useState(getCurrentWidth());

  const [name, setName] = useState('');
  const [issuingOrganization, setIssuingOrganization] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [credentialId, setCredentialId] = useState('');
  const [credentialUrl, setCredentialUrl] = useState('');

  const [editId, setEditId] = useState('');

  const [modalText, setModalText] = useState('Dodaj certyfikat do listy');

  const [visible, setVisible] = useState(false);

  const DateHandler = date => {
    setIssueDate(date);
  };

  const closeModal = () => {
    setVisible(false);
    setName('');
    setIssuingOrganization('');
    setIssueDate('');
    setCredentialId('');
    setCredentialUrl('');
  };

  const remove = id => {
    certificates.forEach(element => {
      if (element.id === id || element.index === id + 1) {
        element.remove = true;
        setCertificatesRemoveList(certificatesRemoveList => [
          ...certificatesRemoveList,
          element,
        ]);
      }
    });

    setCertificates(current =>
      current.filter(employment => {
        return employment.id !== id || employment.index === id + 1;
      })
    );
  };

  const submit = () => {
    if (modalText === 'Dodaj certyfikat do listy') {
      setCertificates(certificates => [
        ...certificates,
        {
          index: certificates.length + 1,
          name: name,
          issuing_organization: issuingOrganization,
          issue_date: issueDate,
          credential_id: credentialId,
          credential_url: credentialUrl,
          remove: false,
        },
      ]);
      closeModal();
    }

    if (modalText === 'Edytuj') {
      certificates.forEach(element => {
        if (element.id === editId || element.index === editId) {
          element.name = name;
          element.issuing_organization = issuingOrganization;
          element.issue_date = issueDate;
          element.credential_id = credentialId;
          element.credential_url = credentialUrl;
        }
      });
      closeModal();
    }
  };

  const openAddModal = () => {
    setVisible(true);
    setModalText('Dodaj certyfikat do listy');
  };

  const openEditModal = id => {
    setVisible(true);
    setModalText('Edytuj');
    certificates.forEach(element => {
      if (element.id === id || element.index === id + 1) {
        if (element?.id) {
          setEditId(element?.id);
        } else {
          setEditId(element?.index);
        }
        setName(element?.name);
        setIssueDate(element?.issue_date);
        setIssuingOrganization(element?.issuing_organization);
        setCredentialId(element?.credential_id);
        setCredentialUrl(element?.credential_url);
      }
    });
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenWidth]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={cn('title-green')}>
          <div className={styles.header_text}>Certyfikaty</div>
        </div>
        <Button
          onClick={() => openAddModal()}
          classNames={cn('button', 'button-small')}
        >
          Dodaj certyfikat
        </Button>
      </div>
      <div className={styles.list}>
        {certificates.length === 0 && (
          <span className={styles.info}>
            Nie dodałeś/aś jeszcze posiadanych certyfikatów
          </span>
        )}
        {certificates.length > 0 && (
          <div className={styles.labels}>
            {screenWidth.width > 1000 ? (
              <div className={styles.certificates_info}>
                <div className={styles.list_item}>Nazwa certyfikatu</div>
                <div className={styles.list_item}>Organizacja wydająca</div>
                <div className={styles.list_item}>ID certyfikatu</div>
                <div className={styles.list_item}>URL certyfikatu</div>
                <div className={styles.list_item}>Data wydania certyfikatu</div>
              </div>
            ) : (
              <div className={styles.certificates_info_mobile}>
                <div className={styles.doubled_list_item}>
                  <div>Nazwa</div>
                  <div>Organizacja wydająca</div>
                </div>
                <div className={styles.doubled_list_item}>
                  <div>ID certyfikatu</div>
                  <div>URL certyfikatu</div>
                </div>
                <div className={styles.list_item}>Data wydania certyfikatu</div>
              </div>
            )}
          </div>
        )}

        {certificates.map(
          (item, index) =>
            !item?.remove &&
            (screenWidth.width > 1000 ? (
              <div className={styles.list_container} key={index}>
                <div className={styles.item_container}>
                  <div className={styles.certificates_info_grid}>
                    <div className={styles.list_item}>{item.name}</div>
                    <div className={styles.list_item}>
                      {item.issuing_organization}
                    </div>
                    <div className={styles.list_item}>{item.credential_id}</div>
                    <div className={styles.list_item}>
                      {item.credential_url}
                    </div>
                    <div className={styles.list_item}>
                      {item.issue_date && (
                        <>
                          {new Date(item.issue_date).getUTCDate()}/
                          {new Date(item.issue_date).getUTCMonth()}/
                          {new Date(item.issue_date).getUTCFullYear()}
                        </>
                      )}
                    </div>
                    <div className={styles.buttons_container}>
                      <Button
                        onClick={() => remove(item?.id ? item?.id : index)}
                        classNames={styles.button_remove}
                      >
                        <Icon name='close' size={18} />
                      </Button>
                      <Button
                        onClick={() =>
                          openEditModal(item?.id ? item?.id : index)
                        }
                        classNames={styles.button}
                      >
                        <Icon name='edit' size={18} />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.list_container} key={index}>
                <div className={styles.item_container}>
                  <div className={styles.certificates_info_grid_mobile}>
                    <div className={styles.doubled_list_item}>
                      <div className={styles.item_info}>
                        <span>Nazwa: </span>
                        {item.name}
                      </div>
                      <div className={styles.item_info}>
                        <span>Org. wydająca: </span>
                        {item.issuing_organization}
                      </div>
                    </div>
                    <div className={styles.doubled_list_item}>
                      <div className={styles.item_info}>
                        <span>ID: </span>
                        {item.credential_id}
                      </div>
                      <div className={styles.item_info}>
                        <span>URL: </span>
                        {item.credential_url}
                      </div>
                    </div>
                    <div className={styles.list_item}>
                      <div className={styles.item_info}>
                        <span>Data wydania: </span>
                        {item.issue_date && (
                          <>
                            {new Date(item.issue_date).getUTCDate()}/
                            {new Date(item.issue_date).getUTCMonth()}/
                            {new Date(item.issue_date).getUTCFullYear()}
                          </>
                        )}
                      </div>
                    </div>
                    <div className={styles.buttons_container}>
                      <Button
                        onClick={() => remove(item?.id ? item?.id : index)}
                        classNames={styles.button_remove}
                      >
                        <Icon name='close' size={18} />
                      </Button>
                      <Button
                        onClick={() =>
                          openEditModal(item?.id ? item?.id : index)
                        }
                        classNames={styles.button}
                      >
                        <Icon name='edit' size={18} />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))
        )}
      </div>

      <Modal
        visible={visible}
        onClose={() => closeModal()}
        outerClassName={styles.modal}
      >
        <div className={styles.modal_container}>
          <div className={styles.names_container}>
            <TextInput
              className={styles.field}
              label='Nazwa certyfikatu'
              placeholder='Podaj nazwę!'
              type='text'
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <TextInput
              className={styles.field}
              label='Organizacja wydająca'
              placeholder='Podaj nazwę organizacji!'
              type='text'
              value={issuingOrganization}
              onChange={e => setIssuingOrganization(e.target.value)}
            />
          </div>
          <div className={styles.dates_container}>
            <TextInput
              className={styles.field}
              label='ID certyfikatu'
              placeholder='Podaj id!'
              type='text'
              value={credentialId}
              onChange={e => setCredentialId(e.target.value)}
            />
            <TextInput
              className={styles.field}
              label='URl certyfikatu'
              placeholder='Podaj url!'
              type='text'
              value={credentialUrl}
              onChange={e => setCredentialUrl(e.target.value)}
            />
            <DatePicker
              yearRangeStart={1970}
              value={DateHandler}
              label='Data wydania'
              startingDate={issueDate}
            />
          </div>
          <Button onClick={() => submit()} classNames={styles.modal_button}>
            {modalText}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Certificates;
