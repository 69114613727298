import React, { useMemo, useState } from 'react';
import styles from './BigFiveDetails.module.sass';
import Card from 'components/Card';
import NavUnderline from 'components/NavUnderline';
import { b5Domains } from 'screens/BigFiveTest/b5utils';
import generateResult from 'lib/b5-result-description/lib/generate-result';
import getTemplate from 'lib/b5-result-description/lib/get-template';
import Domain from './components/BigFiveDomain';
import Facet from './components/BigFiveFacet';

const navItems = [
  {
    name: 'N',
    text: b5Domains['N'],
  },
  {
    name: 'E',
    text: b5Domains['E'],
  },
  {
    name: 'O',
    text: b5Domains['O'],
  },
  {
    name: 'A',
    text: b5Domains['A'],
  },
  {
    name: 'C',
    text: b5Domains['C'],
  },
];

const BigFiveDetails = ({ result }) => {
  const [domain, setDomain] = useState(navItems[0]);

  const generatedDesc = useMemo(() => {
    if (!result) return null;

    try {
      const generated = generateResult(result, getTemplate('pl'));
      const domains = {
        N: generated.find(item => item.domain === 'N'),
        E: generated.find(item => item.domain === 'E'),
        O: generated.find(item => item.domain === 'O'),
        A: generated.find(item => item.domain === 'A'),
        C: generated.find(item => item.domain === 'C'),
      };

      return domains;
    } catch (error) {
      return null;
    }
  }, [result]);

  return (
    <Card title={'Szczegóły'} classTitle={'title-red'}>
      <div className={styles.container}>
        <NavUnderline items={navItems} tab={domain} setTab={setDomain} />
        {generatedDesc && (
          <>
            <Domain data={generatedDesc[domain?.name] || null} />
            {generatedDesc[domain?.name]?.facets?.map((facet, index) => (
              <Facet key={index} facet={facet} />
            ))}
          </>
        )}
      </div>
    </Card>
  );
};

export default BigFiveDetails;
