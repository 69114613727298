import showdown from 'showdown';

const options = {
  omitExtraWLInCodeBlocks: true,
  noHeaderId: true,
  simplifiedAutoLink: true,
  excludeTrailingPunctuationFromURLs: true,
  tables: true,
  simpleLineBreaks: true,
  requireSpaceBeforeHeadingText: true,
  openLinksInNewWindow: true,
};

const converter = new showdown.Converter(options);

export const convertHtmlToMd = (html = '') => converter.makeMarkdown(html);
export const convertMdToHtml = (md = '') => converter.makeHtml(md);
