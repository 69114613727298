import React, { useContext, useEffect, useState } from 'react';
import styles from './MoreOptions.module.sass';
import cn from 'classnames';
import Actions from 'components/Actions';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import { adminEndpoints, supportEndpoints } from 'api/endpoints';
import { showError } from 'utils/showError';
import { store } from 'store/rootReducer';
import { showShareModal } from 'store/shareModal/actions';
import { deleteTimeout } from 'data/dataArrays';
import ModalReport from 'components/ModalReport';
import { id } from 'date-fns/locale';
import { useHistory } from 'react-router';
import { jzwitEndpoints, jzwitv2Endpoints } from 'api/endpoints';

const deleteEndpoints = {
  jzwit: adminEndpoints.jzwit.delete,
};

const MoreOptionsJZWIT = ({
  className,
  item,
  type,
  refresh,
  onDelete,
  shareUrlCntx,
  goBack,
}) => {
  const authCtx = useAuth();
  const deleteUrl = deleteEndpoints[type] ?? '';
  const shareUrl = `${document.location.origin}${shareUrlCntx}`;
  const history = useHistory();

  const [reportVisible, setReportVisible] = useState(false);

  const [confirm, setConfirm] = useState(false);
  const [allowClick, setAllowClick] = useState(true);
  const [deleteCountdown, setDeleteCountdown] = useState(false);

  const handleDelete = () => {
    if (authCtx.isModerator || authCtx.isAdmin) {
      if (confirm && allowClick) {
        deleteApi(item.id);
        setConfirm(false);
      } else {
        if (allowClick) {
          // Show countdown in option
          setDeleteCountdown(deleteTimeout / 1000);
          let timer = setInterval(() => {
            setDeleteCountdown(prev => {
              if (prev === 0) clearInterval(timer);
              else return prev - 1;
            });
          }, 1000);
          // Disable click (anti double-click)
          setAllowClick(false);
          setTimeout(() => setAllowClick(true), deleteTimeout);
          //setTimeout(() => setConfirm(false), 10000);
        }

        setConfirm(true);
      }
    }
  };

  const deleteActionConfirmCancel = () => {
    setConfirm(false);
  };

  const handleEditPush = () => {
    history.push(`/jak-zaczac-w-it-edit/${item.slug}`);
  };

  const deleteApi = async e => {
    try {
      const response = await devscntrAuth.delete(
        `${jzwitv2Endpoints.displayTech}${e}/`
      );
      console.log('response', response?.data);
      onDelete && onDelete();
      goBack && goBack();
      console.log('deleted');
    } catch (error) {
      console.log(error);
      showError('Usuwanie nie powiodło się. Spróbuj ponownie później');
    }
  };

  const actions = [
    // {
    //   title: 'Udostępnij',
    //   icon: 'link',
    //   action: handleShare,
    // },
    authCtx.isLoggedIn &&
      (authCtx.isModerator ||
        (authCtx.isAdmin && {
          title: 'Edytuj',
          icon: 'edit',
          action: () => handleEditPush(),
        })),
    authCtx.isLoggedIn &&
      (authCtx.isModerator ||
        (authCtx.isAdmin && {
          title: confirm ? 'Potwierdź' : 'Usuń',
          icon: 'trash',
          color: confirm,
          badge: deleteCountdown > 0 ? deleteCountdown : false,
          action: handleDelete,
        })),
  ];

  return (
    <Actions
      className={cn(styles.menu, className)}
      classActionsHead={styles.settings_button}
      classActionsBody={styles.actionsBody}
      classActionsOption={styles.actionsOption}
      deleteActionConfirmCancel={deleteActionConfirmCancel}
      deleteConfirm={confirm}
      items={actions}
      jzwit_button='Edytuj / Usuń technologie'
    />
  );
};

export default MoreOptionsJZWIT;
