import React, { useEffect, useState } from 'react';
import styles from './NewCourse.module.sass';
import TooltipGlodal from 'components/TooltipGlodal';
import Panel from 'screens/NewLayouts/Panel';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import axios from 'axios';
import cn from 'classnames';
import Card from 'components/Card';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import Details from './Details';
import devscntrAuth from 'api/Instance/devscntrAuth';
import Meta from 'components/Meta';
import {
  courseDifficulty,
  courseFormat,
  courseLanguage,
} from 'data/dataArrays';
import TagsInput from 'screens/NewLayouts/TagsInput';
import ImageInput from 'screens/NewLayouts/ImageInput';
import Content from 'screens/NewLayouts/Content';
import { showError } from 'utils/showError';
import { validateInput, validateUrl } from 'utils/validation';
import useAuth from 'hooks/useAuth';
import ModalSchedule from 'components/ModalSchedule';
import LocationsInput from 'components/InputBlocks/LocationsInput';
import { adminEndpoints, coursesEndpoints } from 'api/endpoints';
import Loader from 'screens/NewLayouts/Loader';
import LinksInput from 'screens/NewLayouts/LinksInput';
import isJson from 'utils/isJson';
import ChooseCategoryBreadcrumbs from 'components/ChooseCategoryBreadcrumbs';
import TextInput from 'components/TextInput';
import SlugInput from 'components/SlugInput';

const DUMMY_TAGS = [{ name: 'jak zacząć', count: 20 }];

const EditCourse = () => {
  // UTILS-------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const { id } = useParams();
  const history = useHistory();

  const location = useHistory();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleSchedule, setVisibleSchedule] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [isPostCreated, setIsPostCreated] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState(false);

  const [article, setArticle] = useState(null);

  // UTILS-------------------------------------------------------------------------UTILS
  // FIELDS-----------------------------------------------------------------------FIELDS

  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('')
  const [editorState, setEditorState] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [detailsData, setDetailsData] = useState({
    duration: '',
    certificate: false,
    courseFormat: 'Wybierz formę kursu',
    language: 'PL',
    reflink: '',
    coursePrice: '',
    courseSalePrice: '',
    courseDifficulty: 'Wybierz poziom kursu',
  });
  const [files, setFiles] = useState(null);
  const [tags, setTags] = useState([]);
  const [links, setLinks] = useState([]);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState(0);

  const handleEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  const handleTitleChange = event => {
    const val = event.target.value;
    setTitle(val);
  };

  const handleMetaTitleChange = event => {
    const val = event.target.value;
    setMetaTitle(val);
  };

  const handleMetaDescriptionChange = event => {
    const val = event.target.value;
    setMetaDescription(val);
  };

  // FIELDS-----------------------------------------------------------------------FIELDS
  // GET DATA-------------------------------------------------------------------GET DATA

  const getCourse = async cancelToken => {
    setFetching(true);
    try {
      // Request user data
      const response = await devscntrAuth.request({
        method: 'get',
        url: `${coursesEndpoints.show}${id}`,
        cancelToken: cancelToken.token,
      });
      setArticle(response?.data[0] || {});
      setFetching(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('post detail canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCourse(cancelToken);

    window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, [id]);

  const saveDraft = () => {
    setStatus(2);
  };

  useEffect(() => {
    if (status === 2) {
      if (validateInput(title.trim(), 100, 10, 'Tytuł')) {
        const formData = prepareFormData();
        editCourseApi(formData);
      } else {
        setStatus(0)
      }
    }
    if (status === 1) {
      if (validation(detailsData)) {
        const formData = prepareFormData();
        editCourseApi(formData);
      }
      else {
        setStatus(0)
      }
    }
  }, [status]);

  // INSERT DATA TO FIELDS
  useEffect(() => {
    if (article) {
      setTitle(article?.title || '');
      setSlug(article?.slug || '')
      setMetaTitle(article?.meta_title || '');
      setMetaDescription(article?.meta_description || '');

      // DESCRIPTION
      if (article?.content) {
        if (article?.editor_version === 1 && isJson(article.content)) {
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(article.content))
            ) || EditorState.createEmpty()
          );
        }
        if (article?.editor_version === 2) {
          setEditorState(article.content);
        }
      }

      let tagList = [];
      if (article?.tags?.length > 0) {
        tagList = article?.tags.map(item => ({
          name: item.name,
          colour: item.colour,
        }));
      }
      // SETTING TAGS
      setTags(tagList);

      //SETTING CATEGORIES
      let categoriesList = [];
      if (article?.categories?.length > 0) {
        categoriesList = article?.categories.map(item => ({
          name: item.name,
          id: item.id,
          color: item.color,
        }));
      }

      setCategories(categoriesList);

      let language = 'Wybierz język';
      if (article?.language) {
        if (courseLanguage.includes(article?.language?.toUpperCase()))
          language = article?.language?.toUpperCase();
        else if (courseLanguage.indexOf(article?.language))
          language = courseLanguage[article?.language - 1];
      }

      let linksList = [];
      if (article?.additional_url?.length > 0) {
        linksList = article?.additional_url.map(item => ({
          title: item.description,
          url: item.url,
        }));
      }
      setLinks(linksList);

      setDetailsData({
        duration: article?.duration || '',
        certificate: article?.certificate || false,
        courseFormat: courseFormat[article?.format - 1] || 'Wybierz format',
        language: language.toUpperCase() || 'Wybierz język',
        reflink: article?.reflink || '',
        coursePrice: article?.price || '',
        courseSalePrice: article?.specialprice || '',
        courseDifficulty:
          courseDifficulty[article?.difficulty - 1] || 'Wybierz poziom',
      });
      if (authCtx.userID === article.author) setPermission(true);
      else if (authCtx.isAdmin) setPermission(true);
    }
  }, [article]);

  // GET DATA-------------------------------------------------------------------GET DATA
  // VALIDATION---------------------------------------------------------------VALIDATION

  function stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  const validation = data => {
    // TITLE
    if (!validateInput(title.trim(), 100, 10, 'Tytuł')) return false;

    // DESCRIPTION

    let string = stripHtml(editorState)
      .replace(/[\r\n]/g, '')
      .replace(/\u00A0/g, '')
      .trim();
    let descriptionLength = string.length;

    if (article?.editor_version === 1) {
      if (
        detailsData.reflink === '' &&
        JSON.stringify(convertToRaw(editorState.getCurrentContent())).length <
        133
      ) {
        showError('Opis nie może być pusty');
        return false;
      }
    }
    if (article?.editor_version === 2) {
      if (detailsData.reflink === '') {
        if (descriptionLength < 1) {
          showError('Opis nie może być pusty');
          return false;
        }
        // if (descriptionLength > 10000) {
        //   showError('Opis nie może mieć więcej niż 10000 znaków');
        //   return false
        // }
      }
    }

    // DURATION
    if (!validateInput(data?.duration?.trim(), 7, 1, 'czas trwania', 3))
      return false;

    // DROPDOWNS
    if (!courseFormat.includes(data.courseFormat)) {
      showError('Wybierz formę kursu');
      return false;
    }
    if (!courseDifficulty.includes(data.courseDifficulty)) {
      showError('Wybierz poziom kursu');
      return false;
    }
    if (!courseLanguage.includes(data.language)) {
      showError('Wybierz język kursu');
      return false;
    }

    // VALIDATE REFLINK
    if (data.reflink !== '') {
      if (!validateUrl(data.reflink.trim())) return false;
    }

    return true;
  };

  //CATEGORY FUNCTIONS
  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck?.some(item => value.name === item.name);
  };

  const categoryFunction = item => {
    if (!checkIfArrayContains(categories, item)) {
      setCategories(categories => [...categories, item]);
    }
    if (checkIfArrayContains(categories, item)) {
      setCategories(current =>
        current.filter(object => object.name !== item.name)
      );
    }
  };

  // VALIDATION---------------------------------------------------------------VALIDATION
  // PREPARE DATA-----------------------------------------------------------PREPARE DATA

  const preparePrice = price => {
    return price.replace(',', '.');
  };

  const handleSlugChange = event => {
    const val = event.target.value;
    setSlug(val);
  };

  const prepareFormData = () => {
    const formData = new FormData();

    const preparedPrice = preparePrice(detailsData.coursePrice);
    const preparedSalePrice = preparePrice(detailsData.courseSalePrice);

    // BASIC DATA
    formData.append('title', title.trim());
    formData.append('price', preparedPrice.trim());
    formData.append('specialprice', preparedSalePrice.trim());
    formData.append('duration', detailsData.duration.trim());
    formData.append(
      'format',
      courseFormat.indexOf(detailsData.courseFormat) + 1
    );
    formData.append(
      'difficulty',
      courseDifficulty.indexOf(detailsData.courseDifficulty) + 1
    );
    formData.append('language', detailsData.language.toLowerCase());
    formData.append('certificate', detailsData.certificate);
    formData.append('reflink', detailsData.reflink);

    // TAGS
    if (tags.length > 0) {
      tags.forEach((item, index) => {
        formData.append(`tags[${index}]name`, item.name);
        formData.append(`tags[${index}]colour`, item.colour);
      });
    }

    if (status === 1) {
      formData.append('scheduled_at', startDate.toISOString());
    }

    // CATEGORIES
    if (categories.length > 0) {
      categories.forEach((item, index) => {
        formData.append(`categories[${index}]name`, item.name);
        formData.append(`categories[${index}]id`, item.id);
      });
    }

    // LINKS
    if (links.length > 0) {
      links.forEach((item, index) => {
        formData.append(`additional_url[${index}]description`, item.title);
        formData.append(`additional_url[${index}]url`, item.url);
      });
    }

    // DESCRIPTION
    if (article?.editor_version === 1) {
      formData.append(
        'content',
        JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      );
    }
    if (article?.editor_version === 2) {
      formData.append('content', editorState);
    }

    // EDITOR VERSION
    formData.append('editor_version', article?.editor_version);

    //STATUS
    formData.append('status', status);

    // LOCATIONS
    if (locations.length > 0) {
      locations.forEach((item, index) => {
        formData.append(`localization[${index}]city`, item.city);
        formData.append(`localization[${index}]code`, item.code);
        formData.append(`localization[${index}]street`, item.street);
        formData.append(`localization[${index}]adres`, item.number);
      });
    }

    // IMAGE
    if (files) {
      formData.append('image', files);
    }

    //SLUG
    formData.append('slug', slug)

    //META
    formData.append('meta_title', metaTitle.trim());
    formData.append('meta_description', metaDescription);

    return formData;
  };

  const clearInputs = () => {
    setTitle('');
    setSlug('');
    setEditorState('');
    setMetaTitle('');
    setMetaDescription('');
    setDetailsData({
      duration: '',
      certificate: false,
      courseFormat: 'Wybierz formę kursu',
      language: 'PL',
      reflink: '',
      coursePrice: '',
      courseSalePrice: '',
      courseDifficulty: 'Wybierz poziom kursu',
    });
    setFiles(null);
    setTags([]);
    setLinks([]);
    setLocations([]);
    setCategories([]);
  };


  // PREPARE DATA-----------------------------------------------------------PREPARE DATA
  // UPDATE API---------------------------------------------------------------UPDATE API

  const editCourseApi = async data => {
    setIsLoading(true);
    try {
      if (article.author === authCtx.userID) {
        const newData = data;
        newData.append('id', id);
        const response = await devscntrAuth.post(
          `${coursesEndpoints.update}`,
          newData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              method: 'update',
            },
          }
        );
      } else if (authCtx.isAdmin) {
        const response = await devscntrAuth.put(
          `${adminEndpoints.courses.update}${id}/`,
          data,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } else {
        return;
      }
      history.push(`/course/${slug}`);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    if (validation(detailsData)) {
      const formData = prepareFormData();
      editCourseApi(formData);
    }
  };

  const scheduleHandler = () => {
    setVisibleSchedule(false)
    setStatus(1)
  }

  // UPDATE API---------------------------------------------------------------UPDATE API
  return !fetching ? (
    permission ? (
      <>
        <div className={styles.row}>
          {!isPostCreated ? (
            <>
              <div className={styles.col}>
                <div className={styles.course_info}>
                  <Content
                    className={styles.card}
                    onTitleChange={handleTitleChange}
                    onDescriptionChange={handleEditorStateChange}
                    title={title}
                    description={editorState}
                    editorVersion={article?.editor_version}
                  />
                  <ChooseCategoryBreadcrumbs
                    title='Kategorie'
                    itemClicked={categoryFunction}
                    loading={false}
                    selectedCategories={categories}
                  />
                  <LinksInput
                    links={links}
                    setLinks={setLinks}
                    className={styles.card}
                  />
                  <Details data={detailsData} setData={setDetailsData} />
                  {detailsData.courseFormat === 'stacjonarny' && (
                    <LocationsInput
                      locations={locations}
                      setLocations={setLocations}
                    />
                  )}
                  <ImageInput
                    className={styles.card}
                    title={'Miniaturka kursu'}
                    setFile={setFiles}
                    fileName={`course_img_u${authCtx.userID}`}
                    img={article?.image || null}
                  />
                  <TagsInput tags={tags} setTags={setTags} />
                  <SlugInput slug={slug} onSlugChange={handleSlugChange} />
                  <Meta
                    title={metaTitle}
                    description={metaDescription}
                    onTitleChange={handleMetaTitleChange}
                    onDescriptionChange={handleMetaDescriptionChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <Card className={styles.postcreated}>
              <p>Kurs został utworzony!</p>
              <div className={styles.panel}>
                <button
                  className={cn('button', styles.homebtn)}
                  onClick={() => location.push('/courses')}
                >
                  Zobacz listę kursów
                </button>
                <button
                  className={cn('button-stroke', styles.newbtn)}
                  onClick={window.location.reload}
                >
                  Utwórz kolejny kurs
                </button>
              </div>
            </Card>
          )}
        </div>
        <Panel
          setVisiblePreview={setVisiblePreview}
          saveDraft={saveDraft}
          setVisibleSchedule={setVisibleSchedule}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          article={article}
          clearInputs={clearInputs}
        />
        <TooltipGlodal />
        <ModalSchedule
          visible={visibleSchedule}
          setVisible={setVisibleSchedule}
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
          onSubmit={scheduleHandler}
        />
      </>
    ) : (
      <Redirect to={`/course/${slug}`} />
    )
  ) : (
    <Loader />
  );
};

export default EditCourse;
