import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import styles from './Modal.module.sass';
import Icon from 'components/Icon';
const ModalLayoutImage = ({ outerClassName, visible, onClose, setSide, side }) => {
  const escFunction = useCallback(
    e => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector('#modal');
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
      return () => clearAllBodyScrollLocks()

    }
  }, [visible]);

  // console.log('side: ', side)

  return createPortal(
    visible && (
      <div id='modal' className={styles.modal}>
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={onClose}>
            <div className={styles.body}>
              <h2>Wybierz położenie obrazka</h2>
              <div className={styles.LayoutContent}>
                <button onClick={()=> {setSide(false)}}>
                  <div className={cn(styles.LayoutBtnContent, !side && styles.active)}>
                    <div className={styles.LayoutBtnText}>
                      <div className={styles.LayoutBtntitle}></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className={styles.LayoutBtnImage}></div>
                  </div>
                </button>
                <button onClick={()=> {setSide(true)}}>
                  <div className={cn(styles.LayoutBtnContent, side && styles.active)}>
                    <div className={styles.LayoutBtnImage}></div>
                    <div className={styles.LayoutBtnText}>
                      <div className={styles.LayoutBtntitle}></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </button>
              </div>

                <button onClick={onClose} className={styles.btn_ce}>
                  ZAMKNIJ
                </button>
            </div>

            {/* <button className={styles.close} onClick={onClose}>
              <Icon name="close" size="20" />
            </button> */}
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default ModalLayoutImage;
