import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Item.module.sass';
import Icon from '../../Icon';
import axios from 'axios';
import ModalLogin from '../../ModalLogin';

import useAuth from '../../../hooks/useAuth';
import devscntrAuth from '../../../api/Instance/devscntrAuth';
import { showLoginModal } from 'utils/showLoginModal';

const Item = ({ className, comment }) => {
  const authCtx = useAuth();
  const [isMounted, setIsMounted] = useState(false);

  const getReactionNum = () => {
    if (comment?.has_liked) return 1;
    if (comment?.has_disliked) return 2;
    return 0;
  };

  const [reactionNumber, setReactionNumber] = useState(getReactionNum());
  const [reactionsCount, setReactionsCount] = useState({
    like: comment?.likes_count ? comment?.likes_count?.like : 0,
    dislike: comment?.likes_count ? comment?.likes_count?.dislike : 0,
  });

  const reactionApi = async () => {
    const api = comment.to_post
      ? '/posts/article/comment-emote/'
      : '/course/course-comment-emote/';
    const data = {
      comment: comment.id,
      emote: reactionNumber,
    };
    try {
      const response = await devscntrAuth.post(api, data);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted) {
      reactionApi();
    } else {
      setIsMounted(true);
    }

    return () => {};
  }, [reactionNumber]);

  const [allowClick, setAllowClick] = useState(true);
  const emotionClickTimeout = 1000;

  const handleLike = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    setAllowClick(false);
    if (reactionNumber == 1) return;
    if (!allowClick) return;
    setTimeout(() => setAllowClick(true), emotionClickTimeout);

    setReactionsCount(prev => ({
      like: prev.like + 1,
      dislike: reactionNumber == 2 ? prev.dislike - 1 : prev.dislike,
    }));
    setReactionNumber(1);
  };

  const handleDislike = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    setAllowClick(false);
    if (reactionNumber == 2) return;
    if (!allowClick) return;
    setTimeout(() => setAllowClick(true), emotionClickTimeout);

    setReactionsCount(prev => ({
      like: reactionNumber == 1 ? prev.like - 1 : prev.like,
      dislike: prev.dislike + 1,
    }));
    setReactionNumber(2);
  };

  // console.log(comment);
  return (
    <div className={styles.item}>
      <Link to={`/profile/${comment?.author}`}>
        <div className={styles.avatar}>
          <img
            src={
              comment?.user_image?.length > 0
                ? comment?.user_image
                : '/images/default-avatar.png'
            }
            alt='Avatar'
          />
        </div>
      </Link>
      <div className={styles.details}>
        <div className={styles.line}>
          <div className={styles.user}>
            <Link to={`/profile/${comment?.author}`}>
              <span className={styles.title}>
                {comment?.user_name || 'Brak nazwy'}
              </span>
              {/* <span className={styles.login}>{comment.user_name}</span> */}
            </Link>
          </div>
          {/* <div className={styles.time}>{comment?.created_at?.slice(0, 10)}</div> */}
        </div>
        {/* <div
                      className={styles.content}
                      dangerouslySetInnerHTML={{ __html: comment.content }}
                    ></div> */}
        <div className={styles.comment}>{comment?.text}</div>
        {/* <div className={styles.control}>
                      <Link className={styles.link} to="/message-center">
                        <Icon name="message" size="20" />
                      </Link>
                      <Favorite className={cn(styles.favorite, styles.link)} />
                      <Link className={styles.link} to="/products/comments">
                        <Icon name="link" size="20" />
                      </Link>
                    </div> */}
        <div className={styles.control}>
          <div className={styles.link} onClick={handleLike}>
            <Icon
              name={reactionNumber == 1 ? 'thumbs-up-fill' : 'thumbs-up'}
              size={20}
              viewBox='0 0 24 24'
            />
            <span>{reactionsCount.like}</span>
          </div>
          <div className={styles.link} onClick={handleDislike}>
            <Icon
              name={reactionNumber == 2 ? 'thumbs-down-fill' : 'thumbs-down'}
              size={20}
              viewBox='0 0 24 24'
            />
            <span>{reactionsCount.dislike}</span>
          </div>
          <div className={styles.link}>
            {comment?.post_slug && (
              <Link to={`/post/${comment.post_slug}`} className={styles.link_to}>
                Zobacz
              </Link>
            )}
            {comment?.course_slug && (
              <Link
                to={`/course/${comment.course_slug}`}
                className={styles.link_to}
              >
                Zobacz
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
