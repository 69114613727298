import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import classNames from 'classnames';
import Icon from 'components/Icon';
import styles from './NavigationLink.module.sass';

const NavigationLink = props => {
  const { className, item, onClick } = props;
  const { title, icon, url, alternativeUrl } = item;
  const visible = props.visibleSidebar

  const isActive = () => {
    const pathname = window.location.pathname;
    const defaultUrl = url.split('?')[0];
    const altUrl = alternativeUrl?.split('?')[0] || '';
    return altUrl === pathname || defaultUrl === pathname;
  };

  useEffect(() => {
    const thisNavLink = document.getElementById(url.slice(1, url.length));
    if (thisNavLink) thisNavLink.addEventListener('click', onClick);

    return () => {
      if (thisNavLink) thisNavLink.removeEventListener('click', onClick);
    };
  }, [onClick, url]);

  return (
    <>
      {visible ?
        (
          <NavLink
            className={classNames(styles.link, className, {
              [styles.active]: isActive(),
            })}
            to={url}
            id={url.slice(1, url.length)}
          >
            <Icon name={icon} size='24' />
            {title}
          </NavLink>
        ) : (
          <NavLink
            className={classNames(styles.link, className, styles.menu_hidden, {
              [styles.active]: isActive(),
            })}
            to={url}
            id={url.slice(1, url.length)}
          >
            <Icon name={icon} size='24' />
          </NavLink>
        )}
    </>
  );
};

export default NavigationLink;
