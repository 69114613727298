import React from 'react';
import styles from './NavUnderline.module.sass';
import classNames from 'classnames';
import HorizontalScroll from 'components/HorizontalScroll';

/*
  items structure:
  items = [
    {
      name: 'tab_name',
      text: 'Tab Name',
    }
  ]
*/

const NavUnderline = ({ items, tab, setTab }) => {
  return (
    <nav>
      <HorizontalScroll className={styles.nav}>
        {items.map((item, index) => (
          <button
            key={index}
            className={classNames(styles.navItem, {
              [styles.active]: tab.name === item.name,
            })}
            onClick={() => setTab(item)}
          >
            {item.text}
          </button>
        ))}
      </HorizontalScroll>
    </nav>
  );
};

export default NavUnderline;
