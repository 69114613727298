import { useState, useRef, useEffect } from 'react';
import styles from './Charts.module.sass';
import cn from 'classnames';

import devscntrAuth from 'api/Instance/devscntrAuth';
import { adminEndpoints } from 'api/endpoints';
import Card from 'components/Card';
import GlobalChart from './GlobalChart';
import Dropdown from 'components/Dropdown';
import { prepareChartData } from './utils';

const chartDropdownInterval = [
  {
    name: 'Cały okres',
    value: 'default',
  },
  {
    name: 'Ostatnie 365 dni',
    value: 'pastyear',
  },
  {
    name: 'Ostatnie 30 dni',
    value: 'pastmonth',
  },
  {
    name: 'Ostatnie 7 dni',
    value: 'pastweek',
  },
  {
    name: 'Ostatnie 24 godziny',
    value: 'pastday',
  },
  {
    name: 'W tym roku',
    value: 'thisyear',
  },
  {
    name: 'W tym miesiącu',
    value: 'thismonth',
  },
];

export default function Charts({ className }) {
  /* ---------------------------------- chart --------------------------------- */
  const [chartData, setChartData] = useState({
    total: null,
    posts: null,
    courses: null,
  });
  const [preparedChartData, setPreparedChartData] = useState({});
  const [chartDropdown, setChartDropdown] = useState(
    chartDropdownInterval[0].name
  );

  /* ---------------------------------- utils --------------------------------- */
  const isMounted = useRef(true);

  /* ------------------------------- controllers ------------------------------ */
  const globalStatsController = new AbortController();

  /* -------------------------------------------------------------------------- */
  /*                                  requests                                  */
  /* -------------------------------------------------------------------------- */
  const getUserStats = async () => {
    try {
      const response = await devscntrAuth.get(
        `${adminEndpoints.usercontentstats.get}`,
        {
          signal: globalStatsController.signal,
          headers: {
            method: chartDropdownInterval.find(
              obj => obj.name === chartDropdown
            )?.value,
          },
        }
      );
      // console.log(response?.data);
      isMounted && setChartData(response?.data);
    } catch (err) {
      console.error(err);
      isMounted && setChartData({});
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                   effects                                  */
  /* -------------------------------------------------------------------------- */
  // when component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // fetch stats
  useEffect(() => {
    getUserStats();

    return () => {
      globalStatsController.abort();
    };
  }, [chartDropdown]);

  // prepares chart data on change
  useEffect(() => {
    setPreparedChartData(prepareChartData(chartData));
  }, [chartData]);

  /* -------------------------------------------------------------------------- */
  /*                                     DOM                                    */
  /* -------------------------------------------------------------------------- */
  return (
    <Card
      className={cn(styles.card, className)}
      title='Statystyki serwisu'
      classTitle='title-blue'
      head={
        <Dropdown
          value={chartDropdown}
          setValue={setChartDropdown}
          options={chartDropdownInterval.map(v => v.name)}
        />
      }
    >
      <div className={styles.chartContainer}>
        <div className={cn(styles.globalChart, styles.chartTotal)}>
          <div className={styles.chartTitle}>Wszystko</div>
          <GlobalChart data={preparedChartData.total} barColor='#b1e5fc' />
        </div>
        <div className={cn(styles.globalChart, styles.chartPosts)}>
          <div className={styles.chartTitle}>Posty</div>
          <GlobalChart data={preparedChartData.posts} />
        </div>
        <div className={cn(styles.globalChart, styles.chartCourses)}>
          <div className={styles.chartTitle}>Kursy</div>
          <GlobalChart data={preparedChartData.courses} barColor='#b5e4ca' />
        </div>
      </div>
    </Card>
  );
}
