import style from './SkeletonLoader.module.sass';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function SkeletonLoader({ cards }) {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <SkeletonTheme key={i}>
        <div className={style.skeleton} key={i}>
          <div className={style.left}>
            <Skeleton width={30} height={30} borderRadius={10} />
          </div>
          <div className={style.right}>
            <Skeleton width={100} height={30} borderRadius={10} />
          </div>
        </div>
      </SkeletonTheme>
    ));
}
