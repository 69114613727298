import Submenu from 'components/Submenu';
import React, { useEffect, useState, useRef } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import ReportBug from './ReportBug';
import styles from './Support.module.sass';
import ContactForm from 'screens/ContactForm';
import useBreakpoints from 'hooks/useBreakpoints';
import Dropdown from 'components/Dropdown';

const menu = [
  {
    title: 'Kontakt',
    icon: 'help',
    url: '/support/contact',
  },
  {
    title: 'Zgłoś błąd',
    icon: 'bug',
    url: '/support/report-bug',
  },
  // {
  //   title: 'Zadaj pytanie',
  //   icon: 'help',
  //   url: '/support/ask-question',
  // },
  {
    title: 'Polityka prywatności',
    icon: 'link',
    url: '/privacy',
  },
  {
    title: 'Regulamin',
    icon: 'link',
    url: '/statute',
  },
];

const Support = () => {
  /* --------------------------------- routing -------------------------------- */
  const history = useHistory();
  const { pathname } = useLocation();

  /* ---------------------------------- utils --------------------------------- */
  const firstRender = useRef(true);

  /* ------------------------------- breakpoints ------------------------------ */
  const [ref, setRef] = useState();
  const breakpoints = [
    ['desktop', Infinity],
    ['r-medium', 1012],
    ['mobile', 700],
  ];
  useBreakpoints(ref, breakpoints);

  /* ---------------------------- dropdown options ---------------------------- */
  const [currentSectionName, setCurrentSectionName] = useState(menu[0].title);

  useEffect(() => {
    switch (pathname) {
      case '/support/contact':
        setCurrentSectionName(menu.find(el => el.url === pathname).title);
        break;
      case '/support/report-bug':
        setCurrentSectionName(menu.find(el => el.url === pathname).title);
        break;
      case '/support/ask-question':
        setCurrentSectionName(menu.find(el => el.url === pathname).title);
        break;
      default:
        break;
    }
  }, [pathname]);

  /* ----------------------------- push to new tab ---------------------------- */
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    history.push(menu.find(el => el.title === currentSectionName).url);
  }, [currentSectionName]);

  /* -------------------------------------------------------------------------- */
  /*                                     DOM                                    */
  /* -------------------------------------------------------------------------- */
  return (
    <section ref={x => setRef(x)}>
      <div className={styles.container}>
        <Submenu menu={menu} />
        {/* <div className={styles.dropdown}>
        <Dropdown
          value={currentSectionName}
          setValue={setCurrentSectionName}
          options={menu.map(el => el.title)}
        />
      </div> */}
        <div className={styles.content}>
          <Switch>
            <Route exact path='/support/contact'>
              <ContactForm />
            </Route>
            <Route exact path='/support/report-bug'>
              <ReportBug />
            </Route>
          </Switch>
        </div>
      </div>
    </section>
  );
};

export default Support;
