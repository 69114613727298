import cn from 'classnames';
import styles from './PaginationGrowingFooter.module.sass';

const PaginationGrowingFooter = ({
  data,
  isLastPage,
  isLoading,
  setCurrentPage,
}) => (
  <>
    {data.length > 0 && (
      <div className={styles.foot}>
        {!isLastPage && (
          <button
            className={cn('button-stroke button-small', styles.button)}
            style={{ marginLeft: '12px' }}
            onClick={() => {
              !isLastPage && setCurrentPage(prevState => ++prevState);
            }}
          >
            {isLoading ? 'Ładowanie...' : 'Następna'}
          </button>
        )}
      </div>
    )}
  </>
);
export default PaginationGrowingFooter;
