import { accountsEndpoints, levelsEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import React, { useState, createContext, useEffect, useCallback } from 'react';

// Define logout timer, expiration time
let logoutTimer;

// Create authorization context
const AuthContext = createContext({
  token: '',
  isLoggedIn: false,
  userId: '',
  userImg: null,
  userName: '',
  blockedUsers: [],
  follows: [],
  isAuthor: false,
  isAdmin: false,
  isStaff: false,
  isSuperuser: false,
  levelData: {
    level: 'Level 0',
    currentXp: 0,
    currentRank: 'Brak',
    badge: null,
    xpToNextLevel: 1,
    thisLevelXp: 0,
  },
  xpPoints: 0,
  xpToNextLvl: 0,
  login: token => {},
  logout: () => {},
  changeUserImg: () => {},
  getLevel: () => {},
});

// Calculate remaining token's time
const calculateRemainingTime = expirationTime => {
  // Get current date
  const currentTime = new Date().getTime();
  // Get expiration date
  const adjExpirationTime = new Date(expirationTime).getTime();

  // Calculate time
  const remainingDuration = adjExpirationTime - currentTime;

  // Return remaining token's duration
  return remainingDuration;
};

// Retrieve stored token if token didn't expire
const retrieveStoredToken = () => {
  // Get token stored
  const storedToken = localStorage.getItem('bearerToken');
  const storedExpirationDate = localStorage.getItem('expirationTime');

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem('bearerToken');
    localStorage.removeItem('expirationTime');
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

export const AuthContextProvider = props => {
  const tokenData = retrieveStoredToken();
  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }
  // Set initalToken, if token is in Local Storage, if not storage returns null
  //const initialToken = localStorage.getItem("bearerToken");
  const initialUserId = localStorage.getItem('userId');
  const initialUserName = localStorage.getItem('userName');
  const initialIsAuthor = JSON.parse(localStorage.getItem('isAuthor'));
  const initialIsAdmin = JSON.parse(localStorage.getItem('isAdmin'));
  const initialIsSuperuser = JSON.parse(localStorage.getItem('isSuperuser'));

  const [token, setToken] = useState(initialToken);
  const [userId, setUserId] = useState(initialUserId);
  const [userName, setUserName] = useState(initialUserName);
  const [userImg, setUserImg] = useState(
    `${process.env.PUBLIC_URL}/images/default-avatar.png`
  );
  const [levelData, setLevelData] = useState({});

  // Permissions
  const [isAuthor, setIsAuthor] = useState(initialIsAuthor);
  const [isAdmin, setIsAdmin] = useState(initialIsAdmin);
  const [isStaff, setIsStaff] = useState(false);
  const [isSuperuser, setIsSuperuser] = useState(false);

  const [blockedUsers, setBlockedUsers] = useState([]);
  const [followedUsers, setFollowedUsers] = useState([]);
  const userIsLoggedIn = !!token;

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  });

  useEffect(() => {
    if (userIsLoggedIn) {
      getAccountInfo(token);
      getLevel();
    }
  }, [token, userId, userIsLoggedIn]);

  // Handle logout
  const logoutHandler = useCallback(() => {
    // Remove user data from context
    setToken(null);
    setUserImg(null);
    setUserName('');
    setIsAuthor(false);
    setIsAdmin(false);
    setIsStaff(false);
    setIsSuperuser(false);
    setLevelData({
      level: 'Level 0',
      currentXp: 0,
      currentRank: 'Brak',
      badge: null,
      xpToNextLevel: 1,
      thisLevelXp: 0,
    });

    // Remove user data from local storage
    localStorage.removeItem('bearerToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('isAuthor');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('isSuperuser');
    localStorage.removeItem('userName');
    localStorage.removeItem('expirationTime');

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  // Handle login
  const loginHandler = (token, expirationTime, redirect) => {
    // Set token, to given token
    setToken(token);
    getAccountInfo(token);

    // Set token in Local Storage
    localStorage.setItem('bearerToken', token);

    localStorage.setItem('expirationTime', expirationTime);
    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
    window.location.reload();

    redirect && redirect();
  };

  const getLevel = async () => {
    if (!userIsLoggedIn) return;
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: levelsEndpoints.userLevel,
        headers: { 'USER-ID': userId },
      });

      const data = response.data;
      const calcNextLevelXp =
        data.next_level_xp_required - data.current_level_xp;
      const calcCurrentLevelXp = data.current_xp - data.current_level_xp;

      setLevelData({
        level: data.current_level || 'Level 0',
        currentXp: calcCurrentLevelXp || 0,
        currentRank: data.current_order || 'Brak',
        badge: data.current_order_image || null,
        xpToNextLevel: calcNextLevelXp || 1,
        thisLevelXp: 0,
        allPointsData: {
          nextLvlPoints: data.next_level_xp_required,
          currentLvlPoints: data.current_level_xp,
          currentPoints: data.current_xp,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountInfo = async token => {
    try {
      const response = await devscntrAuth.request({
        method: 'GET',
        url: accountsEndpoints.userData.myAccount,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      localStorage.setItem('userId', response.data.data.user.id);
      setUserId(response.data.data.user.id);

      localStorage.setItem('userName', response.data.data.user.display_name);
      setUserName(response.data.data.user.display_name);

      localStorage.setItem('isAuthor', response.data.data.user.is_author);
      setIsAuthor(response.data.data.user.is_author);
      setIsAdmin(response.data.data.user.is_admin);
      localStorage.setItem('isAdmin', response.data.data.user.is_admin);
      setIsStaff(response.data.data.user.is_staff);
      //localStorage.setItem("isSuperuser", response.data.data.user.is_superuser);
      setIsSuperuser(response.data.data.user.is_superuser);

      setUserImg(
        response.data.data.user.image ||
          `${process.env.PUBLIC_URL}/images/default-avatar.png`
      );
    } catch (error) {
      console.log(error);
    }
  };

  const changeUserImgHandler = img => {
    setUserImg(img);
  };

  // GET BLOCK LIST----------------------------------------------------------GET BLOCK LIST
  const [refreshBlockList, setRefreshBlockList] = useState(false);

  const getBlockList = async signal => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: accountsEndpoints.userContent.userBlocked,
        signal: signal,
      });
      const data = response.data;
      const blockedIdList = data.map(item => item.blockeduser.id);
      setBlockedUsers(blockedIdList);
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
  };

  const updateBlockedUsers = id => {
    if (blockedUsers.includes(parseInt(id))) {
      setBlockedUsers(prev => prev.filter(item => item !== parseInt(id)));
    } else {
      setBlockedUsers(prev => [...prev, id]);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    userIsLoggedIn && getBlockList(signal);

    return () => {
      abortController.abort();
    };
  }, []);

  // GET BLOCK LIST----------------------------------------------------------GET BLOCK LIST
  // GET FOLLOW LIST----------------------------------------------------------GET FOLLOW LIST

  const getFollowsList = async signal => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: accountsEndpoints.userData.followUser,
        signal: signal,
      });
      const data = response.data;
      const followedIdList = data.map(user => user.id);
      setFollowedUsers(followedIdList);
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
  };

  const updateFollows = id => {
    if (followedUsers.includes(parseInt(id))) {
      setFollowedUsers(prev => prev.filter(item => item !== parseInt(id)));
    } else {
      setFollowedUsers(prev => [...prev, id]);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    userIsLoggedIn && getFollowsList(signal);

    return () => {
      abortController.abort();
    };
  }, []);

  // GET FOLLOW LIST----------------------------------------------------------GET FOLLOW LIST

  // Context values
  const contextValue = {
    userID: userId,
    userName: userName,
    // image
    userImg: userImg,
    changeUserImg: changeUserImgHandler,
    // blocked users
    blockedUsers: blockedUsers,
    updateBlockedUsers: updateBlockedUsers,
    // followed users
    follows: followedUsers,
    updateFollows: updateFollows,
    // permissions
    isAuthor: isAuthor,
    isAdmin: isAdmin,
    isStaff: isStaff,
    isSuperuser: isSuperuser,
    // level
    levelData: levelData,
    getLevel: getLevel,
    // login
    login: loginHandler,
    logout: logoutHandler,
    token: token,
    isLoggedIn: userIsLoggedIn,
  };

  // Return provider
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
