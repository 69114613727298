import React from 'react';
import style from './Loader.module.sass';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Loader = () => {
  return (
    <SkeletonTheme>
      <div className={style.container}>
        <Skeleton width={`100%`} height={150} />
        <Skeleton width={`100%`} height={600} />
        <Skeleton width={`100%`} height={900} />
      </div>
    </SkeletonTheme>
  );
};

export default Loader;
