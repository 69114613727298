import Card from 'components/Card';
import React from 'react';
import styles from './BigFiveTestCongrats.module.sass';

const BigFiveTestCongrats = () => {
  return (
    <Card
      title={'Gratulacje! Ukończyłeś/aś test osobowości'}
      classTitle={'title-blue'}
    >
      <p className={styles.text}>
        Poniżej znajdziesz szczegóły swojego wyniku oraz w jaki sposób Twoja
        osobowość może wpłynąć na wybór specjalizacji. Pamiętaj, że to są
        jedynie sugestie i nie należy ich brać za pewnik.
      </p>
    </Card>
  );
};

export default BigFiveTestCongrats;
