import React from 'react';
import styles from './QuizzesList.module.sass';
import QuizListItem from './components/QuizListItem';

const QuizzesList = ({ data }) => {
  const sortByDate = (a, b) => {
    try {
      return new Date(b.created_at) - new Date(a.created_at);
    } catch (error) {
      return 0;
    }
  };

  if (!data || data.length === 0)
    return (
      <div className={styles.empty}>
        <p className={styles.info}>Brak wypełnionych kwestionariuszy</p>
      </div>
    );

  return (
    <ul className={styles.list}>
      {data?.sort(sortByDate)?.map(item => (
        <QuizListItem key={item.id} data={item} />
      ))}
    </ul>
  );
};

export default QuizzesList;
