import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Card from 'components/Card';
import styles from './PromoCodes.module.sass';
import { showError } from 'utils/showError';
import Icon from 'components/Icon';
import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";
import Dropdown from 'components/Dropdown';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import DatePicker from '../DatePicker';
import Radio from 'components/Radio';

const PromoCodes = ({
  refresh
}) => {
  const authCtx = useAuth();
  const [visible, setVisible] = useState(false);
  const [codeValue, setCodeValue] = useState('');
  const [timeLimit, setTimeLimit] = useState(false);
  const [numberValue, setNumberValue] = useState('');
  const [percentageValue, setPercentageValue] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [useLimit, setUseLimit] = useState(false);
  const [amountOfUses, setAmountOfUses] = useState('');
  const [radioButton, setRadioButton] = useState(true)

  const dateHandler = (date) => {
    setExpirationDate(date);
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setVisible(false);
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  const validateInputs = () => {
    if (codeValue.trim(' ').length === 0) {
      showError('Kod nie może być pusty.');
      return false;
    }
    if (codeValue.trim(' ').length > 64) {
      showError('Kod nie być dłuższy niż 64 znaków.');
      return false;
    }
    if (numberValue.trim(' ').length === 0 && percentageValue.trim(' ').length === 0) {
      showError('Zniżka nie może być pusta.');
      return false;
    }
    if (isNaN(+numberValue)) {
      showError('Wartość zniżki (zł) musi być liczbą');
      return false;
    }
    if (percentageValue.trim(' ').length > 100) {
      showError('Zniżka (%) nie może być większa niż 100.');
      return false;
    }
    if (isNaN(+percentageValue)) {
      showError('Wartość zniżki (%) musi być liczbą.');
      return false;
    }
    if (timeLimit === true) {
      if (expirationDate === '' || !expirationDate) {
        showError('Data nie może być pusta.');
        return false;
      }
    }
    if (useLimit === true) {
      if (amountOfUses.trim('').length === 0) {
        showError('Ilosć użyć nie może być pusta.');
        return false;
      }
      if (isNaN(+amountOfUses)) {
        showError('Wartość ilości użyć musi być liczbą.');
        return false;
      }
    }
    return true;
  };

  const addCodeApi = async data => {
    try {
      const response = await devscntrAuth.post(
        'course/course-promo-code/',
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            method: 'create',
          },
        }
      );
      authCtx.getLevel();
      refresh && refresh();
      setVisible(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = e => e.key === 'Enter';

  const handleClearInputs = () => {
    setCodeValue('');
    setNumberValue('');
    setPercentageValue('');
    setExpirationDate('');
    setTimeLimit(false);
    setUseLimit(false);
    setAmountOfUses('');
  };

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const prepareFormData = () => {
    const formData = new FormData();

    //temporary solution
    let x = formatDate(expirationDate);
    let t = ' 00:00:00';
    let xt = x + t;

    formData.append(`code`, codeValue);
    formData.append(`number_value`, numberValue);
    formData.append(`percentage_value`, percentageValue);
    formData.append(`time_limited`, timeLimit);
    if (timeLimit) {
      formData.append(`expiration_date`, xt);
    }
    formData.append(`use_limited`, useLimit);
    if (useLimit) {
      formData.append(`number_of_uses_max`, amountOfUses);
    }
    return formData;
  }

  const handleSubmit = () => {
    if (validateInputs()) {
      const formData = prepareFormData();
      addCodeApi(formData);
      handleClearInputs();
    }
  };

  return (
    <div
      className={cn(styles.filters, { [styles.active]: visible })}
    >
      <button
        className={cn("button-square-stroke button-small", styles.head)}
        onClick={() => setVisible(true)}
      >
        <Icon name="add" size="24" />
      </button>
      <div className={styles.body}>
        <div className={styles.top}>
          <div className={cn("title-blue", styles.title)}>Kody rabatowe</div>
          <button className={styles.close} onClick={() => setVisible(false)}>
            <Icon name="close" size="20" />
          </button>
        </div>
        {/* ---------------------------------- Tworzenie kodów rabatowych ---------------------------------- */}
        <div>
          <div className={styles.input_container}>
            <div className={styles.input_title}>
              <TextInput
                label="Dodaj kod rabatowy"
                placeholder='Wpisz nazwę kodu rabatowego'
                maxLength='64'
                onKeyDown={handleKeyDown}
                value={codeValue}
                onChange={e => setCodeValue(e.target.value)}
              />
            </div>
            <div className={styles.radio_box}>
              <Checkbox
                content="Zniżka w zł"
                value={radioButton}
                onChange={()=>{setRadioButton(true);setPercentageValue('')}}
              />
              <Checkbox
                content="Zniżka w %"
                value={!radioButton}
                onChange={()=>{setRadioButton(false);setNumberValue('')}}
              />
            </div>

            <div className={styles.data_box}>
              {radioButton && (
                <TextInput
                  placeholder="Podaj zniżke zł"
                  value={numberValue}
                  onChange={e => setNumberValue(e.target.value)}
                />
              )}
              {!radioButton && (
                <TextInput
                  placeholder="Podaj zniżke %"
                  value={percentageValue}
                  onChange={e => setPercentageValue(e.target.value)}
                />
              )}
            </div>

            <div className={styles.checkbox_box}>
              <Checkbox
                content="Ograniczenie czasowe"
                value={timeLimit}
                onChange={(e) => {
                  setTimeLimit(e.target.checked);
                }}
              />
            </div>
            <div className={styles.data_box}>
              {timeLimit && (
                <DatePicker
                  value={dateHandler}
                />
              )}
            </div>

            <div className={styles.checkbox_box}>
              <Checkbox
                content="Ograniczona ilość użyć"
                value={useLimit}
                onChange={(e) => {
                  setUseLimit(e.target.checked);
                }}
              />
            </div>
            <div className={styles.data_box}>
              {useLimit && (
                <TextInput
                  placeholder="Podaj ilość użyć"
                  value={amountOfUses}
                  onChange={e => setAmountOfUses(e.target.value)}
                />
              )}
            </div>
          </div>
        </div>
        {/* ---------------------------------- Dodawanie kodów do kursów ---------------------------------- */}
        <div className={styles.modal_footer}>
          <button className={cn("button", styles.button)} onClick={handleSubmit}>
            Dodaj kod do listy.
          </button>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setVisible(false)}></div>
    </div>
  );
};

export default PromoCodes;