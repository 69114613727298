import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../../../components/Icon";
import { Link } from "react-router-dom";
import devscntrAuth from "../../../../../api/Instance/devscntrAuth";

const Item = ({className, item, isAllRead}) => {
    const [ visible, setVisible ] = useState(false);
    // Controlled input's value in reply
    const [ currentValue, setCurrentValue ] = useState("");
    const [ isRead, setIsRead ] = useState(false);
    const date = new Date(item.created_at);

    const setAsRead = () => {
        if (!isRead && !isAllRead) {
            devscntrAuth
                .delete(`/accounts/me/notification/`, {
                    headers: {
                        TYPE: "misc",
                        ID: item.id,
                    },
                })
                .then(() => {
                    setIsRead(true);
                });
        }
    };

    let icon = <Icon name="more-horizontal"/>;
    let text = item.text;

    switch (item.type) {
        case 'REACTION':
            icon = <Icon
                name={item.value === 1 ? "thumbs-up" : "thumbs-down"}
                viewBox="0 0 24 24"
            />
            text = "Zareagowano na "
            break;
        case 'PIN':
            icon = <Icon
                name="heart"
            />
            text = "Przypięto twoją publikację"
            break;
        case 'APPLICATION':
            icon = <Icon
                name="job"
            />
            text = "Zaaplikował(a) na twoją ofertę pracy"
            break;
        case 'COURSE':
            icon = <Icon
                name="store"
            />
            text = "Użytkownik którego obserwujesz dodał nowy kurs"
            break;
        case 'FOLLOW':
            icon = <Icon
                name="profile-circle"
            />
            text = "Użytkownik obserwuje Cię"
            break;
    }

    console.log(item)
    return (
        <div className={cn(styles.item, {[ styles.new ]: !isRead && !isAllRead}, className)}>
            <div
                className={cn(styles.isread, {[ styles.new ]: !isRead && !isAllRead})}
                onClick={setAsRead}
            >
                {
                    !isRead && !isAllRead
                        ? "Nowe"
                        : "Przeczytano"
                }
            </div>
            <div className={styles.avatar}>
                <img
                    src={
                        item.foreign_user_image
                            ? item.foreign_user_image
                            : "/images/default-avatar.png"
                    }
                    alt="Avatar"
                />
                <div className={styles.icon}>
                    {icon}
                </div>
            </div>
            <div className={styles.details}>
                <div className={styles.line}>
                    <div className={styles.subtitle}>
                        {item?.joboffer_title && "Oferta pracy " + item?.joboffer_title}
                        {item?.course_id && <Link to={'/course/'+item?.course_id} className={styles.link}>Nowy kurs</Link>}
                        {item?.type === "FOLLOW" && <Link to={'/profile/'+item?.foreign_user} className={styles.link}>{item?.foreign_user_name}</Link>}
                    </div>
                    <div className={styles.time}>{date.toLocaleString()}</div>
                </div>
                <div className={styles.content}>
                    {item?.type !== "FOLLOW" && item?.foreign_user_name}
                    <p>
                        {text}
                        {item.type == "REACTION" && <Link
                            className={styles.link}
                            to={
                                item.post
                                    ? `/post/${item.post_id}`
                                    : `/course/${item.course}`
                            }
                        >
                            {item.post ? "Twój post" : "Twój kurs"}
                        </Link>}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Item;
