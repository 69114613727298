import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Interests.module.sass';
import Icon from 'components/Icon';

const Interests = ({ interests, setInterests, setInterestsRemoveList }) => {
  const [interest, setInterest] = useState('');

  const addButton = () => {
    setInterests(interests => [...interests, { name: interest }]);
    setInterest('');
  };

  const handleRemove = (e, index) => {
    e.preventDefault();

    for (let i = 0; i < interests.length; i++) {
      if (index === i) {
        interests[i].remove = true;
        setInterestsRemoveList(interestsRemoveList => [
          ...interestsRemoveList,
          interests[i],
        ]);
      }
    }

    setInterests(prev => prev.filter((_, i) => index !== i));
  };

  return (
    <div className={styles.container}>
      <div className={cn('title-red', styles.header)}>
        <div className={styles.header_text}>Zainteresowania</div>
      </div>
      {interests.map((interest, index) => (
        <div className={styles.input_container} key={index}>
          <input
            className={cn(styles.input, styles.input_title)}
            value={interest.name}
            maxLength='100'
            readOnly
            disabled
          />
          <button
            className={cn('button', styles.remove_button)}
            onClick={e => handleRemove(e, index)}
          >
            <Icon name='close' size={18} />
          </button>
        </div>
      ))}
      <div className={styles.input_container}>
        <input
          placeholder='Podaj swoje zainteresowanie'
          className={cn(styles.input, styles.input_title)}
          value={interest}
          onChange={e => setInterest(e.target.value)}
          maxLength='100'
        />
        <button className={cn('button')} onClick={addButton}>
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default Interests;
