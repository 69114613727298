import React, { useState, useEffect, useRef } from 'react';
import cn from "classnames";
import styles from './CourseChoice.module.sass';
import Icon from 'components/Icon';
import devscntrAuth from 'api/Instance/devscntrAuth';
import Checkbox from 'components/Checkbox';
import useAuth from 'hooks/useAuth';
import { CropSquareSharp } from '@mui/icons-material';
import ModalDraft from 'components/ModalDraft';

export default function CourseChoice(
    {
        checkedPublications,
        disabled,
        refresh,
    }) {
    const [visible, setVisible] = useState(false);
    const [coursesList, setCoursesList] = useState([]);
    const controller = new AbortController();
    const [chosenCourses, setChosenCourses] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const prevPage = useRef(1);
    const [lastPage, setLastPage] = useState(1);
    const pageSize = 10;

    const getCourses = async () => {
        try {
            const response = await devscntrAuth.get(
                `/publikacje/publication/v2/?&page_size=${pageSize}&page=${currentPage}&filter=courses`,
                {
                    signal: controller.signal,
                },
            );
            setCoursesList(response?.data?.data);
            setLastPage(response?.data?.pages);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getCourses();

        return () => {
            controller.abort();
        };
    }, [visible, currentPage]);

    useEffect(()=>
    {
        setChosenCourses([]);
    },[visible]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setVisible(false);
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const handleSelect = e => {
        const row = e.target.closest('.row');
        const rowId = +row.getAttribute('data-item-id');
        const checked = e.target.checked;

        const currPublication = coursesList.find(
            pub => pub.id === rowId
        );

        if (
            !chosenCourses.find(
                checked => checked.id === rowId
            )
        ) {
            setChosenCourses(latest => [...latest, currPublication]);
        }

        if (!checked) {
            setChosenCourses(latest =>
                latest.filter(obj => !(obj.id === rowId))
            );
        }
    };

    const closeHandle = () => {
        setVisible(false);
    }

    const prepareFormData = () => {
        var temp = [];

        const formData = (checkedPublications.map(item =>
        (
            temp = [...new Set([...item.courses, ...chosenCourses])],
            {
                id: item.id,
                code: item.code,
                courses: temp.map(course => (
                    { id: course.id }
                ))
            }
        )))
        return formData;
    }

    const addPromoCodes = async data => {
        try {
            await Promise.all(data.map(async (item) => {
                const response = await devscntrAuth.post(
                    'course/course-promo-code/',
                    item,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            method: 'edit',
                        },
                    }
                );
            }))
            refresh && refresh();
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = () => {
        if (chosenCourses.length > 0) {
            const formData = prepareFormData();
            addPromoCodes(formData);
            setVisible(false);
        }
    };

    return (
        <div
            className={cn(styles.modal_body, { [styles.active]: visible })}
        >
            <button
                className={cn(styles.choiceButton, {
                    [styles.disabled]: !(checkedPublications.length > 0)
                }, { [styles.enabled]: checkedPublications.length > 0 })}
                onClick={() => setVisible(true)}
                disabled={disabled}
            >
                <Icon name="multiselect" size="24" />
            </button>
            <div className={styles.body}>
                <div className={styles.top}>
                    <div className={cn("title-blue", styles.title)}>Panel przypisywania kodów rabatowych</div>
                    <button className={styles.close} onClick={() => closeHandle()}>
                        <Icon name="close" size="20" />
                    </button>
                </div >
                <div className={styles.itemTitle}>
                    Kursy:
                </div>
                {coursesList.map(item => {
                    return (
                        <div key={item.id} data-item-id={item.id} className={cn(styles.row, 'row')}>
                            <Checkbox
                                value={!!chosenCourses.find(
                                    checked =>
                                        checked.id === item.id
                                )}
                                onChange={handleSelect}
                            />
                            {item.title}
                        </div>
                    )
                })}

                {/* ------------------------------- PAGINATION ------------------------------- */}
                {lastPage > 1 && coursesList.length > 0 && (
                    <div className={styles.foot}>
                        {/* --------------------------- 3 DOTS AFTER FIRST --------------------------- */}
                        {currentPage > 2 && (
                            <>
                                <button
                                    className={styles.pagination_numbers}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        setCurrentPage(() => {
                                            prevPage.current = currentPage;
                                            return 1;
                                        });
                                    }}
                                >
                                    {1}
                                </button>
                                ...
                            </>
                        )}
                        {/* ----------------------- NUMBERS (PREV, CURR, NEXT) ----------------------- */}
                        {currentPage > 1 && (
                            <button
                                className={styles.pagination_numbers}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    setCurrentPage(prevState => {
                                        prevPage.current = currentPage;
                                        return --prevState;
                                    });
                                }}
                            >
                                {currentPage - 1}
                            </button>
                        )}
                        {currentPage !== 0 && (
                            <button
                                className={styles.pagination_numbers}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                {currentPage}
                            </button>
                        )}
                        {currentPage < lastPage && (
                            <button
                                className={styles.pagination_numbers}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    setCurrentPage(prevState => {
                                        prevPage.current = currentPage;
                                        return ++prevState;
                                    });
                                }}
                            >
                                {currentPage + 1}
                            </button>
                        )}
                        {/* --------------------------- 3 DOTS BEFORE LAST --------------------------- */}
                        {currentPage < lastPage - 1 && (
                            <>
                                ...
                                <button
                                    className={styles.pagination_numbers}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        setCurrentPage(() => {
                                            prevPage.current = currentPage;
                                            return lastPage;
                                        });
                                    }}
                                >
                                    {lastPage}
                                </button>
                            </>
                        )}
                    </div>
                )}

                <button
                    className={cn(styles.footerButton, {
                        [styles.disabledFooter]: !(chosenCourses.length > 0)
                    }, { [styles.enabledFooter]: chosenCourses.length > 0 })}
                    onClick={handleSubmit}
                >
                    Dodaj {checkedPublications.length} kody rabatowe do {chosenCourses.length} kursów.
                </button>
            </div>
            <div className={styles.overlay} onClick={() => closeHandle()}></div>
        </div >
    )
}