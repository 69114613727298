import React from 'react';
import cn from 'classnames';
import styles from './Item.module.sass';
import Icon from '../../../Icon';
import { Link } from 'react-router-dom';

const links = {
  post: '/post',
  course: '/course',
  job: '/jobs',
};

const Item = ({ className, item, onClick, type }) => {
  //const type = item?.type?.toLowerCase() || "";
  const link = links[type];
  const author = type !== 'job' ? item.display_name : item.company_name;
  const imgSrc = type !== 'job' ? item.image : item.company_logo;

  return (
    <div className={cn(styles.item, className)}>
      <Link to={`${link}/${item.slug}`} className={styles.link} onClick={onClick}>
        <div
          className={cn(
            styles.preview,
            type !== 'job' ? styles.cover : styles.contain
          )}
        >
          <img
            src={imgSrc || '/images/image-placeholder.png'}
            alt='Miniaturka'
          />
        </div>
        <div className={styles.details}>
          <div className={styles.content}>{author || 'Brak nazwy'}</div>
          <div className={styles.title}>{item?.title || 'Brak tytułu'}</div>
        </div>
      </Link>
      {/* <button className={styles.close}>
        <Icon name="close" size="24" />
      </button> */}
    </div>
  );
};

export default Item;
