import React, { useRef, useState } from 'react';
import cn from 'classnames';
import styles from './Entry.module.sass';
import TextInput from 'components/TextInput';
import Image from 'components/Image';
import Icon from 'components/Icon';
import PhoneInput from 'react-phone-number-input';
import PhoneNumberInput from 'components/PhoneNumberInput';
import Loader from 'components/Loader';
import Checkbox from 'components/Checkbox';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

const Entry = ({
  onConfirm,
  onChange,
  phoneData,
  onPhoneChange,
  registerData,
  setRegisterData,
  errorMsg,
  isLoading,
}) => {
  //const [phone, setPhone] = useState("");
  return (
    <div className={styles.entry}>
      {/* <div className={styles.head}>
        <div className={styles.info}>
          Zarejestruj się przez konto zewnętrzne
        </div>
        <div className={styles.btns}>
          <button className={cn("button-stroke", styles.button)}>
            <img src="/images/content/google.svg" alt="Google" />
            Google
          </button>
          <button className={cn("button-stroke", styles.button)}>
            <Image
              className={styles.pic}
              src="/images/content/apple-dark.svg"
              srcDark="/images/content/apple-light.svg"
              alt="Apple"
            />
            Apple ID
          </button>
        </div>
      </div> */}
      <div className={styles.body}>
        {/* <div className={styles.info}>Lub kontynuuj z adresem email</div> */}
        {errorMsg && (
          <div className={styles.error}>
            <Icon name='info' size='24' />
            {errorMsg}
          </div>
        )}
        <TextInput
          className={styles.field}
          name='email'
          type='email'
          placeholder='Email'
          required
          icon='mail'
          onChange={onChange}
          value={registerData.email}
        />
        <TextInput
          className={styles.field}
          name='displayName'
          type='text'
          placeholder='Nazwa użytkownika'
          required
          icon='smile'
          onChange={onChange}
          value={registerData.displayName}
        />
        {/* <TextInput
          className={styles.field}
          name="phone"
          type="text"
          placeholder="Your phone"
          required
          icon="phone"
          onChange={onChange}
          value={registerData.phone}
        /> */}
        {/* <PhoneNumberInput
          className={styles.field}
          name='phone'
          value={phoneData}
          onChange={onPhoneChange}
        /> */}
        <TextInput
          className={`${styles.field}`}
          name='passwd'
          type='password'
          placeholder='Hasło'
          required
          icon='lock'
          onChange={onChange}
          value={registerData.passwd}
        />
        <TextInput
          className={styles.field}
          name='passwd2'
          type='password'
          placeholder='Powtórz hasło'
          required
          icon='lock'
          onChange={onChange}
          value={registerData.passwd2}
        />
        <div className={cn(styles.acceptance_links)}>
          <Link
            to='/privacy'
            target='_blank'
            rel='noopener noreferrer'
            className={cn(styles.field, styles.privacy_link)}
          >
            Polityka prywatności
          </Link>
          &nbsp;i&nbsp;
          <Link
            to='/statute'
            target='_blank'
            rel='noopener noreferrer'
            className={cn(styles.field, styles.privacy_link)}
          >
            Regulamin
          </Link>
        </div>

        <Checkbox
          className={cn(styles.field, styles.acceptance)}
          content='* Niniejszym wyrażam zgodę na przetwarzanie moich danych osobowych podanych przeze mnie w formularzu rejestracyjnym konta na portalu https://www.itcntr.com/'
          value={registerData.acceptPersonalData}
          onChange={() =>
            setRegisterData(prev => ({
              ...prev,
              acceptPersonalData: !prev.acceptPersonalData,
            }))
          }
        />
        <Checkbox
          className={cn(styles.field, styles.acceptance)}
          content='* Zapoznałem się z treścią polityki prywatności https://www.itcntr.com/ w tym z informacją o celu i sposobach przetwarzania danych osobowych oraz prawie dostępu do treści swoich danych i prawie ich poprawiania.'
          // content={`* Zapoznałem się z treścią ${(
          //   <Link to="/privacy">polityki prywatności</Link>
          // )} https://www.itcntr.com/ w tym z informacją o celu i sposobach przetwarzania danych osobowych oraz prawie dostępu do treści swoich danych i prawie ich poprawiania.`}
          value={registerData.acceptPrivacyPolicy}
          onChange={() =>
            setRegisterData(prev => ({
              ...prev,
              acceptPrivacyPolicy: !prev.acceptPrivacyPolicy,
            }))
          }
        />
        <Button
          isLoading={isLoading}
          disabled={
            !(
              registerData.acceptPersonalData &&
              registerData.acceptPrivacyPolicy
            ) || isLoading
          }
          classNames={styles.button}
          onClick={e => onConfirm(e)}
          title={'Zarejestruj się'}
        >
          Zarejestruj się
        </Button>

        <div className={styles.note} style={{ textAlign: 'center' }}>
          This site is protected by reCAPTCHA and the Google{' '}
          <a href='https://policies.google.com/privacy'>Privacy Policy</a> and{' '}
          <a href='https://policies.google.com/terms'>Terms of Service</a>{' '}
          apply.
        </div>
        {/* <button
          className={cn(
            'button',
            styles.button,
            isLoading && styles.disabled,
            !registerData.acceptPersonalData && styles.disabled,
            !registerData.acceptPrivacyPolicy && styles.disabled
          )}
          onClick={e => onConfirm(e)}
          type='submit'
          disabled={
            isLoading ||
            !registerData.acceptPersonalData ||
            !registerData.acceptPrivacyPolicy
          }
        >
          <Loader className={cn(styles.loader, isLoading && styles.visible)} />
          Zarejestruj się
        </button> */}
        {/* <div className={styles.note}>
          Ta strona jest zabezpieczona przez reCAPTCHA i Politykę Prywatności
          Google
        </div> */}
      </div>
    </div>
  );
};

export default Entry;
