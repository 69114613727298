import React, { useEffect, useState } from 'react';
import styles from './Levels.module.sass';
import cn from 'classnames';
import devscntrAuth from 'api/Instance/devscntrAuth';
import axios from 'axios';
import { adminEndpoints } from 'api/endpoints';
import Card from 'components/Card';
import Icon from 'components/Icon';
import { showError } from 'utils/showError';
import Modal from 'components/Modal';
import AddLevel from './AddLevel';
import useAuth from 'hooks/useAuth';
import { validateInput } from 'utils/validation';
import uuid from 'react-uuid';

const Levels = () => {
  // UTILS-------------------------------------------------------------------------UTILS
  const authCtx = useAuth();

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);

  const [levels, setLevels] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [currentEditId, setCurrentEditId] = useState(null);
  const [editName, setEditName] = useState('');
  const [editPoints, setEditPoints] = useState('');

  // UTILS-------------------------------------------------------------------------UTILS
  // GET LEVELS---------------------------------------------------------------GET LEVELS

  const getLevels = async cancelToken => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: adminEndpoints.settings.levels,
        cancelToken: cancelToken.token,
      });
      setLevels(
        response.data.map(item => ({ ...item, name: item.name.substring(6) }))
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('admin levels canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getLevels(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [refresh]);

  // GET LEVELS---------------------------------------------------------------GET LEVELS
  // ADD LEVEL-----------------------------------------------------------------ADD LEVEL

  const onAddLevel = () => {
    setAddModalVisible(false);
    setRefresh(prev => !prev);
    authCtx.getLevel();
  };

  // ADD LEVEL-----------------------------------------------------------------ADD LEVEL
  // EDIT LEVEL---------------------------------------------------------------EDIT LEVEL

  const validation = () => {
    if (!validateInput(editName, 20, 1, 'level', 'numbers')) return false;
    if (!validateInput(editPoints, 20, 1, 'min. punkty', 'numbers'))
      return false;

    return true;
  };

  const editLevel = item => {
    setCurrentEditId(item.id);
    setEditName(item.name);
    setEditPoints(item.from_xp);
  };

  const saveEditLevel = async () => {
    if (!validation()) return;
    try {
      console.log(currentEditId);
      const response = await devscntrAuth.put(
        `${adminEndpoints.settings.levels}${currentEditId}/`,
        {
          name: `Level ${editName.trim()}`,
          from_xp: editPoints,
        }
      );
      setCurrentEditId(null);
      setRefresh(prev => !prev);
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
      showError('Nie udało się zapisać zmian');
    }
  };

  // EDIT LEVEL---------------------------------------------------------------EDIT LEVEL
  // DELETE LEVEL-----------------------------------------------------------DELETE LEVEL

  const deleteLevel = async id => {
    if (confirmDeleteId !== id) {
      setConfirmDeleteId(id);
      setTimeout(() => {
        setConfirmDeleteId(null);
      }, 10000);
    } else {
      try {
        const response = await devscntrAuth.delete(
          `${adminEndpoints.settings.levels}${id}/`
        );
        console.log(id);
        setRefresh(prev => !prev);
        authCtx.getLevel();
      } catch (error) {
        console.log(error);
        showError('Nie udało się zapisać zmian');
      }
    }
  };

  // DELETE LEVEL-----------------------------------------------------------DELETE LEVEL

  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title='Zarządzanie poziomami'
      classTitle={cn('title-yellow', styles.title)}
      head={
        <button className='button' onClick={() => setAddModalVisible(true)}>
          <Icon name='add' size={20} />
          Dodaj poziom
        </button>
      }
    >
      <Modal
        visible={addModalVisible}
        onClose={() => setAddModalVisible(false)}
      >
        <AddLevel onSuccess={onAddLevel} />
      </Modal>
      <div className={styles.container}>
        <table>
          <thead>
            <tr>
              <th>Level</th>
              <th>Min. punkty</th>
              <th>Edycja</th>
            </tr>
          </thead>
          <tbody>
            {levels.map(item =>
              currentEditId !== item.id ? (
                <tr key={uuid()}>
                  <td width='40%'>{item.name}</td>
                  <td width='40%'>{item.from_xp}</td>
                  <td className={styles.buttons} width='20%'>
                    <button
                      className={styles.edit_button}
                      onClick={() => editLevel(item)}
                    >
                      <Icon name='edit' size={20} />
                    </button>
                    <button
                      className={cn(
                        styles.delete_button,
                        confirmDeleteId == item.id && styles.confirm_delete
                      )}
                      onClick={() => deleteLevel(item.id)}
                    >
                      <Icon name='trash' size={20} />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td width='40%'>
                    <input
                      type='text'
                      value={editName}
                      className={styles.input}
                      onChange={e => setEditName(e.target.value)}
                    />
                  </td>
                  <td width='40%'>
                    <input
                      type='text'
                      value={editPoints}
                      className={styles.input}
                      onChange={e => setEditPoints(e.target.value)}
                    />
                  </td>
                  <td className={styles.buttons} width='20%'>
                    <button
                      className={styles.edit_button}
                      onClick={saveEditLevel}
                    >
                      <Icon name='check' size={20} />
                    </button>
                    <button
                      className={styles.cancel_button}
                      onClick={() => setCurrentEditId(null)}
                    >
                      <Icon name='close' size={20} />
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default Levels;
