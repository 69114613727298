import React, { useEffect, useRef, useState } from 'react';
import styles from './MiniJobOffer.module.sass';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import Icon from '../Icon';
import {
  jobContractType,
  jobFormat,
  currencyList,
  jobSeniority,
} from '../../data/dataArrays';
import Actions from 'components/Actions';
import useAuth from 'hooks/useAuth';
import ModalPin from 'components/ModalPin';
import { showLoginModal } from 'utils/showLoginModal';
import Thumbnail from 'components/MiniItemsLayout/Thumbnail';
import Tags from 'components/MiniItemsLayout/Tags';
import MoreOptions from 'components/MiniItemsLayout/MoreOptions';
import useBreakpoints from 'hooks/useBreakpoints';

import relatedStyles from './RelatedMiniJob.module.sass';
import LocationsList from 'components/LocationsList';
import Locations from 'components/MiniItemsLayout/Locations';

const currencyCode = currencyList[0].code;

const MiniJobOffer = React.forwardRef(
  ({ article, related = false, classNames, hideThumbnail, hideTags }, ref) => {
    const authCtx = useAuth();
    const [hidden, setHidden] = useState(false);
    const [extendLocations, setExtendLocations] = useState(false);

    const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
    const breakpoints = [
      ['desktop', Infinity],
      ['mobile', 716],
      ['tiny', 300],
    ];
    useBreakpoints(stylesRef, breakpoints);

    const now = new Date();
    const date = new Date(article?.created_at);
    const diff = now - date;
    const daysAgo = Math.ceil(diff / (1000 * 3600 * 24));

    // -----------------------------------------------------
    // <----- !!!! PRZYPINKI !!!! ----->
    // -----------------------------------------------------
    const [pinState, setPinState] = useState(article?.pinned);
    const [StarModalVisible, setStarModalVisible] = useState(false);
    const location = useLocation();

    // <----- WALIDACJA ODPINANIA ----->
    const pinsPath = location.pathname.slice(0, 5);
    const [redirectedUser, setRedirectedUser] = useState();
    const [unpinPost, setunpinPost] = useState();

    const unpinHandler = () => {
      if (!authCtx.isLoggedIn) {
        showLoginModal();
        return;
      }

      if (pinsPath === '/pins') {
        setunpinPost(true);
      } else {
        setRedirectedUser(true);
      }
    };
    const pinHandler = () => {
      if (!authCtx.isLoggedIn) {
        showLoginModal();
        return;
      }
      // console.log('ARTICLE', article)

      setStarModalVisible(true);
    };
    // -----------------------------------------------------
    // -----------------------------------------------------

    return (
      article &&
      !hidden && (
        <article ref={x => setStylesRef(x)}>
          {/* <----- !!!! MODALPIN !!!! -----> */}

          {(StarModalVisible || redirectedUser || unpinPost) && (
            <ModalPin
              visible={StarModalVisible}
              onClose={() => setStarModalVisible(false)}
              article={article}
              setPinState={setPinState}
              redirectedUser={redirectedUser}
              setRedirectedUser={setRedirectedUser}
              unpinPost={unpinPost}
              setunpinPost={setunpinPost}
              pinUrl={'/jobs/user-job/'}
              idName={'job_id'}
            />
          )}
          <div
            className={cn(
              //!related ? styles.item : relatedStyles.item,
              styles.item,
              classNames && classNames
            )}
            ref={ref}
          >
            {!hideThumbnail && (
              <Link
                to={`/jobs/${article.id}`}
                // className={styles.image}
                //className={!related ? styles.image : relatedStyles.postimage}
                className={styles.image}
              >
                <Thumbnail
                  className={cn(
                    article?.company_logo && styles.image_wrapper,
                    related && styles.fixed_image_wrapper,
                    article.is_rss && styles.no_padding
                  )}
                  image={article?.company_logo}
                  imageFit={
                    !article?.company_logo && related ? 'cover' : 'contain'
                  }
                />
              </Link>
            )}
            <div className={styles.details}>
              <section>
                <div className={styles.head}>
                  <div
                    className={cn(styles.author, {
                      [styles.one_line]: related,
                    })}
                  >
                    {article?.company_name || 'Brak nazwy firmy'}
                  </div>

                  {!hideTags && (
                    <Tags
                      className={styles.tags}
                      tags={article?.tags?.sort(
                        (a, b) => b.tags_count - a.tags_count
                      )}
                      baseLink='/jobs'
                    />
                  )}

                  {!related && (
                    <div className={styles.actions}>
                      <button
                        className={styles.pin_btn}
                        onClick={pinState ? unpinHandler : pinHandler}
                      >
                        <img
                          src={
                            pinState
                              ? '/images/Icons/heart/filled-color.svg'
                              : '/images/Icons/heart/stroke.svg'
                          }
                          alt='favourite'
                        />
                      </button>

                      <MoreOptions
                        item={article}
                        authorId={article?.author_id || null}
                        onDelete={() => setHidden(true)}
                        type='job'
                      />
                    </div>
                  )}
                </div>
                <div>
                  <Link
                    to={`/jobs/${article.id}`}
                    style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    <div className={styles.title}>
                      {article?.title || 'Brak tytułu'}
                    </div>
                  </Link>
                  {article?.seniority && (
                    <div className={styles.seniority}>
                      {jobSeniority[article?.seniority - 1]}
                    </div>
                  )}
                </div>
              </section>

              <section>
                {/* <div className={styles.row}>
                <div className={styles.div_placeholder}></div>
                <div className={styles.format}>
                  {jobFormat[article?.format - 1]}
                </div>
                </div> */}
                <div className={cn(styles.row, styles.col_reverse)}>
                  <div className={styles.salary}>
                    {article?.salary?.length < 1 && <p>Brak podanej pensji</p>}
                    {article?.salary?.length > 0 && (
                      <>
                        <p>{jobContractType[article?.salary[0]?.type - 1]}: </p>
                        {article?.salary[0].type == '1'
                          ? `${parseFloat(
                              article?.salary[0]?.hour_wage
                            ).toLocaleString()} ${currencyCode}/h`
                          : `${parseFloat(
                              article?.salary[0]?.salary_from
                            ).toLocaleString()} ${currencyCode} - ${parseFloat(
                              article?.salary[0]?.salary_to
                            ).toLocaleString()} ${currencyCode}`}
                      </>
                    )}
                  </div>

                  {article?.format && (
                    <div className={styles.format}>
                      {jobFormat[article?.format - 1]}
                    </div>
                  )}

                  {/* {article?.tags?.length > 0 && (
                    <Tags className={styles.tags} tags={article?.tags} />
                  )} */}
                </div>
                {!related && (
                  <div
                    className={cn(styles.row, styles.foot, styles.mobile_row)}
                  >
                    {/* {article?.localization?.length > 0 && (
                      <LocationsList
                        className={styles.locations}
                        locations={article?.localization || []}
                      />
                    )} */}
                    {article?.localization?.length > 0 ? (
                      <Locations
                        className={styles.locations}
                        locations={article?.localization || []}
                      />
                    ) : (
                      <p className={styles.localization_info}>
                        Brak lokalizacji
                      </p>
                    )}
                    {/* <div className={styles.locations}>
                      <img
                        src={'/images/Icons/location/stroke.svg'}
                        alt='Localization'
                      />
                      {article?.localization?.length > 0 ? (
                        <>
                          {article?.localization[0]?.city}
                          {article?.localization?.length > 1 &&
                            `, +${article?.localization?.length - 1} inne`}
                          {article?.localization?.length > 1 && (
                            <button
                              className={styles.extend_locations}
                              onClick={() => setExtendLocations(prev => !prev)}
                            >
                              <Icon
                                name='arrow-down'
                                size='20'
                                className={extendLocations && styles.rotate180}
                              />
                            </button>
                          )}
                          <div
                            className={cn(
                              styles.show_locations,
                              extendLocations && styles.active
                            )}
                          >
                            <ul>
                              {article?.localization?.map((location, index) => (
                                <Link to={`/jobs/${article.id}`} key={index}>
                                  <li>
                                    ul. {location.street} {location.number},{' '}
                                    {location.city}
                                  </li>
                                </Link>
                              ))}
                            </ul>
                          </div>
                        </>
                      ) : (
                        'Brak lokalizacji'
                      )}
                    </div> */}
                    <div className={styles.date}>
                      {/* {daysAgo < 2 ? (
                  <div className={styles.new_badge}>Nowe</div>
                ) : (
                  article?.created_at?.slice(0, 10)
                )} */}
                      {article?.created_at?.slice(0, 10)}
                    </div>
                  </div>
                )}
              </section>
            </div>
          </div>
        </article>
      )
    );
  }
);

export default MiniJobOffer;
