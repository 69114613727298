import React from 'react';
import cn from 'classnames';
import styles from './Panel.module.sass';
import Icon from 'components/Icon';
import Actions from 'components/Actions';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import Button from 'components/Button';

const Panel = ({
  setVisiblePreview,
  setVisibleSchedule,
  onSubmit,
  isLoading = false,
  buttonText,
  article = null,
  clearInputs,
  saveDraft,
}) => {
  const actions = [
    {
      title: 'Podgląd',
      icon: 'expand',
      action: () => setVisiblePreview(true),
    },
    {
      title: 'Zapisz szkic',
      icon: 'edit',
      action: () => saveDraft(),
    },
    {
      title: 'Zaplanuj publikację',
      icon: 'calendar',
      action: () => setVisibleSchedule(true),
    },
    // {
    //   title: 'Udostępnij',
    //   icon: 'link',
    //   action: () => console.log('Get shareable link'),
    // },
    {
      title: 'Wyczyść pola',
      icon: 'close',
      action: () => clearInputs && clearInputs(),
    },
  ];

  return (
    <div className={cn(styles.panel)}>
      <div className={styles.info}>
        <Link className={cn('button-stroke', styles.button)} to='/'>
          <Icon name='arrow-left' size='24' />
          <span className={styles.buttonText}>Powrót</span>
        </Link>
        {article?.updated_at && (
          <div className={styles.lastSave}>
            <Icon name='check-all' size='24' />
            Ostatnia aktualizacja <span>{new Date(article?.updated_at).toLocaleString() || '-'}</span>
          </div>
        )}
      </div>
      <div className={styles.btns}>
        <Button isLoading={isLoading} disabled={isLoading} onClick={onSubmit}>
          {buttonText || 'Opublikuj'}
        </Button>
        <Actions
          className={styles.actions}
          classActionsHead={styles.actionsHead}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          items={actions}
          up
        />
      </div>
    </div>
  );
};

export default Panel;
