export default {
  domain: 'N',
  title: 'Neurotyzm',
  shortDescription: 'Neurotyzm odnosi się do skłonności do doświadczania negatywnych uczuć.',
  description: 'Neurotyzm to cecha osobowości odnosząca się do stopnia, w jakim osoba doświadcza negatywnych emocji, takich jak lęk, gniew, smutek czy poczucie winy. Osoby o wysokim poziomie neurotyzmu mogą być bardziej podatne na stres i negatywne myśli. Z drugiej strony, osoby o niskim poziomie neurotyzmu zazwyczaj są bardziej stabilne emocjonalnie i mniej podatne na stres. Oto pięć mocnych stron zarówno dla wysokiego, jak i niskiego wyniku w neurotyzmie.',
  results: [
    {
      score: 'low', // do not translate this line
      text: 'Twój wynik w dziedzinie Neurotyczności jest niski, co oznacza, że jesteś wyjątkowo spokojny, opanowany i niewzruszony. Nie reagujesz intensywnymi emocjami, nawet na sytuacje, które większość osób uważałaby za stresujące.'
    },
    {
      score: 'neutral', // do not translate this line
      text: 'Twój wynik w dziedzinie Neurotyczności jest średni, co oznacza, że twoja reaktywność emocjonalna jest typowa dla ogółu społeczeństwa. Sytuacje stresujące i frustrujące nieco cię niepokoją, ale z reguły jesteś w stanie poradzić sobie z tymi uczuciami i radzić sobie z takimi sytuacjami.'
    },
    {
      score: 'high', // do not translate this line
      text: 'Twój wynik w dziedzinie Neurotyczności jest wysoki, co oznacza, że łatwo się denerwujesz, nawet na to, co większość osób uważa za normalne trudności życia. Uważa się, że jesteś osobą wrażliwą i emocjonalną.'
    }
  ],
  facets: [
    {
      facet: 1,
      title: 'Lęk',
      text: 'U osób z wysokim wynikiem na skali lęku układ "walcz lub uciekaj" w mózgu łatwo i często jest aktywowany. Dlatego osoby o wysokim poziomie lęku często mają poczucie, że coś niebezpiecznego jest o krok. Mogą obawiać się konkretnych sytuacji lub ogólnie być bojaźliwe. Czują się spięte, nerwowe i zdenerwowane. Osoby o niskim poziomie lęku są zwykle spokojne i obawami reagują w sytuacjach większego zagrożenia.'
    },
    {
      facet: 2,
      title: 'Złość',
      text: 'Osoby o wysokim wyniku w dziedzinie złości czują się wściekłe, gdy rzeczy nie idą po ich myśli. Są wrażliwe na sprawiedliwe traktowanie i czują się urażone i rozgoryczone, gdy mają wrażenie, że są oszukiwane. Ta skala mierzy skłonność do odczuwania złości; czy osoba wyraża irytację i wrogość, zależy od jej poziomu w skali Ugodowości. Osoby o niskim wyniku rzadko i niełatwo się denerwują nawet, jeśli doświadczają pewnej niesprawiedliwości w życiu.'
    },
    {
      facet: 3,
      title: 'Smutek',
      text: 'Ta skala mierzy skłonność do odczuwania smutku, przygnębienia i zniechęcenia. Osoby o wysokim wyniku mają brak energii i mają trudności z inicjowaniem działań. Osoby o niskim wyniku zwykle są wolne od uczuć smutku.'
    },
    {
      facet: 4,
      title: 'Skrępowanie',
      text: 'Skrępowane osoby są wrażliwe na to, co inni o nich myślą. Ich obawy o odrzucenie i kpiny powodują, że czują się nieśmiali i niewygodnie w obecności innych. Łatwo się zawstydzają i często odczuwają wstyd. Ich obawy, że inni ich skrytykują lub będą się z nich nabijać, są często przesadzone i nierealistyczne, ale ich niezgrabność w zachowaniu i objawiając się poczucie dyskomfortu mogą sprawić, że te lęki stają się samospełniającą się przepowiednią. Osoby o niskim wyniku nie mają przekonania, że wszyscy ich obserwują i oceniają. Nie czują się zdenerwowane w sytuacjach społecznych.'
    },
    {
      facet: 5,
      title: 'Brak umiaru',
      text: 'Osoby wysokim wyniku na skali braku umiaru odczuwają silne pragnienia i impulsy, którym mają trudności się oprzeć. Są skoncentrowane na krótkoterminowych przyjemnościach i nagrodach. Nie rozważają specjalnie długoterminowych konsekwencji. Osoby o niskim wyniku nie doświadczają silnych, nieodpartych pragnień i dlatego nie czują pokusy, aby się nadmiernie rozpieszczać.'
    },
    {
      facet: 6,
      title: 'Podatność',
      text: 'Osoby o wysokim wyniku w dziedzinie podatności doświadczają paniki, zamieszania i bezradności w sytuacjach stresowych lub pod presją. Osoby o niskim wyniku czują się bardziej zrównoważone, pewne siebie i potrafią klarownie myśleć w sytuacjach stresowych.'
    }
  ]
};
