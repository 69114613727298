import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { guestModalSlice } from "./guestModal/slice";
import { jobsMapSlice } from "./jobsMap/slice";
import { messageSlice } from "./message/slice";
import { reportModalSlice } from "./reportModal/slice";
import { shareModalSlice } from "./shareModal/slice";
import { userInfoSlice } from "./userInfo/slice";

export const rootRecucer = combineReducers({
  message: messageSlice.reducer,
  jobsMap: jobsMapSlice.reducer,
  userInfo: userInfoSlice.reducer,
  guestModal: guestModalSlice.reducer,
  shareModal: shareModalSlice.reducer,
  reportModal: reportModalSlice.reducer,
});
export const store = configureStore({
  reducer: rootRecucer,
  devTools: process.env.NODE_ENV !== "production",
});
