import React from 'react';
import styles from './Author.module.sass';
import cn from 'classnames';
import { Link } from 'react-router-dom';

const Author = ({ className, id, username, image, level, isRss, forAdmin }) => {
  // const authCtx = useAuth();
  // const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  // const [isLoading, setIsLoading] = useState(false);

  /* ---------------------------------- utils --------------------------------- */
  const userLevel = level?.error ? level.current_level : null;
  const path = forAdmin ? '/admin/users/' : '/profile/';

  return isRss || !id ? (
    <div
      className={cn(styles.author, styles.authorRss, className && className)}
    >
      <div className={styles.author_image}>
        <img src={image || '/images/default-avatar.png'} alt='' />
      </div>
      <span>{username || 'Brak nazwy'}</span>
    </div>
  ) : (
    <Link
      className={cn(styles.author, className && className)}
      to={`${path}${id}`}
    >
      <div className={styles.author_image}>
        <img src={image || '/images/default-avatar.png'} alt='' />
      </div>
      <span>{username || 'Brak nazwy'}</span>
      {userLevel && <div className={styles.level}>{userLevel}</div>}
    </Link>
  );
  // <Link className={cn(styles.author, className && className)} to={`/profile/${id}`}>
  //   <div className={styles.author_image}>
  //     <img src={image || "/images/default-avatar.png"} alt="" />
  //   </div>
  //   <span>{username || "Brak nazwy"}</span>
  //   {userLevel && <div className={styles.level}>{userLevel}</div>}
  // </Link>
};

export default Author;
