import React from 'react';
import styles from './Editor.module.sass';
import cn from 'classnames';
import { Editor as ReactEditor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Tooltip from '../Tooltip';

const Editor = ({
  editorState,
  onEditorChange,
  classEditor,
  label,
  classLabel,
  tooltip,
  place,
  button,
}) => {
  return (
    <div
      className={cn(
        styles.editor,
        { [styles.editorButton]: button },
        classEditor
      )}
    >
      {label && (
        <p className={cn(classLabel, styles.label)}>
          {label}{' '}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon='info'
              place={place ? place : 'right'}
            />
          )}
        </p>
      )}
      <ReactEditor
        editorState={editorState}
        toolbarClassName={styles.editorToolbar}
        wrapperClassName={styles.editorWrapper}
        editorClassName={styles.editorMain}
        onEditorStateChange={onEditorChange}
        toolbar={{
          //options: ["inline", "emoji", "link", "list", "textAlign", "history"],
          options: ['inline', 'emoji', 'list', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          // link: {
          //   options: ['link'],
          // },
          list: {
            options: ['unordered'],
          },
          // textAlign: {
          //   options: ["center"],
          // },
        }}
      />
      {button && (
        <button className={cn('button-small', styles.button)}>{button}</button>
      )}
    </div>
  );
};

export default Editor;
