import React from "react";
import style from "./Skeleton.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TechnologyLoader = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      // zrobic light mode
      <SkeletonTheme key={i}>
        <div className={style.skeleton} key={i}>
          <div className={style.section}>
            <div className={style.contentTech}>
              <Skeleton width={200} className={style.text} />
              <Skeleton count={6} />
            </div>
          </div>

          <div className={style.Techsection}>
            <div className={style.content}>
              <Skeleton width={150} height={30} className={style.text} />
              <div className={style.techtag}>
                <Skeleton height={35} />
                <Skeleton height={35} />
                <Skeleton height={35} />
              </div>
            </div>
            <Skeleton width={80} borderRadius={80} height={80} />
          </div>
          <div className={style.Techsection}>
            <div className={style.content}>
              <Skeleton width={150} height={30} className={style.text} />
              <div className={style.techtag}>
                <Skeleton height={35} />
                <Skeleton height={35} />
                <Skeleton height={35} />
              </div>
            </div>
            <Skeleton width={80} borderRadius={80} height={80} />
          </div>
          <div className={style.Techsection}>
            <div className={style.content}>
              <Skeleton width={150} height={30} className={style.text} />
              <div className={style.techtag}>
                <Skeleton height={35} />
                <Skeleton height={35} />
                <Skeleton height={35} />
              </div>
            </div>
            <Skeleton width={80} borderRadius={80} height={80} />
          </div>
          

          <div className={style.section}>
            <div className={style.contentTech}>
              <Skeleton width={200} className={style.text} />
              <Skeleton count={6} />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default TechnologyLoader;
