import React, { useEffect, useState } from 'react';
import styles from './Item.module.sass';
import cn from 'classnames';

import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import FollowButton from 'components/FollowButton';

const Item = ({ user }) => {
  // UTILS-------------------------------------------------------------------------------UTILS

  const authCtx = useAuth();

  // UTILS-------------------------------------------------------------------------------UTILS

  return (
    <div className={styles.container}>
      <Link to={`/profile/${user.id}`} className={styles.row}>
        <div className={styles.image}>
          <img src={user?.image || '/images/default-avatar.png'} alt='' />
        </div>
        <div className={styles.username}>
          {user?.display_name || 'Brak nazwy'}
        </div>
      </Link>
      {authCtx.isLoggedIn && <FollowButton id={user.id} />}
    </div>
  );
};

export default Item;
