import React, { useState, useEffect } from 'react';
import styles from './EarningsBar.module.sass';

const EarningsBar = ({
  object,
  seniority,
  minItemRange,
  maxItemRange,
  empty,
}) => {
  const [minRange, setMinRange] = useState(0);
  const [maxRange, setMaxRange] = useState(0);
  const [minArray, setMinArray] = useState([]);
  const [maxArray, setMaxArray] = useState([]);

  function averageSalaryFormat(salary) {
    return salary?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  useEffect(() => {
    seniority.forEach(element => {
      const tempMinRange = object[element][object[element].length - 1].y[0];
      const tempMaxRange = object[element][object[element].length - 1].y[1];
      if (minRange < tempMinRange) {
        setMinArray(minArray => [...minArray, tempMinRange]);
      }
      if (maxRange < tempMaxRange) {
        setMaxArray(maxArray => [...maxArray, tempMaxRange]);
      }
    });
  }, [object]);

  useEffect(() => {
    setMinRange(Math.min(...minArray));
  }, [minArray]);

  useEffect(() => {
    setMaxRange(Math.max(...maxArray));
  }, [maxArray]);

  return (
    <>
      <div className={styles.earnings_bar_container}>
        <div className={styles.earnings_bar}></div>
        <div
          className={styles.earnings_bar_horizontal_line}
          style={
            !empty
              ? {
                  width: `${Math.ceil(
                    ((maxItemRange - minItemRange) / (maxRange - minRange)) * 90
                  )}%`,
                  left: `${Math.floor(
                    ((minItemRange - minRange) / (maxRange - minRange)) * 90
                  )}%`,
                }
              : {
                  width: `50%`,
                  left: `20%`,
                }
          }
        >
          <div className={styles.earnings_bar_vertical_line}></div>
        </div>
      </div>
      <>
        <div className={styles.earnings_bar_info_left}>
          {!empty ? <>{averageSalaryFormat(minRange)} PLN</> : <>0</>}
        </div>
        <div className={styles.earnings_bar_info_right}>
          {!empty ? <>{averageSalaryFormat(maxRange)} PLN</> : <>0</>}
        </div>
      </>
    </>
  );
};
export default EarningsBar;
