import React, { useState } from 'react';
import styles from './LocationsList.module.sass';
import cn from 'classnames';
import uuid from 'react-uuid';
import Icon from 'components/Icon';
import OutsideClickHandler from 'react-outside-click-handler';

const LocationsList = ({ className, locations }) => {
  const [visible, setVisible] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.container, className)}>
        <div className={styles.main_location}>
          <img
            src={'/images/Icons/location/stroke-nopadding.svg'}
            alt='Localization'
          />
          {locations?.length > 0 ? (
            <div className={styles.content}>
              {locations[0]?.city}
              {locations?.length > 1 && `, +${locations?.length - 1} inne`}
              {locations?.length > 1 && (
                <button
                  className={cn(styles.extend_locations, {
                    [styles.active]: visible,
                  })}
                  onClick={() => setVisible(prev => !prev)}
                >
                  <Icon
                    name='arrow-down'
                    size='20'
                    className={visible && styles.rotate180}
                  />
                </button>
              )}
            </div>
          ) : (
            'Brak lokalizacji'
          )}
        </div>

        {locations?.length > 0 && visible && (
          <ul className={styles.list}>
            {locations.map(item => (
              <li className={styles.item} key={uuid()}>
                ul. {item.street} {item.number}, {item.code} {item.city}
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default LocationsList;
