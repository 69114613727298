import React from 'react';
import styles from './CategoriesList.module.sass';
import cn from 'classnames';
import uuid from 'react-uuid';
import Category from 'components/MiniItemsLayout/Categories/Category';
import { useHistory } from 'react-router';

const CategoriesList = ({ className, categories, baseLink }) => {
  const history = useHistory();
  return (
    <div className={cn(styles.container, className && className)}>
      <div className={cn('title-green', styles.title)}>Kategorie</div>
      <ul className={styles.categories}>
        {categories?.map(item => (
          <Category
            classNames={styles.category}
            key={uuid()}
            name={item?.name || 'Brak nazwy'}
            color={item?.color || '#ffffff'}
            onClick={() => {
              baseLink && history.push(`${baseLink}?categories=${encodeURIComponent(item?.name)}`);
            }}
          />
        ))}
      </ul>
    </div>
  );
};

export default CategoriesList;