import React, { useState } from 'react';
import styles from './More.module.sass';
import Actions from 'components/Actions';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import {
  coursesEndpoints,
  postsEndpoints,
  supportEndpoints,
  jzwitv2Endpoints
} from 'api/endpoints';
import { deleteTimeout } from 'data/dataArrays';
import ModalReport from 'components/ModalReport';
import { showError } from 'utils/showError';

const types = {
  post: postsEndpoints.createComment,
  course: coursesEndpoints.createComment,
  jzwit: `${jzwitv2Endpoints.addComment}`,
};

const reportEndpoints = {
  post: supportEndpoints.reportContent.posts.comment,
  course: supportEndpoints.reportContent.courses.comment,
};

const More = ({ id, author, type, refresh }) => {
  const authCtx = useAuth();
  const [reportVisible, setReportVisible] = useState(false);

  // USED BY DELETE
  const [confirm, setConfirm] = useState(false);
  const [allowClick, setAllowClick] = useState(true);
  const [deleteCountdown, setDeleteCountdown] = useState(false);

  const deleteUrl = types[type] ?? '';
  const handleDelete = () => {
    if (deleteUrl === '') return;
    console.log('delete comment');
    console.log({ id: id, author: author });

    if (confirm && allowClick) {
      try {
        const response = devscntrAuth.delete(`${deleteUrl}${id}/`);
        console.log(response);
        setTimeout(refresh, 1000);
      } catch (error) {
        showError('Nie udało się usunąć komentarza. Spróbuj ponownie później.');
      }
      setConfirm(false);
    } else {
      if (allowClick) {
        // Show countdown in option
        setDeleteCountdown(deleteTimeout / 1000);
        let timer = setInterval(() => {
          setDeleteCountdown(prev => {
            if (prev === 0) clearInterval(timer);
            else return prev - 1;
          });
        }, 1000);
        // Disable click (anti double-click)
        setAllowClick(false);
        setTimeout(() => setAllowClick(true), deleteTimeout);
        setTimeout(() => setConfirm(false), 10000);
      }
      setConfirm(true);
    }
  };

  const reportUrl = reportEndpoints[type] ?? '';
  const handleReport = async (title, description) => {
    try {
      const response = await devscntrAuth.post(reportUrl, {
        comment: id,
        title: title,
        description: description,
      });
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
      showError('Zgłoszenie nie powiodło się. Spróbuj ponownie później');
    }
  };

  const actions = [
    // {
    //   title: "Edytuj",
    //   icon: "edit",
    //   action: () => console.log('test'),
    // },
    type !== 'jzwit' && {
      title: 'Zgłoś',
      icon: 'ticket',
      action: () => setReportVisible(true),
    },
    ((authCtx.isAdmin || author === authCtx?.userID) && {
      title: confirm ? 'Potwierdź' : 'Usuń',
      icon: 'trash',
      color: confirm,
      badge: deleteCountdown > 0 ? deleteCountdown : false,
      action: handleDelete,
    }),
  ];

  return (
    <>
      <ModalReport
        visible={reportVisible}
        onClose={() => setReportVisible(false)}
        onSubmit={handleReport}
      />
      {authCtx.isLoggedIn && (
        <Actions
          className={styles.menu}
          classActionsHead={styles.settings}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          items={actions}
        />
      )}
    </>
  );
};
export default More;
