import React, { useEffect, useState } from 'react';
import styles from './Company.module.sass';
import cn from 'classnames';
import Item from '../Item';
import Icon from 'components/Icon';

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import Editor from 'components/Editor';
import TextInput from 'components/TextInput';
import { validateInput, validateUrl } from 'utils/validation';
import PhoneNumberInput from 'components/PhoneNumberInput';
import Dropdown from 'components/Dropdown';
import { companySize } from 'data/dataArrays';
import devscntrAuth from 'api/Instance/devscntrAuth';
import ThumbnailEditor from 'components/ThumbnailEditor';
import { showError } from 'utils/showError';
import { showSuccess } from 'utils/showSuccess';
import Loader from 'components/Loader';
import LocationsInput from 'components/InputBlocks/LocationsInput';
import Button from 'components/Button';
import { accountsEndpoints } from 'api/endpoints';

const Company = ({ data, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyData, setCompanyData] = useState({
    id: data?.id || null,
    companyName: data?.name || '',
    companyTaxNumber: data?.NIP || '',
    companyUrl: data?.website || '',
    companyEmail: data?.mail || '',
    companyPhone: data?.phone || '',
    companySize: companySize[companySize.indexOf(data?.size)] || companySize[0],
    onlyRemote: false,
  });
  const [phone, setPhone] = useState(data?.phone || '');
  const [companyLocations, setCompanyLocations] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [descriptionTooLong, setDescriptionTooLong] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setCompanyData({
      id: data?.id || null,
      companyName: data?.name || '',
      companyTaxNumber: data?.NIP || '',
      companyUrl: data?.website || '',
      companyEmail: data?.mail || '',
      companyPhone: data?.phone || '',
      companySize:
        companySize[companySize.indexOf(data?.size)] || companySize[0],
      onlyRemote: false,
    });
    setPhone(data?.phone || '');
    if (data?.description) {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(data?.description))
        ) || EditorState.createEmpty()
      );
    }
    setCompanyLogo(data?.logo || null);
    if (data?.locations?.length > 0) {
      setCompanyLocations(
        data.locations.map(item => ({
          city: item.city || '',
          code: item.postcode || '',
          street: item.street || '',
          number: item.house_number || '',
          location_lat: null,
          location_lon: null,
        }))
      );
    }
  }, [data]);

  const handleRemoveImage = () => {};

  const handleEditorStateChange = newEditorState => {
    if (
      convertToRaw(newEditorState.getCurrentContent()).blocks[0].text.length <=
      5000
    ) {
      setEditorState(newEditorState);
      setDescriptionTooLong(false);
    } else setDescriptionTooLong(true);
  };

  // Handle inputs changes
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setCompanyData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSizeChange = value => {
    setCompanyData(prev => ({ ...prev, companySize: value }));
  };

  const checkFields = () => {
    if (!validateInput(companyData.companyName, 255, 3, 'Nazwa firmy'))
      return false;
    if (!validateInput(companyData.companyTaxNumber, 10, 10, 'NIP', 'numbers'))
      return false;
    if (!validateUrl(companyData.companyUrl)) return false;
    if (!validateInput(companyData.companyEmail, 100, 3, 'E-mail'))
      return false;

    return true;
  };

  const prepareFormData = () => {
    const formData = new FormData();

    // BASIC DATA
    formData.append('name', companyData.companyName.trim());
    formData.append('phone', phone.trim());
    formData.append('mail', companyData.companyEmail.trim());
    formData.append('website', companyData.companyUrl.trim());
    formData.append('NIP', companyData.companyTaxNumber.trim());
    formData.append('size', companyData.companySize);
    formData.append(
      'description',
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );

    // LOCATIONS
    if (companyLocations.length > 0) {
      companyLocations.forEach((item, index) => {
        formData.append(`locations[${index}]city`, item.city);
        formData.append(`locations[${index}]postcode`, item.code);
        formData.append(`locations[${index}]street`, item.street);
        formData.append(`locations[${index}]house_number`, item.number);
      });
    }

    // IMAGE
    if (file) {
      formData.append('logo', file);
    }

    return formData;
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (checkFields()) {
      const preparedData = prepareFormData();
      updateCompany(preparedData);
    }
  };

  const updateCompany = async data => {
    setIsLoading(true);
    try {
      const response = await devscntrAuth.request({
        method: 'post',
        url: accountsEndpoints.company.create,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
          method: 'create',
        },
      });
      showSuccess('Zaktualizowano dane firmy');
      refresh && refresh();
    } catch (error) {
      console.log(error);
      showError(
        'Nie udało się zaktualizować danych firmy. Spróbuj ponownie później.'
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(file);
  }, [file]);

  return (
    <Item
      className={cn(styles.card)}
      title='Moja firma'
      classTitle='title-blue'
    >
      <h3 className={styles.heading}>Informacje podstawowe</h3>
      <div className={styles.profile}>
        <ThumbnailEditor
          className={styles.image_editor}
          img={companyLogo}
          setFile={setFile}
        />
      </div>
      <div className={styles.basic}>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              label='Nazwa firmy'
              name='companyName'
              type='text'
              tooltip='Nazwa twojej firmy'
              value={companyData.companyName}
              onChange={handleChange}
            />
          </div>
          <div className={styles.col}>
            <Dropdown
              label='Liczba pracowników'
              value={companyData.companySize}
              setValue={handleSizeChange}
              options={companySize}
              classDropdownHead='Liczba pracowników'
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              label='Numer NIP'
              name='companyTaxNumber'
              type='text'
              tooltip='Numer identyfikacji podatkowej twojej firmy'
              value={companyData.companyTaxNumber}
              onChange={handleChange}
            />
          </div>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              label='Strona internetowa (adres URL)'
              name='companyUrl'
              type='text'
              tooltip='Wpisz link do strony internetowej Twojej firmy'
              value={companyData.companyUrl}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <Editor
            editorState={editorState}
            onEditorChange={handleEditorStateChange}
            classEditor={styles.field}
            label='O firmie'
            tooltip='Opisz działaność swojeje firmy (max 5000 znaków)'
          />
          {descriptionTooLong && (
            <div className={styles.errormessage}>*Max 5000 znaków</div>
          )}
        </div>
      </div>
      <div className={styles.contact}>
        <h3 className={styles.heading}>Dane kontaktowe</h3>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              label='Adres email'
              name='companyEmail'
              type='email'
              tooltip='Adres e-mail kontaktowy firmy'
              value={companyData.companyEmail}
              onChange={handleChange}
            />
          </div>
          <div className={styles.col}>
            <PhoneNumberInput
              className={styles.field}
              label='Numer telefonu'
              name='phone'
              type='text'
              tooltip='Wpisz numer telefonu firmy, wybierz kraj'
              value={phone}
              onChange={setPhone}
            />
          </div>
        </div>
      </div>
      <LocationsInput
        className={styles.locations_input}
        locations={companyLocations}
        setLocations={setCompanyLocations}
      />
      <Button
        classNames={styles.loader}
        isLoading={isLoading}
        onClick={handleSubmit}
      >
        Zapisz zmiany
      </Button>
    </Item>
  );
};

export default Company;
