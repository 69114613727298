import React, { useContext, useState } from 'react';
import styles from './EditorTiny.module.sass';
import BundledEditor from './BundledEditor';
import Tooltip from 'components/Tooltip';
import cn from 'classnames';
import AuthContext from 'context/AuthContext';
import {
  codeLanguages,
  plugins,
  menuUser,
  menuAdmin,
  toolbarUser,
  toolbarAdmin,
} from './editorConfig';

export default function EditorTiny({
  state,
  setState,
  label,
  tooltip,
  place = 'right',
  classEditor,
}) {
  const [, refresh] = useState();

  const authCtx = useContext(AuthContext);
  const isAdmin = authCtx.isAdmin;

  const onToolbar = isAdmin
    ? toolbarAdmin.join(' | ')
    : toolbarUser.join(' | ');

  const handleChange = content => {
    setState(content);
  };

  return (
    <div className={cn(styles.editor, classEditor)}>
      {label && (
        <p className={cn(styles.label)}>
          {label}{' '}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon='info'
              place={place}
            />
          )}
        </p>
      )}
      <BundledEditor
        initialValue=''
        onInit={refresh}
        value={state}
        onEditorChange={handleChange}
        init={{
          height: 500,
          menubar: true,
          menu: isAdmin ? menuAdmin : menuUser,
          plugins: plugins,
          toolbar: onToolbar,
          content_style:
            'body { font-family: Inter,sans-serif; font-size:16px }',
          codesample_languages: codeLanguages,
          codesample_global_prismjs: true,
        }}
      />
    </div>
  );
}
