import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import styles from './Page.module.sass';
import Sidebar from '../Sidebar';
import Header from '../Header';
import GoBackButton from 'components/GoBackButton';
import ScrollToTop from 'components/ScrollToTop';

const Page = ({ wide, children, title, backButton, backPath }) => {
  // sidebar active state
  const [visible, setVisible] = useState(
    JSON.parse(localStorage.getItem('sidebarExpand')) ?? false
  );
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isMounted) {
      localStorage.setItem('sidebarExpand', visible);
    } else {
      // if width > 1024 sidebar is expanded on first visit
      if (JSON.parse(localStorage.getItem('sidebarExpand')) === null) {
        const isMobile = window.innerWidth < 1024;
        if (!isMobile) {
          setVisible(true);
        }
      }
      setIsMounted(true);
    }
  }, [visible]);

  return (
    <div id='page' className={styles.page_container}>
      <Sidebar
        className={cn(styles.sidebar, { [styles.visible]: visible })}
        onClose={() => setVisible(false)}
        visible={visible}
        setVisible={setVisible}
      />
      <div className={styles.page}>
        <Header sidebarExpanded={visible} onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            {backButton && (
              <GoBackButton
                className={cn(
                  styles.go_back_button,
                  title && styles.more_margin
                )}
                path={backPath}
              />
            )}
            {title && <div className={cn('h3', styles.title)}>{title}</div>}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Page);
