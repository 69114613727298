import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Overview.module.sass';
import TooltipGlodal from 'components/TooltipGlodal';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { setShortenAmount } from 'utils';

const intervals = ['Ostatnie 7 dni', 'Ostatnie 30 dni', 'Cały okres'];

const Overview = ({ className, userStats }) => {
  const [sorting, setSorting] = useState(intervals[0]);

  const items = [
    {
      title: 'Zalogowani dzisiaj',
      counter: setShortenAmount(userStats.todayUsers),
      icon: 'profile-circle',
      color: '#B5E4CA',
      tooltip: 'Ilość użytkowników którzy się dzisiaj zalogowali',
      value: 37.8,
    },
    {
      title: 'Aktywni',
      counter: setShortenAmount(userStats.activeNonRssUsers),
      icon: 'profile-circle',
      color: '#CABDFF',
      tooltip: 'Ilość obecnie aktywnych użytkowników',
      value: -17.8,
    },
    {
      title: 'Ilość użytkoników ogółem',
      counter: setShortenAmount(userStats.nonRssUsers),
      icon: 'profile-circle',
      color: '#FFBC99',
      tooltip: 'Ilość wszystkich użytkowników na naszej stronie',
      value: 24.3,
    },
    {
      title: 'Ilość adminów',
      counter: setShortenAmount(userStats.adminUsers),
      icon: 'profile-circle',
      color: '#B1E5FC',
      tooltip: 'Ilość adminów na naszej stronie',
      value: -11.3,
    },
  ];

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title='Statystyki o użytkownikach'
        classTitle='title-red'
        // head={
        //   <Dropdown
        //     className={styles.dropdown}
        //     classDropdownHead={styles.dropdownHead}
        //     value={sorting}
        //     setValue={setSorting}
        //     options={intervals}
        //     small
        //   />
        // }
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <Icon name={x.icon} size='24' />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>
                    {x.title}
                    <Tooltip
                      className={styles.tooltip}
                      title={x.tooltip}
                      icon='info'
                      place='top'
                    />
                  </div>
                  <div className={styles.counter}>{x.counter}</div>
                  {/* <div className={styles.indicator}>
                    <Balance className={styles.balance} value={x.value} />
                    <span>this week</span>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
