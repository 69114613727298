import React, { useEffect, useState } from 'react';
import styles from './ApplicationDetails.module.sass';
import cn from 'classnames';
import { accountsEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { Link, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import CvDetails from 'screens/Account/CV/CvDetails';
import Icon from 'components/Icon';
import MiniJobOffer from 'components/MiniJobOffer';
import MiniJob from 'components/MiniJob';

const ApplicationDetails = ({ classNames, recruiter = false }) => {
  // UTILS ----------------------------------------------------------------UTILS

  const { id } = useParams();
  const [data, setData] = useState(null);

  // UTILS ----------------------------------------------------------------UTILS
  // GET JOB APPLICATIONS ----------------------------------GET JOB APPLICATIONS

  const getJobApplications = async signal => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: `${accountsEndpoints.documents.jobApplications.show}${id}/`,
        signal: signal,
        headers: {
          method: 'employer',
        },
      });
      let fileName = null;
      let data = response.data;
      if (response?.data?.file) {
        fileName = response.data.file.split('/').pop();
      }
      data = { ...data, filename: fileName };
      setData(data);
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    getJobApplications(signal);

    return () => {
      abortController.abort();
    };
  }, []);

  // GET JOB APPLICATIONS ----------------------------------GET JOB APPLICATIONS

  return (
    <div className={cn(styles.container, classNames && classNames)}>
      <h3 className={cn('title-yellow', styles.title)}>Aplikacja</h3>
      {data ? (
        <>
          <section className={styles.section}>
            <div className={styles.subsection}>
              <h4 className={styles.subsection_title}>Wiadomość</h4>
              {data?.info || 'Brak informacji o aplikacji'}
            </div>
            <div className={styles.subsection}>
              <h4 className={styles.subsection_title}>Załączniki</h4>
              <div className={styles.attachments}>
                {data?.file ? (
                  <a
                    href={data.file}
                    target='_blank'
                    className={styles.attachment}
                  >
                    <Icon name='download' size={20} />
                    <span className={styles.attachment_name}>
                      {data.filename || 'plik.pdf'}
                    </span>
                  </a>
                ) : (
                  <p className={styles.info}>Brak załączników</p>
                )}
              </div>
            </div>
          </section>

          <h3 className={cn('title-blue', styles.title)}>Curriculum vitae</h3>
          <section className={styles.section}>
            <CvDetails cvData={data.cv} image={data.author_image} />
          </section>

          {data?.job && (
            <>
              <h3 className={cn('title-yellow', styles.title)}>Oferta pracy</h3>
              <section className={styles.section}>
                <MiniJob
                  article={data.job}
                  className={styles.job}
                  hideActions
                />
              </section>
            </>
          )}
        </>
      ) : (
        <p className={styles.info}>Brak danych</p>
      )}
    </div>
  );
};

export default ApplicationDetails;
