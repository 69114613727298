import React, { useContext, useEffect, useState } from 'react';
import Editor from '../../../components/Editor';
import TextInput from '../../../components/TextInput';
import styles from './Trends.module.sass';
import gt from '../NewTechnology.module.sass';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import FormContext from 'context/FormContext';
import EditorTiny from 'components/EditorTiny';
import Icon from 'components/Icon';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { jzwitv2Endpoints } from 'api/endpoints';
import Section from '../DynamicSection/Section/Section';

const Trends = ({ editTrendsData }) => {
  const formCtx = useContext(FormContext);

  let trendsData = {
    id: '',
    subtitle: '',
    title: '',
  }
  const [formData, updateFormData] = useState(trendsData);
  const [trendsArray, setTrendsArray] = useState([])
  const [removedTrends, setRemovedTrends] = useState([])

  const updateTitle = (value, i) => {
    const temp = trendsArray.map((obj, index) => {
      if (index === i) {
        return { ...obj, title: value }
      }
      return obj
    })
    setTrendsArray(temp)
  }

  const submit = e => {
    e.preventDefault();
  };

  useEffect(() => {
    setTrendsArray(editTrendsData);
  }, [editTrendsData]);

  const updateSubTitle = (value, i) => {
    const temp = trendsArray.map((obj, index) => {
      if (index === i) {
        return { ...obj, subtitle: value }
      }
      return obj
    })
    setTrendsArray(temp)
  }

  const addTrends = () => {
    setTrendsArray([...trendsArray, formData]);
  }

  useEffect(() => {
    addTrends();
  }, []);

  useEffect(() => {
    formCtx.setTrendsData(trendsArray);
  }, [trendsArray]);

  useEffect(() => {
    formCtx.setDeletedTrends(removedTrends);
  }, [removedTrends]);

  const deleteHandler = (id) => {
    setTrendsArray(item => {
      setRemovedTrends([...removedTrends, item.filter((_, i) => i === id)])
      return item.filter((_, i) => i !== id)
    })
  }

  return (
    <>
      <form className={styles.form} onSubmit={submit}>
        {trendsArray?.map((section, index) => {
          return (
            <Section
              isTrend
              key={index}
              form={section}
              index={index}
              formFields={trendsArray}
              setFormFields={setTrendsArray}
              onDelete={() => deleteHandler(index)}
              itsEdit={trendsArray}
            />
          );
        })}
      </form>

      <button onClick={() => addTrends()} className={styles.btn_add}>
        Dodaj trend
      </button>
    </>
  );
};

export default Trends;