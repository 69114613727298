import React, { useState } from 'react';
import styles from './ResetPassword.module.sass';
import cn from 'classnames';
import { Link, useParams } from 'react-router-dom';
import Image from 'components/Image';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { use100vh } from 'react-div-100vh';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import { accountsEndpoints } from 'api/endpoints';
import { validateInput } from 'utils/validation';
import { validationRules } from 'data/validationRules';
import { showError } from 'utils/showError';

const ResetPassword = () => {
  // UTILS---------------------------------------------------------------------------------UTILS

  const heightWindow = use100vh();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { uidb64, token } = useParams();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  // UTILS---------------------------------------------------------------------------------UTILS
  // VALIDATION-----------------------------------------------------------------------VALIDATION

  const validation = () => {
    // if (
    //   !validateInput(
    //     email,
    //     validationRules.email.maxChars,
    //     validationRules.email.minChars,
    //     validationRules.email.name,
    //     validationRules.email.security
    //   )
    // ) {
    //   return false;
    // }

    if (
      !validateInput(
        password,
        validationRules.password.maxChars,
        validationRules.password.minChars,
        validationRules.password.name,
        validationRules.password.security
      )
    ) {
      return false;
    }

    if (password !== passwordConfirm) {
      showError('Hasła nie są identyczne.');
      return false;
    }

    return true;
  };

  // VALIDATION-----------------------------------------------------------------------VALIDATION
  // SEND EMAIL REQUEST-------------------------------------------------------SEND EMAIL REQUEST

  const changePassword = async () => {
    setIsLoading(true);
    try {
      const response = await devscntrNoauth.post(
        accountsEndpoints.auth.passwordReset,
        {
          uidb64: uidb64,
          token: token,
          //email: email,
          new_password: password,
        }
      );
      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    //https://itcntr.com/reset-password/Mjkz/be6mwq-1f3d1fb4325588fcde587931c5d6d5fe
  };

  // SEND EMAIL REQUEST-------------------------------------------------------SEND EMAIL REQUEST
  // EVENT HANDLERS---------------------------------------------------------------EVENT HANDLERS

  const handleKeyDown = e => {
    if (e.key !== 'Enter') return;
    handleSubmit();
  };

  const handleSubmit = () => {
    if (validation()) {
      changePassword();
    }
  };

  // EVENT HANDLERS---------------------------------------------------------------EVENT HANDLERS

  return (
    <div className={styles.container} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to='/'>
          <Image
            className={styles.pic}
            src='/images/logo-light.svg'
            srcDark='/images/logo-dark.svg'
            alt='itCNTR'
          />
        </Link>
        {success ? (
          <>
            <div className={cn('h2', styles.title)}>Zmieniono hasło</div>
            <div className={styles.body}>
              <div className={styles.note}>
                Możesz się teraz zalogować z użyciem nowego hasła.
              </div>

              <Link to='/sign-in' className={cn('button', styles.button)}>
                Przejdź do logowania
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className={cn('h2', styles.title)}>Zmiana hasła</div>
            <div className={styles.body}>
              <div className={styles.note}>Ustaw swoje nowe hasło.</div>

              <TextInput
                className={styles.field}
                name='password'
                type='password'
                placeholder='Nowe hasło'
                required
                icon='lock'
                onChange={e => setPassword(e.target.value)}
                value={password}
                onKeyDown={handleKeyDown}
              />
              <TextInput
                className={styles.field}
                name='passwordConfirm'
                type='password'
                placeholder='Potwierdź nowe hasło'
                required
                icon='lock'
                onChange={e => setPasswordConfirm(e.target.value)}
                value={passwordConfirm}
                onKeyDown={handleKeyDown}
              />

              <Button
                classNames={cn('button', styles.button)}
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                Zmień hasło
              </Button>

              <div className={styles.links}>
                <Link className={styles.info_link} to='/sign-in'>
                  Przejdź do logowania
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
