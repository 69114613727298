export const getErrorMessage = (key = '', code = '') => {
  switch (key) {
    case 'email':
      switch (code) {
        case 'invalid':
          return 'Podaj poprawny adres email';
          break;
        case 'max_length':
          return 'Podany email jest za długi';
          break;
        case 'min_length':
          return 'Podany email jest za krótki';
          break;
        case 'unique':
          return 'Podany email jest już zajęty';
          break;
        default:
          return 'Wystąpił błąd podczas werfikacji adresu email';
      }
      break;
    case 'display_name':
      switch (code) {
        case 'invalid':
          return 'Podaj poprawną nazwę użytkownika';
          break;
        case 'max_length':
          return 'Podana nazwa użytkownika jest za długa';
          break;
        case 'min_length':
          return 'Podana nazwa użytkownika jest za krótki';
          break;
        case 'unique':
          return 'Podana nazwa użytkownika jest już zajęta';
          break;
        default:
          return 'Wystąpił błąd podczas werfikacji nazwy użytkownika';
      }
      break;
    case 'password':
      switch (code) {
        case 'invalid':
          return 'Podaj poprawne hasło';
          break;
        case 'max_length':
          return 'Podana hasło jest za długie';
          break;
        case 'min_length':
          return 'Podane hasło jest za krótkie';
          break;
        case 'password_too_common':
          return 'Podane hasło jest zbyt powszechne';
          break;
        default:
          return 'Wystąpił błąd podczas werfikacji hasła';
      }
      break;
    case 'recaptcha':
      return 'Wystąpił błąd z reCaptcha. Proszę spróbować później.';
      break;
    default:
      return 'Wystąpił błąd serwera, spróbuj ponownie później.';
  }
};
