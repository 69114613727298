import React, { useEffect, useState } from 'react';
import styles from './Parent.module.sass';
import gt from '../NewTechnology.module.sass';
import Select from 'react-select';
import useCollapse from 'react-collapsed';
import useAxios from 'hooks/useAxios';
import { jzwitv2Endpoints } from 'api/endpoints';
import { useContext } from 'react';
import FormContext from 'context/FormContext';
import devscntrAuth from 'api/Instance/devscntrAuth';
import TreeSelector from '../TreeSelector';
import RoadMap from 'screens/JakZaczacWIt/RoadMap';

const Parent = ({ editParentData, parentHandler, currentObject }) => {
  const formCtx = useContext(FormContext);

  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [techNames, setTechNames] = useState([]);
  const [initialParent,setInitialParent] = useState(null)
  const [selectedTechnologies, setSelectedTechnologies] = useState();
  const [defaultValue, setDefaultValue] = useState();
  const [currentSlug , setCurrentSlug] = useState('jak-zaczac-w-it');

  const [roadMap, setRoadMap] = useState([]);
  const [tree,setTree] = useState([])
  const [response, errorTechnology, loadingTechnology, GetTechnology] =
    useAxios();

    const getTechnology = async () => {
      const temp = await devscntrAuth.get(
        `${jzwitv2Endpoints.displayTech}`,
        {
          headers: {
            method: 'roadmap',
          },
        }
      );
      setRoadMap(temp?.data)
    };
  
  useEffect(() => {
    getTechnology();
  }, []);

  useEffect(() => {
    setTechNames(response.map(x => ({ value: x?.id, label: x?.name })));
    return () => {
      clearInterval(setTechNames());
    };
  }, [response]);

  const technologiesNames = techNames.filter(e => e.value !== 1);

  useEffect(() => {
    if (defaultValue) {
      formCtx.setParentData(defaultValue);
    }
    // formCtx.setTechnologiesData(selectedTechnologies);
  }, [defaultValue]);


  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused && '#9659e1',
        color: isFocused && '#FFF',
      };
    },
    singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#808080',
      };
    },
  };

  useEffect(() => {
    if (!editParentData) {
      setDefaultValue(techNames.at(-1));
    }
  }, [techNames]);

  useEffect(() => {
    setDefaultValue(editParentData);
    if(editParentData?.slug)
    {
      if(!initialParent)
      {
        setInitialParent(editParentData)
      }

      setCurrentSlug(editParentData?.slug)
    }
  }, [editParentData]);

  return (
    <>
      <div className={styles.container}>
        {roadMap[0]?.children?.length > 0 && (
            <RoadMap title='Rodzic' data={[roadMap[0]]} 
            initialParent={currentObject} 
            roadMapFunction={parentHandler} 
            currentSlug={currentSlug} 
            />
        )}
      </div>
    </>
  );
};

export default Parent;
