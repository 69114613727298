import Card from 'components/Card';
import React, { useState } from 'react';
import styles from './LinksInput.module.sass';
import cn from 'classnames';
import Icon from 'components/Icon';
import { showError } from 'utils/showError';
import { validateUrl } from 'utils/validation';
import Link from './Link';

const maxLinks = 10;

export default function LinksInput({ className, links, setLinks }) {
  const [titleValue, setTitleValue] = useState('');
  const [linkValue, setLinkValue] = useState('');

  const handleRemove = (e, index) => {
    e.preventDefault();
    setLinks(prev => prev.filter((_, i) => index !== i));
  };

  const handleClearInputs = () => {
    setTitleValue('');
    setLinkValue('');
  };

  const validateInputs = () => {
    if (!(links.length < maxLinks)) {
      showError('Osiągnięto maksymalną ilość linków');
      return false;
    }
    if (titleValue.trim(' ').length === 0) {
      showError('Tytuł linka nie może być pusty');
      return false;
    }
    if (!validateUrl(linkValue)) return false;

    return true;
  };

  const handleAddToArray = () => {
    if (!validateInputs()) return;

    setLinks(prev => [
      ...prev,
      {
        title: titleValue,
        url: linkValue,
      },
    ]);

    handleClearInputs();
  };

  const handleKeyDown = e => e.key === 'Enter' && handleAddToArray();

  console.log(links)

  return (
    <Card
      className={cn(styles.card, className)}
      title='Linki'
      classTitle='title-purple'
      head={`${links.length}/${maxLinks}`}
    >
      <div className={styles.input_container}>
        <input
          placeholder='Podaj tytuł linka'
          className={cn(styles.input, styles.input_title)}
          onKeyDown={handleKeyDown}
          value={titleValue}
          onChange={e => setTitleValue(e.target.value)}
          maxLength='100'
        />
        <input
          placeholder='Podaj link'
          className={cn(styles.input, styles.input_link)}
          onKeyDown={handleKeyDown}
          value={linkValue}
          onChange={e => setLinkValue(e.target.value)}
          maxLength='100'
        />
        <button className={cn('button')} onClick={() => handleAddToArray()}>
          Dodaj
        </button>
      </div>

      {links.length > 0 && (
        <div className={styles.separator}></div>
      )}

      <div className={styles.container}>
        {links.map((link, index) => (
          <Link link={link} index={index} key={index} setLinks={setLinks} links={links} remove={handleRemove} />
        ))}
      </div>
    </Card>
  );
}