import React, { useState, useEffect, useContext } from 'react';
import {
  // Link,
  // Route,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import LatestComments from '../../../components/LatestComments';
import LatestCourses from '../../../components/LatestCourses';
import useAxios from '../../../hooks/useAxios';
import TechnologyDetailLoader from './Loader/detail';
import styles from '../JZWIT.module.sass';
import { jzwitEndpoints } from 'api/endpoints';
import { convertFromRaw, EditorState, convertToRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import RecommendedPosts from 'components/RecommendedPosts';
import AverageSalary from 'components/AverageSalary';
import MiniJZWIT from 'components/MiniJZWIT';
import TechnologyLoader from './Loader/technology';
import JZWITContext from 'context/jzwitContext';
import TechnologyChart from './TechnologyChart';
import MoreOptionsJZWIT from '../MoreOptions';
import Related from 'components/Related';

const TechnologyDetail = () => {
  const { idTech } = useParams();
  const jzwitCtx = useContext(JZWITContext);

  const location = useHistory();
  const [description, setDescription] = useState(null);
  const [trendsDescription, setTrendsDescription] = useState(null);
  const [text, setText] = useState(null);
  const [trendsText, settrendsText] = useState(null);
  // const { url, path } = useRouteMatch();

  // <----- POBIERNAIE TECHNOLOGI ----->
  const [response, errorTechnology, loadingTechnology, GetTechnology] =
    useAxios();
  const getTechnology = () => {
    GetTechnology({
      method: 'get',
      url: `${jzwitEndpoints.displayTech}${idTech}`,
    });
  };

  useEffect(() => {
    getTechnology();
    window.scrollTo(0, 0);
  }, [idTech]);

  const technology = response?.technology;

  // const Route = () => {
  //   response?.technology?.children.map((child, index) => child?.name);
  // };

  // MAIN TEXT
  useEffect(() => {
    if (technology?.text) {
      setDescription(technology?.text);
      // setDescription(JSON.parse(technology?.text));
    }
  }, [technology?.text]);

  useEffect(() => {
    if (description) {
      // const preparedDescription = EditorState.createWithContent(
      //   convertFromRaw(description)
      // );
      // setText(convertToHTML(preparedDescription.getCurrentContent()));
      setText(description);
    }
  }, [description]);

  // TRENDS TEXT
  useEffect(() => {
    if (technology?.trends[0]?.text) {
      setTrendsDescription(technology?.trends[0]?.text);
      // setTrendsDescription(JSON.parse(technology?.trends[0]?.text));
    }
  }, [technology?.trends]);

  useEffect(() => {
    if (trendsDescription) {
      settrendsText(trendsDescription);
      // const preparedDescription = EditorState.createWithContent(
      //   convertFromRaw(trendsDescription)
      // );
      // settrendsText(convertToHTML(preparedDescription.getCurrentContent()));
    }
  }, [trendsDescription]);

  // useEffect(() => {
  //   jzwitCtx.setJzwitPath(url)
  // }, [url])

  return (
    <main className={styles.row}>
      <section className={styles.col}>
        {loadingTechnology ? (
          <TechnologyDetailLoader cards={1} />
        ) : (
          <>
            <header className={styles.subhead}>
              <Breadcrumbs
                slice={16}
                icon_name={'desktop'}
                headline={''}
                history_push={'/jak-zaczac-w-it'}
                technologyName={technology?.name}
              />
              <MoreOptionsJZWIT
                item={technology}
                type='jzwit'
                onDelete={() => location.push('/jak-zaczac-w-it/')}
                shareUrlCntx={jzwitCtx.jzwitUrl}
              />
            </header>
            <article className={styles.mainSection}>
              <div className={styles.content}>
                <h1>{technology?.name}</h1>
                <h3>{technology?.subtitle}</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: text }}
                  className={styles.mainSectionText}
                ></div>
              </div>
              {technology?.image && (
                <img
                  src={technology?.image}
                  alt={technology?.name}
                  className={styles.mainSectionImage}
                />
              )}
            </article>
            <article className={styles.chartAndTrends}>
              <TechnologyChart
                className={styles.chart}
                title={'Wykres Popularności'}
                data={response?.graph}
              />
              <div className={styles.content}>
                <h1>{technology?.trends[0]?.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: trendsText }}></div>
              </div>
            </article>
            {technology?.sections.map((section, index) => (
              <article
                key={index}
                className={
                  section.side
                    ? styles.dynamicSectionSide
                    : styles.dynamicSection
                }
                // className={!section.side && styles.dynamicSection}
              >
                <div className={styles.content}>
                  <h1>{section?.title}</h1>
                  <h3>{section?.subtitle}</h3>
                  <div
                    // dangerouslySetInnerHTML={{
                    //   __html: convertToHTML(
                    //     EditorState.createWithContent(
                    //       convertFromRaw(JSON.parse(section?.text))
                    //     ).getCurrentContent()
                    //   ),
                    // }}
                    dangerouslySetInnerHTML={{ __html: section?.text }}
                    className={styles.dynamicSectionText}
                  ></div>
                </div>
                {section.image && (
                  <img src={section?.image} alt={section?.id} />
                )}
              </article>
            ))}
            <section>
              <ul className={styles.jzwitlist}>
                <header className={styles.sc_title}>
                  <h4>Powiązane Technologie</h4>
                </header>
                {loadingTechnology ? (
                  <TechnologyLoader />
                ) : technology?.children?.length !== 0 ? (
                  <>
                    {technology?.children?.map((technology, index) => (
                      <MiniJZWIT
                        globalTech={technology}
                        child={technology}
                        key={index}
                      />
                    ))}
                  </>
                ) : (
                  <h4 className={styles.isEmpty}>
                    BRAK POWIĄZANYCH TECHNOLOGI
                  </h4>
                )}
              </ul>
            </section>
            <Related type='Kursy' name={technology?.name} />
            <Related type='Oferty Pracy' name={technology?.name} />
            <Related type='Wpisy' name={technology?.name} />
          </>
        )}
      </section>
      <section className={styles.col}>
        <AverageSalary className={styles.card} limit={3} />
        <LatestCourses className={styles.card} limit={3} />
        <RecommendedPosts className={styles.card} limit={3} />
        <LatestComments className={styles.card} limit={3} />
      </section>
    </main>
  );
};

export default TechnologyDetail;
