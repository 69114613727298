import React, { useEffect, useState } from 'react';
import styles from './MoreOptions.module.sass';
import cn from 'classnames';
import Actions from 'components/Actions';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import {
  accountsEndpoints,
  adminEndpoints,
  supportEndpoints,
} from 'api/endpoints';
import { showError } from 'utils/showError';
import { store } from 'store/rootReducer';
import { showShareModal } from 'store/shareModal/actions';
import { deleteTimeout } from 'data/dataArrays';
import ModalReport from 'components/ModalReport';
import { id } from 'date-fns/locale';
import { useHistory } from 'react-router';
import BanModal from 'components/BanModal';

const deleteEndpoints = {
  post: adminEndpoints.posts.delete,
  course: adminEndpoints.courses.delete,
  job: adminEndpoints.jobs.delete,
  jzwit: adminEndpoints.jzwit.delete,
};

const reportEndpoints = {
  post: supportEndpoints.reportContent.posts.article,
  course: supportEndpoints.reportContent.courses.article,
  job: supportEndpoints.reportContent.jobs.article,
};

const shareUrls = {
  post: '/post/',
  course: '/course/',
  job: '/jobs/',
  jzwit: '/jak-zaczac-w-it-detail/',
};

const MoreOptions = ({
  className,
  item,
  authorId,
  type,
  refresh,
  onDelete,
}) => {
  // UTILS----------------------------------------------------------------------------UTILS
  const authCtx = useAuth();
  const deleteUrl = deleteEndpoints[type] ?? '';
  const shareUrl = `${document.location.origin}${shareUrls[type] ?? ''}`;
  const reportUrl = reportEndpoints[type] ?? '';
  const history = useHistory();

  const [reportVisible, setReportVisible] = useState(false);
  const [banModal, setBanModal] = useState(false);

  // USED BY DELETE
  const [confirm, setConfirm] = useState(false);
  const [allowClick, setAllowClick] = useState(true);
  const [deleteCountdown, setDeleteCountdown] = useState(false);

  // USED BY BLOCK
  const [blockedUser, setBlockedUser] = useState(false);
  const [blockUserLoading, setBlockUserLoading] = useState(false);

  // UTILS----------------------------------------------------------------------------UTILS
  // FUNCTIONS--------------------------------------------------------------------FUNCTIONS

  const handleShare = () => {
    store.dispatch(showShareModal(`${shareUrl}${item.id}`));
  };

  const handleReport = async (title, description) => {
    let data;
    switch (type) {
      case 'post':
        data = { post: item.id, title: title, description: description };
        break;
      case 'course':
        data = { course: item.id, title: title, description: description };
        break;
      case 'job':
        data = { job: item.id, title: title, description: description };
      default:
        break;
    }
    console.log(data);
    try {
      const response = await devscntrAuth.post(reportUrl, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response?.data);
      console.log('reported');
    } catch (error) {
      console.log(error);
      showError('Zgłoszenie nie powiodło się. Spróbuj ponownie później');
    }
  };

  const handleDelete = () => {
    if (authCtx.isModerator || authCtx.isAdmin) {
      if (confirm && allowClick) {
        deleteApi();
        setConfirm(false);
      } else {
        if (allowClick) {
          // Show countdown in option
          setDeleteCountdown(deleteTimeout / 1000);
          let timer = setInterval(() => {
            setDeleteCountdown(prev => {
              if (prev === 0) clearInterval(timer);
              else return prev - 1;
            });
          }, 1000);
          // Disable click (anti double-click)
          setAllowClick(false);
          setTimeout(() => setAllowClick(true), deleteTimeout);
          setTimeout(() => setConfirm(false), 10000);
        }

        setConfirm(true);
      }
    }
  };

  const deleteApi = async () => {
    try {
      const response = await devscntrAuth.delete(`${deleteUrl}${item.id}/`);
      console.log(response?.data);
      //refresh && refresh();
      onDelete && onDelete();
      console.log('deleted');
    } catch (error) {
      console.log(error);
      showError('Usuwanie nie powiodło się. Spróbuj ponownie później');
    }
  };

  // FUNCTIONS--------------------------------------------------------------------FUNCTIONS
  // BLOCK USER------------------------------------------------------------------BLOCK USER

  const handleBlock = () => {
    if (authCtx.userID == authorId) return;
    if (blockUserLoading) return;
    if (blockedUser) unblockApi(authorId);
    else blockApi(authorId);
  };

  const blockApi = async id => {
    if (!id) return;
    setBlockUserLoading(true);
    try {
      const response = await devscntrAuth.post(
        `${accountsEndpoints.userContent.userBlocked}`,
        {
          blockeduser: id,
          reason: '',
        }
      );
      authCtx.updateBlockedUsers(id);
    } catch (error) {
      console.log(error);
      showError('Zablokowanie nie powiodło się. Spróbuj ponownie później');
    } finally {
      setBlockUserLoading(false);
    }
  };

  const unblockApi = async id => {
    if (!id) return;
    setBlockUserLoading(true);
    try {
      const response = await devscntrAuth.delete(
        `${accountsEndpoints.userContent.userBlocked}${id}/`
      );
      authCtx.updateBlockedUsers(id);
    } catch (error) {
      console.log(error);
      showError('Odblokowanie nie powiodło się. Spróbuj ponownie później');
    } finally {
      setBlockUserLoading(false);
    }
  };

  useEffect(() => {
    setBlockedUser(authCtx.blockedUsers.includes(parseInt(authorId)));
  }, [authCtx.blockedUsers]);

  // BLOCK USER------------------------------------------------------------------BLOCK USER
  // OPTIONS------------------------------------------------------------------------OPTIONS

  const actions = [
    {
      title: 'Udostępnij',
      icon: 'link',
      action: handleShare,
    },
    authCtx.isLoggedIn && {
      title: 'Zgłoś',
      icon: 'ticket',
      action: () => setReportVisible(true),
    },
    authCtx.isLoggedIn &&
      authorId &&
      authorId != authCtx.userID && {
        title: blockedUser ? 'Odblokuj' : 'Zablokuj',
        icon: 'profile-circle',
        color: blockedUser,
        loading: blockUserLoading,
        action: () => handleBlock(),
      },
    authCtx.isLoggedIn &&
      (authCtx.isModerator ||
        (authCtx.isAdmin &&
          type === 'jzwit' && {
            title: 'edytuj',
            icon: 'edit',
            action: () => handleEditPush(),
          })),
    authCtx.isLoggedIn &&
      authorId &&
      (authCtx.isModerator ||
        (authCtx.isAdmin && {
          title: 'Zablokuj autora',
          icon: 'lock',
          action: () => setBanModal(true),
        })),
    authCtx.isLoggedIn &&
      (authCtx.isModerator ||
        (authCtx.isAdmin && {
          title: confirm ? 'Potwierdź' : 'Usuń',
          icon: 'trash',
          color: confirm,
          badge: deleteCountdown > 0 ? deleteCountdown : false,
          action: handleDelete,
        })),
  ];

  // OPTIONS------------------------------------------------------------------------OPTIONS

  // JZWIT-EDIT------------------------------------------------------------------------JZWIT-EDIT
  const handleEditPush = () => {
    if (type === 'jzwit') history.push(`/jak-zaczac-w-it-edit/${item.id}`);
  };
  // JZWIT-EDIT------------------------------------------------------------------------JZWIT-EDIT

  return (
    <>
      <ModalReport
        visible={reportVisible}
        onClose={() => setReportVisible(false)}
        onSubmit={handleReport}
      />
      <BanModal userID={authorId} visible={banModal} setVisible={setBanModal} />
      <Actions
        className={cn(styles.menu, className)}
        classActionsHead={styles.settings}
        classActionsBody={styles.actionsBody}
        classActionsOption={styles.actionsOption}
        items={actions}
      />
    </>
  );
};

export default MoreOptions;
