import React, { useEffect, useMemo, useState } from 'react';

import calculateScore from 'lib/b5-calculate-score';
import { getInfo } from 'lib/b5-test-resources';
import PageCounter from './components/PageCounter';
import ProgressBar from './components/ProgressBar';
import SingleQuestion from './components/SingleQuestion';
import {
  clearItems,
  getItems,
  getProgress,
  populateData,
  restoreData,
} from './helpers';

import style from './BigFiveMainTest.module.sass';
import Button from 'components/Button';

const BigFiveMainTest = ({ lang, inventory, onSubmit, isLoading }) => {
  const defaultTestState = {
    progress: 0,
    restore: false,
    position: 0,
    itemsPerPage: 3,
    answers: {},
    items: [],
    now: Date.now(),
    lang,
  };

  const [testState, setTestState] = useState(defaultTestState);
  const done = useMemo(
    () => testState.progress === 100 && testState.next,
    [testState]
  );

  const handleReduceAnswers = (prev, current) => {
    const choice = testState.answers[current];
    prev.push({
      domain: choice.domain,
      facet: choice.facet,
      score: choice.score,
    });
    return prev;
  };

  const handleSubmit = async () => {
    window.scrollTo(0, 0);

    const { items, finished, position } = getItems(
      testState.position,
      testState.itemsPerPage,
      inventory
    ).next();

    if (finished) {
      const choices = Object.keys(testState.answers).reduce(
        handleReduceAnswers,
        []
      );
      const result = {
        ...getInfo(),
        lang: testState.lang,
        answers: choices,
        timeElapsed: Math.round((Date.now() - testState.now) / 1000),
        dateStamp: Date.now(),
      };

      const score = calculateScore(result);
      onSubmit(score);
    } else {
      const next =
        items.filter(item => !testState.answers[item.id]).length === 0;
      setTestState(prev => ({
        ...prev,
        items,
        position,
        next,
        previous: true,
        restore: false,
      }));
      populateData({
        progress: testState.progress,
        next,
        previous: true,
        answers: testState.answers,
        position,
        items,
      });
    }
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    const { previous, items, position } = getItems(
      testState.position,
      testState.itemsPerPage,
      inventory
    ).back();
    setTestState(prev => ({ ...prev, items, position, next: true, previous }));
  };

  const handleChange = async ({ target }) => {
    if (target && target?.name && target?.value) {
      const { answers, items, itemsPerPage, previous, position } = testState;
      const { domain, facet } = inventory.find(q => q.id === target.name) || {};

      if (domain && facet) {
        answers[target.name] = { score: parseInt(target.value), domain, facet };
      }

      const progress = Math.round(
        (Object.keys(answers).length / inventory.length) * 100
      );
      // const next =
      //   itemsPerPage === 1 && progress !== 100
      //     ? false
      //     : items.filter(item => !answers[item.id]).length === 0;

      const next = items.filter(item => !answers[item.id]).length === 0;

      setTestState(prev => ({ ...prev, answers, progress, next }));
      populateData({ answers, progress, next, previous, position, items });
      //if (itemsPerPage === 1 && progress !== 100) await handleSubmit();
    }
  };

  const getTestState = (renew = false) => {
    const itemsPerPage = window.innerWidth < 600 ? 1 : 10;

    if (getProgress() && !renew) {
      const data = restoreData();
      setTestState({ ...data, itemsPerPage, restore: true });
    } else {
      const { items } = getItems(
        renew ? 0 : testState.position,
        itemsPerPage,
        inventory
      ).current();
      setTestState(prev => ({ ...prev, items, itemsPerPage }));
    }
  };

  useEffect(() => {
    getTestState();
  }, [inventory]);

  return (
    <div className={style.container}>
      {/* <Button
        onClick={() => {
          clearItems();
          setTestState(defaultTestState);
          getTestState(true);
        }}
      >
        Resetuj test
      </Button> */}

      {/* <PageCounter testState={testState} inventory={inventory} /> */}
      <div className={style.progress}>
        <ProgressBar progress={testState.progress} />
      </div>

      <div className={style.questions}>
        {testState.items.map(item => (
          <SingleQuestion
            answers={testState.answers}
            handleChange={handleChange}
            item={item}
            key={item.id}
          />
        ))}
      </div>

      <div className={style.buttonWrapper}>
        <div style={{ marginRight: '10px' }}>
          <Button
            type='submit'
            onClick={handleBack}
            disabled={
              !testState?.previous ||
              testState.position - testState.itemsPerPage < 0
            }
            classNames={'button-stroke'}
          >
            Wstecz
          </Button>
        </div>
        <div>
          <Button
            type='submit'
            onClick={handleSubmit}
            disabled={!testState?.next}
            isLoading={isLoading || false}
          >
            {done && testState.position + testState.itemsPerPage >= 120
              ? 'Zobacz wyniki'
              : 'Dalej'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BigFiveMainTest;
