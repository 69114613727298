import React, { useState, useEffect } from 'react';
import styles from './Table.module.sass';
import cn from 'classnames';
import uuid from 'react-uuid';
import { getEnvName } from 'utils';

// import Loader from 'components/Loader';
import Row from '../Row';
import Checkbox from 'components/Checkbox';

import useBreakpoints from 'hooks/useBreakpoints';

import { labels } from './data/labels';

export default function Table({
  items,
  checkedPublications,
  isCheckAll,
  handleSelect,
  handleSelectAll,
  setReRender,
}) {
  const [ref, setRef] = useState(); // DOM element for breakpoints
  const [blockingJobs, setBlockingJobs] = useState('')

  useEffect(() => {
    if (getEnvName() === 'prod') {
      setBlockingJobs('job')
    }
  }, []);

  const breakpoints = [
    ['r-max', Infinity],
    ['r-lg', 1140],
    ['r-md', 996],
    ['r-sm', 858],
    ['r-sm-s', 712],
  ];
  useBreakpoints(ref, breakpoints);

  return (
    <>
      <div className={styles.wrapper} ref={x => setRef(x)}>
        <div className={cn(styles.label, styles.checkbox)}>
          <Checkbox
            className={styles.checkbox}
            onChange={handleSelectAll}
            value={isCheckAll}
          />
          <div
            className={cn(
              styles.label,
              styles.product,
              styles['product-tablet']
            )}
          >
            Produkt
          </div>
        </div>

        <div className={styles.labels}>
          {labels.map(label => (
            <div key={uuid()} className={cn(styles.label, styles[label.style])}>
              {label.name}
            </div>
          ))}
        </div>
        <div className={styles.divider}></div>
        <div className={styles.list}>
          {items &&
            items.map(item => (
              item.type !== blockingJobs &&
              (<Row
                item={item}
                key={uuid()}
                handleSelect={handleSelect}
                isSelected={
                  !!checkedPublications.find(
                    checked =>
                      checked.id === item.id && checked.type === item.type
                  )
                }
                parentRef={ref}
                setReRender={setReRender}
              />)
            ))}
        </div>
      </div>

      <div className={styles.foot}>
        {/* <button className={cn("button-stroke button-small", styles.button)}>
          <Loader className={styles.loader} />
          <span>Załaduj więcej</span>
        </button> */}
      </div>
    </>
  );
}
