import React, { useState } from 'react';
import styles from './GoogleLogin.module.sass';
import cn from 'classnames';
import { accountsEndpoints } from 'api/endpoints';
import useAuth from 'hooks/useAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import Loader from 'components/Loader';
import { showError } from 'utils/showError';

const GOOGLE_LOGIN_REDIRECT = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_LINK;
const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://itcntr.com';

const GoogleLogin = ({ classNames }) => {
  const authCtx = useAuth();

  const [popupOpened, setPopupOpened] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);

  let windowObjectReference = null;
  let previousUrl = null;

  // HANDLE POPUP---------------------------------------------------------------HANDLE POPUP

  const openLoginPopup = (url, name) => {
    // remove any existing event listeners
    window.removeEventListener('message', getPopupData);

    // window features
    const strWindowFeatures =
      'toolbar=no, menubar=no, width=500, height=700, top=100, left=100';

    if (windowObjectReference === null || windowObjectReference.closed) {
      // If window doesn't exist or is closed, reopen
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      // If url is different, reopen and focus
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      // Bring window to front
      windowObjectReference.focus();
    }

    setPopupOpened(true);
    if (windowObjectReference) {
      // Add listener for closing popup
      const timer = setInterval(() => {
        if (windowObjectReference.closed) {
          clearInterval(timer);
          setPopupOpened(false);
          setCancelVisible(false);
        }
      }, 2000);
      // Show cancel button after 3 seconds
      setTimeout(() => {
        setCancelVisible(true);
      }, 3000);
    }

    // Add listener for receiving a message from popup
    window.addEventListener('message', event => getPopupData(event), false);
    previousUrl = url;
  };

  const getPopupData = event => {
    // Check if window is correct
    if (!event.origin.includes(BASE_URL)) {
      return;
    }
    // data = access token from Google
    const { data } = event;

    if (data) {
      login(data);
    }
  };

  const cancelLoginPopup = () => {
    setPopupOpened(false);
    setCancelVisible(false);
  };

  // HANDLE POPUP---------------------------------------------------------------HANDLE POPUP
  // LOGIN-----------------------------------------------------------------------------LOGIN

  const login = async token => {
    if (!token) return;
    try {
      const response = await devscntrNoauth.get(
        accountsEndpoints.auth.loginGoogle,
        {
          params: {
            code: token,
          },
        }
      );
      const loginToken = response.data.access_token;

      // Login user
      const expirationTime = new Date(new Date().getTime() + 86300 * 1000);
      authCtx.login(loginToken, expirationTime.toISOString());
    } catch (error) {
      console.log(error);
      const resData = error.response.data;
      const errorMessage = resData.error;
      const alreadyExists =
        'Regular account with this email exists. Try logging in with regular login endpoint.';
      if (errorMessage === alreadyExists) {
        showError(
          'Konto z podanym adresem email już istnieje. Spróbuj się zalogować za pomocą formularza logowania.'
        );
      } else {
        showError('Wystąpił błąd podczas logowania.');
      }
    }
  };

  // LOGIN-----------------------------------------------------------------------------LOGIN

  return !popupOpened ? (
    <button
      className={cn('button-stroke', styles.button, classNames && classNames)}
      onClick={() => openLoginPopup(GOOGLE_LOGIN_REDIRECT, 'Login with Google')}
      target='_blank'
    >
      <img src='/images/content/google.svg' alt='Google' />
      Google
    </button>
  ) : (
    <div className={styles.overlay}>
      <div className={styles.logo} style={{ alignSelf: 'center' }} to='/'>
        <img
          className={styles.pic}
          src='/images/content/google.svg'
          alt='Google'
        />
      </div>
      <div className={styles.loader_title}>Trwa logowanie z Google</div>
      <Loader className={styles.loader} />
      <button
        className={cn('button-stroke', styles.cancel_button, {
          [styles.visible]: cancelVisible,
        })}
        onClick={cancelLoginPopup}
      >
        Anuluj
      </button>
    </div>
  );
};

export default GoogleLogin;
