import React from 'react';
import styles from './Button.module.sass';
import cn from 'classnames';
import Loader from 'components/Loader';

const Button = ({
  classNames,
  isLoading,
  text,
  title,
  onClick,
  disabled = false,
  children,
  role = 'button',
}) => {
  return (
    <button
      className={cn(
        'button',
        styles.button,
        classNames && classNames,
        isLoading && 'disabled',
        disabled && 'disabled'
      )}
      role={role}
      title={title && title}
      onClick={onClick}
      disabled={isLoading || disabled}
    >
      <Loader className={cn(styles.loader, isLoading && styles.visible)} />
      {text}
      {children}
    </button>
  );
};
export default Button;
