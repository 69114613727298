import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './Courses.module.sass';
import axios from 'axios';
import MiniCourse from 'components/MiniCourse';

import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import Loader from 'components/MiniCourse/Loader';
import Pagination from 'components/Pagination';
import { pageSize } from 'data/dataArrays';
import PaginationGrowingFooter from 'components/PaginationGrowingFooter';

const Courses = ({ author }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(true);
  const [articles, setArticles] = useState([]);

  // PAGINATION----------------------------------------------------------------PAGINATION

  const [currentPage, setCurrentPage] = useState(1);
  const [totalArticles, setTotalArticles] = useState(1);

  // SCROLL TOP ON PAGE CHANGE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // PAGINATION----------------------------------------------------------------PAGINATION

  const getCourses = async cancelToken => {
    try {
      //setIsLoading(true);

      const { data } = await axiosInstance.request({
        method: 'GET',
        url: `/course/user-course/?page=${currentPage}&page_size=${pageSize}`,
        headers: {
          'USER-ID': author,
          METHOD: 'list',
        },
        cancelToken: cancelToken.token,
      });

      const results = data?.results || [];

      setArticles(results);
      setTotalArticles(data?.count)
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('user courses canceled');
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCourses(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [currentPage]);

  return (
    <>
      {!isLoading && (
        <div className={styles.list}>
          {articles.map((item, index) => (
            <MiniCourse
              classNames={styles.item}
              key={index}
              article={item}
            />
          ))}
        </div>
      )}

      {articles?.length === 0 && !isLoading && (
        <p className={styles.info}>Użytkownik nie ma jeszcze żadnych kursów</p>
      )}

      {isLoading && <Loader cards={pageSize * currentPage} />}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={totalArticles}
        onPageChange={page => setCurrentPage(page)}
      />

    </>
  );
};

export default Courses;
