import React, { useState, useEffect } from 'react';
import styles from './RoadMap.module.sass';
import TreeItem from './TreeItem';
import Card from 'components/Card';
import cn from 'classnames';
import Dropdown from 'components/Dropdown';
import { jzwitSortingOptions } from 'screens/Publications/components/Drafts/utilities/options';
import Form from 'components/Form';
import Breadcrumb from 'components/Breadcrumb';
import SearchBarLoaderJZWIT from './SearchBarLoader';

const RoadMap = (
    {
        className,
        data,
        roadMapFunction,
        currentSlug,
        dropdownSorting,
        title,
        initialParent,
        hideIcon,
        isCategorySelector,
        categories,
        categoriesSlugs,
        hasSearchBar,
    }) => {

    const treeData = data
    const [searchValue, setSearchValue] = useState('')
    const [searchBarResults, setSearchBarResults] = useState([])
    const [searchBarLoader, setSearchBarLoader] = useState(false);
    const slugsAmount = currentSlug?.split('_').length || 1
    const [slugsArray, setSlugsArray] = useState([])
    const [finalSlugsArray, setFinalSlugsArray] = useState([])
    const sortJzwitOptions = jzwitSortingOptions.map(x => x.name);
    const [sortingJzwitName, setSortingJzwitName] = useState(jzwitSortingOptions[0].name);
    const [sortingJzwitValue, setSortingJzwitValue] = useState(jzwitSortingOptions[0].value);
    const [prepareCategoriesSlug, setPrepareCategoriesSlug] = useState([])

    useEffect(() => {
        setSlugsArray([])
        setFinalSlugsArray([])
        if (currentSlug) {
            for (let i = 1; i < slugsAmount + 1; i++) {
                setSlugsArray(slugsArray => [...slugsArray, currentSlug.split('_', i)]);
            }
        }
    }, [currentSlug]);

    useEffect(() => {
        if (slugsArray.length > 0) {
            for (let i = 0; i < slugsAmount; i++) {
                setFinalSlugsArray(finalSlugsArray => [...finalSlugsArray, slugsArray[i].join('_')]);
            }
        }
    }, [slugsArray]);

    useEffect(() => {
        categoriesSlugs?.forEach(element => {
            const temp = []
            for (let i = 1; i < element.split('_').length + 1; i++) {
                temp.push(element.split('_', i))
            }
            const writeIntoArray = []
            for (let i = 0; i < element.split('_').length; i++) {
                writeIntoArray.push(temp[i].join('_'))
            }

            for (let i = 0; i < writeIntoArray.length - 1; i++) {
                if (!prepareCategoriesSlug.includes(writeIntoArray[i])) {
                    setPrepareCategoriesSlug(prepareCategoriesSlug => [...prepareCategoriesSlug, writeIntoArray[i]]);
                }
            }
        });
    }, [categoriesSlugs])

    //sortowanie dropdown
    useEffect(() => {
        const sortOptionID = jzwitSortingOptions.findIndex(obj => obj.name === sortingJzwitName);
        setSortingJzwitValue(jzwitSortingOptions[sortOptionID].value);
    }, [sortingJzwitName]);



    const searchBarData = (data) => {
        data?.forEach(element => {
            if (element.name.toLowerCase().includes(searchValue.toLowerCase())) {
                findAllTechnologiesOnPath(element)
            }
            if (element?.children) {
                searchBarData(element.children)
            }
        });
    }

    const findAllTechnologiesOnPath = (item) => {
        const slugArray = []
        const temp = []
        const length = item?.slug.split('_').length

        for (let i = 1; i < length + 1; i++) {
            temp.push(item?.slug.split('_', i))
        }

        temp.forEach(element => {
            slugArray.push(element.join('_'))
        });

        findAllElementsFromTree(slugArray)
    }

    const findAllElementsFromTree = (items) => {
        const temp = []

        items.forEach(item => {
            findItemOnTree(treeData, item, temp)
        });

        setSearchBarResults(
            searchBarResults =>
                [...searchBarResults, temp]
        )
    }

    const findItemOnTree = (data, slug, finalArray) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i]?.slug === slug) {
                finalArray.push({ name: data[i]?.name, icon: data[i]?.icon, to: prepareSlug(data[i]?.slug) })
            }
            if (data[i]?.children) {
                findItemOnTree(data[i].children, slug, finalArray)
            }
        };
    }

    const prepareSlug = (slug) => {
        const temp = slug.split('_')
        return '/' + temp.join('/')
    }

    useEffect(() => {
        setSearchBarResults([])
        setSearchBarLoader(true)
        const delayDebounceFn = setTimeout(() => {
            if (searchValue) {
                searchBarData(treeData)
            }
            setSearchBarLoader(false)
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [searchValue])

    return (
        <div className={cn(styles.card, className)}>
            <div className={cn(styles.head)}>
                <div className={cn('title-darkPurple', styles.title)}>{title}</div>
                {hasSearchBar && (
                    <Form
                        className={styles.form}
                        classNameIcon={styles.formIcon}
                        value={searchValue}
                        setValue={setSearchValue}
                        placeholder='Wyszukaj technologie'
                        type='text'
                        name='search'
                        icon='search'
                    />
                )}
            </div>

            {searchValue && hasSearchBar && (
                <>
                    {!searchBarLoader ? (
                        <>
                            {searchBarResults.length > 0 ? (
                                <div className={styles.list}>
                                    {searchBarResults.map((breadcrumb, index) => (
                                        console.log(breadcrumb),
                                        <Breadcrumb key={index} items={breadcrumb} />
                                    ))}
                                </div>
                            ) : (
                                <p className={styles.info}>Brak wyników</p>
                            )}
                        </>
                    ) : (
                        <SearchBarLoaderJZWIT/>
                    )}

                </>
            )}

            {!searchValue && (
                <div className={styles.grid}>
                    <div className={styles.tree}>
                        <TreeItem
                            data={treeData}
                            itemClicked={roadMapFunction}
                            slugsArray={finalSlugsArray}
                            initialParent={initialParent}
                            hideIcon={hideIcon}
                            hasCheckbox={isCategorySelector}
                            categories={categories}
                            categoriesSlugs={prepareCategoriesSlug}
                        />
                    </div>
                    <div className={styles.dropdown}>
                        {dropdownSorting && (
                            <Dropdown
                                value={sortingJzwitName}
                                setValue={setSortingJzwitName}
                                options={sortJzwitOptions}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default RoadMap