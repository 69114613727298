import React from 'react';

import style from './SingleQuestion.module.sass';
import InputRadio from 'components/InputRadio';

const SingleQuestion = ({ item, answers, handleChange }) => {
  const choices = item?.choices || [];
  const labelLeft = choices?.[0]?.text;
  const labelRight = choices?.[choices.length - 1]?.text;

  return (
    <div className={style.container}>
      <p className={style.label}>
        {item?.num}. {item.text}
      </p>
      <div className={style.answers}>
        <p className={style.text}>{labelLeft}</p>
        <div className={style.wrapper}>
          {item.choices?.map((choice, i) => (
            <InputRadio
              name={item.id}
              key={`${item.id}${choice.score}`}
              value={parseInt(choice.score)}
              label={choice.text}
              checked={
                answers[item.id] &&
                parseInt(answers[item.id]?.score) === parseInt(choice?.score)
              }
              className={style.radioInput}
              onChange={handleChange}
              id={item.id + '-' + choice.score}
            />
          ))}
        </div>
        <p className={style.text}>{labelRight}</p>
      </div>
    </div>
  );
};

export default SingleQuestion;
