import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Skills.module.sass';
import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';
import { getKeyByValue } from 'utils';

const Skills = ({ skills, setSkills, setSkillsRemoveList }) => {
  const levels = {
    1: 'Intern',
    2: 'Junior',
    3: 'Mid',
    4: 'Senior',
    5: 'Expert',
  };

  const [skill, setSkill] = useState('');
  const [level, setLevel] = useState('Wybierz poziom');

  const addButton = () => {
    setSkills(skills => [
      ...skills,
      { name: skill, level: getKeyByValue(levels, level) },
    ]);
    setSkill('');
    setLevel('Wybierz poziom');
  };

  const handleRemove = (e, index) => {
    e.preventDefault();

    for (let i = 0; i < skills.length; i++) {
      if (index === i) {
        skills[i].remove = true;
        setSkillsRemoveList(skillsRemoveList => [
          ...skillsRemoveList,
          skills[i],
        ]);
      }
    }

    setSkills(prev => prev.filter((_, i) => index !== i));
  };

  return (
    <div className={styles.container}>
      <div className={cn('title-yellow', styles.header)}>
        <div className={styles.header_text}>Umiejętności</div>
      </div>
      {skills.map((skill, index) => (
        <div className={styles.input_container} key={index}>
          <input
            className={cn(styles.input, styles.input_title)}
            value={skill.name}
            maxLength='100'
            readOnly
            disabled
          />
          <input
            className={cn(styles.input, styles.input_level)}
            value={levels[parseInt(skill.level)]}
            maxLength='100'
            readOnly
            disabled
          />
          <button
            className={cn('button', styles.remove_button)}
            onClick={e => handleRemove(e, index)}
          >
            <Icon name='close' size={18} />
          </button>
        </div>
      ))}
      <div className={styles.input_container}>
        <input
          placeholder='Podaj swoją umiejętność'
          className={cn(styles.input, styles.input_title)}
          value={skill}
          onChange={e => setSkill(e.target.value)}
          maxLength='100'
        />
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          classDropdown={styles.dropdownBody}
          value={level}
          setValue={setLevel}
          options={Object.values(levels)}
        />
        <button className={cn('button', styles.addButton)} onClick={addButton}>
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default Skills;
