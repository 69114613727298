import React, { useEffect } from 'react';
import styles from './EditorTinyRender.module.sass';
import cn from 'classnames';
import { convertMdToHtml } from 'utils/conversion';
import Prism from 'prismjs';
import './PrismOneDarkTheme.css';

const EditorTinyRender = ({ content, className }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  if (!content) return null;

  const createMarkup = () => {
    return {
      __html: convertMdToHtml(content),
    };
  };

  return (
    <>
      <div
        className={cn(styles.content, { [className]: className })}
        dangerouslySetInnerHTML={createMarkup()}
      ></div>
    </>
  );
};

export default EditorTinyRender;
