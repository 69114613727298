import {useState} from "react";
import {Link} from "react-router-dom";
import cn from "classnames";
import styles from "../Item.module.sass";
import Icon from "../../../../Icon";
import Control from "./Control";

import useAuth from "../../../../../hooks/useAuth";
import devscntrAuth from "../../../../../api/Instance/devscntrAuth";

const ItemComments = ({className, item, onClose, type, isAllRead}) => {
    const [ visible, setVisible ] = useState(false);
    // Controlled input's value in reply
    const [ currentValue, setCurrentValue ] = useState("");
    const [ replied, setReplied ] = useState(false);
    const [ isRead, setIsRead ] = useState(false)

    const authCtx = useAuth();

    const date = new Date(item.created_at);

    const setAsRead = () => {
        if (!isRead) {
            devscntrAuth
                .delete(`/accounts/me/notification/`, {
                    headers: {
                        TYPE: `comment_${type === "commentPost" ? "post" : "course"}`, ID: item.id,
                    },
                })
                .then(() => {
                    setIsRead(true);
                });
        }
        setIsRead(true);
    };

    const sendReply = () => {
        devscntrAuth
            .post("/posts/article/comment/", {
                text: currentValue, commentID: item.slug,
            })
            .then(() => {
                setCurrentValue("");
                setReplied(true);
                setAsRead();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (<div className={cn(styles.item, styles.new, className)} onClick={setAsRead}>
            <div
                className={cn(styles.isread, {[ styles.new ]: !isRead && !isAllRead})}
                
            >
                {!isRead && !isAllRead ? "Nowe" : "Przeczytano"}
            </div>
            <div className={styles.avatar}>
                <img
                    src={item.user_image ? item.user_image : "/images/default-avatar.png"}
                    alt="Avatar"
                />
                <div className={styles.icon}>
                    <Icon name="message"/>
                </div>
            </div>
            <div className={styles.details}>
                <div className={styles.line}>
                    <div className={styles.subtitle}>
                        {item.user_displayname}
                        <p> skomentował
                            <Link
                                className={styles.link}
                                to={type === "commentPost" ? `/post/${item.post_slug}` : `/course/${item.course_slug}`}
                            >
                                {type === "commentPost" ? "Twój post" : "Twój kurs"}
                            </Link>
                        </p>
                    </div>
                    <div className={styles.time}>{date.toLocaleString()}</div>
                </div>
                <div className={styles.content}>"{item.text}"</div>
                <Control
                    className={styles.control}
                    value={visible}
                    setValue={setVisible}
                    valueAnswer={currentValue}
                    setValueAnswer={setCurrentValue}
                    sendReplyHandler={sendReply}
                    isReplied={replied}
                    userImg={authCtx.userImg != undefined ? authCtx.userImg : "/images/default-avatar.png"}
                    comment={item}
                    authCtx={authCtx}
                />
            </div>
        </div>);
};

export default ItemComments;
