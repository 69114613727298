import de from './data/de';
import en from './data/en';
import pl from './data/pl';

const languages = {
  en: en,
  pl: pl,
  de: de,
};

export default function (lang) {
  try {
    return languages[lang];
  } catch (error) {
    return en;
  }
}
