import React from 'react';
import style from './Skeleton.module.sass';
import cn from 'classnames';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = ({ cards, className }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <SkeletonTheme key={i}>
        <div className={cn(style.skeleton, { [className]: className })} key={i}>
          <div className={style.img}>
            <Skeleton borderRadius={10} />
          </div>
          <div className={style.content}>
            <div className={style.row}>
              <Skeleton count={1} height={17} width={'90%'} />
              <Skeleton count={1} height={17} width={'40%'} />
            </div>
            <div className={style.row}>
              <Skeleton count={1} height={17} width={'60%'} />
              <Skeleton count={1} height={17} width={'50%'} />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default SkeletonLoader;
