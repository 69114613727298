export default {
  domain: 'O',
  title: 'Otwartość na doświadczenie',
  shortDescription: 'Otwartość na doświadczenie opisuje wymiar stylu poznawczego, który odróżnia kreatywnych ludzi z wyobraźnią od twardo stąpających po ziemi, konwencjonalnych ludzi.',
  description: 'Otwartość na doświadczenia to jedna z cech "Wielkiej Piątki", która odnosi się do stopnia, w jakim jednostka jest otwarta na nowe idee, doświadczenia, ma bujną wyobraźnię i jest zainteresowana sztuką. Osoby o wysokim poziomie otwartości na doświadczenia często są kreatywne, ciekawe świata i otwarte na różne perspektywy. Z drugiej strony, osoby o niskim poziomie otwartości na doświadczenia mogą być bardziej praktyczne, konwencjonalne i skłonne do rutyny. Oto pięć mocnych stron zarówno dla wysokiego, jak i niskiego wyniku w otwartości na doświadczenia.',
  results: [
    {
      score: 'low', // do not translate this line
      text: 'Twój wynik w dziedzinie Otwartość na Doświadczenie jest niski, co oznacza, że preferujesz myślenie w prostych, standardowych, pragmatycznych kategoriach. Inni opisują Cię jako przyziemnego, praktycznego i konserwatywnego.'
    },
    {
      score: 'neutral', // do not translate this line
      text: 'Twój wynik w dziedzinie Otwartość na Doświadczenie jest średni, co oznacza, że uznajesz tradycję za coś ważnego, ale jesteś osobą otwartą na próbowanie nowych rzeczy. Twoje myślenie nie jest ani specjalnie proste, ani przesadnie złożone. W oczach innych jesteś ciekawie myślącą osobą, ale nie przesadnym “artystą” poszukującym ciągle nowego.'
    },
    {
      score: 'high', // do not translate this line
      text: 'Twój wynik w dziedzinie Otwartość na Doświadczenie jest wysoki, co oznacza, że cieszysz się nowością, różnorodnością i zmianą. Jesteś osobą ciekawską, z bogatą wyobraźnią i dużymi pokładami kreatywności.'
    }
  ],
  facets: [
    {
      facet: 1,
      title: 'Wyobraźnia',
      text: 'Osoby o wysokim wyniku na skali wyobraźni często uważają, że prawdziwy świat jest zbyt zwykły i zwyczajny. Używają fantazji jako sposobu tworzenia bogatszego, bardziej interesującego świata. Osoby o niskim wyniku są bardziej skoncentrowane na faktach, tym co jest tu i teraz, niż na fantazji.'
    },
    {
      facet: 2,
      title: 'Zainteresowania artystyczne',
      text: 'Osoby o wysokim wyniku w tej dziedzinie kochają piękno zarówno w sztuce, jak i w naturze. Łatwo angażują się i pochłaniają artystyczne i naturalne wydarzenia. Nie muszą być szkoleni ani utalentowani artystycznie, chociaż wielu z nich takimi będzie. Definiującą cechą tej skali jest zainteresowanie i docenienie naturalnego i tworzonego piękna (design). Osoby o niskim wyniku nie wykazują wrażliwości estetycznej i zainteresowania sztuką.'
    },
    {
      facet: 3,
      title: 'Emocjonalność',
      text: 'Osoby o wysokim wyniku w dziedzinie emocjonalności mają łatwy dostęp i świadomość swoich własnych uczuć. Osoby o niskim wyniku są mniej świadome swoich uczuć i mają tendencję do zatrzymywania ekspresji swoich emocji.'
    },
    {
      facet: 4,
      title: 'Przygodowość',
      text: 'Osoby o wysokim wyniku w dziedzinie przygodowości są chętne do próbowania nowych działań, podróżowania do obcych krajów i doświadczania różnych rzeczy. Znudziła im się rutyna i codzienność, więc często zmieniają trasę do domu tylko dlatego, że jest inna. Osoby o niskim wyniku często czują się niekomfortowo w obliczu zmian i preferują znane rutyny, sposoby działania i konkretne procedury.'
    },
    {
      facet: 5,
      title: 'Intelekt',
      text: 'Intelekt i zainteresowania artystyczne są dwoma najważniejszymi i centralnymi aspektami otwartości na doświadczenie. Osoby o wysokim wyniku w dziedzinie intelektu uwielbiają bawić się ideami. Są otwarte na nowe i niezwykłe pomysły i lubią debatować na tematy intelektualne. Lubią łamigłówki, zagadki i łamigłówki logiczne. Osoby o niskim wyniku w dziedzinie intelektu preferują kontakt z ludźmi lub rzeczami niż z ideami. Uważają ćwiczenia intelektualne za stracony czas. Intelekt nie powinien być utożsamiany z inteligencją. Intelekt to styl intelektualny, a nie zdolność intelektualna, chociaż osoby o wysokim wyniku w dziedzinie intelektu osiągają nieco wyższe wyniki w standardowych testach inteligencji niż osoby o niskim wyniku w tej dziedzinie.'
    },
    {
      facet: 6,
      title: 'Liberalizm',
      text: 'Wysoki wynik na tej skali to psychologiczny liberalizm, który odnosi się do gotowości do kwestionowania władzy, konwencji i tradycyjnych wartości. W swojej najbardziej skrajnej formie, psychologiczny liberalizm może nawet oznaczać otwartą wrogość wobec reguł, współczucie dla łamiących prawo osób oraz miłość do niejednoznaczności, chaosu i nieładu. Niski wynik na tej skali to psychologiczny konserwatyzm, który preferuje bezpieczeństwo i stabilność wynikające z podporządkowania się tradycji. Psychologiczny liberalizm i konserwatyzm nie są tożsame z przynależnością polityczną, ale z pewnością skłaniają jednostki do określonych partii politycznych.'
    }
  ]
};
