import React, { useEffect, useState } from 'react';
import styles from './UserLevelBar.module.sass';
import cn from 'classnames';

const UserLevelBar = ({ min, max, current }) => {
  // UTILS--------------------------------------------------------------------------UTILS
  const [isMounted, setIsMounted] = useState(false);

  // UTILS--------------------------------------------------------------------------UTILS
  // CALCULATE PROGRESS------------------------------------------------CALCULATE PROGRESS

  const [progress, setProgress] = useState(current / max);

  const calculateProgress = (current, max) => {
    if (current > max) {
      setProgress(100);
    } else {
      setProgress((current / max) * 100);
    }
  };

  useEffect(() => {
    calculateProgress(current, max);
  }, [current, min, max]);

  // CALCULATE PROGRESS------------------------------------------------CALCULATE PROGRESS
  // ANIMATION-----------------------------------------------------------------ANIMATION

  const [animation, setAnimation] = useState(false);

  const animateXpAdd = () => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 1500);
  };

  useEffect(() => {
    // console.log(current);
    if (current) {
      if (isMounted) {
        animateXpAdd();
      } else {
        setIsMounted(true);
      }
    }
  }, [current]);

  // ANIMATION-----------------------------------------------------------------ANIMATION

  return (
    <div className={styles.container}>
      <div className={styles.bar}>
        <div
          className={cn(styles.bar_fill, animation && styles.animate)}
          style={{ height: `${progress}%` }}
        />
      </div>
      <div className={cn(styles.add_xp_info, animation && styles.animation)}>
        +XP
      </div>
    </div>
  );
};

export default UserLevelBar;
