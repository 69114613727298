import React, { useState } from 'react';
import styles from './Company.module.sass';
import cn from 'classnames';
import Icon from '../../../components/Icon';
import Thumbnail from 'components/MiniItemsLayout/Thumbnail';
import EditorContentRender from 'components/Editor/EditorContentRender';

const Company = ({ id, data }) => {
  const [company, setCompany] = useState(data);

  return (
    <div className={styles.container}>
      <div className={styles.company_info}>
        <Thumbnail
          className={company?.logo && styles.image_wrapper}
          image={company?.logo}
          imageFit='contain'
        />
        <div className={styles.data}>
          <div className={styles.name}>{company?.name || 'Brak nazwy'}</div>
          <div className={styles.tax_number}>
            <b>NIP: </b>
            {company?.NIP || 'Brak numeru podatkowego'}
          </div>
          <div className={styles.contact}>
            <span>
              <Icon name='phone' />
              {company?.phone || 'Brak numeru telefonu'}
            </span>
            <span>
              <b>Email: </b>
              {company?.mail || 'Brak adresu email'}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.about_us}>
        <div className={cn('title-purple', styles.subtitle)}>O firmie</div>
        {data.description ? (
          data.description.length < 8000 ? (
            <EditorContentRender
              className={styles.content}
              data={data.description}
            />
          ) : (
            <p className={styles.info}>Nie udało się wyświetlić opisu</p>
          )
        ) : (
          <p className={styles.info}>Ta firma jeszcze nie dodała opisu</p>
        )}
      </div>
      <div className={styles.locations}>
        <div className={cn('title-blue', styles.subtitle)}>
          Lokalizacje firmy
        </div>
        {company?.locations?.length > 0 ? (
          <ul>
            {company?.locations?.map((item, index) => (
              <li key={index}>
                {item.street} {item.house_number}, {item.postcode} {item.city}
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.info}>
            Ta firma jeszcze nie dodała żadnej lokalizacji
          </p>
        )}
      </div>
    </div>
  );
};

export default Company;
