import choicesDE from './data/de/choices';
import questionsDE from './data/de/questions.json';
import choicesEN from './data/en/choices';
import questionsEN from './data/en/questions.json';
import languages from './data/languages.json';
import choicesPL from './data/pl/choices';
import questionsPL from './data/pl/questions.json';

const files = {
  pl: {
    questions: questionsPL,
    choices: choicesPL,
  },
  en: {
    questions: questionsEN,
    choices: choicesEN,
  },
  de: {
    questions: questionsDE,
    choices: choicesDE,
  },
};

const languageSort = (a, b) => {
  if (a.text < b.text) return -1;
  if (a.text > b.text) return 1;
  return 0;
};

languages.sort(languageSort);

export const getItems = (lang = 'pl') => {
  let choices;
  let questions;

  try {
    questions = files[lang].questions;
    choices = files[lang].choices;
  } catch (error) {
    throw new Error('Inventory not found. Try another language input.');
  }

  const inventory = questions;
  return inventory.map((question, index) =>
    Object.assign(question, { num: ++index, choices: choices[question.keyed] })
  );
};

export const getInfo = () => ({
  name: "Johnson's IPIP NEO-PI-R",
  id: 'johnson-120-ipip-neo-pi-r',
  shortId: 'b5-120',
  time: 10,
  questions: 120,
  note: 'Recommended',
  languages,
});

export const getChoices = (lang = 'pl') => {
  let choices;
  try {
    choices = files[lang].choices;
  } catch (error) {
    throw new Error('Choices not found. Try another language input.');
  }
  return choices;
};

export const getQuestions = (lang = 'pl') => {
  let questions;
  try {
    questions = files[lang].questions;
  } catch (error) {
    throw new Error('Questions not found. Try another language input.');
  }
  return questions;
};
