import React, { useEffect, useState } from 'react';
import styles from './Privacy.module.sass';
import cn from 'classnames';
import Item from '../Item';
import Checkbox from 'components/Checkbox';
import { Link } from 'react-router-dom';

const Privacy = ({ className, agreements }) => {
  const [privacyPolitics, setPrivacyPolitics] = useState(true);
  const [personalDetails, setPersonalDetails] = useState(true);

  useEffect(() => {
    if (agreements) {
      setPrivacyPolitics(agreements.privacy_poitics);
      setPersonalDetails(agreements.personal_details);
    }
  }, [agreements]);

  return (
    <Item
      className={cn(styles.card, className)}
      title='Prywatność'
      classTitle='title-green'
    >
      <div className={styles.links}>
        <Link to='/privacy' className={styles.link}>
          Polityka prywatności
        </Link>
        &nbsp;i&nbsp;
        <Link to='/statute' className={styles.link}>
          Regulamin
        </Link>
      </div>
      <div className={styles.list}>
        <div className={styles.container}>
          <Checkbox
            inactive
            className={styles.checkbox}
            value={privacyPolitics || true}
            onChange={() => setPrivacyPolitics(true)}
          />
          <div>
            Zapoznałem się z treścią polityki prywatności https://www.itcntr.com/ w tym z informacją o celu
            i sposobach przetwarzania danych osobowych oraz prawie dostępu do treści swoich danych i prawie ich poprawiania.
          </div>
        </div>
        <div className={styles.container}>
          <Checkbox
            inactive
            className={styles.checkbox}
            value={personalDetails || true}
            onChange={() => setPersonalDetails(true)}
          />
          <div>
            Niniejszym wyrażam zgodę na przetwarzanie moich danych osobowych podanych przeze mnie w formularzu rejestracyjnym
            konta na portalu https://www.itcntr.com/'
          </div>
        </div>
      </div>
    </Item>
  );
};

export default Privacy;
