import React, { useEffect, useState } from 'react';
import styles from './Comments.module.sass';
import cn from 'classnames';
import { coursesEndpoints, postsEndpoints } from 'api/endpoints';
import Comment from 'components/Comment';
import { showError } from 'utils/showError';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import axios from 'axios';
import { showLoginModal } from 'utils/showLoginModal';
import { validateInput } from 'utils/validation';
import uuid from 'react-uuid';
import { maxCommentLength } from 'data/dataArrays';
import Button from 'components/Button';

const MAX_COMMENT_LENGTH = maxCommentLength;

const sendCommentEndpoints = {
  post: postsEndpoints.createComment,
  course: coursesEndpoints.createComment,
};

const showCommentsEndpoints = {
  post: postsEndpoints.showComments,
  course: coursesEndpoints.showComments,
};

const Comments = React.forwardRef(({ className, id, type }, ref) => {
  // UTILS-----------------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [comments, setComments] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUrl = showCommentsEndpoints[type] ?? '';
  const sendUrl = sendCommentEndpoints[type] ?? '';

  const [value, setValue] = useState('');

  // UTILS-----------------------------------------------------------------------------------UTILS
  // GET COMMENTS---------------------------------------------------------------------GET COMMENTS

  const getComments = async cancelToken => {
    let headers = null;
    switch (type) {
      case 'post':
        headers = { 'POST-ID': id };
        break;
      case 'course':
        headers = { 'COURSE-ID': id };
        break;
      default:
        headers = null;
        break;
    }

    if (!headers) return;
    try {
      const response = await axiosInstance.request({
        method: 'get',
        url: getUrl,
        cancelToken: cancelToken.token,
        headers: headers,
      });
      const sortedComments =
        response?.data?.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        ) || [];
      setComments(sortedComments);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getComments(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [id, refresh]);

  // GET COMMENTS---------------------------------------------------------------------GET COMMENTS
  // VALIDATION-------------------------------------------------------------------------VALIDATION

  const onValueChange = value => {
    const allowedCharacters =
      /^[a-zA-Z0-9ĄąĆćĘęŁłŃńÓóŚśŹźŻż \p{L}\n"':!@#$%\^&*\(\)+,.?\-]+$/;

    if (value.length > 0) {
      if (!allowedCharacters.test(value[value.length - 1])) {
        return;
      }
    }
    setValue(value);
  };

  const validation = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return false;
    }

    if (!validateInput(value, MAX_COMMENT_LENGTH, 2, 'Komentarz', 'text'))
      return false;

    return true;
  };

  // VALIDATION-------------------------------------------------------------------------VALIDATION
  // SEND COMMENTS-------------------------------------------------------------------SEND COMMENTS

  const sendComment = async comment => {
    setIsLoading(true);
    if (!sendUrl.length > 0) return;

    let data = null;
    switch (type) {
      case 'post':
        data = { postID: id, text: value };
        break;
      case 'course':
        data = { courseID: id, text: value };
        break;
      default:
        data = null;
        break;
    }

    if (!data) return;
    try {
      const response = await devscntrAuth.post(sendUrl, data);
      setRefresh(prev => !prev);
      setValue('');
      authCtx.getLevel();
    } catch (error) {
      showError('Nie udało się wysłać komentarza. Spróbuj ponownie później.');
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    if (validation()) sendComment();
  };

  // SEND COMMENTS-------------------------------------------------------------------SEND COMMENTS

  return (
    id && (
      <div className={cn(styles.container, className && className)} ref={ref}>
        <div className={styles.head}>
          <div className={styles.comments_count}>
            {comments?.length > 0 ? comments?.length : '0'}
          </div>
          Komentarze
        </div>
        <div className={styles.field}>
          <textarea
            value={value}
            onChange={e => onValueChange(e.target.value)}
            className={cn(
              styles.textarea,
              value.length > MAX_COMMENT_LENGTH && styles.error
            )}
            placeholder='Podziel się swoją opinią...'
          />
          <div
            className={cn(
              styles.counter,
              value.length > MAX_COMMENT_LENGTH && styles.error
            )}
          >
            {value.length}/{MAX_COMMENT_LENGTH}
          </div>
        </div>
        <Button
          classNames={styles.button}
          isLoading={isLoading}
          text='Wyślij'
          onClick={handleSubmit}
        />
        <div className={styles.list}>
          {comments?.map((item, index) => (
            <Comment
              key={`comment_${item.id}_${index}`}
              className={styles.item}
              item={item}
              onComment={() => setRefresh(prev => !prev)}
              type={type}
            />
          ))}
        </div>
      </div>
    )
  );
});

export default Comments;
