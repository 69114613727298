import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Icon from 'components/Icon';
import styles from './Edit.module.sass';
import TextInput from 'components/TextInput';
import Checkbox from 'components/Checkbox';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import DatePicker from '../../DatePicker';
import { showError } from 'utils/showError';
import uuid from 'react-uuid';

const EditPromoCodes = (
    {
        item,
        refresh,
    }) => {
    const {
        id,
        code,
        percentage_value,
        number_value,
        number_of_uses_left,
        use_limited,
        time_limited,
        expiration_date,
        number_of_uses_max,
        courses,
    } = item;

    const [visible, setVisible] = useState(false);
    const authCtx = useAuth();

    const [codeValue, setCodeValue] = useState(code);
    const [timeLimit, setTimeLimit] = useState(time_limited);
    const [numberValue, setNumberValue] = useState('');
    const [percentageValue, setPercentageValue] = useState('');
    const [expirationDate, setExpirationDate] = useState(expiration_date);
    const [useLimit, setUseLimit] = useState(use_limited);
    const [amountOfUses, setAmountOfUses] = useState(number_of_uses_max);
    const [radioButton, setRadioButton] = useState(true)

    const [coursesList, setCoursesList] = useState(courses);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 12;
    const indexOfLast = currentPage * pageSize;
    const indexOfFirst = indexOfLast - pageSize;
    const [currentList, setCurrentList] = useState(coursesList.slice(indexOfFirst, indexOfLast));

    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(coursesList.length / pageSize); i++) {
        pageNumbers.push(i);
    }

    const pageHandler = (event) => {
        setCurrentPage(event.target.id);
    };

    const validateInputs = () => {
        if (codeValue.trim(' ').length === 0) {
            showError('Kod nie może być pusty.');
            return false;
        }
        if (codeValue.trim(' ').length > 64) {
            showError('Kod nie być dłuższy niż 64 znaków.');
            return false;
        }
        if (numberValue.toString().trim(' ').length === 0 && percentageValue.toString().trim(' ').length === 0) {
            showError('Zniżka nie może być pusta.');
            return false;
        }
        if (isNaN(numberValue)) {
            showError('Wartość zniżki (zł) musi być liczbą');
            return false;
        }
        if (percentageValue > 100) {
            showError('Zniżka (%) nie może być większa niż 100.');
            return false;
        }
        if (isNaN(percentageValue)) {
            showError('Wartość zniżki (%) musi być liczbą.');
            return false;
        }
        if (timeLimit === true) {
            if (expirationDate === '' || !expirationDate) {
                showError('Data nie może być pusta.');
                return false;
            }
        }
        if (useLimit === true) {
            if (amountOfUses.toString().trim('').length === 0) {
                showError('Ilosć użyć nie może być pusta.');
                return false;
            }
            if (isNaN(amountOfUses)) {
                showError('Wartość ilości użyć musi być liczbą.');
                return false;
            }
        }
        return true;
    };

    const dateHandler = (date) => {
        setExpirationDate(date);
    }

    useEffect(() => {
        if (time_limited === false) {
            setExpirationDate('')
        }
        if (use_limited === false) {
            setAmountOfUses('')
        }
        if (!number_value) {
            setRadioButton(false)
            setNumberValue('')
            setPercentageValue(percentage_value)
        }
        if (!percentage_value) {
            setRadioButton(true)
            setPercentageValue('')
            setNumberValue(number_value)
        }
    }, [])

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const prepareFormData = () => {
        //temporary solution
        let x = formatDate(expirationDate);
        let t = ' 00:00:00'
        let xt = x + t;
        const formData =
            (
                {
                    id: id,
                    code: code,
                    courses: coursesList.map(course => (
                        { id: course.id }
                    )),
                    number_value: numberValue !== '' ? (numberValue) : (null),
                    percentage_value: percentageValue !== '' ? (percentageValue) : (null),
                    time_limited: timeLimit,
                    expiration_date: timeLimit ? (xt) : (null),
                    use_limited: useLimit,
                    number_of_uses_max: useLimit ? (amountOfUses) : (null),
                }
            )
        return formData;
    }

    const editCodeApi = async data => {
        try {
            const response = await devscntrAuth.post(
                'course/course-promo-code/',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        method: 'edit',
                    },
                }
            );
            authCtx.getLevel();
            refresh && refresh();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setCurrentList(coursesList.slice(indexOfFirst, indexOfLast));
    }, [currentPage])

    const courseRemover = (e, index) => {
        e.preventDefault()
        coursesList.splice(index + (currentPage - 1) * pageSize, 1)
        setCurrentList(coursesList.slice(indexOfFirst, indexOfLast));
        if (coursesList.length % pageSize === 0) {
            pageNumbers.slice(pageNumbers.length, 1);
            setCurrentPage(pageNumbers.length - 1);
        }
    };

    const handleSubmit = () => {
        if (validateInputs()) {
            const formData = prepareFormData();
            editCodeApi(formData);
        }
    };

    const handleClose = () => {
        setVisible(false);
        refresh && refresh();
    }

    return (
        <div className={cn(styles.filters, { [styles.active]: visible })}>
            <button className={cn("button-square-stroke button-small", styles.head)} onClick={() => setVisible(true)}>
                <Icon name="edit" size="24" />
            </button>
            <div className={styles.body}>
                <div className={styles.top}>
                    <div className={cn("title-red", styles.title)}>Edycja kodu</div>
                    <button className={styles.close} onClick={handleClose}>
                        <Icon name="close" size="20" />
                    </button>
                </div>
                <div>
                    <div className={styles.input_container}>
                        <div className={styles.input_title}>
                            <TextInput
                                label="Kod rabatowy"
                                maxLength='64'
                                value={codeValue}
                                onChange={e => setCodeValue(e.target.value)}
                            />
                        </div>
                        <div className={styles.radio_box}>
                            <Checkbox
                                content="Zniżka w zł"
                                value={radioButton}
                                onChange={() => { setRadioButton(true); setPercentageValue('') }}
                            />
                            <Checkbox
                                content="Zniżka w %"
                                value={!radioButton}
                                onChange={() => { setRadioButton(false); setNumberValue('') }}
                            />
                        </div>

                        <div className={styles.data_box}>
                            {radioButton && (
                                <TextInput
                                    placeholder="Podaj zniżke zł"
                                    value={numberValue}
                                    onChange={e => setNumberValue(e.target.value)}
                                />
                            )}
                            {!radioButton && (
                                <TextInput
                                    placeholder="Podaj zniżke %"
                                    value={percentageValue}
                                    onChange={e => setPercentageValue(e.target.value)}
                                />
                            )}
                        </div>
                        <div className={styles.checkbox_box}>
                            <Checkbox
                                content="Ograniczenie czasowe"
                                value={timeLimit}
                                onChange={(e) => {
                                    setTimeLimit(e.target.checked);
                                }}
                            />
                        </div>
                        {timeLimit && (
                            <div className={styles.data_box}>
                                <DatePicker
                                    value={dateHandler}
                                    startingDate={expirationDate}
                                />
                            </div>
                        )}
                        <div className={styles.checkbox_box}>
                            <Checkbox
                                content="Ograniczona ilość użyć"
                                value={useLimit}
                                onChange={(e) => {
                                    setUseLimit(e.target.checked);
                                }}
                            />
                        </div>
                        {useLimit && (
                            <div className={styles.data_box}>
                                <TextInput
                                    placeholder="Podaj ilość użyć."
                                    value={amountOfUses}
                                    onChange={e => setAmountOfUses(e.target.value)}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={cn(styles.courses_title)}>Przypisane kursy: </div>
                {coursesList.length > 0 ? (
                    <>
                        <div className={styles.coursesList}>
                            {currentList.map((x, index) => (
                                <div key={uuid()} className={styles.coursesListItem}>
                                    <div key={index} className={styles.item}>
                                        <div className={styles.item_title}>{x.title}</div>
                                        <button onClick={(e) => courseRemover(e, index)} className={styles.trash_icon}>
                                            <Icon name="trash" size="20" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.paginationPosition}>
                            {pageNumbers.length > 1 &&
                                (
                                    pageNumbers.map(number => (
                                        <button className={styles.pagination} key={number} id={number} onClick={pageHandler}>
                                            {number}
                                        </button>
                                    ))
                                )}
                        </div>
                    </>
                ) :
                    (
                        <div className={styles.row}>
                            Brak przypisanych kursów.
                        </div>
                    )
                }
                <div className={styles.modal_footer}>
                    <button className={cn("button", styles.button)} onClick={handleSubmit}>
                        Wprowadź zmiany
                    </button>
                </div>
            </div>
            <div className={styles.overlay} onClick={handleClose}></div>
        </div>
    )
}

export default EditPromoCodes;