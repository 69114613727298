import React, { useEffect, useState } from "react";
import styles from "./Filter.module.sass";
import cn from "classnames";
import Filters from "components/Filters";
import Dropdown from "components/Dropdown";
import Button from "components/Button";
import { useHistory, useLocation } from "react-router";

const sortOptions = [
  {
    name: "najnowsze",
    value: "newest",
  },
  {
    name: "najstarsze",
    value: "oldest",
  },
];

const statusOptions = [
  {
    name: "wszystkie",
    value: "",
  },
  {
    name: "oczekujące",
    value: "unsolved",
  },
  {
    name: "rozwiązane",
    value: "solved",
  },
];

const typeOptions = [
  {
    name: "wszystkie",
    value: "",
  },
  {
    name: "post",
    value: "posts",
  },
  {
    name: "kurs",
    value: "courses",
  },
  {
    name: "oferta pracy",
    value: "jobs",
  },
  {
    name: "komentarze postów",
    value: "postscomms",
  },
  {
    name: "komentarze kursów",
    value: "coursescomms",
  },
];

const Filter = () => {
  // UTILS---------------------------------------------------------------------------UTILS

  const [isMounted, setIsMounted] = useState(false);

  const history = useHistory();
  const searchUrl = useLocation().search;

  const [sort, setSort] = useState(sortOptions[0].name);
  const [status, setStatus] = useState(statusOptions[0].name);
  const [type, setType] = useState(typeOptions[0].name);

  const resetFilters = () => {
    setSort(sortOptions[0].name);
    setStatus(statusOptions[0].name);
    setType(typeOptions[0].name);
  };

  // UTILS---------------------------------------------------------------------------UTILS
  // GET PARAMS-----------------------------------------------------------------GET PARAMS

  const getUrlParams = () => {
    let pushParams = false;

    // SORT
    let newSort = new URLSearchParams(searchUrl).get("sortby");
    const sortIndex = sortOptions.map((item) => item.value).indexOf(newSort);
    if (sortIndex >= 0) {
      setSort(sortOptions[sortIndex].name);
    } else {
      setSort(sortOptions[0].name);
      pushParams = true;
    }

    // STATUS
    let newStatus = new URLSearchParams(searchUrl).get("filterby");
    const statusIndex = statusOptions.map((item) => item.value).indexOf(newStatus);
    if (statusIndex >= 0) {
      setStatus(statusOptions[statusIndex].name);
    } else if (newStatus !== null) {
      pushParams = true;
    }

    // TYPE
    let newType = new URLSearchParams(searchUrl).get("query");
    const typeIndex = typeOptions.map((item) => item.value).indexOf(newType);
    if (typeIndex >= 0) {
      setStatus(typeOptions[typeIndex].name);
    } else if (newType !== null) {
      pushParams = true;
    }

    if (pushParams) pushUrlParams();
    setIsMounted(true);
  };

  useEffect(() => {
    getUrlParams();
  }, []);

  // GET PARAMS-----------------------------------------------------------------GET PARAMS
  // PUSH PARAMS---------------------------------------------------------------PUSH PARAMS

  const pushUrlParams = () => {
    let params = "";

    // SORT
    const sortIndex = sortOptions.map((item) => item.name).indexOf(sort);
    if (sortIndex !== undefined && sortIndex >= 0) {
      params = params + `?sortby=${sortOptions[sortIndex].value}`;
    } else {
      params = params + `?sortby=${sortOptions[0].value}`;
    }

    // STATUS
    const statusIndex = statusOptions.map((item) => item.name).indexOf(status);
    if (statusIndex !== undefined && statusIndex > 0) {
      params = params + `&filterby=${statusOptions[statusIndex].value}`;
    }

    // TYPE
    const typeIndex = typeOptions.map((item) => item.name).indexOf(type);
    if (typeIndex !== undefined && typeIndex > 0) {
      params = params + `&query=${typeOptions[typeIndex].value}`;
    }

    history.push({
      search: params,
    });
  };

  useEffect(() => {
    if (isMounted) {
      pushUrlParams();
    }
  }, [sort, status, type]);

  // PUSH PARAMS---------------------------------------------------------------PUSH PARAMS

  return (
    <Filters className={styles.filter} title="Filtry">
      <div className={styles.container}>
        <div className={styles.item}>
          <Dropdown label="Sortowanie" options={sortOptions.map((item) => item.name)} value={sort} setValue={setSort} />
        </div>
        <div className={styles.item}>
          <Dropdown
            label="Status zgłoszenia"
            options={statusOptions.map((item) => item.name)}
            value={status}
            setValue={setStatus}
          />
        </div>
        <div className={styles.item}>
          <Dropdown label="Typ treści" options={typeOptions.map((item) => item.name)} value={type} setValue={setType} />
        </div>
        <Button text="Wyczyść filtry" onClick={resetFilters} />
      </div>
    </Filters>
  );
};

export default Filter;
