import React, { useContext, useEffect, useState } from 'react';
import styles from './minijzwit.module.sass';
import { IoIosArrowForward } from 'react-icons/io';
import { Link, Route, useParams, useRouteMatch } from 'react-router-dom';
import Page from '../Page';
import JZWIT from '../../screens/JakZaczacWIt';
import useAxios from 'hooks/useAxios';
import { jzwitEndpoints } from 'api/endpoints';
import JZWITContext from 'context/jzwitContext';
import { convertFromRaw, EditorState } from 'draft-js';
const MiniJZWIT = ({ child }) => {
  const jzwitCntx = useContext(JZWITContext);
  const [content, setContent] = useState(null);

  const { url, path } = useRouteMatch();

  useEffect(() => {
    jzwitCntx.setJzwitUrl(url);
    // jzwitCntx.setJzwitPath(url);
    // return () => {
    //   // console.log('beforeCTXpath', jzwitCntx.jzwitPath);
    //   // console.log('beforeCTXurl', jzwitCntx.jzwitUrl);
    //   // console.log('beforeTECHpath', path);
    //   // console.log('beforeTECHurl', url);
    //   jzwitCntx.setJzwitUrl(url);
    //   jzwitCntx.setJzwitPath(url);
    //   // console.log('---------------------------')
    //   // console.log('afterCTXpath', jzwitCntx.jzwitPath);
    //   // console.log('afterCTXurl', jzwitCntx.jzwitUrl);
    //   // console.log('afterTECHpath', path);
    //   // console.log('afterTECHurl', url);
    // };
  }, [url]);
  // console.log('CTXpath', jzwitCntx.jzwitPath);
  // console.log('CTXurl', jzwitCntx.jzwitUrl);
  // console.log('TECHpath', path)
  // console.log('TECHurl', url)

  const handleClick = () => {
    jzwitCntx.setJzwitPath(url);
  };

  useEffect(() => {
    if (child) {
      let newContent;
      if (child?.text !== null) {
        if (child?.editor_version === 1) {
          newContent = EditorState.createWithContent(
            convertFromRaw(JSON.parse(child?.text))
          );
          setContent(newContent.getCurrentContent().getPlainText());
        }
        if (child?.editor_version === 2) {
          newContent = child?.text;
          setContent(newContent);
        }
      } else {
        setContent('');
      }
    }
  }, [child]);

  // console.log('child: ', child);

  return (
    <li className={styles.detal_item}>
      <div className={styles.detal_content}>
        <Link
          to={`${jzwitCntx.jzwitUrl}/${child.slug}`}
          className={styles.detal_title}
          onClick={handleClick}
        >
          {/* PRZEJŚCIE DO DETALU TECHNOLOGI  - ✅ */}
          <h4>{child.name}</h4>
        </Link>
        <p className={styles.content}>{content}</p>
        {/* LISTING TECHNOLOGI  - ✅  */}
        {child?.children.length >= 1 && (
          <aside className={styles.detal_tags_row}>
            {child?.children.map((tech, index) => (
              // PRZEJSCIE DO DETALU DZIECKA TECHNOLOGI
              <Link
                to={`${jzwitCntx.jzwitUrl}/${tech.slug}`}
                className={styles.detal_tag}
                key={index}
              >
                <img
                  src={tech?.icon ? tech.icon : '/images/logo-dark.svg'}
                  alt={tech?.name}
                />
                {tech?.name}
              </Link>
            ))}
          </aside>
        )}
      </div>
      <div className={styles.detal_side_icons}>
        {/* PRZEJSCIE DO LISTINGU DZIECI */}
        {/* {child?.children.length > 0 && (
          <Link to={`${child.id}`} className={styles.detal_next_icon}>
            <IoIosArrowForward />
          </Link>
        )} */}

        {/* ICON  - ✅  */}
        <div className={styles.detal_main_icon}>
          <img
            src={child.icon ? child.icon : '/images/logo-dark.svg'}
            alt={child.name}
          />
        </div>
      </div>
    </li>
  );
};

export default MiniJZWIT;
