import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './Pins.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import { useHistory } from 'react-router';
import LatestComments from '../../components/LatestComments';
import LatestCourses from '../../components/LatestCourses';
import { useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import GroupContext from '../../context/group-context';
import MiniPost from 'components/MiniPost';
import MiniCourse from 'components/MiniCourse';
import Loader from 'components/MiniPost/Loader';
import MiniJob from 'components/MiniJob';
import { pageSize } from 'data/dataArrays';
import devscntrAuth from 'api/Instance/devscntrAuth';
import PaginationGrowingFooter from 'components/PaginationGrowingFooter';

const Pins = ({ className }) => {
  const groupCtx = useContext(GroupContext);

  const [visible, setVisible] = useState(false);
  const { name } = useParams();
  const nameChange = useRef(0);

  const history = useHistory();

  const [pins, setPins] = useState([]);
  const [pinsCount, setPinsCount] = useState(0);
  const [loadingPins, setLoadingPins] = useState(false);

  // PAGINATION------------------------------------------------------------------PAGINATION

  const [currentPage, setCurentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const observer = useRef();
  const lastItemRef = useCallback(
    post => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting && !isLastPage) {
          console.log('We are near the last post!');
          setCurentPage(prev => ++prev);
        }
      });
      if (post) observer.current.observe(post);
    },
    [pins]
  );

  // PAGINATION------------------------------------------------------------------PAGINATION

  // <----- POBIERNAIE ----->
  const getPins = async passedNameChange => {
    try {
      setLoadingPins(true);
      const { data } = await devscntrAuth.get(
        `/accounts/user-pins/?groupnames=${name}&page=${currentPage}&page_size=${pageSize}`,
        {
          headers: {
            METHOD: 'pins',
          },
        }
      );
      console.log(data);
      // it checks if you switched card to prevent filling with wrong data
      if (passedNameChange !== nameChange.current) return;

      const results = data?.results || [];

      if (data?.next) setIsLastPage(false);
      else setIsLastPage(true);

      setPinsCount(data?.count || 0);
      setPins(prev => [...prev, ...results]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPins(false);
    }
  };

  // <----- USUWANIE GURPY ----->
  const [Delete, errorDelete, loadingDelete, GetDelete] = useAxios();
  const deleteCurrentGroup = () => {
    GetDelete({
      method: 'post',
      url: '/accounts/me/managegroup/',
      customHeaders: {
        METHOD: 'delete',
      },
      requestConfig: {
        name: name,
      },
    });
  };

  const handleDelete = e => {
    e.preventDefault();
    deleteCurrentGroup();
    groupCtx.setGroupHandler(prevState => !prevState);
    history.push(`/`);
  };

  const handleClearPinsData = () => {
    setPins([]);
    setPinsCount(0);
    setCurentPage(1);
    setIsLastPage(true);
  };

  useEffect(() => {
    nameChange.current = nameChange.current + 1;
    handleClearPinsData();
    // getPins(nameChange.current);
  }, [name]);

  useEffect(() => {
    getPins(nameChange.current);
  }, [name, groupCtx.deleteHandler, groupCtx.groupHandler, currentPage]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.header}>
            <h1>{name}</h1>
            <div className={styles.headerContent}>
              <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                <div
                  className={cn(styles.notification, className, {
                    [styles.active]: visible,
                  })}
                >
                  <button
                    className={styles.options}
                    onClick={() => setVisible(!visible)}
                  >
                    <span></span>
                  </button>

                  <div className={styles.content}>
                    <button
                      className={styles.groupDelete}
                      onClick={handleDelete}
                    >
                      Usuń Grupę
                    </button>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          </div>

          {loadingPins && !pins.length > 0 ? (
            <Loader cards={6} />
          ) : (
            <>
              {pinsCount > 0 &&
                pins.map((pin, index) => (
                  <li key={index} className={styles.pinElement}>
                    {pin.type === 'post' && (
                      <MiniPost
                        key={pin.object.id}
                        article={pin.object}
                        ref={pins.length === index + 1 ? lastItemRef : null}
                      />
                    )}
                    {pin.type === 'course' && (
                      <MiniCourse
                        key={pin.object.id}
                        article={pin.object}
                        ref={pins.length === index + 1 ? lastItemRef : null}
                      />
                    )}
                    {pin.type == 'job' && (
                      <MiniJob
                        className={styles.margin_bottom}
                        key={pin.object.id}
                        article={pin.object}
                        ref={pins.length === index + 1 ? lastItemRef : null}
                      />
                    )}
                  </li>
                ))}
              {!loadingPins && pinsCount === 0 && (
                <p className={styles.isEmpty}>BRAK PRZYPINEK</p>
              )}
            </>
          )}
          <PaginationGrowingFooter
            data={pins}
            isLastPage={isLastPage}
            isLoading={loadingPins}
            setCurrentPage={setCurentPage}
          />
        </div>
        <div className={styles.col}>
          <LatestCourses className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Pins;
