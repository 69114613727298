import React from 'react';
import styles from './BigFiveTestInstruction.module.sass';
import Modal from 'components/Modal';
import Button from 'components/Button';
import instructionImgLight from './assets/b5_question_light.webp';
import instructionImgDark from './assets/b5_question_dark.webp';
import useDarkMode from 'use-dark-mode';

const BigFiveTestInstruction = ({ visible, onClose }) => {
  const isDark = useDarkMode().value;

  return (
    <Modal visible={visible} onClose={onClose} outerClassName={styles.modal}>
      <div className={styles.container}>
        <h2 className={styles.title}>Instrukcja</h2>

        <p className={styles.text}>
          Odpowiadaj na pytania zgodnie z tym, jaki jesteś teraz, a nie, jaki
          chciałbyś być w przyszłości. Udzielaj szczerych odpowiedzi, bo to
          dzięki nim będziemy w stanie podać Tobie trafne wyniki. Pamiętaj, że
          udział w badaniu jest dobrowolny, w każdym momencie można się z niego
          wycofać bez podawania powodu. Nie ma tutaj odpowiedzi dobrych i złych.
        </p>

        <p className={styles.text}>
          Każde pytanie zaiwera 5 możliwych odpowiedzi, z których należy wybrać
          jedną.
        </p>

        <div className={styles.imgContainer}>
          <img
            src={isDark ? instructionImgDark : instructionImgLight}
            alt='Przykładowe pytanie'
            className={styles.img}
          />
        </div>

        <p className={styles.text}>
          Wykonanie testu zajmie około 20 minut. Najlepiej, aby test został
          wykonany w cichym miejscu bez dystrakcji oraz podczas jednego
          posiedzenia.
        </p>

        <Button onClick={onClose}>Rozumiem, przejdź do kwestionariusza</Button>
      </div>
    </Modal>
  );
};

export default BigFiveTestInstruction;
