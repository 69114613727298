import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './MiniPost.module.sass';
import relatedStyle from './RelatedMiniPost.module.sass';

import { Link, useLocation } from 'react-router-dom';
import { EditorState, convertFromRaw } from 'draft-js';
import ModalPin from 'components/ModalPin';
import useAuth from 'hooks/useAuth';
import { showLoginModal } from 'utils/showLoginModal';
import MoreOptions from 'components/MiniItemsLayout/MoreOptions';
import Reactions from 'components/MiniItemsLayout/Reactions';
import Author from 'components/MiniItemsLayout/Author';
import Tags from 'components/MiniItemsLayout/Tags';
import Categories from 'components/MiniItemsLayout/Categories';
import Thumbnail from 'components/MiniItemsLayout/Thumbnail';
import useBreakpoints from 'hooks/useBreakpoints';
import ModalReport from 'components/ModalReport';
import Icon from '../Icon';
import isJson from 'utils/isJson';
import { convertMdToHtml } from 'utils/conversion';

const MiniPost = React.forwardRef(({ className, article, related }, ref) => {
  const authCtx = useAuth();
  const [content, setContent] = useState(null);
  const [hidden, setHidden] = useState(false);

  const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ['desktop', Infinity],
    ['mobile', 716],
  ];
  useBreakpoints(stylesRef, breakpoints);

  useEffect(() => {
    if (article) {
      let newContent;

      if (article?.description === null) {
        setContent('');
        return;
      }

      if (article?.editor_version === 1 && isJson(article?.description)) {
        newContent = EditorState.createWithContent(
          convertFromRaw(JSON.parse(article.description))
        );
        setContent(newContent.getCurrentContent().getPlainText());
      }

      if (article?.editor_version === 2) {
        const parser = new DOMParser();
        const htmlString = convertMdToHtml(article.description);
        const doc = parser.parseFromString(htmlString, 'text/html');
        const text = doc.body.textContent;
        setContent(text);
      }
    }
  }, [article]);

  useEffect(() => {
    setPinState(article.pinned);
    if (pinState !== null) setPinState(article?.pinned);
  }, [article?.pinned]);

  // -----------------------------------------------------
  // <----- !!!! PRZYPINKI !!!! ----->
  // -----------------------------------------------------
  const [pinState, setPinState] = useState(article?.pinned || false);
  const [StarModalVisible, setStarModalVisible] = useState(false);
  const location = useLocation();
  // <----- WALIDACJA ODPINANIA ----->
  const pinsPath = location.pathname.slice(0, 5);
  const [redirectedUser, setRedirectedUser] = useState();
  const [unpinPost, setunpinPost] = useState();

  const unpinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (pinsPath === '/pins') {
      setunpinPost(true);
    } else {
      setRedirectedUser(true);
    }
  };
  const pinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    setStarModalVisible(true);
  };
  // -----------------------------------------------------
  // -----------------------------------------------------

  return (
    article &&
    !hidden && (
      <article
        className={related && styles.article_auto_width}
        ref={x => setStylesRef(x)}
      >
        <div
          className={cn(
            related ? relatedStyle.item : styles.item, className && className
          )}
          ref={ref}
        >
          {(StarModalVisible || redirectedUser || unpinPost) && (
            <ModalPin
              visible={StarModalVisible}
              onClose={() => setStarModalVisible(false)}
              article={article}
              setPinState={setPinState}
              redirectedUser={redirectedUser}
              setRedirectedUser={setRedirectedUser}
              unpinPost={unpinPost}
              setunpinPost={setunpinPost}
              pinUrl={'/posts/article/user-post/'}
              idName={'post_id'}
              path={pinsPath}
            />
          )}

          <Link
            to={`/post/${article.slug}`}
            className={styles.imagecontainer}
          >
            <Thumbnail
              className={!relatedStyle ? styles.image : relatedStyle.image}
              image={article?.image}
            />
            <div className={styles.views}>
              <Icon name='eye' size='24' viewBox='0 0 24 24' />
              {article.views ? article.views : '-'}
            </div>
          </Link>

          <section className={styles.details}>
            <div className={styles.head}>
              <Author
                id={article?.author}
                username={article?.display_name}
                image={article?.author_image}
                level={article?.xp_pkg}
                isRss={article?.is_rss}
              />
              {article?.categories?.length > 0 && (
                <Categories
                  categories={article?.categories?.sort(
                    (a, b) => b.tags_count - a.tags_count
                  )}
                  baseLink='/posts'
                />
              )}
              {!related && (
                <div
                  className={
                    relatedStyle ? styles.actions : relatedStyle.actions
                  }
                >
                  <button
                    className={styles.pin_btn}
                    onClick={pinState ? unpinHandler : pinHandler}
                  >
                    <img
                      src={
                        pinState
                          ? '/images/Icons/heart/filled-color.svg'
                          : '/images/Icons/heart/stroke.svg'
                      }
                      alt='favourite'
                    />
                  </button>

                  <MoreOptions
                    item={article}
                    authorId={article.author}
                    type='post'
                    onDelete={() => setHidden(true)}
                  />
                </div>
              )}
            </div>

            {!related ? (
              <>
                <div>
                  <Link
                    to={`/post/${article.slug}`}
                    style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    <h2 className={styles.title}>
                      {article?.title || 'Brak tytułu'}
                    </h2>
                  </Link>
                </div>
                <div className={styles.description}>
                  {content}
                </div>
              </>
            ) :
              (
                <div>
                  <Link
                    to={`/post/${article.slug}`}
                    style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    <h2 className={styles.title}>
                      {article?.title || 'Brak tytułu'}
                    </h2>
                  </Link>
                </div>
              )}

            {!related && (
              <div className={!relatedStyle ? styles.foot : relatedStyle.foot}>
                <Reactions
                  className={styles.control}
                  type='post'
                  id={article?.slug}
                  comments={article?.comments_count}
                  rating={article?.rating}
                  rated={article?.rated}
                  currentReaction={
                    article?.emotion_of_user?.length > 0
                      ? article?.emotion_of_user[0]?.emotion
                      : 0
                  }
                  likes={article?.emotions_likes_count}
                  dislikes={article?.emotions_dislikes_count}
                  commentsLink={`/post/${article?.slug}#comments`}
                  ratingLink={`/post/${article?.slug}#rating`}
                />
              </div>
            )}
          </section>
        </div>
      </article>
    )
  );
});

export default MiniPost;
