import React, { useEffect, useState } from 'react';
import styles from './Search.module.sass';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import LatestCourses from '../../components/LatestCourses';
import LatestComments from '../../components/LatestComments';
import TooltipGlodal from '../../components/TooltipGlodal';
import Card from '../../components/Card';
import axios from 'axios';
import MiniPost from '../../components/MiniPost';
import MiniCourse from '../../components/MiniCourse';

import useAuth from '../../hooks/useAuth';
import devscntrAuth from '../../api/Instance/devscntrAuth';
import devscntrNoauth from '../../api/Instance/devscntrNoauth';
import { miscEndpoints } from 'api/endpoints';
import { pageSize } from 'data/dataArrays';
import MiniJobOffer from 'components/MiniJobOffer';
import uuid from 'react-uuid';
import MiniUser from 'components/MiniUser';
import Icon from 'components/Icon';
import UserLoader from 'components/MiniUser/Loader';
import Loader from 'components/MiniCourse/Loader';
import JobLoader from 'components/MiniJobOffer/Loader';
import MiniJob from 'components/MiniJob';

const DUMMY_DATA = [
  {
    id: 43,
    created_at: '2022-07-27T19:43:52.171046Z',
    author: 5,
    author_name: 'Bitcoin Majster <frontend@mail.mail>',
    display_name: 'BtcMiner21',
    title: 'asdasfsadfasdfafdgadsfadsg',
    description:
      '{"blocks":[{"key":"al10r","text":"sdgfhfyhgjukjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    image: 'https://d315baofnr6zvj.cloudfront.net/Bez_tytu%C5%82u.png',
    author_image:
      'https://d315baofnr6zvj.cloudfront.net/userimages/profile_img_5.jpeg',
    test: null,
    tags: [
      {
        name: 'na start',
        tags_count: 2,
      },
      {
        name: 'frontend',
        tags_count: 5,
      },
      {
        name: 'backend',
        tags_count: 1,
      },
    ],
    emotions_likes_count: 1,
    emotions_dislikes_count: 0,
    rating_count: 1,
    rating: 5.0,
    rated: false,
    pinned: false,
    comments_count: 1,
    emotion_of_user: [],
  },
];

const PAGE_SIZE = 5;

const Search = () => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(true);

  // PAGINATION
  const [postsPerPage, setPostsPerPage] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [onlyOnePage, setOnlyOnePage] = useState(false);

  const { search } = useParams();
  const [results, setResults] = useState([]);

  const [usersLength, setUsersLength] = useState(PAGE_SIZE);
  const [postsLength, setPostsLength] = useState(PAGE_SIZE);
  const [coursesLength, setCoursesLength] = useState(PAGE_SIZE);
  const [jobsLength, setJobsLength] = useState(PAGE_SIZE);

  const [users, setUsers] = useState([]);
  const [posts, setPosts] = useState([]);
  const [courses, setCourses] = useState([]);
  const [jobs, setJobs] = useState([]);

  const getSearchResults = async (search, cancelToken) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.request({
        method: 'get',
        url: `${miscEndpoints.search}?query=${search}&page=${currentPage}&page_size=${postsPerPage}`,
        cancelToken: cancelToken.token,
      });
      setUsers(prev => [...prev, ...response?.data?.data?.users]);
      setPosts(prev => [...prev, ...response?.data?.data?.posts]);
      setCourses(prev => [...prev, ...response?.data?.data?.courses]);
      setJobs(prev => [...prev, ...response?.data?.data?.jobs]);

      // PAGINATION
      setOnlyOnePage(response.data.pages == 1);
      setLastPage(response.data.pages);

      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('search canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getSearchResults(search, cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [search, currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setUsers([]);
    setPosts([]);
    setCourses([]);
    setJobs([]);
  }, [search]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Card
            className={styles.heading}
            title={`Wyniki wyszukiwania dla "${search}"`}
            classTitle='title-red'
            oneLine={true}
          />
          <Card
            className={styles.category}
            title='Użytkownicy'
            classTitle='title-yellow'
          >
            {users.length > 0
              ? users?.map(
                (item, index) =>
                  index < usersLength && <MiniUser user={item} key={uuid()} />
              )
              : !isLoading && (
                <p className={styles.info}>
                  Nie znaleźliśmy nic dla tej kategorii.
                </p>
              )}
            {isLoading && <UserLoader cards={postsPerPage} />}
            {users.length >= usersLength && (
              <button
                className={cn('button-stroke', styles.next_page_btn)}
                onClick={() => {
                  if (
                    currentPage < lastPage &&
                    usersLength == currentPage * PAGE_SIZE
                  ) {
                    setCurrentPage(prev => prev + 1);
                    setUsersLength(prev => prev + postsPerPage);
                  } else {
                    setUsersLength(prev => prev + postsPerPage);
                  }
                }}
              >
                Pokaż więcej
              </button>
            )}
          </Card>

          <Card
            className={styles.category}
            title='Posty'
            classTitle='title-green'
          >
            {posts.length > 0
              ? posts?.map(
                (item, index) =>
                  index < postsLength && (
                    <MiniPost key={uuid()} article={item} />
                  )
              )
              : !isLoading && (
                <p className={styles.info}>
                  Nie znaleźliśmy nic dla tej kategorii.
                </p>
              )}
            {isLoading && <Loader cards={4} />}
            {posts.length >= postsLength && (
              <button
                className={cn('button-stroke', styles.next_page_btn)}
                onClick={() => {
                  if (
                    currentPage < lastPage &&
                    postsLength == currentPage * PAGE_SIZE
                  ) {
                    setCurrentPage(prev => prev + 1);
                    setPostsLength(prev => prev + postsPerPage);
                  } else {
                    setPostsLength(prev => prev + postsPerPage);
                  }
                }}
              >
                Pokaż więcej
              </button>
            )}
          </Card>

          <Card
            className={styles.category}
            title='Kursy'
            classTitle='title-purple'
          >
            {courses.length > 0
              ? courses?.map(
                (item, index) =>
                  index < coursesLength && (
                    <MiniCourse key={uuid()} article={item} />
                  )
              )
              : !isLoading && (
                <p className={styles.info}>
                  Nie znaleźliśmy nic dla tej kategorii.
                </p>
              )}
            {isLoading && <Loader cards={4} />}
            {courses.length >= coursesLength && (
              <button
                className={cn('button-stroke', styles.next_page_btn)}
                onClick={() => {
                  if (
                    currentPage < lastPage &&
                    coursesLength == currentPage * PAGE_SIZE
                  ) {
                    setCurrentPage(prev => prev + 1);
                    setCoursesLength(prev => prev + postsPerPage);
                  } else {
                    setCoursesLength(prev => prev + postsPerPage);
                  }
                }}
              >
                Pokaż więcej
              </button>
            )}
          </Card>

          {/* <Card
            className={styles.category}
            title='Oferty pracy'
            classTitle='title-blue'
          >
            {jobs.length > 0
              ? jobs?.map(
                (item, index) =>
                  index < jobsLength && (
                    <MiniJob key={uuid()} article={item} />
                  )
              )
              : !isLoading && (
                <p className={styles.info}>
                  Nie znaleźliśmy nic dla tej kategorii.
                </p>
              )}
            {isLoading && <JobLoader cards={postsPerPage} />}
            {jobs.length >= jobsLength && (
              <button
                className={cn('button-stroke', styles.next_page_btn)}
                onClick={() => {
                  if (
                    currentPage < lastPage &&
                    jobsLength == currentPage * PAGE_SIZE
                  ) {
                    setCurrentPage(prev => prev + 1);
                    setJobsLength(prev => prev + postsPerPage);
                  } else {
                    setJobsLength(prev => prev + postsPerPage);
                  }
                }}
              >
                Pokaż więcej
              </button>
            )}
          </Card> */}
        </div>
        <div className={styles.col}>
          <LatestCourses className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Search;
