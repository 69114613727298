import React from 'react';
import cn from 'classnames';
import styles from './ModalLogin.module.sass';
import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { hideGuestModal } from 'store/guestModal/actions';
import Image from 'components/Image';
import { useHistory, useLocation } from 'react-router-dom';

const ModalLogin = () => {
  const history = useHistory();
  const location = useLocation();

  const { visible } = useSelector(state => state.guestModal);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideGuestModal());
  };

  const redirectToLogin = () => {
    history.push('/sign-in', { from: location.pathname + location.search });
    handleClose();
  };

  return (
    <Modal visible={visible} onClose={handleClose}>
      <div className={styles.logo}>
        <Image
          src='/images/logo-light.svg'
          srcDark='/images/logo-dark.svg'
          alt='itCNTR'
        />
      </div>
      <h2>Ta funkcja dostępna jest tylko dla zalogowanych użytkowników</h2>
      <div className={styles.btns}>
        <button className={cn('button')} onClick={redirectToLogin}>
          Zaloguj się
        </button>
        <button className={cn('button-stroke')} onClick={handleClose}>
          Kontynuuj jako gość
        </button>
      </div>
    </Modal>
  );
};
export default ModalLogin;
