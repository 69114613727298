import React, { useState, useEffect } from 'react';
import styles from './ChooseCategoryBreadcrumbs.module.sass';
import Card from 'components/Card';
import ChooseCategoryBreadcrumbsItems from './ChooseCategoryBreadcrumbsItems';
import ChosenCategories from './ChosenCategories';
import Breadcrumbs from 'screens/JakZaczacWIt/Breadcrumbs';
import cn from 'classnames';
import Form from 'components/Form';
import Icon from 'components/Icon';

import { earningsEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import useAuth from 'hooks/useAuth';

const ChooseCategoryBreadcrumbs = ({
  title,
  hasSearchBar,
  itemClicked,
  lastSelectedCategory,
  loading,
  selectedCategories,
}) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [searchValue, setSearchValue] = useState('');
  const [searchBarCategories, setSearchBarCategories] = useState([]);
  const [searchBarCategoriesData, setSearchBarCategoriesData] = useState([]);
  const [pathArray, setPathArray] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [chosenCategories, setChosenCategories] = useState([]);

  const getCategories = async () => {
    try {
      const temp = await axiosInstance.get(`${earningsEndpoints.categories}`, {
        headers: {
          method: 'details',
        },
      });
      setCategories({ name: 'Kategorie', children: temp?.data });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setCurrentData(categories);
  }, [categories]);

  useEffect(() => {
    if (currentData?.name) {
      if (!pathArray.includes(currentData.name)) {
        setPathArray(pathArray => [...pathArray, currentData.name]);
      }
    }
  }, [currentData]);

  useEffect(() => {
    setSearchBarCategories([]);
    searchBarData(categories?.children);
  }, [searchValue]);

  useEffect(() => {
    setChosenCategories(selectedCategories);
  }, [selectedCategories]);

  useEffect(() => {
    const uniq = [
      ...new Map(searchBarCategories.map(v => [v.name, v])).values(),
    ];
    setSearchBarCategoriesData(uniq);
  }, [searchBarCategories]);

  const searchBarData = data => {
    data?.forEach(element => {
      if (element.name.toLowerCase().includes(searchValue.toLowerCase())) {
        setSearchBarCategories(searchBarCategories => [
          ...searchBarCategories,
          element,
        ]);
      }
      if (element?.children) {
        searchBarData(element.children);
      }
    });
  };

  const searchForCategory = (data, name) => {
    if (data?.name === name) {
      setCurrentData(data);
    } else {
      data?.children.forEach(element => {
        if (element.name === name) {
          setCurrentData(element);
        } else if (element.children.length > 0) {
          searchForCategory(element, name);
        }
      });
    }
  };

  const navigateHandler = (pathName, item) => {
    searchForCategory(categories, item);
    const newPathArrayLength = pathArray.indexOf(item) + 1;
    const newPathArray = pathArray;
    newPathArray.length = newPathArrayLength;
    setPathArray(newPathArray);
  };

  const onItemClick = item => {
    if (item?.children?.length > 0) {
      setCurrentData(item);
    }
  };

  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck?.some(item => value.name === item.name);
  };

  const onItemAdd = item => {
    if (!checkIfArrayContains(chosenCategories, item)) {
      setChosenCategories(chosenCategories => [...chosenCategories, item]);
      itemClicked(item);
    }
    if (checkIfArrayContains(chosenCategories, item)) {
      console.log(chosenCategories);
      setChosenCategories(current =>
        current.filter(object => object.name !== item.name)
      );
      itemClicked(item);
    }
  };

  const onItemRemove = item => {
    if (checkIfArrayContains(chosenCategories, item)) {
      setChosenCategories(current =>
        current.filter(object => object.name !== item.name)
      );
      itemClicked(item);
    }
  };

  return (
    <>
      <Card className={cn(styles.card)} title={title} classTitle='title-blue'>
        <div>
          {hasSearchBar && (
            <>
              <Form
                className={styles.form}
                classNameIcon={styles.formIcon}
                value={searchValue}
                setValue={setSearchValue}
                placeholder='Wyszukaj kategorie'
                type='text'
                name='search'
                icon='search'
              />
              {searchValue && (
                <div className={styles.blur_overlay}>
                  <div className={styles.info} style={{ marginBottom: '12px' }}>
                    <Icon name='info' size='16' fill='gray' />
                    Wybierz kategorię. Kliknij "+", aby dodać do listy, kliknij
                    nazwę aby przejść dalej.
                  </div>
                  <ChooseCategoryBreadcrumbsItems
                    searchBarActive
                    data={searchBarCategoriesData}
                    selectedCategories={chosenCategories}
                    onItemClick={onItemClick}
                    onItemAdd={onItemAdd}
                    lastSelectedCategory={lastSelectedCategory}
                    loading={loading}
                  />
                </div>
              )}
            </>
          )}
          {!searchValue && (
            <div className={styles.blur_overlay}>
              <Breadcrumbs
                navigate={navigateHandler}
                names={pathArray}
                className={styles.breadcrumbs}
              />
              <div className={styles.info} style={{ marginBottom: '12px' }}>
                <Icon name='info' size='16' fill='gray' />
                Wybierz kategorię. Kliknij "+", aby dodać do listy, kliknij
                nazwę aby przejść dalej.
              </div>
              {currentData?.children?.length > 0 && (
                <ChooseCategoryBreadcrumbsItems
                  data={currentData.children}
                  selectedCategories={chosenCategories}
                  onItemClick={onItemClick}
                  onItemAdd={onItemAdd}
                  lastSelectedCategory={lastSelectedCategory}
                  loading={loading}
                />
              )}
            </div>
          )}
          {chosenCategories?.length > 0 && (
            <>
              <ChosenCategories
                data={chosenCategories}
                onItemClick={onItemRemove}
                loading={loading}
              />
            </>
          )}
        </div>
      </Card>
    </>
  );
};
export default ChooseCategoryBreadcrumbs;
