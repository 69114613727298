import React from 'react';
import styles from './BigFiveAbout.module.sass';
import Card from 'components/Card';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useAuth from 'hooks/useAuth';
import Button from 'components/Button';
import { showLoginModal } from 'utils/showLoginModal';

const BigFiveAbout = () => {
  const authCtx = useAuth();

  return (
    <div className={styles.container}>
      <Card title={'Czy znasz siebie?'} classTitle={'title-blue'}>
        <p className={styles.text}>
          Czy zastanawiałeś/aś się, jakie cechy charakteryzują Twoją osobowość?
          Prezentowany przez nas kwestionariusz IPIP-NEO-PI-R pomoże Ci to
          odkryć! Jest to narzędzie oparte na modelu Big Five, który analizuje
          pięć głównych wymiarów osobowości. Wynik tego kwestionariusza możesz
          traktować jako narzędzie, które pozwoli Ci na lepszej poznanie samego
          siebie oraz ułatwi obranie odpowiedniej drogi kariery.
        </p>
      </Card>

      <Card
        title={'Odkryj swoją osobowość i jej wpływ na dobór specjalizacji'}
        classTitle={'title-yellow'}
      >
        <p className={styles.text}>
          Czy zastanawiałeś/aś się, jakie cechy charakteryzują Twoją osobowość?
          Nasz Test Big Five pomoże Ci to odkryć. To narzędzie oparte na modelu
          Big Five, który analizuje pięć głównych wymiarów osobowości.
        </p>
      </Card>

      <Card title={'Czym jest model Big Five?'} classTitle={'title-purple'}>
        <div className={styles.section}>
          <p className={styles.text}>
            Teoria Wielkiej Piątki (Big Five) jest jednym z najbardziej
            ugruntowanych i szeroko przyjętych modeli w badaniach nad
            osobowością. Została ona rozwinięta w latach 70. i 80. XX wieku
            dzięki pracom takich naukowców jak Lewis Goldberg, Robert McCrae czy
            Paul Costa. Teoria ta zakłada, że ludzka osobowość może być opisana
            za pomocą pięciu podstawowych wymiarów, które zostały
            zidentyfikowane poprzez analizy statystyczne odpowiedzi wielu ludzi
            na różne testy osobowości. Wymiary o których tu mowa prezentują się
            w następujący sposób:
          </p>
          <ul>
            <li className={styles.text}>
              <strong>Neurotyzm</strong> - Jak reagujesz na stres i emocje?
            </li>
            <li className={styles.text}>
              <strong>Ekstrawersja</strong> - Czy jesteś towarzyski czy raczej
              nieśmiały?
            </li>
            <li className={styles.text}>
              <strong>Ugodowość</strong> - Jakie masz podejście do innych ludzi?
            </li>
            <li className={styles.text}>
              <strong>Sumienność</strong> - Jakie jest Twoje podejście do
              obowiązków i celów?
            </li>
            <li className={styles.text}>
              <strong>Otwartość na doświadczenia</strong> - Czy jesteś otwarty
              na nowe doświadczenia i zmiany?
            </li>
          </ul>
          <p className={styles.text}>
            Choć te pięć wymiarów stanowi podstawę modelu, istnieje wiele
            podskal i konkretnych cech, które są uwzględniane w bardziej
            szczegółowych badaniach nad osobowością. Model Wielkiej Piątki jest
            stosowany w wielu kontekstach, od rekrutacji i selekcji pracowników,
            przez terapię i doradztwo, aż po badania kliniczne i
            kryminalistyczne. Współczesne badania nadal rozwijają i
            doprecyzowują ten model, dostarczając coraz bardziej zaawansowanych
            narzędzi do jego stosowania.
          </p>
        </div>
      </Card>

      <Card title={'Jak to działa?'} classTitle={'title-green'}>
        <ol className={styles.orderedList}>
          <li className={styles.text}>
            Rozpocznij badanie osobowości i odpowiedz na zestaw pytań
            dotyczących Twojego zachowania i preferencji.
          </li>
          <li className={styles.text}>
            Po wypełnieniu kwestionariusza otrzymasz wyniki w postaci wykresu i
            opisów poszczególnych wymiarów osobowości.
          </li>
          <li className={styles.text}>
            Dowiesz się, jakie cechy charakteryzują Twoją osobowość i jak można
            je interpretować.
          </li>
        </ol>
      </Card>

      <Card title={'Profesje i kursy'} classTitle={'title-red'}>
        <p className={styles.text}>
          Po wypełnieniu kwestionariusza, wyniki mogą sugerować profesje i
          kursy, które mogą odpowiadać Twojej osobowości. Jednak pamiętaj, że te
          elementy wyniku to tylko sugestie i nie muszą być jedyną ścieżką,
          którą możesz podążać. Każdy człowiek jest unikalny, a wybór zawodu lub
          kursu zależy od wielu czynników.
        </p>
        <br />
        <p className={styles.text}>
          Uzyskane przez Ciebie wyniki pozwolą nam na dopasowanie Twojej
          osobowości do konkretnych profesji i polecenie odpowiednich kursów.
          Jednak pamiętaj, że te elementy wyniku to tylko sugestie i nie muszą
          być jedyną ścieżką, którą możesz podążać. Każdy człowiek jest
          unikalny, a wybór zawodu lub kursu zależy od wielu czynników.
        </p>
      </Card>

      <Card title={'Rozpocznij badanie osobowości'} classTitle={'title-blue'}>
        <div className={styles.section}>
          <p className={styles.text}>
            Gotów, aby odkryć swoją osobowość? Po przeczytaniu instrukcji
            zawartej poniżej przejdź do wykonywania kwestionariusza klikając na
            przycisk poniżej!
          </p>
          {authCtx.isLoggedIn && (
            <Link
              className={classNames('button', styles.goToTestButton)}
              to='/big-five/test'
            >
              Przejdź do kwestionariusza
            </Link>
          )}
          {!authCtx.isLoggedIn && (
            <Button
              classNames={styles.goToTestButton}
              onClick={() => showLoginModal()}
            >
              Rozpocznij test
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default BigFiveAbout;
