import React, { useEffect, useState } from 'react';
import styles from './BigFiveTestResult.module.sass';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { b5TestEndpoints } from 'api/endpoints';
import { useParams } from 'react-router-dom';
import Congrats from './components/BigFiveTestCongrats';
import OverallChart from './components/BigFiveOverallChart';
import SuggestionInfo from './components/BigFiveSuggestionInfo';
import Details from './components/BigFiveDetails';
import Card from 'components/Card';
import RecommendedCourses from './components/BigFiveCourses';
import CareerPaths from './components/BigFiveCareerPaths';
import ShareSettings from './components/BigFiveShareSettings';
import useAuth from 'hooks/useAuth';
import UserInfo from './components/BigFiveUserInfo';
import { useLocation } from 'react-router-dom';
import Loader from './components/Loader';
import { showLoginModal } from 'utils/showLoginModal';
import ScrollToTop from 'components/ScrollToTop';

const BigFiveTestResult = () => {
  const { id } = useParams();
  const authCtx = useAuth();
  const location = useLocation();
  const fromTest = location?.state?.from === '/big-five/test';

  const [isLoading, setIsLoading] = useState(true);
  const [isCalculatingSuggestions, setIsCalculatingSuggestions] =
    useState(false);
  const [hasCalcSuggestions, setHasCalcSuggestions] = useState(false);

  const [test, setTest] = useState(null);

  const getTestResult = async (testId, reload = false) => {
    try {
      !reload && setIsLoading(true);
      const { data } = await devscntrAuth.request({
        method: 'GET',
        url: `${b5TestEndpoints.get}${testId}/`,
      });

      const hasSuggestions = data?.jzwit_objects?.length > 0;
      if (!hasSuggestions && !hasCalcSuggestions)
        requestSuggestionsCalculation(testId);

      setTest(data || null);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const requestSuggestionsCalculation = async testId => {
    try {
      setIsCalculatingSuggestions(true);
      await devscntrAuth.request({
        method: 'POST',
        url: `${b5TestEndpoints.calculateSuggestions}`,
        headers: {
          'TEST-ID': testId,
        },
      });
      getTestResult(testId, true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsCalculatingSuggestions(false);
      setHasCalcSuggestions(true);
    }
  };

  useEffect(() => {
    if (authCtx.isLoggedIn) getTestResult(id);
    else showLoginModal();
  }, [authCtx?.isLoggedIn, id]);

  if (!authCtx.isLoggedIn) return null;
  if (isLoading) return <Loader />;

  if (!test?.result && !isLoading)
    return (
      <Card title={'Wynik'} classTitle={'title-red'} className={styles.empty}>
        <p className={styles.info}>
          Wynik badania osobowości jest niedostępny.
        </p>
      </Card>
    );

  return (
    <>
      <ScrollToTop />
      <div className={styles.container}>
        {+authCtx.userID === +test?.user?.id && fromTest && <Congrats />}
        {+authCtx.userID !== +test?.user?.id && <UserInfo user={test?.user} />}
        <ShareSettings testId={id} data={test} />
        <OverallChart result={test.result} />
        <Details result={test.result} />
        <SuggestionInfo />
        <CareerPaths
          items={test?.jzwit_objects || []}
          isLoading={isCalculatingSuggestions}
        />
        <RecommendedCourses
          courses={test?.related_courses || []}
          isLoading={isCalculatingSuggestions}
        />
      </div>
    </>
  );
};

export default BigFiveTestResult;
