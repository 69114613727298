import React, { useEffect, useState } from "react";
import styles from "./ReportedContent.module.sass";
import cn from "classnames";
import Card from "components/Card";
import { supportEndpoints } from "api/endpoints";
import devscntrAuth from "api/Instance/devscntrAuth";
import uuid from "react-uuid";
import axios from "axios";
import Item from "./Item";
import { useLocation } from "react-router";
import Filter from "./Filter";

const ReportedContent = () => {
  // UTILS---------------------------------------------------------------------UTILS

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const searchUrl = useLocation().search;
  const [urlQuery, setUrlQuery] = useState("");

  const [refresh, setRefresh] = useState(false);
  const [articles, setArticles] = useState([]);

  // UTILS---------------------------------------------------------------------UTILS
  // GET REPORTS---------------------------------------------------------GET REPORTS

  const getReports = async (cancelToken) => {
    setIsLoading(true);
    try {
      const response = await devscntrAuth.request({
        method: "get",
        url: `${supportEndpoints.reportContent.show}${urlQuery}`,
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      setArticles(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("admin reported content canceled");
      } else {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (urlQuery.length > 0) getReports(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [refresh, urlQuery]);

  useEffect(() => {
    setUrlQuery(searchUrl);
  }, [searchUrl]);

  // GET REPORTS---------------------------------------------------------GET REPORTS
  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title="Zgłoszone treści"
      classTitle={cn("title-yellow", styles.title)}
      head={<Filter />}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          {articles?.map((item) => (
            <Item key={uuid()} article={item} refresh={() => setRefresh((prev) => !prev)} />
          ))}
        </div>
      </div>
    </Card>
  );
};
export default ReportedContent;
