import React, { useEffect, useState } from 'react';
import styles from './Events.module.sass';
import cn from 'classnames';
import Item from '../Item';
import Icon from 'components/Icon';
import Editor from 'components/Editor';
import TextInput from 'components/TextInput';
import PhoneNumberInput from 'components/PhoneNumberInput';
import Dropdown from 'components/Dropdown';
import { companySize } from 'data/dataArrays';
import ThumbnailEditor from 'components/ThumbnailEditor';
import Loader from 'components/Loader';
import devscntrAuth from '../../../api/Instance/devscntrAuth';
import axios from 'axios';
import { levelsEndpoints } from '../../../api/endpoints';
import uuid from 'react-uuid';

// API URLs
const GET_ACCOUNT_XP_HISTORY = levelsEndpoints.userLevelHistory;

const types = {
  addpfp: 'Aktualizacja zdjęcia profilowego użytkownika',
  addbgimg: 'Aktualizacja tła użytkownika',
  followuser: 'Follow użytkownika',
  choosetags: 'Wybranie preferowanych tagów',
  viewpost: 'Wyświetlenie wpisu',
  addpost: 'Dodanie wpisu',
  emotionpost: 'Reakcja na wpis',
  ratingpost: 'Ocenienie wpisu',
  commentpost: 'Dodanie komentarza pod wpisem',
  replycommentpost: 'Odpowiedź na komentarz pod wpisem',
  emotioncommentpost: 'Reakcja na komentarz pod wpisem',
  pinpost: 'Przypięcie wpisu',
  viewcourse: 'Wyświetlenie kursu',
  addcourse: 'Dodanie kursu',
  emotioncourse: 'Reakcja na kurs',
  ratingcourse: 'Ocenienie kursu',
  commentcourse: 'Dodanie komentarza pod kursem',
  replycommentcourse: 'Odpowiedź na komentarz pod kursem',
  emotioncommentcourse: 'Reakcja na komentarz pod kursem',
  pincourse: 'Przypięcie kursu',
  viewjoboffer: 'Wyświetlenie oferty pracy',
  applicatejoboffer: 'Aplikacja do oferty pracy',
  addjoboffer: 'Dodanie oferty pracy',
  reportcontent: 'Zgłoszenie treści',
  reportbug: 'Zgłoszenie błędu',
  QUIZ: 'Quiz',
};

const Events = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [levelPage, setLevelPage] = useState(0);
  const [levelData, setLevelData] = useState([]);

  const getXpHistory = async cancelToken => {
    setIsLoading(true);
    try {
      const page = `?limit=10&offset=${levelPage * 10}`;

      // Request level history
      const response = await devscntrAuth.request({
        method: 'GET',
        url: GET_ACCOUNT_XP_HISTORY + page,
        cancelToken: cancelToken.token,
      });
      console.log('LEVEL HISTORY');

      if (response.data.next === null) setLevelPage(-1);

      const lvlData = response.data.results.map((item, index) => {
        const date = new Date(item.date);

        let text = `${date.toLocaleDateString()} - ${types[`${item.type}`]}`;
        console.log(response.data.results);
        return (
          <li key={uuid()}>
            {text} <span>+{item.xp} XP</span>
          </li>
        );
      });
      response.data && setLevelData(prevState => [...prevState, ...lvlData]);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('level history canceled');
      } else {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    console.log(levelPage);
    if (levelPage >= 0) {
      getXpHistory(cancelToken);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [levelPage]);

  return (
    <Item
      className={cn(styles.card)}
      title='Historia zdarzeń'
      classTitle='title-red'
    >
      <ul className={styles.events}>
        {levelData.length > 0 ? (
          <>
            {levelData}
            {levelPage !== -1 && (
              <button
                type='button'
                className={cn('button-stroke', styles.btn)}
                onClick={() => setLevelPage(prevState => ++prevState)}
              >
                {isLoading ? (
                  <>
                    <span>Ładowanie</span> <Loader className={styles.loader} />
                  </>
                ) : (
                  'Kolejna strona'
                )}
              </button>
            )}
          </>
        ) : isLoading ? (
          <Loader />
        ) : (
          <p>Historia zdarzeń jest pusta</p>
        )}
      </ul>
    </Item>
  );
};

export default Events;
