import Card from 'components/Card';
import React, { useMemo } from 'react';
import styles from './BigFiveCareerPaths.module.sass';
import Breadcrumb from 'components/Breadcrumb';
import Overlay from 'components/Overlay';

const getJzwitUrlFromSlug = slug => {
  return slug.split('_').slice(1).join('/');
};

const structureBreadcrumbItem = item => {
  return {
    name: item.name,
    to: `/jak-zaczac-w-it/${getJzwitUrlFromSlug(item.slug)}`,
    icon: item?.icon || null,
  };
};

const BigFiveCareerPaths = ({ items, isLoading }) => {
  const breadcrumbsList = useMemo(() => {
    const list = [];

    items.forEach(item => {
      const breadcrumb = [];

      let current = item;
      breadcrumb.push(structureBreadcrumbItem(current));

      while (current?.parent) {
        current = current.parent;
        if (current.slug === 'jak-zaczac-w-it') continue;

        breadcrumb.push(structureBreadcrumbItem(current));
      }

      breadcrumb.reverse();
      if (breadcrumb.length > 0) list.push(breadcrumb);
    });

    return list;
  }, [items]);

  return (
    <Overlay isLoading={isLoading}>
      <Card
        title={'Proponowane ścieżki rozwoju'}
        classTitle={'title-blue'}
        className={styles.card}
      >
        {breadcrumbsList.length === 0 && !isLoading && (
          <div className={styles.empty}>
            <p className={styles.info}>Brak proponowanych ścieżek rozwoju</p>
          </div>
        )}

        {breadcrumbsList.length > 0 && !isLoading && (
          <div className={styles.list}>
            {breadcrumbsList.map((breadcrumb, index) => (
              <Breadcrumb key={index} items={breadcrumb} />
            ))}
          </div>
        )}
      </Card>
    </Overlay>
  );
};

export default BigFiveCareerPaths;
