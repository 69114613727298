import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ContextProviders from 'App/ContextProviders';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

if (process.env.NODE_ENV === 'production') {
  // SENTRY
  Sentry.init({
    dsn: 'https://c7fba4faf6bf4b0fab96e7ac5e141358@o4504278218244096.ingest.sentry.io/4504278222372864',
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <ContextProviders>
      <HelmetProvider context={helmetContext}>
        <App />
      </HelmetProvider>
    </ContextProviders>
  </React.StrictMode>,
  document.getElementById('root')
);
