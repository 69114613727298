import React from 'react';
import { Link } from 'react-router-dom';
import styles from './RelatedPosts.module.sass';
import cn from 'classnames';
import MiniCourse from 'components/MiniCourse';
import MiniPost from 'components/MiniPost';
import Card from 'components/Card';
import uuid from 'react-uuid';
import { courseSortingOptions } from 'data/dataArrays';
import HorizontalScroll from 'components/HorizontalScroll';

const RelatedPosts = ({ items, className }) => {
  return (
    <Card
      className={cn(styles.card, className)}
      title='Powiązane posty'
      classTitle='title-purple'
      head={
        <Link
          to={`/posts`}
          className={cn('button', styles.button)}
        >
          Więcej
        </Link>
      }
    >
      <div className={styles.container}>
        {items.length > 0 ? (
          <HorizontalScroll className={styles.list} controls wide>
            {items.map(item => (
              <MiniPost
                classNames={styles.item}
                related
                article={item}
                key={uuid()}
              />
            ))}
          </HorizontalScroll>
        ) : (
          <p className={styles.info}>Brak powiązanych postów</p>
        )}
      </div>
    </Card>
  );
};

export default RelatedPosts;
