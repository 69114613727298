const data = {
  plus: [
    {
      text: 'Zdecydowanie nie',
      score: 1,
      color: 1
    },
    {
      text: 'Raczej nie',
      score: 2,
      color: 2
    },
    {
      text: 'Ani tak, ani nie',
      score: 3,
      color: 3
    },
    {
      text: 'Raczej tak',
      score: 4,
      color: 4
    },
    {
      text: 'Zdecydowanie tak',
      score: 5,
      color: 5
    }
  ],
  minus: [
    {
      text: 'Zdecydowanie nie',
      score: 5,
      color: 1
    },
    {
      text: 'Raczej nie',
      score: 4,
      color: 2
    },
    {
      text: 'Ani tak, ani nie',
      score: 3,
      color: 3
    },
    {
      text: 'Raczej tak',
      score: 2,
      color: 4
    },
    {
      text: 'Zdecydowanie tak',
      score: 1,
      color: 5
    }
  ]
};

export default data;