import React, { useState } from "react";
import cn from "classnames";
import styles from "./ModalReport.module.sass";
import Modal from "components/Modal";
import Dropdown from "components/Dropdown";
import { reportDescriptionMaxChars, reportTitles } from "data/dataArrays";

const MAX_DESCRIPTION_CHARS = reportDescriptionMaxChars;

const ModalReport = ({ visible, onClose, onSubmit }) => {
  const [title, setTitle] = useState("Podaj powód zgłoszenia");
  const [description, setDescription] = useState("");

  const [isPosted, setIsPosted] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const validation = () => {
    // TITLE
    if (!reportTitles.includes(title)) {
      setTitleError(true);
      console.log("title error");
      return false;
    }

    const allowedCharacters = /^[a-zA-Z0-9ĄąĆćĘęŁłŃńÓóŚśŹźŻż \p{L}\n"':!@#$%\^&*\(\)+,.?\-]+$/;

    if (description.length > 0) {
      if (!allowedCharacters.test(description)) {
        return false;
      }
    }

    // DESCRIPTION
    if (description.length > MAX_DESCRIPTION_CHARS) {
      return false;
    }

    return true;
  };

  const onTitleChange = (value) => {
    setTitleError(false);
    setTitle(value);
  };

  const onDescriptionChange = (e) => {
    const allowedCharacters = /^[a-zA-Z0-9ĄąĆćĘęŁłŃńÓóŚśŹźŻż \p{L}\n"':!@#$%\^&*\(\)+,.?\-]+$/;

    if (e.target.value.length > 0) {
      if (!allowedCharacters.test(e.target.value[e.target.value.length - 1])) {
        return;
      }
    }
    setDescription(e.target.value);
  };

  const handleSubmit = () => {
    if (validation()) {
      setIsPosted(true);
      onSubmit(title, description);
      setTimeout(() => {
        onClose();
      }, 4000);
    }
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className={styles.container}>
        {isPosted ? (
          <>
            <div className={cn("title-green", styles.title)}>Dziękujemy za zgłoszenie</div>
            <div className={styles.description}>Dzięki Tobie jesteśmy w stanie tworzyć przyjazne środowisko.</div>
          </>
        ) : (
          <>
            <div className={cn("title-red", styles.title)}>Zgłoś</div>
            <div className={styles.fields}>
              <div className={styles.field}>
                <Dropdown
                  classDropdownHead={titleError && styles.input_error}
                  value={title}
                  setValue={(value) => onTitleChange(value)}
                  options={reportTitles}
                />
              </div>
              <div className={styles.field}>
                <textarea
                  value={description}
                  onChange={(e) => onDescriptionChange(e)}
                  className={cn(styles.textarea, description.length > MAX_DESCRIPTION_CHARS && styles.error)}
                  placeholder="Opisz go w kilku słowach..."
                  required
                />
                <div className={cn(styles.counter, description.length > MAX_DESCRIPTION_CHARS && styles.error)}>
                  {description.length}/{MAX_DESCRIPTION_CHARS}
                </div>
              </div>
              <button className="button" onClick={handleSubmit}>
                Wyślij
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
export default ModalReport;
