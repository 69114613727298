import cn from "classnames";
import styles from "../List.module.sass";
import Actions from "../../../../components/Actions";
import Item from "./Item";
import Loader from "../../../../components/Loader";
import Card from "../../../../components/Card";
import {useEffect, useState} from "react";
import axios from "axios";
import devscntrAuth from "../../../../api/Instance/devscntrAuth";
import Icon from "../../../../components/Icon";
import SkeletonLoader from "../SkeletonLoader";

const CommentsNotifications = ({ className, notifications, loading, error, filters }) => {
    const [showMoreComPosts, setShowMoreComPosts] = useState(false);
    const [showMoreComCourses, setShowMoreComCourses] = useState(false);
    const [isAllPostRead, setIsAllPostRead] = useState(false);
    const [isAllCourseRead, setIsAllCourseRead] = useState(false);

    const isNotifsFetched = !loading && !error && notifications

    const  allReadPostHandler = async () => {
        try {
            const response = await devscntrAuth.get(
                "/accounts/me/notification-delete/?category=comment_post"
            );
            setIsAllPostRead(true)

        } catch (error) {
            console.log(error);
        }
    };
    const  allReadCourseHandler = async () => {
        try {
            const response = await devscntrAuth.get(
                "/accounts/me/notification-delete/?category=comment_course"
            );
            setIsAllCourseRead(true)

        } catch (error) {
            console.log(error);
        }
    };

    const postNotificationsElements = isNotifsFetched && notifications.newCommentsPosts.length > 0
        ? notifications.newCommentsPosts.map((x, index) => {
            if(index <= 3 || showMoreComPosts){
                return <Item
                    className={cn(styles.item, className)}
                    item={x}
                    key={index}
                    isAllRead={isAllPostRead}
                />
            }
        })
        : error ? "Wystąpił błąd" : "Brak powiadomień";

    const courseNotificationsElements = isNotifsFetched && notifications.newCommentsCourses.length > 0
        ? notifications.newCommentsCourses.map((x, index) => {
            if(index <= 3 || showMoreComCourses){
                return <Item
                    className={cn(styles.item, className)}
                    item={x}
                    type={"commentCourse"}
                    key={index}
                    isAllRead={isAllCourseRead}
                />
            }
        })
        : error ? "Wystąpił błąd" : "Brak powiadomień";


return(
    <>
        {(filters.includes("Komentarze do postów") || filters.length === 0) && <Card
            className={cn(styles.card, className)}
            title="Komentarze do postów"
            classTitle={cn("title-blue", styles.title)}
            classCardHead={styles.head}
        >
            <button
                className={cn("button-stroke", styles.setreadbtn)}
                onClick={allReadPostHandler}
            >
                <Icon name="check-all" size="24" />
                <p>Oznacz jako przeczytane</p>
            </button>
            <div className={styles.notifications}>
                <div className={styles.list}>
                    {loading ? <SkeletonLoader cards={2} /> : postNotificationsElements}
                </div>
                {isNotifsFetched && notifications.newCommentsPosts.length > 4 &&
                    <div className={styles.foot}>
                        <button
                            className={cn("button-stroke button-small", styles.button)}
                            onClick={()=>setShowMoreComPosts(true)}
                        >
                            {loading && <Loader className={styles.loader}/>}
                            <span>Load more</span>
                        </button>
                    </div>}
            </div>
        </Card>}
        {(filters.includes("Komentarze do kursów") || filters.length === 0) && <Card
            className={cn(styles.card, className)}
            title="Komentarze do kursów"
            classTitle={cn("title-green", styles.title)}
            classCardHead={styles.head}
        >
            <button
                className={cn("button-stroke", styles.setreadbtn)}
                onClick={allReadCourseHandler}
            >
                <Icon name="check-all" size="24" />
                <p>Oznacz jako przeczytane</p>
            </button>
            <div className={styles.notifications}>
                <div className={styles.list}>
                    {loading ? <SkeletonLoader cards={2} /> : courseNotificationsElements}
                </div>
                {isNotifsFetched && notifications.newCommentsCourses.length > 3 && <div className={styles.foot}>
                    <button
                        className={cn("button-stroke button-small", styles.button)}
                        onClick={()=>setShowMoreComCourses(true)}
                    >
                        {loading && <Loader className={styles.loader}/>}
                        <span>Load more</span>
                    </button>
                </div>}
            </div>
        </Card>}
    </>
)}

export default CommentsNotifications