import React, { useState, useEffect } from 'react';
import styles from './PreferedTagsSettings.module.sass';
import cn from 'classnames';
import useAuth from 'hooks/useAuth';
import Search from 'components/Search';
import Checkbox from 'components/Checkbox';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { accountsEndpoints, tagsEndpoints } from 'api/endpoints';
import axios from 'axios';
import Icon from 'components/Icon';
import Loader from './Loader';
import { showError } from 'utils/showError';
import { colorRelatedToBackground } from 'utils';
import Tag from 'components/Tags/Tag';

const PreferedTagsSettings = ({ className, allTags, myTags, onChange }) => {
  // UTILS--------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState('');
  const [preparedTags, setPreparedTags] = useState(tags || []);
  const [showSelected, setShowSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  // UTILS--------------------------------------------------------------------------UTILS
  // GET ALL TAGS------------------------------------------------------------GET ALL TAGS

  const getTags = async cancelToken => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: `${tagsEndpoints.showAll}?limit=1000`,
        cancelToken: cancelToken.token,
      });
      setTags(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('all tags canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (allTags) {
      setTags(allTags);
    } else {
      getTags(cancelToken);
    }
    console.log('useeffect tagi');

    return () => {
      cancelToken.cancel();
    };
  }, [allTags]);

  // GET ALL TAGS------------------------------------------------------------GET ALL TAGS
  // GET MY TAGS--------------------------------------------------------------GET MY TAGS

  const getMyTags = async cancelToken => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: `${accountsEndpoints.userData.tagPreferences}`,
        cancelToken: cancelToken.token,
      });
      setSelectedItems(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('my tags canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (myTags) {
      setSelectedItems(myTags);
    } else {
      getMyTags(cancelToken);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [myTags]);

  // GET MY TAGS--------------------------------------------------------------GET MY TAGS
  // UPDATE MY TAGS--------------------------------------------------------UPDATE MY TAGS

  // Add tags
  const addMyTag = async (id, index) => {
    try {
      const response = await devscntrAuth.post(
        accountsEndpoints.userData.tagPreferences,
        {
          tags: `${id}`,
        }
      );
      setSelectedItems(prev => [...prev, tags[index]]);
      onChange && onChange();
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
      showError(
        'Nie udało się zaktualizować preferowanych tagów. Spróbuj ponownie później'
      );
    }
  };

  const deleteMyTag = async (id, index) => {
    try {
      const response = await devscntrAuth.delete(
        `${accountsEndpoints.userData.tagPreferences}${id}`
      );
      // const response = await devscntrAuth.request({
      //   method: "get",
      //   url: accountsEndpoints.userData.tagPreferences,
      //   params: {
      //     tags: `${id}`,
      //   },
      //   headers: {
      //     method: "delete",
      //   },
      // });
      setSelectedItems(prev => prev.filter(item => item.id !== tags[index].id));
      onChange && onChange();
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
      showError(
        'Nie udało się zaktualizować preferowanych tagów. Spróbuj ponownie później'
      );
    }
  };

  // UPDATE MY TAGS--------------------------------------------------------UPDATE MY TAGS
  // TOGGLE TAG----------------------------------------------------------------TOGGLE TAG

  const toggleTag = id => {
    const index = tags.map(item => item.id).indexOf(id);
    if (selectedItems.map(item => item.id)?.includes(tags[index].id)) {
      //setSelectedItems((prev) => prev.filter((item) => item.id !== tags[index].id));
      deleteMyTag(id, index);
    } else {
      //setSelectedItems((prev) => [...prev, tags[index]]);
      addMyTag(id, index);
    }
  };

  // TOGGLE TAG----------------------------------------------------------------TOGGLE TAG
  // SEARCH TAGS--------------------------------------------------------------SEARCH TAGS

  useEffect(() => {
    if (tags.length > 0) {
      let filteredTags = tags;
      if (search !== '') {
        filteredTags = filteredTags?.filter(item =>
          item.name.toLowerCase().includes(search.toLowerCase())
        );
      }
      setPreparedTags(filteredTags);
    }
  }, [tags, search]);

  // SEARCH TAGS--------------------------------------------------------------SEARCH TAGS

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <Icon name='setting' size={20} />
        </div>
        <h2 className={styles.title}>Preferowane tagi</h2>
      </div>
      <div className={styles.content}>
        <Search
          className={styles.search}
          value={search}
          setValue={setSearch}
          name='tagSearch'
          icon='search'
          placeholder='Szukaj...'
        />

        <Checkbox
          className={styles.checkbox}
          value={showSelected}
          onChange={() => setShowSelected(prev => !prev)}
          content='Pokaż tylko zaznaczone'
        />

        {tags?.length < 1 ? (
          <Loader cards={20} />
        ) : (
          <div className={cn(styles.tags, { [className]: className })}>
            {tags?.map((item, index) =>
              showSelected ? (
                selectedItems.map(item => item.id)?.includes(item.id) && (
                  <Tag
                    key={`tag_${index}`}
                    name={item?.name || 'Brak nazwy'}
                    color={item?.colour || '#ffffff'}
                    selected={
                      selectedItems.map(item => item.id)?.includes(item.id) ||
                      false
                    }
                    visible={preparedTags.includes(item) || false}
                    onClick={() => toggleTag(item.id)}
                  />
                )
              ) : (
                <Tag
                  key={`tag_${index}`}
                  name={item?.name || 'Brak nazwy'}
                  color={item?.colour || '#ffffff'}
                  selected={
                    selectedItems.map(item => item.id)?.includes(item.id) ||
                    false
                  }
                  visible={preparedTags.includes(item) || false}
                  onClick={() => toggleTag(item.id)}
                />
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreferedTagsSettings;
