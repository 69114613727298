export default {
  domain: 'C',
  title: 'Sumienność',
  shortDescription: 'Sumienność jest cechą osobowości charakteryzującą się organizacją, odpowiedzialnością, niezawodnością i dążeniem do osiągnięcia sukcesu.',
  description: 'Sumienność to cecha osobowości, która odnosi się do stopnia, w jakim jesteśmy zorganizowani, odpowiedzialni i skrupulatni. Ta cecha mówi o naszej tendencji do przestrzegania zasad, realizacji zadań i planowania przyszłości. Osoby o wysokiej sumienności zazwyczaj są niezawodne, dokładne i zdeterminowane. Z drugiej strony, osoby o niskiej sumienności mogą być bardziej spontaniczne, elastyczne i kreatywne.',
  results: [
    {
      score: 'low', // do not translate this line
      text: ' Twój wynik na skali Sumienności jest niski, co wskazuje na to, że lubisz żyć chwilą i robić to co jest teraz dobre. Twoja praca, działania są zorganizowane w niewielkim stopniu.'
    },
    {
      score: 'neutral', // do not translate this line
      text: 'Twój wynik w skali sumienności jest średni. Oznacza to, że jesteś osobą w miarę rzetelną, zorganizowaną i opanowaną.'
    },
    {
      score: 'high', // do not translate this line
      text: 'Twój wynik w skali sumienności jest wysoki. Oznacza to, że wyznaczasz sobie jasne cele i dążysz do nich z dużą determinacją i planujesz kroki kolejnych działań.'
    }
  ],
  facets: [
    {
      facet: 1,
      title: 'Pewność siebie',
      text: 'Pewność siebie opisuje przekonanie o swojej zdolności do realizacji zadań. Osoby z wysokim wynikiem wierzą, że mają inteligencję (zdrowy rozsądek), motywację i samokontrolę niezbędne do osiągnięcia sukcesu. Osoby z niskim wynikiem nie czują się skuteczne i mogą mieć poczucie, że nie mają kontroli nad swoim życiem.'
    },
    {
      facet: 2,
      title: 'Porządek',
      text: 'Osoby z wysokim wynikiem w zakresie porządku są dobrze zorganizowane. Lubią żyć zgodnie z rutyną i harmonogramami. Spisują listy i robią plany. Osoby z niskim wynikiem zazwyczaj są niezorganizowane i chaotyczne.'
    },
    {
      facet: 3,
      title: 'Poczucie obowiązku',
      text: 'Ta skala odzwierciedla siłę poczucia obowiązku i zobowiązania u osoby. Ci, którzy osiągają wysokie wyniki na tej skali, mają silne poczucie moralnego obowiązku. Osoby z niskim wynikiem uważają umowy, zasady i regulacje za zbyt ograniczające. Mogą być postrzegane jako niesolidne lub nawet nieodpowiedzialne.'
    },
    {
      facet: 4,
      title: 'Dążenie do osiągnięć',
      text: 'Osoby, które osiągają wysokie wyniki na tej skali, dążą do doskonałości. Ich motywacja do bycia uznawanym za udanego utrzymuje je na drodze do swoich ambitnych celów. Często mają silne poczucie kierunku w życiu, ale bardzo wysokie wyniki mogą być zbyt jednokierunkowe i zobsesjonowane swoją pracą. Osoby z niskim wynikiem zadowalają się minimalną ilością pracy i mogą być postrzegane przez innych jako leniwe.'
    },
    {
      facet: 5,
      title: 'Samodyscyplina',
      text: 'Samodyscyplina, czyli to, co wielu ludzi nazywa siłą woli, odnosi się do zdolności do wytrwałości w trudnych lub nieprzyjemnych zadaniach do ich zakończenia. Osoby posiadające wysoką samodyscyplinę są w stanie pokonać niechęć do rozpoczęcia zadań i utrzymać koncentrację mimo rozproszeń. Osoby z niskim wynikiem na skali z samodyscypliną odkładają na później i wykazują mniejszą konsekwencję, często nie kończąc zadań - nawet tych, które bardzo chcą zakończyć.'
    },
    {
      facet: 6,
      title: 'Ostrożność',
      text: 'Ostrożność opisuje skłonność do przemyślenia możliwości przed podjęciem działania. Osoby z wysokim wynikiem na skali ostrożności dają sobie czas na podjęcie decyzji. Osoby z niskim wynikiem często mówią lub robią to, co przychodzi im do głowy jako pierwsze, bez rozważania alternatyw i prawdopodobnych następstw tych alternatyw.'
    }
  ]
};
