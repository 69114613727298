import React from 'react';
import styles from './Title.module.sass';
import cn from 'classnames';
import Thumbnail from 'components/MiniItemsLayout/Thumbnail';
import Icon from 'components/Icon';
import LocationsList from 'components/LocationsList';

const Title = ({
  className,
  imageClassName,
  title,
  subtitle,
  image,
  imageFit,
  date,
  url,
  urlClicksCount,
  urlClicked,
  locations,
  buttonText,
  onButtonClick,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <Thumbnail
        className={cn(styles.thumbnail, imageClassName && imageClassName)}
        imageFit={imageFit || 'cover'}
        image={image}
      />

      <div className={styles.content}>
        <div className={styles.row}>
          <h1 className={styles.title}>{title || 'Brak tytułu'}</h1>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          {locations?.length > 0 && (
            <LocationsList
              className={styles.locations}
              locations={locations || []}
            />
          )}
        </div>

        <div className={styles.bottom_container}>
          <div className={styles.bottom}>
            {url && (
              <div className={styles.external}>
                <a
                  href={url}
                  target='_blank'
                  className={cn('button', styles.button)}
                  onClick={urlClicked}
                >
                  {buttonText}
                </a>
                <div className={styles.clickcount}>
                  <Icon name='clicks' size='24' viewBox='0 0 24 24' />
                  {urlClicksCount ? urlClicksCount : '-'}
                </div>
              </div>
            )}
            {!url && onButtonClick && (
              <button
                className={cn('button', styles.button)}
                onClick={onButtonClick}
              >
                {buttonText}
              </button>
            )}
          </div>
          
          <div className={styles.publish_date}>{date && date}</div>

        </div>
      </div>
    </div>
  );
};

export default Title;
