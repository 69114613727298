import React, { useState } from 'react';
import styles from './Dashboard.module.sass';
import cn from 'classnames';
import useBreakpoints from 'hooks/useBreakpoints';

const Dashboard = () => {
  const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ['desktop', Infinity],
    ['mobile', 716],
  ];
  useBreakpoints(stylesRef, breakpoints);

  return (
    <div className={styles.container} ref={x => setStylesRef(x)}>
      <div className={styles.content}></div>
    </div>
  );
};

export default Dashboard;
