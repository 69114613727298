import React, { useState, useEffect } from 'react';
import styles from './NewPost.module.sass';
import TooltipGlodal from 'components/TooltipGlodal';
import Panel from 'screens/NewLayouts/Panel';
import cn from 'classnames';
import Card from 'components/Card';
import { useHistory } from 'react-router-dom';
import { validateInput, validateUrl } from 'utils/validation';
import { showError } from 'utils/showError';
import devscntrAuth from 'api/Instance/devscntrAuth';
import TagsInput from 'screens/NewLayouts/TagsInput';
import ImageInput from 'screens/NewLayouts/ImageInput';
import Content from 'screens/NewLayouts/Content';
import Details from './Details';
import useAuth from 'hooks/useAuth';
import ModalSchedule from 'components/ModalSchedule';
import { postsEndpoints } from 'api/endpoints';
import LinksInput from 'screens/NewLayouts/LinksInput';
import Meta from 'components/Meta';
import ChooseCategoryBreadcrumbs from 'components/ChooseCategoryBreadcrumbs';
import SlugInput from 'components/SlugInput';

const EDITOR_VERSION = 2;

const NewPost = () => {
  const authCtx = useAuth();

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleSchedule, setVisibleSchedule] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [isLoading, setIsLoading] = useState(false);
  const [isPostCreated, setIsPostCreated] = useState(false);

  const [files, setFiles] = useState(null);
  const [status, setStatus] = useState(0);

  const [editorState, setEditorState] = useState('');
  const [titleState, setTitleState] = useState('');
  const [reflink, setReflink] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');

  const [tags, setTags] = useState([]);
  const [links, setLinks] = useState([]);
  const [categories, setCategories] = useState([])

  const [slug, setSlug] = useState('')

  const location = useHistory();

  const handleEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  const handleTitleChange = e => {
    const val = e.target.value;
    setTitleState(val);
  };

  const handleMetaDescriptionChange = event => {
    const val = event.target.value;
    setMetaDescription(val);
  };

  const handleMetaTitleChange = event => {
    const val = event.target.value;
    setMetaTitle(val);
  };

  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck?.some(item => value.name === item.name)
  }

  const categoryFunction = (item) => {
    if (!checkIfArrayContains(categories, item)) {
      setCategories(categories => [...categories, item])
    }
    if (checkIfArrayContains(categories, item)) {
      setCategories((current) => current.filter((object) => object.name !== item.name));
    }
  }

  // PREPARE DATA-----------------------------------------------------------PREPARE DATA
  const prepareFormData = () => {
    const formData = new FormData();

    // BASIC CONTENT
    formData.append('title', titleState.trim());
    formData.append('description', editorState);

    // EDITOR VERSION
    formData.append('editor_version', EDITOR_VERSION);

    // EXTERNAL URL
    if (reflink !== '') {
      formData.append('is_external', true);
      formData.append('external_url', reflink.trim());
    }

    formData.append('status', status);

    // TAGS
    if (tags.length > 0) {
      tags.forEach((item, index) => {
        formData.append(`tags[${index}]name`, item.name);
        formData.append(`tags[${index}]colour`, item.colour);
      });
    }

    // CATEGORIES
    if (categories.length > 0) {
      categories.forEach((item, index) => {
        formData.append(`categories[${index}]name`, item.name);
        formData.append(`categories[${index}]id`, item.id);
      });
    }

    // LINKS
    if (links.length > 0) {
      links.forEach((item, index) => {
        formData.append(`additional_url[${index}]description`, item.title);
        formData.append(`additional_url[${index}]url`, item.url);
      });
    }

    if (status === 1) {
      formData.append('scheduled_at', startDate.toISOString());
    }

    // IMAGE
    if (files) {
      formData.append('image', files);
    }

    //SLUG
    formData.append('slug', slug)

    //META
    formData.append('meta_title', metaTitle.trim());
    formData.append('meta_description', metaDescription);

    console.log({ formData });
    return formData;
  };

  // PREPARE DATA-----------------------------------------------------------PREPARE DATA

  const validation = () => {
    // TITLE
    if (!validateInput(titleState.trim(), 100, 10, 'Tytuł')) return false;

    // DESCRIPTION
    if (editorState.trim(' ').length < 1) {
      showError('Opis nie może być pusty');
      return false;
    }

    if (reflink !== '') {
      if (!validateUrl(reflink.trim())) return false;
    }

    return true;
  };

  const requestCreatePost = async data => {
    setIsLoading(true);
    try {
      const response = await devscntrAuth.post(postsEndpoints.create, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          method: 'create',
        },
      });
      console.log(response);
      setIsPostCreated(true);
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const clearAllFields = () => {
    setIsPostCreated(false);
    setIsLoading(false);
    setStartDate(new Date());
    setStartTime(new Date())
    setCategories([])
    setReflink('');
    setTitleState('');
    setEditorState('');
    setMetaTitle('');
    setMetaDescription('');
    setFiles(null);
    setSlug('')
    setTags([]);
    setLinks([]);
  }

  useEffect(() => {
    if (status === 2) {
      if (validateInput(titleState.trim(), 100, 10, 'Tytuł')) {
        const formData = prepareFormData();
        requestCreatePost(formData);
      } else {
        setStatus(0)
      }
    }
    if (status === 1) {
      if (validation()) {
        const formData = prepareFormData();
        requestCreatePost(formData);
      }
      else {
        setStatus(0)
      }
    }
  }, [status]);

  const scheduleHandler = () => {
    setVisibleSchedule(false)
    setStatus(1)
  }

  const handleSlugChange = event => {
    const val = event.target.value;
    setSlug(val);
  };

  const handleSubmit = () => {
    if (!validation()) return;

    const formData = prepareFormData();
    requestCreatePost(formData);
  };

  const saveDraft = () => {
    setStatus(2);
  };

  return (
    <>
      <div className={styles.row}>
        {!isPostCreated ? (
          <>
            <div className={styles.col}>
              <Content
                className={styles.card}
                onTitleChange={handleTitleChange}
                onDescriptionChange={handleEditorStateChange}
                title={titleState}
                description={editorState}
                editorVersion={EDITOR_VERSION}
              />
              <ChooseCategoryBreadcrumbs
                title='Kategorie'
                itemClicked={categoryFunction}
                loading={false}
                selectedCategories={categories}
              />
              <LinksInput
                links={links}
                setLinks={setLinks}
                className={styles.card_links}
              />
              <Details
                className={styles.card}
                reflink={reflink}
                setReflink={setReflink}
              />
              <ImageInput
                className={styles.card}
                title={'Miniaturka postu'}
                setFile={setFiles}
                fileName={`post_img_u${authCtx.userID}`}
              />
              <TagsInput className={styles.container} tags={tags} setTags={setTags} />
              <SlugInput className={styles.container} slug={slug} onSlugChange={handleSlugChange} />
              <Meta
                title={metaTitle}
                description={metaDescription}
                onTitleChange={handleMetaTitleChange}
                onDescriptionChange={handleMetaDescriptionChange}
              />
              {/*To Do*/}
              {/* <CategoryAndAttibutes
                className={styles.card}
                tagsState={tags}
                setTagsState={setTags}
              /> */}
            </div>
          </>
        ) : (
          <Card className={styles.postcreated}>
            <p>Post został utworzony!</p>
            <div className={styles.panel}>
              <button
                className={cn('button', styles.homebtn)}
                onClick={() => location.push('/posts')}
              >
                Zobacz posty
              </button>
              <button
                className={cn('button-stroke', styles.newbtn)}
                onClick={() => clearAllFields()}
              >
                Utwórz kolejny post
              </button>
            </div>
          </Card>
        )}
      </div>
      {!isPostCreated && (
        <>
          <Panel
            setVisiblePreview={setVisiblePreview}
            setVisibleSchedule={setVisibleSchedule}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            clearInputs={clearAllFields}
            saveDraft={saveDraft}
          />
          <TooltipGlodal />
          <ModalSchedule
            visible={visibleSchedule}
            setVisible={setVisibleSchedule}
            startDate={startDate}
            setStartDate={setStartDate}
            startTime={startTime}
            setStartTime={setStartTime}
            onSubmit={scheduleHandler}
          />
        </>
      )}
    </>
  );
};

export default NewPost;
