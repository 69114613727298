import React from 'react';
import { Link } from 'react-router-dom';
import styles from './RelatedJobs.module.sass';
import cn from 'classnames';
import MiniJobOffer from 'components/MiniJobOffer';
import Card from 'components/Card';
import uuid from 'react-uuid';
import { jobSortingOptions } from 'data/dataArrays';
import HorizontalScroll from 'components/HorizontalScroll';
import MiniJob from 'components/MiniJob';

const RelatedJobs = ({ items, className }) => {
  return (
    <Card
    className={cn(styles.card, className)}
      title='Powiązane oferty pracy'
      classTitle='title-purple'
    >
      <div className={styles.container}>
        {items.length > 0 ? (
          <HorizontalScroll className={styles.list} controls wide>
            {items.map((item, i) => (
              <div className={styles.related_job} key={uuid()}>
                <MiniJob className={styles.item} recommended article={item} />
              </div>
            ))}
          </HorizontalScroll>
        ) : (
          <p className={styles.info}>Brak powiązanych ofert pracy</p>
        )}
      </div>
    </Card>
  );
};

export default RelatedJobs;
