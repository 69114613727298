import React from 'react';
import styles from './AverageEarnings.module.sass';
import cn from 'classnames';
import Card from 'components/Card';
import EarningsBar from './EarningsBar';

const AverageEarnings = ({ data, className }) => {
  function averageSalaryFormat(salary) {
    return salary?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  const seniority = ['junior', 'mid', 'senior'];

  return (
    <Card
      className={cn(className ? className : styles.card)}
      title='Średnie zarobki'
      classTitle='title-blue'
    >
      <>
        {data.map((item, index) => (
          <div key={index}>
            {index > 0 && (
              <>
                <div className={styles.separator}></div>
                <div className={styles.separator_text}>VS</div>
                <div className={styles.separator}></div>
              </>
            )}

            <div className={styles.technology} key={index}>
              <div
                className={styles.technology_name}
                style={{
                  backgroundColor: `${item.color}`,
                  borderColor: `${item.color}`,
                  color: `black`,
                }}
              >
                {item.name}
              </div>
            </div>

            <div className={styles.earnings}>
              {seniority.map((element, element_index) => (
                <div className={styles.earnings_monthly} key={element_index}>
                  <div className={styles.earnings_font_PLN}>
                    <div className={styles.earnings_font_info}>
                      Zarobki miesięczne - {element}
                    </div>
                    {item.range[element][0].y[0] ? (
                      <>
                        od {averageSalaryFormat(item.range[element][0].y[0])}{' '}
                        PLN do{' '}
                        {averageSalaryFormat(item.range[element][0].y[1])} PLN
                      </>
                    ) : (
                      <>Brak danych</>
                    )}
                  </div>

                  <div className={styles.earnings_font_median}>
                    <div className={styles.earnings_font_info}>Mediana</div>
                    {item?.median?.series[element_index + 1]?.data[
                      item?.median?.series[element_index]?.data.length - 1
                    ] ? (
                      <>
                        {averageSalaryFormat(
                          item?.median?.series[element_index + 1]?.data[
                            item?.median?.series[element_index]?.data.length - 1
                          ]
                        )}{' '}
                        PLN
                      </>
                    ) : (
                      <>Brak danych</>
                    )}
                  </div>
                  <EarningsBar
                    object={item.range}
                    seniority={seniority}
                    minItemRange={item.range[element][0].y[0]}
                    maxItemRange={item.range[element][0].y[1]}
                    empty={!item.range[element][0].y[0] ? true : false}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </>
    </Card>
  );
};
export default AverageEarnings;
