import React, { useState, useEffect } from 'react';
import styles from './TreeItem.module.sass';
import Checkbox from 'components/Checkbox';

const TreeItem = ({
  data,
  isChild,
  itemClicked,
  slugsArray,
  initialParent,
  hideIcon,
  hasCheckbox,
  categories,
  categoriesSlugs,
  isModalVisible,
}) => {
  const [showBranches, setShowBranches] = useState([]);

  const toggleBranch = slug => {
    if (!showBranches.includes(slug)) {
      setShowBranches(showBranches => [...showBranches, slug]);
    }
    if (showBranches.includes(slug)) {
      setShowBranches(current => current.filter(name => name !== slug));
    }
  };

  useEffect(() => {
    const temp = [...slugsArray];
    data.forEach(item => {
      if (temp.includes(item.slug)) {
        setShowBranches(showBranches => [...showBranches, item.slug]);
      }
    });
  }, [slugsArray]);

  useEffect(() => {
    if (categoriesSlugs) {
      const temp = [...categoriesSlugs];
      data.forEach(item => {
        if (temp.includes(item.slug)) {
          setShowBranches(showBranches => [...showBranches, item.slug]);
        }
      });
    }
  }, [categoriesSlugs]);

  return (
    <div className={!isChild ? styles.container : styles.child_container}>
      {data.map((parent, index) => {
        return (
          <div key={index}>
            <div
              className={
                isChild
                  ? slugsArray.includes(parent.slug)
                    ? styles.item_active
                    : styles.item
                  : styles.first_item
              }
            >
              {hasCheckbox && (
                <div className={styles.checkbox}>
                  <Checkbox
                    value={parent.checked}
                    onChange={() => itemClicked(parent)}
                  />
                </div>
              )}
              {!hideIcon && (
                <div
                  className={
                    parent.slug === slugsArray[slugsArray.length - 1]
                      ? styles.thumbnail_active
                      : styles.thumbnail
                  }
                >
                  {parent.icon ? (
                    <img src={parent.icon} alt='Miniaturka' />
                  ) : (
                    <span className={styles.dot}></span>
                  )}
                </div>
              )}
              {parent.slug !== initialParent?.slug ? (
                hasCheckbox ? (
                  <button
                    className={
                      parent.slug === slugsArray[slugsArray.length - 1]
                        ? styles.item_button_active
                        : styles.item_button
                    }
                    onClick={() => toggleBranch(parent.slug)}
                  >
                    <div>{parent.name}</div>
                  </button>
                ) : (
                  <button
                    className={
                      parent.slug === slugsArray[slugsArray.length - 1]
                        ? styles.item_button_active
                        : styles.item_button
                    }
                    onClick={() => itemClicked(parent)}
                  >
                    <div
                      className={
                        parent.slug === slugsArray[slugsArray.length - 1]
                          ? styles.branch_active
                          : undefined
                      }
                    >
                      {parent.name}
                    </div>
                  </button>
                )
              ) : (
                <div className={styles.item_button_edit}>{parent.name}</div>
              )}

              {parent.children.length > 0 &&
                parent.slug !== initialParent?.slug && (
                  <button
                    className={
                      showBranches.includes(parent.slug)
                        ? styles.up_arrow
                        : styles.down_arrow
                    }
                    onClick={() => toggleBranch(parent.slug)}
                  ></button>
                )}
            </div>

            <div
              style={{ display: !showBranches.includes(parent.slug) && 'none' }}
            >
              {parent.children && (
                <TreeItem
                  data={parent.children}
                  isChild
                  itemClicked={itemClicked}
                  slugsArray={slugsArray}
                  initialParent={initialParent}
                  hideIcon={hideIcon}
                  hasCheckbox={hasCheckbox}
                  categories={categories}
                  categoriesSlugs={categoriesSlugs}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TreeItem;
