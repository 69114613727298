import React from 'react';
import styles from './AboutMeDescription.module.sass';
import Icon from 'components/Icon';

const AboutMeDescription = ({ description, phone, email }) => {
  function phoneFormat(phone) {
    return phone?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return (
    <div className={styles.bio_section}>
      <div className={styles.bio}>
        {description && description}
        {!description && 'Użytkownik nie dodał jeszcze swojego opisu.'}
      </div>
      <div className={styles.contact_data}>
        {phone && (
          <div className={styles.phone}>
            <Icon name='phone' size={24} />
            <a href={'tel:' + phone}>{phoneFormat(phone)}</a>
          </div>
        )}
        {email && (
          <div className={styles.email}>
            <Icon name='mail' size={24} />
            <a href={'mailto:' + email}>{email}</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutMeDescription;
