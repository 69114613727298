import Modal from "components/Modal";
import styles from "./UnbanModal.module.sass"
import React, { useState } from "react";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import { adminEndpoints } from "../../api/endpoints";
import cn from "classnames";
import Icon from "../Icon";
import axios from "axios";

const UnbanModal = ({userId, visible, setVisible}) => {
    const [ isSubmitted, setIsSubmitted ] = useState(false);

    const handleUnbanUser = () => {
        try {
            const response = devscntrAuth.delete(
                `${adminEndpoints.users.unban}${userId}`
            );
            console.log(response)
            setIsSubmitted(true)
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('unban user canceled');
            } else {
                console.log(error);
            }
        }
    };

    return (
        <Modal
            outerClassName={styles.modal}
            visible={visible}
            onClose={() => setVisible(false)}
        >
            {!isSubmitted
                ? <div className={styles.container}>
                    <h3>Odblokuj użytkownika</h3>
                    <p className={styles.label}>Czy chcesz odblokować użytkownika?</p>
                    <div className={styles.buttons}>
                        <button className={cn("button", styles.submit)} onClick={handleUnbanUser}>
                            Odblokuj
                        </button>
                        <button className={cn("button-stroke")} onClick={() => setVisible(false)}>
                            Anuluj
                        </button>
                    </div>
                </div>
                : <div className={styles.container}>
                    <h3>Użytkownik został odblokowany</h3>
                </div>
            }
        </Modal>
    )
}

export default UnbanModal;