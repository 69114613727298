import React, { useRef, useState } from 'react';
import styles from './AvatarEditor.module.sass';
import cn from 'classnames';
import PhotoUploader from 'components/PhotoUploader';
import { validateImage } from 'utils/validation';
import Icon from 'components/Icon';

const AvatarEditor = ({
  className,
  userId,
  image = null,
  onDelete,
  setImage,
  setFile,
}) => {
  const inputRef = useRef();

  //const [image, setImage] = useState(img || null);
  const [cropperData, setCropperData] = useState({
    visible: false,
    image: null,
    width: 0,
    height: 0,
    filename: '',
  });

  const handleClick = () => {
    inputRef.current.click();
  };

  const removeImage = () => {
    setImage(null);
    setFile(null);
    onDelete && onDelete();
  };

  // Hide image cropper
  const hideCropper = () => {
    setCropperData(prev => ({
      ...prev,
      visible: false,
    }));
  };

  const onCropSubmit = (file, imgBase64) => {
    hideCropper();
    setFile(file);
    setImage(imgBase64);
    //setImg && setImg(imgBase64);
  };

  // LOGO CROPPING
  const handleFileChange = e => {
    const file = e.target.files[0];

    if (!validateImage(file, 5)) return;

    setCropperData({
      visible: true,
      image: file,
      width: 150,
      height: 150,
      filename: `profile_img_${Math.floor(Math.random() * 10000000000)}`,
      onSubmit: onCropSubmit,
    });
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.avatar}>
        <img src={image || '/images/default-avatar.png'} alt='' />
        <button className={styles.remove} onClick={removeImage}>
          <Icon name='close' />
        </button>
      </div>
      <div className={styles.file}>
        <input
          type='file'
          accept='image/*'
          onChange={handleFileChange}
          ref={inputRef}
        />
        {cropperData.visible && (
          <PhotoUploader
            onSubmit={cropperData.onSubmit}
            sourceImage={cropperData.image}
            width={cropperData.width}
            height={cropperData.height}
            filename={cropperData.filename}
          />
        )}
        <button
          className={cn('button', styles.button)}
          type='button'
          onClick={handleClick}
        >
          <Icon name='add' size='24' />
          <span>Prześlij zdjęcie</span>
        </button>
      </div>
      <button
        className={cn('button-stroke', styles.button_remove)}
        onClick={removeImage}
      >
        Usuń
      </button>
    </div>
  );
};
export default AvatarEditor;
