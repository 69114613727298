import React from 'react';
import styles from './Content.module.sass';
import cn from 'classnames';
import EditorContentRender from 'components/Editor/EditorContentRender';
import isJson from 'utils/isJson';
import EditorTinyRender from 'components/EditorTiny/EditorTinyRender';

const Content = ({ className, description, title = 'Treść',subtitle, version }) => {
  if (!description) return null;

  const isEditorV1 = version === 1 && isJson(description) ? true : false;
  const isEditorV2 = version === 2 ? true : false;

  if (!isEditorV1 && !isEditorV2) return null;

  return (
    <div className={cn(styles.container, className && className)}>
      <div className={cn('title-red', styles.title)}>{title}</div>
      {subtitle&&
      (
        <div>{subtitle}</div>
      )}
      {isEditorV1 && (
        <EditorContentRender className={styles.content} data={description} />
      )}
      {isEditorV2 && <EditorTinyRender content={description} />}
    </div>
  );
};

export default Content;
