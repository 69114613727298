import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Education.module.sass';
import TextInput from 'components/TextInput';
import TextArea from 'components/TextArea';
import DatePicker from 'screens/Publications/components/DatePicker';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Icon from 'components/Icon';

const Education = ({ education, setEducation, setEducationRemoveList }) => {
  function getCurrentWidth() {
    return {
      width: window.innerWidth,
    };
  }

  const [screenWidth, setScreenWidth] = useState(getCurrentWidth());

  const [school, setSchool] = useState('');
  const [field, setField] = useState('');
  const [major, setMajor] = useState('');
  const [degree, setDegree] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [editId, setEditId] = useState('');

  const [modalText, setModalText] = useState('Dodaj zatrudnienie do listy');

  const [visible, setVisible] = useState(false);

  const startDateHandler = date => {
    setStartDate(date);
  };

  const endDateHandler = date => {
    setEndDate(date);
  };

  const handleDescriptionChange = e => {
    setDescription(e.target.value);
  };

  const closeModal = () => {
    setVisible(false);
    setSchool('');
    setField('');
    setMajor('');
    setDegree('');
    setDescription('');
    setStartDate('');
    setEndDate('');
  };

  const removeEducation = id => {
    education.forEach(element => {
      if (element.id === id || element.index === id + 1) {
        element.remove = true;
        setEducationRemoveList(educationRemoveList => [
          ...educationRemoveList,
          element,
        ]);
      }
    });

    setEducation(current =>
      current.filter(education => {
        return education.id !== id || education.index === id + 1;
      })
    );
  };

  const educationSubmit = () => {
    if (modalText === 'Dodaj wykształcenie') {
      setEducation(education => [
        ...education,
        {
          index: education.length + 1,
          school: school,
          field: field,
          major: major,
          degree: degree,
          description: description,
          start_date: startDate,
          end_date: endDate,
          remove: false,
        },
      ]);
      closeModal();
    }

    if (modalText === 'Edytuj') {
      education.forEach(element => {
        if (element.id === editId || element.index === editId) {
          element.school = school;
          element.field = field;
          element.major = major;
          element.degree = degree;
          element.description = description;
          element.start_date = startDate;
          element.end_date = endDate;
        }
      });
      closeModal();
    }
  };

  const openAddModal = () => {
    setVisible(true);
    setModalText('Dodaj wykształcenie');
  };

  const openEditModal = id => {
    setVisible(true);
    setModalText('Edytuj');
    education.forEach(element => {
      if (element.id === id || element.index === id + 1) {
        if (element?.id) {
          setEditId(element?.id);
        } else {
          setEditId(element?.index);
        }
        setSchool(element?.school);
        setField(element?.field);
        setMajor(element?.major);
        setDegree(element?.degree);
        setDescription(element?.description);
        setStartDate(element?.start_date);
        setEndDate(element?.end_date);
      }
    });
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenWidth]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={cn('title-purple')}>
          <div className={styles.header_text}>Wykształcenie</div>
        </div>
        <Button
          onClick={() => openAddModal()}
          classNames={cn('button', 'button-small')}
        >
          Dodaj wykształcenie
        </Button>
      </div>
      <div className={styles.list}>
        {education.length === 0 && (
          <span className={styles.info}>
            Nie dodałeś/aś jeszcze swojego wykształcenia
          </span>
        )}
        {education.length > 0 && (
          <div className={styles.labels}>
            {screenWidth.width > 1000 ? (
              <div className={styles.education_info}>
                <div className={styles.list_item}>Szkoła</div>
                <div className={styles.list_item}>Dziedzina</div>
                <div className={styles.list_item}>Specjalizacja</div>
                <div className={styles.list_item}>Stopień</div>
                <div className={styles.list_item}>Czas nauki</div>
              </div>
            ) : (
              <div className={styles.education_info_mobile}>
                <div className={styles.list_item}>Nazwa szkoły i Dziedzina</div>
                <div className={styles.list_item}>Specjalizacja i Stopień</div>
                <div className={styles.list_item}>
                  Data rozpoczęcia i zakończenia nauki
                </div>
              </div>
            )}
          </div>
        )}

        {education.map(
          (item, index) =>
            !item?.remove &&
            (screenWidth.width > 1000 ? (
              <div className={styles.list_container} key={index}>
                <div className={styles.item_container}>
                  <div className={styles.education_info_grid}>
                    <div className={styles.list_item}>{item.school}</div>
                    <div className={styles.list_item}>{item.field}</div>
                    <div className={styles.list_item}>{item.major}</div>
                    <div className={styles.list_item}>{item.degree}</div>
                    <div className={styles.list_item}>
                      {item.start_date && (
                        <>
                          {new Date(item.start_date).getUTCDate()}/
                          {new Date(item.start_date).getUTCMonth()}/
                          {new Date(item.start_date).getUTCFullYear()} -{' '}
                        </>
                      )}
                      {item.end_date ? (
                        <>
                          {new Date(item.end_date).getUTCDate()}/
                          {new Date(item.end_date).getUTCMonth()}/
                          {new Date(item.end_date).getUTCFullYear()}
                        </>
                      ) : (
                        item.start_date && <>Teraz</>
                      )}
                    </div>
                    <div className={styles.buttons_container}>
                      <Button
                        onClick={() =>
                          removeEducation(item?.id ? item?.id : index)
                        }
                        classNames={styles.button_remove}
                      >
                        <Icon name='close' size={18} />
                      </Button>
                      <Button
                        onClick={() =>
                          openEditModal(item?.id ? item?.id : index)
                        }
                        classNames={styles.button}
                      >
                        <Icon name='edit' size={18} />
                      </Button>
                    </div>
                  </div>
                  <div className={styles.list_description}>
                    {item.description}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.list_container} key={index}>
                <div className={styles.item_container}>
                  <div className={styles.education_info_grid_mobile}>
                    <div className={styles.doubled_list_item}>
                      <div>{item.school}</div>
                      <div>{item.field}</div>
                    </div>
                    <div className={styles.doubled_list_item}>
                      <div>{item.major}</div>
                      <div>{item.degree}</div>
                    </div>
                    <div className={styles.list_item}>
                      {item.start_date && (
                        <>
                          {new Date(item.start_date).getUTCDate()}/
                          {new Date(item.start_date).getUTCMonth()}/
                          {new Date(item.start_date).getUTCFullYear()} -{' '}
                        </>
                      )}
                      {item.end_date ? (
                        <>
                          {new Date(item.end_date).getUTCDate()}/
                          {new Date(item.end_date).getUTCMonth()}/
                          {new Date(item.end_date).getUTCFullYear()}
                        </>
                      ) : (
                        item.start_date && <>Teraz</>
                      )}
                    </div>
                    <div className={styles.buttons_container}>
                      <Button
                        onClick={() =>
                          removeEducation(item?.id ? item?.id : index)
                        }
                        classNames={styles.button_remove}
                      >
                        <Icon name='close' size={18} />
                      </Button>
                      <Button
                        onClick={() =>
                          openEditModal(item?.id ? item?.id : index)
                        }
                        classNames={styles.button}
                      >
                        <Icon name='edit' size={18} />
                      </Button>
                    </div>
                  </div>
                  <div className={styles.list_description}>
                    {item.description}
                  </div>
                </div>
              </div>
            ))
        )}
      </div>

      <Modal
        visible={visible}
        onClose={() => closeModal()}
        outerClassName={styles.modal}
      >
        <div className={styles.modal_container}>
          <div className={styles.names_container}>
            <TextInput
              className={styles.field}
              label='Nazwa szkoły/uczelni'
              placeholder='Podaj nazwę'
              type='text'
              value={school}
              onChange={e => setSchool(e.target.value)}
            />
            <TextInput
              className={styles.field}
              label='Dziedina'
              placeholder='Podaj dziedzinę nauki'
              type='text'
              value={field}
              onChange={e => setField(e.target.value)}
            />
          </div>
          <div className={styles.names_container}>
            <TextInput
              className={styles.field}
              label='Specjalizacja'
              placeholder='Podaj specjalizację'
              type='text'
              value={major}
              onChange={e => setMajor(e.target.value)}
            />
            <TextInput
              className={styles.field}
              label='Stopień'
              placeholder='Podaj stopień'
              type='text'
              value={degree}
              onChange={e => setDegree(e.target.value)}
            />
          </div>
          <TextArea
            className={styles.text_area}
            label='Opis wykształcenia'
            maxLength={2000}
            value={description}
            onTitleChange={handleDescriptionChange}
          />
          <div className={styles.dates_container}>
            <DatePicker
              yearRangeStart={1970}
              value={startDateHandler}
              label='Data rozpoczęcia nauki'
              startingDate={startDate}
            />
            <DatePicker
              yearRangeStart={1970}
              value={endDateHandler}
              label='Data zakończenia nauki'
              startingDate={endDate}
            />
          </div>
          <Button
            onClick={() => educationSubmit()}
            classNames={styles.modal_button}
          >
            {modalText}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Education;
