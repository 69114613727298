import React, { useEffect, useState } from 'react';
import styles from './NewCourse.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import Panel from 'screens/NewLayouts/Panel';
import cn from 'classnames';
import Card from '../../components/Card';
import { useHistory } from 'react-router-dom';
import Details from './Details';
import devscntrAuth from '../../api/Instance/devscntrAuth';
import {
  courseDifficulty,
  courseFormat,
  courseLanguage,
} from '../../data/dataArrays';
import TagsInput from 'screens/NewLayouts/TagsInput';
import ChooseCategoryBreadcrumbs from 'components/ChooseCategoryBreadcrumbs';
import ImageInput from 'screens/NewLayouts/ImageInput';
import Content from 'screens/NewLayouts/Content';
import PromoCodes from 'screens/NewLayouts/PromoCodes';
import { showError } from 'utils/showError';
import { validateInput, validateUrl } from 'utils/validation';
import useAuth from 'hooks/useAuth';
import ModalSchedule from 'components/ModalSchedule';
import LocationsInput from 'components/InputBlocks/LocationsInput';
import LinksInput from 'screens/NewLayouts/LinksInput';
import Meta from '../../components/Meta';
import SlugInput from 'components/SlugInput';

const DUMMY_TAGS = [{ name: 'jak zacząć', count: 20 }];

const EDITOR_VERSION = 2;

const NewCourse = () => {
  const authCtx = useAuth();

  const location = useHistory();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleSchedule, setVisibleSchedule] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [isLoading, setIsLoading] = useState(false);
  const [isPostCreated, setIsPostCreated] = useState(false);

  const [status, setStatus] = useState(0);
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('')
  const [editorState, setEditorState] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [detailsData, setDetailsData] = useState({
    duration: '',
    certificate: false,
    courseFormat: 'Wybierz formę kursu',
    courseLocation: {
      city: '',
      code: '',
      street: '',
      address: '',
    },
    language: courseLanguage[0],
    reflink: '',
    coursePrice: '',
    courseSalePrice: '',
    courseDifficulty: 'Wybierz poziom kursu',
  });
  const [files, setFiles] = useState(null);
  const [tags, setTags] = useState([]);
  const [links, setLinks] = useState([]);
  const [promoCodes, setCodes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);

  console.log(files)

  const clearAllFields = () => {
    setIsPostCreated(false);
    setIsLoading(false);
    setStartDate(new Date());
    setStartTime(new Date());
    setTitle('');
    setEditorState('');
    setMetaTitle('');
    setMetaDescription('');
    setDetailsData({
      duration: '',
      certificate: false,
      courseFormat: 'Wybierz formę kursu',
      courseLocation: {
        city: '',
        code: '',
        street: '',
        address: '',
      },
      language: courseLanguage[0],
      reflink: '',
      coursePrice: '',
      courseSalePrice: '',
      courseDifficulty: 'Wybierz poziom kursu',
    });
    setFiles(null);
    setTags([]);
    setLinks([]);
    setLocations([]);
  };

  // const prepareTagList = tags => {
  //   return tags.map(tag => tag).join(',');
  // };

  const preparePrice = price => {
    return price.replace(',', '.');
  };

  function stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  const validation = data => {
    //console.log(data);
    // TITLE
    if (!validateInput(title.trim(), 100, 10, 'Tytuł')) return false;

    //META
    // if (!validateInput(metaTitle.trim(), 100, 10, 'Meta tytuł')) return false;
    // if (!validateInput(metaDescription.trim(), 100, 10, 'Meta opis')) return false;
    // DESCRIPTION

    let string = stripHtml(editorState)
      .replace(/[\r\n]/g, '')
      .replace(/\u00A0/g, '')
      .trim();
    let descriptionLength = string.length;

    if (detailsData.reflink === '') {
      if (descriptionLength < 1) {
        showError('Opis nie może być pusty');
        return false;
      }
      // if (descriptionLength > 10000) {
      //   showError('Opis nie może mieć więcej niż 10000 znaków');
      //   return false
      // }
    }

    if (!validateInput(data?.duration?.trim(), 6, 1, 'czas trwania', 3))
      return false;

    // DROPDOWNS
    if (!courseFormat.includes(data.courseFormat)) {
      showError('Wybierz formę kursu');
      return false;
    }
    if (!courseDifficulty.includes(data.courseDifficulty)) {
      showError('Wybierz poziom kursu');
      return false;
    }
    if (!courseLanguage.includes(data.language)) {
      showError('Wybierz język kursu');
      return false;
    }

    // VALIDATE REFLINK
    if (data.reflink !== '') {
      if (!validateUrl(data.reflink.trim())) return false;
    }

    return true;
  };

  const clearInputs = () => {
    setTitle('');
    setSlug('');
    setEditorState('');
    setMetaTitle('');
    setMetaDescription('');
    setDetailsData({
      duration: '',
      certificate: false,
      courseFormat: 'Wybierz formę kursu',
      language: 'PL',
      reflink: '',
      coursePrice: '',
      courseSalePrice: '',
      courseDifficulty: 'Wybierz poziom kursu',
    });
    setFiles(null);
    setTags([]);
    setLinks([]);
    setLocations([]);
    setCategories([]);
  };

  const prepareFormData = () => {
    const formData = new FormData();

    //const stringTags = prepareTagList(tags);
    const preparedPrice = preparePrice(detailsData.coursePrice);
    const preparedSalePrice = preparePrice(detailsData.courseSalePrice);

    // EDITOR VERSION
    formData.append('editor_version', EDITOR_VERSION);

    // BASIC DATA
    formData.append('title', title.trim());
    formData.append('content', editorState);

    formData.append('price', preparedPrice.trim());
    formData.append('specialprice', preparedSalePrice.trim());
    formData.append('duration', detailsData.duration.trim());

    if (detailsData.courseFormat !== 'Wybierz formę kursu') {
      formData.append(
        'format',
        courseFormat.indexOf(detailsData.courseFormat) + 1
      );
    }

    formData.append(
      'difficulty',
      courseDifficulty.indexOf(detailsData.courseDifficulty) + 1
    );
    formData.append('language', detailsData.language.toLowerCase());
    formData.append('certificate', detailsData.certificate);
    formData.append('reflink', detailsData.reflink);

    // TAGS
    if (tags.length > 0) {
      tags.forEach((item, index) => {
        formData.append(`tags[${index}]name`, item.name);
        formData.append(`tags[${index}]colour`, item.colour);
      });
    }

    // CATEGORIES
    if (categories.length > 0) {
      categories.forEach((item, index) => {
        formData.append(`categories[${index}]name`, item.name);
        formData.append(`categories[${index}]id`, item.id);
      });
    }

    // LINKS
    if (links.length > 0) {
      links.forEach((item, index) => {
        formData.append(`additional_url[${index}]description`, item.title);
        formData.append(`additional_url[${index}]url`, item.url);
      });
    }

    //PROMOCODES
    if (promoCodes.length > 0) {
      promoCodes.forEach((item, index) => {
        formData.append(`promo_codes[${index}]code`, item.title);
        formData.append(`promo_codes[${index}]number_value`, item.number);
        formData.append(
          `promo_codes[${index}]percentage_value`,
          item.percentage
        );
        formData.append(`promo_codes[${index}]time_limited`, item.limit);
        console.log(item.limit);
        if (item.limit === true) {
          formData.append(
            `promo_codes[${index}]expiration_date`,
            item.expiration
          );
        }
      });
    }

    // LOCATIONS
    if (locations.length > 0) {
      locations.forEach((item, index) => {
        formData.append(`localization[${index}]city`, item.city);
        formData.append(`localization[${index}]code`, item.code);
        formData.append(`localization[${index}]street`, item.street);
        formData.append(`localization[${index}]adres`, item.number);
      });
    }

    // IMAGE
    if (files) {
      formData.append('image', files);
    }

    if (status === 1) {
      formData.append('scheduled_at', startDate.toISOString());
    }

    formData.append('status', status);

    //SLUG
    formData.append('slug', slug)

    //META
    formData.append('meta_title', metaTitle.trim());
    formData.append('meta_description', metaDescription);

    return formData;
  };

  //CATEGORY FUNCTION
  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck?.some(item => value.name === item.name);
  };

  const categoryFunction = item => {
    if (!checkIfArrayContains(categories, item)) {
      setCategories(categories => [...categories, item]);
    }
    if (checkIfArrayContains(categories, item)) {
      setCategories(current =>
        current.filter(object => object.name !== item.name)
      );
    }
  };

  const addCourseApi = async data => {
    setIsLoading(true);
    try {
      const response = await devscntrAuth.post(
        '/course/course-actions/',
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            method: 'create',
          },
        }
      );
      console.log(response);
      setIsPostCreated(true);
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  const handleMetaDescriptionChange = event => {
    const val = event.target.value;
    setMetaDescription(val);
  };

  const handleTitleChange = event => {
    const val = event.target.value;
    setTitle(val);
  };

  const handleSlugChange = event => {
    const val = event.target.value;
    setSlug(val);
  };

  const handleMetaTitleChange = event => {
    const val = event.target.value;
    setMetaTitle(val);
  };

  const handleSubmit = () => {
    if (validation(detailsData)) {
      const formData = prepareFormData();
      addCourseApi(formData);
    }
  };

  const saveDraft = () => {
    setStatus(2);
  };

  useEffect(() => {
    if (status === 2) {
      if (validateInput(title.trim(), 100, 10, 'Tytuł')) {
        const formData = prepareFormData();
        addCourseApi(formData);
      } else {
        setStatus(0)
      }
    }
    if (status === 1) {
      if (validation(detailsData)) {
        const formData = prepareFormData();
        addCourseApi(formData);
      }
      else {
        setStatus(0)
      }
    }
  }, [status]);

  const scheduleHandler = () => {
    setVisibleSchedule(false)
    setStatus(1)
  }

  return (
    <>
      <div className={styles.row}>
        {!isPostCreated ? (
          <>
            <div className={styles.col}>
              <div className={styles.course_info}>
                <Content
                  className={styles.card}
                  onTitleChange={handleTitleChange}
                  onDescriptionChange={handleEditorStateChange}
                  title={title}
                  description={editorState}
                  editorVersion={EDITOR_VERSION}
                />
                <ChooseCategoryBreadcrumbs
                  title='Kategorie'
                  itemClicked={categoryFunction}
                  loading={false}
                  selectedCategories={categories}
                />
                <LinksInput
                  links={links}
                  setLinks={setLinks}
                  className={styles.card}
                />
                <Details data={detailsData} setData={setDetailsData} />
                {detailsData.courseFormat === 'stacjonarny' && (
                  <LocationsInput
                    locations={locations}
                    setLocations={setLocations}
                  />
                )}
                <ImageInput
                  className={styles.card}
                  title={'Miniaturka kursu'}
                  setFile={setFiles}
                  fileName={`course_img_u${authCtx.userID}`}
                />
                <TagsInput tags={tags} setTags={setTags} />
                <SlugInput slug={slug} onSlugChange={handleSlugChange} />
                <Meta
                  title={metaTitle}
                  description={metaDescription}
                  onTitleChange={handleMetaTitleChange}
                  onDescriptionChange={handleMetaDescriptionChange}
                />

              </div>
            </div>
          </>
        ) : (
          <Card className={styles.postcreated}>
            <p>Kurs został utworzony!</p>
            <div className={styles.panel}>
              <button
                className={cn('button', styles.homebtn)}
                onClick={() => location.push('/courses')}
              >
                Zobacz listę kursów
              </button>
              <button
                className={cn('button-stroke', styles.newbtn)}
                onClick={() => clearAllFields()}
              >
                Utwórz kolejny kurs
              </button>
            </div>
          </Card>
        )}
      </div>
      {!isPostCreated && (
        <Panel
          setVisiblePreview={setVisiblePreview}
          setVisibleSchedule={setVisibleSchedule}
          saveDraft={saveDraft}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          clearInputs={clearInputs}
        />
      )}
      <TooltipGlodal />
      <ModalSchedule
        visible={visibleSchedule}
        setVisible={setVisibleSchedule}
        startDate={startDate}
        setStartDate={setStartDate}
        startTime={startTime}
        setStartTime={setStartTime}
        onSubmit={scheduleHandler}
      />
    </>
  );
};

export default NewCourse;
