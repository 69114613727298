import React, { useEffect, useRef, useState } from 'react';
import styles from './HorizontalScroll.module.sass';
import cn from 'classnames';
import Icon from 'components/Icon';

const HorizontalScroll = ({
  children,
  className,
  controlsClassName,
  showScrollbar = false,
  controls = false,
  wide = false,
}) => {
  // UTILS---------------------------------------------------------------------------------UTILS

  //const scrollId = `hzs_${Math.floor(Math.random() * (2137997 - 1) + 1)}`;
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    setRerender(prev => !prev);
  }, [children]);

  const scrollRef = useRef(null);
  const scroll = scrollRef.current || null;

  const [currentScrollLeft, setCurrentScrollLeft] = useState(0);
  const [maxScrollLeft, setMaxScrollLeft] = useState(false);

  const [isScrollable, setIsScrollable] = useState(true);
  let pressed = false;
  let startx;
  let scrollLeft;
  let x;

  // UTILS---------------------------------------------------------------------------------UTILS
  // EVENT HANDLERS---------------------------------------------------------------EVENT HANDLERS

  const handleMouseDown = e => {
    if (!scroll) return;
    pressed = true;
    scroll.style.cursor = 'grabbing';

    startx = e.clientX;
    scrollLeft = scroll.scrollLeft;
  };

  const handleMouseUp = () => {
    pressed = false;
    scroll.style.cursor = 'grab';
    setCurrentScrollLeft(scroll?.scrollLeft || 0);

    const items = Array.from(scrollRef.current.children) || [];
    items.forEach(item => {
      item.style.pointerEvents = 'auto';
    });
  };

  const handleMouseEnter = e => {
    scroll.style.cursor = 'grab';
  };

  const handleMouseLeave = e => {
    setCurrentScrollLeft(scroll?.scrollLeft || 0);
    pressed = false;
  };

  const handleMouseMove = e => {
    if (!scroll) return;
    if (!pressed) return;
    e.preventDefault();

    x = e.clientX;
    scroll.scrollLeft = startx - x + scrollLeft;

    const items = Array.from(scrollRef.current.children) || [];
    items.forEach(item => {
      item.style.pointerEvents = 'none';
    });
  };

  // EVENT HANDLERS---------------------------------------------------------------EVENT HANDLERS
  // APPLY LISTENERS-------------------------------------------------------------APPLY LISTENERS

  useEffect(() => {
    if (scroll) {
      //console.log(scroll);
      const containerWidth = scroll.clientWidth;
      const scrollWidth = scroll.scrollWidth;

      if (scrollWidth === containerWidth) setIsScrollable(false);

      if (isScrollable) {
        scroll.addEventListener('mousedown', handleMouseDown);
        scroll.addEventListener('mouseup', handleMouseUp);
        scroll.addEventListener('mouseenter', handleMouseEnter);
        scroll.addEventListener('mouseleave', handleMouseLeave);
        scroll.addEventListener('mousemove', handleMouseMove);

        return () => {
          scroll.removeEventListener('mousedown', handleMouseDown);
          scroll.removeEventListener('mouseup', handleMouseUp);
          scroll.removeEventListener('mouseenter', handleMouseEnter);
          scroll.removeEventListener('mouseleave', handleMouseLeave);
          scroll.removeEventListener('mousemove', handleMouseMove);
        };
      }
    }
  }, [scroll, isScrollable, scrollRef]);

  useEffect(() => {
    if (scroll) {
      const containerWidth = scroll.clientWidth;
      const scrollWidth = scroll.scrollWidth;

      if (currentScrollLeft + containerWidth >= scrollWidth)
        setMaxScrollLeft(true);
      else setMaxScrollLeft(false);
    }
  }, [currentScrollLeft]);

  // APPLY LISTENERS-------------------------------------------------------------APPLY LISTENERS
  // SCROLLING ARROWS-----------------------------------------------------------SCROLLING ARROWS

  const scrollToPrevious = () => {
    if (!scroll) return;
    const currentScroll = scroll.scrollLeft;
    const containerWidth = scroll.clientWidth;

    if (currentScroll <= 0) return;
    const newScrollLeft = currentScroll - containerWidth;
    scroll.style.scrollBehavior = 'smooth';
    scroll.scrollLeft = currentScroll - containerWidth;
    scroll.style.scrollBehavior = 'auto';

    setCurrentScrollLeft(newScrollLeft || 0);
  };

  const scrollToNext = () => {
    if (!scroll) return;
    const currentScroll = scroll.scrollLeft;
    const containerWidth = scroll.clientWidth;
    const scrollWidth = scroll.scrollWidth;

    if (currentScroll + containerWidth >= scrollWidth) return;
    const newScrollLeft = scroll.scrollLeft + containerWidth;
    scroll.style.scrollBehavior = 'smooth';
    scroll.scrollLeft += containerWidth;
    scroll.style.scrollBehavior = 'auto';
    console.log(scroll.scrollLeft);

    setCurrentScrollLeft(newScrollLeft || 0);
  };

  // SCROLLING ARROWS-----------------------------------------------------------SCROLLING ARROWS

  return (
    <div className={cn(styles.container)}>
      <div
        className={cn(styles.scroll, className, {
          [styles.scrollbar_hidden]: !showScrollbar,
        })}
        ref={scrollRef}
      >
        {children}
      </div>
      <div
        className={cn(styles.controls, {
          [styles.hidden]: !controls || !isScrollable,
        })}
      >
        <button
          className={cn(styles.prev, {
            [controlsClassName]: controlsClassName,
            [styles.wide]: wide,
            [styles.hidden]: currentScrollLeft <= 0,
          })}
          onClick={scrollToPrevious}
        >
          <Icon name='arrow-next' size={20} />
        </button>
        <button
          className={cn(styles.next, {
            [controlsClassName]: controlsClassName,
            [styles.wide]: wide,
            [styles.hidden]: maxScrollLeft,
          })}
          onClick={scrollToNext}
        >
          <Icon name='arrow-next' size={20} />
        </button>
      </div>
    </div>
  );
};

export default HorizontalScroll;
