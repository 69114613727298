import React from 'react';
import cn from 'classnames';
import styles from './SlugInput.module.sass'
import Card from '../Card';
import TextInput from '../TextInput';

const SlugInput = ({
  className,
  slug,
  onSlugChange,
}) => {
  return (
    <Card
      className={cn(styles.card, className)}
      classCardHead={styles.head}
      title='Przyjazny adres URL'
      classTitle='title-green'
    >
      <div className={styles.description}>
        <TextInput
          className={styles.field}
          label='Końcówka adresu URL - tylko litery, cyfry, podkreślenia (_) i minus (-) są znakami dozwolonymi.'
          name='slug'
          type='text'
          placeholder='Podaj slug'
          required
          value={slug}
          onChange={onSlugChange}
        />
      </div>
    </Card>
  );
};

export default SlugInput;