import React from "react";
import style from "./Skeleton.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const RelatedLoader = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      // zrobic light mode
      <SkeletonTheme key={i}>
        <div className={style.skeleton_related} key={i}>
          <div className={style.img}>
            <Skeleton width={170} height={150} borderRadius={20}/>
          </div>
          <div className={style.content}>
            <Skeleton count={4} />
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default RelatedLoader;
