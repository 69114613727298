import React, { useState, useEffect, useRef, useContext } from 'react';
// import axios from 'axios';
import cn from 'classnames';
import styles from './Account.module.sass';
import Item from '../Item';
import TextInput from 'components/TextInput';
import Icon from 'components/Icon';
// import useAuth from '../../../hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { showError } from 'utils/showError';
import { validateInput } from 'utils/validation';
import { validationRules } from 'data/validationRules';
import ModalConfirm from 'components/ModalConfirm';
import { useHistory } from 'react-router';
import { showSuccess } from 'utils/showSuccess';
import AuthContext from 'context/AuthContext';
import Button from 'components/Button';

const CHANGE_PASSWORD_URL = '/accounts/password';
const DELETE_ACCOUNT_URL = '/accounts/me/delete-account';

const Account = ({ className }) => {
  // const authCtx = useAuth();
  const history = useHistory();
  const { logout } = useContext(AuthContext);

  /* --------------------------------- states --------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInfoDeleteModal, setShowInfoDeleteModal] = useState(false);
  //const [errMsg, setErrMsg] = useState("");
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
  });

  /* ---------------------------------- utils --------------------------------- */
  const controller = new AbortController();
  const isMounted = useRef(true);

  /* -------------------------------------------------------------------------- */
  /*                                    LOGIC                                   */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    return () => {
      controller.abort();
      isMounted.current = false;
    };
  }, []);

  const verifyPassword = (passwd, passwd2) => {
    return passwd === passwd2 ? true : false;
  };

  /* -------------------------------------------------------------------------- */
  /*                                  requests                                  */
  /* -------------------------------------------------------------------------- */

  const changePasswordAPI = async () => {
    setIsLoading(true);
    try {
      // Send data
      const response = await devscntrAuth.post(
        CHANGE_PASSWORD_URL,
        JSON.stringify({
          old_password: passwordData.oldPassword,
          new_password: passwordData.newPassword,
        })
      );

      showSuccess('Hasło zostało zmienione');

      // Clear inputs
      setPasswordData({
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
      });
    } catch (error) {
      if (!error?.response) {
        //showMessage("error", "Brak odpowiedzi serwera");
      } else if (error.response?.status === 400) {
        error?.response?.data[0]?.key === 'old_password'
          ? showError('Stare hasło jest niepoprawne') //showMessage("error", "Stare hasło jest niepoprawne")
          : showError('Nowe hasło jest niepoprawne'); //showMessage("error", "Nowe hasło jest niepoprawne");
      } else if (error.response?.status === 401) {
        //showMessage("error", "Błędne hasło");
        showError('Błędne hasło');
      } else {
        //showMessage("error", "Wystąpił błąd serwera");
        showError('Wystąpił błąd serwera');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAccount = async () => {
    try {
      // const abortTimeout = setTimeout(() => {
      //   controller.abort();
      //   throw new Error('Serwer nie odpowiada. Proszę spróbować później.');
      // }, 10000);

      await devscntrAuth.post(DELETE_ACCOUNT_URL, {
        signal: controller.signal,
        confirmation: 'yes',
      });

      /* --------------------------------- testing -------------------------------- */
      // const response = await new Promise(resolve => setTimeout(resolve, 2000));
      // const response = await new Promise((_, reject) =>
      //   setTimeout(reject, 2000)
      // );
      /* --------------------------------- testing -------------------------------- */

      logout();
      history.push('/');
      showSuccess(
        'Usuwanie konta przebiegło pomyślnie.'
        // 'Usuwanie konta przebiegło pomyślnie. Za 10 sekund zostaniesz przeniesiony na stronę główną.'
      );
    } catch (err) {
      err
        ? showError(err)
        : showError('Nie udało się usunąć konta. Spróbuj ponownie później.');
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                  handlers                                  */
  /* -------------------------------------------------------------------------- */

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setPasswordData(prev => ({
      ...prev,
      [name]: value,
    }));
    //console.log(passwordData);
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log('Submit');
    console.log(passwordData);
    if (
      !validateInput(
        passwordData.newPassword,
        validationRules.password.maxChars,
        validationRules.password.minChars,
        validationRules.password.name,
        validationRules.password.security
      )
    ) {
      return;
    }

    if (verifyPassword(passwordData.newPassword, passwordData.newPassword2)) {
      changePasswordAPI();
    } else {
      //showMessage("error", "Podane hasła nie są identyczne");
      showError('Podane hasła nie są identyczne');
    }
  };

  return (
    <>
      <Item
        className={cn(styles.card, className)}
        title='Zmień hasło'
        classTitle='title-purple'
      >
        <div className={styles.password_container}>
          <div className={styles.current_password}>
            <TextInput
              label='Aktualne hasło'
              name='oldPassword'
              type='password'
              value={passwordData.oldPassword}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.row}>
            <TextInput
              className={styles.field}
              label='Nowe hasło'
              name='newPassword'
              type='password'
              value={passwordData.newPassword}
              onChange={handleChange}
              required
            />
            <TextInput
              className={styles.field}
              label='Potwierdź nowe hasło'
              name='newPassword2'
              type='password'
              value={passwordData.newPassword2}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.password_tip}>
            Dobre hasło zawiera małe, duże litery, cyfry i znaki np. !@#$%^&*.
          </div>
          <Button onClick={handleSubmit} isLoading={isLoading}>
            Zmień hasło
          </Button>
        </div>
      </Item>
      <div className={styles.divider}></div>
      <Item
        className={cn(styles.card, className)}
        title='Usuń konto'
        classTitle='title-red'
      >
        <div className={styles.dangerZone}>
          <button
            className={cn('button-stroke-red', styles.button)}
            onClick={() => setShowDeleteModal(true)}
          >
            <span>Usuń</span>
            <Icon name='trash' size='24' />
          </button>
          <ModalConfirm
            visible={showDeleteModal}
            setVisible={setShowDeleteModal}
            onConfirm={() => deleteAccount()}
          />
        </div>
      </Item>
    </>
  );
};

export default Account;
