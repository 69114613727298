import React, { useState, useEffect, useContext } from 'react';
import styles from './MainInformation.module.sass';
import cn from 'classnames';
import ShowContent from '../ShowContent';
import Card from 'components/Card';

const MainInformation = ({ currentObject }) => {
  const [object, setObject] = useState(currentObject);

  return (
    <Card className={cn(styles.card, { [styles.hasIcon]: !!object?.icon })}>
      <div className={cn(styles.header, { [styles.hasIcon]: !!object?.icon })}>
        {object?.icon && <img src={object?.icon} />}
        <h1 className={styles.title}>{object?.title}</h1>
      </div>
      <div
        className={cn(styles.container, { [styles.hasImage]: !!object?.image })}
      >
        <div className={styles.content}>
          <ShowContent description={object.text} version={2} title='' />
        </div>
        {object?.image && (
          <div className={styles.image_container}>
            <img src={object?.image} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default MainInformation;
