import { StatusNames, TypeNames } from './names';

//TODO status is not implemented yet - need to change
export const setStatusName = id => {
  switch (id) {
    case 0:
      return StatusNames.PUBLISHED;
    case 1:
      return StatusNames.SCHEDULED;
    case 2:
      return StatusNames.DRAFT;
    default:
      return StatusNames.PUBLISHED;
  }
};

export const setTypeName = type => {
  if (type === 'post') return TypeNames.POST;
  if (type === 'course') return TypeNames.COURSE;
  if (type === 'job') return TypeNames.JOB; // check later
  return '-';
};

// now in /utils.js

// export const setShortenAmount = value => {
//   if (isNaN(value)) return 'N/A';
//   if (!value && value !== 0) return 'N/A';
//   if (value < 1000) return value;

//   const stringNumber = value.toString().replace(/[^0-9.]/g, '');
//   const si = [
//     { v: 1e3, s: 'K' },
//     { v: 1e6, s: 'M' },
//     { v: 1e9, s: 'B' },
//     { v: 1e12, s: 'T' },
//     { v: 1e15, s: 'P' },
//     { v: 1e18, s: 'E' },
//   ];

//   let id;
//   for (id = si.length - 1; id > 0; id--) {
//     if (stringNumber >= si[id].v) break;
//   }
//   return (
//     (stringNumber / si[id].v)
//       .toFixed(1)
//       .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[id].s
//   );
// };

export const formatLocaleDate = dateStr => new Date(dateStr).toLocaleString();

export const setBarWidth = (value = 0, valueMax) =>
  valueMax ? `${(100 * value) / valueMax}%` : '0%';
