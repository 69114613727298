import React from 'react';
import styles from './Breadcrumb.module.sass';
import { Link } from 'react-router-dom';

/**
 *
 * @param {Array.<{name: String, to?: String, onClick?: Function, icon?: String|null}>} items
 *
 */

const Breadcrumb = ({ items }) => {
  return (
    <div className={styles.breadcrumb}>
      {items.map((item, index) => {
        const { name = '', to, onClick, icon = null } = item;

        const content = (
          <>
            {icon && <img src={icon} alt={name} />}
            {name}
          </>
        );

        if (item?.to) {
          return (
            <Link key={index} className={styles.item} to={to}>
              {content}
            </Link>
          );
        }

        if (item?.onClick) {
          return (
            <button key={index} className={styles.item} onClick={onClick}>
              {content}
            </button>
          );
        }

        return (
          <span key={index} className={styles.item}>
            {content}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
