import React, { useContext, useState, useEffect } from 'react';
import styles from './NewTechnology.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import Modal from '../../components/Modal';
import Schedule from '../../components/Schedule';
import Icon from '../../components/Icon';
import Panel from 'screens/NewLayouts/Panel';
import cn from 'classnames';
import Card from '../../components/Card';
import { Prompt, useHistory } from 'react-router-dom';
import BasicData from './MiniData';
import DynamicSection from './DynamicSection';
import Parent from './Parent';
import Trends from './Trends';
import { jzwitEndpoints, jzwitv2Endpoints } from 'api/endpoints';
import ChooseCategoryBreadcrumbs from 'components/ChooseCategoryBreadcrumbs';
import FormContext from 'context/FormContext';
import { validateInput, validateUrl } from 'utils/validation';
import { showError } from 'utils/showError';
import devscntrAuth from 'api/Instance/devscntrAuth';
import Checkbox from 'components/Checkbox';

const EDITOR_VERSION = 2;

const NewTechnology = () => {
  const formCtx = useContext(FormContext);

  // BASIC STATES
  const [errorMsg, setErrorMsg] = useState('');
  const location = useHistory();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [isPostCreated, setIsPostCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEntering, setIsEntering] = useState(false);
  const [isTechnology, setIsTechnology] = useState(false);
  const [categories, setCategories] = useState([])

  //PARENT DATA
  const [parent, setParent] = useState(0)

  const parentHandler = (item) => {
    setParent(item)
  }

  // VALIDATION---------------------------------------------------------------VALIDATION
  const validation = () => {
    const name = basicData?.name;
    const subTitle = basicData?.subtitle;
    const editor = basicData?.content;
    const image = basicData?.image;
    const icon = basicData?.icon;
    const technologies = technologiesData;
    const trendsTitle = trendsData.title;
    const trendsEditor = trendsData.text;
    const Section = dynamicSectionsData;


    if (!validateInput(name?.trim(), 100, 1, 'Nazwa Technologi', 0))
      return false;

    if (editor.trim(' ').length < 1) {
      showError('Opis nie może być pusty');
      return false;
    }

    if (parent === 0) {
      showError('Musisz wybrać rodzica');
      return false;
    }

    for (let i = 0; i < Section.length; i++) {
      if (Section[i].title.trim().length < 1) {
        showError('Tytuł sekcji nie może być pusty.');
        return false;
      }
      if (Section[i].text.trim(' ').length < 1) {
        showError('Opis sekcji nie może być pusty.');
        return false;
      }
    }

    for (let i = 0; i < trendsData.length; i++) {
      if (trendsData[i].title.trim().length < 1) {
        showError('Tytuł trendu nie może być pusty.');
        return false;
      }
    }
    return true;
  };

  

  // DATA TO SEND
  const basicData = formCtx.basicData;
  const technologiesData = formCtx.technologiesData?.map(
    element => element?.value
  );
  const trendsData = formCtx.trendsData;
  const dynamicSectionsData = formCtx.dynamicSectionsData;

  const prepareFormData = () => {
    const formData = new FormData();
    formData.append('name', basicData?.name);
    formData.append('title', basicData?.name);
    formData.append('image', basicData?.image);
    formData.append('icon', basicData?.icon);
    formData.append('subtitle', basicData?.subtitle);
    formData.append('text', basicData?.content);
    formData.append('is_technology', isTechnology);


    formData.append('parent', parent.id);

    formData.append('editor_version', EDITOR_VERSION);
    if (dynamicSectionsData.length > 0) {
      dynamicSectionsData.forEach((item, index) => {
        formData.append(`sections[${index}]title`, item.title);
        formData.append(`sections[${index}]subtitle`, item.subtitle);
        formData.append(`sections[${index}]text`, item.text);
        formData.append(`sections[${index}]side`, item.side);
        formData.append(`sections[${index}]position`, item.position);
      });
    }

    // CATEGORIES
    if (categories.length > 0) {
      categories.forEach((item, index) => {
        formData.append(`categories[${index}]name`, item.name);
        formData.append(`categories[${index}]id`, item.id);
      });
    }

    if (trendsData.length > 0) {
      trendsData.forEach((item, index) => {
        if (item.title !== '' && item.subtitle !== '') {
          formData.append(`trends[${index}]title`, item.title);
          formData.append(`trends[${index}]subtitle`, item.subtitle);
        }
      });
    }

    return formData;
  };
  // ----------------------------------------------------

  // WYSŁANIE
  const EditApi = async data => {
    try {
      const response = await devscntrAuth.post(
        `${jzwitv2Endpoints.createTech}`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            method: 'create',
          },
        }
      );
      // console.log(response);
      setIsPostCreated(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };
  // SUBMIT FUNCTION
  const handleSubmit = () => {
    if (!validation()) return;
    setIsLoading(true);
    const formData = prepareFormData();
    EditApi(formData);
  };

  // ----------------------------------------------------

  //CATEGORY FUNCTION
  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck?.some(item => value.name === item.name)
  }

  const categoryFunction = (item) => {
    if (!checkIfArrayContains(categories, item)) {
      setCategories(categories => [...categories, item])
    }
    if (checkIfArrayContains(categories, item)) {
      setCategories((current) => current.filter((object) => object.name !== item.name));
    }
  }

  const onFocusHandler = () => {
    setIsEntering(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(false)
  }, [isPostCreated]);

  return (
    <>
      <Prompt
        when={isEntering}
        message={location => 'Czy na pewno chcesz wyjsc ?'}
      />
      <div className={styles.row}>
        {!isPostCreated ? (
          <>
            <div className={styles.course_info}>
              {errorMsg.length > 0 && (
                <div className={styles.error}>
                  <Icon name='info' size='24' />
                  {errorMsg}
                </div>
              )}
              <BasicData checkboxValue={isTechnology} onCheckboxChange={setIsTechnology} isEditor={false}/>
              <Parent parentHandler={parentHandler} editParentData={parent} />
              <ChooseCategoryBreadcrumbs
                title='Kategorie'
                itemClicked={categoryFunction}
                loading={false}
                selectedCategories={categories}
              />
              <Trends className={'Trends'} />
              <DynamicSection />
            </div>
          </>
        ) : (
          <Card className={styles.postcreated}>
            <p>Technologia została utworzona</p>
            <div className={styles.panel}>
              <button
                className={cn('button', styles.homebtn)}
                onClick={() => location.push('/jak-zaczac-w-it')}
              >
                Zobacz Wszystkie Technologie
              </button>
              <button
                className={cn('button-stroke', styles.newbtn)}
                onClick={() => window.location.reload(false)}
              >
                Utwórz kolejną Technologie
              </button>
            </div>
          </Card>
        )}
      </div>
      {!isPostCreated && (
        <>
          <Panel
            setVisiblePreview={setVisiblePreview}
            setVisibleSchedule={setVisibleModal}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
          <TooltipGlodal />
          <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
            <Schedule
              startDate={startDate}
              setStartDate={setStartDate}
              startTime={startTime}
              setStartTime={setStartTime}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default NewTechnology;
