import React from 'react';
import styles from './TechStack.module.sass';
import cn from 'classnames';
import uuid from 'react-uuid';
import Item from './Item';

const TechStack = ({ className, tags, headingVisible = true }) => {

  const sortTechStack = (a,b) => {return b?.level-a?.level};

  return (
    tags && (
      <div className={cn(styles.container, className && className)}>
        {headingVisible && (
          <div className={cn('title-blue', styles.title)}>Technologie</div>
        )}
        <ul className={styles.tags}>
          {tags?.sort(sortTechStack).map(item => (
            <Item
              classNames={styles.item}
              key={uuid()}
              name={item?.name || 'Brak nazwy'}
              level={item?.level || 0}
              color={item?.colour || '#ffffff'}
            />
          ))}
        </ul>
      </div>
    )
  );
};

export default TechStack;
