export const setItem = (key, value) => {
  if (window.localStorage) return window.localStorage.setItem(key, value);
};

export const getItem = key => {
  if (window.localStorage) return window.localStorage.getItem(key);
};

export const getProgress = () => !!getItem('b5InProgress');

export const restoreData = () => {
  const data = getItem('b5data');
  return data ? JSON.parse(data) : null;
};

export const populateData = data => {
  setItem('b5InProgress', 'true');
  const { position, progress, next, previous, items } = data;
  const answers = Object.assign({}, data.answers);
  setItem(
    'b5data',
    JSON.stringify({ progress, next, previous, answers, position, items })
  );
};

export const clearItems = () => {
  if (window.localStorage) window.localStorage.clear();
};

export const getItems = (position, itemsPerPage, inventory) => {
  const next = position + itemsPerPage;
  const back = position - itemsPerPage;
  const previous = back !== 0;

  return {
    next: () => ({
      items: inventory?.slice(next, next + itemsPerPage) || [],
      finished: inventory.length === next,
      position: next,
    }),
    back: () => ({
      items: inventory?.slice(back, position) || [],
      previous,
      position: back,
    }),
    current: () => ({
      items: inventory?.slice(position, next) || [],
      previous,
    }),
  };
};
