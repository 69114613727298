import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './Notification.module.sass';
import Icon from '../../Icon';
import Actions from '../../Actions';
import ItemPost from './Item/ItemPost';
import SkeletonLoader from './SkeletonLoader';

// data
import { useHistory } from 'react-router';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import api from '../../../api/devscntr';
import ItemComments from './Item/ItemComments';
import ItemMisc from './Item/ItemMisc';
import devscntrAuth from '../../../api/Instance/devscntrAuth';
import { accountsEndpoints } from '../../../api/endpoints';
import devscntrNoauth from '../../../api/Instance/devscntrNoauth';
import useAuth from '../../../hooks/useAuth';

const navigation = ['Posty', 'Komentarze', 'Różne'];

const NOTIFICATION_URL = '/accounts/me/notification/';

const Notification = ({ className }) => {
  const authCtx = useAuth();

  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const location = useHistory();
  const [received, setReceived] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [notifications, setNotifications] = useState([]);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [activeCommentTypeIndex, setActiveCommentTypeIndex] = useState(0);
  const [isAllRead, setIsAllRead] = useState(false);

  useEffect(() => {
    setIsAllRead(false);
    if (received.length !== 0) {
      setNotifications([])
      received['notifications posts']?.forEach(element => {
        setNotifications(notifications => [...notifications, { ...element, type: 'post' }])
      });
      received['notifications comments posts']?.forEach(element => {
        setNotifications(notifications => [...notifications, { ...element, type: 'commentPost' }])
      });
      received['notifications comments courses']?.forEach(element => {
        setNotifications(notifications => [...notifications, { ...element, type: 'commentCourse' }])
      });
      received['notification misc']?.forEach(element => {
        setNotifications(notifications => [...notifications, element])
      });

      setHasNewNotifications(false);
      for (let key in received) {
        if (received[key].length > 0) setHasNewNotifications(true);
      }
    }
  }, [activeIndex, activeCommentTypeIndex, visible, received]);

  useEffect(() => {
    getNotificationsAPI();
  }, [
    activeIndex,
    activeCommentTypeIndex,
    visible,
    location.location.pathname,
  ]);

  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const getNotificationsAPI = async () => {
    setIsLoading(true);
    await axiosInstance
      .get(accountsEndpoints.notifications.show)
      .then(response => {
        setReceived(response.data);

        setIsLoading(false);
      })
      .catch(err => {
        setError(err);
        console.log(err);

        setIsLoading(false);
      });
  };

  const allReadHandler = async () => {
    for (let i = 0; i < 4; i++) {
      switch (i) {
        case 0:
          allReadSend('post');
        case 1:
          allReadSend('comment_post');
        case 2:
          allReadSend('comment_course');
        case 3:
          allReadSend('misc');
      }
    }
  };

  const allReadSend = async (item) => {
    let params = `?category=` + item;

    try {
      await devscntrAuth.get(
        `${accountsEndpoints.notifications.delete}${params}`
      );
      setIsAllRead(true);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.notification, className, {
          [styles.active]: visible,
        })}
      >
        <button
          className={cn(styles.head, { [styles.active]: hasNewNotifications })}
          onClick={() => (visible ? setVisible(false) : setVisible(true))}
        >
          <Icon name='notification' size='24' />
        </button>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.title}>Powiadomienia</div>
          </div>
          <div className={styles.list}>
            {notifications.length > 0 && (
              <button
                className={cn('button-stroke', styles.setreadbtn)}
                onClick={allReadHandler}
              >
                <Icon name='check-all' size='20' />
                Oznacz wszystkie jako przeczytane
              </button>
            )}
            {!isLoading ? (
              notifications.length > 0 ? (
                notifications.map((x, index) => {
                  if (index < 5) {
                    return (
                      <div key={index}>
                        {x.type === 'post' && (
                          <ItemPost
                            className={cn(styles.item, className)}
                            item={x}
                            onClose={() => setVisible(false)}
                            isAllRead={isAllRead}
                          />
                        )}
                        {x.type === 'commentPost' && (
                          <ItemComments
                            className={cn(styles.item, className)}
                            item={x}
                            key={index}
                            onClose={() => setVisible(false)}
                            type={x.type}
                            isAllRead={isAllRead}
                          />
                        )}
                        {x.type === 'commentCourse' && (
                          <ItemComments
                            className={cn(styles.item, className)}
                            item={x}
                            key={index}
                            onClose={() => setVisible(false)}
                            type={x.type}
                            isAllRead={isAllRead}
                          />
                        )}
                        {(x.type === 'REACTION' || x.type === 'PIN' || x.type === 'APPLICATION' || x.type === 'COURSE' || x.type === 'FOLLOW') && (
                          <ItemMisc
                            className={cn(styles.item, className)}
                            item={x}
                            key={index}
                            isAllRead={isAllRead}
                          />
                        )}
                      </div>
                    )
                  }
                })
              ) : (
                <p className={styles.center}>
                  {error ? 'Wystąpił błąd' : 'Brak powiadomień'}
                </p>
              )
            ) : (
              <SkeletonLoader cards={1} />
            )}
          </div>
          <Link
            className={cn('button', styles.button)}
            to='/notification'
            onClick={() => setVisible(false)}
          >
            Wyświetl wszystkie
          </Link>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Notification;
