import React, { useEffect, useState } from 'react';
import styles from './Account.module.sass';
import cn from 'classnames';
import { Switch, Route } from 'react-router';
import useBreakpoints from 'hooks/useBreakpoints';
import Dashboard from './Dashboard';
import CvForm from './CvForm';
import Submenu from 'components/Submenu';
import JobApplications from './JobApplications';
import MyApplications from './JobApplications/MyApplications';
import ReceivedApplications from './JobApplications/ReceivedApplications';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { accountsEndpoints } from 'api/endpoints';
import ApplicationDetails from './JobApplications/ReceivedApplications/ApplicationDetails';

const menu = [
  // {
  //   title: 'Dashboard',
  //   icon: 'leaderboard',
  //   url: '/account/dashboard',
  // },
  {
    title: 'Moje CV',
    icon: 'design',
    url: '/account/cv',
  },
  {
    title: 'Wysłane aplikacje',
    icon: 'mail',
    url: '/account/my-job-applications',
  },
];

const Account = () => {
  const [menuOptions, setMenuOptions] = useState(menu || []);

  const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ['desktop', Infinity],
    ['tablet', 1024],
    ['mobile', 768],
  ];
  useBreakpoints(stylesRef, breakpoints);

  const getUserInfo = async signal => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: accountsEndpoints.userData.myAccount,
        signal: signal,
      });
      const data = response.data.data.user;

      //If user has company, add links to menu
      if (data.company) {
        setMenuOptions(prev => [
          ...prev,
          {
            title: 'Otrzymane aplikacje',
            icon: 'copy',
            url: '/account/received-job-applications',
          },
        ]);
      }
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    getUserInfo(signal);

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <section ref={x => setStylesRef(x)}>
      <div className={styles.container}>
        <Submenu classNames={styles.nav} menu={menuOptions || menu} />
        <div className={styles.content}>
          <Switch>
            <Route exact path='/account/dashboard'>
              <Dashboard />
            </Route>
            <Route exact path='/account/cv'>
              <CvForm />
            </Route>
            <Route exact path='/account/my-job-applications'>
              <MyApplications />
            </Route>
            <Route exact path='/account/my-job-applications/:id'>
              <ApplicationDetails />
            </Route>
            <Route exact path='/account/received-job-applications'>
              <ReceivedApplications />
            </Route>
            <Route exact path='/account/received-job-applications/:id'>
              <ApplicationDetails recruiter />
            </Route>
          </Switch>
        </div>
      </div>
    </section>
  );
};

export default Account;
