export class SortOptionName {
  // static NAME = 'Nazwa (A-Z)';
  // static NAME_DESC = 'Nazwa (Z-A)';
  static LATEST = 'Najnowsze';
  static ELDEST = 'Najstarsze';
  static STATUS = 'Status';
  static VIEWS_DESC = 'Wyświetlenia (malejąco)';
  static VIEWS = 'Wyświetlenia (rosnąco)';
  static LEADS_DESC = 'Lead-y (malejąco)';
  static LEADS = 'Lead-y (rosnąco)';
  static LIKES_DESC = 'Polubienia (malejąco)';
  static LIKES = 'Polubienia (rosnąco)';
  static RATING_DESC = 'Ocena (malejąco)';
  static RATING = 'Ocena (rosnąco)';
}

export class SortOptionValue {
  // static NAME = 'name';
  // static NAME_DESC = 'name_desc';
  static LATEST = '-created_at';
  static ELDEST = 'created_at';
  static STATUS_DESC = '-status';
  static STATUS = 'status';
  static VIEWS_DESC = '-views';
  static VIEWS = 'views';
  static LEADS_DESC = '-lead';
  static LEADS = 'lead';
  static LIKES_DESC = '-likes';
  static LIKES = 'likes';
  static RATING_DESC = '-rating_avg';
  static RATING = 'rating_avg';
}

export class SortOptionNameCodes {
  static LATEST = 'Najnowsze';
  static ELDEST = 'Najstarsze';
  static NAME = 'Alfabetycznie (A-Z)';
  static NAME_DESC = 'Alfabetycznie (Z-A)';
  static ABSOLUTE = 'Rosnące wartości liczbowe';
  static ABSOLUTE_DESC = 'Malejące wartości liczbowe';
  static PERCENTAGE = 'Rosnące wartości %';
  static PERCENTAGE_DESC = 'Malejące wartości %';
}

export class SortOptionValueCodes {
  static LATEST = '-created_at';
  static ELDEST = 'created_at';
  static NAME = 'alpha';
  static NAME_DESC = '-alpha';
  static ABSOLUTE = 'absolute';
  static ABSOLUTE_DESC = '-absolute';
  static PERCENTAGE = 'percentage';
  static PERCENTAGE_DESC = '-percentage';
}

export class FilterOptionName {
  static POSTS = 'Posty';
  static COURSES = 'Kursy';
  static JOBS = 'Oferty pracy';
  static PUBLISHED = 'Opublikowane';
  static SCHEDULED = 'Zaplanowane';
  static DRAFTS = 'Szkice';
  static ALL = 'Wszystko';
}

export class FilterOptionValue {
  static POSTS = 'posts';
  static COURSES = 'courses';
  static JOBS = 'jobs';
  static PUBLISHED = 'published';
  static SCHEDULED = 'scheduled';
  static DRAFTS = 'draft';
  static ALL = 'all';
}

export class FilterOptionJZWIT 
{
  static ALL = 'Wszystko'
  static JUNIOR = 'Junior';
  static MID = 'Mid';
  static SENIOR = 'Senior';
  static SAVE = 'Zapisz'
}
