import styles from './Pins.module.sass';
import Loader from 'components/MiniPost/Loader';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import MiniPost from 'components/MiniPost';
import MiniCourse from 'components/MiniCourse';
import MiniJob from 'components/MiniJob';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import { pageSize } from 'data/dataArrays';
import GroupContext from 'context/group-context';
import PaginationGrowingFooter from 'components/PaginationGrowingFooter';
import useAuth from 'hooks/useAuth';
import PinsCategories from './PinsCategories';
import Pagination from 'components/Pagination';

export default function Pins({ userId }) {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(false);

  const [pins, setPins] = useState([]);
  const [pinsCount, setPinsCount] = useState(0);

  const groupCtx = useContext(GroupContext);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const isGroupFetched = useRef(false);
  const groupNameChange = useRef(0);

  // PAGINATION----------------------------------------------------------------PAGINATION

  const [currentPage, setCurrentPage] = useState(1);

  // SCROLL TOP ON PAGE CHANGE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // PAGINATION----------------------------------------------------------------PAGINATION

  const handleClearPinsData = () => {
    setPins([]);
    setPinsCount(0);
    setCurrentPage(1);
  };

  useEffect(() => {
    groupNameChange.current = groupNameChange.current + 1;
    handleClearPinsData();
  }, [selectedGroup]);

  useEffect(() => {
    if (isGroupFetched.current) {
      getPins(groupNameChange.current);
    }
    // fetching group only once
    if (!isGroupFetched.current) {
      (async () => {
        await getAllGroups();
        isGroupFetched.current = true;
        getPins(groupNameChange.current);
      })();
    }
  }, [
    groupCtx.groupHandler,
    groupCtx.deleteHandler,
    currentPage,
    selectedGroup,
  ]);

  const getAllGroups = async () => {
    try {
      const { data } = await axiosInstance.get(`/accounts/me/managegroup/`, {
        headers: {
          'USER-ID': userId,
        },
      });
      setGroups(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPins = async passedNameChange => {
    try {
      setIsLoading(true);

      const selectedGroupName = selectedGroup
        ? `groupnames=${selectedGroup}&`
        : '';

      const { data } = await axiosInstance.get(
        `/accounts/user-pins/?${selectedGroupName}page=${currentPage}&page_size=${pageSize}`,
        {
          headers: {
            METHOD: 'pins',
            'USER-ID': userId,
          },
        }
      );

      // it checks if you switched card to prevent filling with wrong data
      if (passedNameChange !== groupNameChange.current) return;

      const results = data?.results || [];

      setPinsCount(data?.count || 0);
      setPins(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = name => {
    setSelectedGroup(name);
  };

  return (
    <div className={styles.container}>
      <PinsCategories
        data={groups}
        selectedCategory={selectedGroup}
        onCategoryChange={handleCategoryChange}
      />
      {!isLoading && (
        <>
          {pinsCount > 0 &&
            pins.map((pin, index) => (
              <li key={index} className={styles.pinElement}>
                {pin.type === 'post' && (
                  <MiniPost
                    key={pin.object.id}
                    article={pin.object}
                    className={styles.article}
                  />
                )}
                {pin.type === 'course' && (
                  <MiniCourse
                    key={pin.object.id}
                    article={pin.object}
                    classNames={styles.article}
                  />
                )}
                {pin.type === 'job' && (
                  <MiniJob
                    className={styles.margin_bottom}
                    key={pin.object.id}
                    article={pin.object}
                  />
                )}
              </li>
            ))}
        </>
      )}
      {pins?.length === 0 && !isLoading && (
        <p className={styles.isEmpty}>
          Użytkownik nie ma jeszcze żadnych przypinek.
        </p>
      )}
      {isLoading && <Loader cards={pageSize * currentPage} />}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={pinsCount}
        onPageChange={page => setCurrentPage(page)}
      />
    </div>
  );
}
