import React from 'react';
import styles from './Item.module.sass';
import cn from 'classnames';
import { colorRelatedToBackground } from 'utils';
import Icon from 'components/Icon';
import ProgressBar from 'components/ProgressBar';
import { jobLevel, jobSortingOptions } from 'data/dataArrays';
import { Link } from 'react-router-dom';

const Item = ({
  classNames,
  name,
  level = 0,
  color = '#B1E5FC',
  link = true,
}) => {
  return (
    <div className={cn(styles.container, classNames && classNames)}>
      <ProgressBar
        classNames={styles.progress}
        barColor={color}
        currentProgress={level || 0}
        maxProgress={jobLevel.length - 1}
        height={15}
      />
      <div className={styles.content}>
        {link ? (
          <Link
            to={`/jobs?sortby=${jobSortingOptions[0].value}&tags=${name}`}
            className={styles.name}
          >
            {name}
          </Link>
        ) : (
          <span className={styles.name}>{name}</span>
        )}

        <div className={styles.level}>
          {jobLevel[level]} ({level}/{jobLevel.length - 1})
        </div>
      </div>
    </div>
  );
};

export default Item;
