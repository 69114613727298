import React from 'react';
import styles from './LinksList.module.sass';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import cn from 'classnames';

const LinksList = ({ className, links = [] }) => {
  const hasLinks = links.length > 0;

  if (!hasLinks) return null;

  return (
    <div className={cn(styles.container, className && className)}>
      <div className={cn('title-purple', styles.title)}>Linki</div>
      <ul className={styles.links}>
        {links?.map((item, i) => (
          <a
            href={item.url}
            className={styles.link}
            key={i}
            target='_blank'
            rel='noreferrer'
          >
            <OpenInNewIcon className={styles.icon} />
            {item.description}
          </a>
        ))}
      </ul>
    </div>
  );
};

export default LinksList;
