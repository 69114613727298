import React, { useState, useEffect } from 'react';
import styles from './MiniCourse.module.sass';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../Icon';
// import AuthContext from '../../context/AuthContext';
// import useAxiosFunction from '../../hooks/useAxiosFunction';
import ModalPin from '../ModalPin';
// import devscntrAuth from '../../api/Instance/devscntrAuth';
// import Actions from 'components/Actions';
import { showLoginModal } from 'utils/showLoginModal';
import Reactions from 'components/MiniItemsLayout/Reactions';
import MoreOptions from 'components/MiniItemsLayout/MoreOptions';
import Thumbnail from 'components/MiniItemsLayout/Thumbnail';
import Tags from 'components/MiniItemsLayout/Tags';
import Categories from 'components/MiniItemsLayout/Categories';
import Author from 'components/MiniItemsLayout/Author';
import {
  courseDifficulty,
  courseFormat,
  courseLanguage,
  currencyList,
} from 'data/dataArrays';
import useAuth from 'hooks/useAuth';
import useBreakpoints from 'hooks/useBreakpoints';
import relatedStyles from './RelatedMiniCourse.module.sass';

const currencyCode = currencyList[0].code;
const format = [...courseFormat];
const difficulty = [...courseDifficulty];

const MiniCourse = React.forwardRef(
  ({ article, related, classNames, relatedStyle }, ref) => {
    const authCtx = useAuth();
    const [hidden, setHidden] = useState(false);
    const location = useLocation();

    const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
    const breakpoints = [
      ['desktop', Infinity],
      ['mobile', 716],
    ];
    useBreakpoints(stylesRef, breakpoints);

    // -----------------------------------------------------
    // <----- !!!! PRZYPINKI !!!! ----->
    // -----------------------------------------------------
    const [pinState, setPinState] = useState(article?.pinned);
    const [StarModalVisible, setStarModalVisible] = useState();
    const [redirectedUser, setRedirectedUser] = useState();
    const [unpinPost, setunpinPost] = useState();
    const pinsPath = location.pathname.slice(0, 5);

    const pinHandler = () => {
      if (!authCtx.isLoggedIn) {
        showLoginModal();
      }
      if (authCtx.isLoggedIn) {
        setStarModalVisible(true);
      }
    };

    const unpinHandler = () => {
      if (!authCtx.isLoggedIn) {
        showLoginModal();
      }

      if (pinsPath === '/pins') {
        setunpinPost(true);
      } else {
        setRedirectedUser(true);
      }
    };

    //AVERAGE EARNINGS
    function averageSalaryFormat(salary) {
      return salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    // console.log('ARTICLE', article)

    let language = '';
    if (article?.language) {
      if (courseLanguage.includes(article?.language?.toUpperCase()))
        language = article?.language?.toUpperCase();
      else if (courseLanguage.indexOf(article?.language))
        language = courseLanguage[article?.language - 1];
    }

    let languageFlagPath;
    switch (language) {
      case 'PL':
        languageFlagPath = '/images/Icons/flags/poland.png';
        break;
      case 'ENG':
        languageFlagPath = '/images/Icons/flags/united-kingdom.png';
        break;
      default:
        languageFlagPath = '/images/Icons/flags/poland.png';
        break;
    }

    return (
      article &&
      !hidden && (
        <article
          className={related && styles.article_auto_width}
          ref={x => setStylesRef(x)}
        >
          <div
            className={cn(
              classNames && classNames,
              related ? relatedStyles.item : styles.item,
            )}
            ref={ref}
          >
            {(StarModalVisible || redirectedUser || unpinPost) && (
              <ModalPin
                visible={StarModalVisible}
                onClose={() => setStarModalVisible(false)}
                article={article}
                setPinState={setPinState}
                redirectedUser={redirectedUser}
                setRedirectedUser={setRedirectedUser}
                unpinPost={unpinPost}
                setunpinPost={setunpinPost}
                pinUrl={'/course/user-course/'}
                idName={'course_id'}
                path={pinsPath}
              />
            )}

            <Link
              to={`/course/${article.slug}`}
              className={styles.imagecontainer}
            >
              <Thumbnail
                className={!relatedStyle ? styles.image : relatedStyles.image}
                image={article?.image}
              />
              <div className={styles.views}>
                <Icon name='eye' size='24' viewBox='0 0 24 24' />
                {article.views ? article.views : '-'}
              </div>
            </Link>

            <section className={styles.details}>
              <div className={styles.head}>
                <Author
                  id={article?.author}
                  username={article?.display_name}
                  image={article?.author_image}
                  level={article?.xp_pkg}
                  isRss={article?.is_rss}
                />
                {article?.categories?.length > 0 && (
                  <Categories
                    categories={article?.categories?.sort(
                      (a, b) => b.tags_count - a.tags_count
                    )}
                    baseLink='/'
                  />
                )}
                {!related && (
                  <div
                    className={
                      !relatedStyle ? styles.actions : relatedStyles.actions
                    }
                  >
                    <button
                      className={styles.pin_btn}
                      onClick={pinState ? unpinHandler : pinHandler}
                    >
                      <img
                        src={
                          pinState
                            ? '/images/Icons/heart/filled-color.svg'
                            : '/images/Icons/heart/stroke.svg'
                        }
                        alt='favourite'
                      />
                    </button>

                    <MoreOptions
                      item={article}
                      authorId={article.author}
                      type='course'
                      onDelete={() => setHidden(true)}
                    />
                  </div>
                )}
              </div>

              {!related ? (
                <div>
                  <Link
                    to={`/course/${article.slug}`}
                    style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    <h2 className={styles.title}>
                      {article?.title || 'Brak tytułu'}
                    </h2>
                  </Link>

                  <div className={styles.content}>
                    <div className={styles.price}>Cena:</div>
                    <div className={styles.price_detail}>
                      {article?.specialprice > 0 ? (
                        <>
                          <s>{article.price}</s>{' '}
                          {`${article.specialprice} ${currencyCode}`}
                        </>
                      ) : article?.price > 0 ? (
                        `${article.price} ${currencyCode}`
                      ) : (
                        'za darmo'
                      )}
                    </div>
                  </div>

                  <div className={styles.specifics}>
                    {article?.mean_earnings > 0 && (
                      <div className={styles.specifics_item}>
                        <Icon name='bar_chart' size={17} />
                        <div className={styles.specifics_item_salary}>Śr. zarobki</div>
                        {averageSalaryFormat(article?.mean_earnings)} PLN
                      </div>
                    )}

                    {parseFloat(article?.duration) > 1 && (
                      <div className={styles.specifics_item}>
                        <Icon name='clock' size={18} />
                        {article?.duration} h
                      </div>
                    )}

                    {article?.format && (
                      <div className={styles.specifics_item}>
                        <Icon name='repeat' size={18} />
                        {format[article?.format - 1]}
                      </div>
                    )}

                    {article?.difficulty !== '0' && (
                      <div className={styles.specifics_item}>
                        <Icon name='leaderboard' size={18} />
                        {difficulty[article?.difficulty - 1]}
                      </div>
                    )}

                    {article?.language && (
                      <div className={styles.specifics_item}>
                        <img
                          src={languageFlagPath}
                          className={styles.language_flag}
                          alt={language || ''}
                          height='16'
                        />
                        {language}
                      </div>
                    )}
                  </div>
                </div>
              ) :
                (
                  <div>
                    <Link
                      to={`/course/${article.slug}`}
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      <h2 className={styles.title}>
                        {article?.title || 'Brak tytułu'}
                      </h2>
                    </Link>
                    <div className={styles.content}>
                      {article?.specialprice ? (
                        <>
                          <s>{article.price}</s>{' '}
                          {`${article.specialprice} ${currencyCode}`}
                        </>
                      ) : article?.price > 0 ? (
                        `${article.price} ${currencyCode}`
                      ) : (
                        'za darmo'
                      )}
                    </div>
                  </div>
                )}
              {!related && (
                <div className={!relatedStyle ? styles.foot : relatedStyles.foot}>
                  <Reactions
                    className={styles.control}
                    type='course'
                    id={article?.slug}
                    comments={article?.comments_count}
                    rating={article?.rating_avg}
                    rated={article?.user_rating > 0}
                    currentReaction={
                      article?.emotion_of_user?.length > 0
                        ? article?.emotion_of_user[0]?.emotion
                        : 0
                    }
                    likes={article?.emotions_likes_count}
                    dislikes={article?.emotions_dislikes_count}
                    commentsLink={`/course/${article?.slug}#comments`}
                    ratingLink={`/course/${article?.slug}#rating`}
                  />
                  {!related && (
                    <div className={styles.time}>
                      {article?.last_content_update?.slice(0, 10)}
                    </div>
                  )}
                </div>
              )}
            </section>
          </div>
        </article>
      )
    );
  }
);
export default MiniCourse;
