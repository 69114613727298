import React, { useState } from 'react';
import styles from './Salary.module.sass';
import cn from 'classnames';
import Card from '../../../components/Card';
import Icon from '../../../components/Icon';
import { Link } from 'react-router-dom';
import Dropdown from '../../../components/Dropdown';
import TextInput from '../../../components/TextInput';
import Checkbox from '../../../components/Checkbox';
import { jobContractType, currencyList } from '../../../data/dataArrays';
import { validateInput } from '../../../utils/validation';
import { showError } from '../../../utils/showError';

const currencyCode = currencyList[0].code;

const Salary = ({ data, setData }) => {
  const [contractType, setContractType] = useState(jobContractType[0]);
  const [salaryData, setSalaryData] = useState({
    salary_from: '',
    salary_to: '',
    hour_wage: '',
  });

  const handleDelete = index => {
    setData(data.filter((item, i) => i !== index));
  };

  const checkFields = () => {
    if (jobContractType.indexOf(contractType) === 0) {
      if (!validateInput(salaryData.hour_wage, 20, 1, 'stawka godzinowa', 3))
        return false;
      if (isNaN(salaryData.hour_wage)) {
        showError('Wartość pola musi być liczbą');
        return false;
      }
    }
    if (jobContractType.indexOf(contractType) !== 0) {
      if (!validateInput(salaryData.salary_from, 20, 1, 'od', 3)) return false;
      if (!validateInput(salaryData.salary_to, 20, 1, 'do', 3)) return false;
      if (
        parseFloat(salaryData.salary_from) > parseFloat(salaryData.salary_to)
      ) {
        showError(
          'Wartość pola "od" nie może być niższa od wartości pola "do"'
        );
        return false;
      }
    }

    return true;
  };

  const getFloatNumber = number => {
    return parseFloat(number.replaceAll(',', '.')).toFixed(2);
  };

  const handleClick = () => {
    if (checkFields()) {
      // Check if type already exists
      let index;
      data.forEach((item, i) => {
        if (item.type === jobContractType.indexOf(contractType) + 1) {
          index = i;
        }
      });

      if (index === undefined) {
        setData(prev => [
          ...prev,
          {
            type: parseInt(jobContractType.indexOf(contractType) + 1),
            salary_from: getFloatNumber(salaryData.salary_from || '0'),
            salary_to: getFloatNumber(salaryData.salary_to || '0'),
            hour_wage: getFloatNumber(salaryData.hour_wage || '0'),
          },
        ]);
      } else {
        handleDelete(index);
        setData(prev => [
          ...prev,
          {
            type: jobContractType.indexOf(contractType) + 1,
            salary_from: getFloatNumber(salaryData.salary_from || '0'),
            salary_to: getFloatNumber(salaryData.salary_to || '0'),
            hour_wage: getFloatNumber(salaryData.hour_wage || '0'),
          },
        ]);
      }
    }

    console.log(salaryData);
  };

  return (
    <Card
      className={cn(styles.card)}
      title='Typ umowy i wynagrodzenie'
      classTitle='title-blue'
    >
      <ul className={styles.list}>
        {data.map((x, index) => (
          <li className={styles.item} key={index}>
            <div className={styles.item_data}>
              <span className={styles.type}>{jobContractType[x.type - 1]}</span>
              {x.type - 1 === 0 ? (
                <span>
                  {x.hour_wage || '0,00'} {currencyCode}/
                  <span style={{ textTransform: 'lowercase' }}>h</span>
                </span>
              ) : (
                <span>
                  {x.salary_from || '0,00'} {currencyCode} -{' '}
                  {x.salary_to || '0,00'} {currencyCode}
                </span>
              )}
            </div>
            <div className={styles.btns}>
              <button
                className={styles.delete}
                onClick={() => handleDelete(index)}
              >
                <Icon name='close' size={24} />
              </button>
            </div>
          </li>
        ))}
      </ul>

      <div className={styles.details}>
        <div className={styles.col}>
          <Dropdown
            label='Typ umowy'
            value={contractType}
            setValue={setContractType}
            options={jobContractType}
            classDropdownHead='Typ umowy'
          />
        </div>

        <div className={styles.col}>
          {jobContractType.indexOf(contractType) !== 0 && (
            <div className={styles.row}>
              <div className={styles.col}>
                <TextInput
                  label='od'
                  value={salaryData.salary_from}
                  currency={currencyCode}
                  onChange={e =>
                    setSalaryData(prev => ({
                      ...prev,
                      salary_from: e.target.value,
                    }))
                  }
                />
              </div>
              <div className={styles.col}>
                <TextInput
                  label='do'
                  value={salaryData.salary_to}
                  currency={currencyCode}
                  onChange={e =>
                    setSalaryData(prev => ({
                      ...prev,
                      salary_to: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          )}
          {jobContractType.indexOf(contractType) === 0 && (
            <div className={styles.col}>
              <TextInput
                icon='payment'
                label='Stawka godzinowa'
                placeholder='Stawka godzinowa'
                value={salaryData.hour_wage}
                onChange={e =>
                  setSalaryData(prev => ({
                    ...prev,
                    hour_wage: e.target.value,
                  }))
                }
              />
            </div>
          )}
        </div>

        <button className={cn('button', styles.add_btn)} onClick={handleClick}>
          Dodaj wynagrodzenie
        </button>
      </div>
    </Card>
  );
};

export default Salary;
