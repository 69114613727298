import React, { useEffect, useState } from 'react';
import styles from './LevelProgress.module.sass';
import cn from 'classnames';

const LevelProgress = ({
  classNames,
  min = 0,
  max = 50,
  currentXp = 30,
  level = 3,
  rankName,
}) => {
  // UTILS--------------------------------------------------------------------------UTILS
  const [isMounted, setIsMounted] = useState(false);

  // UTILS--------------------------------------------------------------------------UTILS
  // CALCULATE PROGRESS------------------------------------------------CALCULATE PROGRESS

  const [progress, setProgress] = useState(currentXp / max);

  const calculateProgress = (currentXp, max) => {
    if (currentXp > max) {
      setProgress(100);
    } else {
      setProgress((currentXp / max) * 100);
    }
  };

  useEffect(() => {
    calculateProgress(currentXp, max);
  }, [currentXp, min, max]);

  console.log(progress)

  // CALCULATE PROGRESS------------------------------------------------CALCULATE PROGRESS

  return (
    <div className={cn(styles.container, classNames && classNames)}>
      <div className={styles.progress}>
        {currentXp} / {currentXp > max ? 'max' : max} XP
      </div>
      <div className={styles.bar}>
        <div
          className={cn(styles.bar_fill)}
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default LevelProgress;
