import React from "react";
import style from "./ProfileLoader.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { StylesProvider } from "@material-ui/core";

const ProfileLoader = ({ cards }) => {
    return Array(cards)
        .fill(0)
        .map((_, i) => (
            <SkeletonTheme key={i}>
                <div className={style.skeleton} key={i}>
                    <div className={style.section}>
                        <div className={style.contentTech}>
                            <Skeleton width={'100%'} height={148} className={style.text} />
                        </div>
                    </div>
                </div>
            </SkeletonTheme>
        ));
};

export default ProfileLoader;