import React from 'react';
import styles from './PerformanceByDay.module.sass';
import cn from 'classnames';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import useDarkMode from 'use-dark-mode';
import Card from 'components/Card';

const TechnologyChart = ({ className, title, subtitle, data }) => {
  const darkMode = useDarkMode(false);

  const graphData = [];
  const graphDataX = data.chart_data.x
  const graphDataY = data.chart_data.y

  const dataLength = graphDataY.length

  for (let i = 0; i < dataLength; i++) {
    let d = {
      year: graphDataX[i],
      value: graphDataY[i]
    };

    graphData.push(d);
  }

  return (
    <Card
      className={cn(styles.card, className)}
      classCardHead={styles.head}
      classTitle='title-green'
      title={title}
      subtitle={subtitle}
    >
      <div className={styles.chart}>
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            width={500}
            height={300}
            data={graphData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              strokeDasharray='none'
              stroke={darkMode.value ? '#272B30' : '#EFEFEF'}
              vertical={false}
            />
            <XAxis
              dataKey='year'
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: '500', fill: '#9A9FA5' }}
              padding={{ left: 10 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: '500', fill: '#9A9FA5' }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#272B30',
                borderColor: 'rgba(255, 255, 255, 0.12)',
                borderRadius: 8,
                boxShadow:
                  '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000',
              }}
              labelStyle={{ fontSize: 12, fontWeight: '500', color: '#fff' }}
              itemStyle={{
                padding: 0,
                textTransform: 'capitalize',
                fontSize: 12,
                fontWeight: '600',
                color: '#fff',
              }}
            />
            <Line
              type='monotone'
              dataKey='value'
              dot={false}
              strokeWidth={4}
              stroke='#2A85FF'
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default TechnologyChart;
