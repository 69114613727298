import React from 'react';
import styles from './Tags.module.sass';
import cn from 'classnames';
import uuid from 'react-uuid';
import Tag from 'components/Tags/Tag';
import { useHistory } from 'react-router';
import HorizontalScroll from 'components/HorizontalScroll';

const Tags = ({ className, tags, baseLink = null }) => {
  const history = useHistory();
  
  return (
    <HorizontalScroll
      className={cn(styles.tags, className && className)}
      controls
      controlsClassName={styles.scroll_controls}
    >
      {tags?.map(item => (
        <Tag
          classNames={styles.item}
          key={uuid()}
          name={item?.name || 'Brak nazwy'}
          color={item?.colour || '#ffffff'}
          onClick={() => {
            baseLink && history.push(`${baseLink}?tags=${item?.name}`);
          }}
        />
      ))}
    </HorizontalScroll>
  );
};

export default Tags;
