import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';
import styles from './SignIn.module.sass';
import { use100vh } from 'react-div-100vh';
import { Link } from 'react-router-dom';
import TextInput from '../../components/TextInput';
import Image from '../../components/Image';
import Icon from '../../components/Icon';

import useAuth from '../../hooks/useAuth';
import devscntrNoauth from '../../api/Instance/devscntrNoauth';
import GoogleLogin from 'components/Auth/GoogleLogin';
import GithubLogin from 'components/Auth/GithubLogin';
import DiscordLogin from 'components/Auth/DiscordLogin';
import Button from 'components/Button';
import { accountsEndpoints } from 'api/endpoints';
import { showSuccess } from 'utils/showSuccess';
import { showError } from 'utils/showError';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const LOGIN_URL = 'accounts/login';

// SignIn component
const SignIn = () => {
  // Authorization context
  const authCtx = useAuth();
  const location = useLocation();
  const history = useHistory();
  const heightWindow = use100vh();

  const [isLoading, setIsLoading] = useState(false);

  // Manage inputs, errors
  const [errMsg, setErrMsg] = useState('');
  const [loginData, setLoginData] = useState({
    email: '',
    passwd: '',
  });

  // LOGIN---------------------------------------------------------------------------------------LOGIN

  // Send login data to API, handle response
  const loginVerification = async () => {
    setIsLoading(true);
    try {
      // Send data
      const response = await devscntrNoauth.post(
        LOGIN_URL,
        JSON.stringify({ email: loginData.email, password: loginData.passwd })
      );

      // Clear login inputs
      setLoginData(prev => ({
        ...prev,
        email: '',
        passwd: '',
      }));

      const expirationTime = new Date(new Date().getTime() + 86300 * 1000);
      const from = location?.state?.from;

      // Login user
      authCtx.login(
        response.data.access,
        expirationTime.toISOString(),
        history.push(from || '/')
      );
      if (from);
    } catch (error) {
      // if (!error?.response) {
      //   setErrMsg("Brak odpowiedzi");
      // } else if (error.response?.status === 400) {
      //   setErrMsg("Uzupełnij pola");
      // } else if (error.response?.status === 401) {
      //   setErrMsg("Nieprawidłowy email lub hasło");
      // } else {
      //   setErrMsg("Wystąpił błąd serwera");
      // }
      setErrMsg('Nieprawidłowy email lub hasło');
      if (!error?.response) {
        setErrMsg('Brak odpowiedzi serwera');
      } else if (
        error?.response?.data[0]?.detail[0] === 'Email is not verified'
      ) {
        setErrMsg(
          <>
            <p>Konto nie zostało jeszcze aktywowane. </p>
            <button
              className={styles.info_link}
              onClick={resendEmailVerification}
            >
              Wyślij link aktywacyjny ponownie
            </button>
          </>
        );
      } else {
        setErrMsg('Nieprawidłowy email lub hasło');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // LOGIN---------------------------------------------------------------------------------------LOGIN
  // GOOGLE RECAPTCHA-----------------------------------------------------------------GOOGLE RECAPTCHA

  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const recaptchaRef = useRef();
  // const [recaptchaToken, setRecaptchaToken] = useState(null);

  // const handleReCaptchaVerify = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available');
  //     return;
  //   }
  //   const token = await executeRecaptcha('yourAction');
  //   recaptchaRef.current = token;
  //   setRecaptchaToken(token);
  // }, [executeRecaptcha]);

  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  // GOOGLE RECAPTCHA-----------------------------------------------------------------GOOGLE RECAPTCHA
  // RESEND EMAIL VERIFICATION-----------------------------------------------RESEND EMAIL VERIFICATION

  const resendEmailVerification = async () => {
    try {
      const response = await devscntrNoauth.post(
        accountsEndpoints.auth.registerResendActivation,
        { email: loginData.email }
      );
      showSuccess('Wysłaliśmy link aktywacyjny. Sprawdź swoja skrzynkę email.');
    } catch (err) {
      console.log(err);
      showError(
        'Wystąpił błąd podczas wysyłania linku aktywacyjnego. Spróbuj ponownie później.'
      );
    }
  };

  // RESEND EMAIL VERIFICATION-----------------------------------------------RESEND EMAIL VERIFICATION
  // EVENT HANDLERS---------------------------------------------------------------------EVENT HANDLERS

  // Handle changes in inputs, set values in registerData every change
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setLoginData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleKeyDown = e => {
    if (e.key !== 'Enter') return;
    loginVerification();
  };

  // Handle submit, login
  const handleSubmit = async e => {
    e.preventDefault();

    // Validate inputs
    const allowedCharacters = /[a-zA-Z0-9\p{L}!@#$%\^&*\(\)+,.?\-]/g;
    if (loginData.email.length === 0 || loginData.passwd.length === 0) {
      setErrMsg('Wypełnij pola');
      return;
    }
    if (
      !allowedCharacters.test(loginData.email) ||
      !allowedCharacters.test(loginData.passwd)
    ) {
      setErrMsg('Wykryto niedozwolone znaki');
      return;
    }
    setErrMsg('');
    setLoginData(prev => ({
      ...prev,
      passwd: '',
    }));
    // Try to login
    loginVerification();
  };

  // EVENT HANDLERS---------------------------------------------------------------------EVENT HANDLERS

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to='/'>
          <Image
            className={styles.pic}
            src='/images/logo-light.svg'
            srcDark='/images/logo-dark.svg'
            alt='itCNTR'
          />
        </Link>
        <div className={cn('h2', styles.title)}>Zaloguj się</div>
        <div className={styles.head}>
          <div className={styles.subtitle}>
            Zaloguj się przez konto zewnętrzne
          </div>
          <div className={styles.btns}>
            {/* <button
              className={cn('button-stroke', styles.button_account)}
              onClick={googleAccount}
            >
              <img src='/images/content/google.svg' alt='Google' />
              Google
            </button> */}

            <GoogleLogin classNames={styles.button_account} />
            {/* <GithubLogin classNames={styles.button_account} /> */}
            {/* <DiscordLogin classNames={styles.button_account} /> */}

            {/* <button className={cn('button-stroke', styles.button_account)}>
              <Image
                className={styles.pic}
                src='/images/content/apple-dark.svg'
                srcDark='/images/content/apple-light.svg'
                alt='Apple'
              />
              Apple ID
            </button> */}
          </div>
        </div>
        <div className={styles.body}>
          {/* <div className={styles.subtitle}>Lub kontynuuj z adresem email</div> */}

          {errMsg && (
            <div className={styles.error}>
              <Icon name='info' size='24' />
              {errMsg}
            </div>
          )}

          <TextInput
            className={styles.field}
            name='email'
            type='email'
            placeholder='Twój email'
            required
            icon='mail'
            onChange={handleChange}
            value={loginData.email}
            onKeyDown={handleKeyDown}
          />
          <TextInput
            className={styles.field}
            name='passwd'
            type='password'
            placeholder='Hasło'
            required
            icon='lock'
            onChange={handleChange}
            value={loginData.passwd}
            onKeyDown={handleKeyDown}
          />
          <Button
            isLoading={isLoading}
            classNames={styles.button}
            onClick={handleSubmit}
            title={'Zaloguj się'}
          >
            Zaloguj się
          </Button>
          {/* <button
            className={cn('button', isLoading && 'disabled', styles.button)}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            <Loader
              className={cn(styles.loader, isLoading && styles.visible)}
            />
            Zaloguj się
          </button> */}
          {/* <div className={styles.note}>
            Ta strona jest zabezpieczona przez reCAPTCHA i Politykę Prywatności
            Google
          </div> */}

          <div className={styles.links}>
            <div className={styles.info}>
              Nie masz jeszcze konta?{' '}
              <Link className={styles.link} to='/sign-up'>
                Zarejestruj się
              </Link>
            </div>
            <Link className={styles.info_link} to='/account/password/reset'>
              Nie pamiętasz hasła?
            </Link>
          </div>

          <Link
            to='/'
            className={cn('button-stroke', styles.button, styles.guest)}
          >
            Kontynuuj jako gość
          </Link>

          <div className={styles.note} style={{ textAlign: 'center' }}>
            This site is protected by reCAPTCHA and the Google{' '}
            <a href='https://policies.google.com/privacy'>Privacy Policy</a> and{' '}
            <a href='https://policies.google.com/terms'>Terms of Service</a>{' '}
            apply.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
