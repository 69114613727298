import React, { useState } from 'react';
// import styles from './MiniJob.module.sass';
// import useAuth from 'hooks/useAuth';
import useBreakpoints from 'hooks/useBreakpoints';
// import { useLocation } from 'react-router';
// import { showLoginModal } from 'utils/showLoginModal';
import Item from './Item';
import ItemRestricted from './ItemRestricted';
import ItemRecommended from './ItemRecommended';

const MiniJob = React.forwardRef(
  ({ className, article, recommended = false, hideActions = false }, ref) => {
    // const authCtx = useAuth();
    const [hidden, setHidden] = useState(false);

    const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
    const breakpoints = [
      ['desktop', Infinity],
      ['mobile', 716],
      ['tiny', 300],
    ];
    useBreakpoints(stylesRef, breakpoints);

    const onDelete = () => {
      setHidden(true);
    };

    return (
      article &&
      !hidden && (
        <article ref={ref}>
          {recommended ? (
            <ItemRecommended
              className={className}
              article={article}
              onDelete={onDelete}
              recommended={recommended}
            />
          ) : (
            <>
              {article?.is_rss ? (
                <ItemRestricted
                  className={className}
                  article={article}
                  onDelete={onDelete}
                  hideActions={hideActions}
                />
              ) : (
                <Item
                  className={className}
                  article={article}
                  onDelete={onDelete}
                  hideActions={hideActions}
                />
              )}
            </>
          )}
        </article>
      )
    );
  }
);
export default MiniJob;
