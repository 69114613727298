import React, { useState } from 'react';
import cn from 'classnames';
import styles from './ModalShare.module.sass';
import Modal from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { removeShareModal } from 'store/shareModal/actions';
import Icon from 'components/Icon';

const socials = [
  {
    icon: 'facebook',
    url: 'https://www.facebook.com/',
  },
  {
    icon: 'twitter',
    url: 'https://twitter.com/',
  },
  {
    icon: 'linkedin',
    url: 'https://www.linkedin.com/',
  },
];

const ModalShare = () => {
  const { visible, url, image } = useSelector(state => state.shareModal);
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);

  const handleClose = () => {
    dispatch(removeShareModal());
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  return (
    <Modal visible={visible} onClose={handleClose}>
      <div className={styles.share}>
        <div className={styles.head}>
          <div className={cn('title-red', styles.title)}>Udostępnij</div>
        </div>
        <div className={styles.field}>
          <div className={styles.icon}>
            <Icon name='link' size='24' />
          </div>
          <input
            className={styles.input}
            type='text'
            name='site'
            value={url}
            readOnly
          />
          <button className={styles.copy} onClick={copyToClipboard}>
            {isCopied ? <Icon name='check' size={24} /> : 'Kopiuj'}
          </button>
        </div>
        {image && (
          <div className={styles.preview}>
            <img src={image} alt='Udostępnij' />
          </div>
        )}
        {/* <div className={styles.btns}>
          {socials.map((x, index) => (
            <a
              className={cn('button-stroke', styles.button)}
              href={x.url}
              target='_blank'
              rel='noopener noreferrer'
              key={index}
            >
              <Icon name={x.icon} size='24' />
            </a>
          ))}
        </div> */}
      </div>
    </Modal>
  );
};
export default ModalShare;
