import React from 'react';
import styles from './Details.module.sass';
import cn from 'classnames';
import {
  courseDifficulty,
  courseFormat,
  courseLanguage,
} from 'data/dataArrays';

const Details = ({ article, averageSalary }) => {
  let language = '';
  if (article?.language) {
    if (courseLanguage.includes(article?.language?.toUpperCase()))
      language = article?.language?.toUpperCase();
    else if (courseLanguage.indexOf(article?.language))
      language = courseLanguage[article?.language - 1];
  }

  //AVERAGE EARNINGS
  const numberWithSpaces = x => {
    try {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } catch (e) {
      return x;
    }
  };

  return (
    <div className={styles.container}>
      <div className={cn('title-green', styles.title)}>Szczegóły</div>
      <div className={styles.content}>
        <ul>
          <li>
            <b>Średnie zarobki:</b>{' '}
            {averageSalary
              ? `${numberWithSpaces(averageSalary)} PLN`
              : 'brak danych'}
          </li>

          <li className={styles.price}>
            <b>Cena:</b>{' '}
            {article.specialprice ? (
              <>
                <s>{article.price}zł</s> {article.specialprice}zł
              </>
            ) : article.price ? (
              `${article.price}zł`
            ) : (
              'za darmo'
            )}
          </li>

          <li>
            <b>Czas trwania:</b> {article?.duration && article.duration}h
          </li>

          <li>
            <b>Poziom:</b>{' '}
            {(article?.difficulty &&
              courseDifficulty[article.difficulty - 1]) ||
              'brak danych'}
          </li>

          <li>
            <b>Format:</b> {article?.format && courseFormat[article.format - 1]}
          </li>

          <li>
            <b>Język:</b> {article?.language && language}
          </li>

          <li>
            <b>Certyfikat ukończenia:</b> {article.certificate ? 'tak' : 'nie'}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Details;
