import React from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const TextInput = ({
  className,
  metaDescription,
  classLabel,
  classInput,
  label,
  icon,
  copy,
  currency,
  tooltip,
  place,
  onTitleChange,
  onDescriptionChange,
  titleValue,
  descriptionValue,
  error,
  ...props
}) => {
  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency != undefined },
        className,
        error === true && styles.error
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        {!metaDescription ?
          (
            <input
              className={cn(classInput, styles.input)}
              onChange={onTitleChange}
              maxLength={100}
              {...props}
            />
          )
          :
          (
            <textarea
              className={cn(styles.meta_description)}
              value={descriptionValue}
              onChange={onDescriptionChange}
              maxLength={metaDescription}
            />
          )}
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size="24" />{" "}
          </div>
        )}
        {copy && (
          <button className={styles.copy}>
            <Icon name="copy" size="24" />{" "}
          </button>
        )}
        {currency != undefined && (
          <div className={styles.currency}>{currency}</div>
        )}
      </div>
    </div>
  );
};

export default TextInput;
