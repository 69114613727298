import React, { useRef } from 'react';
import cn from 'classnames';

import style from './ProgressBar.module.sass';

const ProgressBar = ({ progress = 0, hidePercentages = false }) => {
  const barRef = useRef(null);

  return (
    <span
      className={cn(style.progressWrapper, { [style.small]: hidePercentages })}
    >
      <span
        className={style.progress}
        style={{ width: `${progress <= 100 ? progress : 100}%` }}
        ref={barRef}
      >
        {!hidePercentages && (
          <span
            className={cn(style.percentages, {
              [style.lowValue]: progress <= 10,
            })}
          >
            {progress <= 100 ? progress : 100}%
          </span>
        )}
      </span>
    </span>
  );
};

export default ProgressBar;
