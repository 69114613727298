import cn from "classnames";
import styles from "../List.module.sass";
import Actions from "../../../../components/Actions";
import Item from "./Item";
import Loader from "../../../../components/Loader";
import Card from "../../../../components/Card";
import { useState, useEffect } from "react";
import devscntrAuth from "../../../../api/Instance/devscntrAuth";
import Icon from "../../../../components/Icon";
import SkeletonLoader from "../SkeletonLoader";

const PostNotifications = ({className, notifications, loading, error}) => {
    const [ showMorePosts, setShowMorePosts ] = useState(false);
    const [ isAllRead, setIsAllRead ] = useState(false);
    const isNotifsFetched = !loading && !error && notifications != undefined;

    const allReadHandler = async () => {
        try {
            const response = await devscntrAuth.get(
                "/accounts/me/notification-delete/?category=post"
            );
            setIsAllRead(true)
        } catch (error) {
            console.log(error);
        }
    };

    const postNotificationsElements = isNotifsFetched && notifications.newPosts.length > 0
        ? notifications.newPosts.map((x, index) => {
            if (index <= 3 || showMorePosts) {
                return <Item
                    className={cn(styles.item, className)}
                    item={x}
                    type={"post"}
                    key={index}
                    isAllRead={isAllRead}
                />
            }
        })
        : error ? "Wystąpił błąd" : "Brak powiadomień"


    return (
        <Card
            className={cn(styles.card, className)}
            title="Nowe posty"
            classTitle={cn("title-red", styles.title)}
            classCardHead={styles.head}
        >
            <button
                className={cn("button-stroke", styles.setreadbtn)}
                onClick={allReadHandler}
            >
                <Icon name="check-all" size="24"/>
                <p>Oznacz jako przeczytane</p>
            </button>
            <div className={styles.notifications}>
                <div className={styles.list}>
                    {loading ? <SkeletonLoader cards={2} /> : postNotificationsElements}
                </div>
                {isNotifsFetched && notifications.newPosts.length > 4 &&
                    <div className={styles.foot}>
                        <button
                            className={cn("button-stroke button-small", styles.button)}
                            onClick={() => setShowMorePosts(true)}
                        >
                            {loading && <Loader className={styles.loader}/>}
                            <span>Load more</span>
                        </button>
                    </div>}
            </div>
        </Card>
    )
}

export default PostNotifications