import React, { useEffect, useState } from 'react';
import styles from './ProfileAvatar.module.sass';
import cn from 'classnames';

const levelRanks = {
  bronze: 0,
  silver: 10,
  gold: 20,
  platinium: 30,
  diamond: 40,
  master: 50,
  grandmaster: 60,
  challenger: 70,
};

const ProfileAvatar = ({ className, image, level, onClick, small, itsMe }) => {
  // UTILS-------------------------------------------------------------------------UTILS

  const [isMounted, setIsMounted] = useState(false);

  // UTILS-------------------------------------------------------------------------UTILS
  // ANIMATION-----------------------------------------------------------------ANIMATION

  const [animation, setAnimation] = useState(false);

  const animateLevel = () => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 1000);
  };

  useEffect(() => {
    // console.log(level);
    if (level) {
      if (isMounted) {
        itsMe && animateLevel();
      } else {
        setIsMounted(true);
      }
    }
  }, [level]);

  // ANIMATION-----------------------------------------------------------------ANIMATION

  return (
    <div
      className={cn(
        small ? styles.avatar_small : styles.avatar,
        className && className
      )}
      onClick={onClick}
    >
      <img src={image ? image : '/images/default-avatar.png'} alt='Avatar' />
      {level !== null && (
        <div className={cn(styles.level, animation && styles.animate)}>
          {level ? level.substring(6) : 0}
        </div>
      )}
    </div>
  );
};
export default ProfileAvatar;
