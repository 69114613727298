import React, { useState, useEffect } from 'react';
import styles from './SalaryDetails.module.sass';
import Category from 'components/MiniItemsLayout/Categories/Category';
import { useHistory } from 'react-router';
import Chart from 'react-apexcharts';
import { colorRelatedToBackground } from 'utils';

const SalaryDetails = ({ item, isDarkMode }) => {
  const history = useHistory();
  const overall = item?.overall;
  const junior = item?.junior;
  const mid = item?.mid;
  const senior = item?.senior;
  const [MinEarnings, setMinEarnings] = useState(item?.junior?.min_earnings);
  const [MaxEarnings, setMaxEarnings] = useState(item?.senior?.max_earnings);

  function numberWithSpaces(x) {
    if (!x) return 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  useEffect(() => {
    if (MinEarnings > item?.mid?.min_earnings) {
      setMinEarnings(item?.mid?.min_earnings);
    }
    if (MinEarnings > item?.senior?.min_earnings) {
      setMinEarnings(item?.senior?.min_earnings);
    }
    if (MaxEarnings < item?.junior?.min_earnings) {
      setMaxEarnings(item?.junior?.max_earnings);
    }
    if (MaxEarnings < item?.mid?.min_earnings) {
      setMaxEarnings(item?.mid?.max_earnings);
    }
  }, [item]);

  const chartSeries = [
    {
      data: [
        {
          valueInvented: (junior?.min_earnings + junior?.max_earnings) / 2,
          x: 'Junior',
          y: [junior?.min_earnings, junior?.max_earnings],
        },
        {
          valueInvented: (mid?.min_earnings + mid?.max_earnings) / 2,
          x: 'Mid',
          y: [mid?.min_earnings, mid?.max_earnings],
        },
        {
          valueInvented: (senior?.min_earnings + senior?.max_earnings) / 2,
          x: 'Senior',
          y: [senior?.min_earnings, senior?.max_earnings],
        },
      ],
      color: overall?.color,
    },
  ];

  const options = {
    theme: {
      mode: isDarkMode ? 'dark' : 'light',
    },
    chart: {
      type: 'rangeBar',
      background: 'none',
      height: 250,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [
          colorRelatedToBackground(overall?.color) === 'dark'
            ? '#1A1D1F'
            : '#FCFCFC',
        ],
      },
      textAnchor: 'middle',
      formatter: function (val, opt) {
        return numberWithSpaces(
          opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
            .valueInvented
        );
      },
    },
    yaxis: {
      labels: {
        offsetX: -15,
        style: {
          colors: [!isDarkMode ? 'black' : 'white'],
        },
        formatter: function (value) {
          return numberWithSpaces(value);
        },
      },
    },
    grid: {
      padding: {
        left: -5,
      },
    },
  };

  const chart = (
    <Chart
      options={options}
      series={chartSeries}
      type='rangeBar'
      width='100%'
      height='300px'
    />
  );

  return (
    <>
      <div className={styles.overall}>
        <div className={styles.earnings}>
          <Category
            classNames={styles.category}
            name={item?.name || 'Brak nazwy'}
            color={overall?.color || '#ffffff'}
            onClick={() => {
              history.push(
                `/courses?categories=${encodeURIComponent(item?.name)}`
              );
            }}
          />
        </div>
        <div
          className={styles.earnings_font}
          style={{
            color: isDarkMode ? overall?.color : 'black',
          }}
        >
          od {numberWithSpaces(MinEarnings)} PLN do{' '}
          {numberWithSpaces(MaxEarnings)} PLN &nbsp;
          <div className={styles.brutto}>(brutto)</div>
        </div>
      </div>

      <div className={styles.seniorities}>
        <div className={styles.seniorities_font}>Junior</div>
        <div className={styles.seniorities_earnings_font}>
          {numberWithSpaces(junior.min_earnings)} PLN -{' '}
          {numberWithSpaces(junior.max_earnings)} PLN
        </div>
        <div className={styles.seniorities_font}>Mid</div>
        <div className={styles.seniorities_earnings_font}>
          {numberWithSpaces(mid.min_earnings)} PLN -{' '}
          {numberWithSpaces(mid.max_earnings)} PLN
        </div>
        <div className={styles.seniorities_font}>Senior</div>
        <div className={styles.seniorities_earnings_font}>
          {numberWithSpaces(senior.min_earnings)} PLN -{' '}
          {numberWithSpaces(senior.max_earnings)} PLN
        </div>
      </div>

      <div className={styles.trends}>{chart}</div>
    </>
  );
};
export default SalaryDetails;
