import React from 'react';
import styles from './BigFiveSuggestionInfo.module.sass';
import Card from 'components/Card';

const BigFiveSuggestionInfo = () => {
  return (
    <Card
      title={'Sugestie dopasowane na podstawie wyników testu osobowości'}
      classTitle={'title-blue'}
    >
      <p className={styles.text}>
        Poniżej znajdziesz nasze propozycje przygotowane na podstawie uzyskanego
        wyniku. Pamiętaj, że to są jedynie sugestie i nie należy ich brać za
        pewnik.
      </p>
    </Card>
  );
};

export default BigFiveSuggestionInfo;
