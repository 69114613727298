import React, { useState } from "react";

const GroupContext = React.createContext();

export const GroupContextProvider = (props) => {
  const [groupHandler, setGroupHandler] = useState(false);
  const [deleteHandler, setDeleteHandler] = useState(false);

  return (
    <GroupContext.Provider
      value={{
        groupHandler: groupHandler,
        setGroupHandler: setGroupHandler,
        setDeleteHandler: setDeleteHandler,
        deleteHandler: deleteHandler,
      }}
    >
      {props.children}
    </GroupContext.Provider>
  );
};

export default GroupContext;