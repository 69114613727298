import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './Posts.module.sass';
import cn from 'classnames';

import Loader from 'components/MiniPost/Loader';
import MiniPost from 'components/MiniPost';
import axios from 'axios';
import Pagination from 'components/Pagination';
import PaginationGrowingFooter from 'components/PaginationGrowingFooter';
import { pageSize } from 'data/dataArrays';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import Post from 'screens/Post';

const Posts = ({ className, setRerender, params, refreshPosts }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // PAGINATION----------------------------------------------------------------PAGINATION

  const [currentPage, setCurrentPage] = useState(1);
  const [totalArticles, setTotalArticles] = useState(1);

  // SCROLL TOP ON PAGE CHANGE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // PAGINATION----------------------------------------------------------------PAGINATION

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getPosts(cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, [currentPage]);

  useEffect(() => {
    setPosts([]);
    setCurrentPage(1);
  }, [params?.id]);

  const getPosts = async cancelToken => {
    try {
      setIsLoading(true);

      const { data } = await axiosInstance.get(
        `/posts/article/user-post/?page=${currentPage}&page_size=${pageSize}`,
        {
          headers: { 'USER-ID': params.id, METHOD: 'list' },
          cancelToken: cancelToken.token,
        }
      );

      setPosts(data?.results || []);
      setTotalArticles(data?.count || 1);

      setIsLoading(false);
      setRerender(prev => !prev);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={cn(styles.container, { [className]: className })}>
      <div className={styles.posts}></div>
      {!isLoading &&
        posts?.map((post, index) => {
          return (
            <MiniPost className={styles.item} key={index} article={post} />
          );
        })}
      {posts?.length === 0 && !isLoading && (
        <p className={styles.info}>Użytkownik nie ma jeszcze żadnych postów</p>
      )}
      {isLoading && <Loader cards={pageSize * currentPage} />}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={totalArticles}
        onPageChange={page => setCurrentPage(page)}
      />
    </div>
  );
};

export default Posts;
