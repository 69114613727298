import React, { useState, useEffect, useCallback, useRef } from 'react';
import styles from './JobOffers.module.sass';
import LatestCourses from 'components/LatestCourses';
import LatestComments from 'components/LatestComments';
import TooltipGlodal from 'components/TooltipGlodal';
import axios from 'axios';
import SkeletonLoader from 'components/MiniJob/Loader';
import Sorting from './Sorting';
import AppHelmet from 'components/AppHelmet';
import {
  jobSortingOptions,
  jobSeniority,
  jobFormat,
  jobRecruitment,
  pageSize,
  jobSeniorityLevels,
} from 'data/dataArrays';
import Filtering from './Filtering';
import cn from 'classnames';

import TagFilter from 'components/Tags/TagFilter';
import { default as TagFilterLoader } from 'components/Tags/TagFilter/Loader';

import { useLocation, useHistory } from 'react-router-dom';
import devscntrNoauth from 'api/Instance/devscntrNoauth';

import { showJobsMap } from 'store/jobsMap/actions';
import { store } from 'store/rootReducer';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import { jobsEndpoints } from 'api/endpoints';
import AverageSalary from 'components/AverageSalary';
import MiniJob from 'components/MiniJob';

const postsPerPage = pageSize;

const jobRecruitmentOptions = ['wszystkie', ...jobRecruitment];
const jobSeniorityOptions = ['wszystkie', ...jobSeniority];
const jobFormatOptions = ['wszystkie', ...jobFormat];

const JobOffers = () => {
  // UTILS--------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const search = useLocation().search;
  const history = useHistory();

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);

  const [mapVisible, setMapVisible] = useState(false);
  const showMap = () => {
    store.dispatch(showJobsMap());
  };

  const [tags, setTags] = useState([]);
  const [articles, setArticles] = useState([]);

  // SCROLL TOP ON PAGE CHANGE
  useEffect(() => {
    //window.scrollTo(0, 0);
  }, [tags]);

  // UTILS--------------------------------------------------------------------------UTILS
  // FILTERS STATES--------------------------------------------------------FILTERS STATES

  const [sortMethod, setSortMethod] = useState(jobSortingOptions[0].value);
  const [filters, setFilters] = useState({
    seniority: jobSeniorityOptions[0],
    format: jobFormatOptions[0],
    recruitmentFormat: jobRecruitmentOptions[0],
  });
  const [params, setParams] = useState('');

  // FILTERS STATES--------------------------------------------------------FILTERS STATES
  // PAGINATION----------------------------------------------------------------PAGINATION

  const [currentPage, setCurentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [refresher, setRefresher] = useState(false);

  const observer = useRef();
  const lastPostRef = useCallback(
    post => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting) {
          console.log('We are near the last post!');
          setCurentPage(prev => ++prev);
        }
      });
      if (post) observer.current.observe(post);
    },
    [articles, isLastPage]
  );

  // PAGINATION----------------------------------------------------------------PAGINATION
  // GET JOBS--------------------------------------------------------------------GET JOBS

  const getJobs = async cancelToken => {
    setIsLoading(true);
    try {
      const page = `&page=${currentPage}&page_size=${postsPerPage}`;
      const response = await axiosInstance.request({
        method: 'get',
        url: `${jobsEndpoints.show}?${params}${page}`,
        cancelToken: cancelToken.token,
      });

      if (response.data.pages <= currentPage) setIsLastPage(true);
      else setIsLastPage(false);

      const resData = response?.data?.jobs || [];
      let data = resData.filter(item => item.title) || [];
      if (authCtx.isAdmin) {
        data = resData;
      }

      // const loadedArticles = response.data.results.map((item, index) => {
      const loadedArticles = data?.map((item, index) => {
        if (data.length - 1 === index + 1) {
          return (
            <MiniJob
              article={item}
              key={`job_${item.id}_${index}`}
              ref={lastPostRef}
            />
          );
        }
        return <MiniJob article={item} key={`job_${item.id}_${index}`} />;
      });

      if (articles.length === 0) setArticles(loadedArticles);
      else setArticles(prevState => [...prevState, ...loadedArticles]);

      setIsLoading(false);
      console.log(`Załadowano ${currentPage} strone`);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('jobs canceled');
      } else {
        console.log(error);
      }
    }
    setContentLoaded(true);
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (isMounted) {
      !isLastPage && getJobs(cancelToken);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [currentPage, search, refresher]);

  // GET JOBS--------------------------------------------------------------------GET JOBS
  // FILTERS----------------------------------------------------------------------FILTERS

  useEffect(() => {
    if (isMounted) {
      setIsLoading(true);
      setCurentPage(1);
      setIsLastPage(false);
      setArticles([]);
      setRefresher(prev => !prev);
    }
    console.log(params);
  }, [params]);

  const getUrlParams = () => {
    let sortBy = new URLSearchParams(search).get('sortby');
    let seniority =
      jobSeniorityOptions[new URLSearchParams(search).get('seniority')] ||
      jobSeniorityOptions[0];
    let format =
      jobFormatOptions[new URLSearchParams(search).get('type')] ||
      jobFormatOptions[0];
    let recruitmentFormat =
      jobRecruitmentOptions[
      new URLSearchParams(search).get('recruitment_format')
      ] || jobRecruitmentOptions[0];
    let tags = new URLSearchParams(search).get('tags') || [];

    setSortMethod(sortBy || jobSortingOptions[0].value);
    setFilters({
      seniority: seniority,
      format: format,
      recruitmentFormat: recruitmentFormat,
    });
    if (tags.length > 0) setTags(tags.split('&'));
    else setTags([]);

    setIsMounted(true);
  };

  useEffect(() => {
    getUrlParams();
    setArticles([]);
  }, [search]);

  useEffect(() => {
    if (isMounted) {
      changeUrlParams();
    }
  }, [filters, sortMethod, tags, isMounted]);

  const changeUrlParams = () => {
    //const sortMethodIndex = jobSortingOptions.map((item) => item.name).indexOf(sortBy);
    //setArticles([]);
    let params = '';
    params += `sortby=${sortMethod}`;

    // SENIORITY
    if (jobSeniorityOptions.indexOf(filters.seniority) > 0) {
      params += `&seniority=${jobSeniorityOptions.indexOf(filters.seniority)}`;
      //params += `&seniority=${jobSeniorityLevels[filters.seniority]}`;
    }
    // FORMAT
    if (jobFormatOptions.indexOf(filters.format) > 0) {
      params += `&type=${jobFormatOptions.indexOf(filters.format)}`;
    }
    // RECRUITMENT TYPE
    if (jobRecruitmentOptions.indexOf(filters.recruitmentFormat) > 0) {
      params += `&recrutype=${jobRecruitmentOptions.indexOf(
        filters.recruitmentFormat
      )}`;
    }
    // TAGS
    if (tags?.length > 0) {
      params += `&tags=${tags.join('%26')}`;
    }

    setParams(params);
  };

  useEffect(() => {
    if (isMounted) {
      history.push({
        search: params,
      });
    }
  }, [params]);

  // FILTERS----------------------------------------------------------------------FILTERS

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {/* <Category
            categories={categories}
            onChangeCategory={handleChangeCategory}
          /> */}
          {/* <TagFilter selectedTags={tags} setSelectedTags={setTags} /> */}
          {contentLoaded ? (
            <TagFilter selectedTags={tags} setSelectedTags={setTags} />
          ) : (
            <TagFilterLoader />
          )}
          <div className={styles.options}>
            <Sorting
              setSortMethod={setSortMethod}
              style={{ justifySelf: 'start' }}
            />
            <div className={styles.box}>
              {/* <button
                className={cn("button-stroke", styles.open_map_btn)}
                style={{ justifySelf: "center" }}
                onClick={showMap}
              >
                Pokaż mapę
              </button> */}
              <Filtering filters={filters} setFilters={setFilters} />
            </div>
          </div>

          {articles?.length > 0 ? (
            <div className={styles.items_list} id={'jobs-list'}>
              {articles}
              {isLoading && <SkeletonLoader cards={postsPerPage} />}
            </div>
          ) : (
            !isLoading && (
              <div className={styles.info}>
                Nie znaleźliśmy żadnych ofert pracy
              </div>
            )
          )}
          {isLoading && !isLastPage && currentPage < 2 && (
            <div className={styles.items_list}>
              <SkeletonLoader cards={postsPerPage} />
            </div>
          )}

          <div className={styles.foot}>
            {!isLastPage && (
              <span
                className={cn('button-stroke button-small', styles.button)}
                style={{ marginLeft: '12px' }}
              >
                {isLoading ? 'Ładowanie...' : 'Następna'}
              </span>
            )}
          </div>
        </div>
        <div className={styles.col}>
          <AverageSalary className={styles.card} limit={3} />
          <LatestCourses className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default JobOffers;
