import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import cn from 'classnames';
import styles from './SignUp.module.sass';
import { use100vh } from 'react-div-100vh';
import Entry from './Entry';
import Code from './Code';
import Image from '../../components/Image';

import useAuth from '../../hooks/useAuth';
import devscntrNoauth from '../../api/Instance/devscntrNoauth';
import { validateInput } from 'utils/validation';
import { validationRules } from 'data/validationRules';
import { showError } from 'utils/showError';
import Loader from 'components/Loader';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getErrorMessage } from './errorHandler';

// Left sidebar text with "Plan includes"
const items = [
  'Przypinaj ulubione posty',
  'Obserwuj twórców',
  'Bądź na bieżąco z najnowszą technologią',
  'Oceniaj treści',
];

// URLs for axios requests
const REGISTRATION_URL = '/accounts/registration';
const PHONE_VERIFY_URL = '/accounts/login/phone-verifications';

// SignUp component
const SignUp = () => {
  // UTILS----------------------------------------------------------------------------UTILS

  // Authorization context
  const authCtx = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [visible, setVisible] = useState(true);
  const heightWindow = use100vh();

  // Manage inputs and success/error messages
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  // UTILS----------------------------------------------------------------------------UTILS
  // FIELDS--------------------------------------------------------------------------FIELDS

  const [phone, setPhone] = useState('');
  const [registerData, setRegisterData] = useState({
    email: '',
    displayName: '',
    passwd: '',
    passwd2: '',
    acceptPersonalData: false,
    acceptPrivacyPolicy: false,
  });

  // FIELDS--------------------------------------------------------------------------FIELDS
  // VALIDATION------------------------------------------------------------------VALIDATION

  const validation = () => {
    if (
      !validateInput(
        registerData.email,
        validationRules.email.maxChars,
        validationRules.email.minChars,
        validationRules.email.name,
        validationRules.email.security
      )
    )
      return;

    if (
      !validateInput(
        registerData.displayName,
        validationRules.displayName.maxChars,
        validationRules.displayName.minChars,
        validationRules.displayName.name,
        validationRules.displayName.security
      )
    )
      return;

    // if (phone.length < 9) {
    //   showError('Podaj poprawny numer telefonu');
    //   return;
    // }

    if (
      !validateInput(
        registerData.passwd,
        validationRules.password.maxChars,
        validationRules.password.minChars,
        validationRules.password.name,
        validationRules.password.security
      )
    )
      return;

    if (registerData.passwd !== registerData.passwd2) {
      showError('Hasła nie są identyczne');
      return;
    }

    // if (!recaptchaRef.current) {
    //   showError('Wystąpił błąd z reCaptcha. Proszę spróbować później.');
    //   return;
    // }

    return true;
  };

  // VALIDATION------------------------------------------------------------------VALIDATION
  // GOOGLE RECAPTCHA------------------------------------------------------GOOGLE RECAPTCHA
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptchaRef = useRef();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  // const [refreshRecaptcha, setRefreshRecaptcha] = useState(false);

  const handleReCaptchaVerify = async () => {
    setIsLoading(true);

    if (!executeRecaptcha) {
      //console.log('Execute recaptcha not yet available');
      showError('Wystąpił błąd z reCaptcha. Spróbuj odświeżyć stronę.');
      setIsLoading(false);
      return;
    }
    const token = await executeRecaptcha('register');

    registerToApi(token);
  };

  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  // GOOGLE RECAPTCHA------------------------------------------------------GOOGLE RECAPTCHA
  // API CALL----------------------------------------------------------------------API CALL

  // Send registration data to API, handle response
  const registerToApi = async recaptcha => {
    setIsLoading(true);
    try {
      // Send data
      const response = await devscntrNoauth.post(
        REGISTRATION_URL,
        JSON.stringify({
          email: registerData.email,
          display_name: registerData.displayName,
          //phone: phone,
          password: registerData.passwd,
          accept_personal_details: registerData.acceptPersonalData,
          accept_privacy_politics: registerData.acceptPrivacyPolicy,
          recaptcha: recaptcha,
        })
      );

      // If there is a reponse, set accessToken
      //const accessToken = response?.data?.token;
      setSuccess(true);

      // Verify your token, get bearer access token in response
      //phoneVerification(accessToken);

      //const expirationTime = new Date(new Date().getTime() + 86300 * 1000);
      // Login user
      //authCtx.login(response.data.access, expirationTime.toISOString()); //expirationTime.toISOString());
    } catch (error) {
      const resError = error.response.data.error;
      const errorKey = resError.split("'")[1] || null;
      const errorCode = resError.split("code='")[1].split("'")[0];

      if (!error?.response) {
        setErrMsg('Brak odpowiedzi serwera');
      } else if (errorKey) {
        const message = getErrorMessage(errorKey, errorCode || '');
        showError(message);
      } else if (error.error) {
        showError('Wystąpił błąd.');
      } else {
        showError('Wystąpił błąd, spróbuj ponownie później.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // const onVerify = useCallback(token => {
  //   setRecaptchaToken(token);
  // });

  /*
  // Phone verification, set login if given token is accepted
  const phoneVerification = async (token) => {
    try {
      // Send data
      const response = await devscntrNoauth.post(
        PHONE_VERIFY_URL,
        JSON.stringify({ token: token, code: "0" })
      );

      // Clear register inputs
      setRegisterData((prev) => ({
        ...prev,
        email: "",
        displayName: "",
        passwd: "",
        passwd2: "",
      }));
      setErrMsg("");

      // Set 'Authorization' header as a default in axios requests with Bearer token
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response?.data?.access}`;

      // Login user
      authCtx.login(response?.data?.access);
      setSuccess(true);

      // Redirect to '/' path after succeed
      history.replace("/");
    } catch (error) {
      setErrMsg("Wystąpił błąd serwera");
    }
  };
  */

  // API CALL----------------------------------------------------------------------API CALL
  // HANDLERS----------------------------------------------------------------------HANDLERS

  // Handle changes in inputs, set values in registerData every change
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setRegisterData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle submit, register
  const handleSubmit = e => {
    e.preventDefault();

    if (validation()) {
      handleReCaptchaVerify();
    }
  };

  // HANDLERS----------------------------------------------------------------------HANDLERS

  return (
    <>
      {/* <GoogleReCaptcha
        onVerify={onVerify}
        refreshReCaptcha={() => setRefreshRecaptcha(prev => !prev)}
      /> */}
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <div className={styles.preview}>
              {/* <img src="/images/content/login-pic.png" alt="Login" /> */}
              <Image
                className={styles.pic}
                src='/images/logo-light.svg'
                srcDark='/images/logo-dark.svg'
                alt='itCNTR'
              />
            </div>
            <div className={cn('h4', styles.subtitle)}>
              Dlaczego warto założyć konto
            </div>
            <ul className={styles.list}>
              {items.map((x, index) => (
                <li key={index}>{x}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.col} style={{ minHeight: heightWindow }}>
          <div className={styles.head}>
            <Link className={styles.logo} to='/'>
              <Image
                className={styles.pic}
                src='/images/logo-light.svg'
                srcDark='/images/logo-dark.svg'
                alt='itCNTR'
              />
            </Link>
            <div className={styles.info}>
              Masz już konto?{' '}
              <Link className={styles.link} to='/sign-in'>
                Zaloguj się
              </Link>
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={cn('h2', styles.title)}>
              {success ? 'Potwierdź email' : 'Zarejestruj się'}
            </div>
            {success ? (
              <>
                <div className={styles.message}>
                  Sprawdź swoją skrzynkę pocztową. Wysłaliśmy Tobie link
                  aktywujący Twoje nowe konto.
                </div>
                <Link to='/sign-in' className={cn('button', styles.button)}>
                  <Loader className={styles.loader} white />
                  <span>Przejdź do logowania</span>
                </Link>
              </>
            ) : (
              <Entry
                onConfirm={handleSubmit}
                onChange={handleChange}
                //phoneData={phone}
                //onPhoneChange={setPhone}
                registerData={registerData}
                setRegisterData={setRegisterData}
                errorMsg={errMsg}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
