import { FilterOptionName, FilterOptionValue, SortOptionName, SortOptionValue } from "./names";

export const sortOptions = [
  {
    name: SortOptionName.LATEST,
    value: SortOptionValue.LATEST,
  },
  {
    name: SortOptionName.ELDEST,
    value: SortOptionValue.ELDEST,
  },
  {
    name: SortOptionName.STATUS,
    value: SortOptionValue.STATUS,
  },
  {
    name: SortOptionName.VIEWS_DESC,
    value: SortOptionValue.VIEWS_DESC,
  },
  {
    name: SortOptionName.VIEWS,
    value: SortOptionValue.VIEWS,
  },
  {
    name: SortOptionName.LEADS_DESC,
    value: SortOptionValue.LEADS_DESC,
  },
  {
    name: SortOptionName.LEADS,
    value: SortOptionValue.LEADS,
  },
  {
    name: SortOptionName.LIKES_DESC,
    value: SortOptionValue.LIKES_DESC,
  },
  {
    name: SortOptionName.LIKES,
    value: SortOptionValue.LIKES,
  },
  {
    name: SortOptionName.RATING_DESC,
    value: SortOptionValue.RATING_DESC,
  },
  {
    name: SortOptionName.RATING,
    value: SortOptionValue.RATING,
  },
];

export const filterOptionsType = [
  {
    name: FilterOptionName.ALL,
    value: FilterOptionValue.ALL,
  },
  {
    name: FilterOptionName.POSTS,
    value: FilterOptionValue.POSTS,
  },
  {
    name: FilterOptionName.COURSES,
    value: FilterOptionValue.COURSES,
  },

  {
    name: FilterOptionName.JOBS,
    value: FilterOptionValue.JOBS,
  },
];

export const filterOptionsStatus = [
  {
    name: FilterOptionName.ALL,
    value: FilterOptionValue.ALL,
  },
  {
    name: FilterOptionName.PUBLISHED,
    value: FilterOptionValue.PUBLISHED,
  },
  {
    name: FilterOptionName.SCHEDULED,
    value: FilterOptionValue.SCHEDULED,
  },
  {
    name: FilterOptionName.DRAFTS,
    value: FilterOptionValue.DRAFTS,
  },
];

export const pageLimitOptions = [10, 25, 50];