import React from 'react';
import styles from './Statute.module.sass';
import Card from 'components/Card';

const Statute = () => {
  return (
    <Card
      title='Regulamin serwisu itcntr.com'
      classTitle='title-blue'
      className={styles.container}
    >
      <div className={styles.content}>
        <article>
          <h3>§1 Postanowienia ogólne</h3>
          <ol>
            <li>
              Niniejszy regulamin (dalej: Regulamin) określa zasady korzystania
              z serwisu internetowego dostępnego pod adresem{' '}
              <a href='https://itcntr.com/'>https://itcntr.com/</a>{' '}
              (dalej: Platforma albo Serwis IT CNTR).
            </li>
            <li>
              Korzystając z platformy{' '}
              <a href='https://itcntr.com/'>https://itcntr.com/</a> akceptujesz
              zasady regulaminu, którego aktualna wersja znajduje się pod
              adresem 
              <a href='https://itcntr.com/statute'>
                https://itcntr.com/statute
              </a>{' '}
              oraz polityki prywatności, która stanowi integralną część
              niniejszego regulaminu pod adresem 
              <a href='https://itcntr.com/privacy'>
                https://itcntr.com/privacy
              </a>
            </li>
            <li>
              Właścicielem platformy https://itcntr.com/ jest:
              <br />
              IT CNTR spółka z ograniczoną odpowiedzialnością z siedzibą w
              Poznaniu, ul. Święty Marcin 29/6, 61-806 Poznań, wpisaną do
              Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego
              przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, VIII
              Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS
              0001038503, NIP: 7831881662, REGON: 525428734, o kapitale
              zakładowym w wysokości 5.500 zł, zwaną dalej „Spółką”, adres
              e-mail <a href={'mailto:office@itcntr.com'}>office@itcntr.com</a>.
              <br />
              (dalej: Usługodawca)
            </li>
            <li>
              Serwis IT CNTR dostarcza użytkownikom linki do treści zewnętrznych
              serwisów internetowych z branży IT oraz umożliwia samodzielne
              dodawanie treści przez użytkowników. Portal umożliwia użytkownikom
              korzystanie z następujących usług :
              <ol type='a'>
                <li>Przeglądanie treści w serwisie itcntr.com;</li>
                <li>Publikacja treści w serwisie itcntr.com;</li>
                <li>Edytowanie i usuwanie treści w serwisie itcntr.com;</li>
                <li>Zakładanie konta użytkownika w serwisie itcntr.com; </li>
                <li>Usunięcie konta w serwisie itcntr.com;</li>
                <li>Dodawanie komentarzy do treści;</li>
                <li>Polubienie treści;</li>
                <li>Dołączanie do społeczności;</li>
                <li>Zapisywanie treści na później;</li>
                <li>Otrzymywanie powiadomień e-mail;</li>
                <li>
                  Wypełnienie i wysłanie formularza zgłoszenia społeczności;
                </li>
                <li>Wypełnienie i wysłanie formularza opinii;</li>
                <li>Wypełnienie i wysłanie formularza zgłoszenia treści;</li>
                <li>Newsletter;</li>
              </ol>
            </li>
            <li>
              Serwis IT CNTR nie ponosi odpowiedzialności za treść zamieszczaną
              przez inne serwisy internetowe, do których prowadzą linki
              zamieszczone na naszej stronie, ani za treści dodawane przez
              użytkowników.
            </li>
            <li>
              Użytkownicy serwisu IT CNTR zobowiązani są do przestrzegania
              obowiązujących przepisów prawnych oraz do zachowania dobrych
              obyczajów podczas korzystania z naszego serwisu. Użytkownicy
              ponoszą odpowiedzialność za treści, które samodzielnie dodają do
              serwisu.
            </li>
            <li>
              Serwis IT CNTR reklamuje kursy i szkolenia z dziedziny szeroko
              rozumianej branży informatycznej. Sprzedaż takich kursów i szkoleń
              następuje na zewnętrznych serwisach, a IT CNTR nie ponosi żadnej
              odpowiedzialności za kursy i szkolenia kupione poza naszym
              serwisem.
            </li>
            <li>
              Serwis IT CNTR zastrzega sobie prawo do usuwania lub edytowania
              treści zamieszczanych przez użytkowników bez podania przyczyny.
            </li>
            <li>
              Korzystanie z serwisu IT CNTR oznacza akceptację regulaminu.
            </li>
            <li>
              Wszelkie pytania lub wątpliwości dotyczące regulaminu prosimy
              kierować na adres email:{' '}
              <a href={'mailto:kontakt@itcntr.com'}>kontakt@itcntr.com</a>.
            </li>
          </ol>
        </article>
        <article>
          <h3>§2 Rejestracja</h3>
          <ol>
            <li>
              Rejestracja konta jest dozwolona tylko dla osób fizycznych, które
              ukończyły 13 lat.
            </li>
            <li>
              W celu zarejestrowania konta należy podać następujące dane
              osobowe: nazwa użytkownika, adres e-mail, hasło.
            </li>
          </ol>
        </article>
        <article>
          <h3>§3 Korzystanie z serwisu</h3>
          <ol>
            <li>
              Korzystanie z serwisu jest dozwolone tylko w sposób zgodny z jego
              przeznaczeniem i w oparciu o obowiązujące przepisy prawa.
            </li>
            <li>
              Użytkownik jest zobowiązany do niezwłocznego powiadomienia
              administratora serwisu o jakichkolwiek naruszeniach zasad
              korzystania z serwisu lub o innych działaniach, które mogą być
              sprzeczne z prawem lub dobrymi obyczajami.
            </li>
            <li>
              Użytkownik ponosi pełną odpowiedzialność za treści, które
              umieszcza w serwisie oraz za konsekwencje ich rozpowszechniania.
            </li>
            <li>
              Zabrania się umieszczania w serwisie treści naruszających prawa
              autorskie, nękających innych użytkowników lub naruszających inne
              przepisy prawa.
            </li>
          </ol>
        </article>
        <article>
          <h3>§4 Ochrona danych osobowych: </h3>
          <br />
          <h4>1. Administrator</h4>
          <ol type='1'>
            <li>
              Administratorem danych osobowych jest IT CNTR spółka z ograniczoną
              odpowiedzialnością z siedzibą w Poznaniu, ul. Święty Marcin 29/6,
              61-806 Poznań, wpisaną do Rejestru Przedsiębiorców Krajowego
              Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań - Nowe
              Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego
              Rejestru Sądowego, pod numerem KRS 0001038503, NIP: 7831881662,
              REGON: 525428734, o kapitale zakładowym w wysokości 5.500 zł,
              zwaną dalej „Spółką”, adres e-mail{' '}
              <a href={'mailto:office@itcntr.com'}>office@itcntr.com</a> (dalej
              „Administrator”), który przykłada dużą wagę do ochrony prywatności
              i poufności danych osobowych swoich Klientów, a także danych
              swoich pracowników i innych osób fizycznych, których dane są przez
              Administratora przetwarzane (zwanych dalej „Użytkownikami”).
            </li>
            <li>
              Z Administratorem można kontaktować się pisemnie, kierując
              korespondencję na adres siedziby Administratora, e-mailowo pod
              adresem: <a href={'mailto:info@itcntr.com'}>info@itcntr.com</a>
            </li>
            <li>
              Administrator nie wyznaczył Inspektora ochrony danych osobowych.
            </li>
          </ol>

          <h4>2. Zasady przetwarzania danych osobowych</h4>
          <ol>
            <li>
              Administrator przetwarza dane osobowe w minimalnym zakresie
              koniecznym do realizacji celów ich przetwarzania, określonych
              jasno niniejszą Polityką Prywatności.
            </li>
            <li>
              Administrator z należytą starannością dobiera i stosuje
              odpowiednie środki techniczne i organizacyjne zapewniające ochronę
              przetwarzanych danych osobowych. Pełen dostęp do baz danych
              posiadają jedynie osoby należycie uprawnione przez Administratora.
            </li>
            <li>
              Administrator zabezpiecza dane osobowe przed ich udostępnieniem
              osobom nieupoważnionym, jak również przed ich przetwarzaniem z
              naruszeniem obowiązujących przepisów prawa. Administrator
              przetwarzając dane osobowe stosuje rozwiązania dostosowane do
              skali i charakteru przetwarzania zapewniając osobom, których dane
              dotyczą, najwyższy stopień ochrony wynikający zarówno ze
              stosowanych rozwiązań technologicznych, jak i organizacyjnych.
            </li>
          </ol>

          <h4>3. Podstawa przetwarzania danych osobowych</h4>
          <ol>
            <li>
              Podane przez Użytkownika dane osobowe przetwarzane są zgodnie z
              niniejszą Polityką Prywatności oraz obowiązującymi przepisami
              prawa, w szczególności zgodnie z rozporządzeniem Parlamentu
              Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie
              ochrony osób fizycznych w związku z przetwarzaniem danych
              osobowych i w sprawie swobodnego przepływu takich danych oraz
              uchylenia dyrektywy 95/46/WE z dnia 27 kwietnia 2016 r., („RODO”).
            </li>
            <li>
              Podanie danych osobowych jest dobrowolne, jednakże ich niepodanie
              spowoduje, że wykonanie wnioskowanych czynności będzie niemożliwe.
            </li>
            <li>
              Podstawą przetwarzania danych osobowych jest:
              <ol type='a'>
                <li>
                  art. 6 ust. 1 lit. a RODO – w zakresie danych osobowych
                  uzyskanych za pomocą zgody, na warunkach określonych w art. 7
                  RODO;
                </li>
                <li>
                  art. 6 ust. 1 lit. b RODO – w zakresie danych podawanych
                  dobrowolnie w celu przygotowania i realizacji Umowy pomiędzy
                  Użytkownikiem a Administratorem lub podmiotem, któremu
                  Administrator zleca wykonanie Umowy. Podanie danych jest
                  dobrowolne, jednak niezbędne do realizacji Umowy.
                </li>
                <li>
                  art. 6 ust. 1 lit c RODO – w zakresie wypełnienia obowiązku
                  prawnego ciążącego na Administratorze, np. wynikającego z
                  przepisów prawa podatkowego oraz innych regulacji prawnych
                  związanych z prowadzoną działalnością;
                </li>
                <li>
                  art. 6 ust. 1 lit e RODO – w zakresie wykonania zadania
                  realizowanego w interesie publicznym, w szczególności do celów
                  obejmujących przeciwdziałanie przestępstwom;
                </li>
                <li>
                  art. 6 ust. 1 lit. f RODO – w zakresie danych przetwarzanych w
                  związku z realizacją prawnie uzasadnionych celów
                  Administratora.
                </li>
              </ol>
            </li>
            <li>
              Administrator może przetwarzać dane osób trzecich udostępnione
              przez Użytkowników w celu lub w związku ze świadczeniem usług
              przez Administratora. Użytkownik, przekazując Administratorowi
              dane osób trzecich, każdorazowo oświadcza, że posiada stosowną
              zgodę osób trzecich na przekazanie ich danych Administratorowi.
            </li>
            <li>
              Użytkownik w razie zmiany danych osobowych, o których mowa w
              niniejszej Polityce Prywatności, poinformuje niezwłocznie
              Administratora w celu aktualizacji danych osobowych.
            </li>
            <li>
              Administrator nie stosuje wobec Użytkowników profilowania w
              rozumieniu art. 4 pkt 4) RODO.
            </li>
          </ol>

          <h4>4. Okres przetwarzania danych osobowych</h4>
          <ol>
            <li>
              Dane Użytkownika przechowywane będą nie dłużej niż jest to
              konieczne, tj.:
              <ol type='a'>
                <li>
                  w zakresie wykonania umowy – do czasu zakończenia realizacji
                  umowy, a po tym czasie przez okres wymagany przez przepisy
                  prawa lub dla realizacji ewentualnych roszczeń, jakie może
                  podnosić Administrator i jakie mogą być podnoszone wobec
                  Administratora;
                </li>
                <li>
                  w zakresie wypełniania obowiązku prawnego ciążącego na
                  Administratorze – do czasu jego wypełnienia;
                </li>
                <li>
                  w zakresie realizacji prawnie uzasadnionych interesów przez
                  Administratora lub przez stronę trzecią – do czasu ich
                  realizacji lub do czasu wniesienia przez Użytkownika sprzeciwu
                  wobec przetwarzania danych osobowych, o ile nie występują
                  uzasadnione podstawy dalszego przetwarzania;
                </li>
                <li>
                  w zakresie przetwarzania realizowanego wyłącznie w oparciu o
                  zgodę – do czasu niezwłocznego usunięcia danych,
                  zrealizowanego w oparciu o zgłoszone przez Użytkownika
                  żądanie.
                </li>
              </ol>
            </li>
          </ol>

          <h4>5. Prawa Użytkownika</h4>
          <ol>
            <li>
              W związku z przetwarzaniem danych osobowych przez Administratora,
              Użytkownik ma prawo do:
              <ol type='a'>
                <li>
                  żądania dostępu do danych osobowych – art. 15; Na żądanie
                  Użytkownika dotyczące dostępu do jego danych Administrator
                  informuje Użytkownika, czy przetwarza jego dane, oraz
                  informuje Użytkownika o szczegółach przetwarzania zgodnie z
                  RODO, a także udziela Użytkownikowi dostępu do danych go
                  dotyczących. Dostęp do danych będzie zrealizowany przez
                  przesłanie kopii danych drogą elektroniczną. W przypadku
                  żądania dostarczenia kolejnej kopii danych w formie papierowej
                  Administrator ma prawo obciążyć Użytkownika kosztami
                  związanymi z ich przygotowaniem w takiej formie i wysłaniem
                  zgodnie z art. 15 ust. 3 RODO.
                </li>
                <li>
                  prawo do sprostowania danych osobowych – 16 RODO;
                  Administrator dokonuje sprostowania nieprawidłowych danych na
                  żądanie Użytkownika.
                </li>
                <li>
                  prawo do żądania usunięcia danych osobowych – art. 17 RODO;
                  Prawo to obowiązuje w zakresie, w jakim usunięcie danych nie
                  stoi w sprzeczności z obowiązującymi Administratora
                  przepisami, w szczególności przepisami o rachunkowości.
                </li>
                <li>
                  prawo do ograniczenia przetwarzania danych – art. 18 RODO;
                  Prawo to obowiązuje w zakresie, w jakim Administrator może
                  ograniczyć przetwarzanie danych osobowych w kontekście
                  obowiązujących go przepisów oraz w jakim nie narusza to prawa
                  Administratora do dochodzenia swoich roszczeń od Użytkownika.
                </li>
                <li>
                  przenoszenia danych – art. 20 RODO; Na żądanie Użytkownika
                  Administrator wydaje w ustrukturyzowanym, powszechnie używanym
                  formacie nadającym się do odczytu maszynowego lub przekazuje
                  innemu podmiotowi, jeśli jest to możliwe, dane dotyczące
                  Użytkownika, który dostarczył je w celu zawarcia lub wykonania
                  Umowy lub które przetwarzane są na podstawie zgody.
                </li>
                <li>
                  wniesienia sprzeciwu wobec przetwarzania – art. 21 RODO;
                  Jeżeli Użytkownik zgłosi umotywowany jego szczególną sytuacją
                  sprzeciw względem przetwarzania jego danych a dane są
                  przetwarzane przez Administratora w oparciu o uzasadniony
                  interes Administratora, Administrator uwzględni sprzeciw, o
                  ile nie zachodzą po stronie Administratora ważne prawnie
                  uzasadnione podstawy do przetwarzania, nadrzędne wobec
                  interesów, praw i wolności osoby zgłaszającej sprzeciw, lub
                  podstawy do ustalenia, dochodzenia lub obrony roszczeń.
                </li>
                <li>
                  cofnięcia zgody na przetwarzanie danych, bez wpływu na
                  zgodność z prawem przetwarzania, którego dokonano na postawie
                  zgody przed jej cofnięciem – art. 7 ust. 3 RODO;
                </li>
                <li>wniesienia skargi do organu nadzorczego – art. 77 RODO.</li>
              </ol>
            </li>
            <li>
              Jeżeli Administrator nie będzie w stanie ustalić treści żądania
              lub zidentyfikować osoby realizującej ww. uprawnienia w oparciu o
              dokonane zgłoszenie, zwróci się do wnioskodawcy o dodatkowe
              informacje.
            </li>
            <li>
              Odpowiedź na zgłoszenia zostanie udzielona najpóźniej w ciągu
              miesiąca od jego otrzymania. W razie konieczności przedłużenia
              tego terminu, Administrator poinformuje wnioskodawcę o przyczynach
              takiego przedłużenia.
            </li>
          </ol>

          <h4>6. Udostępnianie danych osobowych</h4>
          <ol>
            <li>
              Dane osobowe będą udostępniane jedynie uprawnionym podmiotom, tj.
              upoważnionym pracownikom Administratora oraz innym osobom
              działającym z upoważnienia Administratora, oraz innym podmiotom
              upoważnionym do odbioru danych Użytkownika na podstawie
              odpowiednich przepisów prawa, jak również podmiotom świadczącym
              usługi na rzecz Administratora, w tym usługi IT i księgowe. Dane
              osobowe Użytkowników mogą być przekazywane innym podmiotom – w
              przypadkach niewskazanych przez Administratora bądź przepisy prawa
              – tylko za zgodą Użytkownika.
            </li>
            <li>
              Administrator zobowiązuje się do nieprzekazywania danych osobowych
              Użytkowników do krajów trzecich i organizacji międzynarodowych.
            </li>
            <li>
              Administrator zobowiąże wszelkie podmioty, którym powierzy dane
              osobowe Użytkownika, do wdrożenia stosownych zabezpieczeń tych
              danych.
            </li>
          </ol>

          <h4>7. Pliki Cookies</h4>
          <ol>
            <li>
              W serwisie internetowym{' '}
              <a href='https://www.itcntr.com'>https://www.itcntr.com</a>{' '}
              („Serwis”) stosuje się dane informatyczne przechowywane w
              urządzeniach końcowych użytkowników Serwisu, tj. w szczególności
              pliki tekstowe, zawierające m.in. nazwę strony internetowej, z
              której pochodzą, czas przechowywania ich na urządzeniu końcowym
              oraz unikalny numer („Cookies”).
            </li>
            <li>
              Na podstawie art. 173 ust. 1 ustawy Prawo telekomunikacyjne z dnia
              16 lipca 2004 r. (Dz. U. z 2021 r. poz. 576) Administrator
              niniejszym informuje, że:
              <ol type='a'>
                <li>
                  pliki Cookies są stosowane w Serwisie w celu ułatwienia
                  korzystania z Serwisu, pozwalają dostosowywać treści dostępne
                  w Serwisie do indywidualnych potrzeb i preferencji
                  użytkowników Serwisu, jak również służą do opracowywania
                  ogólnych statystyk dotyczących korzystania z Serwisu.
                </li>
                <li>
                  dane osobowe gromadzone przy użyciu plików Cookies są zbierane
                  wyłącznie w celu wykonywania określonych funkcji na rzecz
                  użytkowników i są zaszyfrowane w sposób uniemożliwiający
                  dostęp do nich osobom nieuprawnionym.
                </li>
                <li>
                  użytkownik Serwisu ma możliwość wyrażenia zgody na stosowanie
                  Cookies poprzez dokonanie odpowiednich ustawień w swojej
                  przeglądarce internetowej (w szczególności umożliwienie lub
                  zablokowanie stosowania plików „cookies”).
                </li>
                <li>
                  użytkownik Serwisu może dokonać w każdym czasie zmiany
                  ustawień dotyczących Cookies – szczegółowe informacje o
                  możliwości i sposobach obsługi plików Cookies dostępne są w
                  ustawieniach oprogramowania (przeglądarki internetowej).
                  Przykładowe opcje edytowania ustawień w popularnych
                  przeglądarkach:
                  <ul>
                    <li>
                      Mozilla FireFox:{' '}
                      <a href='https://support.mozilla.org/pl/kb/ciasteczka'>
                        https://support.mozilla.org/pl/kb/ciasteczka
                      </a>
                    </li>
                    <li>
                      Internet Explorer:{' '}
                      <a href='http://www.support.microsoft.com/kb/278835/pl'>
                        http://www.support.microsoft.com/kb/278835/pl
                      </a>
                    </li>
                    <li>
                      Edge:{' '}
                      <a href='https://privacy.microsoft.com/pl-pl/windows-10-microsoft-edge-and-privacy'>
                        https://privacy.microsoft.com/pl-pl/windows-10-microsoft-edge-and-privacy
                      </a>
                    </li>
                    <li>
                      Google Chrome:{' '}
                      <a href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pl'>
                        https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pl
                      </a>
                    </li>
                    <li>
                      Opera:{' '}
                      <a href='https://help.opera.com/pl/latest/web-preferences/#cookies.'>
                        https://help.opera.com/pl/latest/web-preferences/#cookies.
                      </a>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
          </ol>
        </article>
        <article>
          <h3>§5 Postanowienia końcowe:</h3>
          <ol>
            <li>
              Konto użytkownika może wygasnąć po upływie okresu 6 miesięcy
              nieaktywności.
            </li>
            <li>
              Administrator serwisu zastrzega sobie prawo do zmiany regulaminu w
              dowolnym momencie. Zmiany wchodzą w życie z chwilą ich
              opublikowania w serwisie.
            </li>
            <li>
              Wszelkie spory związane z korzystaniem z serwisu rozstrzygane są
              przez sąd właściwy dla siedziby administratora serwisu.
            </li>
          </ol>
        </article>
      </div>
    </Card>
  );
};

export default Statute;
