import React, { useContext } from 'react';
import styles from './Breadcrumbs.module.sass';
import {Breadcrumbs as MUIBreadcrumbs,Link,Typography,} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Icon from '../Icon';
import JZWITContext from 'context/jzwitContext';

const Breadcrumbs = props => {
  const jzwitCntx = useContext(JZWITContext);
  const {history,location: { pathname },} = props;
  const pathnames = pathname.slice(props.slice).split('/').filter(x => x);

  return (
    <div className={styles.Breadcrumb}>
      <MUIBreadcrumbs aria-label='breadcrumb' color='initial'>
        {pathnames ? (
          <Link
            onClick={() => history.push(props.history_push)}
            className={styles.JZWIT}
          >
            <Icon
              name={props.icon_name}
              size={24}
              className={styles.JZWITIcon}  
            />
            {props.headline}
          </Link>
        ) : (
          <Typography> Home </Typography>
        )}
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index - 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const nameToRoute = pathnames[index - 1];

          return isLast ? (
            <Typography key={name} className={styles.last}>  
              {props.technologyName}
            </Typography>
          ) : (
            <Link
              key={name}
              onClick={() => {
                if (nameToRoute === undefined) {
                  jzwitCntx.setJzwitPath(`/jak-zaczac-w-it`);
                } else {
                  jzwitCntx.setJzwitPath(
                    `/jak-zaczac-w-it${routeTo}${nameToRoute}`
                  );
                }
                history.push(jzwitCntx.jzwitPath);
              }}
            >
              {name}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
};

export default withRouter(Breadcrumbs);