import React, { useState, useContext } from 'react';
import cn from 'classnames';
import styles from './Control.module.sass';
import Icon from 'components/Icon';
import Answer from 'components/Answer';
import AuthContext from 'context/AuthContext';
import api from 'api/devscntr';
import useAxiosFunction from 'hooks/useAxiosFunction';
import axios from 'axios';
import ModalLogin from 'components/ModalLogin';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import { showLoginModal } from 'utils/showLoginModal';
import { jzwitv2Endpoints } from 'api/endpoints';

const types = {
  post: '/posts/article/comment-emote/',
  course: '/course/course-comment-emote/',
  jzwit: `${jzwitv2Endpoints.commentEmotion}`,
};

const Control = ({
  valueAnswer,
  setValueAnswer,
  handleReply,
  comment,
  type,
}) => {
  const [visibleFavorite, setVisibleFavorite] = useState(false);
  const [visibleReply, setVisibleReply] = useState(false);

  const authCtx = useAuth();
  const reactionUrl = types[type] ?? '';

  const getReactionNum = () => {
    if (comment?.has_liked) return 1;
    if (comment?.has_disliked) return 2;
    return 0;
  };

  const [reactionNumber, setReactionNumber] = useState(getReactionNum());
  const [reactionsCount, setReactionsCount] = useState(
    type === 'jzwit' ?
      {
        like: comment?.likes_count ? comment?.likes_count?.likes : 0,
        dislike: comment?.likes_count ? comment?.likes_count?.dislikes : 0,
      } :
      {
        like: comment?.likes_count ? comment?.likes_count?.like : 0,
        dislike: comment?.likes_count ? comment?.likes_count?.dislike : 0,
      }
  );

  const reactionApi = async reaction => {
    let headers = null;
    switch (type) {
      case 'post':
        headers = {
          course_id: comment.id,
          comment: comment.id,
          emote: reaction,
        };
        break;
      case 'course':
        headers = {
          course_id: comment.id,
          comment: comment.id,
          emote: reaction,
        };
        break;
      case 'jzwit':
        headers = {
          comment: comment.id,
          emotion: reaction,
        };
        break;
      default:
        headers = null;
        break;
    }

    if (!headers) return;

    try {
      const response = await devscntrAuth.post(reactionUrl, {
        ...headers,
      });
      console.log({ CommentControlResponse: response });
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
    }
  };

  const [allowClick, setAllowClick] = useState(true);
  const emotionClickTimeout = 1000;

  const handleLike = () => {
    if (reactionUrl === '') return;
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    setAllowClick(false);
    if (reactionNumber == 1) return;
    if (!allowClick) return;
    setTimeout(() => setAllowClick(true), emotionClickTimeout);

    setReactionsCount(prev => (
      console.log(prev),
      type === 'jzwit' ?
        {
          like: prev.like + 1,
          dislike: comment?.likes_count ? comment?.likes_count?.dislikes : 0,
        } :
        {
          like: prev.like + 1,
          dislike: comment?.likes_count ? comment?.likes_count?.dislike : 0,
        }
    ));
    setReactionNumber(1);
    reactionApi(1);
  };

  const handleDislike = () => {
    if (reactionUrl === '') return;
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    setAllowClick(false);
    if (reactionNumber == 2) return;
    if (!allowClick) return;
    setTimeout(() => setAllowClick(true), emotionClickTimeout);

    setReactionsCount(prev => (
      type === 'jzwit' ?
        {
          like: comment?.likes_count ? comment?.likes_count?.likes : 0,
          dislike: prev.dislike + 1,
        } :
        {
          like: comment?.likes_count ? comment?.likes_count?.like : 0,
          dislike: prev.dislike + 1,
        }
    ));
    setReactionNumber(2);
    reactionApi(2);
  };

  return (
    <>
      <div className={styles.control}>

        <div className={styles.favorite} onClick={handleLike}>
          <Icon
            name={reactionNumber == 1 ? 'thumbs-up-fill' : 'thumbs-up'}
            size={20}
            viewBox='0 0 24 24'
          />
          <span>{reactionsCount.like}</span>
        </div>
        <div className={styles.favorite} onClick={handleDislike}>
          <Icon
            name={reactionNumber == 2 ? 'thumbs-down-fill' : 'thumbs-down'}
            size={20}
            viewBox='0 0 24 24'
          />
          <span>{reactionsCount.dislike}</span>
        </div>
        <button
          className={cn(styles.reply, { [styles.active]: visibleReply })}
          onClick={() => setVisibleReply(true)}
        >
          <span>
            <Icon name='repeat' size='20' />
          </span>
          Odpowiedz
        </button>
      </div>
      <Answer
        className={cn(styles.answer, { [styles.show]: visibleReply })}
        avatar={authCtx.userImg || '/images/default-avatar.png'}
        onClose={() => setVisibleReply(false)}
        currentValue={valueAnswer}
        setCurrentValue={setValueAnswer}
        onSendReply={() => {
          handleReply();
          setVisibleReply(false);
        }}
      />
    </>
  );
};

export default Control;
