import React, { useState, useEffect } from 'react';

const JZWITContext = React.createContext();

export const JZWITContextProvider = props => {
  const [jzwitPath, setJzwitPath] = useState('/jak-zaczac-w-it');
  const [jzwitUrl, setJzwitUrl] = useState('/jak-zaczac-w-it')

  return (
    <JZWITContext.Provider
      value={{
        jzwitPath: jzwitPath,
        setJzwitPath: setJzwitPath,
        jzwitUrl: jzwitUrl,
        setJzwitUrl: setJzwitUrl
      }}
    >
      {props.children}
    </JZWITContext.Provider>
  );
};

export default JZWITContext;
