import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './ForeingLanguages.module.sass';
import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';
import { getKeyByValue } from 'utils';

const ForeignLanguages = ({
  languages,
  setLanguages,
  setLanguagesRemoveList,
}) => {
  const [language, setLanguage] = useState('');
  const [level, setLevel] = useState('Wybierz poziom');

  const levels = {
    1: 'Początkujący',
    2: 'Podstawowy',
    3: 'Średnio zaawansowany',
    4: 'Ponad średnio zaawansowany',
    5: 'Zaawansowany',
    6: 'Biegły',
  };

  const addButton = () => {
    setLanguages(languages => [
      ...languages,
      { language: language, level: getKeyByValue(levels, level) },
    ]);
    setLanguage('');
    setLevel('Wybierz poziom');
  };

  const handleRemove = (e, index) => {
    e.preventDefault();

    for (let i = 0; i < languages.length; i++) {
      if (index === i) {
        languages[i].remove = true;
        setLanguagesRemoveList(languagesRemoveList => [
          ...languagesRemoveList,
          languages[i],
        ]);
      }
    }

    setLanguages(prev => prev.filter((_, i) => index !== i));
  };

  return (
    <div className={styles.container}>
      <div className={cn('title-blue', styles.header)}>
        <div className={styles.header_text}>Języki obce</div>
      </div>
      {languages.map((language, index) => (
        <div className={styles.input_container} key={index}>
          <input
            className={cn(styles.input, styles.input_title)}
            value={language.language}
            maxLength='100'
            readOnly
            disabled
          />
          <input
            className={cn(styles.input, styles.input_level)}
            value={levels[parseInt(language.level)]}
            maxLength='100'
            readOnly
            disabled
          />
          <button
            className={cn('button', styles.remove_button)}
            onClick={e => handleRemove(e, index)}
          >
            <Icon name='close' size={18} />
          </button>
        </div>
      ))}
      <div className={styles.input_container}>
        <input
          placeholder='Podaj język'
          className={cn(styles.input, styles.input_title)}
          value={language}
          onChange={e => setLanguage(e.target.value)}
          maxLength='100'
        />
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          classDropdown={styles.dropdownBody}
          value={level}
          setValue={setLevel}
          options={Object.values(levels)}
        />
        <button className={cn('button', styles.addButton)} onClick={addButton}>
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default ForeignLanguages;
