export const socialMedia = [
  {
    icon: 'facebook',
    url: 'https://www.facebook.com/groups/577615503221631',
  },
  {
    icon: 'discord',
    url: 'https://discord.gg/WzFAhq7KrJ',
  },
  // {
  //   icon: "instagram",
  //   url: "https://www.instagram.com/",
  // },
  // {
  //   icon: "twitter",
  //   url: "https://twitter.com/",
  // },
];

export const fileSizes = {
  avatar: 2,
  background: 5,
  thumbnail: 3,
};

export const currencyList = [
  {
    country: 'POLAND',
    currency: 'złoty',
    code: 'PLN',
  },
];

// PAGE SIZE IN LIST PAGES
export const pageSize = 10;
export const adminPageSize = 10;

// DELETE CONFIRM TIMEOUT IN miliseconds
export const deleteTimeout = 0;

// BLOCK USER CONFIRM TIMEOUT IN miliseconds
export const blockUserTimeout = 0;

// EMOTION CHANGE DELAY IN MILISECONDS
export const emotionChangeDelay = 1000;

export const maxCommentLength = 500;

export const reportDescriptionMaxChars = 200;
export const reportTitles = [
  'Spam',
  'Nieodpowiednia treść',
  'Agresywne lub szkodliwe zachowanie',
  'Mowa nienawiści',
  'Inne',
];

export const companySize = ['1', '10', '50', '100', '500', '1000'];

export const jobContractType = ['Zlecenie', 'UOP', 'B2B', 'UOD'];
export const jobSeniority = [
  //'intern',
  'trainee',
  'junior',
  'regular',
  'mid',
  'senior',
  'lead',
];
export const jobSeniorityLevels = {
  intern: 1,
  trainee: 2,
  junior: 3,
  regular: 4,
  mid: 5,
  senior: 6,
  lead: 7,
};
export const jobFormat = ['stacjonarnie', 'do wyboru', 'zdalnie'];
export const jobRecruitment = ['online', 'stacjonarnie'];
export const jobLevel = [
  'Intern',
  'Trainee',
  'Junior',
  'Regular',
  'Mid',
  'Senior',
  'Lead',
];
export const jobSortingOptions = [
  {
    name: 'Najnowsze',
    value: 'newest',
  },
  {
    name: 'Najstarsze',
    value: 'oldest',
  },
  {
    name: 'Najlepiej płatne',
    value: 'highestpaid',
  },
  {
    name: 'Najniżej płatne',
    value: 'lowestpaid',
  },
  {
    name: 'Największe doświadczenie',
    value: 'highestexp',
  },
  {
    name: 'Najniższy próg wejścia',
    value: 'lowestexp',
  },
];

export const courseFormat = ['artykuł', 'wideo', 'online', 'stacjonarny'];
export const courseDifficulty = [
  'początkujący',
  'średnio zaawansowany',
  'ekspert',
];
export const courseLanguage = ['PL', 'ENG'];
export const courseSortingOptions = [
  {
    name: 'Najnowsze',
    value: 'newest',
  },
  {
    name: 'Najstarsze',
    value: 'oldest',
  },
  {
    name: 'Cena (malejąco)',
    value: 'cheapest',
  },
  {
    name: 'Cena (rosnąco)',
    value: 'mostexpensive',
  },
  // {
  //   name: 'Wyświetlenia malejąco',
  //   value: 'views'
  // },
  // {
  //   name: 'Wyświetlenia rosnąco',
  //   value: '-views'
  // },
  // {
  //   name: 'Najlepiej oceniane',
  //   value: 'bestrated'
  // },
  // {
  //   name: 'Najbardziej lubiane',
  //   value: 'mostliked'
  // },
  // {
  //   name: 'Ostatnio aktualizowane',
  //   value: 'lastupdate'
  // }
];

export const postSortingOptions = [
  {
    name: 'Najnowsze',
    value: 'newest',
  },
  {
    name: 'Najstarsze',
    value: 'oldest',
  },
  {
    name: 'Wyświetlenia malejąco',
    value: 'views'
  },
  {
    name: 'Wyświetlenia rosnąco',
    value: '-views'
  },
  {
    name: 'Najlepiej oceniane',
    value: 'bestrated'
  },
  {
    name: 'Najbardziej lubiane',
    value: 'mostliked'
  },
];

export const adminUsersRoles = [
  {
    name: 'admin',
    value: 'admin',
  },
  {
    name: 'twórca',
    value: 'author',
  },
  {
    name: 'moderator',
    value: 'moderator',
  },
];

export const adminUsersStatuses = [
  {
    name: 'Aktywny',
    value: 'false',
  },
  {
    name: 'Zablokowany',
    value: 'true',
  },
];
