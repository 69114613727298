import React from 'react';
import { Link } from 'react-router-dom';
import styles from './RelatedCourses.module.sass';
import cn from 'classnames';
import MiniCourse from 'components/MiniCourse';
import Card from 'components/Card';
import uuid from 'react-uuid';
import { courseSortingOptions } from 'data/dataArrays';
import HorizontalScroll from 'components/HorizontalScroll';

const RelatedCourses = ({ items, className }) => {

  return (
    <Card
      className={cn(styles.card, className)}
      title='Powiązane kursy'
      classTitle='title-purple'
      head={
        <Link
          to={`/courses?sortby=${courseSortingOptions[0].value}`}
          className={cn('button', styles.button)}
        >
          Więcej
        </Link>
      }
    >
      <div className={styles.container}>
        {items.length > 0 ? (
          <HorizontalScroll className={styles.list} controls wide>
            {items.map((item,index) => (
              <div className={styles.course_container} key = {index}>
                <MiniCourse
                  classNames={styles.item}
                  related
                  article={item}
                  key={uuid()}
                />
              </div>
            ))}
          </HorizontalScroll>
        ) : (
          <p className={styles.info}>Brak powiązanych kursów</p>
        )}
      </div>
    </Card>
  );
};

export default RelatedCourses;
