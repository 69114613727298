import React, { useEffect, useState, useCallback, useRef } from 'react';
import styles from './Posts.module.sass';
import TooltipGlodal from 'components/TooltipGlodal';
import axios from 'axios';
import LatestComments from 'components/LatestComments';
import LatestCourses from 'components/LatestCourses';
import { postSortingOptions } from 'data/dataArrays';

import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import MiniPost from 'components/MiniPost';
import { pageSize } from 'data/dataArrays';
import Loader from 'components/MiniPost/Loader';
import { postsEndpoints } from 'api/endpoints';
import { useHistory, useLocation } from 'react-router';
import AverageSalary from 'components/AverageSalary';
import Pagination from 'components/Pagination';
import CategoriesFilter from 'components/CategoriesFilter';
import Dropdown from 'components/Dropdown';

const postsPerPage = pageSize;

const Posts = () => {
  // UTILS----------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  const history = useHistory();
  const searchUrl = useLocation().search;

  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [sortBy, setSortBy] = useState(postSortingOptions[0].name);

  const [params, setParams] = useState('');
  const [tags, setTags] = React.useState([]);
  const [articles, setArticles] = React.useState([]);
  const [category, setCategory] = useState([])

  // UTILS----------------------------------------------------------------------------UTILS
  // PAGINATION----------------------------------------------------------------PAGINATION

  const [currentPage, setCurrentPage] = useState(1);
  const [totalArticles, setTotalArticles] = useState(1);
  const [refresher, setRefresher] = useState(false);

  // SCROLL TOP ON PAGE CHANGE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // PAGINATION----------------------------------------------------------------PAGINATION
  // GET URL PARAMS----------------------------------------------------------GET URL PARAMS

  const getUrlParams = () => {
    const loadedCategory = new URLSearchParams(searchUrl).get('categories');
    if (loadedCategory !== null && loadedCategory !== undefined) {
      const formatedCategory = loadedCategory.split('&')
      setCategory(formatedCategory)
    }

    let index;

    const sortby = new URLSearchParams(searchUrl).get('sortby');
    index = postSortingOptions.map(item => item.value).indexOf(sortby);

    if (index > 0) setSortBy(postSortingOptions[index].name);
    else setSortBy(postSortingOptions[0].name);

    const page = new URLSearchParams(searchUrl).get('page');
    if (page !== null) setCurrentPage(parseInt(page));

    let tags = new URLSearchParams(searchUrl).get('tags') || [];
    if (tags.length > 0) setTags(tags.split('&'));
    else setTags([]);

    setIsMounted(true);
  };

  useEffect(() => {
    getUrlParams();
    if (!searchUrl) {
      setCategory([])
    }
  }, [searchUrl]);

  const changeUrlParams = () => {
    const sortMethodIndex = postSortingOptions
      .map(item => item.name)
      .indexOf(sortBy);

    let params = '';
    if (sortMethodIndex > 0)
      params += `sortby=${postSortingOptions[sortMethodIndex].value}`;

    // PAGE
    if (currentPage > 1) {
      params += `page=${currentPage}`;
    }

    // TAGS
    if (tags?.length > 0) {
      params += `${currentPage > 1 ? '&' : ''}tags=${tags.join('%26')}`;
    }

    //CATEGORY
    if (category?.length > 0) {
      let temp = []
      category.forEach(element => {
        temp.push(encodeURIComponent(element))
      });
      params += `&categories=${temp.join('%26')}`;
    }

    setParams(params);
  };

  useEffect(() => {
    changeUrlParams();
  }, [currentPage, tags, category, sortBy]);

  useEffect(() => {
    if (isMounted) {
      history.push({
        search: params,
      });

      setTotalArticles(1);
      setArticles([]);
      setRefresher(prev => !prev);
    }
  }, [params]);

  // GET URL PARAMS----------------------------------------------------------GET URL PARAMS
  // GET CONTENT----------------------------------------------------------------GET CONTENT

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (isMounted) {
      setArticles([]);
      fetchPosts(cancelToken);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [searchUrl, refresher, isMounted]);

  const fetchPosts = async cancelToken => {
    setIsLoading(true);
    const preparedTags = tags.map(tag => tag.replaceAll('#', '%23'));
    const preparedCategories = []
    category.forEach(element => {
      preparedCategories.push(encodeURIComponent(element))
    });
    const params =
      '?page=' +
      currentPage +
      (tags.length > 0 ? '&tags=' + preparedTags.join('%26') : '') +
      '&page_size=' +
      postsPerPage +
      (category?.length > 0 ? '&categories=' + preparedCategories.join('%26') : '');

    try {
      const response = await axiosInstance.request({
        method: 'GET',
        url: postsEndpoints.show + params,
        cancelToken: cancelToken.token,
      });

      const resData = response.data.data || [];
      let data = resData.filter(item => item.title) || [];
      if (authCtx.isAdmin) {
        data = resData;
      }

      const loadedArticles = data.map(item => (
        <MiniPost key={`post_${item.id}`} article={item} />
      ));

      setTotalArticles(response.data?.count || 1);
      setArticles(loadedArticles);

      setContentLoaded(true);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('posts canceled');
      } else {
        console.log(error);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.sorting}>
            <div className={styles.dropdown}>
              {/* <Dropdown
                options={postSortingOptions.map(item => item.name)}
                value={sortBy}
                setValue={filter => {
                  setCurrentPage(1);
                  setSortBy(filter);
                }}
              /> */}
            </div>
            <div className={styles.categories}>
              <CategoriesFilter
                className={styles.categories_button}
                categories={category}
                categorySelector={setCategory}
              />
            </div>
          </div>
          {articles.length > 0 ? (
            <>
              {articles}
              {isLoading && <Loader cards={postsPerPage} />}
            </>
          ) : (
            !isLoading && <div className={styles.info}>Brak kursów</div>
          )}
          {isLoading && <Loader cards={postsPerPage * currentPage} />}
          <Pagination
            currentPage={currentPage}
            pageSize={postsPerPage}
            totalCount={totalArticles}
            onPageChange={page => setCurrentPage(page)}
          />
        </div>
        <div className={styles.col}>
          <AverageSalary className={styles.card} limit={3} />
          <LatestCourses className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default Posts;
