import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Notification.module.sass';
import List from './List';
import Filters from './Filters';
import Dropdown from 'components/Dropdown';
import FiltersSmall from 'components/Filters';
import FiltersModal from './FiltersModal';

const filters = [
  'Posty',
  'Komentarze do postów',
  'Komentarze do kursów',
  'Różne',
];

const Notification = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  return (
    <div className={styles.row}>
      <div className={styles.filtersSmall}>
        <FiltersSmall title={'Filtr'}>
          <FiltersModal
            filters={filters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </FiltersSmall>
      </div>
      <div className={styles.cardWrap}>
        <List className={styles.card} filters={selectedFilters} />
      </div>
      <div className={styles.filters}>
        <Filters
          // className={styles.filters}
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </div>
    </div>
  );
};

export default Notification;
