import React, { useState } from 'react';
import cn from 'classnames';
import styles from './FiltersModal.module.sass';
import Card from '../../../components/Card';
import Checkbox from '../../../components/Checkbox';
import Radio from '../../../components/Radio';

const FiltersModal = ({
  className,
  filters,
  selectedFilters,
  setSelectedFilters,
}) => {
  const handleChange = filter => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter(x => x !== filter));
    } else {
      setSelectedFilters(selectedFilters => [...selectedFilters, filter]);
    }
  };

  const [users, setUsers] = useState(false);

  return (
    <div className={cn(styles.filters, className)}>
      <div className={styles.group}>
        {filters.map((x, index) => (
          <Checkbox
            className={styles.checkbox}
            content={x}
            value={selectedFilters.includes(x)}
            onChange={() => handleChange(x)}
            key={index}
            reverse
          />
        ))}
      </div>
      <div className={styles.btns}>
        <button
          className={cn('button-stroke button-small', styles.button)}
          onClick={() => setSelectedFilters([...filters])}
        >
          Zaznacz wszystkie
        </button>
        <button
          className={cn('button-stroke button-small', styles.button)}
          onClick={() => setSelectedFilters([])}
        >
          Odznacz wszystkie
        </button>
      </div>
    </div>
  );
};

export default FiltersModal;
