import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Header.module.sass';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Icon from '../Icon';
import Search from './Search';
import Notification from './Notification';
import User from './User';
import AddButton from './AddButton';
import useAuth from '../../hooks/useAuth';
import useBreakpoints from 'hooks/useBreakpoints';
import Image from 'components/Image';

const Header = ({ sidebarExpanded, onOpen }) => {
  const history = useHistory();
  const location = useLocation();

  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    onOpen();
  };

  const authCtx = useAuth();
  const isAuthor = authCtx.isAuthor;

  const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ['desktop', Infinity],
    ['mobile', 640],
  ];
  useBreakpoints(stylesRef, breakpoints);

  const isSticky = e => {
    const header = document.querySelector('.header-section');
    const scrollTop = window.scrollY;
    scrollTop >= 150
      ? header.classList.add(styles.sticky)
      : header.classList.remove(styles.sticky);
  };

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  return (
    <header
      className={cn(
        'header-section',
        styles.header,
        sidebarExpanded && styles.expanded
      )}
      ref={x => setStylesRef(x)}
    >
      <div className={styles.leftWrap}>
        <button
          className={styles.burger}
          onClick={() => handleClick()}
        ></button>
        <Search className={cn(styles.search, { [styles.visible]: visible })} />
        <button
          className={cn(styles.buttonSearch, { [styles.active]: visible })}
          onClick={() => setVisible(!visible)}
        >
          <Icon name='search' size='24' />
        </button>
      </div>

      <Link className={styles.pic} to='/'>
        <Image
          className={styles.pic}
          src='/images/logo-light.svg'
          srcDark='/images/logo-dark.svg'
          alt='itCNTR'
        />
      </Link>

      <div className={styles.control} onClick={() => setVisible(false)}>
        {isAuthor && authCtx.isLoggedIn && (
          <div className={styles.adb}>
            <AddButton />
          </div>
        )}

        {authCtx.isLoggedIn ? (
          <>
            <Notification className={styles.notification} />
            <User className={styles.user} />
          </>
        ) : (
          <button
            className={cn('button')}
            onClick={() => {
              history.push('/sign-in', {
                from: location.pathname + location.search,
              });
            }}
          >
            Zaloguj się
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
