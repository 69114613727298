import React from 'react';
import cn from 'classnames';
import styles from './InputRadio.module.sass';

const InputRadio = ({
  className,
  id,
  label,
  name,
  value,
  onChange,
  checked,
}) => {
  return (
    <label className={styles.radio}>
      <input
        id={id}
        className={styles.input}
        type='radio'
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <span className={cn(styles.inner, className)}>
        {label && (
          <label
            htmlFor={id}
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: label }}
          ></label>
        )}
        <span className={styles.tick}></span>
      </span>
    </label>
  );
};

export default InputRadio;
