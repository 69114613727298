import React, { useState } from 'react';
import styles from './List.module.sass';
import cn from 'classnames';
import TextInput from 'components/TextInput';
import Icon from 'components/Icon';
import uuid from 'react-uuid';

const List = ({ classNames, list, setList, placeholder }) => {
  const [input, setInput] = useState('');

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
      appendItem(e);
    }
  };

  const handleChange = e => {
    const value = e.target.value;
    const allowedCharacters =
      /^[a-zA-Z0-9ĄąĆćĘęŁłŃńÓóŚśŹźŻż \b\p{L}\n"':!@#$%\^&*\(\)+,.?\-]+$/;
    if (allowedCharacters.test(value) || value === '') setInput(value);
  };

  const appendItem = e => {
    e.preventDefault();
    if (input === '') return;
    setInput('');
    setList(prev => [...prev, input]);
  };

  const deleteItem = index => {
    setList(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <div className={cn(styles.container, classNames && classNames)}>
      <div className={styles.input_with_button}>
        <TextInput
          className={styles.input}
          type='text'
          value={input}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />
        <button
          className={cn('button', styles.input_button)}
          onClick={appendItem}
        >
          +
        </button>
      </div>
      <ul className={styles.items_list}>
        {list.map((item, index) => (
          <li className={styles.item} key={uuid()}>
            <span className={styles.item_content}>
              <Icon name='add' size={20} />
              {item}
            </span>
            <button
              className={styles.item_delete}
              onClick={() => deleteItem(index)}
            >
              <Icon name='close' size={20} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default List;
