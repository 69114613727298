import React from "react";
import styles from "./Item.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";
import Controls from "./Controls";

const Item = ({ article, refresh }) => {
  return (
    <div className={styles.item}>
      <div className={styles.container}>
        <div className={styles.head}>
          <Link to={`/admin/users/${article.author_id}`} className={styles.author}>
            {article.author_display_name}
          </Link>
          <div className={styles.time}>{article.created_at.slice(0, 10)}</div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            {article.title}
            {article.is_solved ? (
              <div className={cn("status-green", styles.status)}>rozwiązane</div>
            ) : (
              <div className={cn("status-yellow", styles.status)}>oczekujące</div>
            )}
          </div>
          <div className={styles.description}>{article.description}</div>
        </div>
      </div>
      <Controls id={article.id} refresh={refresh} />
    </div>
  );
};
export default Item;
