import React from 'react';
import styles from './EditTag.module.sass';
import cn from 'classnames';
import { adminEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { showSuccess } from 'utils/showSuccess';

const EditTag = ({ tag, setTag, refresh }) => {
  const saveChanges = async () => {
    try {
      const response = await devscntrAuth.put(
        `${adminEndpoints.tags.show}${tag.id}/`,
        {
          name: tag.name,
          colour: tag.colour,
        }
      );
      console.log({ saved: response.data });
      showSuccess('Zapisano zmiany');
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.edit_tag}>
      <div className={styles.input_container}>
        <label htmlFor='tagName'>Nazwa</label>
        <input
          className={styles.input}
          type='text'
          name='tagName'
          value={tag.name}
          onChange={e => setTag(prev => ({ ...prev, name: e.target.value }))}
        />
      </div>
      <div className={styles.input_container}>
        <label htmlFor='tagColor'>Kolor</label>
        <div className={styles.input_color_container}>
          <div className={styles.input_color_box}>
            <input
              className={styles.input_color}
              type='color'
              name='tagColor'
              value={tag.colour}
              onChange={e =>
                setTag(prev => ({ ...prev, colour: e.target.value }))
              }
            />
          </div>
          <input
            className={styles.input}
            type='text'
            name='tagColorText'
            value={tag.colour}
            onChange={e =>
              setTag(prev => ({ ...prev, colour: e.target.value }))
            }
          />
        </div>
      </div>
      <button className={cn('button', styles.save_btn)} onClick={saveChanges}>
        Zapisz zmiany
      </button>
    </div>
  );
};

export default EditTag;
