import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "../../Icon";
import SideSubs from "../../../screens/Subscriptions/SideSubs";
import SidePinGroups from "../../Groups/SidePinGroups";
import AddGroupSideButton from "../../Groups/AddGroupSideButton";

const Dropdown = ({ className, item, visibleSidebar, setValue, onClose }) => {
  const [visible, setVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isMounted) {
      if (!visibleSidebar) {
        setVisible(false);
      }
    } else {
      setIsMounted(true);
    }
  }, [visibleSidebar]);

  const handleClick = () => {
    if (window.innerWidth < 768) onClose();
    setVisible(!visible);
    setValue(true);
  };

  const Head = () => {
    return (
      <button
        className={cn(
          styles.head,
          {
            [styles.active]: pathname.includes(item.slug),
          },
          { [styles.wide]: visibleSidebar }
        )}
        onClick={() => handleClick()}
      >
        <Icon name={item.icon} size="24" />
        {item.title}
        <Icon name="arrow-down" size="24" />
      </button>
    );
  };

  return (
    <div
      className={cn(
        styles.dropdown,
        className,
        { [styles.active]: visible },
        {
          [styles.active]: pathname.includes(item.slug),
        },
        { [styles.wide]: visibleSidebar }
      )}
    >
      {item.add ? (
        <div
          className={cn(styles.top, {
            [styles.active]: pathname.startsWith("/products/add"),
          })}
        >
          <Head />

          {item.addgroups ? (
            <AddGroupSideButton />
          ) : (
            <Link
              className={cn(styles.add, {
                [styles.active]: pathname.startsWith("/products/add"),
              })}
              to="/products/add"
              onClick={onClose}
            >
              <Icon name="plus" size="10" />
            </Link>
          )}
        </div>
      ) : (
        <Head />
      )}

      <div className={styles.body}>
        <div className={styles.subs}>
          {item.dropdown.map(
            (x, index) =>
              x.groups && <SidePinGroups key={index} onClose={onClose} />
          )}
        </div>
        <div className={styles.subs}>
          {item.dropdown.map((x, index) => x.subs && <SideSubs key={index} />)}
        </div>
        {item.dropdown.map(
          (x, index) =>
            !x.groups && (
              <NavLink
                className={styles.link}
                activeClassName={styles.active}
                to={x.url}
                key={index}
                onClick={() => {
                  if (window.innerWidth < 768) onClose();
                }}
                exact
              >
                {x.title}
                {x.counter ? (
                  <div
                    className={styles.counter}
                    style={{ backgroundColor: x.colorCounter }}
                  >
                    {x.counter}
                  </div>
                ) : (
                  <Icon name="arrow-next" size="24" />
                )}
              </NavLink>
            )
        )}
      </div>
    </div>
  );
};

export default Dropdown;
