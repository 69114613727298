import React from "react";
import styles from "./Loader.module.sass";
import cn from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Card from "components/Card";
import { Link } from "react-router-dom";

const Loader = ({ className, cards }) => {
  return (
    <SkeletonTheme>
      <Card className={cn(styles.card, className)} title="Zarobki w branży" classTitle="title-blue">
        <div className={styles.list}>
          <div className={styles.container}>
            <div className={styles.head}>
              <div className={styles.col}>
                <Skeleton width={80} height={20} borderRadius={5} />
              </div>
              <div className={styles.col}>
                <Skeleton width={20} height={20} borderRadius={5} />
                <Skeleton width={100} height={20} borderRadius={5} />
              </div>
            </div>

            <div className={styles.content_head}>
              <Skeleton width={100} height={20} borderRadius={5} />
              <Skeleton width={140} height={20} borderRadius={5} />
            </div>

            <div className={styles.content}>
              <div className={styles.item}>
                <Skeleton width={70} height={15} borderRadius={5} />
                <Skeleton width={130} height={15} borderRadius={5} />
              </div>
              <div className={styles.item}>
                <Skeleton width={90} height={15} borderRadius={5} />
                <Skeleton width={120} height={15} borderRadius={5} />
              </div>
              <div className={styles.item}>
                <Skeleton width={50} height={15} borderRadius={5} />
                <Skeleton width={130} height={15} borderRadius={5} />
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.head}>
              <div className={styles.col}>
                <Skeleton width={80} height={20} borderRadius={5} />
              </div>
              <div className={styles.col}>
                <Skeleton width={20} height={20} borderRadius={5} />
                <Skeleton width={100} height={20} borderRadius={5} />
              </div>
            </div>

            <div className={styles.content_head}>
              <Skeleton width={100} height={20} borderRadius={5} />
              <Skeleton width={140} height={20} borderRadius={5} />
            </div>

            <div className={styles.content}>
              <div className={styles.item}>
                <Skeleton width={70} height={15} borderRadius={5} />
                <Skeleton width={130} height={15} borderRadius={5} />
              </div>
              <div className={styles.item}>
                <Skeleton width={90} height={15} borderRadius={5} />
                <Skeleton width={120} height={15} borderRadius={5} />
              </div>
              <div className={styles.item}>
                <Skeleton width={50} height={15} borderRadius={5} />
                <Skeleton width={130} height={15} borderRadius={5} />
              </div>
            </div>
          </div>

          <Link className={cn("button-stroke", styles.button)} to="/jobs">
            Zobacz oferty pracy
          </Link>
        </div>
      </Card>
    </SkeletonTheme>
  );
};

export default Loader;
