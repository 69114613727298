import React from 'react';
import styles from './GoBackButton.module.sass';
import cn from 'classnames';
import { useHistory } from 'react-router';
import Icon from 'components/Icon';
import { useLocation } from 'react-router-dom';

const GoBackButton = ({ className, wide = false, path }) => {
  const history = useHistory();

  const handleClick = () => {
    if (history.length > 2) {
      history.goBack();
    } else if (path) {
      history.push(path);
    } else {
      history.push('/');
    }
  };

  return (
    <button
      className={cn(
        styles.container,
        { [styles.wide]: wide },
        { [className]: className }
      )}
      onClick={handleClick}
      title={'Wstecz'}
    >
      <Icon name='arrow-next' size={20} />
      Wstecz
    </button>
  );
};

export default GoBackButton;
