import React from 'react';
import styles from './Overlay.module.sass';
import Loader from 'components/Loader';
import cn from 'classnames';

/**
 *
 * @param {{
 *  className?: String,
 *  overlayClassName?: String,
 *  isLoading?: Boolean,
 *  visible?: Boolean,
 *  content?: React.ReactNode,
 *  children: React.ReactNode
 * }} props
 *
 */

const Overlay = props => {
  const { className, overlayClassName, isLoading, visible, content, children } =
    props;

  const wrapperClasses = cn(styles.overlayWrapper, className);
  const overlayClasses = cn(
    styles.overlay,
    {
      [styles.visible]: isLoading || visible,
    },
    overlayClassName
  );

  return (
    <div className={wrapperClasses}>
      <div className={overlayClasses}>
        {isLoading && <Loader className={styles.loader} />}
        {!isLoading && content}
      </div>

      {children}
    </div>
  );
};

export default Overlay;
