import React from 'react';
import styles from './Level.module.sass';
import cn from 'classnames';

const Level = ({ data }) => {
  return (
    <div className={styles.container}>
      <div>Poziom: {data?.level || 'brak danych'}</div>
      <div>Punkty XP (całość): {data?.currentXp || 'brak danych'}</div>
    </div>
  );
};

export default Level;
