import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './AverageSalary.module.sass';
import Card from '../Card';
import useAuth from '../../hooks/useAuth';
import devscntrAuth from '../../api/Instance/devscntrAuth';
import devscntrNoauth from '../../api/Instance/devscntrNoauth';
import { earningsEndpoints } from 'api/endpoints';
import Loader from './Loader';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import useDarkMode from 'use-dark-mode';
import SalaryDetails from './SalaryDetails';

const AverageSalary = ({ className }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(true);
  const isDarkMode = useDarkMode().value;
  const [salariesData, setSalariesData] = useState([]);

  const getData = async () => {
    try {
      const temp = await axiosInstance.get(`${earningsEndpoints.earnings}`, {
        headers: {
          method: 'panel',
        },
      });
      setSalariesData(temp?.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const sortCategories = data => {
    const temp = [];
    for (let i = 0; i < data.length; i++) {
      temp.push(data[data.length - i - 1]);
    }
    return temp;
  };

  return isLoading ? (
    <Loader className={className} cards={1} />
  ) : (
    <Card
      className={cn(styles.card, className)}
      title='Zarobki w branży'
      classTitle='title-blue'
    >
      <div className={styles.container}>
        <div className={styles.swiper}>
          <Swiper
            className='mySwiper'
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true, el: '.swiper-pagination' }}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
          >
            {sortCategories(salariesData).map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <SalaryDetails item={item} isDarkMode={isDarkMode} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div
          className='swiper-pagination'
          style={
            isDarkMode
              ? {
                  '--swiper-pagination-color': '#999999',
                  '--swiper-pagination-bullet-inactive-color': '#404040',
                  '--swiper-pagination-bullet-inactive-opacity': '1',
                  '--swiper-pagination-bullet-size': '8px',
                  '--swiper-pagination-bullet-horizontal-gap': '6px',
                }
              : {
                  '--swiper-pagination-color': '#b0b0b0',
                  '--swiper-pagination-bullet-inactive-color': '#ebebeb',
                  '--swiper-pagination-bullet-inactive-opacity': '1',
                  '--swiper-pagination-bullet-size': '8px',
                  '--swiper-pagination-bullet-horizontal-gap': '4px',
                }
          }
        ></div>
      </div>
      <Link className={cn('button-stroke', styles.button)} to='/zarobki-w-it'>
        Porównaj zarobki w IT
      </Link>
    </Card>
  );
};

export default AverageSalary;
