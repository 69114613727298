import React, { useEffect, useState } from 'react';
import styles from './LoginWithGoogle.module.sass';
import { useLocation } from 'react-router-dom';
import Loader from 'components/Loader';
// import cn from 'classnames';
// import devscntrNoauth from 'api/Instance/devscntrNoauth';
// import { accountsEndpoints } from 'api/endpoints';
// import { Link, useLocation } from 'react-router-dom';
// import Image from 'components/Image';
// import useAuth from 'hooks/useAuth';

const LoginWithGoogle = ({ classNames }) => {
  // UTILS-----------------------------------------------------------UTILS

  const location = useLocation();
  const params = new URLSearchParams(location.hash);
  const paramsEntries = [...params.entries()];
  const tokenEntry = paramsEntries.filter(item =>
    item[0].includes('access_token')
  );
  const access_token = tokenEntry.length > 0 ? tokenEntry[0][1] : null;

  // UTILS-----------------------------------------------------------UTILS
  // PASS DATA---------------------------------------------------PASS DATA

  useEffect(() => {
    if (window.opener) {
      // Send token to main window
      window.opener.postMessage(access_token);
      // Close popup
      window.close();
    }
  });

  // PASS DATA---------------------------------------------------PASS DATA

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.logo}
        // style={{ alignSelf: loading ? 'center' : 'flex-start' }}
        style={{ alignSelf: 'center' }}
        to='/'
      >
        <img
          className={styles.pic}
          src='/images/content/google.svg'
          alt='Google'
        />
      </div>

      <div className={styles.loader_title}>Trwa logowanie z Google</div>
      <Loader className={styles.loader} />

      {/* {loading ? (
        <>
          <div className={styles.loader_title}>Trwa logowanie z Google</div>
          <Loader className={styles.loader} />
        </>
      ) : (
        <div className={styles.body}>
          {success ? (
            <>
              <div className={cn(styles.loader_title)}>
                Logowanie zakończono pomyślnie.
                <br />
                Nastąpi przekierowanie.
              </div>
              <Loader className={styles.loader} />
              <Link to='/' className={cn('button', styles.button)}>
                Przejdź do aplikacji
              </Link>
            </>
          ) : (
            <>
              <div className={cn(styles.loader_title)}>
                Logowanie nie powiodło się
                <div className={styles.errorNote}>Spróbuj ponownie później</div>
              </div>

              <Link to='/sign-in' className={cn('button', styles.button)}>
                <span>Przejdź do logowania</span>
              </Link>
            </>
          )}
        </div>
      )} */}
    </div>
  );
};

export default LoginWithGoogle;
