import React, { useEffect, useState } from 'react';
import styles from './CvDetails.module.sass';
import cn from 'classnames';
import { accountsEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import List from './List';

const CvDetails = ({ classNames, cvData, image }) => {
  // UTILS ----------------------------------------------------------------UTILS

  const [data, setData] = useState(cvData || null);

  // UTILS ----------------------------------------------------------------UTILS
  // GET CV --------------------------------------------------------------GET CV

  const getCv = async signal => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: `${accountsEndpoints.documents.CV.show}`,
        signal: signal,
      });
      setData(response.data);
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    !cvData && getCv(signal);

    return () => {
      abortController.abort();
    };
  }, [cvData]);

  // GET CV --------------------------------------------------------------GET CV

  return (
    data && (
      <div className={styles.container}>
        <section className={styles.section}>
          <h4 className={styles.section_header}>Dane osobowe</h4>
          <div className={styles.section_content}>
            <div className={styles.image}>
              <img src={image || '/images/default-avatar.png'} alt='' />
            </div>
            <div className={styles.personal_data}>
              <span className={styles.data_row}>
                <b>Imię: </b>
                {data.firstname}
              </span>
              <span className={styles.data_row}>
                <b>Nazwisko: </b>
                {data.lastname}
              </span>
              <span className={styles.data_row}>
                <b>Tel.: </b>
                {data.phone}
              </span>
              <span className={styles.data_row}>
                <b>Email: </b>
                {data.email}
              </span>
              <span className={styles.data_row}>
                <b>Strona WWW: </b>
                {data?.websites !== 'brak' ? (
                  <a href={data.websites} target='_blank'>
                    {data.websites}
                  </a>
                ) : (
                  'brak'
                )}
              </span>
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <h4 className={styles.section_header}>Umiejętności</h4>
          <List list={data.skills.split(',')} />
        </section>

        <section className={styles.section}>
          <h4 className={styles.section_header}>Doświadczenie</h4>
          <List list={data.experience.split(',')} />
        </section>

        <section className={styles.section}>
          <h4 className={styles.section_header}>Edukacja</h4>
          <List list={data.education.split(',')} />
        </section>

        <section className={styles.section}>
          <h4 className={styles.section_header}>Języki</h4>
          <List list={data.languages.split(',')} />
        </section>

        <section className={styles.section}>
          <h4 className={styles.section_header}>Certyfikaty</h4>
          <List list={data.certificates.split(',')} />
        </section>

        <section className={styles.section}>
          <h4 className={styles.section_header}>Zainteresowania i hobby</h4>
          <div className={styles.section_content}>{data.hobbies}</div>
        </section>

        <section className={styles.footer}>
          <h4 className={styles.footer_header}>Zgody</h4>Użytkownik wyraził
          zgodę na przetwarzanie danych osobowych w celu rekrutacji
        </section>
      </div>
    )
  );
};

export default CvDetails;
