import React from 'react';
import styles from './Item.module.sass';
import cn from 'classnames';
import Icon from 'components/Icon';

const Item = ({ icon, children }) => {
  return (
    children &&
    icon && (
      <div className={styles.container}>
        <div className={styles.icon}>
          <Icon name={icon} size={20} />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    )
  );
};

export default Item;
