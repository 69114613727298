export const appPaths = {
  posts: {
    list: '/posts',
    detail: '/post',
  },
  courses: {
    list: '/courses',
    detail: '/course',
  },
  jobs: {
    list: '/jobs',
    detail: '/jobs',
  },
};
