import styles from './Users.module.sass';
import uuid from 'react-uuid';
import cn from 'classnames';
import Author from 'components/MiniItemsLayout/Author';

export default function Users({ users, className, forAdmin }) {
  return (
    <div className={cn(styles.users, className && className)}>
      {users?.map(item => (
        <Author
          key={uuid()}
          id={item?.id}
          username={item?.username || item?.display_name}
          image={item?.image}
          isRss={item?.is_rss}
          forAdmin={forAdmin}
        />
      ))}
    </div>
  );
}
