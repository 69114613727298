import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './PromoCodesSection.module.sass';
import Card from 'components/Card';
import Form from 'components/Form';
import Dropdown from 'components/Dropdown';
import DeleteButton from '../Drafts/DeleteButton';
import devscntrAuth from 'api/Instance/devscntrAuth';
import CodesTable from './CodesTable';
import uuid from 'react-uuid';
import Checkbox from 'components/Checkbox';
import Loader from '../Loader';
import PromoCodes from '../PromoCodes';
import {
    sortOptionsCodes,
    pageLimitOptions,
} from '../Drafts/utilities/options';
import Filters from 'components/Filters';
import FilterSettings from '../Drafts/FilterSettings';
import CourseChoice from './CourseChoice';

export default function PromoCodesSection(
    {
        title,
        titleColor,
    }
) {
    const [checkedPublications, setCheckedPublications] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [itemsSelectedQuantity, setItemsSelectedQuantity] = useState(0);

    const [loadingPublications, setLoadingPublications] = useState(true);
    const [publicationsList, setPublicationsList] = useState([]);
    const [publicationsFailed, setPublicationsFailed] = useState();
    const havePublications = publicationsList.length > 0;

    const [reRender, setReRender] = useState(0);
    const reRenderHandler = () => setReRender(latest => latest + 1);
    const controller = new AbortController();
    const mountedRef = useRef(true);

    const [sortingName, setSortingName] = useState(sortOptionsCodes[0].name);
    const [sortingValue, setSortingValue] = useState(sortOptionsCodes[0].value);
    const sortOptionsNameArray = sortOptionsCodes.map(x => x.name);

    const [search, setSearch] = useState('');
    const prevSearch = useRef('');

    const [courseChoiceRefresh, setCourseChoiceRefresh] = useState(false);

    const searchHandler = value => {
        prevSearch.current = search;
        setSearch(value);
    };

    const isFirstFetch = useRef(true);
    const [currentPage, setCurrentPage] = useState(1);
    const prevPage = useRef(1);
    const [lastPage, setLastPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(pageLimitOptions[0]);

    const publicationsQuery = `?&page_size=${pageLimit}&page=${currentPage}&sort_by=${sortingValue}${search.toLowerCase() && '&search='}${search.toLowerCase()}`;

    useEffect(() => {
        if (isCheckAll) {
            setCheckedPublications([]);
            setIsCheckAll(false);
            setItemsSelectedQuantity(0);
        }
    }, [publicationsList]);

    useEffect(() => {
        setCheckedPublications([]);
        setIsCheckAll(false);
        setItemsSelectedQuantity(0);
        getPublications()
    }, [reRender]);

    const handleCourseChoiceRefresh = () => {
        setCheckedPublications([]);
        setIsCheckAll(false);
        setItemsSelectedQuantity(0);
        getPublications()
    }

    const handleEditCodeRefresh = () =>
    {
        getPublications();
    }

    useEffect(() => {
        setItemsSelectedQuantity(checkedPublications.length);
    }, [checkedPublications]);

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);

        setIsCheckAll(latest => {
            latest
                ? setCheckedPublications(publicationsList.map(x => x))
                : setCheckedPublications([]);
            return latest;
        });
    };

    const handleSelect = e => {
        const row = e.target.closest('.row');
        const rowId = +row.getAttribute('data-item-id');
        const checked = e.target.checked;

        const currPublication = publicationsList.find(
            pub => pub.id === rowId
        );

        setIsCheckAll(false);

        if (
            !checkedPublications.find(
                checked => checked.id === rowId
            )
        ) {
            setCheckedPublications(latest => [...latest, currPublication]);
        }

        if (!checked) {
            setCheckedPublications(latest =>
                latest.filter(obj => !(obj.id === rowId))
            );
        }
    };

    useEffect(() => {
        let delayDebounceFn;

        if (
            isFirstFetch.current ||
            (search === prevSearch.current && currentPage !== prevPage.current)
        ) {
            getPublications();
            isFirstFetch.current = false;
        }

        else {
            delayDebounceFn = setTimeout(() => {
                getPublications();
            }, 500);
        }

        return () => {
            controller.abort();
            clearTimeout(delayDebounceFn);
        };
    }, [sortingValue, search, reRender, pageLimit, currentPage]);

    const getPublications = async () => {
        setPublicationsFailed(false);
        setLoadingPublications(true);

        try {
            const response = await devscntrAuth.get(
                `/course/course-promo-code/${publicationsQuery}`,
                {
                    signal: controller.signal,
                }
            );
            const preparedData = response?.data?.data.map((item) => ({ ...item, type: 'code' }))
            setPublicationsList(preparedData);
            setLastPage(response?.data?.pages);
        } catch (err) {
            console.error(err);
            mountedRef.current && !havePublications && setPublicationsFailed(true);
        }

        mountedRef.current && setLoadingPublications(false);
    };

    useEffect(() => {
        const sortOptionID = sortOptionsCodes.findIndex(obj => obj.name === sortingName);
        setSortingValue(sortOptionsCodes[sortOptionID].value);
    }, [sortingName]);

    useEffect(() => {
        setLoadingPublications(true);
    }, [sortingValue]);

    useEffect(() => {
        setLoadingPublications(true);
        prevPage.current = currentPage;
        setCurrentPage(1);
    }, [sortingValue, pageLimit, search, reRender]);

    useEffect(() => {
        if (!isFirstFetch) {
            setSearch(latest => {
                prevSearch.current = latest;
                return latest;
            });
        }
    }, [currentPage]);

    return (
        <>
            <Card
                className={styles.card}
                classCardHead={styles.head}
                title={title}
                classTitle={cn(titleColor, styles.title)}
                head={
                    <>
                        <Form
                            className={styles.form}
                            value={search}
                            setValue={searchHandler}
                            onSubmit={e => e.preventDefault()}
                            placeholder='Szukaj...'
                            type='text'
                            name='search'
                            icon='search'
                        />
                        <div className={styles.row}>
                            <div className={cn(styles.column, styles.sorting)}>
                                <Dropdown
                                    className={styles.dropdown}
                                    classDropdownHead={styles.dropdownHead}
                                    value={sortingName}
                                    setValue={setSortingName}
                                    options={sortOptionsNameArray}
                                />
                            </div>
                            <div className={styles.buttonPosition}>
                                <div className={styles.column}>
                                    <CourseChoice
                                        checkedPublications={checkedPublications}
                                        disabled={!itemsSelectedQuantity}
                                        refresh={handleCourseChoiceRefresh}
                                    />
                                    <PromoCodes
                                        className={styles.card}
                                        refresh={getPublications}
                                    />
                                    <Filters className={styles.filter} title='Filtrowanie publikacji'>
                                        <FilterSettings
                                            pageLimitValue={pageLimit}
                                            setPageLimit={setPageLimit}
                                            pageLimitOptions={pageLimitOptions}
                                            isPagination={true}
                                        />
                                    </Filters>
                                    <DeleteButton
                                        quantity={itemsSelectedQuantity}
                                        disabled={!itemsSelectedQuantity}
                                        checkedPublications={checkedPublications}
                                        setReRender={reRenderHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            >
                {!loadingPublications && havePublications && (
                    <div>
                        <div className={styles.table_container}>
                            <div className={styles.item}>
                                <Checkbox
                                    onChange={handleSelectAll}
                                    value={isCheckAll}
                                />
                            </div>
                            <div className={styles.item}>
                                Kod
                            </div>
                            <div className={styles.item}>
                                Wartość (zł)
                            </div>
                            <div className={styles.item}>
                                Wartość (%)
                            </div>
                            <div className={styles.item}>
                                Aktywny do
                            </div>
                            <div className={styles.item}>
                                Użycia
                            </div>
                            <div className={styles.item}>
                                Kursy
                            </div>
                        </div>
                        <div className={styles.divider}></div>
                        <div>
                            {publicationsList.map(item => (
                                <CodesTable
                                    handleSelect={handleSelect}
                                    refresh={handleEditCodeRefresh}
                                    item={item}
                                    key={uuid()}
                                    isSelected={
                                        !!checkedPublications.find(
                                            checked =>
                                                checked.id === item.id
                                        )
                                    }
                                />
                            ))}
                        </div>
                    </div>
                )}
                {loadingPublications && <Loader cards={5} />}
                {!loadingPublications && !havePublications && !publicationsFailed && (
                    <p className={styles.info}>Brak kodów rabatowych</p>
                )}
                {!loadingPublications && !havePublications && publicationsFailed && (
                    <p className={styles.info}>
                        Nie udało się pobrać twoich kodów rabatowych. Spróbuj ponownie później.
                    </p>
                )}



                {!loadingPublications && havePublications && (
                    <div className={styles.foot}>
                        {currentPage > 1 && (
                            <button
                                className={cn('button-stroke button-small', styles.button)}
                                style={{ marginRight: '12px' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    setCurrentPage(prevState => {
                                        prevPage.current = currentPage;
                                        return --prevState;
                                    });
                                }}
                            >
                                Poprzednia
                            </button>
                        )}
                        {/* --------------------------- 3 DOTS AFTER FIRST --------------------------- */}
                        {currentPage > 2 && (
                            <>
                                <button
                                    className={styles.pagination_numbers}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        setCurrentPage(() => {
                                            prevPage.current = currentPage;
                                            return 1;
                                        });
                                    }}
                                >
                                    {1}
                                </button>
                                ...
                            </>
                        )}
                        {/* ----------------------- NUMBERS (PREV, CURR, NEXT) ----------------------- */}
                        {currentPage > 1 && (
                            <button
                                className={styles.pagination_numbers}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    setCurrentPage(prevState => {
                                        prevPage.current = currentPage;
                                        return --prevState;
                                    });
                                }}
                            >
                                {currentPage - 1}
                            </button>
                        )}
                        {currentPage !== 0 && (
                            <button
                                className={styles.pagination_numbers}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                {currentPage}
                            </button>
                        )}
                        {currentPage < lastPage && (
                            <button
                                className={styles.pagination_numbers}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    setCurrentPage(prevState => {
                                        prevPage.current = currentPage;
                                        return ++prevState;
                                    });
                                }}
                            >
                                {currentPage + 1}
                            </button>
                        )}
                        {/* --------------------------- 3 DOTS BEFORE LAST --------------------------- */}
                        {currentPage < lastPage - 1 && (
                            <>
                                ...
                                <button
                                    className={styles.pagination_numbers}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        setCurrentPage(() => {
                                            prevPage.current = currentPage;
                                            return lastPage;
                                        });
                                    }}
                                >
                                    {lastPage}
                                </button>
                            </>
                        )}
                        {/* ------------------------------- NEXT BUTTON ------------------------------ */}
                        {currentPage != lastPage && (
                            <button
                                className={cn('button-stroke button-small', styles.button)}
                                style={{ marginLeft: '12px' }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    setCurrentPage(prevState => ++prevState);
                                }}
                            >
                                Następna
                            </button>
                        )}
                    </div>
                )}
            </Card>
        </>
    );
}