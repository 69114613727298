import React, { useState, useEffect } from 'react';
import styles from './CodesTable.module.sass';
import cn from 'classnames';
import Checkbox from 'components/Checkbox';
import CodesDetails from './CodesDetails';
import Icon from 'components/Icon';
import { useMediaQuery } from '@mui/material';
import EditPromoCodes from '../Edit';
import classNames from 'classnames';

export default function CodesTable({
  handleSelect,
  isSelected,
  item,
  refresh,
}) {
  const {
    id,
    code,
    percentage_value,
    number_value,
    number_of_uses_left,
    use_limited,
    time_limited,
    expiration_date,
    number_of_uses_max,
    courses,
  } = item;

  const breakpoint1 = useMediaQuery('(max-width: 1230px)');

  const [detailsVisible, setDetailsVisible] = useState(false);

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        setDetailsVisible(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const closeHandle = () => {
    setDetailsVisible(false);
  };

  return (
    <>
      <div>
        <div className={cn(styles.row, 'row')} data-item-id={id}>
          <div className={styles.item_checkbox}>
            <Checkbox value={isSelected} onChange={handleSelect} />
          </div>
          {!breakpoint1 ? (
            <div className={styles.button_row}>
              <button
                className={classNames(styles.item, styles.name)}
                onClick={() => setDetailsVisible(true)}
              >
                {code}
              </button>
              {number_value ? (
                <div className={styles.item}>
                  {breakpoint1 && <Icon name='diamond' size='20' fill='gray' />}
                  {number_value}zł
                </div>
              ) : (
                <div className={styles.item}>-</div>
              )}
              {percentage_value ? (
                <div className={styles.item}>
                  {breakpoint1 && (
                    <Icon name='promotion' size='20' fill='gray' />
                  )}
                  {percentage_value}%
                </div>
              ) : (
                <div className={styles.item}>-</div>
              )}
              {time_limited ? (
                <div className={styles.item}>
                  {breakpoint1 && (
                    <Icon name='calendar' size='20' fill='gray' />
                  )}
                  {new Date(item.expiration_date).getDate()}/
                  {new Date(item.expiration_date).getMonth() + 1}/
                  {new Date(item.expiration_date).getFullYear()}
                </div>
              ) : (
                <div className={styles.item}>-</div>
              )}
              {use_limited ? (
                <div className={styles.item}>
                  {breakpoint1 && <Icon name='ticket' size='20' fill='gray' />}
                  {number_of_uses_max}
                </div>
              ) : (
                <div className={styles.item}>-</div>
              )}
              <div className={styles.item}>{courses.length}</div>
            </div>
          ) : (
            <>
              <button
                onClick={() => setDetailsVisible(true)}
                className={styles.mobile_hover}
              >
                <div className={styles.button_row_mobile}>
                  <div className={styles.mobile_item_code}>{code}</div>
                  <div className={styles.item_mobile}>
                    {breakpoint1 && (
                      <Icon name='diamond' size='20' fill='gray' />
                    )}
                    {number_value}zł
                  </div>
                  {time_limited ? (
                    <div className={styles.item_mobile}>
                      {breakpoint1 && (
                        <Icon name='calendar' size='20' fill='gray' />
                      )}
                      {new Date(item.expiration_date).getUTCDate()}/
                      {new Date(item.expiration_date).getUTCMonth()}/
                      {new Date(item.expiration_date).getUTCFullYear()}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className={styles.item_mobile}>
                    {breakpoint1 && (
                      <Icon name='promotion' size='20' fill='gray' />
                    )}
                    {percentage_value}%
                  </div>
                  {use_limited && (
                    <div className={styles.item_mobile}>
                      {breakpoint1 && (
                        <Icon name='ticket' size='20' fill='gray' />
                      )}
                      {number_of_uses_max}
                    </div>
                  )}
                </div>
              </button>
            </>
          )}
          <div className={styles.edit_button}>
            <EditPromoCodes item={item} refresh={refresh} />
          </div>
        </div>
        <div className={styles.divider}></div>
      </div>
      {/* modal, ktory otwiera szczegóły */}
      <CodesDetails
        item={item}
        isVisible={detailsVisible}
        closeHandle={closeHandle}
      />
    </>
  );
}
