import React, { useState, useEffect, useRef } from 'react';
import styles from './UserDetail.module.sass';
import cn from 'classnames';
import { adminEndpoints } from 'api/endpoints';
import { useParams } from 'react-router';
import Icon from 'components/Icon';
import devscntrAuth from 'api/Instance/devscntrAuth';
import axios from 'axios';
import Card from 'components/Card';
import Publications from './Publications';
import { Link } from 'react-router-dom';
import Company from './Company';
import EditProfile from './EditProfile';
import Modal from 'components/Modal';
import AddXpPoints from 'components/AddXpPoints';
import UserChart from './UserChart';
import { prepareChartData } from './utils';
import Dropdown from 'components/Dropdown';
import Level from './Level';
import BanList from './BanList';
import EditorContentRender from 'components/Editor/EditorContentRender';

const chartDropdownInterval = [
  {
    name: 'Cały okres',
    value: 'default',
  },
  {
    name: 'Ostatnie 365 dni',
    value: 'pastyear',
  },
  {
    name: 'Ostatnie 30 dni',
    value: 'pastmonth',
  },
  {
    name: 'Ostatnie 7 dni',
    value: 'pastweek',
  },
  {
    name: 'Ostatnie 24 godziny',
    value: 'pastday',
  },
  {
    name: 'W tym roku',
    value: 'thisyear',
  },
  {
    name: 'W tym miesiącu',
    value: 'thismonth',
  },
];

const UserDetail = () => {
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);

  /* ---------------------------------- user ---------------------------------- */
  const [user, setUser] = useState({});
  const [level, setLevel] = useState({});
  const [banList, setBanList] = useState({});

  /* ------------------------------- visibility ------------------------------- */
  const [bioVisible, setBioVisible] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [userChartVisible, setUserChartVisible] = useState(false);

  /* ---------------------------------- chart --------------------------------- */
  const [chartData, setChartData] = useState({
    total: null,
    posts: null,
    courses: null,
  });
  const [preparedChartData, setPreparedChartData] = useState({});
  const [chartDropdown, setChartDropdown] = useState(
    chartDropdownInterval[0].name
  );

  /* ---------------------------------- utils --------------------------------- */
  const [addXpVisible, setAddXpVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const isMounted = useRef(true);

  /* ------------------------------- controllers ------------------------------ */
  const userStatsController = new AbortController();

  /* -------------------------------------------------------------------------- */
  /*                                  requests                                  */
  /* -------------------------------------------------------------------------- */
  const getUser = async cancelToken => {
    try {
      const response = await devscntrAuth.get(
        `${adminEndpoints.users.show}${id}`,
        {
          cancelToken: cancelToken.token,
        }
      );
      // console.log({ user: response?.data });
      isMounted && setUser(response?.data.data.user);
      isMounted &&
        setLevel({
          level: response?.data.current_level || 'max',
          currentXp: response?.data.current_xp || 0,
        });
      console.log(response);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('get user canceled');
      } else {
        console.log(error);
      }
    }
  };

  const getUserStats = async () => {
    try {
      const response = await devscntrAuth.get(
        `${adminEndpoints.usercontentstats.get}${id}`,
        {
          signal: userStatsController.signal,
          headers: {
            method: chartDropdownInterval.find(
              obj => obj.name === chartDropdown
            )?.value,
          },
        }
      );
      // console.log(response?.data);
      isMounted && setChartData(response?.data);
    } catch (err) {
      console.error(err);
      isMounted && setChartData({});
    }
  };

  const getUserBanList = async cancelToken => {
    try {
      const response = await devscntrAuth.get(
        `${adminEndpoints.users.bannedUsers}${id}`,
        {
          cancelToken: cancelToken.token,
        }
      );
      console.log(response?.data);
      setBanList(response?.data);
    } catch (err) {
      console.error(err);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                   effects                                  */
  /* -------------------------------------------------------------------------- */

  // when component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // fetch user
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getUser(cancelToken);
    getUserBanList(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [id, refresh]);

  // fetch stats
  useEffect(() => {
    getUserStats();

    return () => {
      userStatsController.abort();
    };
  }, [id, refresh, chartDropdown]);

  // prepares chart data on change
  useEffect(() => {
    setPreparedChartData(prepareChartData(chartData));
  }, [chartData]);

  /* -------------------------------------------------------------------------- */
  /*                                    utils                                   */
  /* -------------------------------------------------------------------------- */
  const editProfile = () => {
    console.log('edit');
  };

  // MODERATE USER
  const passwordReset = () => {};
  const changeEmail = () => {};
  const showUserLogs = () => {};
  const banUser = () => {};

  /* -------------------------------------------------------------------------- */
  /*                                     DOM                                    */
  /* -------------------------------------------------------------------------- */
  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title='Zarządzaj użytkownikiem'
      classTitle={cn('title-yellow', styles.title)}
      head={
        <button
          className={cn(
            editMode ? 'button-stroke' : 'button',
            styles.toggle_edit
          )}
          onClick={() => setEditMode(prev => !prev)}
        >
          {editMode ? 'Widok danych' : 'Tryb edycji'}
        </button>
      }
    >
      <button
        className={cn(
          editMode ? 'button-stroke' : 'button',
          styles.toggle_edit_mobile
        )}
        onClick={() => setEditMode(prev => !prev)}
      >
        {editMode ? 'Widok danych' : 'Tryb edycji'}
      </button>
      {editMode && user ? (
        <EditProfile
          user={user}
          setUser={setUser}
          refresh={() => setRefresh(prev => !prev)}
        />
      ) : (
        <div className={styles.container}>
          {user?.created_at ? (
            <>
              {parseInt(id) > 0 && id !== null && (
                <Modal
                  visible={addXpVisible}
                  onClose={() => setAddXpVisible(false)}
                >
                  <AddXpPoints id={id || null} />
                </Modal>
              )}

              <Link
                to={`/profile/${user?.id}`}
                className={cn('button', styles.go_to_profile_btn_mobile)}
              >
                Przejdź do profilu
              </Link>
              <div className={styles.details}>
                <div className={styles.image}>
                  <img
                    src={user?.image || '/images/default-avatar.png'}
                    alt=''
                  />
                </div>
                <div className={styles.user_info}>
                  <span className={styles.username}>
                    {user?.display_name || 'Brak nazwy'}
                    {user?.is_admin && (
                      <div className={cn('status-red', styles.status)}>
                        Administrator
                      </div>
                    )}
                  </span>
                  <span className={styles.time}>
                    Ostatnie logowanie:{' '}
                    {user?.last_logged?.slice(0, 10) || 'Brak danych'}
                  </span>
                </div>
                <Link
                  to={`/profile/${user?.id}`}
                  style={{ marginLeft: 'auto' }}
                  className={cn('button', styles.go_to_profile_btn)}
                >
                  Przejdź do profilu
                </Link>
              </div>

              <div className={styles.background_image}>
                <img
                  src={user?.background_image || '/images/default-bg-img.jpg'}
                  alt=''
                />
              </div>

              {/* USER DATA */}
              <div className={styles.personal_informations}>
                <div className={cn('title-blue', styles.informations_title)}>
                  Informacje o użytkowniku
                </div>
                <div className={styles.list_item}>
                  {user.first_name || '[Imię]'} {user.last_name || '[Nazwisko]'}
                </div>
                <div className={styles.list_item}>
                  <b>@</b> {user.email || 'Brak emailu'}
                </div>
                <div className={styles.list_item}>
                  <Icon name='phone' size={16} />
                  {user.phone || 'Brak nr telefonu'}
                </div>
              </div>

              <div className={styles.bio}>
                <div className={cn('title-green', styles.bio_title)}>
                  <div
                    className={cn(
                      styles.title_head,
                      bioVisible ? styles.active : ''
                    )}
                    onClick={() => setBioVisible(prev => !prev)}
                  >
                    <div>Opis</div>
                    <div>
                      <Icon name='arrow-down' size={20} />
                    </div>
                  </div>
                </div>
                {user?.bio ? (
                  <EditorContentRender
                    className={cn(styles.content, !bioVisible && styles.hide)}
                    data={user.bio}
                  />
                ) : (
                  !bioVisible && (
                    <p className={styles.info}>
                      Użytkownik nie dodał jeszcze opisu
                    </p>
                  )
                )}
              </div>

              <div className={styles.account_informations}>
                <div className={cn('title-blue', styles.title)}>Poziom</div>
                <Level data={level} />
              </div>

              {/* ACCOUNT DATA */}
              <div className={styles.account_informations}>
                <div className={cn('title-purple', styles.informations_title)}>
                  Informacje o koncie
                </div>
                <div className={styles.list_item}>
                  <b>Data utworzenia:</b> {user.created_at.slice(0, 10)}
                </div>
                <div className={styles.list_item}>
                  <b>Obserwujący:</b> {user?.follower_count || 0}
                </div>
                <div className={styles.list_item}>
                  <BanList banList={banList} user={user} />
                </div>
              </div>

              {/* COMPANY DATA */}
              {user?.company !== null && <Company user={user} />}

              {/* CONTENT DATA */}
              <div className={styles.user_content}>
                <div className={cn('title-green', styles.informations_title)}>
                  <div
                    className={cn(
                      styles.title_head,
                      contentVisible ? styles.active : ''
                    )}
                    onClick={() => setContentVisible(prev => !prev)}
                  >
                    <div>Twórczość</div>
                    <div>
                      <Icon name='arrow-down' size={20} />
                    </div>
                  </div>
                </div>
                <div className={styles.content_count}>
                  <div className={styles.list_item}>
                    <b>Posty:</b> {user?.posts_count || 0}
                  </div>
                  <div className={styles.list_item}>
                    <b>Kursy:</b> {user?.courses_count || 0}
                  </div>
                  <div className={styles.list_item}>
                    <b>Oferty pracy:</b> {user?.jobs_count || 0}
                  </div>
                </div>
                {contentVisible && (
                  <div className={styles.publications}>
                    <Publications />
                  </div>
                )}
              </div>

              {/* STATS */}
              <div className={styles.user_content}>
                <div className={cn('title-purple', styles.informations_title)}>
                  <div
                    className={cn(
                      styles.title_head,
                      userChartVisible ? styles.active : ''
                    )}
                    onClick={() => setUserChartVisible(prev => !prev)}
                  >
                    <div>Statystyki</div>
                    <div>
                      <Icon name='arrow-down' size={20} />
                    </div>
                  </div>
                </div>
                {userChartVisible && (
                  <>
                    <div className={styles.dropdown}>
                      <Dropdown
                        value={chartDropdown}
                        setValue={setChartDropdown}
                        options={chartDropdownInterval.map(v => v.name)}
                      />
                    </div>
                    <div className={styles.chartContainer}>
                      <div className={cn(styles.userChart, styles.chartTotal)}>
                        <div className={styles.chartTitle}>Wszystko</div>
                        <UserChart
                          userId={id}
                          data={preparedChartData.total}
                          barColor='#b1e5fc'
                        />
                      </div>
                      <div className={cn(styles.userChart, styles.chartPosts)}>
                        <div className={styles.chartTitle}>Posty</div>
                        <UserChart userId={id} data={preparedChartData.posts} />
                      </div>
                      <div
                        className={cn(styles.userChart, styles.chartCourses)}
                      >
                        <div className={styles.chartTitle}>Kursy</div>
                        <UserChart
                          userId={id}
                          data={preparedChartData.courses}
                          barColor='#b5e4ca'
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* BUTTONS */}
              <div className={styles.user_actions}>
                <div className={cn('title-red', styles.informations_title)}>
                  Podejmij działanie
                </div>
                <div className={styles.actions}>
                  {/* <button
                    className={cn('button')}
                    onClick={() => setAddXpVisible(true)}
                  >
                    Dodaj XP
                  </button> */}
                  {/* <button className={cn("button")} onClick={editProfile}>
                    Edytuj profil
                  </button>
                  <button className={cn("button")} onClick={passwordReset}>
                  Zresetuj hasło
                  </button>
                  <button className={cn("button")} onClick={changeEmail}>
                    Zmień email
                  </button>
                  <button className={cn("button")} onClick={showUserLogs}>
                    Pokaż logi
                  </button> 
                  <button className={cn("button")} onClick={banUser}>
                    Zablokuj
                  </button> */}
                </div>
              </div>
            </>
          ) : (
            'loading'
          )}
        </div>
      )}
    </Card>
  );
};
export default UserDetail;
