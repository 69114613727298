import React, { useState } from 'react';
import styles from './Item.module.sass';
import cn from 'classnames';
import { Link, Redirect } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { showUserInfo } from 'store/userInfo/actions';
import Actions from 'components/Actions';
import { adminEndpoints } from 'api/endpoints';
import useBreakpoints from 'hooks/useBreakpoints';
import devscntrAuth from "../../../../api/Instance/devscntrAuth";
import BanModal from "../../../../components/BanModal";
import UnbanModal from "../../../../components/UnbanModal";

const Item = ({ user, selected, setSelected }) => {
  const authCtx = useAuth();
  const dispatch = useDispatch();

  const [banModal, setBanModal] = useState(false);
  const [unbanModal, setUnbanModal] = useState(false);

  const [stylesRef, setStylesRef] = useState(null);
  const breakpoints = [
    ['r-max', Infinity],
    ['r-lg', 1140],
    ['r-md', 996],
    ['r-sm', 568],
    ['r-sm-s', 450],
  ];
  useBreakpoints(stylesRef, breakpoints);

  const handleShowUserInfo = id => {
    dispatch(showUserInfo(id));
  };

  const handleSelect = () => {
    if (selected) {
      setSelected(prev => prev.filter(item => item.id === user.id));
    } else {
      setSelected(prev => [...prev, user.id]);
    }
  };

  const handlePasswordReset = () => {
    console.log('passwd reset');
  };

  const actions = [
    // {
    //   title: "Podgląd",
    //   icon: "expand",
    //   action: () => handleShowUserInfo(user.id),
    // },
    {
      title: 'Zresetuj hasło',
      icon: 'lock',
      action: handlePasswordReset,
    },
    {
      title: user.is_banned ? 'Odblokuj' : 'Zablokuj',
      icon: 'close',
      color: !user.is_banned,
      action: () => {
        if(!user.is_banned)
          setBanModal(true)
        else
          setUnbanModal(true)
      },
    },
  ];

  const showSettings = () => {
    console.log('ustawienia konta');
  };

  return (
    authCtx.isAdmin && (
      <article>
        <div className={styles.item}>
          <div className={styles.head}>
            <div className={styles.head_content}>
              <Checkbox
                className={styles.user_select}
                value={selected}
                onChange={handleSelect}
              />
              <Link to={`/admin/users/${user?.id}`} className={styles.col}>
                <div className={styles.image}>
                  <img src={user?.image || '/images/default-avatar.png'} />
                </div>
              </Link>
              <Link to={`/admin/users/${user?.id}`} className={styles.username}>
                {user?.display_name || user?.username || 'Brak nazwy'}
                {user?.is_admin && (
                  <div
                    className={cn('status-red', styles.status)}
                    style={{ marginLeft: '12px' }}
                  >
                    Administrator
                  </div>
                )}
              </Link>
            </div>
            <div className={styles.head_actions}>
              <Actions
                className={styles.menu}
                classActionsHead={styles.settings}
                classActionsBody={styles.actionsBody}
                classActionsOption={styles.actionsOption}
                items={actions}
              />
            </div>
          </div>
          <div className={styles.data}>
            <div className={styles.col}>
              <label>
                {/* <Icon name='profile-circle' size={18} /> */}
                Obserwacje:
              </label>
              {user?.follower_count}
            </div>
            <div className={styles.col}>
              <label>
                {/* <img
                  src='/images/icons/views/stroke.svg'
                  style={{ height: '22px' }}
                  alt=''
                /> */}
                Wyświetlenia:
              </label>
              {user?.views_count || '0'}
            </div>
            <div className={cn(styles.col, styles.content_counts)}>
              <label>Publikacje: </label>
              <div className={styles.col_item}>
                <label>
                  <Icon name='message' size={18} />
                </label>
                {user?.posts_count || '0'}
              </div>
              <div className={styles.col_item}>
                <label>
                  <Icon name='store' size={18} />
                </label>
                {user?.courses_count || '0'}
              </div>
              <div className={styles.col_item}>
                <label>
                  <Icon name='search' size={18} />
                </label>
                {user?.jobs_count || '0'}
              </div>
            </div>
            <div className={styles.col}>
              <label className={user.is_banned ? styles.banned : undefined}>
                {user.is_banned ? "Zablokowany" : "Aktywny"}
              </label>
            </div>
          </div>
          <div className={styles.actions}>
            <Actions
              className={styles.menu}
              classActionsHead={styles.settings}
              classActionsBody={styles.actionsBody}
              classActionsOption={styles.actionsOption}
              items={actions}
            />
          </div>

          {/* <button className={styles.settings} onClick={showSettings}>
          <Icon name="more-horizontal" size={24} />
        </button> */}
        </div>

        <BanModal userID={user?.id} visible={banModal} setVisible={setBanModal} />
        <UnbanModal userId={user?.id} visible={unbanModal} setVisible={setUnbanModal} />
      </article>
    )
  );
};
export default Item;
