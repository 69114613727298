import React, { useState } from 'react';
import styles from './PrivacyBanner.module.sass';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const PrivacyBanner = () => {
  const authCtx = useAuth();
  const [accepted, setAccepted] = useState(
    JSON.parse(localStorage.getItem('policyAccepted')) || false
  );
  const acceptPolicy = () => {
    localStorage.setItem('policyAccepted', true);
    setAccepted(true);
  };

  return (
    !authCtx.isLoggedIn &&
    !accepted && (
      <div className={styles.container}>
        <div className={styles.content}>
          itCNTR wykorzystuje pliki cookies. Korzystając z naszej aplikacji
          internetowej, wyrażasz zgodę na naszą &nbsp;
          <Link to='/privacy'>politykę prywatności</Link> i politykę dotyczącą{' '}
          <Link to='/privacy'>plików cookies</Link>.
        </div>
        <button className={cn('button', styles.button)} onClick={acceptPolicy}>
          OK
        </button>
      </div>
    )
  );
};

export default PrivacyBanner;
