import React, { useState, useEffect } from 'react';
import styles from './JZWIT_Content.module.sass';
import cn from 'classnames';
import ShowContent from '../ShowContent';
import Card from 'components/Card';
import TechnologyChart from '../TechnologyDetail/TechnologyChart';
import AverageEarnings from 'screens/EarningsSection/AverageEarnings';
import RelatedCourses from 'screens/DetailsLayout/RelatedCourses';
import RelatedJobs from 'screens/DetailsLayout/RelatedJobs';
import RelatedPosts from 'screens/DetailsLayout/RelatedPosts';

const JZWIT_Content = ({ currentObject, earningsData }) => {

  const { sections, trends, earnings, related_courses, related_jobs, related_posts } = currentObject

  const [elementsOrder, setElementsOrder] = useState([])
  const [elementsList, setElementsList] = useState([])
  const [countOrder, setCountOrder] = useState(false)

  sections.sort(function (a, b) {
    return a.position - b.position
  })

  const prepareList = () => {
    let i = 0
    setElementsList([])
    sections.forEach(element => {
      setElementsList(elementsList => [...elementsList, { section: element, type: 'section', placement: element.position }])
      i = i + 1
    });
    for (let j = 0; j < trends?.length; j++) {
      setElementsList(elementsList => [...elementsList, { trend: trends[j], type: 'trend', placement: sections?.length + j + 1 }])
      i = i + 1
    };
    setElementsList(elementsList => [...elementsList, { related_courses: related_courses, type: 'related_courses', placement: i + 1 }])
    setElementsList(elementsList => [...elementsList, { related_posts: related_posts, type: 'related_posts', placement: i + 2 }])
    setElementsList(elementsList => [...elementsList, { related_jobs: related_jobs, type: 'related_jobs', placement: i + 3 }])
    setElementsList(elementsList => [...elementsList, { earnings: earnings, type: 'earnings', placement: i + 4 }])

    setCountOrder(true)
  }

  const prepareOrder = (data) => {
    const array = data
    const arrayToSend = []
    let sectionsLeft = sections.length
    let sectionsAdded = 0

    for (let i = 0; i < array?.length; i++) {
      for (let j = 0; j < array?.length; j++) {
        if (array[j].type === 'earnings') {
          if (array[j].earnings !== 'data missing' && currentObject?.slug !== 'jak-zaczac-w-it') {
            arrayToSend.push({ earnings: array[j].earnings, type: 'earnings', placement: i + 1 })
            array[j].type = 'taken'
            break
          }
        }
        else if (array[j].type === 'section' && (i % 2 !== 0)) {
          arrayToSend.push({ section: array[j].section, type: 'section', placement: i + 1 })
          array[j].type = 'taken'
          sectionsLeft = sectionsLeft - 1
          sectionsAdded = sectionsAdded + 1
          break
        }
        else if (array[j].type === 'trend' && i > 1) {
          if (!!array[j]?.trend?.chart_data && array[j]?.trend?.chart_data?.x !== 'Brak danych') {
            arrayToSend.push({ trend: array[j].trend, type: 'trend', placement: i + 1 })
            array[j].type = 'taken'
            break
          }
        }
        else if (array[j].type === 'related_courses' && i > 1) {
          if (currentObject?.slug !== 'jak-zaczac-w-it') {
            arrayToSend.push({ related_courses: array[j].related_courses, type: 'related_courses', placement: i + 1 })
            array[j].type = 'taken'
            break
          }
        }
        else if (array[j].type === 'related_posts' && i > 1) {
          if (currentObject?.slug !== 'jak-zaczac-w-it') {
            arrayToSend.push({ related_posts: array[j].related_posts, type: 'related_posts', placement: i + 1 })
            array[j].type = 'taken'
            break
          }
        }
      }
    }

    for (let i = 0; i < sectionsLeft; i++) {
      for (let j = 0; j < array?.length; j++) {
        if (array[j].type === 'section') {
          arrayToSend.push({ section: array[j].section, type: 'section', placement: sectionsAdded + i + 1 })
          array[j].type = 'taken'
          break
        }
      }
    }

    for (let j = 0; j < array?.length; j++) {
      if (array[j].type === 'related_jobs') {
        if (currentObject?.slug !== 'jak-zaczac-w-it') {
          arrayToSend.push({ related_jobs: array[j].related_jobs, type: 'related_jobs', placement: array?.length + 1 })
          array[j].type = 'taken'
        }
      }
    }


    setElementsOrder(arrayToSend)
    setCountOrder(false)
  }

  useEffect(() => {
    prepareList()
  }, [currentObject]);

  useEffect(() => {
    if (countOrder) {
      prepareOrder(elementsList)
    }
  }, [countOrder]);

  return (
    <>
      {elementsOrder.map((item, index) => (
        <div key={index}>
          {item?.type === 'section' &&
            (
              <Card
                className={styles.card}
                classTitle='title-red'
                title={item?.section?.title}
                subtitle={item?.section?.subtitle}
              >
                <ShowContent
                  description={item?.section?.text}
                  version={2}
                  title=''
                />
              </Card>
            )}
          {item?.type === 'trend' &&
            (
              !!item?.trend?.chart_data && item?.trend?.chart_data?.x !== 'Brak danych' && (
                <article className={styles.chartAndTrends}>
                  <TechnologyChart
                    className={styles.chart}
                    title={item?.trend?.title}
                    subtitle={item?.trend?.subtitle}
                    data={item?.trend} />
                </article>
              )
            )}
          {item?.earnings !== 'data missing' && (
            item?.type === 'earnings' && currentObject?.slug !== 'jak-zaczac-w-it' &&
            (
              <AverageEarnings data={earningsData} className={styles.average_earnings_card} />
            )
          )}
          {currentObject?.slug !== 'jak-zaczac-w-it' && (
            <div className={styles.related_list}>
              <div className={styles.related_item}>
                {item?.type === 'related_courses' && (
                  <RelatedCourses items={item?.related_courses} className={styles.related_card} />
                )}
              </div>
              <div className={styles.related_item}>
                {item?.type === 'related_posts' && (
                  <RelatedPosts items={item?.related_posts} className={styles.related_card} />
                )}
              </div>
              <div className={styles.related_item}>
                {item?.type === 'related_jobs' && (
                  <RelatedJobs items={item?.related_jobs} className={styles.related_card} />
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default JZWIT_Content;