export const b5Domains = {
  A: 'Ugodowość',
  C: 'Sumienność',
  E: 'Ekstrawersja',
  N: 'Neurotyzm',
  O: 'Otwartość na doświadczenia',
};

export const b5DomainsArray = Object.keys(b5Domains).map(key => b5Domains[key]);
export const b5DomainsArrayPositioning = [
  'Ugodowość',
  'Sumienność',
  'Ekstrawersja',
  'Neurotyzm',
  ['Otwartość na', 'doświadczenia'],
];

export const getB5TestResultFromJson = json => {
  if (!json) return null;

  const domains = {
    A: json?.A || null,
    C: json?.C || null,
    E: json?.E || null,
    N: json?.N || null,
    O: json?.O || null,
  };

  return domains;
};

export const assignB5DomainsToResultInJson = json => {
  if (!json) return null;

  const domains = {
    A: json?.A || null,
    C: json?.C || null,
    E: json?.E || null,
    N: json?.N || null,
    O: json?.O || null,
  };

  json.result = domains;

  try {
    delete json?.A;
    delete json?.C;
    delete json?.E;
    delete json?.N;
    delete json?.O;
  } catch (error) {
    return null;
  }

  return json;
};

export const getB5ChartSeries = result => {
  const series = [];

  try {
    series.push({
      name: 'Wynik testu',
      color: '#2A85FF',
      data: Object.keys(b5Domains).map(item => {
        return Number(result[item].score != 0 ? result[item].score : 0).toFixed(
          1
        );
      }),
    });
  } catch (error) {
    return [];
  }

  return series || [];
};

export const getB5DomainChartSeries = domain => {
  const series = [];

  try {
    series.push({
      name: 'Wynik',
      color: '#B1E5FC',
      data: domain?.facets?.map(facet => facet?.score || 0),
    });
  } catch (error) {
    return [];
  }

  return series || [];
};
