import React, { useEffect, useState } from 'react';
import styles from './BlockedUsers.module.sass';
import cn from 'classnames';
import Item from '../Item';
import MiniUser from 'components/MiniUser';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { accountsEndpoints } from 'api/endpoints';
import uuid from 'react-uuid';
import useAuth from 'hooks/useAuth';
import Loader from 'components/Loader';

const BlockedUsers = ({ className }) => {
  // UTILS----------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [blockedList, setBlockedList] = useState([]);

  // UTILS----------------------------------------------------------------------------UTILS
  // SET BLOCKED LIST------------------------------------------------------SET BLOCKED LIST

  // useEffect(() => {
  //   console.log({ auth: authCtx?.blockedUsers?.length > 0 });
  //   console.log({ auth: blockedList?.length === 0 });
  //   if (authCtx?.blockedUsers?.length > 0 && blockedList?.length === 0) {
  //     console.log('przeszlo ifa');
  //     setBlockedList(authCtx?.blockedUsers || []);
  //   }
  // }, [authCtx?.blockedUsers]);

  // SET BLOCKED LIST------------------------------------------------------SET BLOCKED LIST
  // GET BLOCK LIST----------------------------------------------------------GET BLOCK LIST

  const getBlockList = async signal => {
    setIsLoading(true);
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: accountsEndpoints.userContent.userBlocked,
        signal: signal,
      });
      const data = response.data;
      const list = data.map(item => item.blockeduser);
      setBlockedList(list);
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    getBlockList(signal);

    return () => {
      abortController.abort();
    };
  }, []);

  // GET BLOCK LIST----------------------------------------------------------GET BLOCK LIST

  return (
    <Item
      className={cn(styles.container, className)}
      title='Zablokwane konta'
      classTitle='title-red'
    >
      <div className={styles.content}>
        {isLoading && <Loader className={styles.loader} white />}
        {blockedList?.length > 0 &&
          !isLoading &&
          blockedList?.map(item => (
            <MiniUser
              key={`user_key_${item.id}`}
              ref={null}
              className={styles.item}
              user={item}
              id={item.id}
              hideRss={false}
            />
          ))}
        {blockedList?.length === 0 && !isLoading && (
          <div className={styles.empty}>
            Nie masz zablokowanych użytkowników
          </div>
        )}
      </div>
    </Item>
  );
};

export default BlockedUsers;
