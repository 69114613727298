import React from 'react';
import styles from './ChosenCategories.module.sass';
import cn from 'classnames';
import Card from 'components/Card';
import Icon from 'components/Icon';

const ChosenCategories = ({ data, onItemClick, loading }) => {
  return (
    <Card className={cn(styles.card)}>
      <div className={styles.info} style={{ marginBottom: '12px' }}>
        <Icon name='info' size='16' fill='gray' />
        Wybierz kategorię. Kliknij "x", aby usunąć z listy.
      </div>
      <div className={styles.categories}>
        {data.map((item, index) => (
          <div
            key={index}
            className={styles.item}
            style={{
              backgroundColor: `${item.color}`,
              borderColor: `${item.color}`,
              color: `black`,
            }}
          >
            {item.name}
            <button
              className={cn(styles.plus)}
              onClick={() => {
                !loading && onItemClick(item);
              }}
            >
              <div className={cn(styles.plus_icon_vertical)}></div>
              <div className={cn(styles.plus_icon_horizontal)}></div>
            </button>
          </div>
        ))}
      </div>
    </Card>
  );
};
export default ChosenCategories;
