import React from 'react';
import styles from './Categories.module.sass';
import cn from 'classnames';
import uuid from 'react-uuid';
import Category from './Category';
import { useHistory } from 'react-router';
import HorizontalScroll from 'components/HorizontalScroll';

const Categories = ({ className, categories, baseLink = null }) => {
  const history = useHistory();

  const sortCategories = (data) => {
    const temp = []
    for (let i = 0; i < data.length; i++) {
      temp.push(data[data.length - i - 1])
    }
    return temp
  }

  return (
    <HorizontalScroll
      className={cn(styles.categories, className && className)}
      controls
      controlsClassName={styles.scroll_controls}
    >
      {sortCategories(categories)?.map(
        item =>
          item?.name !== 'Uncategorized' && (
            <Category
              classNames={styles.item}
              key={uuid()}
              name={item?.name || 'Brak nazwy'}
              color={item?.color || '#ffffff'}
              onClick={() => {
                baseLink &&
                  history.push(
                    `${baseLink}?categories=${encodeURIComponent(item?.name)}`
                  );
              }}
            />
          )
      )}
    </HorizontalScroll>
  );
};

export default Categories;
