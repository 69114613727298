import React from "react";
import styles from "./Loader.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Loader = () => {
  return (
    <SkeletonTheme key={`new_item_loader`}>
      <div className={styles.container} key={"new_item_loader_container"}>
        <div className={styles.card}>
          <Skeleton height={100} borderRadius={10} />
        </div>
        <div className={styles.card}>
          <Skeleton height={350} borderRadius={10} />
        </div>
        <div className={styles.card}>
          <Skeleton height={200} borderRadius={10} />
        </div>
        <div className={styles.card}>
          <Skeleton height={300} borderRadius={10} />
        </div>
        <div className={styles.card}>
          <Skeleton height={200} borderRadius={10} />
        </div>
        <div className={styles.card}>
          <Skeleton height={70} borderRadius={10} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default Loader;
