import React, { useEffect, useState } from 'react';
import styles from './ModalTags.module.sass';
import cn from 'classnames';
import Modal from 'components/Modal';
import Search from 'components/Search';
import Checkbox from 'components/Checkbox';
import useAuth from 'hooks/useAuth';
import { tagsEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import axios from 'axios';
import Icon from 'components/Icon';
import { colorRelatedToBackground } from 'utils';
import Tag from 'components/Tags/Tag';

const ModalTags = ({
  visible,
  setVisible,
  selectedTags,
  toggleTag,
  tags,
  setSelectedTags,
  selectedItems,
}) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [preparedTags, setPreparedTags] = useState(tags || []);
  const [showSelected, setShowSelected] = useState(false);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    //getTags(cancelToken);
    //authCtx.isLoggedIn && getMyTags(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    let filteredTags = tags;
    if (search !== '') {
      filteredTags = filteredTags?.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    setPreparedTags(filteredTags);
  }, [tags, search]);

  // const toggleTag = (index) => {
  //   if (selectedTags?.includes(tags[index].name)) {
  //     setSelectedTags((prev) => prev.filter((item) => item !== tags[index].name));
  //   } else {
  //     setSelectedTags((prev) => [...prev, tags[index].name]);
  //   }
  // };

  return (
    <Modal outerClassName={styles.test} visible={visible} onClose={setVisible}>
      <div className={cn('title-green')}>Tagi</div>

      <Search
        className={styles.search}
        value={search}
        setValue={setSearch}
        name='tagSearch'
        icon='search'
        placeholder='Szukaj...'
      />

      <Checkbox
        className={styles.checkbox}
        value={showSelected}
        onChange={() => setShowSelected(prev => !prev)}
        content='Pokaż tylko zaznaczone'
      />

      <div className={styles.tags}>
        {tags?.map((item, index) =>
          showSelected ? (
            selectedItems.map(item => item.id)?.includes(item.id) && (
              <Tag
                key={`tag_${index}`}
                name={item?.name || 'Brak nazwy'}
                color={item?.colour || '#ffffff'}
                selected={
                  selectedItems.map(item => item.id)?.includes(item.id) || false
                }
                visible={preparedTags.includes(item) || false}
                onClick={() => toggleTag(item.id)}
              />
            )
          ) : (
            <Tag
              key={`tag_${index}`}
              name={item?.name || 'Brak nazwy'}
              color={item?.colour || '#ffffff'}
              selected={
                selectedItems.map(item => item.id)?.includes(item.id) || false
              }
              visible={preparedTags.includes(item) || false}
              onClick={() => toggleTag(item.id)}
            />
          )
        )}
      </div>
    </Modal>
  );
};

export default ModalTags;
