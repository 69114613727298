export const validationRules = {
  email: {
    name: 'email',
    maxChars: 50,
    minChars: 5,
    security: 'email',
  },
  displayName: {
    name: 'nazwa użytkownika',
    maxChars: 25,
    minChars: 3,
    security: 'text',
  },
  firstname: {
    name: 'imię',
    maxChars: 30,
    minChars: 3,
    security: 'letters',
  },
  lastname: {
    name: 'nazwisko',
    maxChars: 40,
    minChars: 3,
    security: 'letters',
  },
  password: {
    name: 'hasło',
    maxChars: 50,
    minChars: 8,
    security: 'password',
  },
  taxNumber: {
    name: 'NIP',
    maxChars: 10,
    minChars: 10,
    security: 'numbers',
  },
  companyName: {
    name: 'nazwa firmy',
    maxChars: 50,
    minChars: 3,
    security: 1,
  },
};
