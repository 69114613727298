import React, { useContext, useState, useEffect } from 'react';
import styles from './NewTechnology.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import Modal from '../../components/Modal';
import Schedule from '../../components/Schedule';
import Icon from '../../components/Icon';
import Panel from 'screens/NewLayouts/Panel';
import cn from 'classnames';
import Card from '../../components/Card';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import BasicData from './MiniData';
import DynamicSection from './DynamicSection';
import Parent from './Parent';
import Trends from './Trends';
import { jzwitv2Endpoints } from 'api/endpoints';
import useAxios from 'hooks/useAxios';
import FormContext from 'context/FormContext';
import { validateInput, validateUrl } from 'utils/validation';
import { showError } from 'utils/showError';
import devscntrAuth from 'api/Instance/devscntrAuth';
import EditLoader from './EditLoader';
import { init } from '@sentry/react';
import ChooseCategoryBreadcrumbs from 'components/ChooseCategoryBreadcrumbs';

const EDITOR_VERSION = 2;

const EditJZWIT = () => {
  const formCtx = useContext(FormContext);
  const { idTech } = useParams();
  // BASIC STATES
  const [errorMsg, setErrorMsg] = useState('');
  const location = useHistory();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [isPostCreated, setIsPostCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEntering, setIsEntering] = useState(false);
  const [isTechnology, setIsTechnology] = useState(false);
  const [parent, setParent] = useState(null)
  const [editParentData, setEditParentData] = useState()
  const [categories, setCategories] = useState([])

  const [technology, errorTechnology, loadingTechnology, GetTechnology] =
    useAxios();

  const getTechnology = () => {
    GetTechnology({
      method: 'get',
      url: `${jzwitv2Endpoints.displayTech}${idTech}`,
    });
  };

  useEffect(() => {
    if (technology?.main_data?.is_technology) {
      setIsTechnology(technology?.main_data?.is_technology)
    }
    //SETTING CATEGORIES
    let categoriesList = [];
    if (technology?.main_data?.categories?.length > 0) {
      categoriesList = technology?.main_data?.categories.map(item => ({
        name: item.name,
        id: item.id,
        color: item.color,
      }));
    }

    setCategories(categoriesList);
  }, [technology]);

  useEffect(() => {
    getTechnology();
    window.scrollTo(0, 0);
  }, [idTech]);

  //CATEGORY FUNCTIONS
  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck?.some(item => value.name === item.name)
  }

  const categoryFunction = (item) => {
    if (!checkIfArrayContains(categories, item)) {
      setCategories(categories => [...categories, item])
    }
    if (checkIfArrayContains(categories, item)) {
      setCategories((current) => current.filter((object) => object.name !== item.name));
    }
  }

  // DATA TO SEND
  const basicData = formCtx.basicData;
  const trendsData = formCtx.trendsData;
  const deletedTrends = formCtx.deletedTrends
  const dynamicSectionsData = formCtx.dynamicSectionsData;
  const deletedSections = formCtx.deletedSections

  const technologySlug = technology?.main_data?.slug

  // VALIDATION---------------------------------------------------------------VALIDATION
  const validation = () => {
    const name = basicData?.name;
    const subTitle = basicData?.subtitle;
    const editor = basicData?.content;
    const image = basicData?.image;
    const icon = basicData?.icon;
    const trendsTitle = trendsData.title;
    const trendsEditor = trendsData.text;
    const Section = dynamicSectionsData;


    if (!validateInput(name?.trim(), 100, 1, 'Nazwa Technologi', 0)) {
      return false;
    }

    if (editor.trim(' ').length < 1) {
      showError('Opis nie może być pusty');
      return false;
    }

    for (let i = 0; i < Section.length; i++) {
      if (Section[i].title.trim().length < 1) {
        showError('Tytuł sekcji nie może być pusty.');
        return false;
      }
      if (Section[i].text.trim(' ').length < 1) {
        showError('Opis sekcji nie może być pusty.');
        return false;
      }
    }

    for (let i = 0; i < trendsData.length; i++) {
      if (trendsData[i].title.trim().length < 1) {
        showError('Tytuł trendu nie może być pusty.');
        return false;
      }
    }

    return true;
  };

  const prepareFormData = () => {
    const formData = new FormData();
    formData.append('id', technology?.main_data?.id);
    formData.append('name', basicData?.name);
    formData.append('title', basicData?.name);
    if (typeof (basicData?.image) !== 'string' && basicData?.image) {
      formData.append('image', basicData?.image);
    }
    if (typeof (basicData?.icon) !== 'string' && basicData?.icon) {
      formData.append('icon', basicData?.icon);
    }

    formData.append('text', basicData?.content);
    formData.append('is_technology', isTechnology);
    console.log(basicData)

    if (technologySlug !== 'jak-zaczac-w-it') {
      formData.append('parent', parent.id);
    }

    if (deletedTrends) {
      deletedTrends.forEach((item) => {
        if (item[0].title) {
          formData.append(`trends[${item[0].id}]id`, item[0].id);
          formData.append(`trends[${item[0].id}]remove`, true);
        }
      });
    }
    // CATEGORIES
    if (categories.length > 0) {
      categories.forEach((item, index) => {
        formData.append(`categories[${index}]name`, item.name);
        formData.append(`categories[${index}]id`, item.id);
      });
    }
    if (categories.length === 0) {

    }

    if (trendsData.length > 0) {
      trendsData.forEach((item, index) => {
        if (item.id) {
          formData.append(`trends[${index}]id`, item.id);
        }
        formData.append(`trends[${index}]title`, item.title);
        formData.append(`trends[${index}]subtitle`, item.subtitle);
      });
    }
    if (deletedSections) {
      deletedSections.forEach((item) => {
        if (item[0].title) {
          formData.append(`sections[${item[0].id}]id`, item[0].id);
          formData.append(`sections[${item[0].id}]remove`, true);
        }
      });
    }
    if (dynamicSectionsData.length > 0) {
      dynamicSectionsData.forEach((item, index) => {
        if (item.id) {
          formData.append(`sections[${index}]id`, item.id);
        }
        formData.append(`sections[${index}]title`, item.title);
        formData.append(`sections[${index}]subtitle`, item.subtitle);
        formData.append(`sections[${index}]text`, item.text);
        formData.append(`sections[${index}]position`, item.position);
      });
    }
    return formData;
  };

  const EditApi = async data => {

    try {
      const response = await devscntrAuth.post(
        `${jzwitv2Endpoints.createTech}`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            method: 'edit',
          },
        }
      );
      setIsPostCreated(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };

  const handleSubmit = () => {
    if (!validation()) return;
    setIsLoading(true);
    const formData = prepareFormData();
    EditApi(formData);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(false)
  }, [isPostCreated]);

  // Get Technology data to edit

  const [editBasicData, setEditBasicData] = useState();
  const [editTrendsData, setEditTrendsData] = useState();
  const [editDynamicSectionData, setEditDynamicSectionsData] = useState();

  const dataToEditTechnology = () => {
    if (technology?.main_data) {
      const { name, subtitle, text, image, icon, parent, trends } =
        technology.main_data;
      const technologyBasicData = { name, subtitle, text, image, icon };
      const technologyTrendsData = {
        title: trends[0]?.title,
        text: trends[0]?.text,
      };
      const technologyParentData = {
        value: parent?.id,
        label: parent?.name,
      };

      const technologyDynamicSectionsData = {
        if(technology) {
          technology?.technology?.map(section => {
            const { title, subtitle, image, text, side } = section;
            return { title, subtitle, image, text, side };
          });
        },
      };
      setEditBasicData(technologyBasicData);
      setEditTrendsData(technology?.main_data?.trends);
      //setEditParentData(technologyParentData);
      let temp = technology?.main_data?.sections
      temp.sort(function (a, b) {
        return a.position - b.position
      })
      setEditDynamicSectionsData(temp);
    }
  };

  useEffect(() => {
    dataToEditTechnology();
  }, [
    technology,
    loadingTechnology,
    setEditBasicData,
    setEditTrendsData,
    //setEditParentData,
    setEditDynamicSectionsData,
  ]);

  const parentHandler = (item) => {
    setParent(item)
  }

  useEffect(() => {
    setParent(technology?.main_data?.parent)
  }, [technology]);

  const goBackUrl = (slug) => {
    const newSlug = slug?.split('_');
    const finalSlug = newSlug?.join('/')
    return finalSlug
  }

  return (
    <>
      <Prompt
        when={isEntering}
        message={location => 'Czy na pewno chcesz wyjsc ?'}
      />
      <div className={styles.row}>
        {!isPostCreated ? (
          <>
            <div className={styles.course_info}>
              {errorMsg.length > 0 && (
                <div className={styles.error}>
                  <Icon name='info' size='24' />
                  {errorMsg}
                </div>
              )}
              <BasicData editBasicData={editBasicData} checkboxValue={isTechnology} onCheckboxChange={setIsTechnology} isEditor={true}/>
              {technology?.main_data?.slug !== 'jak-zaczac-w-it' && (
                <Parent currentObject={technology?.main_data} parentHandler={parentHandler} editParentData={parent} />
              )}
              <ChooseCategoryBreadcrumbs
                title='Kategorie'
                itemClicked={categoryFunction}
                loading={false}
                selectedCategories={categories}
              />
              <Trends
                className={'Trends'}
                editTrendsData={editTrendsData}
              />
              <DynamicSection
                editDynamicSectionData={editDynamicSectionData}
              />
            </div>
          </>
        ) : (
          <Card className={styles.postcreated}>
            <p>Technologia została zedytowana</p>
            <div className={styles.panel}>
              <button
                className={cn('button', styles.homebtn)}
                onClick={() => location.push(`/${goBackUrl(technologySlug)}`)}
              >
                Wróć do technologii
              </button>
            </div>
          </Card>
        )}
      </div>
      {!isPostCreated && (
        <>
          <Panel
            setVisiblePreview={setVisiblePreview}
            setVisibleSchedule={setVisibleModal}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            buttonText='Edytuj'
          />
          <TooltipGlodal />
          <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
            <Schedule
              startDate={startDate}
              setStartDate={setStartDate}
              startTime={startTime}
              setStartTime={setStartTime}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default EditJZWIT;
