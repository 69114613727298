import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './EmploymentHistory.module.sass';
import TextInput from 'components/TextInput';
import TextArea from 'components/TextArea';
import DatePicker from 'screens/Publications/components/DatePicker';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Icon from 'components/Icon';

const EmploymentHistory = ({
  employmentHistory,
  setEmploymentHistory,
  setEmploymentRemoveList,
}) => {
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [editId, setEditId] = useState('');

  const [modalText, setModalText] = useState('Dodaj zatrudnienie do listy');

  const [visible, setVisible] = useState(false);

  const startDateHandler = date => {
    setStartDate(date);
  };

  const endDateHandler = date => {
    setEndDate(date);
  };

  const handleDescriptionChange = e => {
    setDescription(e.target.value);
  };

  const closeModal = () => {
    setVisible(false);
    setCompany('');
    setPosition('');
    setDescription('');
    setStartDate('');
    setEndDate('');
  };

  const removeEmployment = id => {
    employmentHistory.forEach(element => {
      if (element.id === id || element.index === id + 1) {
        element.remove = true;
        setEmploymentRemoveList(employmentRemoveList => [
          ...employmentRemoveList,
          element,
        ]);
      }
    });

    setEmploymentHistory(current =>
      current.filter(employment => {
        return employment.id !== id || employment.index === id + 1;
      })
    );
  };

  const employmentSubmit = () => {
    if (modalText === 'Dodaj zatrudnienie do listy') {
      setEmploymentHistory(employmentHistory => [
        ...employmentHistory,
        {
          index: employmentHistory.length + 1,
          company_name: company,
          position: position,
          description: description,
          start_date: startDate,
          end_date: endDate,
          remove: false,
        },
      ]);
      closeModal();
    }

    if (modalText === 'Edytuj') {
      employmentHistory.forEach(element => {
        if (element.id === editId || element.index === editId) {
          element.company_name = company;
          element.position = position;
          element.description = description;
          element.start_date = startDate;
          element.end_date = endDate;
        }
      });
      closeModal();
    }
  };

  const openAddModal = () => {
    setVisible(true);
    setModalText('Dodaj zatrudnienie do listy');
  };

  const openEditModal = id => {
    setVisible(true);
    setModalText('Edytuj');
    employmentHistory.forEach(element => {
      if (element.id === id || element.index === id + 1) {
        if (element?.id) {
          setEditId(element?.id);
        } else {
          setEditId(element?.index);
        }
        setCompany(element?.company_name);
        setPosition(element?.position);
        setDescription(element?.description);
        setStartDate(element?.start_date);
        setEndDate(element?.end_date);
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={cn('title-red')}>
          <div className={styles.header_text}>Historia zatrudnień</div>
        </div>
        <Button
          onClick={() => openAddModal()}
          classNames={cn('button', 'button-small')}
        >
          Dodaj zatrudnienie
        </Button>
      </div>
      <div className={styles.list}>
        {employmentHistory?.length > 0 && (
          <div className={styles.employment_info}>
            <div className={styles.list_item}>Nazwa firmy</div>
            <div className={styles.list_item}>Pozycja</div>
            <div className={styles.list_item}>Okres</div>
          </div>
        )}
        {employmentHistory?.length > 0 ? (
          employmentHistory.map(
            (item, index) =>
              !item?.remove && (
                <div className={styles.list_container} key={index}>
                  <div className={styles.item_container}>
                    <div className={styles.employment_info_grid}>
                      <div className={styles.list_item}>
                        {item.company_name}
                      </div>
                      <div className={styles.list_item}>{item.position}</div>
                      <div className={styles.list_item}>
                        {item.start_date && (
                          <>
                            {new Date(item.start_date).getUTCDate()}/
                            {new Date(item.start_date).getUTCMonth()}/
                            {new Date(item.start_date).getUTCFullYear()} -{' '}
                          </>
                        )}
                        {item.end_date ? (
                          <>
                            {new Date(item.end_date).getUTCDate()}/
                            {new Date(item.end_date).getUTCMonth()}/
                            {new Date(item.end_date).getUTCFullYear()}
                          </>
                        ) : (
                          item.start_date && <>Teraz</>
                        )}
                      </div>
                      <div className={styles.buttons_container}>
                        <Button
                          onClick={() =>
                            removeEmployment(item?.id ? item?.id : index)
                          }
                          classNames={styles.button_remove}
                        >
                          <Icon name='close' size={18} />
                        </Button>
                        <Button
                          onClick={() =>
                            openEditModal(item?.id ? item?.id : index)
                          }
                          classNames={styles.button}
                        >
                          <Icon name='edit' size={18} />
                        </Button>
                      </div>
                    </div>
                    <div className={styles.list_description}>
                      {item.description}
                    </div>
                  </div>
                </div>
              )
          )
        ) : (
          <span className={styles.info}>
            Twoja historia zatrudnień jest pusta
          </span>
        )}
      </div>

      <Modal
        visible={visible}
        onClose={() => closeModal()}
        outerClassName={styles.modal}
      >
        <div className={styles.modal_container}>
          <div className={styles.names_container}>
            <TextInput
              className={styles.field}
              label='Nazwa firmy'
              placeholder='Podaj nazwę firmy'
              type='text'
              value={company}
              onChange={e => setCompany(e.target.value)}
            />
            <TextInput
              className={styles.field}
              label='Pozycja w firmie'
              placeholder='Podaj pozycję w firmie'
              type='text'
              value={position}
              onChange={e => setPosition(e.target.value)}
            />
          </div>
          <TextArea
            className={styles.text_area}
            label='Opis wykonywanych czynności w firmie'
            maxLength={2000}
            value={description}
            onTitleChange={handleDescriptionChange}
          />
          <div className={styles.dates_container}>
            <DatePicker
              yearRangeStart={1970}
              value={startDateHandler}
              label='Data rozpoczęcia pracy'
              startingDate={startDate}
            />
            <DatePicker
              yearRangeStart={1970}
              value={endDateHandler}
              label='Data zakończenia pracy'
              startingDate={endDate}
            />
          </div>
          <Button
            onClick={() => employmentSubmit()}
            classNames={styles.modal_button}
          >
            {modalText}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default EmploymentHistory;
