import React, { useState } from 'react';
import styles from './List.module.sass';
import cn from 'classnames';
import TextInput from 'components/TextInput';
import Icon from 'components/Icon';
import uuid from 'react-uuid';

const List = ({ classNames, list = [] }) => {
  return (
    list && (
      <ul className={styles.items_list}>
        {list.map(item => (
          <li className={styles.item} key={uuid()}>
            <Icon name='add' size={20} />
            {item}
          </li>
        ))}
      </ul>
    )
  );
};

export default List;
