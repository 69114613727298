import React, { useEffect, useState } from 'react';
import styles from './ProgressBar.module.sass';
import cn from 'classnames';

const ProgressBar = ({
  classNames,
  barColor,
  label,
  currentProgress,
  maxProgress,
  height = 20,
}) => {
  const [progress, setProgress] = useState(currentProgress / maxProgress);

  const calculateProgress = (current, max) => {
    setProgress((current / max) * 100);
  };

  useEffect(() => {
    calculateProgress(currentProgress, maxProgress);
  }, [currentProgress, maxProgress]);

  return (
    <div
      className={cn(styles.container, classNames && classNames)}
      style={{ height: `${height}px` }}
    >
      <div
        className={styles.bar}
        style={{
          width: `${progress}%`,
          backgroundColor: barColor,
        }}
      >
        {label && <span>{label}</span>}
      </div>
    </div>
  );
};

export default ProgressBar;
