import React, { useEffect, useRef, useState } from 'react';
import styles from './Post.module.sass';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Card from 'components/Card';
import cn from 'classnames';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import useAuth from 'hooks/useAuth';
//import RelatedCourses from 'components/Related/RelatedCourses';
//import RelatedPosts from 'components/Related/RelatedPosts';
//import RelatedJobOffer from 'components/Related/RelatedJobOffer';
import SkeletonLoader from 'screens/DetailsLayout/Loader';
import { levelsEndpoints, postsEndpoints } from 'api/endpoints';
import Panel from 'screens/DetailsLayout/Panel';
import AuthorHeader from 'screens/DetailsLayout/AuthorHeader';
import Content from 'screens/DetailsLayout/Content';
import Title from 'screens/DetailsLayout/Title';
import TagsList from 'screens/DetailsLayout/TagsList';
import Comments from 'screens/DetailsLayout/Comments';
import useBreakpoints from 'hooks/useBreakpoints';
import LinksList from 'screens/DetailsLayout/LinksList';
import AppHelmet from 'components/AppHelmet';
import CategoriesList from 'screens/DetailsLayout/CategoriesList';
import RelatedCourses from 'screens/DetailsLayout/RelatedCourses';


const Post = () => {
  // UTILS----------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  const isLoggedIn = authCtx.isLoggedIn

  const history = useHistory();

  const [article, setArticle] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [doesCourseExist, setDoesCourseExist] = useState(true);
  const [coursesList, setCoursesList] = useState([]);
  const [authorLevel, setAuthorLevel] = useState('Level 0');
  const { slug } = useParams();

  const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ['desktop', Infinity],
    ['mobile', 767],
  ];
  useBreakpoints(stylesRef, breakpoints);

  // UTILS----------------------------------------------------------------------UTILS
  // SCROLL TO SECTION----------------------------------------------SCROLL TO SECTION

  const section = useLocation().hash || null;
  const ratingRef = useRef(null);
  const commentsRef = useRef(null);

  useEffect(() => {
    if (ratingRef.current && section === '#rating') {
      const top = ratingRef.current.offsetTop;
      window.scrollTo({ top: top - 100, behavior: 'smooth' });
    }
    if (commentsRef.current && section === '#comments') {
      const top = commentsRef.current.offsetTop;
      window.scrollTo({
        top: top - 100,
        behavior: 'smooth',
      });
    }
  }, [article, ratingRef?.current, commentsRef?.current, section]);

  // SCROLL TO SECTION----------------------------------------------SCROLL TO SECTION
  // GET POST----------------------------------------------------------------GET POST

  const refresher = () => {
    setRefresh(prev => !prev);
  };

  const getPostId = async cancelToken => {
    try {
      const { data } = await axiosInstance.request({
        method: 'get',
        url: `${postsEndpoints.idAndSlug}?slug=${slug}`,
        cancelToken: cancelToken.token,
      });
      if (!data?.id) history.goBack();
      getPost(data.id, cancelToken);
    } catch (error) {
      console.log(error);
      setDoesCourseExist(false);
      //if (error?.response?.status === 500) history.goBack();
    }
  };

  const getPost = async (id, cancelToken) => {
    try {
      const response = await axiosInstance.request({
        method: 'get',
        url: `${postsEndpoints.show}${id}`,
        cancelToken: cancelToken.token,
        headers: {
          view: sessionStorage.getItem(`post${id}Seen`) == null ? true : false,
        },
      });
      const data = response.data[0];
      if (!data.id) history.goBack();

      setArticle(data);
      setCoursesList(data?.related_courses || []);

      fetchLevel(data.author);
      !article && authCtx.getLevel();
      sessionStorage.setItem(`post${data.id}Seen`, 'true');
    } catch (error) {
      console.log(error);
      setDoesCourseExist(false);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getPostId(cancelToken);

    window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, [slug, refresh]);

  // GET POST----------------------------------------------------------------GET POST

  const fetchLevel = id => {
    devscntrAuth
      .get(levelsEndpoints.userLevel, { headers: { 'USER-ID': id } })
      .then(response => {
        setAuthorLevel(response.data.current_level);
      });
  };

  // Increasing External Link clicks count ----------------------------------------/

  const onExternalLinkClicked = () => {
    if (!sessionStorage.getItem(`externalLinkPost${article.id}Seen`)) {
      axiosInstance
        .get(postsEndpoints.externalLinkCount + `${article.id}/`)
        .then(response => {
          sessionStorage.setItem(`externalLinkPost${article.id}Seen`, 'true');
          console.log(response);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  // Increasing External Link clicks count ----------------------------------------/

  return (
    <>
      {doesCourseExist ? (
        !article ? (
          <SkeletonLoader />
        ) : (
          <>
            <AppHelmet
              title={article?.meta_title}
              description={article?.meta_description}
              image={article?.image}
              type='article'
            >
              <meta
                property='og:article:published_time'
                content={article?.created_at}
              />
              <meta
                property='og:article:author'
                content={article?.display_name || 'itCNTR'}
              />
              <meta property='og:article:section' content='Post' />
              <meta
                property='og:article:tag'
                content={article?.tags[0]?.name}
              />
            </AppHelmet>
            <section ref={x => setStylesRef(x)}>
              <div className={styles.container} ref={ratingRef}>
                <div className={styles.content}>
                  <AuthorHeader
                    article={article}
                    type='post'
                    refresh={refresher}
                    rating={article?.rating || 0}
                    userRating={article?.rating || 0}
                    isRss={article?.is_rss || false}
                  />
                  <Title
                    title={article.title}
                    image={article.image}
                    url={article.external_url}
                    urlClicksCount={article.external_views}
                    urlClicked={onExternalLinkClicked}
                    buttonText='Przejdź do artykułu'
                    date={article?.created_at?.slice(0, 10) || false}
                  />
                  <Content
                    description={article?.description}
                    version={article?.editor_version}
                  />
                  <LinksList links={article.additional_url} />
                  {article?.categories?.length > 0 && (
                    <CategoriesList categories={article?.categories} baseLink='/posts' />
                  )}
                  <TagsList tags={article.tags} baseLink='/posts' />
                  <RelatedCourses
                    items={coursesList}
                    tags={article?.tags || []}
                  />
                  <Comments id={article.id} type='post' ref={commentsRef} />
                </div>
                <Panel
                  type='post'
                  id={article?.id}
                  currentReaction={
                    article?.emotion_of_user?.length > 0
                      ? article?.emotion_of_user[0]?.emotion
                      : 0
                  }
                  likes={article?.emotions_likes_count}
                  dislikes={article?.emotions_dislikes_count}
                  author={article.author}
                  author_image={article.author_image}
                  refresh={refresher}
                  level={authorLevel}
                  isRss={article?.is_rss || false}
                  isLoggedIn={isLoggedIn}
                />
              </div>
            </section>
          </>
        )
      ) : (
        <Card className={styles.post_does_not_exist}>
          <p>Taki Post nie istnieje!</p>
          <button
            className={cn('button', styles.homebtn)}
            onClick={() => history.push('/posts')}
          >
            Powrót do listy postów
          </button>
        </Card>
      )}
    </>
  );
};

export default Post;
