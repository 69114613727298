import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './LatestComments.module.sass';
import Card from '../Card';
import Item from './Item';
import axios from 'axios';
import SkeletonLoader from './Loader';

import useAuth from '../../hooks/useAuth';
import devscntrAuth from '../../api/Instance/devscntrAuth';
import devscntrNoauth from '../../api/Instance/devscntrNoauth';

const comments = [
  {
    title: 'Ethel',
    login: '@ethel',
    time: '1h',
    content: 'On <strong>Smiles – 3D icons</strong>',
    comment: 'Great work <span role="img" aria-label="smile">😊</span>',
    avatar: '/images/content/avatar.jpg',
  },
  {
    title: 'Jazmyn',
    login: '@jaz.designer',
    time: '8h',
    content: 'On <strong>Fleet - Travel shopping</strong>',
    comment: 'I need react version asap!',
    avatar: '/images/content/avatar-1.jpg',
  },
  {
    title: 'Ethel',
    login: '@ethel',
    time: '1h',
    content: 'On <strong>Smiles – 3D icons</strong>',
    comment: 'How can I buy only the design?',
    avatar: '/images/content/avatar-2.jpg',
  },
];

const LatestComments = ({ className, limit }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [comments, setComments] = useState([]);
  const [preparedComments, setPreparedComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getLatestComments = async cancelToken => {
    try {
      const response = await axiosInstance.request({
        method: 'GET',
        url: `/misc/newest-comments${limit > 0 ? `?number=${limit}` : '/'}`,
        cancelToken: cancelToken.token,
      });
      setComments(response.data);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('latest comments canceled');
      } else {
        console.log(error);
      }
    }
  };

  const prepareComments = () => {
    setPreparedComments(() =>
      comments?.map(
        (comment, index) =>
          limit > index && <Item comment={comment} key={index} />
      )
    );
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getLatestComments(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    prepareComments();
  }, [comments]);

  return (
    <Card
      className={cn(styles.card, className)}
      title='Najnowsze komentarze'
      classTitle='title-yellow'
    >
      <div className={styles.comments}>
        {isLoading ? (
          <SkeletonLoader cards={3} />
        ) : preparedComments?.length > 0 ? (
          <div className={styles.list}>{preparedComments}</div>
        ) : (
          <span>Brak komentarzy</span>
        )}
      </div>
    </Card>
  );
};

export default LatestComments;
