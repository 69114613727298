import React, { useContext, useState, useEffect } from "react";
import api from "../../../api/devscntr";
import axios from "axios";
// data
//import { notifications } from "../../../mocks/notifications";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import PostNotifications from "./PostNotifications";
import CommentsNotifications from "./CommentsNotifications";
import MiscNotifications from "./MiscNotifications";

import useAuth from "../../../hooks/useAuth";
import devscntrAuth from "../../../api/Instance/devscntrAuth";

const NOTIFICATION_URL = "/accounts/me/notification/";
const ADD_COMMENT_URL = "";

const intervals = ["Recent", "New", "This year"];

const actions = [
  {
    title: "Mark as read",
    icon: "check",
    action: () => console.log("Mark as read"),
  },
  {
    title: "Go setting",
    icon: "setting",
    action: () => console.log("Go setting"),
  },
];

const List = ({ className, filters }) => {
  const [sorting, setSorting] = useState(intervals[0]);

  const authCtx = useAuth();
  const [errMsg, setErrMsg] = useState("");
  const [notifications, setNotifications] = useState();

  const [received, error, loading, axiosFetch] = useAxiosFunction();

  const getNotificationsAPI = () => {
    axiosFetch({
      axiosInstance: api,
      method: "GET",
      url: NOTIFICATION_URL,
    });

    //catch (() => {
    //    setErrMsg("Could not load your notifications");
    //    console.log(error);
    //})
    //received.map(item => notifies.push({...item}))
  };
  useEffect(() => {
    if (received.length === 0) getNotificationsAPI();
    else
      setNotifications({
        newPosts: received["notifications posts"],
        newCommentsPosts: received["notifications comments posts"],
        newCommentsCourses: received["notifications comments courses"],
        newMisc: received["notification misc"],
      });
  }, [received]);


  const addCommentAPI = async (postId) => {
    try {
      const response = await devscntrAuth.post(
        ADD_COMMENT_URL,
        JSON.stringify({
          postId: postId,
        })
      );
      setErrMsg("");
    } catch (error) {
      setErrMsg("Could not add your comment");
      console.log(error);
    }
  };

  return (
    <>
      {(filters.includes("Posty") || filters.length === 0) && (
        <PostNotifications
          className={className}
          notifications={notifications}
          loading={loading}
          error={error}
        />
      )}
      <CommentsNotifications
        className={className}
        notifications={notifications}
        loading={loading}
        error={error}
        filters={filters}
      />
      {(filters.includes("Różne") || filters.length === 0) && (
        <MiscNotifications
          className={className}
          notifications={notifications}
          loading={loading}
          error={error}
          refresh={getNotificationsAPI}
        />
      )}
    </>
  );
};

export default List;
