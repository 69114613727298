import React from 'react';
import style from './BigFiveFacet.module.sass';

const scoreDict = {
  low: 'niski',
  neutral: 'neutralny',
  high: 'wysoki',
};

const BigFiveFacet = ({ facet }) => {
  const { title, scoreText, score, text } = facet;

  const scoreNameClassName = () => {
    switch (scoreText) {
      case 'low':
        return 'status-red-dark';
      case 'neutral':
        return 'status-yellow';
      case 'high':
        return 'status-green-dark';
      default:
        return 'status-yellow';
    }
  };

  if (!facet) return null;
  return (
    <div className={style.facet}>
      <div className={style.header}>
        <h4 className={style.title}>{title}</h4>
        <div className={scoreNameClassName()}>
          {scoreDict[scoreText || 'neutral']}
        </div>
        <div className='status-blue'>{score} pkt.</div>
      </div>
      <p className={style.description}>{text}</p>
    </div>
  );
};

export default BigFiveFacet;
