import React, { useRef, useState } from "react";
import styles from "./ImageInput.module.sass";
import cn from "classnames";
import Card from "components/Card";
import File from "components/File";
import Tooltip from "components/Tooltip";
import Icon from "components/Icon";
import { fileSizes } from "data/dataArrays";
import PhotoUploader from "components/PhotoUploader";
import { validateImage } from "utils/validation";

const ImageInput = ({
  className,
  label,
  tooltip,
  title,
  img,
  setFile,
  onDelete,
  fileName,
  cropperHeight,
  // onImageChange
}) => {
  const inputRef = useRef();
  // image is IMAGEbase64 used to display on screen
  const [image, setImage] = useState(img || null);
  const [cropperData, setCropperData] = useState({
    visible: false,
    image: null,
    width: 0,
    height: 0,
    filename: "",
  });

  // Cropping image submit function
  const onCropSubmit = (file, imgBase64) => {
    // Hide cropper on submit
    setCropperData((prev) => ({
      ...prev,
      visible: false,
    }));

    setFile(file);
    setImage(imgBase64);
    // onImageChange()

  };

  const onChange = () => {};

  const onFileChange = (e) => {
    // Get user's file
    const file = e.target.files[0];

    if (!validateImage(file, fileSizes["thumbnail"])) return;
    // Prepare data for image cropping
    setCropperData({
      visible: true,
      image: file,
      width: 250,
      height: cropperHeight ? cropperHeight : 200,
      filename: `${fileName}_${Math.floor(
        Math.random() * (1000000000000 - 1)
      )}`,
    });
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleDelete = () => {
    setImage(null);
    setFile(null);
    onDelete && onDelete();
  };

  return (
    <>
      {cropperData.visible && (
        <PhotoUploader
          onSubmit={onCropSubmit}
          
          sourceImage={cropperData.image}
          width={cropperData.width}
          height={cropperData.height}
          filename={cropperData.filename}
        />
      )}
      <Card
        className={cn(styles.card, className)}
        title={title}
        classTitle="title-blue"
      >
        <div className={styles.images}>
          <div className={cn(styles.file, className)}>
            {label && (
              <div className={styles.label}>
                {label}{" "}
                {tooltip && (
                  <Tooltip
                    className={styles.tooltip}
                    title={tooltip}
                    icon="info"
                    place="right"
                  />
                )}
              </div>
            )}
            <div className={styles.wrap}>
              {image && <img src={image} alt="Obraz" />}
              <input
                className={styles.input}
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={onFileChange}
              />
              {image ? (
                <div className={styles.box} onClick={handleDelete}>
                  <Icon name="trash" size="24" />
                  Usuń
                </div>
              ) : (
                <div className={styles.box} onClick={handleClick}>
                  <Icon name="upload" size="24" />
                  Prześlij obraz
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
export default ImageInput;
