/**
 * Checks if given string is a JSON format
 * @param {String} str - given string
 */
export default function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
