import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './Jobs.module.sass';
import SkeletonLoader from 'components/MiniJob/Loader';
import axios from 'axios';

import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import MiniJob from 'components/MiniJob';
import { pageSize } from 'data/dataArrays';
import PaginationGrowingFooter from 'components/PaginationGrowingFooter';

const Jobs = ({ userId }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(false);
  const [jobsList, setJobsList] = useState([]);

  // PAGINATION------------------------------------------------------------------PAGINATION

  const [currentPage, setCurentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(true);

  const observer = useRef();
  const lastItemRef = useCallback(
    post => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting && !isLastPage) {
          console.log('We are near the last post!');
          setCurentPage(prev => ++prev);
        }
      });

      if (post) observer.current.observe(post);
    },
    [jobsList]
  );

  // PAGINATION------------------------------------------------------------------PAGINATION

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getJobs(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [userId, currentPage]);

  const getJobs = async cancelToken => {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.request({
        method: 'GET',
        url: `/jobs/user-job/?page=${currentPage}&page_size=${pageSize}`,
        headers: {
          'USER-ID': userId,
          METHOD: 'list',
        },
        cancelToken: cancelToken.token,
      });

      // const resData = response.data.results || [];
      // let data = resData.filter(item => item.title) || [];

      // if (authCtx.isAdmin) {
      //   data = resData;
      // }

      if (data?.next) setIsLastPage(false);
      else setIsLastPage(true);

      const results = data?.results || [];

      setJobsList(prev => [...prev, ...results]);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('user jobs canceled');
      } else {
        console.log(error);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.jobs}>
        {/* <div className={cn('title-yellow', styles.subtitle)}>Oferty pracy</div> */}
        <div className={styles.jobs_list}>
          {isLoading ? (
            <SkeletonLoader cards={2} className={styles.item} />
          ) : jobsList?.length > 0 ? (
            <>
              <div className={styles.items_list}>
                {jobsList.map((job, index) => (
                  <MiniJob
                    className={styles.item}
                    article={job}
                    key={index}
                    ref={jobsList.length === index + 1 ? lastItemRef : null}
                  />
                ))}
              </div>
              <PaginationGrowingFooter
                data={jobsList}
                isLastPage={isLastPage}
                isLoading={isLoading}
                setCurrentPage={setCurentPage}
              />
            </>
          ) : (
            <p className={styles.info}>Brak aktualnych ofert pracy</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default Jobs;
