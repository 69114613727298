import React, { useState, useEffect } from 'react';
import styles from './CategoriesFilter.module.sass';
import cn from 'classnames';
import Icon from 'components/Icon';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import useAuth from 'hooks/useAuth';
import RoadMap from 'screens/JakZaczacWIt/RoadMap';
import Modal from 'components/Modal';

const CategoriesFilter = ({ className, categories, categorySelector }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [visible, setVisible] = useState(false);
  const [roadMap, setRoadMap] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [activeCategoriesSlugs, setActiveCategoriesSlugs] = useState([]);

  const getCategoriesRoadMap = async () => {
    const temp = await axiosInstance.get(`misc/categories`);
    setRoadMap(temp?.data);
  };

  useEffect(() => {
    getCategoriesRoadMap();
  }, []);

  const checkItems = (data, item) => {
    data.forEach(element => {
      if (element.name === item.name) {
        element.checked = !element.checked;
      }
      if (element.children.length > 0) {
        checkItems(element.children, item);
      }
    });
  };

  const checkMultipleItems = (data, categoryArray) => {
    data.forEach(element => {
      if (categoryArray.includes(element.name)) {
        element.checked = true;
      }
      if (!categoryArray.includes(element.name)) {
        element.checked = false;
      }

      if (element.children.length > 0) {
        checkMultipleItems(element.children, categories);
      }
    });
  };

  const roadMapFunction = item => {
    if (!categories.includes(item.name)) {
      categorySelector(categories => [...categories, item.name]);
    }
    if (categories.includes(item.name)) {
      categorySelector(current => current.filter(name => name !== item.name));
    }

    checkItems(treeData, item);
  };

  const uncheckAllItems = data => {
    return data.map(item => ({
      ...item,
      slug: item.slug,
      checked: false,
      children: item.children.length ? uncheckAllItems(item.children) : [],
    }));
  };

  const addToTreeData = (data, parentSlug) => {
    return data.map(item => ({
      ...item,
      slug: parentSlug ? parentSlug + '_' + item.id : item.id.toString(),
      checked: categories.includes(item.name),
      children: item.children.length
        ? addToTreeData(
            item.children,
            parentSlug ? parentSlug + '_' + item.id : item.id
          )
        : [],
    }));
  };

  const addToActiveSlugs = data => {
    if (!visible) {
      data.forEach(element => {
        if (element.checked) {
          if (!activeCategoriesSlugs.includes(element.slug))
            setActiveCategoriesSlugs(activeCategoriesSlugs => [
              ...activeCategoriesSlugs,
              element.slug,
            ]);
        }
        if (!element.checked) {
          setActiveCategoriesSlugs(current =>
            current.filter(slug => slug !== element.slug)
          );
        }
        if (element.children) {
          addToActiveSlugs(element.children);
        }
      });
    }
  };

  useEffect(() => {
    setTreeData(addToTreeData(roadMap));
  }, [roadMap]);

  useEffect(() => {
    addToActiveSlugs(treeData);
  }, [treeData, categories, visible]);

  useEffect(() => {
    if (!(categories.length > 0)) {
      setTreeData(uncheckAllItems(treeData));
    }
    if (categories.length > 0) {
      checkMultipleItems(treeData, categories);
    }
  }, [categories]);

  return (
    <>
      <button
        className={cn(className, styles.button)}
        onClick={() => setVisible(true)}
      >
        Kategorie
      </button>

      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        outerClassName={styles.modal_container}
      >
        <RoadMap
          title={'Wybierz kategorie'}
          data={treeData}
          roadMapFunction={roadMapFunction}
          hideIcon
          className={styles.roadmap_container}
          isCategorySelector
          categories={categories}
          categoriesSlugs={activeCategoriesSlugs}
        />
      </Modal>
    </>
  );
};
export default CategoriesFilter;
