import React, { useState, useEffect } from 'react';
import styles from './Breadcrumbs.module.sass';
import Icon from 'components/Icon';
import classNames from 'classnames';

const Breadcrumbs = props => {
  const [pathArray, setPathArray] = useState();
  const names = props.names;
  const pathName = props.item;

  useEffect(() => {
    if (props.item) {
      setPathArray(props.item.split('/').slice(1));
    } else {
      setPathArray(props.names);
    }
  }, [props.item, props.names]);

  return (
    <div className={classNames(styles.Breadcrumb, props?.className)}>
      <div className={styles.JZWIT}>
        {pathArray?.map((item, index) => (
          <div key={index}>
            {index > 0 && (
              <Icon
                className={styles.breadcrumps_icon}
                fill='gray'
                name='arrow-next'
                size={18}
              ></Icon>
            )}
            <button
              className={styles.breadcrumps_button}
              onClick={() => {
                props.navigate(pathName, item);
              }}
            >
              {names?.length > 0 && names[index]}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
