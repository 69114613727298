import React from "react";
import cn from "classnames";
import styles from "./Card.module.sass";

const Card = ({
  className,
  title,
  subtitle,
  classTitle,
  classCardHead,
  head,
  children,
  oneLine,
}) => {
  const headClassNames = oneLine && styles.head_one_line;
  return (
    <div className={cn(styles.card, className)}>
      {title && (
        <div className={cn(styles.head, classCardHead, headClassNames, { [styles.hasSubtitle]: !!subtitle })}>
          <div className={cn(classTitle, styles.title)}>{title}</div>
          {head && head}
        </div>
      )}
      {subtitle && subtitle !== 'null' &&
        (
          <div className={styles.subtitle}>{subtitle}</div>
        )}
      {children}
    </div>
  );
};

export default Card;
