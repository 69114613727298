import React from 'react';
import styles from './AboutMeContact.module.sass';
import Icon from 'components/Icon';
import cn from 'classnames';

const AboutMeContact = ({ phone, email }) => {
  function phoneFormat(phone) {
    return phone?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return (
    <div className={styles.container}>
      <div className={cn('title-darkPurple', styles.header)}>Kontakt</div>
      <div className={styles.contact_data}>
        {phone && (
          <div className={styles.phone}>
            <Icon name='phone' size={24} />
            <a href={'tel:' + phone}>{phoneFormat(phone)}</a>
          </div>
        )}
        {email && (
          <div className={styles.email}>
            <Icon name='mail' size={24} />
            <a href={'mailto:' + email}>{email}</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutMeContact;
