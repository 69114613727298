export const tagColors = [
  "#E6908B",
  "#E6A085",
  "#FAB594",
  "#E6B370",
  "#DEB79B",
  "#FAEB65",
  "#F5F3B8",
  "#D9FA9A",
  "#A5F5D1",
  "#E8FF9F",
  "#BAE0CD",
  "#9DD5FA",
  "#A6ECF5",
  "#7ABAF5",
  "#869CF5",
  "#83EAF5",
  "#A8AEFF",
  "#B3C1FF",
  "#C5E8F5",
  "#F2D1FE",
  "#FEDCE0",
  "#FFEBD7",
  "#FFFCDD",
  "#F6FEE0",
  "#E1FFDF",
  "#E9FEFD",
];
