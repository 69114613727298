import React, { useEffect, useState } from 'react';
import styles from './Application.module.sass';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import MiniJobOffer from 'components/MiniJobOffer';
import Icon from 'components/Icon';
import MiniJob from 'components/MiniJob';

const Application = ({ item, recruiter = false }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fileName = item?.file?.split('/').pop() || null;
    console.log(fileName);
    setData({
      id: item.id,
      description: item.info || 'Brak opisu',
      author: item.author_displayname || 'Brak nazwy',
      job: item.job,
      file: item.file,
      filename: fileName,
    });
  }, [item]);

  return (
    data && (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.description}>
            <h3 className={styles.heading}>Wiadomość</h3>
            <div className={styles.author}>{data.author}</div>
            {data.description}
            <div className={styles.attachments}>
              {data.file && (
                <a
                  href={data.file}
                  target='_blank'
                  className={styles.attachment}
                >
                  <Icon name='download' size={20} />
                  <span className={styles.attachment_name}>
                    {data.filename || 'plik.pdf'}
                  </span>
                </a>
              )}
            </div>
          </div>

          <Link
            to={`/account/${recruiter ? 'received' : 'my'}-job-applications/${
              data.id
            }`}
            className='button-stroke'
          >
            Zobacz CV
          </Link>
          {/* <button
            className='button'
            disabled={true}
            style={{ cursor: 'not-allowed', opacity: 0.5 }}
          >
            Zobacz CV (wkrótce dostępne)
          </button> */}
        </div>
        <div className={styles.job}>
          <h3 className={styles.heading}>Oferta</h3>
          <MiniJob className={styles.job_item} article={data.job} recommended />
        </div>
      </div>
    )
  );
};

export default Application;
