import React, { useEffect, useState } from 'react';
import styles from './EditorContentRender.module.sass';
import cn from 'classnames';
import { convertFromRaw, EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';

const EditorContentRender = ({ className, data }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (data) {
      let fixedDescription = EditorState.createWithContent(
        convertFromRaw(JSON.parse(data))
      );
      setContent(convertToHTML(fixedDescription.getCurrentContent()));
    }
  }, [data]);

  return (
    content && (
      <div
        className={cn(styles.content, { [className]: className })}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    )
  );
};

export default EditorContentRender;
