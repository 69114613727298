import React, { useEffect, useState } from 'react';
import styles from './Submenu.module.sass';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import Icon from 'components/Icon';
import uuid from 'react-uuid';
import Dropdown from 'components/Dropdown';
import OutsideClickHandler from 'react-outside-click-handler';

const Submenu = ({ classNames, menu }) => {
  const location = useLocation();
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(menu[0]);

  const currentPath = location.pathname;

  const handleClick = (url, index) => {
    history.push(url);
    setCurrent(menu[index]);
    setVisible(false);
  };

  return (
    menu && (
      <div>
        <ul className={cn(styles.menu, classNames && classNames)}>
          {menu.map(
            item =>
              item.title &&
              (item.url ? (
                <li key={uuid()}>
                  <Link
                    to={item.url}
                    className={cn(
                      styles.item,
                      currentPath.includes(item.url) && styles.active
                    )}
                  >
                    {item.icon && <Icon name={item.icon} size={20} />}
                    {item.title}
                  </Link>
                </li>
              ) : (
                <li key={uuid()}></li>
              ))
          )}
        </ul>

        <div className={styles.dropdown_menu}>
          <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
              className={cn(styles.dropdown, {
                [styles.active]: visible,
              })}
            >
              <div
                className={cn(styles.head)}
                onClick={() => setVisible(!visible)}
              >
                <div className={styles.selection}>{current.title}</div>
              </div>
              <div className={cn(styles.body)}>
                {menu?.map((item, index) => (
                  <div
                    className={cn(styles.option, {
                      [styles.selectioned]: currentPath.includes(item.url),
                    })}
                    onClick={() => handleClick(item.url, index)}
                    key={index}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    )
  );
};
export default Submenu;
