import React, { useEffect, useState } from 'react';
import styles from './Points.module.sass';
import cn from 'classnames';
import devscntrAuth from 'api/Instance/devscntrAuth';
import axios from 'axios';
import { adminEndpoints } from 'api/endpoints';
import Card from 'components/Card';
import Icon from 'components/Icon';
import { showError } from 'utils/showError';
import Modal from 'components/Modal';
import AddPoints from './AddPoints';
import uuid from 'react-uuid';
import useAuth from 'hooks/useAuth';
import { validateInput } from 'utils/validation';

const Points = () => {
  // UTILS-------------------------------------------------------------------------UTILS
  const authCtx = useAuth();

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);

  const [points, setPoints] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [currentEditId, setCurrentEditId] = useState(null);
  const [editName, setEditName] = useState('');
  const [editShortDesc, setEditShortDesc] = useState('');
  const [editXpPoints, setEditXpPoints] = useState('');

  // UTILS-------------------------------------------------------------------------UTILS
  // GET LEVELS---------------------------------------------------------------GET LEVELS

  const getPoints = async cancelToken => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: adminEndpoints.settings.points,
        cancelToken: cancelToken.token,
      });
      setPoints(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('admin levels canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getPoints(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [refresh]);

  // GET LEVELS---------------------------------------------------------------GET LEVELS
  // ADD LEVEL-----------------------------------------------------------------ADD LEVEL

  const onAddPoints = () => {
    setAddModalVisible(false);
    setRefresh(prev => !prev);
  };

  // ADD LEVEL-----------------------------------------------------------------ADD LEVEL
  // VALIDATION---------------------------------------------------------------VALIDATION

  const validation = () => {
    if (!validateInput(editName, 30, 1, 'nazwa', 'text')) return false;
    if (!validateInput(editXpPoints, 20, 1, 'punkty', 'numbers')) return false;

    return true;
  };

  // VALIDATION---------------------------------------------------------------VALIDATION
  // EDIT LEVEL---------------------------------------------------------------EDIT LEVEL

  const editPoints = item => {
    setCurrentEditId(item.id);
    setEditName(item.action);
    setEditXpPoints(item.points);
    setEditShortDesc(item.shortdesc);
  };

  const saveEditPoints = async () => {
    if (!validation()) return;
    try {
      const response = await devscntrAuth.request({
        method: 'put',
        url: `${adminEndpoints.settings.points}${currentEditId}/`,
        data: {
          action: editName,
          points: editXpPoints,
        },
      });
      setCurrentEditId(null);
      setRefresh(prev => !prev);
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
      showError('Nie udało się zapisać zmian');
    }
  };

  // EDIT LEVEL---------------------------------------------------------------EDIT LEVEL
  // DELETE LEVEL-----------------------------------------------------------DELETE LEVEL

  const deletePoints = async id => {
    if (confirmDeleteId !== id) {
      setConfirmDeleteId(id);
      setTimeout(() => {
        setConfirmDeleteId(null);
      }, 10000);
    } else {
      try {
        const response = await devscntrAuth.delete(
          `${adminEndpoints.settings.levels}${id}`
        );
        console.log(id);
        setRefresh(prev => !prev);
        authCtx.getLevel();
      } catch (error) {
        console.log(error);
        showError('Nie udało się zapisać zmian');
      }
    }
  };

  // DELETE LEVEL-----------------------------------------------------------DELETE LEVEL

  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title='Zarządzanie punktami'
      classTitle={cn('title-yellow', styles.title)}
      // head={
      //   <button className='button' onClick={() => setAddModalVisible(true)}>
      //     <Icon name='add' size={20} />
      //     Dodaj punkty
      //   </button>
      // }
    >
      <Modal
        visible={addModalVisible}
        onClose={() => setAddModalVisible(false)}
      >
        <AddPoints onSuccess={onAddPoints} />
      </Modal>
      <div className={styles.container}>
        <table>
          <thead>
            <tr>
              <th>Nazwa</th>
              <th>Kod</th>
              <th>Punkty</th>
              <th>Edycja</th>
            </tr>
          </thead>
          <tbody>
            {points.map(item =>
              currentEditId !== item.id ? (
                <tr key={uuid()}>
                  <td width='40%'>{item.action}</td>
                  <td width='25%'>{item.shortdesc}</td>
                  <td width='15%'>{item.points}</td>
                  <td className={styles.buttons} width='20%'>
                    <button
                      className={styles.edit_button}
                      onClick={() => editPoints(item)}
                    >
                      <Icon name='edit' size={20} />
                    </button>
                    {/* <button
                      className={cn(
                        styles.delete_button,
                        confirmDeleteId == item.id && styles.confirm_delete
                      )}
                      onClick={() => deletePoints(item.id)}
                    >
                      <Icon name='trash' size={20} />
                    </button> */}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td width='40%'>
                    <input
                      type='text'
                      value={editName}
                      className={styles.input}
                      onChange={e => setEditName(e.target.value)}
                    />
                  </td>
                  <td width='25%'>
                    <input
                      type='text'
                      value={editShortDesc}
                      className={styles.input}
                      onChange={e => setEditShortDesc(e.target.value)}
                      readOnly
                    />
                  </td>
                  <td width='15%'>
                    <input
                      type='text'
                      value={editXpPoints}
                      className={styles.input}
                      onChange={e => setEditXpPoints(e.target.value)}
                    />
                  </td>
                  <td className={styles.buttons} width='20%'>
                    <button
                      className={styles.edit_button}
                      onClick={saveEditPoints}
                    >
                      <Icon name='check' size={20} />
                    </button>
                    <button
                      className={styles.cancel_button}
                      onClick={() => setCurrentEditId(null)}
                    >
                      <Icon name='close' size={20} />
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default Points;
