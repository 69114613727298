import React, { useState, useEffect, useContext } from 'react';
import styles from '../LinksInput.module.sass';
import cn from 'classnames';
import Icon from 'components/Icon';

const Link = ({ link, index, links, setLinks, remove }) => {

    const [linkValue, setLinkValue] = useState(link.title);
    const [linkUrl, setLinkUrl] = useState(link.url);

    const temp = links

    useEffect(() => {
        temp[index].title = linkValue
        setLinks(temp)
    }, [linkValue]);

    useEffect(() => {
        temp[index].url = linkUrl
        setLinks(temp)
    }, [linkUrl]);

    useEffect(() => {
        setLinkValue(links[index].title)
        setLinkUrl(links[index].url)
    }, [links]);

    return (
        <>
            <div className={styles.input_container}>
                <input
                    className={cn(styles.input, styles.input_title)}
                    value={linkValue}
                    onChange={e => setLinkValue(e.target.value)}
                    maxLength='100'
                />
                <input
                    className={cn(styles.input, styles.input_link)}
                    value={linkUrl}
                    onChange={e => setLinkUrl(e.target.value)}
                    maxLength='100'
                />
                <button className={cn('button', styles.button)} onClick={e => remove(e, index)}>
                    <Icon name='close' size={18} />
                </button>
            </div>
        </>
    );
};

export default Link;