import styles from './FilterSettings.module.sass';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';

import cn from 'classnames';

export default function FilterSettings({
  statusName,
  setStatusName,
  statusOptions,
  clearFilters,

  pageLimitValue,
  setPageLimit,
  pageLimitOptions,
  isPagination,
  isStatus,
  isReset,
}) {
  return (
    <div className={styles.settings}>
      {isPagination && (
        <div className={styles.item}>
          <div className={styles.label}>Ilość publikacji na stronę</div>
          <div className={styles.box}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              classDropdownLabel={styles.label}
              value={pageLimitValue}
              setValue={setPageLimit}
              options={pageLimitOptions}
            />
            <Icon name="filter" size="24" fill="#6F767E" />
          </div>
        </div>
      )}

      {isStatus && (
        <div className={styles.item}>
          <div className={styles.label}>Status publikacji</div>
          <div className={styles.box}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              classDropdownLabel={styles.label}
              value={statusName}
              setValue={setStatusName}
              options={statusOptions}
            />
            <Icon name="filter" size="24" fill="#6F767E" />
          </div>
        </div>
      )}
      {isReset &&(
      <div className={styles.btns}>
        <button
          className={cn("button", styles.button)}
          onClick={clearFilters}
        >
          Resetuj filtry
        </button>
        {/* <button
            className={cn("button", styles.button)}
            onClick={props.onFiltersSubmit}
          >
            Zastosuj filtry
          </button> */}
      </div>
      )}
    </div>
  )
}
