import React from 'react';
import styles from './Category.module.sass';
import cn from 'classnames';
import { colorRelatedToBackground } from 'utils';
import Icon from 'components/Icon';

const Category = ({
  classNames,
  name,
  selected = false,
  visible = true,
  color = '#ffffff',
  onClick = () => {},
}) => {
  const bgColor = color !== 'undefined' ? color : '#ffffff';

  return (
    visible && (
      <button
        className={cn(
          styles.category,
          selected && styles.selected,
          colorRelatedToBackground(bgColor) === 'light'
            ? styles.text_light
            : styles.text_dark,
          classNames && classNames
        )}
        style={{
          backgroundColor: bgColor,
        }}
        onClick={onClick}
      >
        {selected && <Icon name='check' size={14} />}
        {name}
      </button>
    )
  );
};

export default Category;
