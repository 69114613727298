import HorizontalScroll from 'components/HorizontalScroll';
import styles from './PinsCategories.module.sass';
import cn from 'classnames';

export default function PinsCategories({
  data,
  selectedCategory,
  onCategoryChange,
}) {
  return (
    <div className={styles.container}>
      {/* <h3 className={styles.title}>Kategorie</h3> */}
      <HorizontalScroll className={styles.list} controls wide>
        <div
          className={cn(styles.box, {
            [styles.active]: selectedCategory === '',
          })}
          onClick={() => onCategoryChange('')}
        >
          Wszystkie
        </div>
        {data.map(category => (
          <div
            className={cn(styles.box, {
              [styles.active]: category.name === selectedCategory,
            })}
            key={category.id}
            onClick={() => onCategoryChange(category.name)}
          >
            {category.name}
          </div>
        ))}
      </HorizontalScroll>
    </div>
  );
}
