import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import uuid from "react-uuid";

import Icon from "components/Icon";
import ModalDraft from "components/ModalDraft";

import devscntrAuth from "api/Instance/devscntrAuth";
import { TypeNames } from "../Row/utilities/names";
import { setTypeName } from "../Row/utilities";


export default function Panel({
  quantity,
  disabled = true,
  setReRender,
  checkedPublications,
}) {
  const [disabledDeletion, setDisabledDeletion] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteErrorModal, setShowDeleteErrorModal] = useState(false);
  const [notSentTitles, setNotSentTitles] = useState([]);
  const [selectionText, setSelectionText] = useState('Brak wybranych elementów');

  useEffect(() => {
    if (quantity > 4) {
      setSelectionText(`${quantity} wybranych elementów`);
    } else if (quantity > 1) {
      setSelectionText(`${quantity} wybrane elementy`);
    } else if (quantity === 1) {
      setSelectionText(`1 wybrany element`);
    } else {
      setSelectionText('Brak wybranych elementów');
    }
  }, [quantity])

  const showModalHandler = () => {
    setShowModal(true);
  }

  const hideModalHandler = () => {
    setShowModal(false);
  }

  const showDeleteErrorModalHandler = () => {
    setShowDeleteErrorModal(true);
  }

  const hideDeleteErrorModalHandler = () => {
    setShowDeleteErrorModal(false);
    setNotSentTitles([]);
  }


  /* -------------------------------------------------------------------------- */
  /*                               delete handler                               */
  /* -------------------------------------------------------------------------- */

  // raz się odpali gdy klikniesz buttona
  const deleteHandler = async () => {

    /* ------------------------------ DELETING LOOP ----------------------------- */
    const promises = await checkedPublications?.map(obj => {
      const typeName = setTypeName(obj.type);

      const setUrl = type => {
        switch (type) {
          case TypeNames.POST:
            return `/posts/article/create-post/${obj.id}/`;
          case TypeNames.COURSE:
            return `/course/course-actions/${obj.id}/`;
          case TypeNames.JOB:
            return `/jobs/job-create/${obj.id}/`;
          default:
            return undefined;
        }
      }
      const url = setUrl(typeName);

      return { promise: devscntrAuth.delete(url), title: obj.title };
    });

    // TEST-CAUSING ERROR
    // await promises.push({ promise: Promise.reject(new Error('an error')), title: 'The title of rejected promise' });

    /* ---------------------- WHEN ALL PROMISES ARE SETTLED --------------------- */

    await Promise.allSettled(promises).then(objArr => {
      const rejectedPromises = objArr?.map(obj => {
        return obj.value.promise.then(fulfilled => undefined, rejected => {
          setNotSentTitles(latest => {
            return [...latest, obj.value.title]
          })
          return obj;
        })
      });

      Promise.all(rejectedPromises).then(() => {
        setReRender();
        hideModalHandler();
        notSentTitles.length > 0 && showDeleteErrorModalHandler();
      });
    });
  }


  return (
    <>
      {/* <div className={cn("panel", styles.panel)}> */}
      <div className={cn(styles.panel)}>

        <button
          className={cn("button", styles.button, {
            [styles.disabled]: disabled
          })}
          disabled={disabled}
          onClick={showModalHandler}
        >
          Zmień zaznaczone
        </button>

        <div className={styles.info}>
          <Icon name="check-all" size="24" />
          {selectionText}
        </div>

        {/* <div className={styles.btns}> */}
        {/* <button
            className={cn("button-stroke-red", styles.button, {
              [styles.disabled]: disabled
            })}
            disabled={disabled}
            onClick={showModalHandler}
          >
            <span>Usuń</span>
            <Icon name="trash" size="24" />
          </button> */}
        {/* <button className={cn("button", styles.button)}>Wykonaj</button> */}
        {/* </div> */}
      </div>


      {/* ------------------------------ ACTIONS MODAL ----------------------------- */}

      <ModalDraft
        visible={showModal}
        onClose={hideModalHandler}
      >
        <div className={cn(styles.info, styles['info--modal'])}>
          <Icon name="check-all" size="24" />
          {selectionText}
        </div>

        <div className={styles.modalActiveTitles}>
          {checkedPublications?.map(obj => <div className={styles.modalActiveTitle} key={uuid()}>{obj.title}</div>)}
        </div>

        <button
          className={cn("button-stroke-red", styles.button, {
            [styles.disabled]: disabledDeletion
          })}
          disabled={disabledDeletion}
          onClick={deleteHandler}
        >
          <span>Usuń</span>
          <Icon name="trash" size="24" />
        </button>

      </ModalDraft>

      {/* --------------------------- DELETE ERROR MODAL --------------------------- */}

      <ModalDraft
        visible={showDeleteErrorModal}
        onClose={hideDeleteErrorModalHandler}
      >
        <div className={cn(styles.info, styles['info--modal'], styles.modalWarning)}>
          Nie udało się usunąć:
        </div>

        <div className={styles.modalActiveTitles}>
          {notSentTitles?.map(title => <div className={cn(styles.modalActiveTitle, styles.modalActiveTitleWarn)} key={uuid()}>{title}</div>)}
        </div>
      </ModalDraft>
    </>
  );
};