import Modal from "components/Modal";
import styles from "./BanModal.module.sass"
import React, { useState } from "react";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import { adminEndpoints } from "../../api/endpoints";
import Schedule from "../Schedule";
import DatePicker from "react-datepicker";
import cn from "classnames";
import Icon from "../Icon";

const BanModal = ({userID, visible, setVisible}) => {
    const [ reason, setReason ] = useState('');
    const [ expirationDate, setExpirationDate ] = useState(new Date());

    const [ visibleDate, setVisibleDate ] = useState(false);

    const [ isSubmitted, setIsSubmitted ] = useState(false);

    const days = [ "Pon", "Wt", "Śr", "Czw", "Pt", "Sob", "Niedz" ];
    const months = [
        "Styczeń",
        "Luty",
        "Marzec",
        "Kwiecień",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpień",
        "Wrzesień",
        "Październik",
        "Listopad",
        "Grudzień",
    ];

    const locale = {
        localize: {
            day: (n) => days[ n ],
            month: (n) => months[ n ],
        },
        formatLong: {
            date: () => "yyyy-mm-dd",
        },
    };

    const handleBanUser = () => {
        const fixeddate = {
            day: (expirationDate.getDate() < 10 && "0") + (expirationDate.getDate()),
            month: (expirationDate.getMonth() + 1 < 10 && "0") + (expirationDate.getMonth() + 1),
            year: expirationDate.getFullYear()
        }
        const formData = new FormData();
        formData.append('user', userID);
        formData.append('reason', reason);
        formData.append('expiration_date', `${fixeddate.year}-${fixeddate.month}-${fixeddate.day}T00:00`);
        console.log(`${fixeddate.year}-${fixeddate.month}-${fixeddate.day}T00:00`)

        try {
            const response = devscntrAuth.request({
                method: 'post',
                data: formData,
                url: `${adminEndpoints.users.ban}`,
            });
            console.log(response.data);
            setIsSubmitted(true);
        } catch (e) {
            console.log(e);
        }

        console.log('banned');

    };

    return (
        <Modal
            outerClassName={styles.modal}
            visible={visible}
            onClose={() => setVisible(false)}
        >
            {!isSubmitted
                ? <div className={styles.container}>
                    <h3>Zablokuj użytkownika</h3>
                    <p className={styles.label}>Data odblokowania:</p>
                    <div className={styles.selecteddate}>
                        <div className={styles.dateinput} onClick={() => setVisibleDate(prev => !prev)}>
                            <Icon name="calendar" size={24}/>
                            <p>{expirationDate.toLocaleDateString()}</p>
                        </div>
                        {visibleDate && <div className={styles.date}>
                            <DatePicker
                                className={styles.datepicker}
                                selected={expirationDate}
                                onChange={(date) => {
                                    setExpirationDate(date)
                                    setVisibleDate(false)
                                }}
                                dateFormatCalendar={"MMMM yyyy"}
                                locale={locale}
                                dateFormat="yyyy-mm-dd"
                                timeFormat="HH:mm"
                                inline
                            />
                        </div>
                        }
                    </div>

                    <p className={styles.label}>Powód:</p>
                    <textarea className={styles.reason} onChange={(e) => setReason(e.target.value)} value={reason}/>
                    <div className={styles.buttons}>
                        <button className={cn("button", styles.submit)} onClick={handleBanUser}>
                            Zablokuj
                        </button>
                        <button className={cn("button-stroke")} onClick={() => setVisible(false)}>
                            Anuluj
                        </button>
                    </div>
                </div>
                : <div className={styles.container}>
                    <h3>Użytkownik został zablokowany</h3>
                    <p>Do: {expirationDate.toLocaleDateString()}</p>
                    <p>Powód: "{reason}"</p>
                </div>
            }
        </Modal>
    )
}

export default BanModal;