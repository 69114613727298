import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Sorting.module.sass";
import Dropdown from "../../../components/Dropdown";
import { jobSortingOptions } from "../../../data/dataArrays";

const Sorting = ({ setSortMethod }) => {
  const [sortBy, setSortBy] = useState(jobSortingOptions[0].name);
  const [isMounted, setIsMounted] = useState(false);

  /*
  useEffect(() => {
    if (!isMounted) {
      let index;
      jobSortingOptions.forEach((option, i) => {
        if (option.value === sortMethod) {
          index = i;
        }
        console.log(option)
        console.log(sortMethod)
      });
      let sortByName = jobSortingOptions[index].name;
      console.log(sortByName)
      setSortBy(options[sortByName]);
    }
  }, [])
  */

  useEffect(() => {
    if (isMounted) {
      let index;
      jobSortingOptions.forEach((item, i) => {
        if (item.name === sortBy) {
          index = i;
        }
      });
      console.log(index);
      let sortMethod = jobSortingOptions[index].value;
      console.log(jobSortingOptions[index].value);

      setSortMethod(sortMethod);
    } else {
      setIsMounted(true);
    }
  }, [sortBy]);

  return <Dropdown options={jobSortingOptions.map((item) => item.name)} value={sortBy} setValue={setSortBy} />;
};
export default Sorting;
