import React, { useEffect, useState } from 'react';
import styles from './JobOffer.module.sass';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import useAuth from 'hooks/useAuth';
//import RelatedCourses from './Related/RelatedCourses';
import RelatedCourses from 'screens/DetailsLayout/RelatedCourses';
// import RelatedPosts from 'components/Related/RelatedPosts';
// import RelatedJobOffer from 'components/Related/RelatedJobOffer';
import SkeletonLoader from 'screens/DetailsLayout/Loader';
import {
  accountsEndpoints,
  jobsEndpoints,
  levelsEndpoints,
  postsEndpoints,
} from 'api/endpoints';
import Panel from 'screens/DetailsLayout/Panel';
import AuthorHeader from 'screens/DetailsLayout/AuthorHeader';
import Content from 'screens/DetailsLayout/Content';
import TitleThumbnail from 'screens/DetailsLayout/Title';
import TagsList from 'screens/DetailsLayout/TagsList';
// import Comments from 'screens/DetailsLayout/Comments';
import useBreakpoints from 'hooks/useBreakpoints';
import AboutCompany from 'components/AboutCompany';
import Details from './Details';
// import Localization from './Localization';
import TechStack from './TechStack';
import Modal from 'components/Modal';
import CvForm from '../Account/CvForm';
import { showLoginModal } from 'utils/showLoginModal';
import ApplicationForm from './ApplicationForm';
import GoBackButton from 'components/GoBackButton';
import Title from './Title';
import Header from './Header';
import LinksList from 'screens/DetailsLayout/LinksList';

const JobOffer = () => {
  // UTILS----------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const [applicationVisible, setApplicationVisible] = useState(false);

  const [article, setArticle] = useState(null);
  const [coursesList, setCoursesList] = useState([]);

  const [author, setAuthor] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [viewed, setViewed] = useState(false);
  const [authorLevel, setAuthorLevel] = useState('Level 0');
  const { id } = useParams();

  const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ['desktop', Infinity],
    ['mobile', 767],
  ];
  useBreakpoints(stylesRef, breakpoints);

  const showApplicationForm = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    setApplicationVisible(true);
  };

  // UTILS----------------------------------------------------------------------UTILS
  // GET JOB------------------------------------------------------------------GET JOB

  const refresher = () => {
    setRefresh(prev => !prev);
  };

  const getJob = async cancelToken => {
    setIsLoading(true);
    try {
      // Request user data
      const response = await axiosInstance.request({
        method: 'get',
        url: `${jobsEndpoints.show}${id}`,
        cancelToken: cancelToken.token,
        headers: {
          view: !viewed,
        },
      });

      const data = response.data[0];
      if (!data.id) history.goBack();
      // setJobsList(data?.linkedjobs || []);
      console.log('data', { data });
      setCoursesList(data?.linkedcourses || []);
      setArticle(data);
      if (!data?.is_rss) getAccountInfo(cancelToken);
      !article && authCtx.getLevel();
      setViewed(true);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('job detail canceled');
      } else {
        console.log(error);
        if (error?.response?.status === 500) history.goBack();
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getJob(cancelToken);
    window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, [id, refresh]);

  // GET POST----------------------------------------------------------------GET POST
  // GET ACCOUNT----------------------------------------------------------GET ACCOUNT

  const getAccountInfo = async cancelToken => {
    try {
      // Request user data
      const response = await axiosInstance.request({
        method: 'get',
        url: accountsEndpoints.userData.userInfo,
        cancelToken: cancelToken.token,
        headers: {
          'USER-ID': article?.author_id,
        },
      });
      setAuthor(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('account info canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (article) setIsLoading(false);
  }, [article]);

  // GET ACCOUNT----------------------------------------------------------GET ACCOUNT

  const fetchLevel = id => {
    devscntrAuth
      .get(levelsEndpoints.userLevel, { headers: { 'USER-ID': id } })
      .then(response => {
        setAuthorLevel(response.data.current_level);
      });
  };

  // Increasing External Link clicks count ----------------------------------------/

  const onExternalLinkClicked = () => {
    axiosInstance
      .get(jobsEndpoints.externalLinkCount + `${article.id}/`)
      .then(response => {
        console.log(response);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Increasing External Link clicks count ----------------------------------------/

  return (
    <>
      <GoBackButton path={'/jobs'} className={styles.go_back_button} />
      {!isLoading && article ? (
        <section ref={x => setStylesRef(x)}>
          <div className={styles.container}>
            <div className={styles.content}>
              {article?.is_rss && (
                <Header
                  article={article}
                  pinned={article?.pinned || false}
                  views={article?.views}
                />
              )}
              {!article?.is_rss && (
                <AuthorHeader
                  article={article}
                  author={author}
                  type='job'
                  refresh={refresher}
                  isRss={article.is_rss || false}
                />
              )}

              {article?.is_rss && (
                <Title title={article?.title} date={article?.created_at} />
              )}
              {!article?.is_rss && (
                <TitleThumbnail
                  title={article.title}
                  subtitle={article.company_name}
                  image={article.company_logo}
                  imageFit='contain'
                  imageClassName={styles.image_wrapper}
                  date={article?.created_at?.slice(0, 10) || false}
                  url={article.application_link}
                  urlClicksCount={article.external_views}
                  urlClicked={onExternalLinkClicked}
                  buttonText='Aplikuj'
                  locations={article?.localization || []}
                  //onButtonClick={showApplicationForm}
                />
              )}
              <Details article={article} />
              <LinksList links={article.additional_url} />
              {article?.indepthtags.length > 0 && (
                <TechStack tags={article.indepthtags} />
              )}
              {/* <Localization locations={article?.localization || []} /> */}
              {/* {article?.content && ( */}
              <Content
                description={article?.content}
                version={article?.editor_version}
              />
              {/* )} */}
              {!article?.is_rss && <ApplicationForm job={id} />}
              <TagsList tags={article?.tags} baseLink='/jobs' />
              {author?.user && (
                <AboutCompany
                  user={author.user}
                  company={author?.user?.company}
                />
              )}

              {/* tutaj komponent z kursami */}

              <RelatedCourses items={coursesList} tags={article?.tags || []} />
            </div>
            <Panel
              type='job'
              id={article?.id}
              currentReaction={
                article?.emotion_of_user?.length > 0
                  ? article?.emotion_of_user[0]?.emotion
                  : 0
              }
              likes={article?.emotions_likes_count}
              dislikes={article?.emotions_dislikes_count}
              author={article?.author_id || null}
              author_image={article?.author_image}
              refresh={refresher}
              level={authorLevel}
              isRss={article.is_rss || false}
            />
          </div>
          <Modal
            outerClassName={styles.application_modal_outer}
            visible={applicationVisible}
            onClose={() => setApplicationVisible(false)}
          >
            <div className={styles.application_modal_inner}>
              <div className={'title-blue'}>Aplikuj o pracę</div>
              <CvForm classNames={styles.application_form} job={id} />
            </div>
          </Modal>
        </section>
      ) : (
        <SkeletonLoader />
      )}
    </>
  );
};

export default JobOffer;
