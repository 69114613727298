import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Card from 'components/Card';
import styles from './DeleteButton.module.sass';
import { showError } from 'utils/showError';
import Icon from 'components/Icon';
import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";
import devscntrAuth from "api/Instance/devscntrAuth";
import { TypeNames } from '../../Row/utilities/names';
import { setTypeName } from '../../Row/utilities';
import ModalDraft from "components/ModalDraft";
import uuid from "react-uuid";

const DeleteButton = (
    { quantity,
        disabled,
        setReRender,
        checkedPublications,
    }) => {
    const [visible, setVisible] = useState(false);

    const [disabledDeletion, setDisabledDeletion] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteErrorModal, setShowDeleteErrorModal] = useState(false);
    const [notSentTitles, setNotSentTitles] = useState([]);
    const [selectionText, setSelectionText] = useState('Brak wybranych elementów');

    useEffect(() => {
        if (quantity > 4) {
            setSelectionText(`Czy chcesz usunąć ${quantity} wybranych elementów`);
        } else if (quantity > 1) {
            setSelectionText(`Czy chcesz usunąć ${quantity} wybrane elementy`);
        } else if (quantity === 1) {
            setSelectionText(`Czy chcesz usunąć 1 element?`);
        } else {
            setSelectionText('Brak wybranych elementów');
        }
    }, [quantity])

    const showModalHandler = () => {
        setShowModal(true);
    }

    const hideModalHandler = () => {
        setShowModal(false);
    }

    const showDeleteErrorModalHandler = () => {
        setShowDeleteErrorModal(true);
    }

    const hideDeleteErrorModalHandler = () => {
        setShowDeleteErrorModal(false);
        setNotSentTitles([]);
    }

    /* ------------------------------ DELETING LOOP ----------------------------- */
    const deleteHandler = async () => {
        const promises = await checkedPublications?.map(obj => {
            const typeName = setTypeName(obj.type);

            const setUrl = type => {
                switch (type) {
                    case TypeNames.POST:
                        return `/posts/article/create-post/${obj.id}/`;
                    case TypeNames.COURSE:
                        return `/course/course-actions/${obj.id}/`;
                    case TypeNames.JOB:
                        return `/jobs/job-create/${obj.id}/`;
                    case TypeNames.CODE:
                        return `/course/course-promo-code/${obj.id}/`;
                    default:
                        return undefined
                }
            }
            const url = setUrl(typeName);

            return { promise: devscntrAuth.delete(url), title: obj.title };
        });



        await Promise.allSettled(promises).then(objArr => {
            const rejectedPromises = objArr?.map(obj => {
                return obj.value.promise.then(fulfilled => undefined, rejected => {
                    setNotSentTitles(latest => {
                        return [...latest, obj.value.title]
                    })
                    return obj;
                })
            });

            Promise.all(rejectedPromises).then(() => {
                setReRender();
                hideModalHandler();
                notSentTitles.length > 0 && showDeleteErrorModalHandler();
            });
        });
    }
    /* ------------------------------ DELETING LOOP END ----------------------------- */

    return (
        <>
            <div className={cn(styles.filters)}>

                <button
                    className={cn(styles.trash_button, {
                        [styles.disabled]: disabled
                    }, { [styles.enabled]: !disabled })}
                    disabled={disabled}
                    onClick={showModalHandler}
                >
                    <Icon name="trash" size="24" />
                </button>
            </div>


            {/* ------------------------------ ACTIONS MODAL ----------------------------- */}

            <ModalDraft
                visible={showModal}
                onClose={hideModalHandler}
            >
                    <div className={cn(styles.info, styles['info--modal'])}>
                        {selectionText}
                    </div>

                    <div className={styles.modalActiveTitles}>
                        {checkedPublications?.map(obj => <div className={styles.modalActiveTitle} key={uuid()}>
                            {obj.title || obj.code}
                            </div>)}
                    </div>

                    <button
                        className={cn("button-stroke-red", styles.button, {
                            [styles.disabled]: disabledDeletion
                        })}
                        disabled={disabledDeletion}
                        onClick={deleteHandler}
                    >
                        <span>Usuń</span>
                        <Icon name="trash" size="24" />
                    </button>
            </ModalDraft>

            {/* --------------------------- DELETE ERROR MODAL --------------------------- */}

            <ModalDraft
                visible={showDeleteErrorModal}
                onClose={hideDeleteErrorModalHandler}
            >
                <div className={cn(styles.info, styles['info--modal'], styles.modalWarning)}>
                    Nie udało się usunąć:
                </div>

                <div className={styles.modalActiveTitles}>
                    {notSentTitles?.map(title => <div className={cn(styles.modalActiveTitle, styles.modalActiveTitleWarn)} key={uuid()}>{title}</div>)}
                </div>
            </ModalDraft >
        </>
    );
};

export default DeleteButton;