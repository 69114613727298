import React, { useEffect, useState } from 'react';
import styles from './UserActions.module.sass';
import cn from 'classnames';
import Actions from 'components/Actions';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { accountsEndpoints, supportEndpoints } from 'api/endpoints';
import { showError } from 'utils/showError';
import useAuth from 'hooks/useAuth';
import ModalReport from 'components/ModalReport';

const UserActions = ({ className, id, hide = () => {} }) => {
  const authCtx = useAuth();
  const [userBlockLoader, setUserBlockLoader] = useState(false);

  // REPORT USER-------------------------------------------------------------------------------REPORT USER

  const [reportVisible, setReportVisible] = useState(false);

  const reportUser = async (title, description) => {
    try {
      const response = await devscntrAuth.post(
        supportEndpoints.reportUser,
        {
          reporteduser: id,
          title: title,
          description: description,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } catch (error) {
      console.log(error);
      showError('Zgłoszenie nie powiodło się. Spróbuj ponownie później');
    }
  };

  // REPORT USER-------------------------------------------------------------------------------REPORT USER
  // BLOCK USER---------------------------------------------------------------------------------BLOCK USER

  const [blockedUser, setBlockedUser] = useState(false);

  const handleBlock = () => {
    if (authCtx.userID == id) return;
    if (userBlockLoader) return;
    if (blockedUser) unblockApi(id);
    else blockApi(id);
  };

  const blockApi = async id => {
    if (!id) return;
    setUserBlockLoader(true);
    try {
      const response = await devscntrAuth.post(
        `${accountsEndpoints.userContent.userBlocked}`,
        {
          blockeduser: id,
          reason: '',
        }
      );
      authCtx.updateBlockedUsers(id);
    } catch (error) {
      console.log(error);
      showError('Zablokowanie nie powiodło się. Spróbuj ponownie później');
    } finally {
      setUserBlockLoader(false);
    }
  };

  const unblockApi = async id => {
    if (!id) return;
    setUserBlockLoader(true);
    try {
      const response = await devscntrAuth.delete(
        `${accountsEndpoints.userContent.userBlocked}${id}/`
      );
      authCtx.updateBlockedUsers(id);
      hide();
    } catch (error) {
      console.log(error);
      showError('Odblokowanie nie powiodło się. Spróbuj ponownie później');
    } finally {
      setUserBlockLoader(false);
    }
  };

  useEffect(() => {
    setBlockedUser(authCtx.blockedUsers.includes(parseInt(id)));
  }, [authCtx.blockedUsers]);

  // BLOCK USER---------------------------------------------------------------------------------BLOCK USER
  // ACTIONS---------------------------------------------------------------------------------------ACTIONS

  const actions = [
    {
      title: blockedUser ? 'Odblokuj' : 'Zablokuj',
      icon: 'profile-circle',
      color: blockedUser,
      loading: userBlockLoader,
      action: () => handleBlock(),
    },

    authCtx.isLoggedIn && {
      title: 'Zgłoś',
      icon: 'ticket',
      action: () => setReportVisible(true),
    },
  ];

  // ACTIONS---------------------------------------------------------------------------------------ACTIONS

  return (
    authCtx.isLoggedIn &&
    authCtx.userID != id && (
      <>
        <ModalReport
          visible={reportVisible}
          onClose={() => setReportVisible(false)}
          onSubmit={reportUser}
        />
        <Actions
          className={cn(styles.menu, { [className]: className })}
          classActionsHead={styles.settings}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          items={actions}
        />
      </>
    )
  );
};

export default UserActions;
