import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './ActivateAccount.module.sass';
import { Link, useParams } from 'react-router-dom';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import { accountsEndpoints } from 'api/endpoints';
import Loader from 'components/Loader';
import Image from 'components/Image';
import TextInput from 'components/TextInput';
import { showSuccess } from 'utils/showSuccess';
import { showError } from 'utils/showError';
import { validateInput } from 'utils/validation';
import { validationRules } from 'data/validationRules';
import Button from 'components/Button';

const AcitvateAccount = () => {
  const [email, setEmail] = useState('');
  const [resent, setResent] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const { uidb64, token } = useParams();
  // console.log({ uidb64: uidb64 });
  // console.log({ token: token });

  const confirmActivation = async () => {
    try {
      const response = await devscntrNoauth.post(
        accountsEndpoints.auth.registerActivate,
        {
          uidb64: uidb64,
          token: token,
        }
      );
      console.log(response);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSuccess(false);
    }
  };

  useEffect(() => {
    confirmActivation();
  }, [uidb64, token]);

  // RESEND EMAIL VERIFICATION-----------------------------------------------RESEND EMAIL VERIFICATION

  const resendEmailVerification = async () => {
    setProcessing(true);
    try {
      const response = await devscntrNoauth.post(
        accountsEndpoints.auth.registerResendActivation,
        { email: email }
      );
      showSuccess('Wysłaliśmy link aktywacyjny. Sprawdź swoja skrzynkę email.');
      setResent(true);
    } catch (err) {
      console.log(err);
      showError(
        'Wystąpił błąd podczas wysyłania linku aktywacyjnego. Spróbuj ponownie później.'
      );
    } finally {
      setProcessing(false);
    }
  };

  const handleKeyDown = e => {
    if (e.key !== 'Enter') return;
    handleResendEmailVerification();
  };

  const handleResendEmailVerification = () => {
    if (
      !validateInput(
        email,
        validationRules.email.maxChars,
        validationRules.email.minChars,
        validationRules.email.name,
        validationRules.email.security
      )
    )
      return;
    resendEmailVerification();
  };

  // RESEND EMAIL VERIFICATION-----------------------------------------------RESEND EMAIL VERIFICATION

  return (
    <div className={styles.wrapper}>
      <Link
        className={styles.logo}
        style={{ alignSelf: loading ? 'center' : 'flex-start' }}
        to='/'
      >
        <Image
          className={styles.pic}
          src='/images/logo-light.svg'
          srcDark='/images/logo-dark.svg'
          alt='itCNTR'
        />
      </Link>
      {loading ? (
        <>
          <div className={styles.loader_title}>Trwa aktywacja konta</div>
          <Loader className={styles.loader} />
        </>
      ) : (
        <div className={styles.body}>
          {success ? (
            <>
              <div className={cn('h2', styles.title)}>
                Rejestracja zakończona pomyślnie
              </div>
              <div className={styles.info}>Możesz się teraz zalogować</div>
              <Link to='/sign-in' className={cn('button', styles.button)}>
                <span>Przejdź do logowania</span>
              </Link>
              <div className={styles.note}>Twoje konto jest już aktywne</div>
            </>
          ) : resent ? (
            <>
              <div className={cn('h2', styles.title)}>
                Wysłaliśmy Tobie link aktywacyjny
              </div>
              <div className={styles.info}>Sprawdź swoją skrzynkę email</div>
              <Link to='/sign-in' className={cn('button', styles.button)}>
                <span>Przejdź do logowania</span>
              </Link>
            </>
          ) : (
            <>
              <div className={cn('h2', styles.title)}>
                Aktywacja nie powiodła się
              </div>
              <div className={styles.errorNote}>
                Twój link aktywacyjny wygasł, lub jest niepoprawny
              </div>
              <TextInput
                placeholder='Podaj adres email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                className={styles.email_input}
              />
              <Button
                classNames={styles.button}
                onClick={handleResendEmailVerification}
                isLoading={processing}
              >
                <span>Wyślij link ponownie</span>
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AcitvateAccount;
