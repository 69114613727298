import styles from './RatingInput.module.sass';
import Icon from 'components/Icon';
import React, { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { showLoginModal } from 'utils/showLoginModal';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { showError } from 'utils/showError';
import { coursesEndpoints, postsEndpoints } from 'api/endpoints';
import { emotionChangeDelay } from 'data/dataArrays';

const clickDelay = emotionChangeDelay;

const ratingApi = {
  post: postsEndpoints.rating,
  course: coursesEndpoints.rating,
};

const RatingInput = ({
  id,
  author,
  refresh,
  type,
  articleRating,
  userRating,
}) => {
  const authCtx = useAuth();
  const [rateChangeVisible, setRateChangeVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [allowClick, setAllowClick] = useState(true);
  const [isRated, setIsRated] = useState(userRating !== null);

  const [rating, setRating] = useState(userRating && userRating);

  let ratingUrl = ratingApi[type] ?? '';

  /* -------------------------------------------------------------------------- */
  /*                            change rating handler                           */
  /* -------------------------------------------------------------------------- */

  const changeRating = newRating => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    if (author == authCtx.userID) {
      showError('Nie możesz ocenić swojej treści');
      return;
    }
    setRating((newRating * 5) / 100);
    postRating(newRating);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  requests                                  */
  /* -------------------------------------------------------------------------- */

  const postRating = async localRating => {
    if (processing) return;

    // ANTI-SPAM
    if (!allowClick) return;
    setAllowClick(false);
    setTimeout(() => setAllowClick(true), clickDelay);

    let preparedRating = (localRating * 5) / 100;
    let data;

    if (type === 'post') data = { post: id, stars: preparedRating };
    if (type === 'course')
      data = { course: id, rating: preparedRating, author: author };

    try {
      setProcessing(true);
      await devscntrAuth.post(ratingUrl, data);
      refresh && refresh();
      !isRated && authCtx.getLevel();
    } catch (error) {
      showError('Nie udało się przesłać oceny. Spróbuj ponownie później');
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div
      className={styles.rating}
      onMouseEnter={() => setRateChangeVisible(true)}
      onMouseLeave={() => setRateChangeVisible(false)}
    >
      <Icon name='star-fill' size='24' />

      {rateChangeVisible
        ? parseFloat(rating).toFixed(1)
        : parseFloat(articleRating).toFixed(1)}
      {rateChangeVisible && (
        <div className={styles.ratechange}>
          <Rating
            size={20}
            ratingValue={rating}
            onClick={newRating => changeRating(newRating)}
          />
        </div>
      )}
    </div>
  );
};
export default RatingInput;
