import React, { useState, useEffect, useContext } from 'react';
import LatestComments from '../../components/LatestComments';
import LatestCourses from '../../components/LatestCourses';
import styles from './JZWIT.module.sass';
import useAxios from '../../hooks/useAxios';
import MiniJZWIT from '../../components/MiniJZWIT';
import Breadcrumbs from './Breadcrumbs';
import TechnologyLoader from './TechnologyDetail/Loader/technology';
import { jzwitEndpoints, jzwitv2Endpoints } from 'api/endpoints';
import JZWITContext from 'context/jzwitContext';
import RecommendedPosts from 'components/RecommendedPosts';
import AverageSalary from 'components/AverageSalary';
import { useHistory } from 'react-router-dom';
import { convertFromRaw, EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import MoreOptionsJZWIT from './MoreOptions';
import Related from 'components/Related';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import { TempleHinduTwoTone } from '@mui/icons-material';
import cn from 'classnames';
import Trends from './Trends';
import MainInformation from './MainInformation';
import Sections from './Sections';
import parseWithOptions from 'date-fns/esm/fp/parseWithOptions/index';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getEnvName } from 'utils';
import useAuth from 'hooks/useAuth';
import Card from 'components/Card';
import NextStepLink from './NextStepLink';
import RoadMap from './RoadMap';
import AverageEarnings from 'screens/EarningsSection/AverageEarnings';
import JZWIT_Content from './JZWIT_Content';
import JZWIT_Comments from './JZWIT_Comments';

const JZWIT = () => {
  const [roadMap, setRoadMap] = useState([]);
  const [loadingTechnology, setLoadingTechnology] = useState(false);
  const [currentObject, setCurrentObject] = useState(null);
  const [earningsData, setEarningsData] = useState([])
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname
  const currentSlug = pathName.substr(1).replace(/\//g, '_');
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  const isAdmin = authCtx.isAdmin;
  const isLoggedIn = authCtx.isLoggedIn

  // <----- POBIERNAIE TECHNOLOGI ----->
  const getTechnologyRoadMap = async () => {
    const temp = await axiosInstance.get(
      `${jzwitv2Endpoints.displayTech}`,
      {
        headers: {
          method: 'roadmap',
        },
      }
    );
    setRoadMap(temp?.data)
  };

  useEffect(() => {
    getTechnologyRoadMap()
  }, []);

  useEffect(() => {
    getTechnologySlug(currentSlug)
    window.scrollTo(0, 0);
  }, [pathName]);

  const getTechnologySlug = async (slug) => {
    setLoadingTechnology(true)
    try {
      const temp = await axiosInstance.get(
        `${jzwitv2Endpoints.displayTech}${slug}/`,
      )
      if (temp?.data?.main_data) {
        setCurrentObject(temp?.data?.main_data)
        prepareDataForEarnings(temp?.data?.main_data.earnings)
      }
    } catch (error) {
      console.log(error)
      currentUrl('jak-zaczac-w-it')
    }
    setLoadingTechnology(false)
  }

  const currentUrl = (slug) => {
    const newPath = slug.split('_')
    const newUrl = newPath.join('/')
    history.replace(`/${newUrl}`)
  }

  const childrenButtonHandler = (item) => {
    getTechnologySlug(item.slug)
    currentUrl(item.slug)
  }

  const navigateHandler = (slug, slugName) => {
    const navigateSlug = slug.split(slugName)[0].concat(slugName);
    const navigateFullSlug = navigateSlug.replace(/\//g, '_').slice(1);

    getTechnologySlug(navigateFullSlug)
    currentUrl(navigateFullSlug)
  }

  const goBackDelete = () => {
    const goBackPath = pathName.split('/');
    goBackPath.pop();
    const goBackSlug = goBackPath.join('_').replace('_', '')
    getTechnologySlug(goBackSlug);
    getTechnologyRoadMap();
    currentUrl(goBackSlug);
  }

  const prepareDataForEarnings = (data) => {
    const temp = [{
      name: data?.name ? data?.name : 'Brak podanej nazwy',
      median:
      {
        series:
          [
            { name: 'Overall', data: [data?.overall?.median_earnings && data?.overall?.median_earnings] },
            { name: 'Junior', data: [data?.junior?.median_earnings && data?.junior?.median_earnings] },
            { name: 'Mid', data: [data?.mid?.median_earnings && data?.mid?.median_earnings] },
            { name: 'Senior', data: [data?.senior?.median_earnings && data?.senior?.median_earnings] },
          ]
      },
      range: {
        junior: [{ x: "9-2023", y: [data?.junior?.min_earnings, data?.junior?.max_earnings] }],
        mid: [{ x: "9-2023", y: [data?.mid?.min_earnings, data?.mid?.max_earnings] }],
        senior: [{ x: "9-2023", y: [data?.senior?.min_earnings, data?.senior?.max_earnings] }],
        overall: [{ x: "9-2023", y: [data?.overall?.min_earnings, data?.overall?.max_earnings] }]
      },
      color: data?.color ? data?.color : 'gray',
    },]

    setEarningsData(temp)
  }

  return (
    <main className={styles.row}>
      <>
        <section className={styles.col}>
          <div className={styles.content}>
            {loadingTechnology ? (
              <TechnologyLoader />
            ) : (
              <>
                <header className={styles.subhead}>
                  <Breadcrumbs
                    item={pathName}
                    navigate={navigateHandler}
                    names={currentObject?.names_list}
                  />
                </header>
                {isAdmin && isLoggedIn && (
                  <div className={styles.jzwit_more_options}>
                    <MoreOptionsJZWIT
                      item={currentObject}
                      goBack={goBackDelete}
                    />
                  </div>
                )}
                {currentObject?.children && (
                  <>
                    <MainInformation currentObject={currentObject} />

                    <JZWIT_Content currentObject={currentObject} earningsData={earningsData} />

                  </>
                )}
                {roadMap[0]?.children?.length > 0 && (
                  <RoadMap
                    title='Wybierz swoją ścieżkę w IT'
                    data={roadMap[0].children}
                    roadMapFunction={childrenButtonHandler}
                    currentSlug={currentSlug}
                    className={styles.roadmap_container}
                    hasSearchBar
                  />
                )}
                {currentObject?.children && (
                  <JZWIT_Comments id={currentObject?.id} type='jzwit' />
                )}
              </>
            )}
          </div>
        </section>
        <div className={styles.column}>
          <AverageSalary className={styles.card} limit={3} />
          <LatestCourses className={styles.card} limit={3} />
          <RecommendedPosts className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </>
    </main>
  );
};

export default JZWIT;
