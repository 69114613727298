import React from "react";
import style from "./AboutMeLoader.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AboutMeLoader = () => {
    return (
        <SkeletonTheme >
            <div className={style.skeleton}>
                <div className={style.contentTech}>
                    <Skeleton width={'100%'} height={150} className={style.text} />
                </div>
                <div className={style.contentTech}>
                    <Skeleton width={'100%'} height={150} className={style.text} />
                </div>
                <div className={style.contentTech}>
                    <Skeleton width={'100%'} height={150} className={style.text} />
                </div>
                <div className={style.contentTech}>
                    <Skeleton width={'100%'} height={150} className={style.text} />
                </div>
                <div className={style.contentTech}>
                    <Skeleton width={'100%'} height={150} className={style.text} />
                </div>
                <div className={style.contentTech}>
                    <Skeleton width={'100%'} height={150} className={style.text} />
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default AboutMeLoader;