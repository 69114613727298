import { useSelector, useDispatch } from 'react-redux';
import { getNotIncludedCharacter } from 'utils';
import { showError } from './showError';

export const validateInput = (
  value = '',
  maxChars = 255,
  minChars = 0,
  fieldName = '',
  security
) => {
  const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lettersLatinExtended = 'ąćęłńóśźżĄĆĘŁŃÓŚŹŻ';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*()+,.?-_';
  const textChars = '?!.:"\'\n';
  const whiteSpace = ' ';

  let allowedCharacters;
  let message = '';

  switch (security) {
    case 0:
      // EVERYTHING ALLOWED
      console.log('Bez ograniczeń');
      break;
    case 1:
      // LETTERS, NUMBERS, SPECIAL CHARS
      console.log('Dozwolone znaki specjalne, cyfry i litery');
      allowedCharacters =
        letters + lettersLatinExtended + numbers + specialChars;
      break;
    case 2:
      // LETTERS AND NUMBERS
      console.log('Dozwolone tylko cyfry i litery');
      allowedCharacters = letters + lettersLatinExtended + numbers;
      break;
    case 3:
      // PRICE
      console.log('Dozwolone tylko cyfry, przecinek i kropka');
      allowedCharacters = numbers + ',.';
      break;
    case 'letters':
      console.log('Dozwolone tylko litery');
      allowedCharacters = letters + lettersLatinExtended;
      break;
    case 'numbers':
      console.log('Dozwolone tylko cyfry');
      allowedCharacters = numbers;
      break;
    case 'text':
      console.log('Dozwolone znaki specjalne, cyfry i litery');
      allowedCharacters =
        letters +
        lettersLatinExtended +
        numbers +
        specialChars +
        whiteSpace +
        textChars;
      break;
    case 'username':
      console.log('Dozwolone cyfry i litery');
      allowedCharacters = letters + numbers + '_-.';
      break;
    case 'password':
      console.log('password');
      allowedCharacters = letters + numbers + '!@#$%^&*()+,.?-_';
    default:
      allowedCharacters =
        letters +
        lettersLatinExtended +
        numbers +
        specialChars +
        whiteSpace +
        textChars;
      break;
  }

  // Length validation
  if (value.length <= 0) {
    message += '\n• nie może być puste';
  } else if (minChars === maxChars) {
    if (value.length < maxChars) {
      message += `\n• musi zawierać ${maxChars} znaków`;
    }
  } else {
    if (value.length < minChars || value.length > maxChars) {
      message += `\n• musi zawierać ${minChars} - ${maxChars} znaków`;
    }
  }

  // Characters validation
  if (security === 'email') {
    const allowedCharacters =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!allowedCharacters.test(value)) {
      message += '\n• musi być poprawnym adresem email';
    }
  } else {
    const notMatched = getNotIncludedCharacter(value, allowedCharacters);
    if (notMatched.length > 0) {
      let preparedNotMatched = notMatched;
      preparedNotMatched = preparedNotMatched.replaceAll('\n', '');
      preparedNotMatched = preparedNotMatched.replaceAll(' ', '');

      message += `\n• znaki [ ${preparedNotMatched} ] są zabronione`;
      if (notMatched.includes(' ')) message += '\n• nie może zawierać spacji';
      if (notMatched.includes('\n')) message += '\n• nie może zawierać enterów';
    }
  }

  // Show error message
  if (message.length > 0) {
    message = `${fieldName ? `Pole "${fieldName}":` : 'To pole:'}` + message;
    showError(message);
    return false;
  }

  return true;
};

export const validateUrl = url => {
  try {
    if (Boolean(new URL(url))) {
      return true;
    } else {
      showError(
        `Podany adres URL jest nieprawidłowy (przykład: https://itcntr.com/)`
      );
      return false;
    }
  } catch (e) {
    showError(
      `Podany adres URL jest nieprawidłowy (przykład: https://itcntr.com/)`
    );
    return false;
  }
};

export const validateImage = (file, maxFileSize = 5) => {
  // Allowed file extensions
  const allowedExtensions = /(\.png|\.jpg|\.jpeg)$/i;

  // Check file's size
  if (file?.size / 1024 / 1024 > maxFileSize) {
    showError(`Maksymalny rozmiar pliku to ${maxFileSize} MB`);
    return false;
  }
  // Check file's extension
  if (!allowedExtensions.exec(file?.name)) {
    showError(`Możesz przesłać tylko pliki w formacie .png, .jpg, .jpeg`);
    return false;
  }

  // If file is OK
  return true;
};
