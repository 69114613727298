import React, { useEffect, useState } from 'react';
import styles from './ApplicationForm.module.sass';
import cn from 'classnames';
import TextArea from 'components/TextArea';
import FileInput from 'components/FileInput';
import useAuth from 'hooks/useAuth';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { validateInput } from 'utils/validation';
import { showError } from 'utils/showError';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { accountsEndpoints } from 'api/endpoints';
import { showLoginModal } from 'utils/showLoginModal';
import { showSuccess } from 'utils/showSuccess';
import CvForm from 'screens/Account/CvForm';
import Modal from 'components/Modal';

const maxAboutMeLength = 500;

const ApplicationForm = ({ classNames, job }) => {
  // UTILS ----------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const [processing, setProcessing] = useState(false);
  const [refresher, setRefresher] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasCv, setHasCv] = useState(true);
  const [cvFormVisible, setCvFormVisible] = useState(false);

  // UTILS ----------------------------------------------------------------UTILS
  // FIELDS --------------------------------------------------------------FIELDS

  const [aboutMe, setAboutMe] = useState('');
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [file, setFile] = useState(null);

  // FIELDS --------------------------------------------------------------FIELDS
  // GET USER CV ----------------------------------------------------GET USER CV
  const getMyCV = async signal => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: accountsEndpoints.documents.CV.show,
        signal: signal,
      });
      const data = response.data;
      if (data?.created_at) {
        setHasCv(true);
      } else {
        setHasCv(false);
      }
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    getMyCV(signal);

    return () => {
      abortController.abort();
    };
  }, [refresher]);

  // GET USER CV ----------------------------------------------------GET USER CV
  // VALIDATE FORM ------------------------------------------------VALIDATE FORM

  const validateForm = () => {
    // Check ABOUT ME
    if (!validateInput(aboutMe, maxAboutMeLength, 20, 'o mnie', 'text')) {
      return false;
    }

    // Check PRIVACY
    if (!acceptPrivacy) {
      showError('Musisz zaakceptować politykę prywatności');
      return false;
    }

    return true;
  };

  // VALIDATE FORM ------------------------------------------------VALIDATE FORM
  // SUBMIT APPLICATION --------------------------------------SUBMIT APPLICATION

  const handleSubmit = e => {
    e.preventDefault();

    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (success) {
      showError('Już wysłałeś/aś swoją aplikację');
    }

    if (!validateForm()) return;
    const data = prepareData();
    console.log(data);
    data && submitApplication(data);
  };

  const prepareData = () => {
    let data = {
      job: job,
      info: aboutMe || '',
      accept_privacy_policy: acceptPrivacy || true,
    };
    if (file) data = { ...data, file: file };

    return data;
  };

  const submitApplication = async data => {
    if (!job) return;
    setProcessing(true);
    try {
      const response = await devscntrAuth.post(
        accountsEndpoints.documents.jobApplications.create,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setSuccess(true);
      showSuccess('Dziękujemy! Twoja aplikacja została wysłana.');
      console.log(response);
    } catch (e) {
      console.log(e);
    }
    setProcessing(false);
  };

  // SUBMIT APPLICATION --------------------------------------SUBMIT APPLICATION

  return (
    <div className={cn(styles.container, classNames && classNames)}>
      <div className={cn('title-red', styles.title)}>
        Zainteresowany/a? Wyślij swoją aplikację
      </div>
      <div className={styles.content}>
        {!hasCv && (
          <div className={styles.overlay}>
            <div className={styles.overlay_title}>Aby aplikować...</div>
            <button className='button' onClick={() => setCvFormVisible(true)}>
              Stwórz swoje CV
            </button>
          </div>
        )}
        <form className={styles.form} method='post'>
          <section className={styles.form_section}>
            <TextArea
              label='Opisz siebie w kilku zdaniach'
              className={styles.textarea}
              name='about'
              type='text'
              placeholder='Opowiedz nam coś o sobie...'
              value={aboutMe}
              onChange={e => setAboutMe(e.target.value)}
              maxLength={maxAboutMeLength}
            />

            <div className={styles.file}>
              <div className={styles.label}>Prześlij swoje CV</div>
              <FileInput
                classNames={styles.file_input}
                file={file}
                setFile={setFile}
              />
              <ul className={styles.attachments}>
                {file && (
                  <li className={styles.attachment}>
                    <span className={styles.attachment_name}>{file.name}</span>
                    <span className={styles.attachment_size}>
                      {Math.ceil(file.size / 1024)} KB
                    </span>
                    <button
                      className={styles.attachment_remove}
                      onClick={() => setFile(null)}
                    >
                      <Icon name='close' size={20} />
                    </button>
                  </li>
                )}
              </ul>
            </div>

            <div className={styles.acceptance}>
              <Checkbox
                className={styles.checkbox}
                content='Wyrażam zgodę na przetwarzanie moich danych osobowych przez itCNTR w celu prowadzenia rekrutacji. <span style="color: red">*</span>'
                value={acceptPrivacy}
                onChange={() => setAcceptPrivacy(prev => !prev)}
              />
            </div>
          </section>
        </form>
        <Button
          classNames={styles.button}
          isLoading={processing}
          onClick={handleSubmit}
          disabled={!acceptPrivacy || success}
        >
          Wyślij aplikację
        </Button>
      </div>

      <Modal
        outerClassName={styles.cv_form_modal_outer}
        visible={cvFormVisible}
        onClose={() => setCvFormVisible(false)}
      >
        <CvForm
          classNames={styles.cv_form}
          onSubmit={() => setRefresher(prev => !prev)}
        />
      </Modal>
    </div>
  );
};

export default ApplicationForm;
