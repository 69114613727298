import React from 'react';
import styles from './AboutMe.module.sass';
import Card from 'components/Card';

import AboutMeDescription from './AboutMeDescription';
import AboutMeEmployments from './AboutMeEmployments';
import AboutMeEducation from './AboutMeEducation';
import AboutMeCertificates from './AboutMeCertificates';
import AboutMeSkills from './AboutMeSkills';
import AboutMeLanguages from './AboutMeLanguages';
import AboutMeInterests from './AboutMeInterests';
import AboutMeContact from './AboutMeContact';

const AboutMe = ({ data }) => {
  return (
    <Card className={styles.card}>
      <AboutMeDescription
        description={data?.description}
        phone={data?.phone}
        email={data?.email}
      />
      <AboutMeEmployments employments={data?.employments} />
      <AboutMeEducation education={data?.education} />
      <AboutMeSkills skills={data?.skills} />
      <AboutMeCertificates certificates={data?.certificates} />
      <AboutMeInterests interests={data?.interests} />
      <AboutMeLanguages languages={data?.foreign_languages} />
      <AboutMeContact phone={data?.phone} email={data?.email} />
    </Card>
  );
};

export default AboutMe;
