import React from 'react';
import styles from './ShowContent.module.sass';
import cn from 'classnames';
import EditorContentRender from 'components/Editor/EditorContentRender';
import isJson from 'utils/isJson';
import EditorTinyRender from 'components/EditorTiny/EditorTinyRender';

const ShowContent = ({
  className,
  description,
  title = 'Treść',
  subtitle,
  version,
}) => {
  if (!description) return null;

  const isEditorV1 = version === 1 && isJson(description) ? true : false;
  const isEditorV2 = version === 2 ? true : false;

  if (!isEditorV1 && !isEditorV2) return null;

  return (
    <div className={cn(styles.container, className && className)}>
      {title?.length > 0 && (
        <div className={styles.content_title}>
          <div className={styles.title}>{title}</div>
          {subtitle && subtitle !== 'null' && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
      )}
      {isEditorV1 && (
        <EditorContentRender className={styles.content} data={description} />
      )}
      {isEditorV2 && (
        <EditorTinyRender className={styles.content} content={description} />
      )}
    </div>
  );
};

export default ShowContent;
