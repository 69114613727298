import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Search.module.sass';
import Icon from '../../Icon';
import Item from './Item';
import ModalProduct from '../../../components/ModalProduct';
import { useHistory } from 'react-router-dom';
import { validateInput } from '../../../utils/validation';
import axios from 'axios';
import { miscEndpoints } from 'api/endpoints';

import useAuth from '../../../hooks/useAuth';
import devscntrAuth from '../../../api/Instance/devscntrAuth';
import devscntrNoauth from '../../../api/Instance/devscntrNoauth';
import SkeletonLoader from './Loader';
import ItemUser from './ItemUser';
import uuid from 'react-uuid';

const MAX_RESULTS = 3;

const Search = ({ className }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);

  const [users, setUsers] = useState(null);
  const [posts, setPosts] = useState(null);
  const [courses, setCourses] = useState(null);
  const [jobs, setJobs] = useState(null);

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let searchDelay;

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (isMounted) {
      if (search.length > 0) {
        searchDelay = setTimeout(() => getSearchResults(cancelToken), 500);
        setIsLoading(true);
      } else {
        setResults([]);
      }
    } else {
      setIsMounted(true);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [search]);

  const getSearchResults = async cancelToken => {
    //setIsLoading(true);
    setResults([]);

    try {
      const response = await axiosInstance.request({
        method: 'get',
        url: `${miscEndpoints.search}?query=${search}&page=1&page_size=${MAX_RESULTS}`,
        cancelToken: cancelToken.token,
      });
      setResults(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('searchbar canceled');
      } else {
        console.log(error);
      }
    }
  };

  const prepareData = () => {
    // USERS
    const newUsers = results?.users?.filter(user => !user.is_rss) || [];
    {
      setUsers(
        newUsers.length > 0 ? (
          <>
            <div className={styles.category}>Użytkownicy</div>
            {newUsers.map(item => (
              <ItemUser item={item} key={uuid()} />
            ))}
          </>
        ) : null
      );
    }

    // POSTS
    const newPosts = results?.posts || [];
    {
      setPosts(
        newPosts.length > 0 ? (
          <>
            <div className={styles.category}>Posty</div>
            {newPosts.map(item => (
              <Item
                className={styles.item}
                item={item}
                type='post'
                key={uuid()}
              />
            ))}
          </>
        ) : null
      );
    }

    // COURSES
    const newCourses = results?.courses || [];
    {
      setCourses(
        newCourses.length > 0 ? (
          <>
            <div className={styles.category}>Kursy</div>
            {newCourses.map(item => (
              <Item
                className={styles.item}
                item={item}
                type='course'
                key={uuid()}
              />
            ))}
          </>
        ) : null
      );
    }

    // JOBS
    const newJobs = results?.jobs || [];
    {
      setJobs(
        newJobs.length > 0 ? (
          <>
            <div className={styles.category}>Oferty pracy</div>
            {newJobs.map(item => (
              <Item
                className={styles.item}
                item={item}
                type='job'
                key={uuid()}
              />
            ))}
          </>
        ) : null
      );
    }
  };

  useEffect(() => {
    if (results) prepareData();
  }, [results]);

  const handleChange = e => {
    //setVisible(true);
    clearTimeout(searchDelay);
    setSearch(e.target.value);
  };

  const handleKeyUp = e => {
    if (e.key !== 'Enter') return;
    handleSubmit();
  };

  const handleSubmit = () => {
    if (!validateInput(search, 255)) return;
    setVisible(false);
    history.push({
      pathname: `/search/${search}`,
    });
  };

  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name='search' size='24' />
          </button>
          <button className={styles.direction}>
            <Icon name='arrow-left' size='24' />
          </button>
          <input
            className={styles.input}
            type='text'
            placeholder='Szukaj...'
            value={search}
            onFocus={() => setVisible(true)}
            onBlur={() => {
              setTimeout(() => setVisible(false), 100);
            }}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
          <button className={styles.result} onClick={handleSubmit}>
            Szukaj
          </button>
          <button className={styles.close} onClick={() => setVisible(false)}>
            <Icon name='close-circle' size='24' />
          </button>
        </div>
        <div className={styles.body}>
          <div className={styles.box}>
            <div
              className={styles.category}
              style={{ marginTop: '0', marginBottom: '12px' }}
            >
              {search?.length > 0 && `Wyniki wyszukiwania dla "${search}"`}
            </div>

            <div className={styles.list}>
              {users}
              {posts}
              {courses}
              {/* {jobs} */}

              {search.length === 0 ? (
                <div className={styles.no_results}>
                  Daj znać czego szukasz...
                </div>
              ) : isLoading ? (
                <SkeletonLoader cards={1} />
              ) : (
                !users &&
                !posts &&
                !courses &&
                !jobs && (
                  <div className={styles.no_results}>
                    Nic nie znaleźliśmy...
                  </div>
                )
              )}
            </div>

            {search.length > 0 && (
              <button className={styles.more_results} onClick={handleSubmit}>
                Zobacz wszystkie
              </button>
            )}
          </div>
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Search;
