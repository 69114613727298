export const codeLanguages = [
  { text: 'HTML/XML', value: 'markup' },
  { text: 'JavaScript', value: 'javascript' },
  { text: 'CSS', value: 'css' },
  { text: 'PHP', value: 'php' },
  { text: 'Ruby', value: 'ruby' },
  { text: 'Python', value: 'python' },
  { text: 'Java', value: 'java' },
  { text: 'C', value: 'c' },
  { text: 'C#', value: 'csharp' },
  { text: 'C++', value: 'cpp' },
];

export const plugins = [
  'media',
  'autolink',
  'help',
  'image',
  'link',
  'lists',
  'searchreplace',
  'wordcount',
  'code',
  'codesample',
  'textpattern',
  'autoresize',
  'emoticons',
  'insertdatetime',
  'table',
];

export const menuUser = {
  file: { title: 'File', items: 'newdocument restoredraft | print ' },
  edit: {
    title: 'Edit',
    items: 'undo redo | cut copy paste | selectall | searchreplace',
  },
  view: {
    title: 'View',
    items: 'code | visualaid visualchars visualblocks | spellchecker',
  },
  insert: {
    title: 'Insert',
    items:
      'image link template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
  },
  format: {
    title: 'Format',
    items:
      'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | removeformat',
  },
  tools: {
    title: 'Tools',
    items: 'spellchecker spellcheckerlanguage | code wordcount',
  },
  table: {
    title: 'Table',
    items: 'inserttable | cell row column | tableprops deletetable',
  },
  help: { title: 'Help', items: 'help' },
};

export const menuAdmin = {
  file: { title: 'File', items: 'newdocument restoredraft | print ' },
  edit: {
    title: 'Edit',
    items: 'undo redo | cut copy paste | selectall | searchreplace',
  },
  view: {
    title: 'View',
    items: 'code | visualaid visualchars visualblocks | spellchecker',
  },
  insert: {
    title: 'Insert',
    items:
      'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
  },
  format: {
    title: 'Format',
    items:
      'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
  },
  tools: {
    title: 'Tools',
    items: 'spellchecker spellcheckerlanguage | code wordcount',
  },
  table: {
    title: 'Table',
    items: 'inserttable | cell row column | tableprops deletetable',
  },
  help: { title: 'Help', items: 'help' },
};

export const toolbarUser = [
  'undo redo',
  'blocks',
  'bold italic',
  'alignleft aligncenter alignright alignjustify',
  'bullist numlist',
  'codesample image link table',
  'code',
];

export const toolbarAdmin = [
  'undo redo',
  'blocks',
  'bold italic forecolor backcolor',
  'alignleft aligncenter alignright alignjustify',
  'bullist numlist',
  'codesample image media link table',
  'code',
];
