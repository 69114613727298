export default {
  domain: 'A',
  title: 'Ugodowość',
  shortDescription: 'Ugodowość odzwierciedla indywidualne różnice związane z nastawieniem na współpracę i chęć dążenia do harmonii społecznej. Osoby o wysokim wyniku ugodowości cenią sobie dobrą relację z innymi ludźmi - zarówno bliskimi, znajomymi jak i ogólnie w społecznościach, w których funkcjonują.',
  description: 'Ugodowość to jedna z pięciu głównych cech osobowości, która odnosi się do naszej skłonności do bycia altruistycznymi, współpracującymi i empatycznymi wobec innych. Osoby o wysokiej ugodowości zazwyczaj są życzliwe, pomocne i zainteresowane dobrostanem innych. Z drugiej strony, osoby o niskiej ugodowości mogą być bardziej samodzielne, konkurencyjne i krytyczne. Poniżej przedstawiam pięć mocnych stron zarówno dla wysokiego, jak i niskiego wyniku w ugodowości.',
  results: [
    {
      score: 'low', // do not translate this line
      text: 'Twoja ocena w dziedzinie ugodowości jest niska, co oznacza, że ważniejsze są dla Ciebie potrzeby własne, niż innych osób. Zależnie od sytuacji oraz własnych cech osobowości, ludzie mogą postrzegać Cię raczej jako osobę twardą, krytyczną i nieustępliwą.'
    },
    {
      score: 'neutral', // do not translate this line
      text: 'Twój poziom ugodowości jest średni, co oznacza pewne zainteresowanie potrzebami innych, ale ogólnie brak skłonności do poświęcania się dla innych.'
    },
    {
      score: 'high', // do not translate this line
      text: 'Twój wysoki poziom ugodowości wskazuje na silne zainteresowanie potrzebami i dobrem innych. Zależnie od sytuacji oraz własnych cech osobowości, ludzie mogą postrzegać Cię raczej jako osobę przyjemną, współczującą i chętną do współpracy.'
    }
  ],
  facets: [
    {
      facet: 1,
      title: 'Zaufanie',
      text: 'Osoba o wysokim poziomie zaufania zakłada, że większość ludzi jest sprawiedliwa, uczciwa i ma dobre intencje. Osoby o niskim poziomie zaufania widzą innych jako egoistycznych, podstępnych i potencjalnie niebezpiecznych.'
    },
    {
      facet: 2,
      title: 'Moralność',
      text: 'Osoby o wysokich wynikach na tej skali nie widzą potrzeby udawania lub manipulacji w relacjach z innymi i dlatego są szczere, otwarte i prawdziwe. Osoby o niskich wynikach uważają, że pewna ilość dystansu, braku ujawniania informacji o sobie (czasem naginania w formie oszustwa) w relacjach społecznych jest konieczna. Ludzie, tak ogólnie, łatwiej nawiązują relacje z osobami mającymi wysoki wynik na skali moralności. Ludzie łatwo nawiązują relacje z bezpośrednimi osobami o wysokich wynikach na tej skali. Zazwyczaj mają trudności w nawiązaniu relacji z nieuczciwymi osobami o niskich wynikach. Należy jednak jasno stwierdzić, że osoby o niskich wynikach nie są pozbawione zasad moralnych; są po prostu bardziej ostrożne i mniej skłonne otwarcie ujawniać całą prawdę. Jednocześnie więcej czasu zajmuje budowanie relacji z osobami, które mają niski wynik w tej skali. Należy jednak jasno stwierdzić, że osoby o niskich wynikach nie są pozbawione zasad moralnych; są po prostu bardziej ostrożne i mniej skłonne otwarcie ujawniać całą prawdę.'
    },
    {
      facet: 3,
      title: 'Altruizm',
      text: 'Osoby altruistyczne znajdują prawdziwą satysfakcję w pomaganiu innym ludziom. Dlatego z reguły są chętne do udzielania pomocy potrzebującym. Dla altruistycznych osób pomaganie innym jest formą samorealizacji, a nie poświęcenia. Osoby o niskich wynikach na tej skali niekoniecznie lubią pomagać tym, którzy są w potrzebie. Prośby o pomoc odbierają jako narzucanie się, a nie jako szansę do samorealizacji.\n'
    },
    {
      facet: 4,
      title: 'Współpraca',
      text: 'Osoby o wysokich wynikach na tej skali nie lubią konfrontacji. Są gotowe do kompromisów i rezygnacji z własnych potrzeb, aby dobrze dogadywać się z innymi. Osoby o niskich wynikach na tej skali częściej skupiają się na celu, który chcą osiągnąć bez specjalnego wchodzenia w kontekst relacji. Mogą być odbierane jako sztywne i niechętne do porozumienia. Przy bardzo niskich wynikach mogą zdarzyć się sytuacje, w których używają metod osiągania celów ogólnie uznanych za nieprzyjemne (np.: zastraszanie innych).'
    },
    {
      facet: 5,
      title: 'Skromność',
      text: 'Osoby o wysokich wynikach na tej skali nie lubią twierdzić, że są lepsze od innych ludzi. To buduje pewne poczucie wspólnoty i harmonii z innymi. W niektórych przypadkach taka postawa (ten wynik) może wynikać z niskiej pewności siebie lub samooceny. Z drugiej strony osoby o niskich wynikach na tej skali uważają, że skromność jest niewłaściwa - nie pozwala pokazać siebie w pełni. Są skłonne opisywać się jako bardzo dobre w tym co robią, chodź jeśli brakuje im umiejętności dyplomacji mogą być postrzegane jako nieprzyjemnie lub aroganckie przez innych ludzi.'
    },
    {
      facet: 6,
      title: 'Współczucie',
      text: 'Osoby o wysokich wynikach na tej skali są wrażliwe i pełne współczucia. Cierpienie innych ludzi odczuwają mimowolnie (niezależnie od swojej woli) i łatwo się wzruszają. Osoby o niskich wynikach nie są silnie dotknięte cierpieniem innych. Cenią sobie obiektywne osądy oparte na rozumie. Są bardziej zainteresowane prawdą i bezstronną sprawiedliwością niż miłosierdziem.'
    }
  ]
};
