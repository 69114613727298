import React, { useState } from 'react';
import styles from './Row.module.sass';
import cn from 'classnames';
import devscntrAuth from 'api/Instance/devscntrAuth';

import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import Actions from 'components/Actions';
import Modal from 'components/Modal';
import ModalConfirm from 'components/ModalConfirm';
import Schedule from 'components/Schedule';

import { setShortenAmount } from 'utils';
import {
  setStatusName,
  setTypeName,
  formatLocaleDate,
  setBarWidth,
} from './utilities';
import { StatusNames, TypeNames } from './utilities/names';
import { Link, useHistory } from 'react-router-dom';
import { showError } from 'utils/showError';

export default function Row({ item, isSelected, handleSelect, setReRender }) {
  const {
    id,
    created_at,
    image,
    lead,
    likes_count,
    rating_avg,
    scheduled_at,
    status,
    title,
    type,
    views,
    max_lead,
    max_views,
  } = item;

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [visibleModalSchedule, setVisibleModalSchedule] = useState(false);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);

  const history = useHistory();

  const formatedDate = formatLocaleDate(created_at);
  const statusName = setStatusName(status);
  const typeName = setTypeName(type);
  const ratingValue = rating_avg ?? 'N/A';
  const shortenViews = setShortenAmount(views);
  const shortenLead = setShortenAmount(lead);
  const shortenLikes = setShortenAmount(likes_count?.like);
  const shortenUnlikes = setShortenAmount(likes_count?.dislike);
  const viewsBarWidth = setBarWidth(views, max_views);
  const leadsBarWidth = setBarWidth(lead, max_lead);

  const actions = [
    // {
    //   title: "Schedule product",
    //   icon: "calendar",
    //   action: () => setVisibleModalSchedule(true),
    // },
    {
      title: 'Edytuj publikację',
      icon: 'edit',
      action: () => goToEdit(),
    },
    {
      title: 'Usuń na zawsze',
      icon: 'trash',
      color: true,
      action: () => setVisibleModalDelete(true),
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                                 go to edit                                 */
  /* -------------------------------------------------------------------------- */

  const goToEdit = () => {
    const setUrl = type => {
      switch (type) {
        case TypeNames.POST:
          return `/edit-post/${id}`;
        case TypeNames.COURSE:
          return `/edit-course/${id}`;
        case TypeNames.JOB:
          return `/edit-job/${id}`;
        default:
          return undefined;
      }
    };
    const url = setUrl(typeName);

    if (!url) throw new Error('Undefined publication type');
    history.push(url);
  };

  /* -------------------------------------------------------------------------- */
  /*                            deleting publication                            */
  /* -------------------------------------------------------------------------- */

  const deleteHandler = async (id, publicationType) => {
    const setUrl = type => {
      switch (type) {
        case TypeNames.POST:
          return `/posts/article/create-post/${id}/`;
        case TypeNames.COURSE:
          return `/course/course-actions/${id}/`;
        case TypeNames.JOB:
          return `/jobs/job-create/${id}/`;
        default:
          return undefined;
      }
    };
    const url = setUrl(publicationType);

    try {
      if (!url) throw new Error('Undefined publication type');
      await devscntrAuth.delete(url);
      setReRender();
    } catch (error) {
      console.error(error);
      showError('Nie udało się usunąć publikacji');
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                          link to view publication                          */
  /* -------------------------------------------------------------------------- */

  const setViewPath = type => {
    switch (type) {
      case TypeNames.POST:
        return `/post/${id}/`;
      case TypeNames.COURSE:
        return `/course/${id}/`;
      case TypeNames.JOB:
        return `/jobs/${id}/`;
      default:
        return undefined;
    }
  };
  const viewPath = setViewPath(typeName);

  return (
    <>
      <div
        className={cn(styles.row, 'row')}
        data-item-id={id}
        data-item-type={type}
      >
        <div className={styles['mobile-wrapper']}>
          {/* <-- Checkbox --> */}
          <div className={cn(styles.col, styles.checkbox)}>
            <Checkbox value={isSelected} onChange={handleSelect} />
          </div>

          {/* <-- Product --> */}
          <Link to={viewPath} className={cn(styles.col, styles.product)}>
            <div className={styles['product-wrapper']}>
              {/* <-- Thumbnail --> */}
              <div className={styles.thumbnail}>
                {image ? (
                  <img src={image} alt='Miniaturka' />
                ) : (
                  <img
                    src={'/images/image-placeholder.png'}
                    alt='fallback img'
                  />
                )}
              </div>

              {/* <-- Detale --> */}
              <div className={styles.details}>
                <div className={styles['product-name']}>{title}</div>
                {/* <-- Status --> */}
                <div className={cn(styles.status, styles.moved)}>
                  <div
                    className={cn(styles['status-color'], {
                      [styles.published]: statusName === StatusNames.PUBLISHED,
                      [styles.scheduled]: statusName === StatusNames.SCHEDULED,
                      [styles.draft]: statusName === StatusNames.DRAFT,
                    })}
                  >
                    {statusName}
                  </div>
                </div>

                {/* <-- Data --> */}
                <div className={styles.date}>
                  <Icon name='clock' size='20' />
                  <div>{formatedDate}</div>
                </div>
              </div>
            </div>
          </Link>

          {/* <-- Akcje --> */}
          <div
            className={cn(
              styles.col,
              styles.actions,
              styles['actions--mobile']
            )}
          >
            <Actions
              classActionsHead={styles.actionsHead}
              items={actions}
              publicationType={type}
            />
          </div>
        </div>

        {/* <-- Status --> */}
        <div className={cn(styles.col, styles.status)}>
          <div className={styles.label}>Status</div>
          <div
            className={cn(styles['status-color'], {
              [styles.published]: statusName === StatusNames.PUBLISHED,
              [styles.scheduled]: statusName === StatusNames.SCHEDULED,
              [styles.draft]: statusName === StatusNames.DRAFT,
            })}
          >
            {statusName}
          </div>
        </div>

        <div className={cn(styles['mobile-wrapper'], styles['mobile-details'])}>
          {/* <-- Typ --> */}
          <div className={cn(styles.col, styles.type)}>
            <div className={styles.label}>Typ</div>
            <div>{typeName}</div>
          </div>

          {/* <-- Wyświetlenia --> */}
          <div className={styles.col}>
            <div className={styles.label}>Wyświetlenia</div>
            <div>{shortenViews}</div>
            <div className={styles.bar}>
              <div
                className={styles.progress}
                style={{ width: viewsBarWidth }}
              ></div>
            </div>
          </div>

          {/* <-- Leady --> */}
          <div className={styles.col}>
            <div className={styles.label}>Lead-y</div>
            <div>{shortenLead}</div>
            <div className={styles.bar}>
              <div
                className={cn(styles.progress, styles['progress--violet'])}
                style={{ width: leadsBarWidth }}
              ></div>
            </div>
          </div>
          {/* </div> */}

          <div
            className={cn(styles['laptop-wrapper'], styles['mobile-wrapper'])}
          >
            {/* <-- Reakcje --> */}
            <div className={cn(styles.col, styles.reactions)}>
              <div className={styles.label}>Reakcje</div>
              <div className={styles['icon-in-row']}>
                <div className={styles.thumb}>
                  <img
                    src='/images/Icons/thumbs_up/light.svg'
                    alt='Thumbs up'
                  />
                  {type === 'job' ? (
                    <div className={styles.NA}>N/A</div>
                  ) : (
                    <div>{shortenLikes}</div>
                  )}
                </div>
                <div className={styles.thumb}>
                  <img
                    src='/images/Icons/thumbs_down/light.svg'
                    alt='Thumbs down'
                  />
                  {console.log(type)}
                  {type === 'job' ? (
                    <div className={styles.NA}>N/A</div>
                  ) : (
                    <div>{shortenUnlikes}</div>
                  )}
                </div>
              </div>
            </div>

            {/* <-- Ocena --> */}
            <div className={cn(styles.col, styles.rating)}>
              <div className={styles.label}>Ocena</div>
              <div className={styles['icon-in-row']}>
                <img
                  className={cn(styles.star, {
                    [styles['star--inactive']]: !rating_avg,
                  })}
                  src='/images/Icons/star/light.svg'
                  alt='Gwiazdka'
                />
                {/* <div>{ratingValue}</div> */}
                {type === 'job' ? (
                  <div className={styles.NA}>N/A</div>
                ) : (
                  <div>{ratingValue}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <-- Akcje --> */}
        <div className={styles.ghost}>
          <div
            className={cn(
              styles.col,
              styles.actions,
              styles['actions--desktop']
            )}
          >
            <Actions classActionsHead={styles.actionsHead} items={actions} />
          </div>
        </div>
      </div>

      {/* <-- Przedziałek --> */}
      <div className={styles.divider}></div>

      {/* <-- Modal po kliknięciu akcji --> */}
      <Modal
        visible={visibleModalSchedule}
        onClose={() => setVisibleModalSchedule(false)}
      >
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal>
      <ModalConfirm
        onConfirm={() => {
          deleteHandler(id, typeName);
        }}
        visible={visibleModalDelete}
        setVisible={setVisibleModalDelete}
      />
    </>
  );
}
