import React from 'react';
import styles from '../AboutMeEmployments.module.sass';
import Icon from 'components/Icon';
import Button from 'components/Button';
import cn from 'classnames';

const AboutMeDescriptionDisplay = ({
  openSections,
  setOpenSections,
  index,
}) => {
  const displayDescription = () => {
    if (openSections.includes(index, 0)) {
      setOpenSections(current =>
        current.filter(id => {
          return id !== index;
        })
      );
    }
    if (!openSections.includes(index, 0)) {
      setOpenSections(openSections => [...openSections, index]);
    }
  };

  return (
    <Button
      classNames={
        openSections.includes(index)
          ? cn(styles.description_button, styles.description_button_open)
          : cn(styles.description_button)
      }
      onClick={displayDescription}
    >
      <Icon name='arrow-down' size={24} />
    </Button>
  );
};

export default AboutMeDescriptionDisplay;
