import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import useDarkMode from 'use-dark-mode';
import { getB5DomainChartSeries } from 'screens/BigFiveTest/b5utils';
import styles from './BigFiveDomainChart.module.sass';

const BigFiveDomainChart = ({ domain }) => {
  const isDarkMode = useDarkMode();

  const series = useMemo(
    () => (domain ? getB5DomainChartSeries(domain) : []),
    [domain]
  );

  const options = {
    theme: {
      mode: isDarkMode.value ? 'dark' : 'light',
    },
    chart: {
      background: 'none',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
      },
    },
    xaxis: {
      labels: {
        style: {
          fontFamily: 'Inter, sans-serif',
          fontWeight: 500,
          fontSize: '12px',
        },
        maxHeight: 200,
      },
      categories: domain ? domain.facets.map(facet => facet.title) : [],
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: 'Inter, sans-serif',
          fontWeight: 500,
          fontSize: '12px',
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
  };

  return (
    <Chart
      className={styles.chart}
      options={options}
      series={series}
      type='bar'
      height={300}
    />
  );
};

export default BigFiveDomainChart;
