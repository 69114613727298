import React, { useState } from 'react';
import styles from './AboutMeCertificates.module.sass';
import cn from 'classnames';
import Icon from 'components/Icon';

const AboutMeCertificates = ({ certificates }) => {
  const [openSections, setOpenSections] = useState([]);

  const months = [
    'styczenia',
    'lutego',
    'marca',
    'kwietnia',
    'maja',
    'czerwca',
    'lipca',
    'sierpnia',
    'września',
    'października',
    'listopada',
    'grudnia',
  ];

  const getMonth = month => {
    for (let i = 0; i < months.length; i++) {
      if (i === month) {
        return months[i];
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={cn('title-red', styles.header)}>Certyfikaty</div>
      {certificates?.length > 0 ? (
        <div className={styles.certificates_section}>
          {certificates?.map((item, index) => (
            <div className={styles.certificates_container} key={index}>
              <div className={styles.certificates_list}>
                <div className={styles.image}>
                  <img src={'/images/Certificates.png'} alt='Education' />
                </div>
                <div className={styles.information}>
                  <div className={styles.name}>
                    <a
                      target='_blank'
                      href={item?.credential_url}
                      rel='noreferrer'
                    >
                      {item?.name}
                      <Icon name='new-tab-link' size={18} />
                    </a>
                    <div className={styles.organization}>
                      {item?.issuing_organization}
                    </div>
                  </div>
                  <div className={styles.date}>
                    {new Date(item?.issue_date).getUTCDate()}{' '}
                    {getMonth(new Date(item?.issue_date).getUTCMonth())}{' '}
                    {new Date(item?.issue_date).getUTCFullYear()}
                  </div>
                  <div className={styles.organization}>
                    ID uwierzytelnienia: {item?.credential_id}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.no_information}>
          Brak informacji o certyfikatach.
        </div>
      )}
    </div>
  );
};

export default AboutMeCertificates;
