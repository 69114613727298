import React from 'react';
import cn from 'classnames';
import styles from './TextArea.module.sass';
// import Icon from '../Icon';
import Tooltip from '../Tooltip';

const TextArea = ({
  className,
  classLabel,
  classInput,
  label,
  icon,
  copy,
  currency,
  tooltip,
  place,
  onTitleChange,
  titleValue,
  error,
  maxLength,
  rows,
  value = '',
  ...props
}) => {
  const valueLength = value?.length || 0;
  const maxLengthOrDefault = maxLength || 100;
  const maxRowsOrDefault = rows || 4;
  return (
    <div
      className={cn(styles.field, className, error === true && styles.error)}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{' '}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon='info'
              place={place ? place : 'right'}
            />
          )}
        </div>
      )}
      <textarea
        value={value}
        className={cn(classInput, styles.input)}
        onChange={onTitleChange}
        maxLength={maxLengthOrDefault}
        rows={maxRowsOrDefault}
        {...props}
      />
      <div
        className={styles.counter}
      >{`${valueLength} / ${maxLengthOrDefault}`}</div>
    </div>
  );
};

export default TextArea;
