import React, { useMemo, useState } from 'react';
import styles from './BigFiveOverallChart.module.sass';
import Chart from 'react-apexcharts';
import useDarkMode from 'use-dark-mode';
import {
  b5DomainsArray,
  b5DomainsArrayPositioning,
  getB5ChartSeries,
} from 'screens/BigFiveTest/b5utils';
import Card from 'components/Card';

const BigFiveOverallChart = ({ result }) => {
  const isDarkMode = useDarkMode();

  const series = useMemo(
    () => (result ? getB5ChartSeries(result) : []),
    [result]
  );

  const options = {
    theme: {
      mode: isDarkMode.value ? 'dark' : 'light',
    },
    chart: {
      background: 'none',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
      },
    },
    xaxis: {
      labels: {
        style: {
          fontFamily: 'Inter, sans-serif',
          fontWeight: 500,
          fontSize: '12px',
        },
      },
      categories: b5DomainsArrayPositioning || [],
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: 'Inter, sans-serif',
          fontWeight: 500,
          fontSize: '12px',
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
  };

  return (
    <Card title={'Wykres cech osobowości'} classTitle={'title-yellow'}>
      <Chart options={options} series={series} type='bar' height={500} />
    </Card>
  );
};

export default BigFiveOverallChart;
