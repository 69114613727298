import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import styles from './Modal.module.sass';
import Icon from 'components/Icon';
const ModalRemove = ({
  outerClassName,
  visible,
  onClose,
  children,
  setFormFields,
  setVisibleRemove,
  sectionID,
  onDelete,
}) => {
  const escFunction = useCallback(
    e => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector('#modal');
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
    return () => clearAllBodyScrollLocks();
  }, [visible]);

  // // REMOVE FIELDS
  // const removeFields = sectionID => {
  //   // let data = [...formFields];
  //   // data.splice(sectionID, 1);
  //   // setFormFields(data);
  //   setFormFields(prev =>
  //     prev.filter(o => {
  //       return o.id !== sectionID;
  //     })
  //   );
  //   setVisibleRemove(prevState => !prevState);
  // };

  const removeField = () => {
    onDelete();
    setVisibleRemove(prevState => !prevState);
  };

  return createPortal(
    visible && (
      <div id='modal' className={styles.modal}>
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={onClose}>
            <div className={styles.body}>
              <h3 className={styles.unpinHeadline}>
                {`Czy na pewno chcesz usunąć ?`}
              </h3>
              <div className={styles.groupButtons}>
                <button onClick={removeField} className={styles.btn_remove}>
                  USUŃ
                </button>
                <button onClick={onClose} className={styles.btn_ce}>
                  ZAMKNIJ
                </button>
              </div>
            </div>

            {/* <button className={styles.close} onClick={onClose}>
              <Icon name="close" size="20" />
            </button> */}
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default ModalRemove;
