export default {
  domain: 'E',
  title: 'Ekstrawersja',
  shortDescription:
    'Ekstrawersja charakteryzuje się wyraźnym zaangażowaniem w świat zewnętrzny - szczególnie w kontekście potrzeby interakcji z ludźmi.',
  description:
    'Ekstrawersja to cecha osobowości, która odnosi się do stopnia, w jakim jesteśmy otwarci na innych, towarzyscy i skłonni do poszukiwania stymulacji zewnętrznej. Osoby o wysokim poziomie ekstrawersji zazwyczaj cieszą się interakcjami społecznymi, są energiczne i pozytywne. Osoby o niskim poziomie ekstrawersji, często określane jako introwertycy, mogą być bardziej zamknięte, skłonne do samotności i wewnętrznej refleksji. Poniżej przedstawiam pięć mocnych stron zarówno dla wysokiego, jak i niskiego wyniku w ekstrawersji.',
  results: [
    {
      score: 'low', // do not translate this line
      text: 'Twój wynik w zakresie Ekstrawersji jest niski, co wskazuje, że jesteś introwertykiem, osobą skrytą i cichą. Cenisz samotność i samodzielne zajęcia. Twoje kontakty społeczne skupiają się zazwyczaj na kilku bliskich osobach.'
    },
    {
      score: 'neutral', // do not translate this line
      text: 'Twój wynik w zakresie Ekstrawersji jest średni, co wskazuje, że nie jesteś ani osobą preferują odosobnienie, ani wesołym gadułą. Lubisz spędzać czas z innymi, ale cenisz też samotność.'
    },
    {
      score: 'high', // do not translate this line
      text: 'Twój wynik w zakresie Ekstrawersji jest wysoki, co wskazuje, że jesteś osobą towarzyską, otwartą, energiczną i pełną życia. Preferujesz spędzanie czasu w towarzystwie innych osób.'
    }
  ],
  facets: [
    {
      facet: 1,
      title: 'Przyjazność',
      text: 'Przyjazne osoby naprawdę lubią innych ludzi i otwarcie wyrażają pozytywne uczucia wobec nich. Szybko nawiązują przyjaźnie, jest im łatwo tworzyć bliskie, intymne relacje. Osoby o niskich wynikach w kategorii Przyjazność niekoniecznie są zimne i wrogo nastawione, ale nie starają się specjalnie nawiązywać kontaktów z innymi i są postrzegane jako zdystansowane i powściągliwe.'
    },
    {
      facet: 2,
      title: 'Towarzyskość',
      text: 'Towarzyskie osoby znajdują przyjemność w towarzystwie innych i uważają je za stymulujące i satysfakcjonujące. Cieszą się z atmosfery tłumu. Osoby o niskich wynikach zwykle czują się przytłoczone i aktywnie unikają dużych skupisk ludzi. Niekoniecznie nie lubią czasami przebywać z innymi, ale ich potrzeba prywatności i czasu dla siebie jest znacznie większa niż u osób, które uzyskały wysokie wyniki w tej skali.'
    },
    {
      facet: 3,
      title: 'Asertywność',
      text: 'Osoby o wysokim wyniku w kategorii Asertywność lubią zabierać głos, przejmować kontrolę i kierować działaniami innych. Zwykle pełnią rolę liderów w grupach. Osoby o niskim wyniku zwykle nie rozmawiają zbyt wiele i pozwalają innym kontrolować działania grupy podążając za tym, co pojawi się w grupie.'
    },
    {
      facet: 4,
      title: 'Poziom aktywności',
      text: 'Aktywne osoby prowadzą szybkie, intensywne życie. Poruszają się szybko, energicznie i z zapałem, angażując się w wiele działań. Osoby o niskim wyniku w tej skali preferują wolniejsze, bardziej spokojne tempo życia.'
    },
    {
      facet: 5,
      title: 'Poszukiwanie ekscytacji',
      text: 'Osoby o wysokim wyniku w tej skali łatwo się nudzą bez wysokiego poziomu stymulacji. Uwielbiają jasne światła i zgiełk - np.: koncerty. Skłonne są do podejmowania ryzyka i poszukiwania dreszczyku emocji. Osoby o niskim wyniku czują się przytłoczone hałasem i zamieszaniem, nie są zainteresowane poszukiwaniem wrażeń.'
    },
    {
      facet: 6,
      title: 'Radość',
      text: 'Ta skala mierzy pozytywne nastroje i uczucia. Osoby o wysokim wyniku na tej skali zazwyczaj doświadczają różnorodnych pozytywnych uczuć, takich jak szczęście, entuzjazm, optymizm i radość. Osoby o niskim wyniku nie wykazują takiej skłonności do energicznych, wesołych nastrojów, ale nie ma to związku z doświadczaniem (lub nie) emocji negatywnych.'
    }
  ]
};
