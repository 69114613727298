import React, { useState } from 'react';

const FormContext = React.createContext();

export const FormContextProvider = props => {
  const [basicData, setBasicData] = useState();
  const [trendsData, setTrendsData] = useState();
  const [dynamicSectionsData, setDynamicSectionsData] = useState([]);
  const [parentData, setParentData] = useState();
  const [technologiesData, setTechnologiesData] = useState();
  const [deletedTrends,setDeletedTrends] = useState();
  const [deletedSections,setDeletedSections] = useState();

  return (
    <FormContext.Provider
      value={{
        basicData: basicData,
        setBasicData: setBasicData,
        trendsData: trendsData,
        setTrendsData: setTrendsData,
        deletedTrends: deletedTrends,
        setDeletedTrends: setDeletedTrends,
        dynamicSectionsData: dynamicSectionsData,
        setDynamicSectionsData: setDynamicSectionsData,
        deletedSections: deletedSections,
        setDeletedSections: setDeletedSections,
        parentData: parentData,
        setParentData: setParentData,
        technologiesData: technologiesData,
        setTechnologiesData: setTechnologiesData,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export default FormContext;
