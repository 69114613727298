import React from 'react';
import styles from './Tag.module.sass';
import cn from 'classnames';
import { colorRelatedToBackground } from 'utils';
import Icon from 'components/Icon';

const Tag = ({
  classNames,
  name,
  selected = false,
  visible = true,
  color = '#ffffff',
  onClick = () => { },
}) => {
  const bgColor = color !== 'undefined' ? color : '#ffffff';

  return (
    visible && (
      <button
        className={styles.item_tag}
        onClick={onClick}
      >
        {selected && <Icon name='check' size={14} />}
        #{name}
      </button>
    )
  );
};

export default Tag;
