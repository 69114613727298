import React, { useEffect, useState } from 'react';
import styles from './MyApplications.module.sass';
import cn from 'classnames';
import { accountsEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { Link } from 'react-router-dom';
import Application from '../Application';
import uuid from 'react-uuid';
import Loader from '../Application/Loader';

const MyApplications = ({ classNames }) => {
  // UTILS ----------------------------------------------------------------UTILS

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const sortByRecent = (a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  };

  // UTILS ----------------------------------------------------------------UTILS
  // GET JOB APPLICATIONS ----------------------------------GET JOB APPLICATIONS

  const getJobApplications = async signal => {
    setIsLoading(true);
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: accountsEndpoints.documents.jobApplications.show,
        signal: signal,
        headers: {
          method: 'employee',
        },
      });
      const data = response.data.sort(sortByRecent);
      setList(data);
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    getJobApplications(signal);

    return () => {
      abortController.abort();
    };
  }, []);

  // GET JOB APPLICATIONS ----------------------------------GET JOB APPLICATIONS

  return (
    <div className={cn(styles.container, classNames && classNames)}>
      <h3 className={cn('title-yellow', styles.title)}>Moje aplikacje</h3>

      <div className={styles.content}>
        <div className={styles.list}>
          {isLoading && !list.length > 0 && <Loader cards={3} />}
          {list.length > 0
            ? list.map((item, index) => (
                <Application item={item} key={uuid()} />
              ))
            : !isLoading && (
                <div className={styles.empty}>
                  <div className={styles.info}>
                    Nie wysłałeś/aś jeszcze żadnej aplikacji
                  </div>
                  <Link
                    to='/jobs'
                    className={cn('button-stroke', styles.button)}
                  >
                    Zobacz oferty
                  </Link>
                </div>
              )}
        </div>
      </div>
    </div>
  );
};

export default MyApplications;
