import React, { useState, useEffect, useRef } from 'react';
import styles from './Tags.module.sass';
import { Redirect, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Item from './Item';
import cn from 'classnames';
import Card from 'components/Card';
import Form from 'components/Form';
import axios from 'axios';
import devscntrAuth from 'api/Instance/devscntrAuth';
import Checkbox from 'components/Checkbox';
import useBreakpoints from 'hooks/useBreakpoints';
import { adminEndpoints } from 'api/endpoints';
import { adminPageSize } from 'data/dataArrays';
import Filters from 'components/Filters';
import SkeletonLoader from './Item/Loader';
import Filtering from './Filtering';
import Dropdown from 'components/Dropdown';
import ModalConfirm from 'components/ModalConfirm';
import { showError } from 'utils/showError';

const DUMMY_DATA = [
  {
    id: 1,
    name: 'test',
    tags_count: 49,
    author: 58,
    author_displayname: 'Tester21',
    created_at: '2022-08-01T08:10:40.475114Z',
  },
];

const labels = [
  {
    name: 'Nazwa',
    style: 'name',
  },
  {
    name: 'Kolor',
    style: 'color',
  },
  {
    name: 'Ilość użyć',
    style: 'usage',
  },
  {
    name: 'Twórca',
    style: 'creator',
  },
];

const pageSize = adminPageSize;

const Tags = () => {
  const authCtx = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedAction, setSelectedAction] = useState('Wybierz akcję');
  const [refresh, setRefresh] = useState(false);

  const urlSearch = useLocation().search;

  const [currentPage, setCurrentPage] = useState(
    new URLSearchParams(urlSearch).get('page') || 1
  );
  const [lastPage, setLastPage] = useState(true);

  const [stylesRef, setStylesRef] = useState(null);
  const breakpoints = [
    ['r-max', Infinity],
    ['r-lg', 1140],
    ['r-md', 768],
    ['r-sm', 568],
    ['r-sm-s', 450],
  ];
  useBreakpoints(stylesRef, breakpoints);

  //get tags from API
  const getTags = async cancelToken => {
    setSelectedTags([]);
    setIsLoading(true);
    const params = `?page=${currentPage}&page_size=${pageSize}${
      search.length > 0 ? `&query=${search.toLowerCase()}` : ''
    }`;
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: `${adminEndpoints.tags.show}${params}`,
        cancelToken: cancelToken.token,
      });
      console.log({ tags: response.data.results });
      setTags(response.data.results);
      setLastPage(response.data.next === null);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('admin tags canceled');
      } else {
        if (error?.response?.status === 404) {
          setCurrentPage(1);
        } else {
          console.log(error);
        }
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getTags(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [search, urlSearch, refresh]);

  const deleteTag = () => {
    try {
      selectedTags.forEach(async id => {
        try {
          const response = await devscntrAuth.delete(
            `${adminEndpoints.tags.show}${id}/`
          );
          console.log(response);
          setRefresh(prev => !prev);
          setSelectedTags([]);
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ACTIONS FOR SELECTED FIELDS
  const selectedActions = [
    {
      name: 'Wybierz akcję',
      value: 'selectActon',
      action: () => {},
    },
    {
      name: 'Usuń',
      value: 'delete',
      action: deleteTag,
    },
  ];

  const executeSelected = () => {
    let index;
    selectedActions.forEach((item, i) => {
      if (item.name === selectedAction) {
        index = i;
        return;
      }
    });

    if (!selectedTags.length > 0) {
      showError('Brak zaznaczonych');
      return;
    }

    if (index !== 0) {
      let item = selectedActions[index];
      item.action();
    }
  };

  // select/unselect all checkboxes
  const handleSelectAll = () => {
    if (selectedTags?.length === 0) {
      setSelectedTags(tags?.map(item => item.id));
    } else {
      setSelectedTags([]);
    }
  };

  const handleSubmit = () => {};

  // akcje dla zaznaczonych userow
  return (
    <div ref={x => setStylesRef(x)}>
      {authCtx.isAdmin ? (
        <Card
          className={styles.card}
          classCardHead={styles.head}
          title='Zarządzaj tagami'
          classTitle={cn('title-purple', styles.title)}
          head={
            <>
              <Form
                className={styles.form}
                value={search}
                setValue={setSearch}
                onSubmit={() => handleSubmit()}
                placeholder='Szukaj...'
                type='text'
                name='search'
                icon='search'
              />
            </>
          }
        >
          <Form
            className={styles.form_mobile}
            value={search}
            setValue={setSearch}
            onSubmit={() => handleSubmit()}
            placeholder='Szukaj...'
            type='text'
            name='search'
            icon='search'
          />
          <ModalConfirm
            visible={confirmVisible}
            setVisible={setConfirmVisible}
            onConfirm={executeSelected}
          />
          <div className={styles.container}>
            <div className={styles.actions_container}>
              <Dropdown
                className={styles.select_action}
                options={selectedActions.map(item => item.name)}
                value={selectedAction}
                setValue={setSelectedAction}
              />
              <button
                className='button-stroke'
                onClick={() => setConfirmVisible(true)}
              >
                Wykonaj dla zaznaczonych
              </button>
            </div>

            <div className={styles.header}>
              <div className={styles.labels}>
                <div className={cn(styles.checkbox)}>
                  <Checkbox
                    className={styles.checkbox}
                    onChange={handleSelectAll}
                    value={selectedTags?.length > 0}
                  />
                </div>
                {labels.map((label, index) => (
                  <div
                    key={`label_${index}`}
                    className={cn(styles.label, styles[label.style])}
                  >
                    {label.name}
                  </div>
                ))}
              </div>
              <Filtering currentPage={currentPage} />
            </div>

            {isLoading ? (
              <SkeletonLoader cards={pageSize} />
            ) : tags?.length > 0 ? (
              <div className={styles.tags_list}>
                {tags.map((item, index) => (
                  <Item
                    selected={selectedTags.includes(item.id)}
                    setSelected={setSelectedTags}
                    tag={item}
                    refresh={() => setRefresh(prev => !prev)}
                    key={`tag_${index}`}
                  />
                ))}
              </div>
            ) : (
              <p className={styles.info}>Nie znaleziono tagów</p>
            )}

            <div className={styles.pagination}>
              {currentPage !== 1 && (
                <button
                  className={cn(
                    'button-stroke button-small',
                    isLoading && 'disabled',
                    styles.button
                  )}
                  style={{ marginLeft: '12px' }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setCurrentPage(prevState => --prevState);
                  }}
                  disabled={isLoading}
                >
                  Poprzednia
                </button>
              )}
              {!lastPage && (
                <button
                  className={cn(
                    'button-stroke button-small',
                    isLoading && 'disabled',
                    styles.button
                  )}
                  style={{ marginLeft: '12px' }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setCurrentPage(prevState => ++prevState);
                  }}
                  disabled={isLoading}
                >
                  Następna
                </button>
              )}
            </div>
          </div>
        </Card>
      ) : (
        <Redirect to='/' />
      )}
    </div>
  );
};
export default Tags;
