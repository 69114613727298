import React from 'react';
import cn from 'classnames';
import styles from './Meta.module.sass'
import Card from '../Card';
import TextInput from '../TextInput';

const Meta = ({
  className,
  title,
  onTitleChange,
  description,
  onDescriptionChange,
}) => {
  return (
    <Card
      className={cn(styles.card, className)}
      title='Meta content'
      classTitle='title-red'
    >
      <div className={styles.description}>
        <TextInput
          className={styles.field}
          label='Tytuł'
          name='title'
          type='text'
          tooltip='Maksymalnie 60 znaków, bez tagów html. '
          required
          value={title}
          onChange={onTitleChange}
        />
        <TextInput
          className={styles.field}
          metaDescription={155}
          label='Opis'
          name='description'
          type='text'
          tooltip='Maksymalnie 155 znaków, bez tagów html. '
          descriptionValue={description}
          onDescriptionChange={onDescriptionChange}
          required
        />
      </div>
    </Card>
  );
};

export default Meta;