import React, { useEffect, useState } from 'react';
import styles from './Badges.module.sass';
import cn from 'classnames';
import devscntrAuth from 'api/Instance/devscntrAuth';
import axios from 'axios';
import { adminEndpoints } from 'api/endpoints';
import Card from 'components/Card';
import Icon from 'components/Icon';
import { showError } from 'utils/showError';
import Modal from 'components/Modal';
import AddBadge from './AddBadge';
import uuid from 'react-uuid';
import useAuth from 'hooks/useAuth';
import { validateImage, validateInput } from 'utils/validation';

const Badges = () => {
  // UTILS-------------------------------------------------------------------------UTILS
  const authCtx = useAuth();

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);

  const [badges, setBadges] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [currentEditId, setCurrentEditId] = useState(null);
  const [editName, setEditName] = useState('');
  const [editImage, setEditImage] = useState(null);
  const [editLevel, setEditLevel] = useState('');

  // UTILS-------------------------------------------------------------------------UTILS
  // GET BADGES---------------------------------------------------------------GET BADGES

  const getBadges = async cancelToken => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: adminEndpoints.settings.badges,
        cancelToken: cancelToken.token,
      });
      setBadges(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('admin badges canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getBadges(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [refresh]);

  // GET BADGES---------------------------------------------------------------GET BADGES
  // ADD BADGE-----------------------------------------------------------------ADD BADGE

  const onAddBadge = () => {
    setAddModalVisible(false);
    setRefresh(prev => !prev);
    authCtx.getLevel();
  };

  // ADD BADGE-----------------------------------------------------------------ADD BADGE
  // VALIDATION---------------------------------------------------------------VALIDATION

  const validation = () => {
    if (!validateInput(editName, 40, 1, 'nazwa', 'letters')) return false;
    if (!validateInput(editLevel, 20, 1, 'od poziomu', 'numbers')) return false;
    if (editImage) {
      if (!validateImage(editImage, 5)) return false;
    }

    return true;
  };

  // VALIDATION---------------------------------------------------------------VALIDATION
  // EDIT BADGE---------------------------------------------------------------EDIT BADGE

  const editBadge = item => {
    setCurrentEditId(item.id);
    setEditName(item.name);
    setEditLevel(item.from_level);
  };

  const saveEditBadge = async () => {
    if (!validation()) return;
    let data = {
      name: editName,
      from_level: editLevel,
    };
    if (editImage) data = { ...data, image: editImage };
    try {
      const response = await devscntrAuth.request({
        method: 'put',
        url: `${adminEndpoints.settings.badges}${currentEditId}/`,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setCurrentEditId(null);
      setRefresh(prev => !prev);
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
      showError('Nie udało się zapisać zmian');
    }
  };

  // EDIT BADGE---------------------------------------------------------------EDIT BADGE
  // DELETE BADGE-----------------------------------------------------------DELETE BADGE

  const deleteBadge = async id => {
    if (confirmDeleteId !== id) {
      setConfirmDeleteId(id);
      setTimeout(() => {
        setConfirmDeleteId(null);
      }, 10000);
    } else {
      try {
        const response = await devscntrAuth.delete(
          `${adminEndpoints.settings.badges}${id}/`
        );
        console.log(id);
        setRefresh(prev => !prev);
        authCtx.getLevel();
      } catch (error) {
        console.log(error);
        showError('Nie udało się zapisać zmian');
      }
    }
  };

  // DELETE BADGE-----------------------------------------------------------DELETE BADGE

  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title='Zarządzanie orderami'
      classTitle={cn('title-yellow', styles.title)}
      head={
        <button className='button' onClick={() => setAddModalVisible(true)}>
          <Icon name='add' size={20} />
          Dodaj order
        </button>
      }
    >
      <Modal
        visible={addModalVisible}
        onClose={() => setAddModalVisible(false)}
      >
        <AddBadge onAddBadge={onAddBadge} />
      </Modal>
      <div className={styles.container}>
        <table>
          <thead>
            <tr>
              <th>Nazwa</th>
              <th>Obrazek</th>
              <th>Od poziomu</th>
              <th>Edycja</th>
            </tr>
          </thead>
          <tbody>
            {badges.map(item =>
              currentEditId !== item.id ? (
                <tr key={`row_${item.id}`}>
                  <td width='25%'>{item.name}</td>
                  <td width='25%'>
                    <img src={item.image || ''} alt={item.name} />
                  </td>
                  <td width='25%'>{item.from_level}</td>
                  <td className={styles.buttons} width='25%'>
                    <button
                      className={styles.edit_button}
                      onClick={() => editBadge(item)}
                    >
                      <Icon name='edit' size={20} />
                    </button>
                    <button
                      className={cn(
                        styles.delete_button,
                        confirmDeleteId == item.id && styles.confirm_delete
                      )}
                      onClick={() => deleteBadge(item.id)}
                    >
                      <Icon name='trash' size={20} />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={`row_${item.id}`}>
                  <td width='25%'>
                    <input
                      type='text'
                      value={editName}
                      className={styles.input}
                      onChange={e => setEditName(e.target.value)}
                    />
                  </td>
                  <td width='25%'>
                    <input
                      type='file'
                      onChange={e => setEditImage(e.target.files[0])}
                    />
                  </td>
                  <td width='25%'>
                    <input
                      type='text'
                      value={editLevel}
                      className={styles.input}
                      onChange={e => setEditLevel(e.target.value)}
                    />
                  </td>
                  <td className={styles.buttons} width='25%'>
                    <button
                      className={styles.edit_button}
                      onClick={saveEditBadge}
                    >
                      <Icon name='check' size={20} />
                    </button>
                    <button
                      className={styles.cancel_button}
                      onClick={() => setCurrentEditId(null)}
                    >
                      <Icon name='close' size={20} />
                    </button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default Badges;
