import React, { useState } from 'react';
import styles from './ResetRequest.module.sass';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Image from 'components/Image';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { use100vh } from 'react-div-100vh';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import { accountsEndpoints } from 'api/endpoints';
import { validateInput } from 'utils/validation';
import { validationRules } from 'data/validationRules';

const ResetRequest = () => {
  // UTILS---------------------------------------------------------------------------------UTILS

  const heightWindow = use100vh();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [email, setEmail] = useState('');

  // UTILS---------------------------------------------------------------------------------UTILS
  // VALIDATION-----------------------------------------------------------------------VALIDATION

  const validation = () => {
    if (
      !validateInput(
        email,
        validationRules.email.maxChars,
        validationRules.email.minChars,
        validationRules.email.name,
        validationRules.email.security
      )
    ) {
      return false;
    }

    return true;
  };

  // VALIDATION-----------------------------------------------------------------------VALIDATION
  // SEND EMAIL REQUEST-------------------------------------------------------SEND EMAIL REQUEST

  const sendEmail = async () => {
    setIsLoading(true);
    try {
      const response = await devscntrNoauth.post(
        accountsEndpoints.auth.sendPasswordReset,
        {
          email: email,
        }
      );
      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  // SEND EMAIL REQUEST-------------------------------------------------------SEND EMAIL REQUEST
  // EVENT HANDLERS---------------------------------------------------------------EVENT HANDLERS

  const handleKeyDown = e => {
    if (e.key !== 'Enter') return;
    handleSubmit();
  };

  const handleSubmit = () => {
    if (validation()) {
      sendEmail();
    }
  };

  // EVENT HANDLERS---------------------------------------------------------------EVENT HANDLERS

  return (
    <div className={styles.container} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to='/'>
          <Image
            className={styles.pic}
            src='/images/logo-light.svg'
            srcDark='/images/logo-dark.svg'
            alt='itCNTR'
          />
        </Link>
        {success ? (
          <>
            <div className={cn('h2', styles.title)}>Sprawdź skrzynkę email</div>
            <div className={styles.body}>
              <div className={styles.note}>
                Wysłaliśmy link do zmiany hasła na podany adres email. Sprawdź
                swoją skrzynkę.
              </div>
              {/* <div className={styles.subtitle}>
                Możesz bezpieczenie zamknąć kartę.
              </div> */}
              <div className={styles.links}>
                <Link
                  className={cn('button-stroke', styles.button)}
                  to='/sign-in'
                >
                  Przejdź do logowania
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={cn('h2', styles.title)}>Reset hasła</div>
            <div className={styles.body}>
              <div className={styles.note}>
                Wyślemy wiadomość z linkiem do resetu hasła na podany adres
                email.
              </div>
              {/* <div className={styles.subtitle}>Lub kontynuuj z adresem email</div> */}

              <TextInput
                className={styles.field}
                name='email'
                type='email'
                placeholder='Twój email'
                required
                icon='mail'
                onChange={e => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                value={email}
              />
              <Button
                classNames={cn('button', styles.button)}
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                Wyślij
              </Button>

              <div className={styles.links}>
                <Link className={styles.info_link} to='/sign-in'>
                  Przejdź do logowania
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetRequest;
