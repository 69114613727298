import React from 'react';
import styles from './BigFiveCourses.module.sass';
import Card from 'components/Card';
import MiniCourse from 'components/MiniCourse';
import uuid from 'react-uuid';
import HorizontalScroll from 'components/HorizontalScroll';
import Overlay from 'components/Overlay';

const BigFiveCourses = ({ courses, isLoading }) => {
  return (
    <Overlay isLoading={isLoading}>
      <Card
        title={'Polecane kursy'}
        classTitle={'title-blue'}
        className={styles.card}
      >
        {courses?.length === 0 && !isLoading && (
          <p className={styles.info}>Brak polecanych kursów</p>
        )}
        {courses.length > 0 && !isLoading && (
          <HorizontalScroll className={styles.list} controls wide>
            {courses.map(item => (
              <MiniCourse
                classNames={styles.item}
                related
                article={item}
                key={uuid()}
              />
            ))}
          </HorizontalScroll>
        )}
      </Card>
    </Overlay>
  );
};

export default BigFiveCourses;
