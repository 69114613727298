import React, { useState, useEffect, useRef } from 'react';
import styles from './CodesDetails.module.sass';
import cn from 'classnames';
import Icon from 'components/Icon';
import uuid from 'react-uuid';

export default function CodesDetails({ item, isVisible, closeHandle }) {
  //pagination
  var coursesList = [];
  {
    item.courses.map(x => coursesList.push(x));
  }
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const indexOfLast = currentPage * pageSize;
  const indexOfFirst = indexOfLast - pageSize;
  const currentList = coursesList.slice(indexOfFirst, indexOfLast);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(coursesList.length / pageSize); i++) {
    pageNumbers.push(i);
  }
  //pagination

  const pageHandler = event => {
    setCurrentPage(event.target.id);
  };

  const closingHandle = () => {
    closeHandle();
    setCurrentPage(1);
  };

  return (
    <>
      <div className={cn(styles.filters, { [styles.active]: isVisible })}>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={cn(styles.main_title)}>
              <div className={cn('title-blue', styles.title_position)}></div>
              <div className={styles.code_title}>{item.code}</div>
            </div>
            <button onClick={() => closingHandle()} className={styles.close}>
              <Icon name='close' size='20' />
            </button>
          </div>
          <div className={styles.details}>
            <div className={cn(styles.title)}>Szczegóły: </div>
            <div className={styles.list}>
              <div className={styles.row}>
                <Icon
                  name='diamond'
                  size='16'
                  className={styles.image_style}
                  fill='gray'
                />
                <div className={styles.item}>
                  <b className={styles.text_colour}>Zniżka (zł): </b>

                  {item.number_value ? <>{item.number_value}zł</> : <>Brak</>}
                </div>
              </div>
              <div className={styles.row}>
                <Icon
                  name='promotion'
                  size='16'
                  fill='gray'
                  className={styles.image_style}
                />
                <div className={styles.item}>
                  <b className={styles.text_colour}>Zniżka (%): </b>
                  {item.percentage_value ? (
                    <>{item.percentage_value}%</>
                  ) : (
                    <>Brak</>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <Icon
                  name='basket'
                  size='16'
                  fill='gray'
                  className={styles.image_style}
                />
                <div className={styles.item}>
                  <b className={styles.text_colour}>Liczba użyć: </b>
                  {item.use_limited ? (
                    <>{item.number_of_uses_max}</>
                  ) : (
                    <>Nieograniczona</>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <Icon
                  name='calendar'
                  size='16'
                  fill='gray'
                  className={styles.image_style}
                />
                <div className={styles.item}>
                  <b className={styles.text_colour}>Aktywny do: </b>
                  {item.time_limited ? (
                    <>
                      {new Date(item.expiration_date).getDate()}/
                      {new Date(item.expiration_date).getMonth() + 1}/
                      {new Date(item.expiration_date).getFullYear()}
                    </>
                  ) : (
                    <>Nie wygasa</>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={cn(styles.title)}>Kursy z tym kodem: </div>
          {coursesList.length > 0 ? (
            <>
              <div className={styles.coursesList}>
                {currentList.map((x, index) => (
                  <a key={uuid()} className={styles.coursesListItem}>
                    <div key={index}>{x.title}</div>
                  </a>
                ))}
              </div>
              <div className={styles.paginationPosition}>
                {pageNumbers.length > 1 &&
                  pageNumbers.map(number => (
                    <button
                      className={styles.pagination}
                      key={number}
                      id={number}
                      onClick={pageHandler}
                    >
                      {number}
                    </button>
                  ))}
              </div>
            </>
          ) : (
            <div className={styles.emptyCourses}>Brak przypisanych kursów.</div>
          )}
        </div>
        <div className={styles.overlay} onClick={() => closeHandle()}></div>
      </div>
    </>
  );
}
