import React from "react";
import styles from "./Loader.module.sass";
import cn from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import uuid from "react-uuid";

const Loader = ({ className, cards }) => {
  return (
    <SkeletonTheme>
      <div className={cn(styles.container, className && className)}>
        {[...Array(cards)].map(() => (
          <Skeleton width={Math.floor(Math.random() * (160 - 50) + 50)} height={30} borderRadius={5} key={uuid()} />
        ))}
      </div>
    </SkeletonTheme>
  );
};

export default Loader;
