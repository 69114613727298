import styles from './Views.module.sass';
import Icon from 'components/Icon';
import React, { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { showLoginModal } from 'utils/showLoginModal';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { showError } from 'utils/showError';
import { coursesEndpoints, postsEndpoints } from 'api/endpoints';

const ratingApi = {
  post: postsEndpoints.rating,
  course: coursesEndpoints.rating,
};

const Views = ({ viewsCount }) => {
  return (
    <div className={styles.views}>
      <Icon name='eye' size='24' viewBox='0 0 24 24' />
      {viewsCount ? viewsCount : '-'}
    </div>
  );
};
export default Views;
