import React from 'react';
import style from './BigFiveDomain.module.sass';
import Chart from '../BigFiveDomainChart';

const BigFiveDomain = ({ data }) => {
  if (!data) return null;
  return (
    <div className={style.container}>
      <div className={style.text}>
        <h3 className={style.title}>{data.title}</h3>
        <p className={style.description}>{data.description}</p>
      </div>
      <Chart domain={data} />
    </div>
  );
};

export default BigFiveDomain;
