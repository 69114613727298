import useAuth from '../../hooks/useAuth';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Logout = () => {
  const authCtx = useAuth();
  const history = useHistory();
  const location = useLocation();

  const logoutHandler = () => {
    authCtx.logout();

    const from = location?.state?.from;
    if (from) history.push(from);
    else history.push('/sign-in');
  };

  useEffect(() => {
    logoutHandler();
  }, []);

  return null;
};

export default Logout;
