import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './Comment.module.sass';
import Icon from 'components/Icon';
import Control from './Control';
import { Link } from 'react-router-dom';

import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from '../../api/Instance/devscntrNoauth';
import ReplyComment from './ReplyComment';
import Actions from 'components/Actions';
import useAuth from 'hooks/useAuth';
import { showLoginModal } from 'utils/showLoginModal';
import More from './More';
import { validateInput } from 'utils/validation';
import ProfileAvatar from '../ProfileAvatar';
import { jzwitv2Endpoints } from 'api/endpoints';

const types = {
  post: '/posts/article/comment/',
  course: '/course/course-comment/',
  jzwit: `${jzwitv2Endpoints.addComment}`,
};

const Comment = ({ className, item, type, onComment }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [currentValue, setCurrentValue] = useState('');
  const [currentValueAnswer, setCurrentValueAnswer] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [visibleAnswers, setVisibleAnswers] = useState('');
  const [repliesCount, setRepliesCount] = useState(0);

  const [userLevel, setUserLevel] = useState('Level 0');

  useEffect(() => {
    fetchUserLevel();
  }, []);
  useEffect(() => {
    setRepliesCount(0);
    countReplies(item);
  }, [item]);

  const replyUrl = types[type] ?? '';

  const sendReply = () => {
    if (replyUrl === '') return;
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    if (validateInput(currentValue?.trim(), 255, 2, 'odpowiedź')) replyApi();
  };

  const replyApi = async () => {
    let replyComment = null
    if (type === 'post' || type === 'course') {
      replyComment = {
        text: currentValue.trim(),
        commentID: item.id,
      }
    } else if (type === 'jzwit') {
      replyComment = {
        text: currentValue.trim(),
        comment: item.id,
      }
    }

    try {
      const response = await devscntrAuth.post(replyUrl, replyComment);
      console.log(response);
      onComment();
      setCurrentValue('');
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserLevel = () => {
    axiosInstance
      .request({
        method: 'get',
        url: '/levels/xpUser',
        headers: {
          'User-id': item.author,
        },
      })
      .then(response => {
        setUserLevel(response.data.current_level);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const countReplies = comment => {
    if (comment.replies.length > 0) {
      comment.replies.forEach(item => {
        setRepliesCount(prevState => ++prevState);
        countReplies(item);
      });
    }
  };

  return (
    <>
      <div className={cn(styles.item, className)}>
        <Link to={`/profile/${item?.author}`}>
          <ProfileAvatar image={item.user_image} level={userLevel} small />
        </Link>
        <div className={styles.details}>
          <div className={styles.line}>
            <Link to={`/profile/${item?.author}`}>
              <div className={styles.author}>
                {item?.user_name || 'Brak nazwy'}
              </div>
            </Link>
            <div className={styles.rating_and_time}>
              <div className={styles.time}>
                {item?.created_at?.slice(0, 10)}
              </div>
              {item?.course_rating && (
                <div className={styles.rating}>
                  {item?.course_rating}
                  <Icon name='star-fill' size='24' />
                </div>
              )}
              <div className={styles.actions}>
                <More
                  id={item.id}
                  author={item.author}
                  type={type}
                  refresh={onComment}
                />
              </div>
            </div>
          </div>
          <div className={cn(styles.comment, showMore && styles.comment_full)}>
            {item.text}
          </div>
          {item?.text?.length > 100 && (
            <div
              className={styles.show_more}
              onClick={() => setShowMore(prev => !prev)}
            >
              {showMore ? 'Pokaż mniej' : `Pokaż więcej`}
            </div>
          )}
          <Control
            valueAnswer={currentValue}
            setValueAnswer={setCurrentValue}
            handleReply={sendReply}
            comment={item}
            type={type}
            refresh={onComment}
          />
        </div>
      </div>
      {item.replies.length > 0 && (
        <>
          <div
            className={cn(styles.answerlist, { [styles.show]: visibleAnswers })}
          >
            {item.replies.map((x, index) => (
              <ReplyComment
                className={styles.answer}
                key={index}
                item={x}
                onComment={onComment}
                authorName={item.user_name || 'Brak nazwy'}
                replyUrl={replyUrl}
                type={type}
                answer
              />
            ))}
          </div>
          <button
            className={cn(styles.showanswers)}
            onClick={() => setVisibleAnswers(prev => !prev)}
          >
            {visibleAnswers
              ? 'Ukryj odpowiedzi'
              : `Pokaż odpowiedzi (${repliesCount})`}
          </button>
        </>
      )}
    </>
  );
};

export default Comment;
