import React, { useState } from 'react';
import styles from './ModalAuthorSettings.module.sass';
import cn from 'classnames';
import Modal from '../Modal';
import Icon from '../Icon';
import { Link, useHistory } from 'react-router-dom';
import { coursesEndpoints, jobsEndpoints, postsEndpoints } from 'api/endpoints';
import useAuth from 'hooks/useAuth';
import { showError } from 'utils/showError';
import devscntrAuth from 'api/Instance/devscntrAuth';
import BanModal from "../BanModal";

const deleteEndpoints = {
  post: postsEndpoints.delete,
  course: coursesEndpoints.delete,
  job: jobsEndpoints.delete,
};

const ModalAuthorSettings = ({ id, type, visible, setVisible, author }) => {
  const authCtx = useAuth();
  const history = useHistory();

  const deleteUrl = deleteEndpoints[type] ?? '';
  const [deleteVisible, setDeleteVisible] = useState(false);

  const isAdmin = authCtx.isAdmin;
  const [ banModal, setBanModal ] = useState(false);

  const handleEdit = () => {
    if (type === 'post') history.push(`/edit-post/${id}`);
    if (type === 'course') history.push(`/edit-course/${id}`);
    if (type === 'job') history.push(`/edit-job/${id}`);
  };

  const deleteApi = async () => {
    try {
      const response = await devscntrAuth.delete(`${deleteUrl}${id}`);
      if (type === 'post') history.push('/post');
      if (type === 'course') history.push('/courses');
      if (type === 'job') history.push('/jobs');
    } catch (error) {
      console.log(error);
      showError('Usuwanie nie powiodło się. Spróbuj ponownie później');
    }
  };

  const handleDelete = () => {
    deleteApi();
  };

  return (
    <Modal visible={visible} onClose={() => !banModal && setVisible(false)}>
      <div className={styles.title}>Ustawienia</div>
      <div className={styles.settings}>
        <div className={styles.item} onClick={handleEdit}>
          <Link to='/' className={styles.item_name}>
            <Icon name='donut-chart' size={20} />
            <span>Przejdź do panelu</span>
          </Link>
        </div>
        <div className={styles.item} onClick={handleEdit}>
          <div className={styles.item_name}>
            <Icon name='edit' size={20} />
            <span>Edytuj</span>
          </div>
        </div>
        <div
          className={styles.item}
          onClick={() => setDeleteVisible(prev => !prev)}
        >
          <div className={styles.item_name}>
            <Icon name='trash' size={20} />
            <span>Usuń</span>
          </div>
          {deleteVisible && (
            <div className={styles.item_options}>
              <button
                className={styles.item_btn}
                style={{ color: 'red' }}
                onClick={handleDelete}
              >
                POTWIERDŹ
              </button>
            </div>
          )}
        </div>
        {isAdmin && <div
            className={cn(styles.item, styles.red)}
            onClick={() => !banModal && setBanModal(true)}
        >
          <div className={styles.item_name}>
            <Icon name='profile-circle' size={20}/>
            <span>Zablokuj użytkownika</span>
          </div>
          <BanModal userID={author} visible={banModal} setVisible={setBanModal}/>
        </div>}
      </div>
    </Modal>
  );
};

export default ModalAuthorSettings;
