import React, { useEffect, useState } from 'react';
import styles from './NewPost.module.sass';
import Panel from 'screens/NewLayouts/Panel';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import axios from 'axios';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { validateInput, validateUrl } from 'utils/validation';
import { showError } from 'utils/showError';
import devscntrAuth from 'api/Instance/devscntrAuth';
import TagsInput from 'screens/NewLayouts/TagsInput';
import ImageInput from 'screens/NewLayouts/ImageInput';
import Content from 'screens/NewLayouts/Content';
import Details from './Details';
import useAuth from 'hooks/useAuth';
import { adminEndpoints, postsEndpoints } from 'api/endpoints';
import Loader from 'screens/NewLayouts/Loader';
import LinksInput from 'screens/NewLayouts/LinksInput';
import isJson from 'utils/isJson';
import Meta from 'components/Meta';
import ChooseCategoryBreadcrumbs from 'components/ChooseCategoryBreadcrumbs';
import SlugInput from 'components/SlugInput';
import ModalSchedule from 'components/ModalSchedule';

const EditPost = () => {
  // UTILS-------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const history = useHistory();
  const { id } = useParams();

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleSchedule, setVisibleSchedule] = useState(false);
  const [status, setStatus] = useState(0);

  const [article, setArticle] = useState(null);
  const [permission, setPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fetching, setFetching] = useState(false);


  const [isPostCreated, setIsPostCreated] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  // UTILS-------------------------------------------------------------------------UTILS
  // FIELDS-----------------------------------------------------------------------FIELDS

  const [editorState, setEditorState] = useState('');
  const [titleState, setTitleState] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [reflink, setReflink] = useState('');
  const [tags, setTags] = useState([]);
  const [links, setLinks] = useState([]);
  const [image, setImage] = useState(null);
  const [files, setFiles] = useState(null);
  const [categories, setCategories] = useState([])
  const [slug, setSlug] = useState('')

  const handleEditorStateChange = editorState => {
    setEditorState(editorState);
    console.log(editorState.length)
  };

  const handleTitleChange = e => {
    const val = e.target.value;
    setTitleState(val);
  };

  const handleMetaTitleChange = event => {
    const val = event.target.value;
    setMetaTitle(val);
  };

  const handleMetaDescriptionChange = event => {
    const val = event.target.value;
    setMetaDescription(val);
  };

  // FIELDS-----------------------------------------------------------------------FIELDS
  // GET DATA-------------------------------------------------------------------GET DATA

  const getPost = async cancelToken => {
    setIsLoading(true);
    try {
      // Request user data
      const response = await devscntrAuth.request({
        method: 'get',
        url: `${postsEndpoints.show}${id}`,
        cancelToken: cancelToken.token,
      });
      setArticle(response?.data[0] || {});
      setImage(response?.data[0]?.image || null);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('post detail canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getPost(cancelToken);

    window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, [id]);

  // INSERT DATA TO FIELDS
  useEffect(() => {
    if (article) {
      // TITLE
      setSlug(article?.slug || '')
      setTitleState(article?.title || '');
      setMetaTitle(article?.meta_title || '');
      setMetaDescription(article?.meta_description || '');

      // DESCRIPTION
      if (article?.description) {
        if (article?.editor_version === 1 && isJson(article.description)) {
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(article.description))
            ) || EditorState.createEmpty()
          );
        }
        if (article?.editor_version === 2) {
          setEditorState(article.description);
        }
      }

      setImage(article?.image || null);

      // SETTING TAGS
      let tagList = [];
      if (article?.tags?.length > 0) {
        tagList = article?.tags.map(item => ({
          name: item.name,
          colour: item.colour,
        }));
      }
      setTags(tagList);

      // SETTING LINKS
      let linksList = [];
      if (article?.additional_url?.length > 0) {
        linksList = article?.additional_url.map(item => ({
          title: item.description,
          url: item.url,
        }));
      }
      setLinks(linksList);

      //SETTING CATEGORIES
      let categoriesList = [];
      if (article?.categories?.length > 0) {
        categoriesList = article?.categories.map(item => ({
          name: item.name,
          id: item.id,
          color: item.color,
        }));
      }

      setCategories(categoriesList);

      if (authCtx.userID === article.author) setPermission(true);
      else if (authCtx.isAdmin) setPermission(true);
    }
  }, [article]);

  // console.log('IMAGE', article?.image);

  const handleSlugChange = event => {
    const val = event.target.value;
    setSlug(val);
  };

  // GET DATA-------------------------------------------------------------------GET DATA
  // VALIDATION---------------------------------------------------------------VALIDATION

  const validation = () => {
    // TITLE
    if (!validateInput(titleState.trim(), 100, 10, 'Tytuł')) return false;

    // DESCRIPTION
    if (article.editor_version === 1) {
      if (
        JSON.stringify(convertToRaw(editorState.getCurrentContent())).length <
        133
      ) {
        showError('Opis nie może być pusty');
        return false;
      }
    }
    if (article.editor_version === 2) {
      if (editorState?.trim(' ')?.length < 1) {
        showError('Opis nie może być pusty');
        return false;
      }
      // if (editorState?.trim(' ')?.length > 16000) {
      //   showError('Przekroczono limit znaków. Skróć opis.');
      //   return false;
      // }
    }

    if (reflink !== '') {
      if (!validateUrl(reflink.trim())) return false;
    }

    return true;
  };

  // VALIDATION---------------------------------------------------------------VALIDATION
  // PREPARE DATA TO SEND-------------------------------------------PREPARE DATA TO SEND

  const prepareFormData = () => {
    const formData = new FormData();

    // TITLE
    formData.append('title', titleState.trim());

    // DESCRIPTION
    if (article.editor_version === 1) {
      formData.append(
        'description',
        JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      );
    }
    if (article.editor_version === 2) {
      formData.append('description', editorState);
    }

    formData.append('status', status);

    // EDITOR VERSION
    formData.append('editor_version', article.editor_version);

    // EXTERNAL URL
    if (reflink !== '') {
      formData.append('is_external', true);
      formData.append('external_url', reflink.trim());
    }

    // TAGS
    if (tags.length > 0) {
      tags.forEach((item, index) => {
        formData.append(`tags[${index}]name`, item.name);
        formData.append(`tags[${index}]colour`, item.colour);
      });
    }

    // CATEGORIES
    if (categories.length > 0) {
      categories.forEach((item, index) => {
        formData.append(`categories[${index}]name`, item.name);
        formData.append(`categories[${index}]id`, item.id);
      });
    }

    // LINKS
    if (links.length > 0) {
      links.forEach((item, index) => {
        formData.append(`additional_url[${index}]description`, item.title);
        formData.append(`additional_url[${index}]url`, item.url);
      });
    }

    // IMAGE
    if (files) {
      formData.append('image', files);
    } else if (image === null) {
      formData.append('delete_image', true);
    }

    //SLUG
    formData.append('slug', slug)

    //META
    formData.append('meta_title', metaTitle.trim());
    formData.append('meta_description', metaDescription);

    if (status === 1) {
      formData.append('scheduled_at', startDate.toISOString());
    }

    return formData;
  };

  const scheduleHandler = () => {
    setVisibleSchedule(false)
    setStatus(1)
  }

  // PREPARE DATA TO SEND-------------------------------------------PREPARE DATA TO SEND
  // UPDATE POST REQUEST---------------------------------------------UPDATE POST REQUEST
  console.log(slug)
  const editPostApi = async formData => {
    setFetching(true);

    try {
      if (article.author === authCtx.userID) {
        const newFormData = formData;
        newFormData.append('id', id);
        const response = await devscntrAuth.post(
          `${postsEndpoints.update}`,
          newFormData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              method: 'update',
            },
          }
        );
      } else if (authCtx.isAdmin) {
        const response = await devscntrAuth.put(
          `${adminEndpoints.posts.update}${id}/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } else {
        return;
      }
      history.push(`/post/${slug}`);
    } catch (error) {
      console.log(error);
    }
    setFetching(false);
  };

  //CATEGORY FUNCTIONS
  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck?.some(item => value.name === item.name)
  }

  const categoryFunction = (item) => {
    if (!checkIfArrayContains(categories, item)) {
      setCategories(categories => [...categories, item])
    }
    if (checkIfArrayContains(categories, item)) {
      setCategories((current) => current.filter((object) => object.name !== item.name));
    }
  }

  const handleSubmit = () => {
    if (!validation()) return;

    const formData = prepareFormData();
    editPostApi(formData);
  };

  const clearAllFields = () => {
    setIsPostCreated(false);
    setIsLoading(false);
    setStartDate(new Date());
    setStartTime(new Date())
    setReflink('');
    setSlug('')
    setTitleState('');
    setEditorState('');
    setMetaTitle('');
    setMetaDescription('');
    setCategories([])
    setFiles(null);
    setTags([]);
    setLinks([]);
  }

  const saveDraft = () => {
    setStatus(2);
  };

  useEffect(() => {
    if (status === 2) {
      if (validateInput(titleState.trim(), 100, 10, 'Tytuł')) {
        const formData = prepareFormData();
        editPostApi(formData);
      } else {
        setStatus(0)
      }
    }
    if (status === 1) {
      if (validation()) {
        const formData = prepareFormData();
        editPostApi(formData);
      }
      else {
        setStatus(0)
      }
    }
  }, [status]);

  // UPDATE POST REQUEST---------------------------------------------UPDATE POST REQUEST

  return !isLoading ? (
    permission ? (
      <>
        <div className={styles.row}>
          <div className={styles.col}>
            <Content
              className={styles.card}
              onTitleChange={handleTitleChange}
              onDescriptionChange={handleEditorStateChange}
              title={titleState}
              description={editorState}
              editorVersion={article?.editor_version}
            />
            <ChooseCategoryBreadcrumbs
              title='Kategorie'
              itemClicked={categoryFunction}
              loading={false}
              selectedCategories={categories}
            />
            <LinksInput
              links={links}
              setLinks={setLinks}
              className={styles.card_links}
            />
            <Details
              className={styles.card}
              reflink={reflink}
              setReflink={setReflink}
            />
            <ImageInput
              className={styles.card}
              title={'Miniaturka postu'}
              setFile={setFiles}
              img={article.image || null}
              onDelete={() => setImage(null)}
              fileName={`post_img_u${authCtx.userID}`}
            />
            <TagsInput className={styles.container} tags={tags} setTags={setTags} />
            <SlugInput className={styles.container} slug={slug} onSlugChange={handleSlugChange} />
            <Meta
              title={metaTitle}
              description={metaDescription}
              onTitleChange={handleMetaTitleChange}
              onDescriptionChange={handleMetaDescriptionChange}
            />

          </div>
        </div>
        <Panel
          setVisiblePreview={setVisiblePreview}
          setVisibleSchedule={setVisibleSchedule}
          onSubmit={handleSubmit}
          isLoading={fetching}
          article={article}
          clearInputs={clearAllFields}
          saveDraft={saveDraft}
        />
        <ModalSchedule
          visible={visibleSchedule}
          setVisible={setVisibleSchedule}
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
          onSubmit={scheduleHandler}
        />
      </>
    ) : (
      <Redirect to={`/post/${slug}`} />
    )
  ) : (
    <Loader />
  );
};

export default EditPost;
