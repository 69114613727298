import React, { useState } from 'react';
import styles from './Item.module.sass';
import { Link, Redirect } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { showUserInfo } from 'store/userInfo/actions';
import Actions from 'components/Actions';
import { adminEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { deleteTimeout } from 'data/dataArrays';

const Item = ({ tag, selected, setSelected, refresh }) => {
  const authCtx = useAuth();
  const isAdmin = true;
  const dispatch = useDispatch();

  // USED BY DELETE
  const [confirm, setConfirm] = useState(false);
  const [allowClick, setAllowClick] = useState(true);
  const [deleteCountdown, setDeleteCountdown] = useState(false);

  const handleShowUserInfo = id => {
    dispatch(showUserInfo(id));
  };

  const handleSelect = () => {
    if (selected) {
      setSelected(prev => prev.filter(item => item.id === tag?.id));
    } else {
      setSelected(prev => [...prev, tag?.id]);
    }
  };

  const banUser = () => {
    console.log('ban user');
    deleteTag();
  };

  const editTag = () => {
    console.log('edit tag');
  };

  const deleteApi = async () => {
    try {
      const response = await devscntrAuth.delete(
        `${adminEndpoints.tags.show}${tag.id}/`
      );
      console.log({ response: response.data });
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTag = () => {
    if (confirm && allowClick) {
      deleteApi();
      setConfirm(false);
    } else {
      if (allowClick) {
        // Show countdown in option
        setDeleteCountdown(deleteTimeout / 1000);
        let timer = setInterval(() => {
          setDeleteCountdown(prev => {
            if (prev === 0) clearInterval(timer);
            else return prev - 1;
          });
        }, 1000);
        // Disable click (anti double-click)
        setAllowClick(false);
        setTimeout(() => setAllowClick(true), deleteTimeout);
        setTimeout(() => setConfirm(false), 10000);
      }
      setConfirm(true);
    }
  };

  const actions = [
    // {
    //   title: "Usuń tag i autora",
    //   icon: "lock",
    //   color: true,
    //   action: banUser,
    // },
    {
      title: confirm ? 'Potwierdź' : 'Usuń',
      icon: 'trash',
      color: confirm,
      badge: deleteCountdown > 0 ? deleteCountdown : false,
      action: deleteTag,
    },
  ];

  const showSettings = () => {
    console.log('ustawienia konta');
  };

  return (
    isAdmin && (
      <div className={styles.item}>
        <div className={styles.head}>
          <div className={styles.head_content}>
            <Checkbox
              className={styles.user_select}
              value={selected}
              onChange={handleSelect}
            />
            <Link to={`/admin/tags/${tag?.id}`} className={styles.name}>
              {tag?.name || 'Brak nazwy tagu'}
            </Link>
          </div>
          <div className={styles.head_actions}>
            <Actions
              className={styles.menu}
              classActionsHead={styles.settings}
              classActionsBody={styles.actionsBody}
              classActionsOption={styles.actionsOption}
              items={actions}
            />
          </div>
        </div>
        <div className={styles.data}>
          <div className={styles.col}>
            <label>
              {/* <Icon name='profile-circle' size={18} /> */}
              Kolor:
            </label>
            <button
              className={styles.color}
              style={{ backgroundColor: tag.colour || '#ffffff' }}
              onClick={() => {
                navigator.clipboard.writeText(tag.colour || '#ffffff');
              }}
            ></button>
          </div>
          <div className={styles.col}>
            <label>Użycia:</label>
            {tag?.tags_count || 'Brak danych'}
          </div>
          <div className={styles.col}>
            <label>
              {/* <Icon name='profile-circle' size={18} /> */}
              Twórca:
            </label>
            {tag.author ? (
              <Link to={`/admin/users/${tag.author}`} className={styles.col}>
                {tag?.author_displayname || 'Brak nazwy'}
              </Link>
            ) : (
              'Brak autora'
            )}
          </div>
        </div>
        <div className={styles.actions}>
          <Actions
            className={styles.menu}
            classActionsHead={styles.settings}
            classActionsBody={styles.actionsBody}
            classActionsOption={styles.actionsOption}
            items={actions}
          />
        </div>

        {/* <button className={styles.settings} onClick={showSettings}>
          <Icon name="more-horizontal" size={24} />
        </button> */}
      </div>
      // <div className={styles.item}>
      //   <div className={styles.data}>
      //     <Checkbox
      //       className={styles.select}
      //       value={selected}
      //       onChange={handleSelect}
      //     />
      //     <Link to={`/admin/tags/${tag.id}`} className={styles.col}>
      //       {tag?.name || 'Brak nazwy tagu'}
      //     </Link>
      //     <div className={styles.col}>
      //       <button
      //         className={styles.color}
      //         style={{ backgroundColor: tag.colour || '#ffffff' }}
      //         onClick={() => {
      //           navigator.clipboard.writeText(tag.colour || '#ffffff');
      //         }}
      //       ></button>
      //     </div>
      //     <div className={styles.col}>{tag?.tags_count || 'Brak danych'}</div>
      //     <Link to={`/admin/users/${tag.author}`} className={styles.col}>
      //       {tag?.author_displayname || 'Brak nazwy'}
      //     </Link>
      //   </div>
      //   {/* <button className={styles.settings} onClick={showSettings}>
      //     <Icon name="more-horizontal" size={24} />
      //   </button> */}
      //   <Actions
      //     className={styles.menu}
      //     classActionsHead={styles.settings}
      //     classActionsBody={styles.actionsBody}
      //     classActionsOption={styles.actionsOption}
      //     items={actions}
      //   />
      // </div>
    )
  );
};
export default Item;
