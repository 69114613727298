import React, { useEffect } from 'react';
import styles from './Admin.module.sass';
import { Link, Route, Switch } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Users from './Users';
import UserModal from './UserModal';
import Tags from './Tags';
import Icon from 'components/Icon';
import UserDetail from './Users/UserDetail';
import TagDetail from './Tags/TagDetail';
import Dashboard from './Dashboard';
import ReportedBugs from './Reports/ReportedBugs';
import ReportedContent from './Reports/ReportedContent';
import Levels from './Settings/Levels';
import Points from './Settings/Points';
import Badges from './Settings/Badges';

const navigation = [
  {
    title: 'Dashboard',
    icon: 'lightning',
    url: '/admin',
  },
  {
    title: 'Użytkownicy',
    icon: 'profile-circle',
    url: '/admin/users',
  },
  {
    title: 'Tagi',
    icon: 'ticket',
    url: '/admin/tags',
  },
  {
    title: 'Zgłoszone błędy',
    icon: 'bug',
    url: '/admin/support/bug-reports',
  },
  {
    title: 'Zgłoszone treści',
    icon: 'ticket',
    url: '/admin/support/content-reports',
  },
  {
    title: 'Ordery',
    icon: 'ticket',
    url: '/admin/settings/badges',
  },
  {
    title: 'Zarządzanie poziomami',
    icon: 'ticket',
    url: '/admin/settings/levels',
  },
  {
    title: 'Zarządzanie XP',
    icon: 'ticket',
    url: '/admin/settings/points',
  },
];

const Admin = ({ children }) => {
  const authCtx = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!authCtx.isAdmin) return null;

  return (
    <>
      <UserModal />

      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.nav}>
            {navigation.map((item, index) => (
              <Link
                to={item.url}
                className={styles.item}
                key={`admin_nav_${index}`}
              >
                <Icon name={item.icon} size={20} />
                {item.title}
              </Link>
            ))}
          </div>
          <div className={styles.content}>{children}</div>

          <Switch>
            <Route exact path='/admin'>
              <Dashboard />
            </Route>
            <Route exact path='/admin/users'>
              <Users />
            </Route>
            <Route exact path='/admin/users/:id'>
              <UserDetail />
            </Route>
            <Route exact path='/admin/tags'>
              <Tags />
            </Route>
            <Route exact path='/admin/tags/:id'>
              <TagDetail />
            </Route>
            <Route exact path='/admin/support/bug-reports'>
              <ReportedBugs />
            </Route>
            <Route exact path='/admin/support/content-reports'>
              <ReportedContent />
            </Route>
            <Route exact path='/admin/settings/badges'>
              <Badges />
            </Route>
            <Route exact path='/admin/settings/levels'>
              <Levels />
            </Route>
            <Route exact path='/admin/settings/points'>
              <Points />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};
export default Admin;
