import React from 'react';
import cn from 'classnames';
import styles from './Content.module.sass';
import Card from '../../../components/Card';
import TextInput from '../../../components/TextInput';
import Editor from '../../../components/Editor';
import EditorTiny from 'components/EditorTiny';

const Content = ({
  className,
  onDescriptionChange,
  onTitleChange,
  title,
  description,
  editorVersion,
}) => {
  return (
    <Card
      className={cn(styles.card, className)}
      title='Tytuł i opis'
      classTitle='title-green'
    >
      <div className={styles.description}>
        <TextInput
          className={styles.field}
          label='Tytuł'
          name='title'
          type='text'
          tooltip={'Maksymalnie 100 znaków. Bez tagów HTML oraz emotek'}
          value={title}
          onChange={onTitleChange}
          currency={title.length}
          required
        />
        {editorVersion === 1 && (
          <Editor
            editorState={description}
            onEditorChange={onDescriptionChange}
            classEditor={styles.editor}
            label='Opis'
          />
        )}
        {editorVersion === 2 && (
          <EditorTiny
            state={description}
            setState={onDescriptionChange}
            classEditor={styles.editor}
            label='Opis'
          />
        )}
      </div>
    </Card>
  );
};

export default Content;
