import React, { useState } from 'react';
import styles from './AddBadge.module.sass';
import cn from 'classnames';
import TextInput from 'components/TextInput';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { adminEndpoints } from 'api/endpoints';
import { validateImage, validateInput } from 'utils/validation';
import { showError } from 'utils/showError';

const AddBadge = ({ onSuccess }) => {
  // UTILS-------------------------------------------------------------------------UTILS

  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [level, setLevel] = useState('');

  // UTILS-------------------------------------------------------------------------UTILS
  // VALIDATION---------------------------------------------------------------VALIDATION

  const validation = () => {
    if (!validateInput(name, 30, 1, 'nazwa', 'letters')) return false;
    if (!validateInput(level, 20, 1, 'od poziomu', 'numbers')) return false;
    if (!validateImage(image, 5)) return false;

    return true;
  };

  // VALIDATION---------------------------------------------------------------VALIDATION
  // ADD LEVEL-----------------------------------------------------------------ADD LEVEL

  const addBadge = async () => {
    if (!validation()) return;
    try {
      let data = {
        name: name,
        from_level: level,
      };
      if (image) data = { ...data, image: image };

      const response = await devscntrAuth.post(
        adminEndpoints.settings.badges,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log(response);
      onSuccess();
    } catch (error) {
      showError('Nie udało się dodać orderu');
    }
  };

  // ADD LEVEL-----------------------------------------------------------------ADD LEVEL

  return (
    <div className={styles.container}>
      <div className='title-blue'>Dodaj order</div>
      <div className={styles.content}>
        <div className={styles.field}>
          <TextInput
            label='Nazwa'
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className={styles.field}>
          <input type='file' onChange={e => setImage(e.target.files[0])} />
        </div>
        <div className={styles.field}>
          <TextInput
            label='Od poziomu'
            value={level}
            onChange={e => setLevel(e.target.value)}
          />
        </div>
        <button className='button' onClick={addBadge}>
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default AddBadge;
