import React, { useState, useEffect, useRef } from 'react';
import styles from './Dashboard.module.sass';
import { Redirect } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import cn from 'classnames';
import Card from 'components/Card';
import useBreakpoints from 'hooks/useBreakpoints';
import Tags from './Tags';
import Users from './Users';
import devscntrAuth from 'api/Instance/devscntrAuth';
import SkeletonLoader from './SkeletonLoader';
import Overview from './Overview';
import GlobalChart from './Charts/GlobalChart';
import Dropdown from 'components/Dropdown';
import Charts from './Charts';

const TAGS_URL = 'accounts/admin/tags/';
const USERS_URL = 'accounts/admin/users/';
const USER_STATS_URL = 'accounts/admin/userstats/';

const Dashboard = () => {
  const authCtx = useAuth();

  /* ---------------------------------- data ---------------------------------- */
  const [tags, setTags] = useState([]);
  const [users, setUsers] = useState([]);
  const [userStats, setUserStats] = useState({
    todayUsers: null,
    adminUsers: null,
    nonRssUsers: null,
    activeNonRssUsers: null,
  });

  /* ------------------------------- is loading ------------------------------- */
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingUserStats, setIsLoadingUserStats] = useState(false);

  /* ---------------------------- abort controllers --------------------------- */
  const tagsController = new AbortController();
  const tagsSignal = tagsController.signal;
  const usersController = new AbortController();
  const usersSignal = usersController.signal;
  const userStatsController = new AbortController();
  const userStatsSignal = userStatsController.signal;

  /* ---------------------------------- utils --------------------------------- */
  const [ref, setRef] = useState(); // for useBreakpoints
  const isMounted = useRef(true);

  const breakpoints = [
    ['r-desktop', Infinity],
    ['r-medium', 1100],
    ['r-mobile', 768],
  ];
  useBreakpoints(ref, breakpoints);

  /* -------------------------------------------------------------------------- */
  /*                                    logic                                   */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getTags();
    getUsers();
    getUserStats();

    return () => {
      isMounted.current = false;
      tagsController.abort();
      usersController.abort();
      userStatsController.abort();
    };
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                  requests                                  */
  /* -------------------------------------------------------------------------- */

  const getTags = async () => {
    console.log('get tags');
    try {
      setIsLoadingTags(true);
      const response = await devscntrAuth.get(TAGS_URL, { signal: tagsSignal });
      const data = response?.data?.results;
      console.log(data);

      isMounted.current && setTags(data);
      //
    } catch (err) {
      console.error(err);
    } finally {
      isMounted.current && setIsLoadingTags(false);
    }
  };

  const getUsers = async () => {
    console.log('get users');
    try {
      setIsLoadingUsers(true);
      const response = await devscntrAuth.get(USERS_URL, {
        signal: usersSignal,
      });
      const data = response?.data?.data;
      console.log(response.data);

      isMounted.current && setUsers(data);
    } catch (err) {
      console.error(err);
    } finally {
      isMounted.current && setIsLoadingUsers(false);
    }
  };

  const getUserStats = async () => {
    try {
      setIsLoadingUserStats(true);
      const response = await devscntrAuth.get(USER_STATS_URL, {
        signal: userStatsSignal,
      });
      const data = response?.data;
      console.log(data);
      const {
        today_logged_users: todayUsers,
        total_admin_users: adminUsers,
        total_non_rss_users: nonRssUsers,
        total_active_non_rss_users: activeNonRssUsers,
      } = data;
      isMounted.current &&
        setUserStats({
          todayUsers,
          adminUsers,
          nonRssUsers,
          activeNonRssUsers,
        });
    } catch (err) {
      console.error(err);
    } finally {
      isMounted.current && setIsLoadingUserStats(false);
    }
  };

  return (
    <>
      {authCtx.isAdmin ? (
        <div className={styles.allWrap} ref={x => setRef(x)}>
          <div className={styles.topContainer} ref={x => setRef(x)}>
            <Card
              className={styles.card}
              classCardHead={styles.head}
              title='Najpopularniejsze tagi'
              classTitle={cn('title-purple', styles.title)}
            >
              {isLoadingTags ? (
                <SkeletonLoader cards={5} />
              ) : (
                <Tags tags={tags} />
              )}
            </Card>
            <Card
              className={styles.card}
              classCardHead={styles.head}
              title='Topowi użytkownicy'
              classTitle={cn('title-purple', styles.title)}
            >
              {isLoadingUsers ? (
                <SkeletonLoader cards={5} />
              ) : (
                <Users users={users} forAdmin />
              )}
            </Card>
          </div>
          <Overview userStats={userStats} />
          <Charts />
        </div>
      ) : (
        <Redirect to='/' />
      )}
    </>
  );
};
export default Dashboard;
