import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { Link } from "react-router-dom";

const Item = ({ course }) => {
  return (
    <Link to={`/course/${course?.slug}`}>
      <div className={styles.item}>
        <div className={styles.preview}>
          <img src={course.image || "/images/image-placeholder.png"} alt="Obrazek kursu" />
        </div>
        <div className={styles.title}>{course.title}</div>
        {/* <div className={styles.details}>
          {course?.tags?.length > 0 && <div className={cn("status-green", styles.status)}>{course?.tags[0].name}</div>}
        </div> */}
      </div>
    </Link>
  );
};

export default Item;
