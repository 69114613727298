import React from 'react';
import styles from './PlusMinusButton.module.sass';
import useDarkMode from 'use-dark-mode';
import cn from 'classnames';

const PlusMinusButton = ({ onClick, item, isOpen, loading }) => {

    const isDarkMode = useDarkMode()

    return (
        <div className={styles.container}>
            <button
                className={isOpen ? cn(styles.plus, styles.plus_rotated) : styles.plus}
                onClick={() => { (!loading || !isOpen) && onClick(item) }}
            >
                <div
                    className={isOpen ? cn(styles.plus_icon_vertical_rotate, styles.plus_icon_vertical) : styles.plus_icon_vertical}
                    style={!isOpen ?
                        {
                            borderColor: isDarkMode.value ? `${item.color}` : `black`,
                        }
                        :
                        {
                            borderColor: `black`,
                        }
                    }
                ></div>
                <div
                    className={isOpen ? cn(styles.plus_icon_horizontal_rotate, styles.plus_icon_horizontal) : styles.plus_icon_horizontal}
                    style={!isOpen ?
                        {
                            borderColor: isDarkMode.value ? `${item.color}` : `black`,
                        }
                        :
                        {
                            borderColor: `black`,
                        }
                    }
                ></div>
            </button>
        </div>
    );
};
export default PlusMinusButton