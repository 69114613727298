import React, { useEffect, useState } from 'react';
import styles from './CvForm.module.sass';
import cn from 'classnames';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { accountsEndpoints } from 'api/endpoints';
import TextInput from 'components/TextInput';
import useAuth from 'hooks/useAuth';
import PhoneNumberInput from 'components/PhoneNumberInput';
import Button from 'components/Button';
import { validateInput } from 'utils/validation';
import { validationRules } from 'data/validationRules';
import Icon from 'components/Icon';
import uuid from 'react-uuid';
import List from './List';
import TextArea from 'components/TextArea';
import FileInput from 'components/FileInput';
import Checkbox from 'components/Checkbox';
import { showSuccess } from 'utils/showSuccess';
import { showLoginModal } from 'utils/showLoginModal';
import useBreakpoints from 'hooks/useBreakpoints';

const maxHobbyLength = 999;

const CvForm = ({ classNames, onSubmit }) => {
  // UTILS ----------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const [processing, setProcessing] = useState(false);
  const [id, setId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ['desktop', Infinity],
    ['mobile', 500],
  ];
  useBreakpoints(stylesRef, breakpoints);

  // UTILS ----------------------------------------------------------------UTILS
  // VALIDATION UTILS ------------------------------------------VALIDATION UTILS

  const [error, setError] = useState('');

  const validator = (value, fieldName = '', maxChars = 255, minChars = 1) => {
    const allowedCharacters =
      /^[a-zA-Z0-9ĄąĆćĘęŁłŃńÓóŚśŹźŻż \b\p{L}\n"':!@#$%\^&*\(\)+,.?\-]+$/;

    if (value.length > maxChars) {
      showError(
        `Pole '${fieldName}' musi mieć ${minChars} - ${maxChars} znaków`
      );
      return false;
    }
    if (value.length < minChars) {
      showError(
        `Pole '${fieldName}' musi mieć ${minChars} - ${maxChars} znaków`
      );
      return false;
    }
    if (value.length !== 0 && !allowedCharacters.test(value)) {
      showError(`Pole '${fieldName}' zawiera niedozwolone znaki`);
      return false;
    }

    return true;
  };

  const showError = message => {
    setError(message);
    document.getElementById('modal').scrollTo({ behavior: 'smooth', top: 0 });
  };

  // VALIDATION UTILS ------------------------------------------VALIDATION UTILS
  // FIELDS --------------------------------------------------------------FIELDS

  // Personal data
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+48');
  const [website, setWebsite] = useState('');

  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [certificates, setCertificates] = useState([]);

  const [hobby, setHobby] = useState('');
  const [acceptPrivacy, setAcceptPrivacy] = useState(true);

  // FIELDS --------------------------------------------------------------FIELDS
  // CLEAR FIELDS --------------------------------------------------CLEAR FIELDS

  const clearFields = () => {
    setId(null);
    setFirstname('');
    setLastname('');
    setEmail('');
    setPhone('+48');
    setWebsite('');
    setSkills([]);
    setExperience([]);
    setEducation([]);
    setLanguages([]);
    setCertificates([]);
    setHobby('');

    // Get user data
    getUserInfo();
  };

  // CLEAR FIELDS --------------------------------------------------CLEAR FIELDS
  // GET USER INFO ------------------------------------------------GET USER INFO
  const getUserInfo = async signal => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: accountsEndpoints.userData.myAccount,
        signal: signal,
      });

      // Set user's data
      const data = response.data.data.user;
      setFirstname(data.first_name || '');
      setLastname(data.last_name || '');
      setEmail(data.email || '');
      setPhone(data.phone || '+48');
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    getUserInfo(signal);

    return () => {
      abortController.abort();
    };
  }, []);

  // GET USER INFO ------------------------------------------------GET USER INFO
  // GET USER CV ----------------------------------------------------GET USER CV
  const getMyCV = async signal => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: accountsEndpoints.documents.CV.show,
        signal: signal,
      });

      const data = response.data;
      if (data.id) {
        setId(data.id);
        setFirstname(data.firstname || '');
        setLastname(data.lastname || '');
        setEmail(data.email || '');
        setPhone(data.phone || '+48');
        setWebsite(data.websites || '');
        data?.skills?.length > 0 && setSkills(data?.skills?.split(','));
        data?.experience?.length > 0 &&
          setExperience(data?.experience?.split(',') || []);
        data?.education?.length > 0 &&
          setEducation(data?.education?.split(',') || []);
        data?.languages?.length > 0 &&
          setLanguages(data?.languages?.split(',') || []);
        data?.certificates?.length > 0 &&
          setCertificates(data?.certificates?.split(',') || []);
        setHobby(data.hobbies || '');
      }
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    getMyCV(signal);

    return () => {
      abortController.abort();
    };
  }, [refresh]);

  // GET USER CV ----------------------------------------------------GET USER CV
  // VALIDATE FORM ------------------------------------------------VALIDATE FORM

  const validateForm = () => {
    // Check FIRSTNAME
    if (
      !validator(
        firstname,
        'Imię',
        validationRules.firstname.maxChars,
        validationRules.firstname.minChars
      )
    ) {
      return false;
    }

    // Check LASTNAME
    if (
      !validator(
        lastname,
        'Nazwisko',
        validationRules.lastname.maxChars,
        validationRules.lastname.minChars
      )
    ) {
      return false;
    }

    // Check EMAIL
    if (
      !validator(
        email,
        'Email',
        validationRules.email.maxChars,
        validationRules.email.minChars
      )
    ) {
      return false;
    }

    // Check WEBSITE
    if (website.length > 0) {
      try {
        if (Boolean(new URL(website))) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    }

    // Check HOBBY
    if (!validator(hobby, 'Hobby', maxHobbyLength, 0)) {
      return false;
    }

    if (!phone.length > 0) {
      return false;
    }

    // Check lists
    if (!skills.length > 0) {
      showError("Pole 'Umiejętności' nie może być puste");
      return false;
    }
    if (!experience.length > 0) {
      showError("Pole 'Doświadczenie' nie może być puste");
      return false;
    }
    if (!education.length > 0) {
      showError("Pole 'Edukacja' nie może być puste");
      return false;
    }
    if (!languages.length > 0) {
      showError("Pole 'Języki' nie może być puste");
      return false;
    }
    if (!certificates.length > 0) {
      showError("Pole 'Certyfikaty' nie może być puste");
      return false;
    }

    return true;
  };

  // VALIDATE FORM ------------------------------------------------VALIDATE FORM
  // SUBMIT CV --------------------------------------------------------SUBMIT CV

  const handleSubmit = e => {
    e.preventDefault();
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (!validateForm()) return;
    const data = prepareData();
    console.log(data);
    data && submitApi(data);
  };

  const prepareData = () => {
    let data = {
      firstname: firstname || '',
      lastname: lastname || '',
      email: email || '',
      phone: phone || '',
      websites: website || 'brak',
      skills: skills.join(',') || [],
      experience: experience.join(',') || [],
      education: education.join(',') || [],
      languages: languages.join(',') || [],
      certificates: certificates.join(',') || [],
      hobbies: hobby || '',
      //accept_privacy_policy: acceptPrivacy || true,
    };
    //if (file) data = { ...data, file: file };

    return data;
  };

  const submitApi = async data => {
    setProcessing(true);
    try {
      const response = await devscntrAuth.request({
        method: id ? 'put' : 'post',
        url: `${accountsEndpoints.documents.CV.create}${id ? `${id}/` : ''}`,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response);
      showSuccess('Twoje CV zostało zapisane');
      onSubmit && onSubmit();
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
    setProcessing(false);
  };

  // SUBMIT CV --------------------------------------------------------SUBMIT CV
  // DELETE CV --------------------------------------------------------DELETE CV

  const handleDelete = () => {
    if (confirmDelete) {
      deleteApi();
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
      setTimeout(() => {
        setConfirmDelete(false);
      }, 5000);
    }
  };

  const deleteApi = async () => {
    if (!id) return;
    setProcessing(true);
    try {
      const response = await devscntrAuth.delete(
        `${accountsEndpoints.documents.CV.delete}${id}/`
      );
      console.log(response);
      clearFields();
      setRefresh(prev => !prev);
    } catch (e) {
      console.log(e);
    }
    setProcessing(false);
  };

  // DELETE CV --------------------------------------------------------DELETE CV

  return (
    <div
      className={cn(styles.container, classNames && classNames)}
      ref={x => setStylesRef(x)}
    >
      <div className={cn('title-purple', styles.title)}>Moje CV</div>
      <form className={styles.form} method='post'>
        {error && (
          <div className={styles.error}>
            <Icon name='info' size='24' />
            {error}
          </div>
        )}
        <h3 className={styles.section_title}>Dane kontaktowe</h3>
        <section className={styles.form_section}>
          <TextInput
            className={styles.field}
            type='text'
            name='firstname'
            label='Imię'
            value={firstname}
            onChange={e => setFirstname(e.target.value)}
          />
          <TextInput
            className={styles.field}
            type='text'
            name='lastname'
            label='Nazwisko'
            value={lastname}
            onChange={e => setLastname(e.target.value)}
          />
          <TextInput
            className={styles.field}
            type='email'
            name='email'
            label='Email'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <PhoneNumberInput
            className={styles.field}
            label={'Numer telefonu'}
            name='phone'
            value={phone}
            onChange={setPhone}
          />
          <TextInput
            className={styles.field}
            type='text'
            name='website'
            placeholder='https://example.com/'
            label='Strona internetowa'
            value={website}
            onChange={e => setWebsite(e.target.value)}
          />
        </section>

        <h3 className={styles.section_title}>Umiejętności</h3>
        <section className={styles.form_section}>
          <List
            classNames={styles.list}
            list={skills}
            setList={setSkills}
            placeholder='W czym jesteś dobry/a?'
          />
        </section>

        <h3 className={styles.section_title}>Doświadczenie</h3>
        <section className={styles.form_section}>
          <List
            classNames={styles.list}
            list={experience}
            setList={setExperience}
            placeholder='Przy czym pracowałeś/aś?'
          />
        </section>

        <h3 className={styles.section_title}>Edukacja</h3>
        <section className={styles.form_section}>
          <List
            classNames={styles.list}
            list={education}
            setList={setEducation}
            placeholder='Jakie szkoły ukończyłeś/aś?'
          />
        </section>

        <h3 className={styles.section_title}>Języki</h3>
        <section className={styles.form_section}>
          <List
            classNames={styles.list}
            list={languages}
            setList={setLanguages}
            placeholder='Jakie znasz języki?'
          />
        </section>

        <h3 className={styles.section_title}>Certyfikaty</h3>
        <section className={styles.form_section}>
          <List
            classNames={styles.list}
            list={certificates}
            setList={setCertificates}
            placeholder='Jakie posiadasz certyfikaty/dyplomy?'
          />
        </section>

        <h3 className={styles.section_title}>Zainteresowania i hobby</h3>
        <section className={styles.form_section}>
          <TextArea
            className={styles.textarea}
            name='hobby'
            type='text'
            placeholder='Co lubisz robić poza pracą? Co Cię motywuje? Co Cię wyróżnia?'
            value={hobby}
            onChange={e => setHobby(e.target.value)}
            maxLength={maxHobbyLength}
          />
        </section>

        {/* <h3 className={styles.section_title}>Załączniki</h3>
        <section className={styles.form_section}>
          <FileInput
            classNames={styles.file_input}
            file={file}
            setFile={setFile}
          />
          <ul className={styles.attachments}>
            {file && (
              <li className={styles.attachment}>
                <span className={styles.attachment_name}>{file.name}</span>
                <span className={styles.attachment_size}>
                  {Math.ceil(file.size / 1024)} KB
                </span>
                <button
                  className={styles.attachment_remove}
                  onClick={() => setFile(null)}
                >
                  <Icon name='close' size={20} />
                </button>
              </li>
            )}
          </ul>
        </section>

        <h3 className={styles.section_title}>Zgody</h3>
        <section className={styles.form_section}>
          <div className={styles.acceptance}>
            <Checkbox
              className={styles.checkbox}
              content='Wyrażam zgodę na przetwarzanie moich danych osobowych przez itCNTR w celu prowadzenia rekrutacji. <span style="color: red">*</span>'
              value={acceptPrivacy}
              onChange={() => setAcceptPrivacy(prev => !prev)}
            />
          </div>
        </section> */}
      </form>
      <Button
        classNames={styles.button}
        isLoading={processing}
        onClick={handleSubmit}
        disabled={!acceptPrivacy}
      >
        Zapisz CV
      </Button>
      <button
        className={cn(
          'button-stroke',
          styles.button,
          confirmDelete && styles.confirm
        )}
        onClick={handleDelete}
      >
        <Icon name='trash' size={20} />
        {confirmDelete ? 'Potwierdź' : 'Usuń CV'}
      </button>
    </div>
  );
};

export default CvForm;
